import config from "@/config"
import http from "@/utils/request"

export default {
	getClauseList: {
		url: `${config.API_URL}/cfg/business/clause/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	delClause: {
		url: `${config.API_URL}/cfg/business/clause/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailClause: {
		url: `${config.API_URL}/cfg/business/clause/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/business/clause/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	insertClause: {
		url: `${config.API_URL}/cfg/business/clause/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateClause: {
		url: `${config.API_URL}/cfg/business/clause/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagClause0: {
		url: `${config.API_URL}/cfg/business/clause/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagClause1: {
		url: `${config.API_URL}/cfg/business/clause/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/business/clause/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
