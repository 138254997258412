import config from "@/config"
import http from "@/utils/request"

export default {
	getListPrice: {
		url: `${config.API_URL}/crm/product/price/getList`,
		name: "产品价格查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListPurPrice: {
		url: `${config.API_URL}/crm/product/purPrice/getList`,
		name: "采购价格查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListSalePrice: {
		url: `${config.API_URL}/crm/product/salePrice/getList`,
		name: "销售价格查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
