import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/buy/payment/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/buy/payment/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	journalInsert: {
		url: `${config.API_URL}/fms/buy/payment/journalInsert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	voucherInsert: {
		url: `${config.API_URL}/fms/buy/payment/voucherInsert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/buy/payment/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/buy/payment/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/buy/payment/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updatePaymentStatus: {
		url: `${config.API_URL}/fms/buy/payment/updatePaymentStatus`,
		name: "确认付款",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	queryBySupplierId: {
		url: `${config.API_URL}/fms/buy/payment/queryBySupplierId`,
		name: "根据id查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	handleCancel: {
		url: `${config.API_URL}/fms/buy/payment/handleCancel`,
		name: "作废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
