import config from "@/config"
import http from "@/utils/request"

export default {
	selectBomDetail: {
		url: `${config.API_URL}/erp/produce/bom/Detail/selectBomDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	queryOrderSonIdByDetail: {
		url: `${config.API_URL}/erp/produce/bom/Detail/queryOrderSonIdByDetail`,
		name: "根据销售订单明细id查询bom清单明细",
		get: async function (saleOrderSonId) {
			return await http.get(this.url + "?orderSonId=" + saleOrderSonId);
		}
	},

	getGoodMaterial: {
		url: `${config.API_URL}/erp/produce/bom/Detail/getGoodMaterial`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},

	queryOrderSonIdsByDetail: {
		url: `${config.API_URL}/erp/produce/bom/Detail/queryOrderSonIdsByDetail`,
		name: "根据销售订单明细id查询bom清单明细",
		get: async function (saleOrderSonIds) {
			return await http.get(this.url + "?orderSonId=" + saleOrderSonIds);
		}
	},
}
