import config from "@/config"
import http from "@/utils/request"
export default {
	getList: {
		url: `${config.API_URL}/sys/base/menu/ordinary/getMenuOrdinary`,
		name: "常用列表",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},
	saveCommon: {
		url: `${config.API_URL}/sys/base/menu/ordinary/setMenuOrdinary`,
		name: "保存常用",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
