import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/business/goodsPrice/getList`,
		name: "分页查询所有",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/business/goodsPrice/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	createInsert: {
		url: `${config.API_URL}/cfg/business/goodsPrice/createInsert`,
		name: "按钮新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/business/goodsPrice/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	check: {
		url: `${config.API_URL}/cfg/business/goodsPrice/check`,
		name: "价格名称校验",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/business/goodsPrice/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/business/goodsPrice/delete`,
		name: "删除",
		post: async function(data = {}){
			return await http.post(this.url,data);
		}
	},
	updateFlag:{
		url: `${config.API_URL}/cfg/business/goodsPrice/updateFlag`,
		name: "修改状态",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	importExcel:{
		url: `${config.API_URL}/cfg/business/goodsPrice/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},
	synchronization:{
		url: `${config.API_URL}/cfg/business/goodsPrice/synchronization`,
		name: "批量同步",
		get: async function(){
			return await http.get(this.url);
		}
	},
}
