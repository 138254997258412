import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/invoice/purInvoice/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/invoice/purInvoice/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/invoice/purInvoice/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/invoice/purInvoice/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/invoice/purInvoice/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	signFor: {
		url: `${config.API_URL}/fms/invoice/purInvoice/signFor`,
		name: "签收",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	invoiceCancel: {
		url: `${config.API_URL}/fms/invoice/purInvoice/invoiceCancel`,
		name: "发票作废",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/fms/invoice/purInvoice/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/fms/invoice/purInvoice/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	alreadyMailOut: {
		url: `${config.API_URL}/fms/invoice/purInvoice/alreadyMailOut`,
		name: "修改状态为已寄出",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	invoiceReject: {
		url: `${config.API_URL}/fms/invoice/purInvoice/invoiceReject`,
		name: "修改审批状态",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	invoiceReceived: {
		url: `${config.API_URL}/fms/invoice/purInvoice/invoiceReceived`,
		name: "签收",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/fms/invoice/purInvoice/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
