import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/fms/buy/apply/getList`,
		name: "列表",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/mobile/fms/buy/apply/delete`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/fms/buy/apply/detail`,
		name: "详情",
		get: async function (id) {
			return await http.get(this.url + '?id='+ id);
		}
	},
	getOrderList: {
		url: `${config.API_URL}/mobile/fms/buy/applyOrder/getList`,
		name: "获取采购订单物品详情",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
