<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="dialogRef"
		v-model="visible"
		:title="title"
		:loading="loading"
		:width="width"
		type="detail"
		:before-close="beforeClose"
		@reset="reset"
	>
		<el-card
			shadow="never"
			class="mycard mt_10"
		>
<!--			<template #header>-->
<!--				<div class="mt_10 mb10 mr10">需分配数量：-->
<!--					<span class="mr10 ml10">物品：{{ form.fz_goodsName }}</span>-->
<!--					<span class="ml10">数量：{{ form.quantity }}</span>-->
<!--				</div>-->
<!--			</template>-->
			<SyEditTable
				ref="refSonDetail"
				:config="configSonDetail"
				:form="dataList"
				hideOprate
				:is-index="false"
				@blur-edit="blurEdit"
			>
				<template #turnState="{ scope }">
					<span v-if="scope.row.turnState == 0">未结转</span>
					<span v-if="scope.row.turnState == 1">部分结转</span>
					<span v-if="scope.row.turnState == 2">全部结转</span>
				</template>
				<template #fz_contractNo="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row)"
					>
						{{ scope.row.fz_contractNo }}
					</el-link>
				</template>
				<template #applyStatus="{ scope }">
					<span v-if="scope.row.applyStatus == 1">单据新增</span>
					<el-link
						v-if="scope.row.applyStatus ==  2"
						type="primary"
						@click="applyList(scope.row)"
					>
						采购申请
					</el-link>
				</template>
			</SyEditTable>
		</el-card>
<!--		<template #footer>-->
<!--			<el-button-->
<!--				type="primary"-->
<!--				:size="$store.state.global.uiSize"-->
<!--				@click="save"-->
<!--			>-->
<!--				确定-->
<!--			</el-button>-->
<!--		</template>-->
		<suggestionPop @suggestionPop="suggestionData" ref="refSuggestionPop" >
		</suggestionPop>
		<applyNumPop ref="refApplyNumPop" />
		<contractDetail ref="refDetail" />
	</sc-dialog>
</template>

<script>

import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs} from 'vue'
import {handleCutZero} from "@/utils/validate";
import suggestionPop from "@/views/erp/purchase/apply/suggestionPop.vue";
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import applyNumPop from "@/views/erp/purchase/order/applyNumPop.vue";
import contractDetail from "@/views/erp/purchase/contract/detail.vue";
export default defineComponent({
	components: {contractDetail, applyNumPop, GoodsDetail, suggestionPop},
	emits: ['fetch-data'],
	setup(prop,{emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '60%',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			applyList:[],
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '单据来源',
			//详情数据对象
			form: {},
			dataList:[],
			orgForm: {},
			//现金流项目数据
			cashFlowList: [],
			//定义本表的ref
			refSonDetail: null,
			configSonDetail: {
				columns: [
					{
						label: '采购合同单号',
						prop: 'fz_contractNo',
						sortable: 'custom',
						fixed: 'left',
						width: 200,
					},
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: "结转状态",
						prop: "turnState",
						width: 110,
						sortable: "custom",
					},
					{
						label: '采购数量',
						prop: 'quantity',
						width: 110,
						sortable: "custom",
					},
					{
						label: '单据来源',
						prop: 'applyStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: "已结转数",
						prop: "transferQty",
						width: 110,
						sortable: "custom",
					},

				],
			},
		})

		const showDialog = async (row,data) => {
			console.log(row,data)
			state.visible = true
			getSonList(row)
			state.loading = false
		}

		//获取子集
		const getSonList = (data) => {
			nextTick(()=>{
				state.refSonDetail.list = []
				proxy.$refs.refSonDetail.list = [data]
			})

		}

		//编辑框失去焦点事件
		const blurEdit = (data) => {
			if(data && data.row && data.item){
				if(data.item.prop == 'quantity'){
					let num = 0
					proxy.$refs.refSonDetail.list.forEach(item => {
						num += Number(item.quantity)
					})
					state.form.quantity = num
				}
			}
		}

		const setValue = (val,field) => {

		}

		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		const handleConfirm = () => {
			hideDialog()
		}
		const hideDialog = () => {
			state.visible = false
		}
		//确定按钮
		const save = () => {
			// const sonDetail = state.refSonDetail.list
			// state.form.goodsOccupyList = sonDetail
			// emit('orderNumData', sonDetail)
			state.visible = false
			// hideDialog()
		}
		//重置表单
		const reset = () => {
			//重置子表数据
			state.refSonDetail.list = []
			// 重置之后 子表的表格还在
			state.refSonDetail.initList()
		}
		const handleList = async (row) => {
			proxy.$refs.refSuggestionPop.showDialog(row)
		}

		// 数量弹框回调
		const suggestionData = (data) => {
			console.log(data)
		}
		// 查看采购申请来源List
		const applyList = async (row) => {
			if (row && row.id) {
				const res = await proxy.$API.purchaseContract.showApplyAllot.get(row.id,row.goodsId)
				if(res.code === 200 && res.data){
					state.applyList = res.data
				}else if(res.code === 500){
					proxy.$baseMessage(res.msg,'error')
				}
			}
			proxy.$refs.refApplyNumPop.showDialog(state.applyList,row)
		}
		//打开详情
		const handleDetail = (row) => {
			const params = Object.assign({}, row)
			params.id = row.contractId
			proxy.$refs.refDetail.showDialog(params)
		}

		return {
			...toRefs(state),
			handleList,
			handleDetail,
			applyList,
			suggestionData,
			showDialog,
			hideDialog,
			blurEdit,
			setValue,
			beforeClose,
			handleConfirm,
			reset,
			save,
		}
	}
})
</script>

<style scoped>
:deep(.el-select){
	width: 100%;
}
</style>
