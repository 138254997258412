<template>
  <sc-dialog
    ref="dialogRef"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm formset">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="机构名称"
        >
          <el-input
            v-model="form.name"
            clearable
            maxlength="255"
          />
        </el-form-item>
        <el-form-item
          prop="stype"
          label="机构类型"
        >
          <dicts-query
            v-model="form.stype"
            :type-code="'hr_train_organization-stype'"
          />
        </el-form-item>
        <el-form-item
          prop="address"
          label="机构地址"
        >
          <el-input
            v-model="form.address"
            clearable
            maxlength="255"
          />
        </el-form-item>
        <el-form-item
          prop="user"
          label="联系人"
        >
          <el-input
            v-model="form.user"
            clearable
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="tel"
          label="联系电话"
        >
          <el-input
            v-model="form.tel"
            clearable
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="introduction"
          label="机构简介"
        >
          <el-input
            v-model="form.introduction"
            type="textarea"
            clearable
            maxlength="1020"
            rows="4"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            clearable
            type="textarea"
            maxlength="255"
            rows="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        @click="save"
      >
        提交
      </el-button>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #name="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail"
        >
          {{ scope.row.name }}23
        </el-link>
      </template>
    </SyCopyDialog>
    <Detail ref="refDetail" />
  </sc-dialog>
</template>
<script>
import {
	defineComponent,
	getCurrentInstance,
	nextTick,
	reactive,
	toRefs,
} from 'vue'
import {resetNum} from "@/utils/validate";
import Detail from "@/views/hr/train/source/organization/detail";
export default defineComponent({
	name: "SelectTrainOrganizationEdit",
	components: {Detail},
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			fields: [],
			triggerDom: {},
			//加载动画
			loading: true,
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			cacheFields: {},
			rules: {
				name: [{required: true, message: '必填'}],
				address: [{required: true, message: '必填'}],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.attendanceShift.getList,
				//弹窗标题
				title: '复制培训机构信息',
				//表格显示列
				columns: [
					{
						label: '机构名称',
						prop: 'name',
						width: 180,
						sortable: "custom",
						fixed: 'left',
					},
					{
						label: '机构类型',
						prop: 'stype',
						width: 110,
						sortable: "custom",
					},
					{
						label: '机构地址',
						prop: 'address',
						width: 180,
						sortable: "custom",
					},
					{
						label: '联系人',
						prop: 'user',
						width: 110,
						sortable: "custom",
					},
					{
						label: '联系电话',
						prop: 'tel',
						width: 120,
						sortable: "custom",
					},
					{
						label: '机构简介',
						prop: 'introduction',
						width: 180,
						sortable: "custom",
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: "custom",
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: "custom",
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: "custom",
					},
				],
				searchConfig: {
					itemList: [
						{
							label: '机构名称',
							span: 8,
							prop: 'name',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '机构类型',
							span: 8,
							prop: 'stype',
							default: true,
							type: 'selectDicts',
							typeCode: 'hr_train_organization-stype'
						},
						{
							label: '机构地址',
							span: 8,
							prop: 'address',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '联系人',
							span: 8,
							prop: 'user',
							type: 'input',
							max: 255,
						},
						{
							label: '联系电话',
							span: 8,
							prop: 'tel',
							type: 'input',
							max: 255,
						},
						{
							label: '机构简介',
							span: 8,
							prop: 'introduction',
							type: 'input',
							max: 255,
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},

			}
		})
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) === '{}') {
				state.title = '新增'
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title = '编辑'
				//根据id查询
				const res = await proxy.$API.attendanceShift.detail.get(row.id)
				if (res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
				}
			}
			state.loading = false
		}
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}
		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({}, data)
			//删除id
			delete state.form.id
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate((valid) => {
				if (valid) {
					proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, async () => {
						state.subLoading = true
						let res
						if (state.form.id) { //修改
							res = await proxy.$API.attendanceShift.update.put(state.form)
						} else { //新增
							res = await proxy.$API.attendanceShift.insert.post(state.form)
						}
						if (res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					})
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if (!isClose) {
				state.form = Object.assign({}, orgForm)
			}
		}
		const handleNumInput = (val, prop) => {
			if (val) {
				state.form[prop] = resetNum(val)
			}
		}

		//详情
		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		return {
			...toRefs(state),
			handleDetail,
			handleNumInput,
			copyData,
			setFormData,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
		};
	},
})
</script>
