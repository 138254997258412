import config from "@/config"
import http from "@/utils/request"
/*角色管理*/
export default {
	getList: {
		url: `${config.API_URL}/cfg/system/dict/getList`,
		name: "分页角色",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getMenuList: {
		url: `${config.API_URL}/sys/base/dictionary/getMenuList`,
		name: "查询菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/sys/base/dictionary/getAllList`,
		name: "查询所有类型",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/system/dict/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.get(this.url + '?ids=' + data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/system/dict/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/dict/insert`,
		name: "批量新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insertObj: {
		url: `${config.API_URL}/cfg/system/dict/insertObj`,
		name: "单条新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/system/dict/update`,
		name: "修改",
		post: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	getListByTypeCode: {
		url: `${config.API_URL}/cfg/system/dict/getListByTypeCode`,
		name: "根据typeCode查询",
		get: async function(params){
			return await http.get(this.url + '?typeCode=' + params);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/system/dict/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	checkCode: {
		url: `${config.API_URL}/cfg/system/dict/CheckOptionValue`,
		name: "编码校验",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
