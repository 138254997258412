
import config from "@/config"
import http from "@/utils/request"

export default {
	getUserList: {
		url: `${config.API_URL}/cfg/organize/user/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getUsers: {
		url: `${config.API_URL}/cfg/organize/user/getUsers`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/organize/user/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getUserById: {
		url: `${config.API_URL}/cfg/organize/user/getById/`,
		name: "id查询",
		get: async function(id={}){
			return await http.get(this.url + id);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/organize/user/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	doCheckPhone: {
		url: `${config.API_URL}/cfg/organize/user/checkPhone`,
		name: "电话号码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	deleteUser: {
		url: `${config.API_URL}/cfg/organize/user/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insertUser: {
		url: `${config.API_URL}/cfg/organize/user/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	updateUser: {
		url: `${config.API_URL}/cfg/organize/user/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	updateInfo: {
		url: `${config.API_URL}/cfg/organize/user/updateInfo`,
		name: "修改个人信息",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detailUser: {
		url: `${config.API_URL}/cfg/organize/user/detail`,
		name: "查询单条数据,用户管理的详情页使用",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	userDetail: {
		url: `${config.API_URL}/cfg/organize/user/userDetail`,
		name: "查询单条数据,除用户管理外的详情页使用",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	updateFlagUser0: {
		url: `${config.API_URL}/cfg/organize/user/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagUser1: {
		url: `${config.API_URL}/cfg/organize/user/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateJobStatus : {
		url: `${config.API_URL}/cfg/organize/user/updateJobStatus`,
		name: "修改转正状态",
		get: async function(ids,jobStatus){
			return await http.get(this.url + '?ids=' + ids + '&jobStatus=' + jobStatus);
		}
	},

	importExcel: {
		url: `${config.API_URL}/cfg/organize/user/importExcel`,
		name: "导入Excel",
		post: async function (data = {},config) {
			return await http.post(this.url, data,config);
		}
	},
	exportExcel: {
		url: `${config.API_URL}/cfg/organize/user/exportExcel`,
		name: "导出Excel",
		get: async function (params) {
			return await http.post(this.url, data);
		}
	},
	ChangePassword:{
		url: `${config.API_URL}/cfg/organize/user/ChangePassword`,
		name: "修改密码",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	setUserPackageId: {
		url: `${config.API_URL}/cfg/organize/user/setUserPackageId`,
		name: "给用户设置工资账套id",
		get: async function(packAgeId,userId){
			return await http.get(this.url + '?packAgeId=' + packAgeId + '&userId=' + userId);
		}
	},
}
