<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
    :width="width"
    :bef-close="false"
  >
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      class="column3"
    >
      <el-descriptions-item
        label="车牌号"
        :label-align="labelAlign"
      >
        {{ data.license }}
      </el-descriptions-item>
      <el-descriptions-item
        label="物流公司"
        :label-align="labelAlign"
      >
        <el-link
          type="primary"
          @click="handleDetail(data, 'refLogisticsDetail', 'logisticsId')"
        >
          {{ data.fz_logisticsName }}
        </el-link>
      </el-descriptions-item>
      <el-descriptions-item
        label="发动机号"
        :label-align="labelAlign"
      >
        {{ data.engine }}
      </el-descriptions-item>

      <el-descriptions-item
        label="品牌"
        :label-align="labelAlign"
      >
        {{ data.brand }}
      </el-descriptions-item>
      <el-descriptions-item
        label="型号"
        :label-align="labelAlign"
      >
        {{ data.model }}
      </el-descriptions-item>
      <el-descriptions-item
        label="所有人"
        :label-align="labelAlign"
      >
        {{ data.owner }}
      </el-descriptions-item>
      <el-descriptions-item
        label="使用性质"
        :label-align="labelAlign"
      >
        {{ data.useNature }}
      </el-descriptions-item>
      <el-descriptions-item
        label="车辆性质"
        :label-align="labelAlign"
      >
        {{ data.vehicleType }}
      </el-descriptions-item>
      <el-descriptions-item
        label="核定载重(KG)"
        :label-align="labelAlign"
      >
        {{ data.checkQuality }}
      </el-descriptions-item>
      <el-descriptions-item
        label="装载长度(米)"
        :label-align="labelAlign"
      >
        {{ data.loadingLength }}
      </el-descriptions-item>
      <el-descriptions-item
        label="装载宽度(米)"
        :label-align="labelAlign"
      >
        {{ data.loadingWidth }}
      </el-descriptions-item>
      <el-descriptions-item
        label="装载高度(米)"
        :label-align="labelAlign"
      >
        {{ data.loadingHigh }}
      </el-descriptions-item>
      <el-descriptions-item
        label="注册日期"
        :label-align="labelAlign"
      >
        {{ data.registerDate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="年检日期"
        :label-align="labelAlign"
      >
        {{ data.verificationDate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="年检有效期"
        :label-align="labelAlign"
      >
        {{ data.verificationValidate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="状态"
        :label-align="labelAlign"
      >
        <el-tag
          v-if="data.flag === 0"
          type="danger"
          :size="$store.state.global.uiSize"
        >
          停用
        </el-tag>
        <el-tag
          v-if="data.flag === 1"
          type="success"
          :size="$store.state.global.uiSize"
        >
          启用
        </el-tag>
        <el-tag
          v-if="data.flag === 2"
          type="info"
          :size="$store.state.global.uiSize"
        >
          报废
        </el-tag>
        <el-tag
          v-if="data.flag === 3"
          type="info"
          :size="$store.state.global.uiSize"
        >
          封存
        </el-tag>
        <el-tag
          v-if="data.flag === 4"
          type="warning"
          :size="$store.state.global.uiSize"
        >
          借出
        </el-tag>
        <el-tag
          v-if="data.flag === 5"
          type="warning"
          :size="$store.state.global.uiSize"
        >
          转让
        </el-tag>
        <el-tag
          v-if="data.flag === 6"
          type="warning"
          :size="$store.state.global.uiSize"
        >
          维修
        </el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="备注"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        {{ data.remark }}
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="图片"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        <div class="mtf_5">
          <el-image
            v-for="(img, index) in imgList"
            :key="index"
            fit="cover"
            class="imgViewItem"
            :preview-src-list="imgPreviewList"
            :initial-index="index"
            hide-on-click-modal
            :z-index="9999"
            :src="$CONFIG.API_URL + '/'+'api/' +img.url"
          />
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="附件"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        <div class="mtf_5">
          <el-tag
            v-for="(file, index) in fileList"
            :key="index"
            class="dtFile"
            @click="previewFile(file)"
          >
            <a href="javascript:">{{ file.name }}</a>
          </el-tag>
        </div>
      </el-descriptions-item>
    </el-descriptions>

<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
    <LogisticsDetail ref="refLogisticsDetail" />
  </sc-dialog>
</template>

<script>
	import {
		defineComponent,
		toRefs,
		reactive,
		getCurrentInstance
	} from 'vue'
	import LogisticsDetail from '@/views/cfg/data/logistics/detail'
	export default defineComponent({
		components: { LogisticsDetail },
		setup() {
			//获取当前实例对象
			const {proxy} = getCurrentInstance()
			const state = reactive({
				//弹窗宽度
				width: '70%',
        type:"detail",
				//加载动画
				loading: true,
				//标题对齐方式 'left' 'right' 'center'
				labelAlign: 'right',
				imgList: [],
				imgPreviewList: [],
				//尺寸大小
				size: 'small',
				//控制弹窗显示隐藏
				visible: false,
				//弹窗标题
				title: '车辆详情',
				data: {},
				//详情数据对象
				deswidth: '25%',
				fileList: [],
			})

			//显示弹窗
			const showDialog = async (data) => {
				state.fileList = []
				state.imgList = []
				state.imgPreviewList = []
				state.visible = true
				if (data.id) {
					//根据id查询
					const res = await proxy.$API.logisticsVehicle.detail.get(data.id)
					if (res.code === 200) {
						state.data = res.data
						getFileList(res.data.files)
						getImgList(res.data.images)
					}
				}
				state.loading = false
			}

			const getImgList = (images) => {
				let arr = []
				if (images) {
					arr = images.split(',')
					if (arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							obj['url'] = item
							state.imgList.push(obj)
							state.imgPreviewList.push(proxy.$CONFIG.API_URL + '/api/' +item)
						})
					}
				}
			}

			const getFileList = (data) => {
				let arr = []
				if (data) {
					arr = data.split(',')
					if (arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							const iarr = item.split('/')
							obj['url'] = item
							obj['name'] = iarr[iarr.length - 1]
							state.fileList.push(obj)
						})
					}
				}
			}

			const previewFile = async (file) => {
				window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
				await proxy.$API.files.insertFilesDownload.post(file)
			}

			//隐藏弹窗
			const hideDialog = () => {
				state.visible = false
			}

			const handleDetail = (row, ref, field) => {
				const params = Object.assign({}, row)
				if(field){
					params.id = row[field]
				}
				proxy.$refs[ref].showDialog(params)
			}

			return {
				...toRefs(state),
				handleDetail,
				showDialog,
				previewFile,
				hideDialog,
			}
		},
	})
</script>
