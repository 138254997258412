<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<!--label: 标题 -->
				<el-descriptions-item
					label="工艺名称"
					label-align="right"
				>
					{{ data.name }}
				</el-descriptions-item>
				<el-descriptions-item
					label="工艺编码"
					label-align="right"
				>
					{{ data.code }}
				</el-descriptions-item>
				<el-descriptions-item
					label="产品名称"
					label-align="right"
				>
					{{ data.fz_goodsName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="工序要求"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.requirement }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="工序步骤"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
			>
				<template #ipqc="{ scope }">
					<span v-if="scope.row.ipqc === 1">是</span>
					<span v-else-if="scope.row.ipqc === 0">否</span>
				</template>
				<template #flag="{ scope }">
					<el-switch
						v-model="scope.row.flag"
						:size="$store.state.global.uiSize"
					/>
				</template>
			</scTable>
		</el-card>
		<!--    <template #footer>-->
		<!--      <el-button-->
		<!--        :size="$store.state.global.uiSize"-->
		<!--        @click="visible = false"-->
		<!--      >-->
		<!--        关 闭-->
		<!--      </el-button>-->
		<!--    </template>-->
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from "vue";

export default defineComponent({
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//弹窗宽度
			width: "60%",
			type: "detail",
			//高度
			height: "500px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "工艺路线详情",
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: "加工顺序",
						prop: "sequence",
						sortable: "custom",
						fixed: "left",
						width: 110,
					},
					{
						label: "工序名称",
						prop: "fz_procedureName",
						sortable: "custom",
						fixed: "left",
						width: 150,
					},
					{
						label: "工序要求",
						prop: "requirement",
						width: 180,
						sortable: "custom",
					},
					{
						label: "生产车间",
						prop: "fz_workshopName",
						width: 150,
						sortable: "custom",
					},
					{
						label: "工作组",
						prop: "workgroupName",
						width: 150,
						sortable: "custom",
					},
					{
						label: "生产人员",
						prop: "fz_managerName",
						width: 110,
						sortable: "custom",
					},
					{
						label: "设备名称",
						prop: "fz_deviceName",
						width: 150,
						sortable: "custom",
					},
					{
						label: "备注",
						prop: "remark",
						width: 180,
						sortable: "custom",
					},
				],
			},
		});

		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = [];
			state.visible = true;
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.produceCraftRoute.detail.get(data.id);
				if (res.code === 200) {
					state.data = res.data;
					//获取明细表数据
					getGoodsList(res.data.id);
					//组装文件列表
					getFileList(res.data.files);
				}
			}
			state.loading = false;
		};

		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.apiObj = proxy.$API.produceCraftRouteProcedure.selectRouteProcedure;
				state.tableSonConfig.params = {routeId: id};
				proxy.$refs.tableSon.reload({routeId: id});
			}
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
			await proxy.$API.files.insertFilesDownload.post(file);
		};

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};
		return {
			...toRefs(state),
			showDialog,
			hideDialog,
			previewFile,
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

