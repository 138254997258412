<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="width"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
    :type="type"
  >
    <div class="syForm">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="parentId"
          label="父级"
        >
          <el-input
            v-model="form.parentName"
            readonly
          />
        </el-form-item>
        <el-form-item
          prop="name"
          label="分类名称"
        >
          <el-input
            v-model="form.name"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        @click="save"
      >
        提交
      </el-button>
      <el-popconfirm
        :title="$TIPS.CONFIRM_CLOSE"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button :size="$store.state.global.uiSize">
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button :size="$store.state.global.uiSize">-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
export default defineComponent({
	name: "AchivesDirEdit",
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      type:'detail',
			//编码验证消息
			codeMsg: '',
			//加载动画
			loading: false,
			//触发元素
			triggerDom: {},
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				code: [
					{required: true, trigger: 'blur', message: '必填'},
					{validator: validateCode, trigger: 'blur'},
				],
				name: [
					{required: true, trigger: 'blur', message: '必填'},
				],
			},
		})
		const showDialog = async (row) => {
			state.visible = true
			nextTick(async () => {
				if (JSON.stringify(row) == '{}') { //新增
					state.title="新增"
					state.orgForm = Object.assign({}, state.form)
				} else { //编辑
					if(row.id){
						state.title="编辑"
					}else{
						state.title="新增子级"
					}
					state.form = Object.assign({}, row)
					state.orgForm = Object.assign({}, row)
					await getParentNode(row.parentId)
				}
			})
		}

		//获取父级节点信息
		const getParentNode = async (id) => {
			const res = await proxy.$API.ArchiveDirectory.detail.get(id)
			if(res.code === 200){
				state.form.parentId = res.data.id
				state.form.parentName = res.data.name
				state.orgForm.parentId = res.data.id
				state.orgForm.parentName = res.data.name
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//关闭弹窗前确认
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}

		//确定关闭
		const handleConfirm = () => {
			hideDialog()
		}

		//确定按钮
		const save = () => {
			state['formRef'].validate((valid) => {
				if(valid) {
					proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res = {}
						if(state.form.id){
							res = await proxy.$API.ArchiveDirectory.update.put(state.form)
						}else{
							res = await proxy.$API.ArchiveDirectory.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					})
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}
		return {
			...toRefs(state),
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm
		}
	},
})
</script>

<style scoped>

</style>
