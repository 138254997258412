<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="width"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="date"
          label="签订日期"
        >
          <el-date-picker
            v-model="form.date"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="name"
          label="项目名称"
        >
          <el-input
            v-model="form.name"
            maxlength="255"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="customer"
          label="客户名称"
        >
          <el-input
            v-model="form.customer"
            maxlength="255"
            clearable
          />
        </el-form-item>
        <el-form-item
          label="经办人"
          prop="fz_agentName"
        >
            <div class="disflex fullWidth sc-table-select--append">
              <div class="flexgs1">
                <sc-table-select
                    v-model="form.fz_agentName"
                    customerValue
                    fullWidth
                    :api-obj="$API.user.getListByKeyword"
                    :params="{
                        flag: 1,
                      }"
                    :height="426"
                    :props="{ label: 'name', value: 'id', keyword: 'name' }"
                    @change="(val) => handleSelChange(val, ['agentId','fz_agentName'])"
                    @clear="inputClear('agent')"
                >
                  <el-table-column
                      prop="name"
                      label="姓名"
                      width="150"/>
                  <el-table-column
                      prop="fz_departmentName"
                      label="部门"
                      width="150"/>
                  <el-table-column
                      prop="code"
                      label="工号"
                      width="150"/>
            <template #append>
              <el-icon
                @click="popSelect('refAgentIput',['agentId','fz_agentName'], 1, 'refSelectUser')"
              >
                <el-icon-search />
              </el-icon>
            </template>
                </sc-table-select>
              </div>
            </div>
        </el-form-item>
        <el-form-item
          prop="stype"
          label="项目类型"
        >
          <dicts-query
            v-model="form.stype"
            :type-code="'oa_project-stype'"
          />
        </el-form-item>
        <el-form-item
          prop="amount"
          label="项目金额"
        >
          <el-input
            v-model="form.amount"
            clearable
            maxlength="24"
            @input="(val) => handleNumInput(val, 'amount')"
          >
            <template #suffix>
              元
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            maxlength="255"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="receipt"
          label="实际收款"
        >
          <el-input
            v-model="form.receipt"
            clearable
            maxlength="24"
            @input="(val) => handleNumInput(val, 'receipt')"
          >
            <template #suffix>
              元
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="commission"
          label="业务提成"
        >
          <el-input
            v-model="form.commission"
            clearable
            maxlength="24"
            @input="(val) => handleNumInput(val, 'commission')"
          >
            <template #suffix>
              元
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button :size="$store.state.global.uiSize">
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
    >
      <template #date="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row)"
        >
          {{ scope.row.date }}123
        </el-link>
      </template>
      <template #isCheck="{ scope }">
        {{ scope.row.isCheck === 1 ? '是' : '否' }}
      </template>
      <template #isGrant="{ scope }">
        {{ scope.row.isGrant === 1 ? '是' : '否' }}
      </template>
    </SyCopyDialog>
    <!--选择用户-->
    <SelectUser
      ref="refSelectUser"
      @set-data="setSelectData"
    />
    <!--选择往来单位-->
    <SelectCustomer
      ref="refSelectCustomer"
      @set-data="setSelectData"
    />
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetNum } from "@/utils/validate";
import {compareDate, getDays, getNowDate} from '@/utils/date'
import Detail from './detail'
export default defineComponent({
	name: "OaProjectEdit",
	components: { Detail },
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const user = proxy.$TOOL.data.get('USER_INFO')
		const state = reactive({
			multiples: false,
			codeMsg: '',
			loading: false,
			triggerDom: {},
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增项目',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				customer: [{required: true, trigger: 'change', message: '必填'}],
				fz_agentName: [{required: true, trigger: 'change', message: '必填'}],
				date: [{required: true, message: '必填'}],
				name: [{required: true, message: '必填'}],
			},
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.projectProject.getList,
				//弹窗标题
				title:'复制项目信息',
				//表格显示列
				columns: [
					{
						label: '签订日期',
						prop: 'date',
						width: 160,
						sortable: true,
						fixed: 'left'
					},
					{
						label: '项目名称',
						prop: 'name',
						width: 170,
						sortable: true,
					},
					{
						label: '客户名称',
						prop: 'customer',
						width: 180,
						sortable: true,
					},
					{
						label: '经办人',
						prop: 'fz_agentName',
						width: 100,
						sortable: true,
					},
					{
						label: '项目类型',
						prop: 'stype',
						width: 120,
						sortable: true,
					},
					{
						label: '项目金额(元)',
						prop: 'amount',
						width: 140,
						sortable: true,
					},
					{
						label: '备注',
						prop: 'remark',
						width: 150,
						sortable: true,
					},
					{
						label: '是否验收',
						prop: 'isCheck',
						width: 120,
						sortable: true,
					},
					{
						label: '验收日期',
						prop: 'checkDate',
						width: 140,
						sortable: true,
					},
					{
						label: '实际收款(元)',
						prop: 'receipt',
						width: 140,
						sortable: true,
					},
					{
						label: '业务提成(元)',
						prop: 'commission',
						width: 140,
						sortable: true,
					},
					{
						label: '是否发放',
						prop: 'isGrant',
						width: 120,
						sortable: true,
					},
					{
						label: '发放日期',
						prop: 'grantDate',
						width: 210,
						sortable: true,
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: true,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 210,
						sortable: true,
					},
				],
				searchConfig: {
					//表单项配置
					itemList: [
						{
							label: '签订日期',
							span: 8,
							default: true,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_date0',
								},
								{
									prop: 'fz_date1',
								},
							],
						},
						{
							label: '项目名称',
							span: 8,
							prop: 'name',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '客户名称',
							span: 8,
							prop: 'customer',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '经办人',
							span: 8,
							prop: 'fz_agentName',
							type: 'popUser',
							fields: ['agentId', 'fz_agentName']
						},
						{
							label: '项目类型',
							span: 8,
							prop: 'stype',
							type: 'selectDicts',
							typeCode: 'oa_project-stype'
						},
						{
							label: '项目金额',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_amount0',
									append: '元',
									max: 24,
								},
								{
									prop: 'fz_amount1',
									append: '元',
									max: 24,
								},
							]
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '是否验收',
							span: 8,
							prop: 'isCheck',
							type: 'select',
							data: [
								{label: '否', value: 0},
								{label: '是', value: 1},
							]
						},
						{
							label: '验收日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_checkDate0',
								},
								{
									prop: 'fz_checkDate1',
								},
							],
						},
						{
							label: '实际收款',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_receipt0',
									append: '元',
									max: 24,
								},
								{
									prop: 'fz_receipt1',
									append: '元',
									max: 24,
								},
							]
						},
						{
							label: '业务提成',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_commission0',
									append: '元',
									max: 24,
								},
								{
									prop: 'fz_commission1',
									append: '元',
									max: 24,
								},
							]
						},
						{
							label: '是否发放',
							span: 8,
							prop: 'isGrant',
							type: 'select',
							data: [
								{label: '否', value: 0},
								{label: '是', value: 1},
							]
						},
						{
							label: '发放日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_grantDate0',
								},
								{
									prop: 'fz_grantDate1',
								},
							],
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			}
		})

		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) === '{}') {
				state.title = '新增项目信息'
				state.form.agentId = user.id
				state.form.fz_agentName = user.name
				state.form.date = getNowDate()
				state.form.isCheck = 0
				state.form.isGrant = 0
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title = '编辑项目信息'
				//根据id查询
				const res = await proxy.$API.projectProject.detail.get(row.id)
				if (res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
				}
			}
			state.loading = false
		}

		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
    //关闭确认
    const handleConfirm = () => {
      save()
      state.visibles = false
    }
    const valiForm = () => {
      nextTick(()=>{
        state.formRef.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		//确定按钮
		const save = () => {
			state['formRef'].validate(async (valid) => {
				if(valid) {
						state.subLoading = true
						let res
						if(state.form.id){
							res = await proxy.$API.projectProject.update.put(state.form)
						}else{
							res = await proxy.$API.projectProject.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//只能输入数字小数符数
		const handleNumInput = (val, field) => {
			if(val){
				state.form[field] = resetNum(val)
			}
		}

		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id
			delete state.form.id
		}

		//输入框失去焦点
		const inputBlur = (field) => {
			if(state.cacheFields) {
				state.form['fz_' + field + 'Name'] = state.cacheFields['fz_' + field + 'Name']
			}
		}

		//输入框清除
		const inputClear = (field) => {
			state.form[field] = ''
			state.form['fz_' + field + 'Id'] = ''
			state.form['fz_' + field + 'Name'] = ''
			state.cacheFields['fz_' + field + 'Name'] = ''
		}

		//打开选择弹窗
		//参数(ref触发元素，fields赋值字段，type类型区分多选单选，pop弹窗ref对象)
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if (type === 1) { //单选
					state.multiples = false
				} else { //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if (state.fields.length > 0) {
				if (data.length) { //多选
					const ids = data.map((item) => {
						return item.id
					}).join()
					const names = data.map((item) => {
						return item.name
					}).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				} else { //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}


		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}
    //下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
    const handleSelChange =async (data, field) => {
      console.log(data)
      if (data.length > -1) { //多选
        let ids = ''
        if (data.length > 0) {
          //取得选中的id串
          ids = data.map((item) => item.id).join()
        } else {
          ids = ''
        }
        state.form[field] = ids
      } else { //单选
        let item = field[0]
        if (data && data.id) {
          //将选择的数据id赋值给指定的form中的属性 例：groupId
          state.form[field[0]] = data.id
        }
      }
    }
		return {
			...toRefs(state),
			setSelectData,
			inputBlur,
			inputClear,
			popSelect,
			setFormData,
			copyData,
			handleNumInput,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			handleDetail,
      handleSelChange,
      valiForm

		}
	},
})
</script>
