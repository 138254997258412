import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/erp/purchase/apply/getList`,
		name: "获取所有采购申请单",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	goodsGetList: {
		url: `${config.API_URL}/mobile/erp/purchase/applyGoods/getList`,
		name: "获取采购申请单物品详情",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	applyDetail: {
		url: `${config.API_URL}/mobile/erp/purchase/apply/detail`,
		name: "获取采购申请单详情",
		get: async function (id) {
			return await http.get(this.url + '?id='+ id);
		}
	},
	delApply: {
		url: `${config.API_URL}/mobile/erp/purchase/apply/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insertApply: {
		url: `${config.API_URL}/mobile/erp/purchase/apply/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	updateApply: {
		url: `${config.API_URL}/mobile/erp/purchase/apply/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	}
}
