<template>
	<sc-dialog
		v-model="visible"
		:title="config.title || '复制信息'"
		:loading="loading"
		:width="config.width || '90%'"
		:type="type"
		:height="config.height || '450px'"
		:bef-close="false"
		:trigger-dom="triggerDom"
	>
		<el-container v-if="dataGoods">
			<el-main class="nopadding">
				<splitpanes class="tabMain">
					<pane
						v-show="leftShow"
						min-size="20"
						max-size="30"
						:size="paneSize"
					>
						<!--树结构组件 :params="{ businessType: 'cfg_OA_ARCHIVE'}" 查询参数 -->
						<SyGoodsDir
							:api-obj="$API.classify.getList"
							hide-pagination
							:params="{}"
							@fetch-data-node="fetchDataNode"
						/>
					</pane>
					<pane :size="leftShow === false ? 100 : 80">
						<!--公用切换按钮，点击切换显示左侧栏
						text:显示文字
						opened:绑定左侧栏显示状态
						show-left:回调函数
						-->
						<sy-switch-btn
							:text="'分类'"
							:opened="leftShow"
							:menu="$MENU.code.DATA_CLASSIFY"
							@show-left="handleShowLeft"
						/>
						<el-container
							direction="vertical"
							class="mainPanel"
						>
							<div>
								<template v-if="config.searchConfig">
									<sy-search-Form
										v-show="!hideForm"
										ref="refFormBar"
										:configs="config.searchConfig || {}"
										:show-item="showItem"
										@fetch-data="fetchData"
										@set-show="handleFitShow"
									/>
									<el-header
										class="tableTopBar"
									>
										<div class="left-panel"></div>
										<div class="right-panel">
											<!--切换显示搜索栏hide-after: 延迟隐藏-->
											<el-tooltip
												:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
												placement="bottom"
												:hide-after="0"
											>
												<el-link
													@click="handleShowSearch"
												>
													搜索
													<el-icon>
														<template v-if="hideForm">
															<el-icon-arrowDown/>
														</template>
														<template v-else>
															<el-icon-arrowUp/>
														</template>
													</el-icon>
												</el-link>
											</el-tooltip>
										</div>
									</el-header>
								</template>
							</div>
							<el-main class="nopadding">
								<scTable
									ref="table"
									:api-obj="config.apiObj"
									:column="config.columns"
									:params="config.params"
									row-key="id"
									border
									highlight-current-row
									remote-sort
									default-expand-all
									:tree-props="{ children: 'children' }"
									:ref-query-form="refFormBar"
									remote-filter
									:hide-pagination="hidePagination"
									hide-slot
									@row-dblclick="handleDbclick"
									@current-change="currentChange"
								>
									<template #column="{ scope, item }">
										<slot
											:name="item.prop"
											:scope="scope"
											:item="item"
										>
											<span>{{ scope.row[item.prop] }}</span>
										</slot>
									</template>
								</scTable>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>

		<el-container v-else>
			<el-main class="nopadding">
				<el-container
					direction="vertical"
					class="mainPanel"
				>
					<div>
						<template v-if="config.searchConfig">
							<sy-search-Form
								v-show="!hideForm"
								ref="refFormBar"
								:configs="config.searchConfig || {}"
								:show-item="showItem"
								@fetch-data="fetchData"
								@set-show="handleFitShow"
							/>
							<el-header
								class="tableTopBar"
							>
								<div class="left-panel"></div>
								<div class="right-panel">
									<!--切换显示搜索栏hide-after: 延迟隐藏-->
									<el-tooltip
										:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
										placement="bottom"
										:hide-after="0"
									>
										<el-link
											@click="handleShowSearch"
										>
											搜索
											<el-icon>
												<template v-if="hideForm">
													<el-icon-arrowDown/>
												</template>
												<template v-else>
													<el-icon-arrowUp/>
												</template>
											</el-icon>
										</el-link>
									</el-tooltip>
								</div>
							</el-header>
						</template>
					</div>
					<el-main class="nopadding">
						<scTable
							ref="table"
							:api-obj="config.apiObj"
							:column="config.columns"
							:params="config.params"
							row-key="id"
							border
							highlight-current-row
							remote-sort
							:ref-query-form="refFormBar"
							remote-filter
							:hide-pagination="hidePagination"
							hide-slot
							@row-dblclick="handleDbclick"
							@current-change="currentChange"
							@data-change="dataChange"
						>
							<template #column="{ scope, item }">
								<slot
									:name="item.prop"
									:scope="scope"
									:item="item"
								>
									<span>{{ scope.row[item.prop] }}</span>
								</slot>
							</template>
						</scTable>
					</el-main>
				</el-container>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				type="primary"
				:size="$store.state.global.uiSize"
				:disabled="isDisable"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<!--      <el-button-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        @click="visible = false"-->
			<!--      >-->
			<!--        关闭-->
			<!--      </el-button>-->
		</template>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from "vue";
import {resetZnum} from "@/utils/validate";

export default defineComponent({
	props: {
		config: {
			type: Object, default: () => {
			}
		},
		searchConfig: {
			type: Object, default: () => {
			}
		},
		dataGoods: {type: Boolean, default: false},
		hidePagination: {type: Boolean, default: false},
	},
	emits: ["set-form", "data-change"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			refFormBar: null,
			type: "detail",
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//隐藏搜索
			hideForm: true,
			form: {},
			//窗格大小
			paneSize: 25,
			paneSize1: 30,
			leftShow: true,
			//传入赋值字段
			param: {},
			//触发元素
			triggerDom: null,
			//按钮diable
			isDisable: true,
			//当前弹窗组件
			refScDialog: null,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: "65%",
			//弹窗标题
			title: "复制",
			//表格对象
			table: null,
			//暂存选中行
			curRow: {},
		});

		//显示弹窗
		const showDialog = (row) => {
			state.visible = true;
			state.loading = false;
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//点击确定按钮
		const handleConfirm = () => {
			if (state.curRow) { //有选中行
				emit("set-form", state.curRow);
				hideDialog();
			} else {
				proxy.$baseMessage("点击选中一行数据", "error");
			}
		};

		//双击行
		const handleDbclick = (row) => {
			if (row) {
				emit("set-form", row);
				hideDialog();
			}
		};

		//输入框清楚按钮点击
		const handleClear = () => {
			//刷新表格
			state.table.reload();
		};

		const fetchDataLeft = (data) => {
			if (data) {
				state.param = Object.assign({}, data);
			}
			if (state.curNode) {
				state.param["directory"] = state.curNode.coordinate;
			}
			state["table"].reload(state.param);
		};

		const fetchDataNode = (node) => {
			if (node) {
				state.curNode = Object.assign({}, node);
				fetchDataLeft();
			}
		};

		//分栏拖动结束
		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[0].size;
			}
		};

		const fetchData = (data) => {
			if (!data) {
				data = {};
			}
			if (props.config.params) {
				Object.assign(data, props.config.params);
			}
			state.table.reload(data);
		};

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool;
		};

		//选中行变化
		const currentChange = (curRow) => {
			state.curRow = curRow;
			if (state.curRow) {
				state.isDisable = false;
			} else {
				state.isDisable = true;
			}
		};

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool;
		};

		//切换显示搜索栏
		const handleShowSearch = () => {
			state.hideForm = !state.hideForm;
		};

		const dataChange = (res) => {
			emit("data-change", res);
		};
		return {
			...toRefs(state),
			dataChange,
			fetchDataLeft,
			fetchDataNode,
			handleResized,
			handleShowLeft,
			handleFitShow,
			handleShowSearch,
			fetchData,
			currentChange,
			handleDbclick,
			handleClear,
			showDialog,
			hideDialog,
			handleConfirm
		};
	},
});
</script>
