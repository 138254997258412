import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/refund/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/sale/refund/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/sale/refund/insert`,
		name: "新增销售订单",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/sale/refund/update`,
		name: "修改销售订单",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/sale/refund/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/sale/refund/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	editStatus: {
		url: `${config.API_URL}/erp/sale/refund/editStatus`,
		name: "查看是否可以修改",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	termination: {
		url: `${config.API_URL}/erp/sale/refund/termination`,
		name: "终止",
		get: async function(id,reason){
			return await http.get(this.url + '?id=' + id + '&reason=' + reason);
		}
	},
	queryByCustomerId: {
		url: `${config.API_URL}/erp/sale/refund/queryByCustomerId`,
		name: "根据商户id查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateProcessInfo: {
		url: `${config.API_URL}/erp/sale/refund/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/sale/refund/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	returnProcess: {
		url: `${config.API_URL}/erp/sale/refund/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?ids=' + id);
		}
	},

}
