import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/getAllList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	importExcel: {
		url: `${config.API_URL}/fms/cashier/cashierReconciliation/importExcel`,
		name: "导入",
		post: async function (data={},config) {
			return await http.post(this.url, data,config);
		}
	},
}
