import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/fms/assets/workload/getList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    getAllList: {
        url: `${config.API_URL}/fms/assets/workload/getAllList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    updateList: {
        url: `${config.API_URL}/fms/assets/workload/updateList`,
        name: "列表",
        get: async function (list) {
            return await http.put(this.url,list);
        }
    },
    delete: {
        url: `${config.API_URL}/fms/assets/workload/delete`,
        name: "删除",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },
    del: {
        url: `${config.API_URL}/fms/assets/workload/del`,
        name: "查询",
        get: async function (params) {
            return await http.get(this.url + '?id=' + params);
        }
    },
    detail: {
        url: `${config.API_URL}/fms/assets/workload/detail`,
        name: "查询单条数据",
        get: async function (params) {
            return await http.get(this.url + '?id=' + params);
        }
    },
    insert: {
        url: `${config.API_URL}/fms/assets/workload/insert`,
        name: "新增",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    update: {
        url: `${config.API_URL}/fms/assets/workload/update`,
        name: "修改",
        put: async function (data = {}) {
            return await http.put(this.url, data);
        }
    },
    updateFlag: {
        url: `${config.API_URL}/fms/assets/workload/updateFlag`,
        name: "修改状态",
        put: async function (data = {}) {
            return await http.put(this.url + "?id=" + data);
        }
    },
}
