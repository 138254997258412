import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/data/subject/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/data/subject/delete`,
		name: "删除",
		post: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/data/subject/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/data/subject/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/data/subject/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagSubject0: {
		url: `${config.API_URL}/cfg/data/subject/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagSubject1: {
		url: `${config.API_URL}/cfg/data/subject/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	importExcel:{
		url: `${config.API_URL}/cfg/data/subject/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/data/subject/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getList`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	detailClassfy: {
		url: `${config.API_URL}/cfg/data/classify/detail`,
		name: "查询分类单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
}
