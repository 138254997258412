import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/erp/quality/submProductDetail/getList`,
        name: "产品送检单细明",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
	insert: {
		url: `${config.API_URL}/erp/quality/submProductDetail/insert`,
		name: "新增",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/quality/submProductDetail/update`,
		name: "编辑",
		get: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/quality/submProductDetail/detail`,
		name: "详情",
		get: async function (data) {
			return await http.get(this.url + '?id=' + data);
		}
	},

	delete: {
		url: `${config.API_URL}/erp/quality/submProductDetail/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/erp/quality/submProductDetail/updateFlag1`,
		name: "启用",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/erp/quality/submProductDetail/updateFlag0`,
		name: "停用",
		get: async function (data) {
			return await http.get(this.url + '?ids=' + data);
		}
	},
}
