import config from "@/config"
import http from "@/utils/request"

export default {
	insert: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/deliver/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/deliver/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/deliver/detail`,
		name: "查询列表数据",
		get: async function (invoiceId) {
			return await http.get(this.url + '?invoiceId=' + invoiceId );
		}
	},
}
