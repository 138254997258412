<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :width="400"
    :height="'400px'"
    :bef-close="false"
    :trigger-dom="triggerDom"
  >
    <el-container>
      <el-header class="autoHeight">
        <el-alert
          type="warning"
          class="alertTips"
          :closable="false"
        >
          选择需要导出的列
        </el-alert>
      </el-header>
      <el-main class="nopadding">
        <scTable
          ref="table"
          :data="tableConfig.data"
          :column="tableConfig.columns"
          row-key="id"
          checkbox
          border
          highlight-current-row
          hide-pagination
          hide-do
          @selection-change="selectChange"
        />
      </el-main>
    </el-container>

    <!--弹窗底部自定义-->
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        :disabled="selections.length < 1"
        @click="save"
      >
        确定导出
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="hideDialog"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { exportExcel } from '@/utils/export'
export default defineComponent({
	name: 'PublicExport',
	props: {
		url: { type: String, default: '' },
		fileName: { type: String, default: '' },
		mainId: { type: String, default: '' },
	},
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
      type:"detail",
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//搜索条件
			condition:{},
			//选中的数据
			selections: [],
			//弹窗标题
			title: '导出Excel',
			//导出参数
			data:[],
			//表格配置
			tableConfig: {
				data: [],
				columns: [
					{
						label: '列名',
						prop: 'label',
					}
				],
			},
		})
		//显示弹窗
		const showDialog = (column,form,condition) => {
			//条件
			state.condition = condition;
			//选中的数据
			state.data = form;
			console.log('export----------'+JSON.stringify(state.data))
			state.visible = true;
			if(column){
				state.tableConfig.data = column
				nextTick(() => {
					setCheck()
				})
			}
			state.loading = false
		}

		//设置选中
		const setCheck = () => {
			if(state.tableConfig.data && state.tableConfig.data.length > 0){
				state.tableConfig.data.forEach((item) => {
					proxy.$refs.table.toggleRowSelection(item, true)
				})
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}

		//提交
		const save = async () => {
      proxy.$LOADING.loading()
			state.subLoading = true
			let dataIds = [];
			if(state.data) {
				state.data.forEach((item) => {
					dataIds.push(item.id);
				})
			}
			let param = {}
			param[props.mainId] = dataIds
			//导出方法
			await exportExcel(proxy.$CONFIG.API_URL + props.url,{
				fileName: props.fileName,
				selections: state.selections,
				condition: state.condition,
				...param
			})
      proxy.$LOADING.closed()
			state.subLoading = false
		}

		//选中数据变化
		const selectChange = (val) => {
			state.selections = val
		}

		return {
			...toRefs(state),
			selectChange,
			save,
			showDialog,
			hideDialog,
			handleConfirm
		}
	},
})
</script>
