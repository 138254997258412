import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/sell/receipt/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/sell/receipt/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	journalInsert: {
		url: `${config.API_URL}/fms/sell/receipt/journalInsert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	voucherInsert: {
		url: `${config.API_URL}/fms/sell/receipt/voucherInsert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/sell/receipt/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/sell/receipt/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/sell/receipt/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateCollectStatus: {
		url: `${config.API_URL}/fms/sell/receipt/updateCollectStatus`,
		name: "确认收款",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	logisticsPayment: {
		url: `${config.API_URL}/fms/sell/receipt/logisticsPayment`,
		name: "确认收款",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	queryByCustomerId: {
		url: `${config.API_URL}/fms/sell/receipt/queryByCustomerId`,
		name: "根据商户id查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getSaleOrderListByKeyword: {
		url: `${config.API_URL}/fms/sell/receipt/getSaleOrderListByKeyword`,
		name: "模糊查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	handleCancel: {
		url: `${config.API_URL}/fms/sell/receipt/handleCancel`,
		name: "作废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
