import config from "@/config"
import http from "@/utils/request"

export default {
	createFile: {
		url: `${config.API_URL}/cfg/activiti/process/create`,
		name: "创建流程",
		post: async function(params){
			return await http.post(this.url, params);
		}
	},
	update:{
		url: `${config.API_URL}/cfg/activiti/process/update`,
		name: "修改流程",
		put: async function(params){
			return await http.put(this.url, params);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/activiti/process/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	deploy:{
		url: `${config.API_URL}/cfg/activiti/process/deploy`,
		name: "流程部署",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
