import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/sys/base/initialization/getList`,
		name: "初始向导",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/sys/base/initialization/update`,
		name: "初始向导修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	loginDefault: {
		url: `${config.API_URL}/sys/base/initialization/loginDefault`,
		name: "初始向导默认显示",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	loadDataOrCleanData: {
		url: `${config.API_URL}/sys/base/initialization/loadDataOrCleanData`,
		name: "初始向导测试数据",
		put: async function(data={},config){
			return await http.put(this.url,data,config);
		}
	},
	officially: {
		url: `${config.API_URL}/sys/base/initialization/officially`,
		name: "正式启用",
		put: async function(data={},config){
			return await http.put(this.url,data,config);
		}
	},
	showUserTreeMenuNavigation: {
		url: `${config.API_URL}/sys/base/user/showUserTreeMenuNavigation`,
		name: "显示用户菜单",
		get: async function(data={},config){
			return await http.get(this.url,data,config);
		}
	},
    showUserClearMenuDataTree: {
        url: `${config.API_URL}/sys/base/user/showUserClearMenuDataTree`,
        name: "显示用户菜单",
        get: async function(data={},config){
            return await http.get(this.url,data,config);
        }
    },
	clearData: {
		url: `${config.API_URL}/sys/base/initialization/clearData`,
		name: "清除测试数据",
		delete: async function(param){
			return await http.delete(this.url + '?menuIds=' + param);
		}
	},

}
