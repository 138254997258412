import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/oa/message/messageReceive/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	lookMessage: {
		url: `${config.API_URL}/oa/message/messageReceive/lookMessage`,
		name: "查看消息",
		get: async function (id) {
			return await http.get(this.url + '?id=' + id);
		}
	},
	delete:{
		url: `${config.API_URL}/oa/message/messageReceive/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getUnreadList: {
		url: `${config.API_URL}/oa/message/messageReceive/queryUnreadMessages`,
		name: "未读消息",
		get: async function(){
			return await http.get(this.url);
		}
	},
	allRead: {
		url: `${config.API_URL}/oa/message/messageReceive/allRead`,
		name: "全部已读取",
		get: async function(){
			return await http.get(this.url);
		}
	},
}
