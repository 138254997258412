
import config from "@/config"
import http from "@/utils/request"

export default {
	getCompanyList: {
		url: `${config.API_URL}/cfg/organize/company/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getRate: {
		url: `${config.API_URL}/crm/sale/opportunity/getRate`,
		name: "默认数据",
		get: async function(){
			return await http.get(this.url);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/organize/company/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/organize/company/getAllList`,
		name: "所有数据",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getCompanyById: {
		url: `${config.API_URL}/cfg/organize/company/getById/`,
		name: "id查询",
		get: async function(id={}){
			return await http.get(this.url + id);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/organize/company/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	doCheckName: {
		url: `${config.API_URL}/cfg/organize/company/checkName`,
		name: "企业名唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	CheckCode: {
		url: `${config.API_URL}/cfg/data/logistics/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	deleteCompany: {
		url: `${config.API_URL}/cfg/organize/company/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insertCompany: {
		url: `${config.API_URL}/cfg/organize/company/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateCompany: {
		url: `${config.API_URL}/cfg/organize/company/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	detailCompany: {
		url: `${config.API_URL}/cfg/organize/company/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	updateFlagCompany0: {
		url: `${config.API_URL}/cfg/organize/company/updateFlag0`,
		name: "修改状态",
		put: async function(params){
			return await http.put(this.url + '?ids=' + params);
		}
	},

	updateFlagCompany1: {
		url: `${config.API_URL}/cfg/organize/company/updateFlag1`,
		name: "修改状态",
		put: async function(params){
			return await http.put(this.url + '?ids=' + params);
		}
	},
	updateDefault: {
		url: `${config.API_URL}/cfg/organize/company/setDefault`,
		name: "设置默认",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},

}
