import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/contract/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/sale/contract/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/sale/contract/insert`,
		name: "新增销售报价",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/sale/contract/update`,
		name: "修改销售报价",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/sale/contract/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/sale/contract/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateContractStatus: {
		url: `${config.API_URL}/erp/sale/contract/updateContractStatus`,
		name: "修改合同状态",
		get: async function(ids,contractStatus){
			return await http.get(this.url + '?ids=' + ids + '&contractStatus=' + contractStatus);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/sale/contract/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateProcessInfo: {
		url: `${config.API_URL}/erp/sale/contract/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	returnProcess: {
		url: `${config.API_URL}/erp/sale/contract/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
}
