import config from "@/config"
import http from "@/utils/request"

export default {
	selectInspRecordDetail: {
        url: `${config.API_URL}/erp/produce/procInspRecord/detail/selectInspRecordDetail`,
        name: "查询列表数据",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
}
