import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/stock/occupancy/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	release: {
		url: `${config.API_URL}/erp/stock/occupancy/release`,
		name: "解除占用",
		put: async function(id,mrpOccupancyId,number){
			return await http.put(this.url + "/" + id + "?mrpOccupancyId=" + mrpOccupancyId + "&number=" + number);
		}
	},
}




