<!--选择费用科目弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'550px'"
    custom-class="popTable"
    :type="type"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes @resized="handleResized">
<!--          <pane-->
<!--            v-if="leftShow"-->
<!--            min-size="10"-->
<!--            max-size="60"-->
<!--            :size="paneSize"-->
<!--          >-->
<!--            <sy-tree-->
<!--              :api-obj="$API.classify.findAll"-->
<!--              :params="{ businessType: 'cfg_data_subject'}"-->
<!--              @fetch-data-node="fetchDataNode"-->
<!--            />-->
<!--          </pane>-->
          <pane>
            <!--公用切换按钮，点击切换显示左侧栏
			text:显示文字
			opened:绑定左侧栏显示状态
			show-left:回调函数
			-->
<!--            <sy-switch-btn-->
<!--              :text="switchText"-->
<!--              :opened="leftShow"-->
<!--              @show-left="handleShowLeft"-->
<!--            />-->
            <el-container
              class="mainPanel sw"
              :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-button
                    v-if="showAdd"
                    ref="refPopAddBtn"
                    type="primary"
                    :size="$store.state.global.uiSize"
                    @click="handleEdit(1, 'refPopAddBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                    v-if="showEdit"
                    ref="refEditBtn"
                    :type="'primary'"
                    :size="$store.state.global.uiSize"
                    :disabled="isDisabled"
                    @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>
                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
				  :params="tableConfig.params"
                  :column="tableConfig.columns"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  remote-sort
									:ref-query-form="refFormBar"
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
				  @select="handleSelect"
                  @current-change="currentChange"
                  @row-click="firClick"
                >
                  <template #flag="{ scope }">
                    <el-tag
                      v-if="scope.row.flag === 1"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      启用
                    </el-tag>
                    <el-tag
                      v-if="scope.row.flag === 0"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      停用
                    </el-tag>
                  </template>
                  <template #stype="{ scope }">
                    {{ scope.row.stype === 1 ? '费用支出' : '其他收入' }}
                  </template>
					<template #balanceDirection="{ scope }">
						{{ scope.row.balanceDirection === 1 ? '借方' : '贷方' }}
					</template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <span class="mr_5">
        <el-checkbox
          v-if="!hideAsyncUpdate"
          v-model="asyncAll"
          :true-label="1"
          :false-label="0"
          :size="$store.state.global.uiSize"
        >
          同步修改所有数据
        </el-checkbox>
      </span>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <!--新增弹窗-->
    <Edit
      ref="refEdit"
      @fetch-data="fetchData"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
} from 'vue'
import Edit from './edit'  //新增组件
export default defineComponent ({
	name: "SelectSubject",
	components: { Edit },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '会计科目-损益项' },
		//隐藏设置更新复选
		hideAsyncUpdate: { type: Boolean, default: true },
		//
		asyncAllData: { type: Number, default: 0 },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar: null,
			dataParams:{
				name:null,
				pageNum:1,
				pageSize:500
			},
      type:'detail',
			//记录选中节点
			curNode: null,
			asyncAll: props.asyncAllData,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//编辑按钮显示
			showEdit: false,
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			leftShow: true,
			paneSize: 20,
			paneSize1: 20,
			switchText: '分类',
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '科目名称',
					prop: 'name',
					width: 150,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			tableConfig: {
				apiObj: proxy.$API.subject.getListByKeyword,
				params: {},
				columns: [
					{
						label: '科目名称',
						prop: 'name',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '科目编号',
						prop: 'number',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '类型',
						prop: 'stype',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '余额方向',
						prop: 'balanceDirection',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '辅助核算',
						width: 120,
						prop: 'fz_accounting',
						sortable: 'custom',
					},
					{
						label: '期末调汇',
						width: 120,
						prop: 'terminal',
						sortable: 'custom',
					},
					{
						label: '现金流相关',
						width: 120,
						prop: 'cashFlow',
						sortable: 'custom',
					},
					{
						label: '出纳签字',
						width: 120,
						prop: 'accounting',
						sortable: 'custom',
					},
					{
						label: '状态',
						width: 120,
						prop: 'flag',
						sortable: 'custom',
					}
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 70,
				//查询表单项配置
				itemList: [

					{
						label: '科目名称',
						span: 8,
						prop: 'name',
						type: 'input',
						//最大字符数，可不设
						max: 50,
						//默认显示
						default: true,
					},
					{
						label: '科目编号',
						span: 8,
						prop: 'number',
						type: 'input',
						//最大字符数，可不设
						max: 32,
						//默认显示
						default: true,
					},
					{
						label: '类型',
						span: 8,
						prop: 'stype',
						type: 'select',
						//下拉选项
						data: [
							{value: '1', label: '费用支出'},
							{value: '2', label: '其他收入'},
						]
					},
					{
						label: '余额方向',
						prop: 'balanceDirection',
						span: 8,
						type: 'select',
						//下拉选项
						data: [
							{value: '1', label: '借方'},
							{value: '2', label: '贷方'},
						]
					},
					{
						label: '辅助核算',
						prop: 'fz_accounting',
						span: 8,
						type: 'input',
						//最大字符数，可不设
						max: 50,
						//默认显示
						default: true,
					},
					{
						label: '期末调汇',
						width: 130,
						prop: 'terminal',
						span: 8,
						type: 'input',
						//最大字符数，可不设
						max: 50,
						//默认显示
						default: true,
					},
					{
						label: '现金流相关',
						width: 120,
						prop: 'cashFlow',
						span: 8,
						type: 'input',
						//最大字符数，可不设
						max: 50,
						//默认显示
						default: true,

					},
					{
						label: '出纳签字',
						width: 120,
						prop: 'accounting',
						span: 8,
						type: 'input',
						//最大字符数，可不设
						max: 50,
						//默认显示
						default: true,
					},
					{
						label: '状态',
						width: 120,
						prop: 'flag',
						span: 8,
						type: 'input',
						//最大字符数，可不设
						max: 50,
						//默认显示
						default: true,
					}
				],
			},
		})

		//显示弹窗
		const showDialog =async (data) => {
			console.log(data,'data')
			state.visible = true
			state.selections = []
			state.selData = []
			if(data){
				Object.assign(state.tableConfig.params, data)
			}
			console.log(state.tableConfig.params)
			getShowAdd()
			getShowEdit()
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_SUBJECT,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_SUBJECT,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}


		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}
		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const firClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}


		//左侧树节点点击
		const fetchDataNode = (node) => {
			if(node){
				state.curNode = Object.assign({},node)
				fetchData({ classify: node.coordinate  })
			}
		}

		//用户搜索
		const handleSearch = () => {
			proxy.$refs.table.reload(state.queryForm)
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			if (!data) {
				data = {}
			}
			// 将params属性复制给tableConfig.params属性
			Object.assign(data, state.tableConfig.params);
			// 查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data);

			// 将tableConfig.params作为参数传递给apiObj和params
			state.tableConfig.apiObj.params = data;
			state.tableConfig.params = data;
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/


		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}


		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false)
			if (data && index === -1) {
				state.selData.push(data)


			} else {
				state.selData = state.selData.filter(item => item.id !== data.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false

			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()

		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//新增修改弹窗
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		return{
			...toRefs(state),
			tableSelselectChange,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			handleSearch,
			currentChange,
			selectChange,
			fetchDataNode,
			handleConfirm,
			handleShowLeft,
			showDialog,
			firClick,
			hideDialog,
			handleSelect
		}
	},
})
</script>
