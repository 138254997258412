<!--选择加工单弹窗组件-->
<template>
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="dftitle"
      :loading="loading"
      :width="width"
      height="400px"
      :trigger-dom="triggerDom"
      :bef-close="false"
      custom-class="popTable"
  >
    <el-container>
			<el-aside width="70%" class="nopadding bgwhite">
				<el-form
            label-position="right"
            :size="$store.state.global.uiSize"
            label-suffix=":"
        >
					<el-row>
						<el-col :span="6">
							<el-form-item label="入库数量" >
<!--								<el-input-->
<!--										v-model="form.baseNum"-->
<!--										disabled-->
<!--								/>-->
                {{form.baseNum}}
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item>
						<el-card shadow="never" style="height: 300px;">
							<template #header>
								<el-checkbox :size="$store.state.global.uiSize" v-model="tempRuleChecked" label="临时规则"/>
							</template>
							<el-form
								ref="myForm"
								:rules="rules"
								class="syForm"
								:model="form"
								:label-width="60"
								label-suffix=":"
								:size="$store.state.global.uiSize"
							>
									<el-row class="btm-margin">
										<el-col :span="8">
											<el-form-item label="前缀" prop="prefix">
												<el-input :size="$store.state.global.uiSize" :disabled="!tempRuleChecked" v-model="form.prefix"/>
											</el-form-item>
										</el-col>
										<el-col class="lsh" :span="8">
											<span>+</span>
											<span>流水号</span>
											<span>+</span>
										</el-col>
										<el-col :span="8">
											<el-form-item label="后缀" prop="end">
												<el-input :size="$store.state.global.uiSize" :disabled="!tempRuleChecked" v-model="form.end"/>
											</el-form-item>
										</el-col>
									</el-row>
	<!--                <el-form-->
									<el-row>
										<el-col class="rit-margin" :span="8">
											<el-form-item label="起始">
												<el-input
														:disabled="!tempRuleChecked"
														v-model="form.beginNum"
                            :size="$store.state.global.uiSize"
														@input="(val) => handleZnumInput(val, 'beginNum')"
														@blur="blurCheck('beginNum')"
												>
													<template #suffix>
														<span class="up-and-down">
															<el-icon><el-icon-arrowUp @click="addNumber('beginNum')" /></el-icon>
															<el-icon><el-icon-arrowDown @click="subNumber('beginNum')" /></el-icon>
														</span>
													</template>
												</el-input>
											</el-form-item>
										</el-col>
										<el-col class="rit-margin" :span="8">
											<el-form-item label="长度">
												<el-input
														:disabled="!tempRuleChecked"
                            :size="$store.state.global.uiSize"
														v-model="form.length"
														@input="(val) => handleZnumInput(val, 'length')"
														@blur="blurCheck('length')"
												>
													<template #suffix>
														<span class="up-and-down">
															<el-icon><el-icon-arrowUp @click="addNumber('length')" /></el-icon>
															<el-icon><el-icon-arrowDown @click="subNumber('length')" /></el-icon>
														</span>
													</template>
												</el-input>
											</el-form-item>
										</el-col>
										<el-col class="rit-margin" :span="8">
											<el-form-item label="间距">
												<el-input
														:disabled="!tempRuleChecked"
														v-model="form.spacing"
                            :size="$store.state.global.uiSize"
														@input="(val) => handleZnumInput(val, 'spacing')"
														@blur="blurCheck('spacing')"
												>
													<template #suffix>
														<span class="up-and-down">
															<el-icon><el-icon-arrowUp @click="addNumber('spacing')" /></el-icon>
															<el-icon><el-icon-arrowDown @click="subNumber('spacing')" /></el-icon>
														</span>
													</template>
												</el-input>
											</el-form-item>
										</el-col>
									</el-row>
								<div class="blank"/>
									<el-row class="btm-margin">
										<el-col :span="6">
											<el-checkbox :size="$store.state.global.uiSize" v-model="preRuleChecked" label="预设规则"/>
										</el-col>
									</el-row>
										<div class="disbet">
											<div style="min-width: 150px;">
												<el-select
                            :size="$store.state.global.uiSize"
                            :disabled="!preRuleChecked"
                            v-model="form.fz_snRulesName"
                            @change="setPreSelChange"
                        >
													<el-option
														v-for="rule in preRules"
														:key="rule.id"
														:label="rule.name"
														:value="rule.id"
													/>
												</el-select>
											</div>
											<el-button :size="$store.state.global.uiSize" :disabled="!preRuleChecked" @click="setRules">
												设置规则
											</el-button>
										</div>
<!--                        select绑定的是option的选项的value，-->
							</el-form>
						</el-card>
					</el-form-item>
				</el-form>
			</el-aside>
			<el-main class="nopadding bgwhite">
				<div class="pd10" style="width: 100%;text-align: right;">
					<el-button :size="$store.state.global.uiSize" type="primary" :loading="subLoading" @click="generation">
						生成
					</el-button>
				</div>
				<!--主数据表格-->
        <ScTable
            ref="refSnList"
            :column="tableConfig.columns"
            :data="tableConfig.data"
            row-key="id"
            border
            :height="300"
            highlight-current-row
            hide-pagination
            remote-sort
            remote-filter
        >
        </ScTable>
			</el-main>
      <Detail ref="refDetail" />
      <GoodsDetail ref="refGoodsDetail" />
      <ProducePlanDetail ref="refProducePlanDetail" />
      <ProduceProcessDetail ref="refProduceProcessDetail" />
      <SetPreRules @repre-rule="setPreRuleList" ref="refSetRules" />
    </el-container>
    <template #footer>
      <el-button
          :size="$store.state.global.uiSize"
          type="primary"
          :disabled="isConfirm"
          @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
          :size="$store.state.global.uiSize"
          @click="visible = false"
      >
        取消
      </el-button>
    </template>
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick, watch,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
import {setColSpan} from "@/utils/window";
import SetPreRules from "./setPreRules.vue";
import {resetZnum} from "@/utils/validate";
export default defineComponent ({
  name: "BatchEntry",
  components: {GoodsDetail, Detail ,ProducePlanDetail,ProduceProcessDetail,SetPreRules},
  props: {
    // //是否开启多选
    // multiple: { type: Boolean, default: false },
    // //默认开启全部选择
    // choseAll: { type: Boolean, default: true },
    //传入标题
    title: { type: String, default: '批量录入' },
		//暂已录入序列号
    seledSn: { type: Array, default: ()=>[] },
  },
  emits: [ 'set-data' ],
  setup(props, { emit }){
    const { proxy } = getCurrentInstance()
    const state = reactive({
			subLoading: false,
      //表单项占比
      span: setColSpan(),
      //表单验证
      rules: {
        // prefix: [{required: true, trigger: 'change', message: '必填'}],
        // end: [{required: true, trigger: 'change', message: '必填'}],
      },
      isDelSel: true,
      paneSize1: 25,
      paneSize: 30,
      leftShow: true,
      //隐藏搜索表单
      hideForm: true,
      //是否显示查询表单隐藏项
      showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
      //查询表单
      queryForm: {},
      refSnDetail: null,
      refSetRules: null,
      //弹窗标题
      dftitle: props.title,
      //打开弹窗触发元素
      triggerDom: {},
      //切换部门角色
      curCls: 1,
      //加载动画
      loading: true,
      //弹窗显示属性
      visible: false,
      //弹窗宽度
      width: '60%',
      //确定按钮状态
      isConfirm: false,
      //主表选中数据
      selections: [],
      //已选数据中选中的数据
      selectionsTableSel: [],
      //已选数据记录
      selData: [],
			myForm: null,
      form: {
        fz_goodsName:'',
        baseNum: 0,
        //序列号前后缀
        prefix: '',
        end: '',
				snRulesId: null,
				fz_snRulesName: '',
        //序列号设置
        beginNum: 1,
        length: 3,
        spacing: 1,
      },
			//预设规则
			preRules: [],
			tempRuleChecked: true,
			preRuleChecked: false,
      //从设置规则那里返回的规则数据存在这里
      setRules: [],
      //清空按钮状态
      clearAble: true,
      //表格
      refSnList: null,
      //传入的赋值字段
      param: null,
      //选中后赋值的字段
      params: [],
      //暂存选中行
      curRow: {},
      //搜索表单配置
      searchConfig: {
        //表单标题宽度
        labelWidth: 100,
        queryForm: {},
        //查询表单项配置
        itemList: [

        ],
      },
      tableConfig: {
        // showSummary: true,
        //合计行统计的字段
        // summary: ['number', 'inspectedQuantity', 'qualifiedQuantity', 'unqualifiedQuantity'],
        columns: [
          {
            label: '序列号',
            prop: 'sn',
            // width: 180,
            // fixed: 'left',
          },
        ],
        data:[]
      }
    })

    const setPreRuleList = async (goodsId)=>{
      //打开弹窗的时候获取preRules\
      const res = await proxy.$API.snRules.getList.get({
        goodsId: goodsId || state.form.goodsId,
      })
      if(res.code==200 && res.data){
        state.preRules = res.data.list
				// 获取默认规则
				const defRule = state.preRules.find(item=>item.flag === 1)
				if(defRule){
					state.form.snRulesId = defRule.id
					state.form.fz_snRulesName = defRule.name
				}
      }
    }
    //显示弹窗
    const showDialog = (data) => {
      state.visible = true
      state.selections = []
      state.selData = []
      if(data){
        Object.assign(state.form, data)
      }
      setPreRuleList(state.form.goodsId)
      state.loading = false
    }

    //关闭弹窗
    const hideDialog = () => {
      state.visible = false
    }

    //临时规则
    const addNumber = (str)=>{
      if(!state.tempRuleChecked){
        return
      }
			if(str == 'length' && state.form[str] + 1 > 9) return;
      state.form[str]++
    }
    const subNumber = (str)=>{
      if(!state.tempRuleChecked){
        return
      }
			if(state.form[str] - 1  > 0){
				state.form[str]--
			}
    }

    const handleZnumInput = (val, prop) => {
      if (val) {
        state.form[prop] = resetZnum(val) - 0
      }
    }
		const blurCheck = (prop) => {
			if(state.form[prop] < 1){
				state.form[prop] = 1
			}else if(prop === 'length' && state.form[prop] > 9){
				state.form[prop] = 9
			}else if(!state.form[prop]){
				state.form[prop] = 1
			}
		}
    // 预设规则
    watch(()=>state.preRuleChecked, async (newValue, old) => {
      // 临时和预设规则选框状态必须相反
      state.tempRuleChecked = !newValue
    })
    watch(()=>state.tempRuleChecked, (newValue, old) => {
      // 临时和预设规则选框状态必须相反
      state.preRuleChecked = !newValue
    })
    //预设规则
    // 选择规则
    const setPreSelChange = (val)=>{
      state.form.snRulesId = val
      // state.form.fz_snRulesName = val.name
    }
    // 设置规则
    const setRules = ()=>{
      state.refSetRules.showDialog(state.form.goodsId)
    }
    // 生成
    const generation = async () => {
			state.subLoading = true
			// if (state.tempRuleChecked && (!state.form.prefix || !state.form.end)) {
			// 	proxy.$baseMessage('请完善前缀和后缀！', 'warning')
			// 	return
			// }
			let param = {...state.form}
			param.baseNum = param.baseNum - 0

			let res = null
			if (state.tempRuleChecked) {
				// 选择临时规则flag为0，预设规则flag为1
				param.flag = 0
				const findSeled = props.seledSn.find(item=>item.snRulesId === 0)
				if(findSeled){
					param['countNum'] = findSeled.countNum
				}
				res = await proxy.$API.snRules.batchEntry.get(param);
			} else if (state.preRuleChecked) {
				const _param = {
					flag: 1,
					snRulesId: state.form.snRulesId,
					goodsId: state.form.goodsId,
					baseNum: state.form.baseNum - 0
				}
				const findSeled = props.seledSn.find(item=>item.snRulesId === _param.snRulesId)
				if(findSeled){
					_param['countNum'] = findSeled.countNum
				}
				res = await proxy.$API.snRules.batchEntry.get(_param);
			}
			if (res.code === 200 && res.data) {
				state.tableConfig.data = res.data
				proxy.$message({
					type: 'success',
					message: '生成成功!'
				});
			} else {
				proxy.$message({
					type: 'error',
					message: res.msg
				});
			}
			state.subLoading = false
		}

    //确定按钮点击
    const handleConfirm = () => {
      // 生成后确定
      // 把生成的序列号传给上一级弹窗
      if (state.tableConfig.data) {
        let data = state.tableConfig.data
        //触发父级赋值方法
        emit('set-data',data)
      }
      //隐藏弹窗
      hideDialog()
    }

    //行单击
    const firClick = (row) => {
      // //添加已选数据
      // setSelData(row)
      // if(row && !props.multiple){
      // 	//传入选中行和赋值的字段
      // 	emit('set-data', row)
      // 	hideDialog()
      // }
      // 多选
      if(props.multiple){
        //添加已选数据
        setSelData(row)
      }
      handleRowClick(row)
    }

    //行双击
    const dblClick = (row) => {
      // 单选
      if(row && !props.multiple){
        //传入选中行和赋值的字段
        emit('set-data', row)
        hideDialog()
      }

    }

    //查询数据，刷新表格
    const fetchData = (data) => {
      //查询参数{ 字段名：值, 字段名：值, ... }
      state['table'].reload(data)
    }

    //保存表单显示状态
    const handleFitShow = (bool) => {
      state.showItem = bool
    }

    //切换显示搜索栏
    const handleShowSearch = () => {
      nextTick(() => {
        state.hideForm = !state.hideForm
      })
    }

    /*--------------------主表事件start-----------------*/
    const blurEdit = (val)=>{}
    const selChange = (val)=>{}
    const popSelectDetail = (val)=>{}
    //主表行点击事件
    const handleRowClick = (row) => {
      if(state.leftShow){
        // state['tableSon'].reload({submId: row.id})
      }
    }

    const handleDetail = (row, ref, field) => {
      const params = Object.assign({}, row)
      if (field) {
        params.id = row[field]
      }
      proxy.$refs[ref].showDialog(params)
    }

    //清空已选数据
    const clearSelections = () => {
      state.selData = []
      proxy.$refs.table.clearSelection()
    }

    return{
      ...toRefs(state),
      addNumber,
      subNumber,
      handleZnumInput,
			blurCheck,
      setPreRuleList,
      showDialog,
      hideDialog,
      handleConfirm,
      firClick,
      dblClick,
      fetchData,
      handleFitShow,
      handleShowSearch,
      setPreSelChange,
      setRules,
      generation,
      blurEdit,
      selChange,
      popSelectDetail,
      handleRowClick,
      handleDetail,
      clearSelections
    }
  },
})
</script>
<style lang="scss" scoped>
  .el-card{
    width: 100%!important;
    .el-row.btm-margin{
      //margin-bottom: 20px;
    }
    .el-input__inner{
      line-height: 1px !important;
    }
    .lsh{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .up-and-down{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
			cursor: pointer;
    }
    .blank{
      width: 100%;
      height: 1px;
      background-color: #eeeeee;
      margin: 20px 0;
    }
  }
</style>
