<!--选择销售发货单弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		type="detail"
		:height="'500px'"
		custom-class="popTable"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<splitpanes
							:horizontal="true"
							@resized="handleResized"
						>
							<pane>
								<el-container
									class="fatherCont"
									:style="multiple && 'padding-right: 10px'"
								>
									<sy-search-Form
										v-show="!hideForm"
										ref="refFormBar"
										:configs="searchConfig"
										:show-item="showItem"
										@fetch-data="fetchData"
										@set-show="handleFitShow"
									/>
									<el-header class="tableTopBar">
										<div class="left-panel"/>
										<div class="right-panel">
											<!--切换显示搜索栏hide-after: 延迟隐藏-->
											<el-tooltip
												:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
												placement="bottom"
												:hide-after="0"
											>
												<el-link @click="handleShowSearch">
													搜索
													<el-icon>
														<template v-if="hideForm">
															<el-icon-arrowDown/>
														</template>
														<template v-else>
															<el-icon-arrowUp/>
														</template>
													</el-icon>
												</el-link>
											</el-tooltip>
										</div>
									</el-header>
									<el-main class="nopadding bgwhite">
										<!--主数据表格-->
										<scTable
											ref="table"
											:api-obj="tableConfig.apiObj"
											:column="tableConfig.columns"
											:params="tableConfig.params"
											row-key="id"
											:checkbox="multiple"
											radio-check
											border
											highlight-current-row
                      :ref-query-form="refFormBar"
											remote-sort
											remote-filter
											hide-column-setting
											hide-setting
											:pagination-layout="'total, prev, pager, next, jumper'"
											@selection-change="selectChange"
											@current-change="currentChange"
											@row-dblclick="dblClick"
											@row-click="handleRowClick"
											@select="handleSelect"
											@data-change="fatherDataChange"
										>
											<template #orderNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refDetail')"
												>
													{{ scope.row.orderNo }}
												</el-link>
											</template>
											<template #fz_saleOrderNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refSaleOrderDetail', 'saleOrderId')"
												>
													{{ scope.row.fz_saleOrderNo }}
												</el-link>
											</template>
											<template #fz_customerName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refCustomerDetail', 'customerId')"
												>
													{{ scope.row.fz_customerName }}
												</el-link>
											</template>
											<template #fz_logisticsName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refLogisticsDetail', 'logisticsId')"
												>
													{{ scope.row.fz_logisticsName }}
												</el-link>
											</template>
											<template #paymentMethod="{ scope }">
												<span v-if="scope.row.paymentMethod == 1">现金</span>
												<span v-if="scope.row.paymentMethod == 2">转账</span>
												<span v-if="scope.row.paymentMethod == 3">支票</span>
												<span v-if="scope.row.paymentMethod == 4">其他</span>
											</template>
											<template #outStatus="{ scope }">
												<el-tag
													v-if="scope.row.outStatus == 0"
													style="color: #666"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未出库
												</el-tag>
												<el-tag
													v-if="scope.row.outStatus == 1"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已出库
												</el-tag>
											</template>
											<template #signStatus="{ scope }">
												<el-tag
													v-if="scope.row.signStatus == 0"
													style="color: #666"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未签收
												</el-tag>
												<el-tag
													v-if="scope.row.signStatus == 1"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已签收
												</el-tag>
											</template>
											<template #qualityStatus="{ scope }">
												<el-tag
													v-if="scope.row.qualityStatus == 0"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未检验
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus == 1"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													检验驳回
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus == 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													检验通过
												</el-tag>
											</template>

										</scTable>
									</el-main>
									<sy-switch-btn
										:text="'物品明细'"
										:opened="leftShow"
										bottom
										@show-left="handleShowLeft"
									/>
								</el-container>
							</pane>
							<!--明细数据-->
							<pane
								v-if="leftShow"
								:size="paneSize"
								max-size="70"
								min-size="20"
							>
								<el-container
									class="sonCont"
									:style="multiple && 'padding-right: 10px'"
								>
									<el-main class="nopadding">
										<!--明细-->
										<scTable
											ref="tableSon"
											:api-obj="tableSonConfig.apiObj"
											:column="tableSonConfig.columns"
											:params="tableSonConfig.params"
											row-key="id"
											border
											remote-sort
										>
											<!--自定义单元格显示 #{字段名称} -->
											<template #fz_goodsName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
												>
													{{ scope.row.fz_goodsName }}
												</el-link>
											</template>
										</scTable>
									</el-main>
								</el-container>
							</pane>
						</splitpanes>
					</pane>
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumn"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<!--      <el-button-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        @click="visible = false"-->
			<!--      >-->
			<!--        关闭-->
			<!--      </el-button>-->
		</template>

		<Detail ref="refDetail" />
		<SaleOrderDetail ref="refSaleOrderDetail" />
		<LogisticsDetail ref="refLogisticsDetail" />
		<GoodsDetail ref="refGoodsDetail" />
		<CustomerDetail ref="refCustomerDetail" />
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from "vue";
import Detail from "@/views/erp/sale/invoice/detail.vue";
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import LogisticsDetail from "@/views/cfg/data/logistics/detail.vue";
import CustomerDetail from "@/views/cfg/data/customer/detail.vue";
import SaleOrderDetail from "@/views/erp/sale/order/reduceAmountDetail.vue";

export default defineComponent({
	name: "SelectSaleOrder",
	components: {SaleOrderDetail, CustomerDetail, LogisticsDetail, GoodsDetail, Detail},
	props: {
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: "选择发货单"},
	},
	emits: ["set-data"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: "75%",
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '订单编号',
					prop: 'orderNo',
					width: 200,
					sortable: 'custom',
					fixed: 'left'
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.saleInvoice.getList,
				//数据表格列配置
				columns: [
					{
						label: '订单编号',
						prop: 'orderNo',
						width: 200,
						sortable: 'custom',
						fixed: 'left'
					},
					{
						label: '销售订单',
						prop: 'fz_saleOrderNo',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '客户',
						prop: 'fz_customerName',
						sortable: 'custom',
						width: 200,
					},
					{
						label: '质检状态',
						prop: 'qualityStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '出库状态',
						prop: 'outStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '签收状态',
						prop: 'signStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '发货方式',
						prop: 'fz_shipMethodValue',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '物流公司',
						prop: 'fz_logisticsName',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '联系人',
						prop: 'contactsName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '联系电话',
						prop: 'contactsTel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '运单号',
						prop: 'waybillNo',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '运输费用',
						prop: 'transportCost',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '付款方式',
						prop: 'paymentMethod',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '付款账户',
						prop: 'fz_paymentAccountName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '发货人',
						prop: 'fz_deliverName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '发货时间',
						prop: 'deliverTime',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '发货说明',
						prop: 'illustrate',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '产品编码',
						prop: 'fz_goodsCode',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						sortable: 'custom',
						width: 120,
					},
					// {
					// 	label: '物品型号',
					// 	prop: 'fz_goodsModel',
					// 	sortable: 'custom',
					// 	width: 120,
					// },
					{
						label: '发货仓库',
						prop: 'fz_depotName',
						sortable: 'custom',
						fixed: 'left',
						width: 110,
					},
					{
						label: '物品批号',
						prop: 'batchNumber',
						width: 130,
						sortable: 'custom',
					},
					{
						label: 'SN序号',
						prop: 'snNumber',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '发货数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '小计成本',
						prop: 'sumCost',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '小计金额',
						prop: 'subtotal',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '订单编号',
						prop: 'orderNo',
						type: 'input',
						default: true,
					},
					{
						label: '销售订单',
						prop: 'fz_saleOrderNo',
						type: 'input',
						default: true,
					},
					{
						label: '客户',
						prop: 'fz_customerName',
						type: 'popCustomer',
						default: true,
						fields: ['customerId', 'fz_customerName'],
						max: 32,
					},
					{
						label: '发货方式',
						prop: 'shipMethod',
						type: 'selectDicts',
						//字典类型编码
						typeCode: 'public_attribute-shipMethod',
						default: true,
					},
					{
						label: '物流公司',
						prop: 'fz_logisticsName',
						type: 'popLogistics',
						default: true,
						fields: ['logisticsId', 'fz_logisticsName'],
					},
					{
						label: '联系人',
						prop: 'contactsName',
						type: 'input',
						max: 10,
					},
					{
						label: '联系电话',
						prop: 'contactsTel',
						type: 'number',
						max: 20,
					},
					{
						label: '质检状态',
						prop: 'qualityStatus',
						type: 'select',
						// short: 12,
						data: [
							{
								label: '未检验',
								value: 0,
							},
							{
								label: '检验驳回',
								value: 1,
							},

							{
								label: '检验通过',
								value: 2,
							},
						],
					},
					{
						label: '出库状态',
						prop: 'outStatus',
						type: 'select',
						// short: 12,
						data: [
							{
								label: '已出库',
								value: 1,
							},
							{
								label: '未出库',
								value: 0,
							},
						],
					},
					{
						label: '签收状态',
						prop: 'signStatus',
						type: 'select',
						// short: 12,
						data: [
							{
								label: '已签收',
								value: 1,
							},
							{
								label: '未签收',
								value: 0,
							},
						],
					},
					{
						label: '运单号',
						prop: 'waybillNo',
						type: 'input',
						max: 32,
					},
					{
						label: '运输费用',
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'transportCost0',
								max: 10,
							},
							{
								prop: 'transportCost1',
								max: 10,
							}
						],
					},
					{
						label: '付款方式',
						prop: 'paymentMethod',
						type: 'select',
						data: [
							{
								label: '现金',
								value: 1,
							},
							{
								label: '转账',
								value: 2,
							},
							{
								label: '支票',
								value: 3,
							},
							{
								label: '其他',
								value: 4,
							},
						]
					},
					{
						label: '付款账户',
						prop: 'paymentAccount',
						type: 'popAccount',
					},
					{
						label: '发货人',
						prop: 'fz_deliverName',
						type: 'popUser',
						// short: 12,
						fields: ['deliverId', 'fz_deliverName'],
						max:32
					},
					{
						label: '发货时间',
						type: 'dateTimeRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'deliverTime0',
							},
							{
								prop: 'deliverTime1',
							},
						],
					},
					{
						label: '发货说明',
						type: 'input',
						max: 32,
						prop: 'illustrate',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						type: 'popUser',
						// short: 12,
						fields: ['operateId', 'fz_operateName'],
						max:32
					},
					{
						label: '操作时间',
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'addTime0',
							},
							{
								prop: 'addTime1',
							},
						],
					},
				],
			},
		});

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true;
			state.selections = [];
			state.selData = [];
			if (data) {
				Object.assign(state.tableConfig.params, data);
			}
			state.isConfirm = true;
			state.loading = false;
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool;
		};

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			if (val.length > 0) {
				state.isConfirm = false;
			} else {
				state.isConfirm = true;
			}
			state.selections = val;
		};

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return;
			}
			const index = selection.indexOf(row);
			const index1 = state.selData.indexOf(row);
			if (index < 0) {
				state.selData.splice(index1, 1);
			} else {
				if (index1 < 0) {
					state.selData.push(row);
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false);
			}
		};

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item);
			});
		};

		//选中行改变
		const currentChange = (row) => {
			state.curRow = row;
			if (state.curRow && JSON.stringify(state.curRow) != "{}") {
				state.isDisabled = false;
				if (!props.multiple) {
					state.isConfirm = false;
				}
			} else {
				state.isDisabled = true;
				if (!props.multiple) {
					state.isConfirm = true;
				}
			}
		};

		//确定按钮点击
		const handleConfirm = () => {
			let data;
			if (!props.multiple) { //多选
				data = state.curRow;
			} else {
				data = state.selData;
			}
			//触发父级赋值方法
			emit("set-data", data);
			//隐藏弹窗
			hideDialog();
		};

		//行单击
		// const firClick = (row) => {
		// 	if(props.multiple){
		// 		//添加已选数据
		// 		setSelData(row)
		// 	}
		// }

		//行双击
		const dblClick = (row) => {
			if (row && !props.multiple) {
				//传入选中行和赋值的字段
				emit("set-data", row);
				hideDialog();
			}

		};

		const firClick = (row) => {
			if (props.multiple) {
				//添加已选数据
				setSelData(row);
			}

		};

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table;
				const tableData = dataTable.tableData;
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false);
				});
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id;
					});
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true);
					}
				});
			});
		};

		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false);
			if (data && index === -1) {
				state.selData.push(data);

			} else {
				state.selData = state.selData.filter(item => item.id !== data.id);
			}
			if (state.selData.length > 0) {
				state.isConfirm = false;

			} else {
				state.selData.splice(index, 1);
			}
			showChooseData();

		};

		//分栏拖动结束
		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[1].size;
			}
		};

		//查询数据，刷新表格
		const fetchData = (data) => {
			if (!data) {
				data = {};
			}
			Object.assign(data, state.tableConfig.params);
			state["table"].reload(data);
		};

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool;
		};

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm;
			});
		};

		//主表行点击事件
		const handleRowClick = (row) => {
			if (row) {
				firClick(row);
			}
			if (state.leftShow) {
				state["tableSon"].reload({invoiceId: row.id});
			}
		};

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if (res.data.list && res.data.list[0]) {
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//传入父级ID
				state.tableSonConfig.params = {invoiceId: res.data.list[0].id};
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.saleInvoiceGoods.selectInvoiceGoods
				//根据主数据加载子数据
				// handleRowClick(res.data.list[0])
			}

		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val;
			if (val.length > 0) {
				state.isDelSel = false;
			} else {
				state.isDelSel = true;
			}
		};

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item);
					const index1 = state.selections.indexOf(item);
					if (index > -1) {
						state.selData.splice(index, 1);
					}
					if (index1 > -1) {
						proxy.$refs.table.toggleRowSelection(item, false);
					}
				});
			}
		};

		//清空已选数据
		const clearSelections = () => {
			state.selData = [];
			proxy.$refs.table.clearSelection();
			// emit('set-data', state.selData)
		};

		return {
			...toRefs(state),
			clearSelections,
			delSelData,
			tableSelselectChange,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			hideDialog,
			handleSelect,
			dblClick,
			firClick
		};
	},
});
</script>
