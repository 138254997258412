<!--选择用户弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="true"
	fullscreen
	:show-fullscreen="false"
    custom-class="popTable"
    @reset="hideDialog"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes @resized="handleResized">
          <pane>
            <el-container
              class="mainPanel"
              :style="multiple && 'padding-right: 10px'"
            >
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  :table-name="$MENU.code.ERP_SALE_COMPANY_SALEORDERGLANCE"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  remote-sort
                  remote-filter
                  hide-column-setting
                  hide-setting
				  @data-change="handleDataChange"
				  @select="handleSelect"
				  @select-all="handleSelectAll"
				  @selection-change="selectChange"
				  @row-click="handleRowClick"
                >
                  <template #orderNo="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refSaleOrderDetail', 'saleOrderId')"
                    >
                      {{ scope.row.orderNo }}
                    </el-link>
                  </template>

                  <template #fz_goodsName="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
                    >
                      {{ scope.row.fz_goodsName }}
                    </el-link>
                  </template>

                  <template #invoiceStatus="{ scope }">
                    <el-tag
                      v-if="scope.row.invoiceStatus === 0"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      未发货
                    </el-tag>
                    <el-tag
                      v-if="scope.row.invoiceStatus === 1"
                      type="warning"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      部分发货
                    </el-tag>
                    <el-tag
                      v-if="scope.row.invoiceStatus === 2"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      全部发货
                    </el-tag>
                    <el-tag
                      v-if="scope.row.invoiceStatus === 3"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      已终止
                    </el-tag>
                  </template>

                  <!--退货状态-->
                  <template #refundStatus="{ scope }">
                    <el-tag
                      v-if="scope.row.refundStatus === 0"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      未退货
                    </el-tag>
                    <el-tag
                      v-if="scope.row.refundStatus === 1"
                      type="warning"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      部分退货
                    </el-tag>
                    <el-tag
                      v-if="scope.row.refundStatus === 2"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      全部退货
                    </el-tag>
                  </template>

                  <template #invoiceType="{ scope }">
                    <span v-if="scope.row.invoiceType === 1">增值税专用发票</span>
                    <span v-if="scope.row.invoiceType === 2">增值税普通发票</span>
                    <span v-if="scope.row.invoiceType === 3">增值税普通发票电子票</span>
                    <span v-if="scope.row.invoiceType === 4">收据</span>
                    <span v-if="scope.row.invoiceType === 5">其他</span>
                  </template>

                  <!--收款状态-->
                  <template #receiptStatus="{ scope }">
                    <el-tag
                      v-if="scope.row.receiptStatus === 0"
                      type="info"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      未收款
                    </el-tag>
                    <el-tag
                      v-if="scope.row.receiptStatus === 1"
                      type="warning"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      部分收款
                    </el-tag>
                    <el-tag
                      v-if="scope.row.receiptStatus === 2"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      全部收款
                    </el-tag>
                  </template>

                  <template #orderType="{ scope }">
                    <span v-if="scope.row.orderType === 1">合约销售</span>
                    <span v-if="scope.row.orderType === 2">直接销售</span>
                    <span v-if="scope.row.orderType === 3">批量销售</span>
                    <span v-if="scope.row.orderType === 4">其他</span>
                  </template>

                  <template #paymentMethod="{ scope }">
                    <span v-if="scope.row.paymentMethod === 1">现金</span>
                    <span v-if="scope.row.paymentMethod === 2">转账</span>
                    <span v-if="scope.row.paymentMethod === 3">支票</span>
                    <span v-if="scope.row.paymentMethod === 4">其他</span>
                  </template>

                  <!--审核状态-->
                  <template #auditStatus="{ scope }">
                    <el-tag
                      v-if="scope.row.auditStatus === 0"
                      type="info"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      未提交
                    </el-tag>
                    <el-tag
                      v-if="scope.row.auditStatus === 1"
                      type="warning"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      审核中
                    </el-tag>
                    <el-tag
                      v-if="scope.row.auditStatus === 2"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      已驳回
                    </el-tag>
                    <el-tag
                      v-if="scope.row.auditStatus === 3"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      审核通过
                    </el-tag>
                  </template>


                  <!--自定义单元格显示 #{字段名称} -->
                  <template #name="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                    >
                      {{ scope.row.name }}
                    </el-link>
                  </template>
                  <template #flag="{ scope }">
                    <el-tag
                      v-if="scope.row.flag === 1"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      启用
                    </el-tag>
                    <el-tag
                      v-if="scope.row.flag === 0"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      停用
                    </el-tag>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="hideDialog"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <!--    <GoodsDetail ref="refGoodsDetail" />-->
        <SaleOrderDetail ref="refSaleOrderDetail" />
    <!--    <Detail ref="refDetail" />-->
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs, watch,} from 'vue'
import SaleOrderDetail from '@/wap/views/erp/sale/order/detail.vue'

export default defineComponent({
	name: "WapSelectSaleOrderGoods",
	components: {SaleOrderDetail},
	props: {
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: '销售订单明细选择'},
		//隐藏设置更新复选
		hideAsyncUpdate: {type: Boolean, default: true},
		//
		asyncAllData: {type: Number, default: 0},
	},
	emits: ['set-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
			asyncAll: props.asyncAllData,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//编辑按钮显示
			showEdit: false,
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			leftShow: true,
			paneSize: 20,
			paneSize1: 20,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			// selColumns: [
			// 	{
			// 		label: '销售订单编号',
			// 		prop: 'orderNo',
			// 		width: 150,
			// 		sortable: true,
			// 	},
			// 	{
			// 		label: '物品名称',
			// 		prop: 'fz_goodsName',
			// 		width: 150,
			// 		sortable: true,
			// 	},
			// 	{
			// 		label: '物品编码',
			// 		prop: 'fz_goodsCode',
			// 		width: 150,
			// 		sortable: true,
			// 	},
			// ],
			//清空按钮状态
			clearAble: true,
			excelCondition: {},
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			tableConfig: {
				apiObj: proxy.$API.saleOrder.getAllGoodsList,
				//搜索默认参数
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '销售订单编号',
						prop: 'orderNo',
						sortable: true,
						// fixed: 'left',
					},

					// {
					// 	label: '收款期限',
					// 	prop: 'collectDate',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '审核状态',
					// 	prop: 'auditStatus',
					// 	width: 120,
					// 	sortable: true,
					// },

					// {
					// 	label: '产品名称',
					// 	prop: 'fz_goodsName',
					// 	sortable: true,
					// 	width: 160,
					// },
					// {
					// 	label: '物品编码',
					// 	prop: 'fz_goodsCode',
					// 	sortable: true,
					// 	width: 150,
					// },
					// {
					// 	label: '产品编号',
					// 	prop: 'code',
					// 	sortable: true,
					// 	width: 160,
					// },
					// {
					// 	label: '产品位号',
					// 	prop: 'productBitNumber',
					// 	sortable: true,
					// 	width: 160,
					// },
					// {
					// 	label: '规格型号',
					// 	prop: 'fz_goodsSpec',
					// 	sortable: true,
					// 	width: 200,
					// },
					// {
					// 	label: '材质',
					// 	prop: 'fz_goodsMaterial',
					// 	sortable: true,
					// 	width: 120,
					// },
					// {
					// 	label: '发货仓库',
					// 	prop: 'fz_depotName',
					// 	sortable: true,
					// 	width: 130,
					// },
					// {
					// 	label: '主单位',
					// 	prop: 'unit',
					// 	width: 120,
					// 	sortable: true,
					// },
					//
					// {
					// 	label: '数量',
					// 	prop: 'quantity',
					// 	width: 130,
					// 	sortable: true,
					// },
					//
					// {
					// 	label: '辅助单位',
					// 	prop: 'assistUnit',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '辅助单位数量',
					// 	prop: 'assistQuantity',
					// 	width: 140,
					// 	sortable: true,
					// },
					//
					//
					// {
					// 	label: '创建日期',
					// 	prop: 'creationDate',
					// 	width: 140,
					// 	sortable: true,
					// },
					// {
					// 	label: '备注',
					// 	prop: 'remark',
					// 	width: 150,
					// 	sortable: true,
					// },
				],
			},
		})

		//显示弹窗
		const showDialog = (param) => {
			state.visible = true
			if (param) {
				state.tableConfig.params = Object.assign({}, param)
				// state.params = Object.assign({}, param)
			}

			state.excelCondition = Object.assign({}, param)
			// getShowAdd()
			// getShowEdit()
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			initProps()
		}

		const initProps = () => {
			state.selections = []
			state.selData = []
			state.data = []
		}

		//弹窗关闭回调
		const handleClosed = () => {
			initProps()
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ORGANIZE_USER, 'add')
			//有结果标识有权限
			if (res.data) {
				state.showAdd = true
			} else {
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ORGANIZE_USER, 'edit')
			//有结果标识有权限
			if (res.data) {
				state.showEdit = true
			} else {
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		// const handleShowLeft = (bool) => {
		// 	state.leftShow = bool
		// }

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//全选
		const handleSelectAll = (selection) => {
			if (selection && selection.length > 0) {
				selection.forEach((item) => {
					setSelData(item)
				})
			} else {
				state.selData = []
			}
		}

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if (index < 0) {
				state.selData.splice(index1, 1)
			} else {
				if (index1 < 0) {
					state.selData.push(row)
				}
				// proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange = (row) => {
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if (!props.multiple) { //多选
				data = state.curRow
			} else {
				if (state.selectionsTableSel && state.selectionsTableSel.length > 0) {
					state.selections = state.selectionsTableSel
				}
				data = state.selections
			}
			//触发父级赋值方法
			emit('set-data', data)
			//隐藏弹窗
			hideDialog()
		}

		// //行双击
		// const dblClick = (row) => {
		// 	if(row && !props.multiple){
		// 		//传入选中行和赋值的字段
		// 		emit('set-data', row)
		// 		hideDialog()
		// 	}
		// 	if(props.multiple){
		// 		//添加已选数据
		// 		setSelData(row)
		// 	}
		// }

		//行单击
		// const firClick = (row) => {
		// 	//添加已选数据
		// 	setSelData(row)
		// }

		//切换显示左侧栏内容
		// const handleClsClick = (num) => {
		// 	state.curCls = num
		// 	//刷新表格
		// 	proxy.$refs.table.reload()
		// 	//清除查询条件
		// 	state.queryForm = {}
		// 	if(num == 1){
		// 		state.switchText = '部门'
		// 	}else{
		// 		state.switchText = '角色'
		// 	}
		// }

		//左侧树节点点击
		// const fetchDataNode = (node) => {
		// 	// let params = {}
		// 	// if(type == 1){ //部门
		// 	// 	params = { departmentId: data.id }
		// 	// }else if(type == 2){ //角色
		// 	// 	params = { roleId: data.id }
		// 	// }
		// 	// proxy.$refs.table.reload(params)
		// 	if (node) {
		// 		state.curNode = Object.assign({}, node)
		// 		fetchDataLeft()
		// 	}
		// }

		// //用户搜索
		// const handleSearch = () => {
		// 	proxy.$refs.table.reload(state.queryForm)
		// }

		//分栏拖动结束
		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			// //查询参数{ 字段名：值, 字段名：值, ... }
			data = {
				...state.excelCondition,
				...data
			}
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if (!props.multiple) return false
			if (data && state.selData.indexOf(data) === -1) {
				state.selData.push(data)
			} else {
				state.selData.splice(state.selData.indexOf(data), 1)
			}
			// if(state.selData.length > 0){
			// 	state.isConfirm = false
			// }else{
			// 	state.isConfirm = true
			// }
			showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		//已选数据表选中的数据变化
		const tableSelectChange = (val) => {
			state.selectionsTableSel = val
			if (val.length > 0) {
				state.isDelSel = false
			} else {
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if (index != -1) {
						state.selData.splice(index, 1)
					}
					if (index > -1) {
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		// //新增修改弹窗
		// const handleEdit = (type, ref) => {
		// 	let param = {}
		// 	if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
		// 		param = state.curRow
		// 	}
		// 	proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
		// 	proxy.$refs.refEdit.showDialog(param)
		// }

		watch(
			() => state.selData,
			(val) => {
				if (val.length > 0) {
					state.isConfirm = false
				} else {
					state.isConfirm = true
				}
			},
			{deep: true}
		)

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//表格数据加载完成
		const handleDataChange = () => {
			showChooseData()
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		return {
			...toRefs(state),
			tableSelectChange,
			handleDetail,
			addToRight,
			delSelData,
			// handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			// handleClsClick,
			// handleSearch,
			handleSelectAll,
			handleDataChange,
			handleSelect,
			selectChange,
			handleRowClick,
			// fetchDataNode,
			handleConfirm,
			// handleShowLeft,
			showDialog,
			// firClick,
			hideDialog,
			currentChange,
			handleClosed
		}
	},
})
</script>
