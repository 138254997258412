import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/oa/politic/politicMeet/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/oa/politic/politicMeet/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/oa/politic/politicMeet/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/oa/politic/politicMeet/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/oa/politic/politicMeet/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	confirmExecution: {
		url: `${config.API_URL}/oa/politic/politicMeet/confirmExecution`,
		name: "修改执行状态",
		get: async function (id,status) {
			return await http.get(this.url + '?id=' + id + '&status=' + status);
		}
	},
}
