import config from "@/config"
import http from "@/utils/request"

export default {
	customerStructureDetail: {
		url: `${config.API_URL}/erp/smartSignBoard/customerStructureDetail`,
		name: "客户信息",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	customerDetailById: {
		url: `${config.API_URL}/erp/smartSignBoard/customerDetailById`,
		name: "客户信息",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

}
