import config from "@/config"
import http from "@/utils/request"

export default {
	groupByWasteId: {
		url: `${config.API_URL}/erp/produce/workOrder/waste/groupByWasteId`,
		name: "查询不良品项去汇总列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	groupWasteRatio: {
		url: `${config.API_URL}/erp/produce/workOrder/waste/groupWasteRatio`,
		name: "查询不良品项比率列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
