import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/stock/stockLoss/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/erp/stock/stockLoss/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/stock/stockLoss/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/stock/stockLoss/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/erp/stock/stockLoss/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	getLossGoodsList: {
		url: `${config.API_URL}/erp/stock/stockLossDetail/getList`,
		name: "根据采购合同id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getLossGoodsById: {
		url: `${config.API_URL}/erp/stock/stockLossDetail/getListByLossId`,
		name: "根据采购合同id查询物品明细",
		get: async function (params) {
			return await http.get(this.url + '?lossId=' + params);
		}
	},
	confirm: {
		url: `${config.API_URL}/erp/stock/stockLoss/confirm`,
		name: "确认报损",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/stock/stockLoss/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/erp/stock/stockLoss/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	returnProcess: {
		url: `${config.API_URL}/erp/stock/stockLoss/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},

}
