const TIPS = {
	DELETE: '确认是否删除？',
	SUBMIT: '确认是否保存数据？',
	SUBMIT_PURCHSING: '确认是否转采购申请？',
	SUBMIT_OUTPROCESS: '确认是否转委外加工单？',
	SUBMIT_PROCESS: '确认是否转加工单？',
	// BOM_SUBMIT:'确认是否保存数据并提交审核',
	BOM_SUBMIT:'确认是否提交审批？',
	SAVE:'确认是否提交?',
	BOM_SAVE:'确认是否保存数据?',
	UPDATA:'确认是否更新?',
	SAVEDATA:'确认是否保存?',
	CLEARDATA:'确认是否清除已选菜单的数据?',
	SUBMIT_NOEDIT: '确认是否保存？保存后不能修改！',
	SAVE_SUBMIT: '确认是否提交审批？',
	QUOTATION_LIST: '是否确认报价？',
	UPLOAD: '上传中，请稍等...',
	SUCCESS: '操作成功！',
	FAIL: '操作失败!',
	CONFIRM_TURN: '确认是否转采购订单？',
	CONFIRM_CLOSE: '确认是否关闭？',
	CONFIRM_RESET: '确认是否重置？确认后数据将被还原！',
	CONFIRM_CHECK: '确认是否质检！',
	CONFIRM_WORD: '确认导出word模板？',
	CONFIRM_DEFAULT: '确认是否设置选中数据未默认模板？',
	CONFIRM_SIGN: '是否确认签收?',

	ENABLE: '确认是否启用？',
	UNABLE: '确认是否停用？',
	SCRAP: '确认是否报废？',
	SEALING: '确认是否封存？',
	LEND: '确认是否借出？',
	TRANSFER: '确认是否转让？',
	REPAIR: '确认是否维修？',
	SUBMIT_: "确认审批？",

	OVERDUE: '确认是否将选中的数据设置为已过期？',
	ERROR_EDIT: '数据错误，关闭当前标签页，重新进入！',
	COLUMN_RESET: '确认是否初始化？确认后表格列将恢复初始状态!',

	//入库
	ENTER_DEPOT: '确认是否入库？确认后库存数量将会增加!',
	//出库
	OUT_DEPOT: '确认是否出库？确认后库存数量将会减少!',
	CONTRACT_ENABLE: '确认是否生效该合同？',

	// 转采购申请
	PURCHASE_REQUISITION : '确认是否转采购申请',
//     销售未税单价为0
	SALEPRICE:'未税单价为0是否继续操作？',
	SALETAXPRICE:'含税单价为0是否继续操作？'
}
module.exports = TIPS
