import config from "@/config"
import http from "@/utils/request"
/*角色管理*/
export default {
	getList: {
		url: `${config.API_URL}/cfg/power/role/getList`,
		name: "分页角色",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/power/role/getAllList`,
		name: "所有角色",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getMenuList: {
		url: `${config.API_URL}/cfg/power/role/getMenuList`,
		name: "查询菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	roleMenuList: {
		url: `${config.API_URL}/cfg/power/role/getRoleMenuList`,
		name: "查询角色菜单权限",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	userMenuList: {
		url: `${config.API_URL}/cfg/power/role/getUserMenuList`,
		name: "查询用户菜单权限",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/power/role/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	saveRole: {
		url: `${config.API_URL}/cfg/power/role/addSave`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saveRoleMenu: {
		url: `${config.API_URL}/cfg/power/role/saveRoleMenu`,
		name: "保存角色权限",
		post: async function(data={},config={}){
			return await http.post(this.url,data,config);
		}
	},
	saveUserMenu: {
		url: `${config.API_URL}/cfg/power/role/saveUserMenu`,
		name: "保存用户权限",
		post: async function(data={},config={}){
			return await http.post(this.url,data,config);
		}
	},
	detailRole: {
		url: `${config.API_URL}/cfg/power/role/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	saveUserWapMenu: {
		url: `${config.API_URL}/cfg/power/wap/permissions/setUserMenu`,
		name: "保存用户手机权限",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saveRoleWapMenu: {
		url: `${config.API_URL}/cfg/power/wap/permissions/setRoleMenu`,
		name: "保存角色手机权限",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	userWapMenuList: {
		url: `${config.API_URL}/cfg/power/wap/permissions/getUserMenu`,
		name: "查询手机端用户菜单权限",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	roleWapMenuList: {
		url: `${config.API_URL}/cfg/power/wap/permissions/getRoleMenu`,
		name: "查询手机端角色菜单权限",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getWapMenuList: {
		url: `${config.API_URL}/cfg/power/wap/permissions/getWapMenu`,
		name: "查询手机端菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
