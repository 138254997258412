<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
  >
    <el-descriptions
      :title="''"
      :column="2"
      :size="$store.state.global.uiSize"
      border
      class="column2"
    >
      <el-descriptions-item
        label="签订日期"
        label-align="right"
      >
        {{ data.date }}
      </el-descriptions-item>
      <el-descriptions-item
        label="项目名称"
        label-align="right"
      >
        {{ data.name }}
      </el-descriptions-item>
      <el-descriptions-item
        label="客户名称"
        label-align="right"
      >
        {{ data.customer }}
      </el-descriptions-item>
      <el-descriptions-item
        label="经办人"
        label-align="right"
      >
        {{ data.fz_agentName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="项目类型"
        label-align="right"
      >
        {{ data.stype }}
      </el-descriptions-item>
      <el-descriptions-item
        label="项目金额(元)"
        label-align="right"
      >
        {{ data.amount }}
      </el-descriptions-item>
      <el-descriptions-item
        label="备注"
        label-align="right"
      >
        {{ data.remark }}
      </el-descriptions-item>
      <el-descriptions-item
        label="是否验收"
        label-align="right"
      >
        {{ data.isCheck === 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="data.isCheck === 1"
        label="验收日期"
        label-align="right"
      >
        {{ data.checkDate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="实际收款(元)"
        label-align="right"
      >
        {{ data.receipt }}
      </el-descriptions-item>
      <el-descriptions-item
        label="业务提成(元)"
        label-align="right"
      >
        {{ data.commission }}
      </el-descriptions-item>
      <el-descriptions-item
        label="是否发放"
        label-align="right"
      >
        {{ data.isGrant === 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="data.isGrant === 1"
        label="发放日期"
        label-align="right"
      >
        {{ data.grantDate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="操作用户"
        label-align="right"
      >
        {{ data.fz_operateName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="操作时间"
        label-align="right"
      >
        {{ data.addTime }}
      </el-descriptions-item>
    </el-descriptions>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//条款数据
			clauseList: [],
      type:"detail",
			//弹窗宽度
			width: '60%',
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '项目详情',
			//详情数据对象
			data: {},
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true
			state.fileList = []
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.projectProject.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
				}
			}
			state.loading = false
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}
		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}
		return {
			...toRefs(state),
			showDialog,
			hideDialog,
			previewFile
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

