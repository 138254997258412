<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
	>
		<el-card
			shadow="never"
			class="mycard"
			header="基本信息"
		>
			<el-descriptions
				:title="''"
				:column="2"
				:size="$store.state.global.uiSize"
				border
				class="column2"
			>

				<el-descriptions-item
					label="标准BOM编号"
					label-align="right"
				>
					{{ data.bomNum }}
				</el-descriptions-item>

				<el-descriptions-item
					label="审核状态"
					label-align="right"
					v-if="!data.history"
				>
					<el-tag
						v-if="data.auditStatus === 0"
						style="color: #666;"
						:size="$store.state.global.uiSize"
					>
						未提交
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 1"
						type="warning"
						:size="$store.state.global.uiSize"
					>
						审核中
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 2"
						type="danger"
						:size="$store.state.global.uiSize"
					>
						已驳回
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 3"
						type="success"
						:size="$store.state.global.uiSize"
					>
						审核通过
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 4"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未确认
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 5"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已确认
					</el-tag>
				</el-descriptions-item>

				<el-descriptions-item
					label="产品名称"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refGoodsDetail', 'goodsId')"
					>
						{{ data.fz_goodsName }}
					</el-link>
				</el-descriptions-item>

				<el-descriptions-item
					label="产品编码"
					label-align="right"
				>
					{{ data.fz_goodsCode }}
				</el-descriptions-item>

				<el-descriptions-item
					label="规格型号"
					label-align="right"
				>
					{{ data.fz_goodsSpec }}
				</el-descriptions-item>

				<!--		  <el-descriptions-item-->
				<!--			  label="材质"-->
				<!--			  label-align="right"-->
				<!--		  >-->
				<!--			  {{ data.fz_goodsMaterial }}-->
				<!--		  </el-descriptions-item>-->

				<el-descriptions-item
					label="版本号"
					label-align="right"
				>
					{{ data.edition }}
				</el-descriptions-item>

				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
          <template v-if="data.history">
            {{ data.updateTime }}
          </template>
          <template v-else>
            {{ data.addTime }}
          </template>
				</el-descriptions-item>
				<el-descriptions-item
					v-for="item in dataList"
					:key="item.value"
					:label="item.name"
					label-align="right">
					{{ Array.isArray(data[item.value]) ? data[item.value].join(",") : data[item.value] }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:column="1"
				:size="$store.state.global.uiSize"
				border
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<!--      <el-descriptions-->
			<!--        :title="''"-->
			<!--        :column="1"-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        border-->
			<!--        class="column1"-->
			<!--      >-->
			<!--        <el-descriptions-item-->
			<!--          label="附件"-->
			<!--          label-class-name="noTopBorder"-->
			<!--          class-name="noTopBorder"-->
			<!--          :label-align="labelAlign"-->
			<!--        >-->
			<!--          <div class="mtf_5">-->
			<!--            <el-tag-->
			<!--              v-for="(file, index) in fileList"-->
			<!--              :key="index"-->
			<!--              class="dtFile"-->
			<!--              @click="previewFile(file)"-->
			<!--            >-->
			<!--              <a href="javascript:">{{ file.name }}</a>-->
			<!--            </el-tag>-->
			<!--          </div>-->
			<!--        </el-descriptions-item>-->
			<!--      </el-descriptions>-->
		</el-card>
		<el-card
			shadow="never"
			class="mycard mt_10"
			header="物品明细"
		>
			<scTable
				ref="detailTableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				:height="'auto'"
				:max-height="300"
				row-key="id"
				border
				remote-sort
				@dataChange="dataChange"
			>
				<!--自定义单元格显示 #{字段名称} -->
				<template #fz_goodsName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
					>
						{{ scope.row.fz_goodsName }}
					</el-link>
				</template>
				<template #fz_goodsStype="{ scope }">
					<span v-if="scope.row.fz_goodsStype == 0">原材料</span>
					<span v-if="scope.row.fz_goodsStype == 1">半成品</span>
					<span v-if="scope.row.fz_goodsStype == 2">成品</span>
				</template>

				<template #files="{ scope }">
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in scope.row.fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							{{ file.name }}
						</el-tag>
					</div>
				</template>
				<!--自定义字段-->
				<template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
					{{ Array.isArray(scope.row[item.value]) ? scope.row[item.value].join(",") : scope.row[item.value] }}
				</template>
				<template #source="{scope}">
					<span v-if="scope.row.source === 1">采购</span>
					<span v-if="scope.row.source === 2">自制</span>
					<span v-if="scope.row.source === 3">委外</span>
				</template>
			</scTable>
		</el-card>
		<!--    <template #footer>-->
		<!--      <div class="text-center">-->
		<!--        <el-button-->
		<!--          :size="$store.state.global.uiSize"-->
		<!--          @click="visible = false"-->
		<!--        >-->
		<!--          关 闭-->
		<!--        </el-button>-->
		<!--      </div>-->
		<!--    </template>-->
		<GoodsDetail ref="refGoodsDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from "vue";
import GoodsDetail from "@/views/cfg/data/goods/detail";

export default defineComponent({
	components: {GoodsDetail},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			dataList: [],
			sonDataList: [],
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: "60%",
			type: "detail",
			//高度
			height: "400px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "标准BOM详情",
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					// {
					// 	label: '供应商',
					// 	prop: 'fz_goodsName',
					// 	width: 160,
					// 	sortable: 'custom',
					// 	fixed: 'left',
					// },
					{
						label: "物品名称",
						prop: "fz_goodsName",
						sortable: "custom",
						fixed: "left",
						width: 180,
					},
					{
						label: "物品编码",
						prop: "fz_goodsCode",
						sortable: "custom",
						width: 120,
					},
					// {
					// 	label: '附件',
					// 	prop: 'files',
					// 	width: 150,
					// },
					{
						label: "规格型号",
						prop: "fz_goodsSpec",
						width: 120,
						sortable: "custom",
					},
					{
						label: "来源",
						prop: "source",
						width: 120,
						sortable: "custom",
					},
					// {
					// 	label: '材质',
					// 	prop: 'fz_goodMaterial',
					// 	width: 180,
					// 	sortable: 'custom',
					// },

					{
						label: "版本号",
						prop: "edition",
						width: 100,
						sortable: "custom",
					},
					{
						label: "物品类型",
						prop: "fz_goodsStype",
						width: 120,
						sortable: "custom",
						filters: [
							{text: "产品", value: "1"},
							{text: "物料", value: "0"},
						]
					},

					// {
					// 	label: '材料牌号',
					// 	prop: 'fz_goodsBrandNumber',
					// 	sortable: 'custom',
					// 	width: 120,
					// },

					// {
					// 	label: '净重 ',
					// 	prop: 'fz_goodsModel',
					// 	width: 120,
					// 	sortable: 'custom',
					// },

					{
						label: "数量",
						prop: "demandQuantity",
						width: 110,
						sortable: "custom",
					},
					{
						label: "耗损率(%)",
						prop: "wearRate",
						width: 130,
						sortable: "custom",
					},
					{
						label: "耗损数量",
						prop: "lossQuantity",
						disable: true,
						width: 110,
						sortable: "custom",
					},

					{
						label: "主单位",
						prop: "unit",
						width: 110,
						sortable: "custom",
					},

					{
						label: "辅助单位",
						prop: "assistUnit",
						width: 110,
						sortable: "custom",
					},
					{
						label: "换算数量",
						prop: "assistQuantity",
						width: 110,
						sortable: "custom",
					},

					{
						label: "备注",
						prop: "remark",
						width: 180,
						sortable: "custom",
					},
				],
			},
		});

		onMounted(() => {
			sonListMap();
			dataListMap();
		});

		// 获取产品明细自定义字段
		const sonListMap = async () => {

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 2, isEnabled: 1, menuCode: "erp_produce_bom",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if (item.stype === 4) {
						item.value = "customField" + item.fieldLength + "List";
					} else {
						item.value = "customField" + item.fieldLength;
					}
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.sonDataList = res.data.list;
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: "custom",
				},);
			});
		};

		// 获取自定义字段
		const dataListMap = async () => {
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 1, isEnabled: 1, menuCode: "erp_produce_bom",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if (item.stype == 4) {
						item.value = "customField" + item.fieldLength + "List";
					} else {
						item.value = "customField" + item.fieldLength;
					}
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.dataList = res.data.list;
			}
		};
		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true;
			state.fileList = [];
      let res;
      //根据id查询
      if (data.history) {
        if(data.inUse){
          res = await proxy.$API.produceBom.showHistoryDetail.get('',data.bomId);
        }else{
          res = await proxy.$API.produceBom.showHistoryDetail.get(data.historyBomId,'');
        }
      } else {
        res = await proxy.$API.produceBom.detail.get(data.id);
      }
      if (res.code === 200) {
        state.data = res.data;
        state.data.history = data.history
        state.data.inUse = data.inUse
        //获取明细表数据
        await getGoodsList();
        //组装文件列表
        await getFileList(state.data.files);
      }
			state.loading = false;
		};

		const getGoodsList = () => {
      let apiObj;
			if (state.data.history) {
        if(state.data.inUse){
          //将接口对象赋给表格
          apiObj = proxy.$API.produceBomDetail.selectBomDetail
        }else{
          apiObj = proxy.$API.produceBom.selectHistoryBomDetail
        }
			}else{
        //将接口对象赋给表格
        apiObj = proxy.$API.produceBomDetail.selectBomDetail;
      }
      state.tableSonConfig.apiObj = apiObj
      state.tableSonConfig.params = {bomId: state.data.id};
      if(proxy.$refs.detailTableSon){
        proxy.$refs.detailTableSon.reload({bomId: state.data.id})
      }
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};
		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
			await proxy.$API.files.insertFilesDownload.post(file);
		};
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};

		const dataChange = (row) => {
			if (row.data.list && row.data.list.length > 0) {
				row.data.list.forEach((item) => {
					const file = item.files;
					if (file) {
						let arr = [];
						let fileList = [];
						if (file.length > 0) {
							arr = file.split(",");
							arr.forEach((item) => {
								let obj = {};
								const iarr = item.split("/");
								obj["url"] = item;
								obj["name"] = iarr[iarr.length - 1];
								fileList.push(obj);
							});
						}
						item.fileList = fileList;
						state.fileList = item.fileList;
					}

				});

			}

		};

		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
			previewFile,
			dataChange
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

