// 静态路由配置
// 书写格式与动态路由格式一致，全部经由框架统一转换
// 比较动态路由在meta中多加入了role角色权限，为数组类型。一个菜单是否有权限显示，取决于它以及后代菜单是否有权限。
// routes 显示在左侧菜单中的路由(显示顺序在动态路由之前)
// 示例如下
const routes = [
    {
        id: 'navigation',
        path: "/navigations",
        name: 'Navigations',
        component: 'home/navigations.vue',
        meta: {
            title: "功能导航"
        }
    },
	/*{
		name: "Tables",
		path: "/tables",
		meta: {
			icon: "el-icon-eleme-filled",
			title: "表格模板"
		},
		children: [
			{
				name: 'BaseTable',
				path: '/tables/baseTable',
				meta: {
					icon: 'el-icon-eleme-filled',
					title: '基础表格',
				},
				component: 'pc/table/baseTable/index',
				children: [

				]
			},
			{
				name: 'EditPage',
				path: '/tables/baseTable/editPage',
				meta: {
					icon: 'el-icon-eleme-filled',
					title: '跳转页面新增',
					hidden: true,
				},
				component: 'pc/table/baseTable/components/editPage',
			},
			{
				name: 'ColumnTable',
				path: '/tables/columnTable',
				meta: {
					icon: 'el-icon-eleme-filled',
					title: '子母表格',
				},
				component: 'pc/table/columnTable/index',
			},
		],
	}*/
]

export default routes;
