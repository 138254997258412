import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	getAllList: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/getAllList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	insert: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/updateFlag1`,
		name: "修改状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/updateFlag0`,
		name: "修改状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateDefault: {
		url: `${config.API_URL}/cfg/finance/voucher/projectManagement/updateDefaul1`,
		name: "设置默认",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},

}
