import config from "@/config"
import http from "@/utils/request"

export default {
	checkOut: {
		url: `${config.API_URL}/fms/cashier/cashierCheckout/checkOut`,
		name: "结账",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	backCheckOut: {
		url: `${config.API_URL}/fms/cashier/cashierCheckout/backCheckOut`,
		name: "反结账",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
