import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/report/TrialBalance/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/fms/report/TrialBalance/getAllList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/report/TrialBalance/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/report/TrialBalance/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/report/TrialBalance/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/report/TrialBalance/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	financialTrialBalance: {
		url: `${config.API_URL}/fms/report/TrialBalance/financialTrialBalance`,
		name: "试算平衡",
		get: async function (data={}) {
			return await http.post(this.url, data);
		}
	},
}
