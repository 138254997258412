import config from "@/config"
import http from "@/utils/request"

export default {
	salePrice: {
		url: `${config.API_URL}/erp/sharing/price/salePrice `,
		name: "销售参考价格计算",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	purchasePrice: {
		url: `${config.API_URL}/erp/sharing/price/purchasePrice `,
		name: "采购参考价格计算",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
