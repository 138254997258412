import config from "@/config"
import http from "@/utils/request"

export default {
	update: {
		url: `${config.API_URL}/sys/base/shop/update`,
		name: "保存设置",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	shopDetail : {
		url: `${config.API_URL}/sys/base/shop/shopDetail`,
		name: "商户详情",
		get: async function(){
			return await http.get(this.url);
		}
	},
}
