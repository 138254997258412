import config from "@/config"
import http from "@/utils/request"

export default {
	selectRefundGoods: {
		url: `${config.API_URL}/erp/sale/refund/goods/selectRefundGoods`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	queryByCustomerId: {
		url: `${config.API_URL}/erp/sale/refund/goods/queryByCustomerId`,
		name: "根据商户id查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
