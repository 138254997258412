<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :bef-close="false"
    fullscreen
    :show-fullscreen="false"
  >
    <div class="wap-container pdb10">
      <el-card
        header="基本信息"
        shadow="never"
        class="mycard detail"
      >
        <el-descriptions
          :title="''"
          :column="2"
          :size="$store.state.global.uiSize"
          border
          class="column2"
        >
		  <el-descriptions-item
			label="物品名称"
			label-align="right"
		  >
			  {{ data.name }}
		  </el-descriptions-item>
          <el-descriptions-item
            label="物品编码"
            label-align="right"
          >
            {{ data.code }}
          </el-descriptions-item>
          <el-descriptions-item
            label="物品类型"
            label-align="right"
          >
            {{ data.stype == 1 ? '产品' : '物料' }}
          </el-descriptions-item>
          <el-descriptions-item
            label="规格"
            label-align="right"
          >
            {{ data.spec }}
          </el-descriptions-item>
          <el-descriptions-item
            label="型号"
            label-align="right"
          >
            {{ data.model }}
          </el-descriptions-item>
          <el-descriptions-item
            label="物品类别"
            label-align="right"
          >
            {{ data.fz_classifyName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="所属仓库"
            label-align="right"
          >
            {{ data.fz_depotName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="保质期(天)"
            label-align="right"
          >
            {{ data.guarantee }}
          </el-descriptions-item>

          <el-descriptions-item
            label="最小采购量"
            label-align="right"
          >
            {{ data.purchaseQuantity }}
          </el-descriptions-item>
<!--          <el-descriptions-item-->
<!--            label="参考成本价"-->
<!--            label-align="right"-->
<!--          >-->
<!--            {{ data.referenceCost }}-->
<!--          </el-descriptions-item>-->
<!--          <el-descriptions-item-->
<!--            label="参考销售价"-->
<!--            label-align="right"-->
<!--          >-->
<!--            {{ data.referencePrice }}-->
<!--          </el-descriptions-item>-->
<!--          <el-descriptions-item-->
<!--            label="税率(%)"-->
<!--            label-align="right"-->
<!--          >-->
<!--            {{ data.taxRate }}-->
<!--          </el-descriptions-item>-->
          <el-descriptions-item
            label="库存上限"
            label-align="right"
          >
            {{ data.inventoryCap }}
          </el-descriptions-item>
          <el-descriptions-item
            label="库存下限"
            label-align="right"
          >
            {{ data.inventoryLower }}
          </el-descriptions-item>
          <el-descriptions-item
            label="组装拆卸"
            label-align="right"
          >
            {{ data.assembly == 1 ? '是' : '否' }}
          </el-descriptions-item>
          <el-descriptions-item
            label="启用状态"
            label-align="right"
          >
            <el-tag
              v-if="data.flag === 1"
              type="success"
              :size="$store.state.global.uiSize"
            >
              正常
            </el-tag>
            <el-tag
              v-if="data.flag === 0"
              type="danger"
              :size="$store.state.global.uiSize"
            >
              停用
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item
            label="自动停用日期"
            label-align="right"
          >
            {{ data.deactivationDate }}
          </el-descriptions-item>
          <el-descriptions-item
            label="供应商名称集合"
            label-align="right"
          >
            {{ data.fz_supplierName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="客户名称集合"
            label-align="right"
          >
            {{ data.fz_customerName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="可替代物品名称集合"
            label-align="right"
          >
            {{ data.fz_replaceGoodsName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="用途"
            label-align="right"
          >
            {{ data.purpose }}
          </el-descriptions-item>
<!--          <el-descriptions-item-->
<!--            label="装箱规格"-->
<!--            label-align="right"-->
<!--          >-->
<!--            {{ data.packing }}-->
<!--          </el-descriptions-item>-->
<!--          <el-descriptions-item-->
<!--            label="存储条件"-->
<!--            label-align="right"-->
<!--          >-->
<!--            {{ data.storage }}-->
<!--          </el-descriptions-item>-->
          <!--<el-descriptions-item
			  label="审核状态"
			  label-align="right"
			>
			  <el-tag
				v-if="data.auditStatus === 0"
				type="info"
				:size="$store.state.global.uiSize"
			  >
				未提交
			  </el-tag>
			  <el-tag
				v-if="data.auditStatus === 1"
				type="warning"
				:size="$store.state.global.uiSize"
			  >
				审核中
			  </el-tag>
			  <el-tag
				v-if="data.auditStatus === 2"
				type="danger"
				:size="$store.state.global.uiSize"
			  >
				已驳回
			  </el-tag>
			  <el-tag
				v-if="data.auditStatus === 3"
				type="success"
				:size="$store.state.global.uiSize"
			  >
				审核通过
			  </el-tag>
			</el-descriptions-item>-->
          <el-descriptions-item
            label="操作用户"
            label-align="right"
          >
            {{ data.fz_operateName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="操作时间"
            label-align="right"
          >
            {{ data.addTime }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :title="''"
          :size="$store.state.global.uiSize"
          border
          :column="1"
          class="column1"
        >
          <el-descriptions-item
            label="封面"
            label-align="right"
            label-class-name="noTopBorder"
            class-name="noTopBorder"
          >
            <el-image
              fit="cover"
              class="imgViewItem"
              :preview-src-list="[$CONFIG.API_URL + '/'+'api/' +data.img]"
              hide-on-click-modal
              :z-index="9999"
              :src="$CONFIG.API_URL + '/'+'api/' +data.img"
            />
          </el-descriptions-item>
<!--        </el-descriptions>-->
<!--        <el-descriptions-->
<!--          :title="''"-->
<!--          :size="$store.state.global.uiSize"-->
<!--          border-->
<!--          :column="1"-->
<!--          class="column1"-->
<!--        >-->
          <el-descriptions-item
            label="描述"
            label-class-name="noTopBorder"
            class-name="noTopBorder"
            :label-align="labelAlign"
          >
            {{ data.represent }}
          </el-descriptions-item>
<!--        </el-descriptions>-->
<!--        <el-descriptions-->
<!--          :title="''"-->
<!--          :size="$store.state.global.uiSize"-->
<!--          border-->
<!--          :column="1"-->
<!--          class="column1"-->
<!--        >-->
          <el-descriptions-item
            label="备注"
            label-class-name="noTopBorder"
            class-name="noTopBorder"
            :label-align="labelAlign"
          >
            {{ data.remark }}
          </el-descriptions-item>
<!--        </el-descriptions>-->
<!--        <el-descriptions-->
<!--          :title="''"-->
<!--          :size="$store.state.global.uiSize"-->
<!--          border-->
<!--          :column="1"-->
<!--          class="column1"-->
<!--        >-->
          <el-descriptions-item
            label="图片"
            label-class-name="noTopBorder"
            class-name="noTopBorder"
            :label-align="labelAlign"
          >
            <div class="mtf_5">
              <el-image
                v-for="(file, index) in imgsList"
                :key="index"
                fit="cover"
                class="imgViewItem"
                :preview-src-list="imgsPreviewList"
                hide-on-click-modal
                :z-index="9999"
                :src="$CONFIG.API_URL + '/'+'api/' +file.url"
              />
            </div>
          </el-descriptions-item>
<!--        </el-descriptions>-->
<!--        <el-descriptions-->
<!--          :title="''"-->
<!--          :size="$store.state.global.uiSize"-->
<!--          border-->
<!--          :column="1"-->
<!--          class="column1"-->
<!--        >-->
          <el-descriptions-item
            label="附件"
            label-class-name="noTopBorder"
            class-name="noTopBorder"
            :label-align="labelAlign"
          >
            <div class="mtf_5">
              <el-tag
                v-for="(file, index) in fileList"
                :key="index"
                class="dtFile"
                @click="previewFile(file)"
              >
                <a href="javascript:">{{ file.name }}</a>
              </el-tag>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card
        header="计量单位"
        shadow="never"
        class="mycard mt_10"
      >
        <el-descriptions
          :title="''"
          :column="1"
          :size="$store.state.global.uiSize"
          border
          class="column1"
        >
          <el-descriptions-item
            label="主计量单位"
            label-align="right"
          >
            {{ data.mainUnit }}
          </el-descriptions-item>
          <el-descriptions-item
            label="辅计量单位1"
            label-align="right"
          >
            {{ data.assistUnit1 }}
            <span v-if="data.assistUnit1 && data.assistRatio1">
              【1 {{ data.assistUnit1 }} = {{ data.assistRatio1 }} {{ data.mainUnit }}】
            </span>
          </el-descriptions-item>
          <el-descriptions-item
            label="辅计量单位2"
            label-align="right"
          >
            {{ data.assistUnit2 }}
            <span v-if="data.assistUnit2 && data.assistRatio2">
              【1 {{ data.assistUnit2 }} = {{ data.assistRatio2 }} {{ data.mainUnit }}】
            </span>
          </el-descriptions-item>
          <el-descriptions-item
            label="辅计量单位3"
            label-align="right"
          >
            {{ data.assistUnit3 }}
            <span v-if="data.assistUnit3 && data.assistRatio3">
              【1 {{ data.assistUnit3 }} = {{ data.assistRatio3 }} {{ data.mainUnit }}】
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card
        header="预售价格"
        shadow="never"
        class="mycard mt_10"
      >
        <el-table
          :data="dataGoodsPrice"
          border
          :size="$store.state.global.uiSize"
        >
          <el-table-column
            prop="fz_goodsPriceName"
            width="200"
            label="物品价格名称"
          />
          <el-table-column
            prop="stype"
            label="价格类型"
            width="100"
          >
            <template #default="scope">
              <span>{{ scope.row.stype == 1 ? '销售价' : '成本价' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="单价"
          />
        </el-table>
      </el-card>
      <el-card
        header="库存限量"
        shadow="never"
        class="mycard mt_10"
      >
        <el-table
          :data="productLimitList"
          :size="$store.state.global.uiSize"
          border
        >
          <el-table-column
            prop="fz_depotName"
            label="仓库名称"
            width="180"
          />
          <el-table-column
            prop="upper"
            label="数量上限"
            width="120"
          />
          <el-table-column
            prop="lower"
            label="数量下限"
            width="120"
          />
          <el-table-column
            prop="fz_upperUserNames"
            label="上限提醒人"
            width="200"
          />
          <el-table-column
            prop="fz_lowerUserNames"
            label="下限提醒人"
            width="200"
          />
        </el-table>
      </el-card>
    </div>

    <template #footer>
      <div class="text-center">
        <el-button
          :size="$store.state.global.uiSize"
          @click="visible = false"
        >
          关 闭
        </el-button>
      </div>
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	name: 'GoodsDetail',
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '60%',
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '物品详情',
			//详情数据对象
			data: {},
			dataGoodsPrice: [],
			productLimitList: [],
			fileList: [],
			imgsList: [],
			imgsPreviewList: [],
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = []
			state.imgsList = []
			state.imgsPreviewList = []
			state.dataGoodsPrice = []
			state.productLimitList = []
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.goods.detail.get(data.id)
				if (res.code === 200) {
					state.dataGoodsPrice = res.data.goodsPriceList
					state.productLimitList = res.data.productLimitList
					state.data = res.data
					getFileList(res.data.imgs, 'imgsList')
					getFileList(res.data.files, 'fileList')
				}
			}
			state.loading = false
		}

		//组装文件列表
		const getFileList = (data, list) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state[list].push(obj)
						if (list === 'imgsList') {
							state.imgsPreviewList.push(proxy.$CONFIG.API_URL + '/' + 'api/' + item)
						}
					})
				}
			}
		}

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		return {
			...toRefs(state),
			showDialog,
			previewFile,
			hideDialog,
		}
	},
})
</script>

<style>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
.detail{
	.el-descriptions__label.el-descriptions__cell.is-bordered-label{
		width: 25%!important;
	}
	.column2 {
		.el-descriptions__content.el-descriptions__cell.is-bordered-content {
			width: 25% !important;
		}
	}
}

</style>

