<!--选择供应商弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:bef-close="false"
		:height="'500px'"
		:loading="loading"
		:title="dftitle"
		:trigger-dom="triggerDom"
		:width="width"
		custom-class="popTable"
	>
		<el-container>
<!--			<el-main class="nopadding">-->
<!--				<el-container-->
<!--					class="mainPanel pdr10"-->
<!--				>-->
					<sy-search-Form
						v-show="!hideForm"
						ref="refFormBar"
						:configs="searchConfig"
						:show-item="showItem"
						@fetch-data="fetchData"
						@set-show="handleFitShow"
					/>
					<el-header class="tableTopBar">
						<div class="left-panel"></div>
						<div class="right-panel">
							<!--切换显示搜索栏hide-after: 延迟隐藏-->
							<el-tooltip
								:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
								:hide-after="0"
								placement="bottom"
							>
								<el-link @click="handleShowSearch">
									搜索
									<el-icon>
										<template v-if="hideForm">
											<el-icon-arrowDown/>
										</template>
										<template v-else>
											<el-icon-arrowUp/>
										</template>
									</el-icon>
								</el-link>
							</el-tooltip>
						</div>
					</el-header>

					<!--主数据表格-->
					<scTable
						ref="table"
						:api-obj="tableConfig.apiObj"
						:checkbox="multiple"
						:column="tableConfig.columns"
						:pagination-layout="'total, prev, pager, next, jumper'"
						:params="tableConfig.params"
						border
						hide-column-setting
						hide-setting
						highlight-current-row
						radio-check
						remote-filter
            :ref-query-form="refFormBar"
						remote-sort
						row-key="id"
						@data-change="dataChange"
						@selection-change="selectChange"
						@row-click="handleRowclick"
					>

						<template #fz_goodsName="{ scope }">
							<el-link
								type="primary"
								@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
							>
								{{ scope.row.fz_goodsName }}
							</el-link>
						</template>
						<template #submType="{scope}">
							<span v-if="scope.row.submType == 1">无损检测</span>
							<span v-if="scope.row.submType == 2">有损检测</span>
						</template>
						<template #qualityStatus="{scope}">
							<span v-if="scope.row.qualityStatus == 0">未检测</span>
							<span v-if="scope.row.qualityStatus == 1">合格</span>
							<span v-if="scope.row.qualityStatus == 2">不合格</span>
						</template>
					</scTable>
<!--				</el-container>-->
<!--			</el-main>-->
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<el-button
				:size="$store.state.global.uiSize"
				@click="visible = false"
			>
				关闭
			</el-button>
		</template>
		<!--选择供应商-->
		<SelectSupplier
			ref="refSelectSupplier"
			@set-data="setSelectData"
		/>
		<Detail ref="refDetail"/>
		<GoodsDetail ref="refGoodsDetail"/>
	</sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs, watch,} from 'vue'
import {getNowDate} from "@/utils/date";
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";

export default defineComponent({
	components: {GoodsDetail},
	props: {
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: '选择委外送检单明细'},
	},
	emits: ['set-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			//选择供应商
			fz_supplierName: '',
			supplierId: '',
			fields: null,
			multiples: false,
			cacheFields: {},
			fileList: [],
			ids: [],
			paneSize1: 25,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//显示编辑那妞
			showEdit: false,
			//隐藏搜索表单
			hideForm: false,
			//编辑按钮状态
			isDisabled: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: null,
				params: {},
				columns: [
					{
						label: '委外送检单',
						prop: 'fz_submOutsourceNo',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '委外订单',
						prop: 'fz_orderNo',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						sortable: 'custom',
						width: 200,
					},
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						width: 180,
					},
					{
						label: '检测类型',
						prop: 'submType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '送检数量',
						prop: 'number',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '质检状态',
						prop: "qualityStatus",
						width: 110,
					},
					{
						label: '备注',
						prop: "remarks",
						width: 180,
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				params: {},
				//查询表单项配置
				itemList: [
					{
						label: '委外送检单',
						prop: 'fz_submOutsourceNo',
						type: 'input',
						default: true,
					},
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						type: 'input',
						default: true,
					},
				],
			},
			//是否只能选择相同供应商
			isSameSup: false,
		})

		//显示弹窗
		const showDialog = (params,bool = false) => {
			state.visible = true
			state.loading = false
			state.isSameSup = bool
			if(params){
				state.tableConfig.params = Object.assign({},params)
			}
			state.tableConfig.apiObj = proxy.$API.qualitySubmOutsource.getDetailList
			// state.tableConfig.params = Object.assign({}, {turnState: 0, auditStatus: 3})
			// state.searchConfig.params = Object.assign({}, {turnState: 0, auditStatus: 3})

		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}


		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}


		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		const handleRowclick = (row) => {
			if(row){
				proxy.$refs.table.toggleRowSelection(row)
			}
		}
		//确定按钮点击
		const handleConfirm = async () => {
			if (state.selections.length < 1) {
				proxy.$baseMessage('请选择明细！', 'warning')
				return
			}
			if (state.isSameSup) {
				let isDiff = false
				state.selections.forEach((item)=>{
					if(item.fz_supplierName != state.selections[0].fz_supplierName){
						isDiff = true
					}
				})
				if (isDiff) {
					proxy.$baseMessage('请选择供应商相同的订单！', 'warning')
					return
				}
			}
			let data = state.selections

			//触发父级赋值方法
			emit('set-data', data)
			//隐藏弹窗
			hideDialog()
		}


		//分栏拖动结束
		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		// 搜索的时候加参数
		// data 是输入框中的参数
		const fetchData = (data) => {
			data = {
				...state.searchConfig.params,
				...data
			}
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//表格数据变化
		const dataChange = (res) => {
			if (res && res.data.list.length > 0) {
				for (let i = 0; i < res.data.list.length; i++) {
					if (res.data.list[i].supplierId != null) {
						state.ids.push(res.data.list[i].supplierId)
					} else {
						state.ids.push(null)
					}
				}

				res.data.list.forEach((item) => {
					const file = item.files;
					if (file) {
						let arr = []
						let fileList = []
						if (file.length > 0) {
							arr = file.split(',')
							arr.forEach((item) => {
								let obj = {}
								const iarr = item.split('/')
								obj['url'] = item
								obj['name'] = iarr[iarr.length - 1]
								fileList.push(obj)
							})
						}
						item.fileList = fileList;
						state.fileList = item.fileList
					}

				});

				state.ids = [...new Set(state.ids)];


			}

		}


		/*--------------------已选数据操作---------------------------*/


		//已选数据表选中的数据变化


		watch(
			() => state.selections,
			(val) => {
				if (val && val.length > 0) {
					state.isConfirm = false
				} else {
					state.isConfirm = true
				}
			},
			{deep: true}
		)


		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//输入框清除
		const inputClear = (field) => {
			state[field + 'Id'] = ''
			state['fz_' + field + 'Name'] = ''
			state.cacheFields['fz_' + field + 'Name'] = ''
		}

		//打开选择弹窗 ref触发对象 fields赋值的字段集合 type:1单选 2多选
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if (type === 1) { //单选
					state.multiples = false
				} else { //多选
					state.multiples = true
				}
				if (pop === 'refSelectSupplier' || pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		//表单中选择项赋值
		const setSelectData = async (data) => {
			if (state.fields.length > 0) {
				if (data.length) { //多选
					const ids = data.map((item) => {
						return item.id
					}).join()
					const names = data.map((item) => {
						return item.name
					}).join()
					state[state.fields[0]] = ids
					state[state.fields[1]] = names
				} else { //单选
					if (state.fields[0] === 'contacts') {
						state[state.fields[0]] = data.name
					} else {
						state[state.fields[0]] = data.id
						state[state.fields[1]] = data.name
					}
					if (state.fields) {
						//如果是选择客户,查询联系人信息,取第一条赋值
						if (state.fields[0] === 'supplierId') {
							const res = await proxy.$API.supplier.getContactList.get({supplierId: data.id});
							if (res.data && res.data.length > 0) {
								state['contacts'] = res.data[0].name
								state['phone'] = res.data[0].phone
								state['tel'] = res.data[0].tel
							}
						}
						//选择联系人带出手机号
						if (state.fields[0] === 'contacts') {
							state['phone'] = data.phone
							state['tel'] = data.tel
						}
						//业务员
						if (state.fields[0] === 'salesmanId') {
							if (data.departmentId) {
								state['departmentId'] = data.departmentId
							}
						}
					}
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}

		return {
			...toRefs(state),
			handleDetail,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			dataChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			hideDialog,
			previewFile,
			inputClear,
			popSelect,
			setSelectData,
			handleRowclick
		}
	},
})
</script>
