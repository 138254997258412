import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/purchase/order/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/purchase/order/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	contractToOrderInsert: {
		url: `${config.API_URL}/erp/purchase/order/contractToOrderInsert`,
		name: "采购合同转采购订单",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getPurchaseOrder: {
		url: `${config.API_URL}/erp/purchase/order/getPurchaseOrder`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	orderQuerySupplierId: {
		url: `${config.API_URL}/erp/purchase/order/queryBySupplierId`,
		name: "根据id查询",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},

	notSharedGetList: {
		url: `${config.API_URL}/erp/purchase/order/notSharedGetList`,
		name: "不受共享人限制查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	contractShowApply: {
		url: `${config.API_URL}/erp/purchase/contractGoods/showApply`,
		name: "查看采购合同",
		get: async function (params) {
			return await http.get(this.url + '?ContractSonId=' + params);
		}
	},

	del: {
		url: `${config.API_URL}/erp/purchase/order/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	showApply: {
		url: `${config.API_URL}/erp/purchase/order/showApply`,
		name: "查看采购申请单号",
		get: async function (orderSonId,goodsId) {
			return await http.get(this.url + '?orderSonId=' + orderSonId + '&goodsId=' + goodsId);
		}
	},
	showOccupy: {
		url: `${config.API_URL}/erp/purchase/orderGoods/showOccupy`,
		name: "查看采购申请单号",
		get: async function (orderSonId) {
			return await http.get(this.url + '?orderSonId=' + orderSonId);
		}
	},
	showApplyAllot: {
		url: `${config.API_URL}/erp/purchase/order/showApplyAllot`,
		name: "查看采购申请单号",
		get: async function (orderSonId,goodsId) {
			return await http.get(this.url + '?orderSonId=' + orderSonId + '&goodsId=' + goodsId);
		}
	},

	contractDetail: {
		url: `${config.API_URL}/erp/purchase/contractGoods/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/purchase/order/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	applyToOrderInsert: {
		url: `${config.API_URL}/erp/purchase/order/applyToOrderInsert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/purchase/order/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/erp/purchase/order/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	getOrderGoodsList: {
		url: `${config.API_URL}/erp/purchase/orderGoods/getList`,
		name: "根据采购订单id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getOrderGoodsById: {
		url: `${config.API_URL}/erp/purchase/orderGoods/getListByOrderId`,
		name: "根据采购订单id查询物品明细",
		get: async function (params) {
			return await http.get(this.url + '?orderId=' + params);
		}
	},

	queryByGoodsId: {
		url: `${config.API_URL}/erp/purchase/orderGoods/queryByGoodsId`,
		name: "根据物品id查询",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},

	queryBySupplierId: {
		url: `${config.API_URL}/erp/purchase/orderGoods/queryBySupplierId`,
		name: "根据id查询",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},

	getOrderClauseById: {
		url: `${config.API_URL}/erp/purchase/orderClause/getListByOrderId`,
		name: "根据采购订单id查询合同条款",
		get: async function (params) {
			return await http.get(this.url + '?orderId=' + params);
		}
	},

	getOrderPaymentById: {
		url: `${config.API_URL}/erp/purchase/orderPayment/getListByOrderId`,
		name: "根据采购订单id查询首付款期限",
		get: async function (params) {
			return await http.get(this.url + '?orderId=' + params);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/purchase/order/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateProcessInfo: {
		url: `${config.API_URL}/erp/purchase/order/updateProcessInfo`,
		name: "修改审批信息(审批人和审批状态)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	termination: {
		url: `${config.API_URL}/erp/purchase/order/termination`,
		name: "终止",
		get: async function(id,reason){
			return await http.get(this.url + '?id=' + id + '&reason=' + reason);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/purchase/order/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},

	//库存上限
	isExceed: {
		url: `${config.API_URL}/erp/purchase/order/isExceed`,
		name: "库存上限",
		get: async function(data){
			return await http.post(this.url,data);
		}
	},
	trackRecord: {
		url: `${config.API_URL}/erp/purchase/order/trackRecord`,
		name: "跟踪-首页数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	trackDetails: {
		url: `${config.API_URL}/erp/purchase/order/trackDetails`,
		name: "跟踪-详情数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	changePurchaseOrder: {
		url: `${config.API_URL}/erp/purchase/order/changePurchaseOrder`,
		name: "订单变更",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	showHistoricalChanges: {
		url: `${config.API_URL}/erp/purchase/order/showHistoricalChanges`,
		name: "订单历史记录",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	showHistoryDetail: {
		url: `${config.API_URL}/erp/purchase/order/showHistoryDetail`,
		name: "历史记录详情",
		get: async function(historyOrderId,orderId){
			return await http.get(this.url+'?historyOrderId='+historyOrderId+'&orderId='+orderId);
		}
	},
	selectChangeDetail: {
		url: `${config.API_URL}/erp/purchase/orderGoods/selectChangeDetail`,
		name: "历史记录详情-明细",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	unreceivedOrderGoods: {
		url: `${config.API_URL}/erp/purchase/order/unreceivedOrderGoods`,
		name: "未收货查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
