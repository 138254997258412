<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="客户"
          prop="fz_customerName"
        >
          <el-input
            ref="refCustomerInput"
            v-model="form.fz_customerName"
            clearable
            placeholder="请选择"
            @blur="inputBlur('customer')"
            @clear="inputClear('customer')"
            maxlength="32"
          >
            <template #append>
              <el-icon @click="popSelect('refCustomerInput',['customerId','fz_customerName'], 1, 'refSelectCustomer')">
                <el-icon-search />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="开户行"
          prop="bank"
        >
          <el-input
            v-model="form.bank"
            clearable
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          label="银行账号"
          prop="accountNumber"
        >
          <el-input
            v-model="form.accountNumber"
            clearable
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          label="预收金额"
          prop="advrecAmount"
        >
          <el-input
            v-model="form.advrecAmount"
            clearable
            maxlength="22"
            @input="(val) => handleNumIpt(val, 'advrecAmount')"
          />
        </el-form-item>
        <el-form-item
          label="退款金额"
          prop="refundAmount"
        >
          <el-input
            v-model="form.refundAmount"
            clearable
            maxlength="22"
            @input="(val) => handleNumIpt(val, 'refundAmount')"
          />
        </el-form-item>
        <el-form-item
          label="已核销金额"
          prop="useAmount"
        >
          <el-input
            v-model="form.useAmount"
            clearable
            maxlength="22"
            @input="(val) => handleNumIpt(val, 'useAmount')"
          />
        </el-form-item>
        <el-form-item
          label="剩余金额"
          prop="surAmount"
        >
          <el-input
            v-model="form.surAmount"
            clearable
            maxlength="22"
            @input="(val) => handleNumIpt(val, 'surAmount')"
          />
        </el-form-item>
        <el-form-item
          label="收款时间"
          prop="receiptTime"
        >
          <el-date-picker
            v-model="form.receiptTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="method"
          label="收款方式"
        >
          <el-select
            v-model="form.method"
            clearable
          >
            <el-option
              label="现金"
              :value="1"
            />
            <el-option
              label="转账"
              :value="2"
            />
            <el-option
              label="支票"
              :value="3"
            />
            <el-option
              label="其他"
              :value="4"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.method < 3"
          prop="account"
          label="收款账户"
        >
          <Selects
            v-model="form.account"
            :api-obj="$API.account.getAllList"
            @set-value="(val) => setValue(val, 'account')"
          />
        </el-form-item>
<!--        <el-form-item-->
<!--          prop="supplement"-->
<!--          label="是否补单"-->
<!--        >-->
<!--          <el-radio-group-->
<!--            v-model="form.supplement"-->
<!--            @change="handleRadioChange"-->
<!--          >-->
<!--            <el-radio :label="1">-->
<!--              是-->
<!--            </el-radio>-->
<!--            <el-radio :label="0">-->
<!--              否-->
<!--            </el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
<!--        <el-form-item-->
<!--          prop="creationDate"-->
<!--          label="创建日期"-->
<!--        >-->
<!--          <el-date-picker-->
<!--            v-model="form.creationDate"-->
<!--            type="date"-->
<!--            value-format="YYYY-MM-DD"-->
<!--            :disabled="form.supplement === 0"-->
<!--            clearable-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item
          label="销售订单"
          prop="fz_saleOrderNo"
        >
          <el-input
            ref="refSaleOrderInput"
            v-model="form.fz_saleOrderNo"
            clearable
            placeholder="请选择"
            @blur="inputBlur('saleOrder')"
            @clear="inputClear('saleOrder')"
          >
            <template #append>
              <el-icon @click="popSelect('refSaleOrderInput',['saleOrderId','fz_saleOrderNo'], 1, 'refSelectSaleOrder')">
                <el-icon-search />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="单位抬头"
          prop="fz_companyName"
        >
          <el-input
            ref="refCompanyInput"
            v-model="form.fz_companyName"
            clearable
            placeholder="请选择"
            @blur="inputBlur('company')"
            @clear="inputClear('company')"
          >
            <template #append>
              <el-icon @click="popSelect('refCompanyInput',['companyId','fz_companyName'], 1, 'refSelectCompany')">
                <el-icon-search />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            v-model="form.remark"
            clearable
            type="textarea"
            rows="2"
            maxlength="255"
          />
        </el-form-item>
        <el-form-item
          prop="files"
          label="附件"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'附件'"
            :accept="'*'"
            :is-file="true"
            :list-type="'text'"
            :limit="10"
          >
            <el-button type="primary">
              选择文件
            </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-button
        :loading="subLoading"
        type="primary"
        :size="$store.state.global.uiSize"
        @click="save(false)"
      >
        保存数据
      </el-button>
      <el-button
        v-if="visibleSubmit"
        :loading="subLoading"
        type="primary"
        :size="$store.state.global.uiSize"
        @click="save(true)"
      >
        保存并提交
      </el-button>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
      <el-popconfirm
        :title="$TIPS.CONFIRM_CLOSE"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            关闭
          </el-button>
        </template>
      </el-popconfirm>
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #orderNo="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row, 'refDetail')"
        >
          {{ scope.row.orderNo }}
        </el-link>
      </template>
      <template #fz_customerName="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row, 'refCustomerDetail', 'customerId')"
        >
          {{ scope.row.fz_customerName }}
        </el-link>
      </template>
      <template #fz_saleOrderNo="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row, 'refSaleOrderDetail', 'saleOrderId')"
        >
          {{ scope.row.fz_saleOrderNo }}
        </el-link>
      </template>
      <template #fz_companyName="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row, 'refCompanyDetail', 'companyId')"
        >
          {{ scope.row.fz_companyName }}
        </el-link>
      </template>
      <template #supplement="{ scope }">
        {{ scope.row.supplement === 1 ? '是' : '否' }}
      </template>
      <template #method="{ scope }">
        <span v-if="scope.row.method === 1">现金</span>
        <span v-if="scope.row.method === 2">转账</span>
        <span v-if="scope.row.method === 3">支票</span>
        <span v-if="scope.row.method === 4">其他</span>
      </template>
      <template #auditStatus="{ scope }">
        <el-tag
          v-if="scope.row.auditStatus === 0"
          type="info"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          未提交
        </el-tag>
        <el-tag
          v-if="scope.row.auditStatus === 1"
          type="warning"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
					审核中
        </el-tag>
        <el-tag
          v-if="scope.row.auditStatus === 2"
          type="danger"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          已驳回
        </el-tag>
        <el-tag
          v-if="scope.row.auditStatus === 3"
          type="success"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          审核通过
        </el-tag>
				<el-tag
					v-if="scope.row.auditStatus === 4"
					type="info"
					class="myTag"
					:size="$store.state.global.uiSize"
				>
					未确认
				</el-tag>
				<el-tag
					v-if="scope.row.auditStatus === 5"
					type="success"
					class="myTag"
					:size="$store.state.global.uiSize"
				>
					已确认
				</el-tag>
      </template>
      <template #useStatus="{ scope }">
        <el-tag
          v-if="scope.row.useStatus === 0"
          type="danger"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          未核销
        </el-tag>
        <el-tag
          v-if="scope.row.useStatus === 1"
          type="warning"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          部分核销
        </el-tag>
        <el-tag
          v-if="scope.row.useStatus === 2"
          type="success"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          全部核销
        </el-tag>
      </template>
    </SyCopyDialog>
    <!--详情页弹出-->
    <detail ref="refDetail" />
    <CustomerDetail ref="refCustomerDetail" />
    <SaleOrderDetail ref="refSaleOrderDetail" />
    <CompanyDetail ref="refCompanyDetail" />
    <SelectCustomer
      ref="refSelectCustomer"
      @set-data="setSelectData"
    />
    <SelectAccount
      ref="refSelectAccount"
      @set-data="setSelectData"
    />
    <SelectSaleOrder
      ref="refSelectSaleOrder"
      @set-data="setSelectData"
    />
    <SelectCompany
      ref="refSelectCompany"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import {getNowDate} from "@/utils/date"; //resetZnum返回正整数的方法
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		const state = reactive({
      refFormBar:null,
			visibleSubmit: true,
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {
				manager: 0,
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.sellAdvreceipt.getList,
				//弹窗标题
				title:'复制预收款单',
				height: '500px',
				//表格显示列
				columns: [
					{
						label: '预收单号',
						prop: 'orderNo',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '客户',
						prop: 'fz_customerName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '开户行',
						prop: 'bank',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '银行账号',
						prop: 'accountNumber',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '预收金额',
						prop: 'advrecAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退款金额',
						prop: 'refundAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已核销金额',
						prop: 'useAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '剩余金额',
						prop: 'surAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '审核状态',
						prop: 'auditStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '核销状态',
						prop: 'useStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '收款时间',
						prop: 'receiptTime',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '收款方式',
						prop: 'method',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '收款账户',
						prop: 'fz_accountName',
						width: 110,
						sortable: 'custom',
					},
					// {
					// 	label: '是否补单',
					// 	prop: 'supplement',
					// 	width: 100,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '创建日期',
					// 	prop: 'creationDate',
					// 	width: 150,
					// 	sortable: 'custom',
					// },
					{
						label: '销售订单',
						prop: 'fz_saleOrderNo',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '单位抬头',
						prop: 'fz_companyName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
				//查询表单配置
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//查询表单项配置
					itemList: [
						//文本框
						{
							label: '预收单号',
							span: 8,
							prop: 'orderNo',
							type: 'input',
							max: 32,
							default: true,
						},
						{
							label: '客户',
							span: 8,
							prop: 'fz_customerName',
							type: 'popCustomer',
							fields: ['customerId', 'fz_customerName'],
							default: true,
						},
						{
							label: '开户行',
							span: 8,
							prop: 'bank',
							type: 'input',
							max: 255,
							default: true,
						},
						{
							label: '银行账号',
							span: 8,
							prop: 'accountNumber',
							type: 'number',
							max: 32,
						},
						{
							label: '预收金额',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_advrecAmount0',
									max: 26,
									append: '元',
								},
								{
									prop: 'fz_advrecAmount1',
									max: 26,
									append: '元',
								},
							]
						},
						{
							label: '退款金额',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_refundAmount0',
									max: 26,
									append: '元',
								},
								{
									prop: 'fz_refundAmount1',
									max: 26,
									append: '元',
								},
							]
						},
						{
							label: '已核销金额',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_useAmount0',
									max: 26,
									append: '元',
								},
								{
									prop: 'fz_useAmount1',
									max: 26,
									append: '元',
								},
							]
						},
						{
							label: '剩余金额',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_surAmount0',
									max: 26,
									append: '元',
								},
								{
									prop: 'fz_surAmount1',
									max: 26,
									append: '元',
								},
							]
						},
						{
							label: '审核状态',
							span: 8,
							prop: 'auditStatus',
							short: 12,
							type: 'select',
							data: [
								{
									label: '未提交',
									value: 0,
								},
								{
									label: '审核中',
									value: 1,
								},
								{
									label: '已驳回',
									value: 2,
								},
								{
									label: '审核通过',
									value: 3,
								},
							]
						},
						{
							label: '核销状态',
							span: 8,
							prop: 'useStatus',
							short: 12,
							type: 'select',
							data: [
								{
									label: '未核销',
									value: 0,
								},
								{
									label: '部分核销',
									value: 1,
								},
								{
									label: '完全核销',
									value: 2,
								},
							]
						},
						{
							label: '收款时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_receiptTime0',
								},
								{
									prop: 'fz_receiptTime1',
								},
							],
						},
						{
							label: '收款方式',
							span: 8,
							prop: 'method',
							type: 'select',
							short: 12,
							data: [
								{
									label: '现金',
									value: 0,
								},
								{
									label: '转账',
									value: 1,
								},
								{
									label: '支票',
									value: 2,
								},
								{
									label: '其他',
									value: 2,
								},
							]
						},
						{
							label: '收款账户',
							span: 8,
							prop: 'account',
							type: 'popAccount',
						},
						// {
						// 	label: '是否补单',
						// 	span: 8,
						// 	prop: 'supplement',
						// 	type: 'select',
						// 	short: 12,
						// 	data: [
						// 		{
						// 			label: '否',
						// 			value: 0,
						// 		},
						// 		{
						// 			label: '是',
						// 			value: 1,
						// 		},
						// 	]
						// },
						// {
						// 	label: '创建日期',
						// 	span: 8,
						// 	type: 'dateRange',
						// 	//开始结束时间字段
						// 	fields: [
						// 		{
						// 			prop: 'fz_creationDate0',
						// 		},
						// 		{
						// 			prop: 'fz_creationDate1',
						// 		},
						// 	],
						// },
						{
							label: '销售订单号',
							prop: 'fz_saleOrderNo',
							max: 32,
							type: 'input',
						},
						{
							label: '单位抬头',
							span: 8,
							prop: 'fz_companyName',
							type: 'popCompany',
							short: 12,
							fields: ['companyId', 'fz_companyName'],
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					]
				},
			},
			//赋值字段
			fields: [],
		})

		//显示弹窗
		const showDialog = async (row, type) => {
			state.visible = true
			if (type === 1) { //新增
				state.title="新增预收款单"
				state.form = {
					supplement: 0,
					method: 1,
					creationDate: getNowDate()
				}
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑预收款单"
				state.visibleSubmit = false
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.sellAdvreceipt.detail.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.form.account= {
					name: res.data.fz_accountName,
					id: res.data.accountId
				}
				state.orgForm = Object.assign({}, state.form)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}

		//提交方法
		const save = (boolean) => {
			state['refForm'].validate((valid) => {
				if(valid) {
					let res = ''
					//提示语句
					let tips = '';
					if(boolean) {
						tips = proxy.$TIPS.SAVE_SUBMIT
					} else {
						tips = proxy.$TIPS.SUBMIT
					}
					proxy.$baseConfirm(tips,null, async ()=>{
						state.subLoading = true
						if (state.form.id) { //修改
							res = await proxy.$API.sellAdvreceipt.update.put(state.form)
						} else { //新增
							res = await proxy.$API.sellAdvreceipt.insert.post(state.form)
						}
						if(res.code === 200) {
							if(boolean){
								let ids = [];
								ids.push(res.data)
								const saveRes = await proxy.$API.sellAdvreceipt.submitProcess.get(ids)
								if(saveRes.code === 200) {
									proxy.$baseMessage('保存成功并且已提交审批', 'success')
								} else {
									proxy.$baseMessage('保存数据成功但提交审批失败 错误为：' + saveRes.msg, 'warning')
								}
							} else {
								proxy.$baseMessage(res.msg, 'success')
							}
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					})
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
			delete state.form.taskId
			delete state.form.instanceId
			delete state.form.auditStatus
			delete state.form.useStatus
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = state.form[fields[0]]
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					if(state.fields[1] === 'fz_saleOrderNo'){
						state.form[state.fields[1]] = data.orderNo
					}else{
						state.form[state.fields[1]] = data.name
					}
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}

		const handleRadioChange = () => {
			const supplement = state.form.supplement
			if(supplement === 0){
				state.form.creationDate = getNowDate()
			}
		}

		const setValue = (val, field) => {
			state.form[field + 'Id'] = val.id
		}

		return {
			...toRefs(state),
			setValue,
			popSelect,
			handleRadioChange,
			setSelectData,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm
		}
	},
})
</script>
