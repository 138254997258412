import config from "@/config"
import http from "@/utils/request"

export default {
	receivable: {
		url: `${config.API_URL}/fms/sell/statistics/receivable`,
		name: "应收查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	reconciliation: {
		url: `${config.API_URL}/erp/sale/personal/reconciliation`,
		name: "应收查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	received: {
		url: `${config.API_URL}/fms/sell/statistics/received`,
		name: "已收查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	relationCount: {
		url: `${config.API_URL}/fms/sell/statistics/relationCount`,
		name: "已收查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	collectPeriod: {
		url: `${config.API_URL}/fms/sell/statistics/collectPeriod`,
		name: "收款账目表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	overdueReceivable: {
		url: `${config.API_URL}/fms/sell/statistics/overdueReceivable`,
		name: "超期应收款",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
