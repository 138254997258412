import config from "@/config"
import http from "@/utils/request"

export default {
	getCustomerList: {
		url: `${config.API_URL}/cfg/data/customer/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/data/customer/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	delCustomer: {
		url: `${config.API_URL}/cfg/data/customer/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailCustomer: {
		url: `${config.API_URL}/cfg/data/customer/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/data/customer/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	doCheckName: {
		url: `${config.API_URL}/cfg/data/customer/checkName`,
		name: "客户名称唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	insertCustomer: {
		url: `${config.API_URL}/cfg/data/customer/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateCustomer: {
		url: `${config.API_URL}/cfg/data/customer/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagCustomer0: {
		url: `${config.API_URL}/cfg/data/customer/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagCustomer1: {
		url: `${config.API_URL}/cfg/data/customer/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},


	getContactList: {
		url: `${config.API_URL}/cfg/data/customer/getContactList`,
		name: "获取联系人列表",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},


	getAddressList: {
		url: `${config.API_URL}/cfg/data/customer/getAddressList`,
		name: "获取地址列表",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},

	queryCustomers:{
		url: `${config.API_URL}/cfg/data/customer/queryCustomers`,
		name: "根据ids查询客户集合",
		get: async function(params){
			return await http.get(this.url+ "?ids=" + params);
		}
	},

	getPublicCustomersList: {
		url: `${config.API_URL}/cfg/data/customer/queryPublicCustomers`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	updateSaleDirector: {
		url: `${config.API_URL}/cfg/data/customer/updateSaleDirector`,
		name: "分配销售负责人",
		put: async function(data = {}){
			return await http.put(this.url + '?ids=' + data.ids + '&saleDirectorId=' + data.saleDirectorId);
		}
	},

	getContactsBirthdayList: {
		url: `${config.API_URL}/cfg/data/customer/queryContactsBirthday`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	importExcel:{
		url: `${config.API_URL}/cfg/data/customer/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},

	customerSourceQuery: {
		url: `${config.API_URL}/cfg/data/customer/customerSourceQuery`,
		name: "客户渠道榜",
		get: async function(ids){
			return await http.post(this.url,ids);
		}
	},
	getCustomerAddress: {
		url: `${config.API_URL}/cfg/data/customer/address/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	addressGetList: {
		url: `${config.API_URL}/cfg/data/customer/address/getList`,
		name: "选择客户后查收货地址",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getAddressListByKeyword: {
		url: `${config.API_URL}/cfg/data/customer/address/getListByKeyword`,
		name: "收货人下拉选择",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
