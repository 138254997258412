<template>
	<el-config-provider
		:locale="config.locale"
		:size="config.size"
		:z-index="config.zIndex"
		:button="config.button"
	>
		<router-view/>
	</el-config-provider>
</template>

<script>
import colorTool from '@/utils/color'
export default {
	name: 'App',
	data() {
		return {
			config: {
				locale: this.$i18n.messages[this.$i18n.locale].el,
				size: "default",
				zIndex: 2000,
				button: {
					autoInsertSpace: false
				},
			}
		}
	},
	created() {
		this.listenSocket()
		//设置主题颜色
		const app_color = this.$CONFIG.COLOR || this.$TOOL.data.get('APP_COLOR')
		if (app_color) {
			document.documentElement.style.setProperty('--el-color-primary', app_color);
			for (let i = 1; i <= 9; i++) {
				document.documentElement.style.setProperty(`--el-color-primary-light-${i}`, colorTool.lighten(app_color, i / 10));
			}
			for (let i = 1; i <= 9; i++) {
				document.documentElement.style.setProperty(`--el-color-primary-dark-${i}`, colorTool.darken(app_color, i / 10));
			}
		}

		// //ui大小
		// const ui_size = this.$TOOL.data.get('UI_SIZE') || this.$CONFIG.UI_SIZE
		// if (ui_size) {
		// 	this.$store.commit('SET_uiSize', ui_size)
		// }

		// //font大小
		// const font_size = this.$TOOL.data.get('FONT_SIZE') || this.$CONFIG.fontSize
		// if (font_size) {
		// 	this.$store.commit('SET_fontSize', font_size)
		// }
		this.size()
	},
	watch: {
		$route(to, from) {
			if (to.path == '/') {
				// console.log(to.path)
				this.size()
			}
		}
	},
	mounted() {
		window.addEventListener("message", this.handleMessage)
		// window.addEventListener('click',function (e){
		// 	console.log(e)
		// })
	},
	unmounted() {
		window.addEventListener("message", this.handleMessage)
	},
	methods: {
		listenSocket() {
			let user = this.$TOOL.data.get('USER_INFO')
			if (user) {
				// let socket = new WebSocket(sysConfig.WEBSOCKET_URL + user.id)
				// socket.onmessage = (event) => {
				// }
			}
		},
		handleMessage(event) {
			if (event && event.data) {
				const cmd = event.data.cmd
				if (cmd === 'uiSize') {
					const uiSize = parent.store && parent.store._state.data.global.uiSize
					if (uiSize) {
						this.$store.commit('SET_uiSize', uiSize)
					}
				}
			}
		},
		size() {
			//font大小
		//从TOOL.data里读取用户字体大小，设置到vuex
			if (this.$TOOL.data.get("UI_SIZE")) {
				const iframes = document.getElementsByTagName('iframe')
				const stateFont = this.$store.state.global.fontSize
				const val = this.$TOOL.data.get("FONT_SIZE")
				const size = this.$TOOL.data.get("UI_SIZE")

				document.documentElement.classList.remove("font" + stateFont)
				document.documentElement.classList.add("font" + val)
				for(const item of iframes){
					item.contentWindow.document.documentElement.classList.remove("font" + stateFont)
					item.contentWindow.document.documentElement.classList.add("font" + val)
					document.getElementById(item.id).contentWindow.postMessage({
						cmd: 'uiSize'
					},'*')
				}

				this.$store.commit("SET_uiSize", size)
				this.$store.commit("SET_fontSize", val)
				window.store = this.$store

			} else {
				//ui大小
				// const ui_size = this.$TOOL.data.get('UI_SIZE') || this.$CONFIG.UI_SIZE
				const ui_size = this.$CONFIG.UI_SIZE
				if (ui_size) {
					this.$store.commit('SET_uiSize', ui_size)
				}
				//font大小
				// const font_size = this.$TOOL.data.get('FONT_SIZE') || this.$CONFIG.fontSize
				const font_size = this.$CONFIG.fontSize
				if (font_size) {
					this.$store.commit('SET_fontSize', font_size)
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/style/style.scss';
</style>
