
import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	doCheckLicense: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/checkLicense`,
		name: "车牌号校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	doCheckFrame: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/checkFrame`,
		name: "车架号号校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	doCheckEngine: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/checkEngine`,
		name: "发动机号校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},


	del: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	update: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	detail: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag0`,
		name: "修改状态-禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag1`,
		name: "修改状态-启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag2: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag2`,
		name: "修改状态-报废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag3: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag3`,
		name: "修改状态-封存",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag4: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag4`,
		name: "修改状态-借出",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag5: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag5`,
		name: "修改状态-转让",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag6: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/updateFlag6`,
		name: "修改状态-维修",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/logistics/logisticsVehicle/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

}
