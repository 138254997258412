<!--选择用户弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="true"
    :height="'550px'"
    custom-class="popTable"
	@reset="hideDialog"
	:type="type"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes @resized="handleResized">
          <pane>
            <!--公用切换按钮，点击切换显示左侧栏
						text:显示文字
						opened:绑定左侧栏显示状态
						show-left:回调函数
						-->
            <el-container
              class="mainPanel"
              :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <!-- <el-link
                    v-if="multiple"
                    type="primary"
                    :disabled="selections.length === 0"
                    @click="addToRight"
                  >
                    添加到已选<el-icon><el-icon-right /></el-icon>
                  </el-link> -->
                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
				  				:params="searchConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  :ref-query-form="refFormBar"
                  remote-sort
                  remote-filter
                  hide-column-setting
                  hide-setting
				  :hide-pagination="hidePagination"
                  :pagination-layout="'total, prev, pager, next, jumper'"
									@data-change="handleDataChange"
									@select="handleSelect"
									@select-all="handleSelectAll"
                  @selection-change="selectChange"
                  @current-change="currentChange"
									@row-click="handleRowClick"
									@row-dblclick="dblClick"
                >

									<template #fz_goodsName="{ scope }">
										<el-link
											type="primary"
											@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
										>
											{{ scope.row.fz_goodsName }}
										</el-link>
									</template>
									<template #orderNo="{ scope }">
										<el-link
											type="primary"
											@click="handleDetail(scope.row, 'refProducePlanrDetail', 'planId')"
										>
											{{ scope.row.orderNo }}
										</el-link>
									</template>
                  <template #bomType="{scope}">
                    <span v-if="scope.row.bomType === 1">标准BOM</span>
                    <span v-if="scope.row.bomType === 2">订单BOM</span>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  over-all-height="100%"
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <span class="mr_5">
        <el-checkbox
          v-if="!hideAsyncUpdate"
          v-model="asyncAll"
          :true-value="1"
          :false-value="0"
          :size="$store.state.global.uiSize"
        >
          同步修改所有数据
        </el-checkbox>
      </span>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="hideDialog"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
	  <GoodsDetail ref="refGoodsDetail" />
	  <ProducePlanDetail ref="refProducePlanrDetail" />
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick,
  watch, onMounted,
} from 'vue'
import Detail from '@/views/cfg/organize/user/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
export default defineComponent ({
	name: "SelectProducePlanGoods",
	components: {ProducePlanDetail, GoodsDetail, Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '生产计划' },
		//隐藏设置更新复选
		hideAsyncUpdate: { type: Boolean, default: true },
		//
		asyncAllData: { type: Number, default: 0 },
		// 是否隐藏分页
		hidePagination: { type: Boolean, default: false },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			asyncAll: props.asyncAllData,
			type:'detail',
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//编辑按钮显示
			showEdit: false,
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			leftShow: true,
			paneSize: 20,
			paneSize1: 20,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [],
			selDefColumns: [
				{
					label: '生产计划单号',
					prop: 'orderNo',
					width: 180,
					sortable: 'custom',
				},
				{
					label: '物品名称',
					prop: 'goodsName',
					width: 180,
					sortable: 'custom',
				},
				{
					label: '物品编码',
					prop: 'goodsCode',
					width: 120,
					sortable: 'custom',
				},
			],
			selMrpColumns: [
				{
					label: '生产计划单号',
					prop: 'orderNo',
					width: 180,
					sortable: 'custom',
				},
				{
					label: '物品名称',
					prop: 'goodsName',
					width: 180,
					sortable: 'custom',
				},
				{
					label: '物品编码',
					prop: 'goodsCode',
					width: 120,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			excelCondition:{},
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {},
			tableConfig: {},
			tableDefConfig: {
				apiObj:  proxy.$API.producePlanGoods.selectPlanGoods,
				//搜索默认参数
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '生产计划',
						prop: 'orderNo',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '生产数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: 'BOM类型',
						prop: 'bomType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '摘要',
						prop: 'abstract',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			tableMrpConfig: {
				apiObj:  proxy.$API.producePlanGoods.sumQueryDetails,
				//搜索默认参数
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '生产计划',
						prop: 'orderNo',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '物品名称',
						prop: 'goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格型号',
						prop: 'goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '生产数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'mainUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: 'BOM类型',
						prop: 'bomType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '摘要',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				// queryForm:{},
				params:{},
				//表单标题宽度
				labelWidth: 80,
				//查询表单项配置
				itemList: [

					{
						label: '生产计划',
						prop: 'orderNo',
						type: 'input',
						default: true,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						type: 'input',
						default: true,
					},

					{
						label: '创建日期',
						type: 'dateRange',
						default: true,
						//开始结束日期字段
						fields: [
							{
								prop: 'creationDate0',
							},
							{
								prop: 'creationDate1',
							},
						],
					},

				],
			},
			tableType: '',
		})
    onMounted(() => {
      //消息监听
      state.hideForm=!state.hideForm
    })
		//显示弹窗
		const showDialog = (param,tableType) => {
			state.visible = true
			if(param){
				state.searchConfig.params = Object.assign({}, param)
				// state.params = Object.assign({}, param)
			}
			state.tableType = tableType
			state.tableConfig = state.tableDefConfig
			state.selColumns = state.selDefColumns
			if(tableType === 'mrp'){
				state.tableConfig = state.tableMrpConfig
				state.selColumns = state.selMrpColumns
			}
			state.excelCondition = Object.assign({}, param)
			getShowAdd()
			getShowEdit()
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			initProps()
		}

		const initProps = () => {
			state.selections = []
			state.selData = []
			state.data = []
		}

		//弹窗关闭回调
		const handleClosed = () => {
			initProps()
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ORGANIZE_USER,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ORGANIZE_USER,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		// const handleShowLeft = (bool) => {
		// 	state.leftShow = bool
		// }

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//全选
		const handleSelectAll = (selection) => {
			if(selection && selection.length > 0){
				selection.forEach((item) => {
					setSelData(item)
				})
			}else{
				state.selData = []
			}
		}

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
			console.log(state.selData)
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange = (row) => {
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			} else {
				if (state.selectionsTableSel && state.selectionsTableSel.length > 0){
					state.selections = state.selectionsTableSel
				}
				data = state.selections
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		// //行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//行单击
		// const firClick = (row) => {
		// 	//添加已选数据
		// 	setSelData(row)
		// }

		//切换显示左侧栏内容
		// const handleClsClick = (num) => {
		// 	state.curCls = num
		// 	//刷新表格
		// 	proxy.$refs.table.reload()
		// 	//清除查询条件
		// 	state.queryForm = {}
		// 	if(num == 1){
		// 		state.switchText = '部门'
		// 	}else{
		// 		state.switchText = '角色'
		// 	}
		// }

		//左侧树节点点击
		// const fetchDataNode = (node) => {
		// 	// let params = {}
		// 	// if(type == 1){ //部门
		// 	// 	params = { departmentId: data.id }
		// 	// }else if(type == 2){ //角色
		// 	// 	params = { roleId: data.id }
		// 	// }
		// 	// proxy.$refs.table.reload(params)
		// 	if (node) {
		// 		state.curNode = Object.assign({}, node)
		// 		fetchDataLeft()
		// 	}
		// }

		// //用户搜索
		// const handleSearch = () => {
		// 	proxy.$refs.table.reload(state.queryForm)
		// }

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			// //查询参数{ 字段名：值, 字段名：值, ... }
			data = {
				...state.excelCondition,
				...data
			}
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if (data && state.selData.indexOf(data) === -1) {
				state.selData.push(data)
			} else {
				state.selData.splice(state.selData.indexOf(data),1)
			}
			// if(state.selData.length > 0){
			// 	state.isConfirm = false
			// }else{
			// 	state.isConfirm = true
			// }
			showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
					if (index > -1) {
						proxy.$refs.table.toggleRowSelection(item,false)
					}
				})
			}
		}

		// //新增修改弹窗
		// const handleEdit = (type, ref) => {
		// 	let param = {}
		// 	if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
		// 		param = state.curRow
		// 	}
		// 	proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
		// 	proxy.$refs.refEdit.showDialog(param)
		// }

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if(findItem){
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//表格数据加载完成
		const handleDataChange = (res) => {
			showChooseData()
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			handleDetail,
			addToRight,
			delSelData,
			// handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			// handleClsClick,
			// handleSearch,
			currentChange,
			handleSelectAll,
			handleDataChange,
			handleSelect,
			selectChange,
			// fetchDataNode,
			handleConfirm,
			// handleShowLeft,
			showDialog,
			dblClick,
			// firClick,
			hideDialog,
			handleRowClick,
			handleClosed
		}
	},
})
</script>
