import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/examine/task/getList`,
		name: "查询流程",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	queryApproval:{
		url: `${config.API_URL}/cfg/examine/task/queryApproval`,
		name: "查询审批状态",
		get: async function(params){
			return await http.get(this.url + '?taskId=' + params);
		}
	},
	processApproval:{
		url: `${config.API_URL}/cfg/examine/task/processApproval`,
		name: "审批",
		post: async function(data = {}){
			return await http.post(this.url,data);
		}
	},
	historyRecord:{
		url: `${config.API_URL}/cfg/examine/task/historyRecord`,
		name: "审批历史记录",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
