<template>
	<!--sc-dialog 弹窗组件
	:title="title" 弹窗标题
	:loading="loading" 弹窗加载动画
	:width="500" 弹窗宽度
	:before-close="beforeClose" 弹窗关闭前回调方法
	:trigger-dom="triggerDom" 触发打开弹窗的元素ref
	:close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
	@reset="reset" 关闭弹窗回调函数,这里执行重置表单
	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:width="500"
		:before-close="beforeClose"
		:trigger-dom="triggerDom"
		:close-on-click-modal="false"
		@reset="reset"
	>
		<!--弹窗头部自定义-->
		<template #header>
			<span class="el-dialog__title">{{ title }}</span>
			<!--复制信息按钮 新增时显示：form.id-->
			<el-button
				v-if="!form.id"
				ref="copyBtn"
				class="ml10"
				:size="$store.state.global.uiSize"
				@click="copyData"
			>
				复制信息
			</el-button>
		</template>
		<!--表单-->
		<div class="syForm formset">
			<el-form
				ref="refForm"
				:model="form"
				:label-width="labelWidth"
				size="small"
				:rules="rules"
			>
				<el-form-item
					prop="contacts"
					label="收货人"
				>
					<el-input
						v-model="form.contacts"
						maxlength="10"
						clearable
					/>
				</el-form-item>
				<el-form-item
					prop="tel"
					label="联系电话"
				>
					<el-input
						v-model="form.tel"
						maxlength="20"
						clearable
					/>
				</el-form-item>
				<el-form-item
					prop="areaObj"
					label="所在地区"
				>
					<el-cascader
						v-model="form.areaObj"
						clearable
						:options="$API.area"
						separator="-"
						placeholder="选择地区"
						:props="{ label: 'name', value: 'name', expandTrigger: 'hover' }"
						style="width: 100%"
						@change="handleArea"
					/>
				</el-form-item>
				<el-form-item
					prop="address"
					label="详细地址"
				>
					<!--
					type="textarea"表示文本输入框
					rows="5"默认行数，越大文本框越高
					-->
					<el-input
						v-model="form.address"
						type="textarea"
						maxlength="255"
						rows="1"
					/>
				</el-form-item>
			</el-form>
		</div>
		<!--弹窗底部自定义-->
		<template #footer>
			<el-popconfirm
				:visible="visibles"
				:title="$TIPS.SAVE"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@cancel="visibles = false"
				@confirm="handleConfirm"
			>
				<template #reference>
					<el-button
						type="primary"
						:size="$store.state.global.uiSize"
						:loading="subLoading"
						@click="valiForm"
					>
						提交
					</el-button>
				</template>
			</el-popconfirm>
			<el-popconfirm
				:title="$TIPS.CONFIRM_RESET"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@confirm="reset(false)"
			>
				<template #reference>
					<el-button
						:size="$store.state.global.uiSize"
					>
						重置
					</el-button>
				</template>
			</el-popconfirm>
			<!--el-popconfirm 确认组件
			 title:提示文字
			 confirm-button-text:确认按钮文本
			 cancel-button-text:取消按钮文本-->
			<!--      <el-popconfirm-->
			<!--        :title="$TIPS.CONFIRM_CLOSE"-->
			<!--        confirm-button-text="确定"-->
			<!--        cancel-button-text="取消"-->
			<!--        @confirm="handleConfirm"-->
			<!--      >-->
			<!--        <template #reference>-->
			<!--          <el-button-->
			<!--            :size="$store.state.global.uiSize"-->
			<!--          >-->
			<!--            关闭-->
			<!--          </el-button>-->
			<!--        </template>-->
			<!--      </el-popconfirm>-->
		</template>
		<!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
		<SyCopyDialog
			ref="copyDialog"
			:config="copyConfig"
			@set-form="setFormData"
			remote-sort
			:ref-query-form="refFormBar"
		/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from "vue";
import {resetZnum} from "@/utils/validate"; //resetZnum返回正整数的方法
export default defineComponent({
	name: 'SelectCustomerAddressEdit',
	emits: ["fetch-data"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg));
			} else {
				callback();
			}
		};

		const state = reactive({
			refFormBar: null,
			visibles: false,
			codeMsg: "",
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: "收货地址新增",
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				contacts: [
					{required: true, trigger: "blur", message: "必填"},
				],
				tel: [
					{required: true, trigger: "blur", message: "必填"},
				],
				areaObj: [
					{required: true, trigger: "blur", message: "必填"},
				],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.customerAddress.getList,
				params: {},
				//弹窗标题
				title: "复制收货地址信息",
				//查询匹配的字段
				prop: "name",
				//表格显示列
				columns: [
					{
						label: '收货人',
						prop: 'contacts',
						width: 120,
						sortable: "custom",
					},
					{
						label: '联系电话',
						prop: 'tel',
						width: 160,
						sortable: "custom",
					},
					{
						label: '所在地区',
						prop: 'area',
						width: 220,
						sortable: "custom",
					},
					{
						label: '详细地址',
						prop: 'address',
						width: 220,
						sortable: "custom",
					},
				]
			},
			//赋值字段
			fields: [],
		});
		//显示弹窗
		const showDialog = async (row, type) => {
			state.visible = true;
			if (type === 1) { //新增
				state.title = "新增收货地址";
				state.form.customerId = row.customerId;
				state.orgForm = Object.assign({}, state.form);
			} else { //编辑
				state.title = "编辑收货地址";
				await getDetailData(row);
			}
			state.copyConfig.params = {customerId: state.form.customerId};
			state.loading = false;
		};

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.customerAddress.detail.get(row.id);
			if (res.code === 200) {
				console.log(res);
				if(res.data.area && res.data.area !== ''){
					res.data.areaObj = res.data.area.split('-')
				}
				state.form = Object.assign({}, res.data);
				state.orgForm = Object.assign({}, res.data);
			}
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
			reset(true);
		};

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done();
					reset(true);
				});
			});
		};

		//关闭确认
		const handleConfirm = () => {
			save();
			state.visibles = false;
		};
		const valiForm = () => {
			nextTick(() => {
				state.refForm.validate((valid) => {
					if (valid) {
						state.visibles = true;
					} else {
						state.visibles = false;
					}
				});
			});
		};

		//提交方法
		const save = () => {
			state["refForm"].validate(async (valid) => {
				if (valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
					state.subLoading = true;
					let res = null;
					if (state.form.id) { //修改
						res = await proxy.$API.customerAddress.update.put(state.form);
					} else {
						res = await proxy.$API.customerAddress.insert.post(state.form);
					}
					if (res.code === 200) {
						proxy.$baseMessage(res.msg, "success");
						emit("fetch-data", {customerId: state.form.customerId});
						hideDialog();
					} else {
						proxy.$baseMessage(res.msg, "error");
					}
					state.subLoading = false;
					// })
				}
			});
		};

		//重置表单
		const reset = (isClose) => {
			state["refForm"].resetFields();
			const orgForm = state.orgForm;
			Object.keys(state.form).forEach((item) => {
				delete state.form[item];
			});
			if (!isClose) {
				state.form = Object.assign({}, orgForm);
			}
		};

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data, field) => {
			if (data.length > -1) { //多选
				let ids = "";
				if (data.length > 0) {
					//取得选中的id串
					ids = data.map((item) => item.id).join();
				} else {
					ids = "";
				}
				state.form[field] = ids;
			} else { //单选
				if (data && data.id) {
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					state.form[field[0]] = data.id;
				}
			}
		};

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				if (state.form.customerId) {
					//把触发元素传给弹窗
					proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn;
					//copyDialog弹窗的ref属性
					proxy.$refs.copyDialog.showDialog();
				}
			});
		};

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({}, data);
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id;
		};

		//地区选择变化 val:选择后的地区数组对象
		const handleArea = (val) => {
			if (val) {
				//将数组对象转为字符串
				state.form.area = val.join("-");
			} else {
				//清空
				state.form.area = "";
			}
		};
		return {
			...toRefs(state),
			handleArea,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			valiForm
		};
	},
});
</script>
