import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/stock/stockQuality/getList`,
		name: "查询所有质量检测记录",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	insert: {
		url: `${config.API_URL}/mobile/stock/stockQuality/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delete: {
		url: `${config.API_URL}/mobile/stock/stockQuality/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detail: {
		url: `${config.API_URL}/mobile/stock/stockQuality/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	inspectionRecord:{
		url: `${config.API_URL}/mobile/stock/stockQuality/inspectionRecord`,
		name: "质检记录",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
