<template>
  <el-container class="propertyContainer">
    <el-header>
      <h3>{{ title }}</h3>
    </el-header>
    <el-main class="bgwhite">
      <el-form
        ref="refForm"
        :label-width="60"
        size="small"
      >
        <el-form-item
          prop="name"
          label="名称"
        >
          <el-input
            v-model="form.name"
            maxlength="50"
            clearable
          />
        </el-form-item>
      </el-form>
      <h4>判断条件</h4>
      <el-table
        class="edit_table_new mt_10"
        size="small"
        :data="list"
        border
      >
        <el-table-column
          prop="operate"
          label="操作"
          width="65"
          fixed="left"
        >
          <template #default="scope">
            <el-button
              circle
              class="iconBtn"
              plain
              size="small"
              type="primary"
              @click="handleAddRow(scope.row)"
            >
              <el-icon><el-icon-plus /></el-icon>
            </el-button>
            <el-button
              circle
              class="iconBtn"
              plain
              size="small"
              type="danger"
              @click="handleDelRow(scope.row)"
            >
              <el-icon><el-icon-minus /></el-icon>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columns"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :show-overflow-tooltip="item.showOverflowTooltip"
        >
          <template #default="scope">
            <template v-if="item.type === 'input'">
              <el-input
                v-model="scope.row[item.prop]"
                maxlength="30"
                :size="$store.state.global.uiSize"
                clearable
              />
            </template>
            <template v-if="item.type === 'select'">
              <el-select
                v-model="scope.row[item.prop]"
                :size="$store.state.global.uiSize"
              >
                <el-option
                  v-for="(option,oindex) in item.options"
                  :key="oindex"
                  :label="option.label"
                  :value="option.value"
                />
              </el-select>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
import {
	defineComponent,
	getCurrentInstance,
	nextTick,
	reactive,
	toRefs,
	watch,
	onMounted,
	onActivated
} from "vue";
import UserList from '@/views/publicQuery/userList'
export default defineComponent ({
	name: "WfPropertySequenceFlow",
	emits: [ 'set-sequence' ],
	setup(props,{ emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			//标题
			title: '线条属性',
			//表单数据对象
			form: {},
			//表格数据
			list: [],
			//表格列
			columns: [
				{
					label: '字段',
					prop: 'field',
					width: 120,
					type: 'input',
				},
				{
					label: '判断符号',
					prop: 'judge',
					width: 120,
					type: 'select',
					options: [
						{
							label: '大于(>)',
							value: '>',
						},
						{
							label: '大于等于(>=)',
							value: '>=',
						},
						{
							label: '等于(==)',
							value: '==',
						},
						{
							label: '小于(<)',
							value: '<',
						},
						{
							label: '小于等于(<=)',
							value: '<=',
						},
						{
							label: '不等于(!=)',
							value: '!=',
						},
					],
				},
				{
					label: '值',
					prop: 'value',
					type: 'input',
				},
				{
					label: '连接符',
					prop: 'linkCode',
					type: 'select',
					width: 120,
					options: [
						{
							label: '和(&&)',
							value: '&&',
						},
						{
							label: '或(||)',
							value: '||',
						},
					],
				},
			],
			//空白行
			blankRow: {
				field: '',
				judge: '',
				value: '',
				linkCode: '',
			}
		})

		//初始化表格数据
		const initList = () => {
			if(state.list.length == 0){
				let arr = []
				arr.push(Object.assign({}, state.blankRow))
				state.list = arr
			}
		}

		onMounted(() => {
			initList()
		})

		//添加行
		const handleAddRow = (row) => {
			const index = state.list.indexOf(row)
			state.list.splice(index + 1, 0, Object.assign({}, state.blankRow))
		}

		//删除行
		const handleDelRow = (row) => {
			const index = state.list.indexOf(row)
			if (state.list.length > 1) {  //插入到点击行的后面
				state.list.splice(index, 1)
			} else {
				proxy.$baseMessage('必须保留一条', 'error')
			}
		}

		//监听数据变化
		watch(state,(val) => {
			if(val.list){
				//传值给父组件
				emit('set-sequence', [val.list, val.form])
			}
		})

		return {
			...toRefs(state),
			initList,
			handleAddRow,
			handleDelRow,
		}
	}
})
</script>
