<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <!--label: 标题 -->
        <el-descriptions-item
          label="申请单号"
          label-align="right"
        >
          {{ data.orderNo }}
        </el-descriptions-item>

        <el-descriptions-item
          label="申请来源"
          label-align="right"
        >
          {{ data.source }}
        </el-descriptions-item>

        <el-descriptions-item
          label="供应商"
          label-align="right"
        >
          <el-link
            type="primary"
            @click="handleDetail(data, 'refSupplierDetail', 'supplierId')"
          >
            {{ data.fz_supplierName }}
          </el-link>
        </el-descriptions-item>

        <el-descriptions-item
          label="审核状态"
          :label-align="labelAlign"
        >
          <el-tag
            v-if="data.auditStatus === 0"
            type="info"
            :size="$store.state.global.uiSize"
          >
            未提交
          </el-tag>
          <el-tag
            v-if="data.auditStatus === 1"
            type="warning"
            :size="$store.state.global.uiSize"
          >
            审核中
          </el-tag>
          <el-tag
            v-if="data.auditStatus === 2"
            type="danger"
            :size="$store.state.global.uiSize"
          >
            已驳回
          </el-tag>
          <el-tag
            v-if="data.auditStatus === 3"
            type="success"
            :size="$store.state.global.uiSize"
          >
            审核通过
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item
          label="付款状态"
          :label-align="labelAlign"
        >
          <el-tag
            v-if="data.paymentStatus === 0"
            type="info"
            :size="$store.state.global.uiSize"
          >
            未付款
          </el-tag>
          <el-tag
            v-if="data.paymentStatus === 1"
            type="success"
            :size="$store.state.global.uiSize"
          >
            已付款
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item
          label="订单金额"
          label-align="right"
        >
          {{ data.orderAmount }}
        </el-descriptions-item>

        <el-descriptions-item
          label="申请金额"
          label-align="right"
        >
          {{ data.applyAmount }}
        </el-descriptions-item>

        <el-descriptions-item
          label="申请人"
          label-align="right"
        >
          {{ data.fz_applicantName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="付款期限"
          label-align="right"
        >
          {{ data.paymentDate }}
        </el-descriptions-item>

        <el-descriptions-item
          label="操作用户"
          label-align="right"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="操作时间"
          label-align="right"
        >
          {{ data.addTime }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="申请说明"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.illustrate }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="订单明细"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableSon"
        :api-obj="tableSonConfig.apiObj"
        :column="tableSonConfig.columns"
        :params="tableSonConfig.params"
        :height="'auto'"
        row-key="id"
        border
        remote-sort
      >
        <template #fz_purOrderNo="{ scope }">
          <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refPurOrderDetail', 'purOrderId')"
          >
            {{ scope.row.fz_purOrderNo }}
          </el-link>
        </template>
        <template #isWhole="{ scope }">
          <span v-if="scope.row.isWhole === 1">是</span>
          <span v-if="scope.row.isWhole === 0">否</span>
        </template>
      </scTable>
    </el-card>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
    <SupplierDetail ref="refSupplierDetail" />
    <PurOrderDetail ref="refPurOrderDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'
import SupplierDetail from "@/views/cfg/data/supplier/detail";
import PurOrderDetail from '@/views/erp/purchase/order/detail'

export default defineComponent({
	components: {
		SupplierDetail,
		PurOrderDetail
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '60%',
      type:"detail",
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '付款申请单详情',
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '采购订单',
						prop: 'fz_purOrderNo',
						sortable: 'custom',
						fixed: 'left',
						width: 200,
					},
					{
						label: '应付金额',
						prop: 'orderAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '申请金额',
						prop: 'applyAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '是否全部付款',
						prop: 'isWhole',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '申请说明',
						prop: 'illustrate',
						width: 180,
						sortable: 'custom',
					},
				],
			},
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = []
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.buyApply.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					//获取明细表数据
					getGoodsList(res.data.id)
					//组装文件列表
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.apiObj = proxy.$API.buyApplyDetail.selectDetail
				state.tableSonConfig.params = {applyId: id}
				proxy.$refs.tableSon.reload({applyId: id})
			}
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}
		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
			previewFile,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

