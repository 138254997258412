import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/logistics/logisticsTransport/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	del: {
		url: `${config.API_URL}/erp/logistics/logisticsTransport/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detail: {
		url: `${config.API_URL}/erp/logistics/logisticsTransport/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/logistics/logisticsTransport/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/erp/logistics/logisticsTransport/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},


	getTransportDetailList: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportDetail/getList`,
		name: "根据运输计划id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getGoodsList: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportDetail/getGoodsList`,
		name: "根据运输计划id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getTransportLocusList: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportLocus/getList`,
		name: "根据运输计划id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delLocus: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportLocus/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailLocus: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportLocus/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	insertLocus: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportLocus/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateLocus: {
		url: `${config.API_URL}/erp/logistics/logisticsTransportLocus/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

}
