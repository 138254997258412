import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/order/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/sale/order/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	notSharedGetList: {
		url: `${config.API_URL}/erp/sale/order/notSharedGetList`,
		name: "不受共享人限制查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/sale/order/insert`,
		name: "新增销售订单",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/sale/order/update`,
		name: "修改销售订单",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/sale/order/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	turnProductionPlanData: {
		url: `${config.API_URL}/erp/sale/order/turnProductionPlanData`,
		name: "查询详情",
		get: async function(id,keyWord){
			return await http.get(this.url + "?id=" + id+ '&keyWord=' + keyWord);
		}
	},
	detailAndAverageCost: {
		url: `${config.API_URL}/erp/sale/order/detailAndAverageCost`,
		name: "查询详情",
		get: async function(params){
		}
	},
	delete: {
		url: `${config.API_URL}/erp/sale/order/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/erp/sale/order/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/erp/sale/order/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	termination: {
		url: `${config.API_URL}/erp/sale/order/termination`,
		name: "终止",
		get: async function(id,reason){
			return await http.get(this.url + '?id=' + id + '&reason=' + reason);
		}
	},
	//开票通知
	invoiceNotice: {
		url: `${config.API_URL}/cfg/system/msg/Invoicing`,
		name: "开票通知",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	queryByCustomerId: {
		url: `${config.API_URL}/erp/sale/order/queryByCustomerId`,
		name: "根据商户id查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getAllGoodsList:{
		url: `${config.API_URL}/erp/sale/order/getAllGoodsList`,
		name: "查询所有商品",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	// 修改生产状态
	updateProduceStatus: {
		url: `${config.API_URL}/erp/sale/order/updateProduceStatus`,
		name: "状态启用",
		get: async function(id,status){
			return await http.get(this.url + '?id=' + id + '&status=' + status);
		}
	},
	returnProcess: {
		url: `${config.API_URL}/erp/sale/order/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	salesRankingQuery: {
		url: `${config.API_URL}/erp/sale/order/salesRankingQuery`,
		name: "销售排行榜",
		get: async function(ids){
			return await http.post(this.url,ids);
		}
	},
	trackRecord: {
		url: `${config.API_URL}/erp/sale/order/trackRecord`,
		name: "跟踪-首页数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	trackDetails: {
		url: `${config.API_URL}/erp/sale/order/trackDetails`,
		name: "跟踪-详情数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	unshippedOrderGoods: {
		url: `${config.API_URL}/erp/sale/order/unshippedOrderGoods`,
		name: "未发货查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	changeSaleOrder: {
		url: `${config.API_URL}/erp/sale/order/changeSaleOrder`,
		name: "订单变更",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	showHistoricalChanges: {
		url: `${config.API_URL}/erp/sale/order/showHistoricalChanges`,
		name: "销售订单历史记录",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	showHistoryDetail: {
		url: `${config.API_URL}/erp/sale/order/showHistoryDetail`,
		name: "历史记录详情",
		get: async function(historyOrderId,orderId){
			return await http.get(this.url+'?historyOrderId='+historyOrderId+'&orderId='+orderId);
		}
	},
}
