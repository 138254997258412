<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="title"
      :loading="loading"
      :type="type"
      :bef-close="false"
      :width="width"
  >
    <el-card
        header="基本信息"
        shadow="never"
        class="mycard"
    >
      <el-descriptions
          :title="''"
          :column="3"
          :size="$store.state.global.uiSize"
          border
          class="column3"
      >
        <el-descriptions-item
            label="部门名称"
            :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>
        <el-descriptions-item
            label="组织类型"
            :label-align="labelAlign"
        >
          <span v-if="data.type == 1">公司</span>
          <span v-if="data.type == 2">部门</span>
        </el-descriptions-item>
        <el-descriptions-item
            label="部门代码"
            :label-align="labelAlign"
        >
          {{ data.code }}
        </el-descriptions-item>
        <el-descriptions-item
            label="部门经理"
            :label-align="labelAlign"
        >
          {{ data.fz_managerName }}
        </el-descriptions-item>
        <el-descriptions-item
            label="部门职能"
            :label-align="labelAlign"
        >
          {{ data.post }}
        </el-descriptions-item>
        <el-descriptions-item
            label="编制人数"
            :label-align="labelAlign"
        >
          {{ data.headcount }}
        </el-descriptions-item>
        <el-descriptions-item
            label="操作用户"
            :label-align="labelAlign"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
            label="操作时间"
            :label-align="labelAlign"
        >
          {{ data.addTime }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance, onMounted
} from "vue";

export default defineComponent({
  props: {
    title: {type: String, default: "部门详情"}
  },
  setup(props) {
    //获取当前实例对象
    const {proxy} = getCurrentInstance();
    const state = reactive({
      dataList: [],
      //弹窗宽度
      width: "60%",
      type: "detail",
      //加载动画
      loading: true,
      //标题对齐方式 'left' 'right' 'center'
      labelAlign: "right",
      //尺寸大小
      size: "small",
      //控制弹窗显示隐藏
      visible: false,
      //弹窗标题
      title: props.title,
      //详情数据对象
      data: {},
      fileList: [],
    });
    //显示弹窗
    const showDialog = async (data) => {
      state.visible = true;
      if (data.id) {
        //根据id查询
        const res = await proxy.$API.department.detail.get(data.id);
        if (res.code === 200) {
          state.data = res.data;
        }
      }
      state.loading = false;
    };
    //隐藏弹窗
    const hideDialog = () => {
      state.visible = false;
    };

    //组装文件列表
    const getFileList = (data) => {
      let arr = [];
      if (data) {
        arr = data.split(",");
        if (arr.length > 0) {
          arr.forEach((item) => {
            let obj = {};
            const iarr = item.split("/");
            obj["url"] = item;
            obj["name"] = iarr[iarr.length - 1];
            state['fileList'].push(obj);
          });
        }
      }
    };
    const previewFile = (file) => {
      window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
    };
    return {
      ...toRefs(state),
      previewFile,
      showDialog,
      hideDialog,
    };
  },
});
</script>
