<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
    :width="width"
    :bef-close="false"
  >
    <div class="popPane">
      <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
      <el-descriptions
        :title="''"
        :column="2"
        :size="$store.state.global.uiSize"
        border
        class="column2"
      >
        <el-descriptions-item
          label="车间名称"
          :min-width="120"
          :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>

        <el-descriptions-item
          label="车间编号"
          :min-width="120"
          :label-align="labelAlign"
        >
          {{ data.code }}
        </el-descriptions-item>

        <el-descriptions-item
          label="车间位置"
          :min-width="120"
          :label-align="labelAlign"
        >
          {{ data.position }}
        </el-descriptions-item>
        <el-descriptions-item
          label="所属部门"
          :min-width="120"
          :label-align="labelAlign"
        >
          {{ data.fz_departmentName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="负责人"
          :label-align="labelAlign"
        >
          {{ data.fz_managerName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="车间人数"
          :min-width="120"
          :label-align="labelAlign"
        >
          {{ data.personnel }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工作时长[小时]"
          :min-width="120"
          :label-align="labelAlign"
        >
          {{ data.workTime }}
        </el-descriptions-item>

        <el-descriptions-item
          label="工作效率(%)"
          :label-align="labelAlign"
        >
          {{ data.efficiency }}
        </el-descriptions-item>
        <el-descriptions-item
          label="总工作能力[小时]"
          :label-align="labelAlign"
        >
          {{ data.efficiency }}
        </el-descriptions-item>
        <el-descriptions-item
          label="单位成本[元]"
          :label-align="labelAlign"
        >
          {{ data.unitCost }}
        </el-descriptions-item>
        <el-descriptions-item
          label="是否关键车间"
          :label-align="labelAlign"
        >
          {{ data.isKey === 1 ? '是' : '否' }}
        </el-descriptions-item>
        <el-descriptions-item
          label="操作用户"
          :label-align="labelAlign"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="操作时间"
          :label-align="labelAlign"
        >
          {{ data.addTime }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
	import {
		defineComponent,
		toRefs,
		reactive,
		getCurrentInstance
	} from 'vue'

	export default defineComponent({
		setup() {
			//获取当前实例对象
			const {proxy} = getCurrentInstance()
			const state = reactive({
				//弹窗宽度
				width: '60%',
        type:"detail",
				//加载动画
				loading: true,
				//标题对齐方式 'left' 'right' 'center'
				labelAlign: 'right',
				//尺寸大小
				size: 'small',
				//控制弹窗显示隐藏
				visible: false,
				//弹窗标题
				title: '生产车间详情',
				data: {},
				//详情数据对象
				deswidth: '25%',
				fileList: [],
			})

			//显示弹窗
			const showDialog = async (data) => {
				state.fileList = []
				state.visible = true
				if (data.id) {
					//根据id查询
					const res = await proxy.$API.produceCraftWorkshop.detail.get(data.id)
					if (res.code === 200) {
						state.data = res.data
						getFileList(res.data.files)
					}
				}
				state.loading = false
			}

			const getFileList = (data) => {
				let arr = []
				if (data) {
					arr = data.split(',')
					if (arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							const iarr = item.split('/')
							obj['url'] = item
							obj['name'] = iarr[iarr.length - 1]
							state.fileList.push(obj)
						})
					}
				}
			}

			const previewFile = async (file) => {
				window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
				await proxy.$API.files.insertFilesDownload.post(file)
			}

			//隐藏弹窗
			const hideDialog = () => {
				state.visible = false
			}

			return {
				...toRefs(state),
				showDialog,
				previewFile,
				hideDialog,
			}
		},
	})
</script>
