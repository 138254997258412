<template>
	<el-header id="searchForm" class="formbar">
		<div class="hdformcont">
			<!--
			  queryForm: 表单对象
			  inline：行内显示表单项
			  $CONFIG：全局配置对象
			  -->
			<el-form
				ref="form"
				class="syForm"
				:inline="true"
				:model="queryForm"
				:label-suffix="':'"
				:size="$store.state.global.uiSize"
				:label-width="configs.labelWidth"
			>
				<div class="">
					<div class="formCont">
						<div class="sformOpt">
							<div class="left-panel flexgs1">
								<el-button
									@click="handleSubmit"
								>
									搜索
								</el-button>
								<el-button
									plain
									@click="handleReset"
								>
									重置
								</el-button>
							</div>
							<div class="right-panel">
								<el-link
									v-if="configs.itemList.length > (configs.defaultLength || 4)"
									@click="handleExpandForm"
								>
									{{ showFitem ? "" : "" }}
								</el-link>
							</div>
						</div>
						<div class="formPanel" ref="formPanel">
							<el-row :gutter="20">
								<template
									v-for="(item, index) in configs.itemList"
									:key="index"
								>
									<el-col
										v-show="showFitem || item.default"
										:xl="item.span || span"
										:lg="item.span || (rangeList.includes(item.type) ? (2*span) : span)"
										:md="8"
										:sm="12"
										:xs="24"
										style="display: flex;"
									>
										<div v-if="item.type === 'date' && item.prop === 'fz_date1'"
											  style="line-height: 20px;margin-top: 0">—
										</div>
										<el-form-item :label="item.label">
											<el-row class="fullWidth">
												<el-col :span="item.short || 24">
													<!--文本输入框-->
													<template v-if="item.type === 'input'">
														<el-input
															v-model="queryForm[item.prop]"
															:maxlength="item.max"
															:show-word-limit="item.showWordLimit || false"
															:clearable="item.clearable || true"
														>
															<template
																v-if="item.append"
																#suffix
															>
																{{ item.append }}
															</template>
														</el-input>
													</template>
													<!--数字输入框 整数负数小数 有小数点-->
													<template v-if="item.type === 'number'">
														<el-input
															v-model="queryForm[item.prop]"
															:clearable="item.clearable || true"
															:maxlength="item.max"
															@input="(val) => handleNumIpt(val, item)"
														>
															<template
																v-if="item.append"
																#suffix
															>
																{{ item.append }}
															</template>
														</el-input>
													</template>
													<!--入库号-->
													<template v-if="item.type==='popWarehouseNum'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															@input="(val)=>inputEdit(val,item)"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, 1, 'refWarehouseNumList', item.params)">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--数字范围输入框-->
													<template v-if="item.type === 'rangeNum'">
														<el-row>
															<el-col :span="11">
																<el-input
																	v-model="queryForm[item.fields[0].prop]"
																	:maxlength="item.fields[0].max"
																	:clearable="item.fields[0].clearable || true"
																	@input="(val) => handleNumIpt(val, item.fields[0].prop)"
																	@blur="compareBig(item.fields)"
																>
																	<template
																		v-if="item.fields[0].append"
																		#suffix
																	>
																		{{ item.fields[0].append }}
																	</template>
																</el-input>
															</el-col>
															<el-col :span="2" class="text-center">-</el-col>
															<el-col :span="11">
																<el-input
																	v-model="queryForm[item.fields[1].prop]"
																	:maxlength="item.fields[1].max"
																	:clearable="item.fields[1].clearable || true"
																	@input="(val) => handleNumIpt(val, item.fields[1].prop)"
																	@blur="compareBig(item.fields)"
																>
																	<template v-if="item.fields[1].append" #suffix>
																		{{ item.fields[0].append }}
																	</template>
																</el-input>
															</el-col>
														</el-row>
													</template>
													<!--下拉选择-->
													<template v-if="item.type === 'select'">
														<el-select
															v-model="queryForm[item.prop]"
															:multiple="item.multiple"
															:clearable="item.clearable!==false"
														>
															<template v-for="(option, key) in item.data" :key="key">
																<el-option
																	:label="option.label"
																	:value="option.value"
																/>
															</template>
														</el-select>
													</template>
													<!--下拉字典选择-->
													<template v-if="item.type === 'selectDicts'">
														<dicts-query
															v-model="queryForm[item.prop]"
															set-null
															:type-code="item.typeCode"
															:filterable="item.filterable"
														/>
													</template>
													<!--							fz_date1-->
													<!--日期选择-->
													<template v-if="item.type === 'date'">
														<el-date-picker
															v-model="queryForm[item.prop]"
															placeholder="请选择"
															:editable="false"
															:clearable="item.clearable!==false"
															type="date"
															value-format="YYYY-MM-DD"
															:disabled-date="item.disabledDate"
														/>
													</template>
													<!--月份选择-->
													<template v-if="item.type === 'dateMonth'">
														<el-date-picker
															v-model="queryForm[item.prop]"
															placeholder="请选择"
															:editable="false"
															:clearable="item.clearable!==false"
															type="month"
															value-format="YYYY-MM"
															:disabled-date="item.disabledDate"
														/>
													</template>
													<!--只显示月份-->
													<template v-if="item.type === 'onlyMonth'">
														<el-date-picker
															v-model="queryForm[item.prop]"
															placeholder="请选择"
															:editable="false"
															:clearable="item.clearable!==false"
															type="month"
															format="MM"
															value-format="MM"
															:disabled-date="item.disabledDate"
															:picker-options="pickerOptions"
															:default-value="dateDefaultShow"
														/>
													</template>
													<!--年份选择-->
													<template v-if="item.type === 'dateYear'">
														<el-date-picker
															v-model="queryForm[item.prop]"
															placeholder="请选择"
															:editable="false"
															:clearable="item.clearable!==false"
															type="year"
															value-format="YYYY"
															:disabled-date="item.disabledDate"
															ref="yearPickRef"
															@change="(val) => yearHandleChange(val, item)"
														/>
													</template>
													<!--时间选择-->
													<template v-if="item.type === 'time'">
														<el-time-select
															v-model="queryForm[item.prop]"
															start="05:00"
															step="00:10"
															end="20:00"
														/>
													</template>
													<!--日期时间选择-->
													<template v-if="item.type === 'datetime'">
														<el-date-picker
															v-model="queryForm[item.prop]"
															placeholder="请选择"
															type="datetime"
															:clearable="item.clearable || true"
															value-format="YYYY-MM-DD HH:mm:ss"
															:disabled-date="item.disabledDate"
														/>
													</template>
													<!--日期范围-->
													<template v-if="item.type === 'dateRange'">
														<el-row>
															<el-col :span="11">
																<el-date-picker
																	v-model="queryForm[item.fields[0].prop]"
																	placeholder="开始日期"
																	:editable="false"
																	type="date"
																	:size="$store.state.global.uiSize"
																	value-format="YYYY-MM-DD"
																	:clearable="item.fields[0].clearable!==false"
																	:disabled-date="item.fields[0].disabledDate"
																	@change="(val) => dateRangeChange(val, item.fields)"
																/>
															</el-col>
															<el-col :span="2" class="text-center">-</el-col>
															<el-col :span="11">
																<el-date-picker
																	v-model="queryForm[item.fields[1].prop]"
																	placeholder="结束日期"
																	:editable="false"
																	type="date"
																	value-format="YYYY-MM-DD"
																	:clearable="item.fields[1].clearable!==false"
																	:disabled-date="item.fields[1].disabledDate"
																	@change="(val) => dateRangeChange(val, item.fields)"
																/>
															</el-col>
														</el-row>
													</template>
													<template v-if="item.type === 'month'">
														<el-row>
															<el-col :span="11">
																<el-date-picker
																	v-model="queryForm[item.fields[0].prop]"
																	placeholder="开始月份"
																	:editable="false"
																	type="month"
																	value-format="YYYY-MM"
																	:clearable="item.fields[0].clearable!==false"
																	:disabled-date="item.fields[0].disabledDate"
																	@change="(val) => monthRangeChange(val, item.fields)"
																/>
															</el-col>
															<el-col :span="2" class="text-center">-</el-col>
															<el-col :span="11">
																<el-date-picker
																	v-model="queryForm[item.fields[1].prop]"
																	placeholder="结束月份"
																	:editable="false"
																	type="month"
																	value-format="YYYY-MM"
																	:clearable="item.fields[1].clearable!==false"
																	:disabled-date="item.fields[1].disabledDate"
																	@change="(val) => monthRangeChange(val, item.fields)"
																/>
															</el-col>
														</el-row>
													</template>
													<!--日期时间范围-->
													<template v-if="item.type === 'dateTimeRange'">
														<el-row>
															<el-col :span="11">
																<el-date-picker
																	v-model="queryForm[item.fields[0].prop]"
																	placeholder="开始日期时间"
																	:editable="false"
																	type="datetime"
																	value-format="YYYY-MM-DD HH:mm:ss"
																	:disabled-date="item.fields[0].disabledDate"
																	@change="(val) => dateRangeChange(val, item.fields)"
																/>
															</el-col>
															<el-col :span="2" class="text-center">-</el-col>
															<el-col :span="11">
																<el-date-picker
																	v-model="queryForm[item.fields[1].prop]"
																	placeholder="结束日期时间"
																	:editable="false"
																	type="datetime"
																	value-format="YYYY-MM-DD HH:mm:ss"
																	:disabled-date="item.fields[1].disabledDate"
																	@change="(val) => dateRangeChange(val, item.fields)"
																/>
															</el-col>
														</el-row>
													</template>
													<!--地区选择-->
													<template v-if="item.type === 'area'">
														<el-cascader
															v-model="area"
															clearable
															:options="$API.area"
															separator="-"
															placeholder="选择地区"
															:props="{
																label: 'name',
																value: 'name',
																expandTrigger: 'hover',
															  }"
															style="width: 100%"
															@change="(val) => handleArea(val, item)"
														/>
													</template>
													<!--弹窗选择用户-->
													<template v-if="item.type === 'popUser'">
														<div class="disflex fullWidth sc-table-select--append">
															<div class="flexgs1">
																<sc-table-select
																	:ref="'ref'+[item.prop]"
																	v-model="queryForm[item.prop]"
																	customerValue
																	fullWidth
																	inputable
																	:multiple="item.multiple"
																	:api-obj="$API.user.getListByKeyword"
																	:params="{flag: 1}"
																	:height="380"
																	:props="{ label: 'name', value: 'id', keyword: 'name' }"
																	@change="(val) => handleTableSel(val, item.fields)"
																	@clear="handleTableClear(item.fields)"
																	@remove-tag="(tag)=>handleRemove(tag,item)"
																>
																	<el-table-column
																		prop="name"
																		label="姓名"
																		width="100"/>
																	<el-table-column
																		prop="fz_departmentName"
																		label="部门"
																		width="100"/>
																	<el-table-column
																		prop="code"
																		label="工号"
																		width="100"/>
																	<template #append>
																		<el-icon
																			@click="popSelect('ref'+[item.prop],item.fields, item.multiple ? 2 : 1, 'refUserList')">
																			<el-icon-search/>
																		</el-icon>
																	</template>
																</sc-table-select>
															</div>
														</div>
													</template>
													<!--弹窗选择车间-->
													<template v-if="item.type == 'popWorkShop'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															@input="(val)=>inputEdit(val,item)"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, item.multiple ? 2 : 1, 'refSelectWorkShop')">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--弹窗选择客户-->
													<template v-if="item.type == 'popCustomer'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															@input="(val)=>inputEdit(val,item)"
															:maxlength="item.max"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, 1, 'refCustomerList', item.params)">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--弹窗选择单位-->
													<template v-if="item.type == 'popCompany'">
														<Selects
															v-model="queryForm[item.prop]"
															:default-assign="false"
                              is-null
															ref="refSelects"
															:api-obj="$API.company.getAllList"
															@set-value="(val) => setValue(val, item.prop)"
														/>
														<!--<el-input
														  :ref="'ref'+[item.prop]"
														  v-model="queryForm[item.prop]"
														>
														  <template #append>
															<el-icon @click="popSelect('ref'+[item.prop],item.fields, 1, 'refCompanyList')">
															  <el-icon-search />
															</el-icon>
														  </template>
														</el-input>-->
													</template>
													<!--选择供应商-->
													<template v-if="item.type == 'popSupplier'">
                            <sc-table-select
                                :ref="'ref'+[item.prop]"
                                v-model="queryForm[item.prop]"
                                customerValue
                                fullWidth
                                inputable
                                :multiple="item.multiple"
                                :api-obj="$API.supplier.getListByKeyword"
                                :params="{flag: 1,...item.params}"
                                :height="426"
                                :props="{ label: 'name', value: 'id', keyword: 'name' }"
                                @change="(val) => handleSelChange(val, item.fields)"
                                @clear="handleTableClear(item.fields)"
                                @remove-tag="(tag)=>handleRemove(tag,item)"
                            >
                              <el-table-column
                                  prop="name"
                                  label="供应商名称"
                                  width="260"
                              />
                              <el-table-column
                                  prop="number"
                                  label="供应商编码"
                                  width="190"
                              />
                              <template #append>
                                <el-icon
                                    @click="popSelect('ref'+[item.prop],item.fields, 1, 'refSupplierList')"
                                >
                                  <el-icon-search />
                                </el-icon>
                              </template>
                            </sc-table-select>
													</template>
													<!--选择车辆-->
													<template v-if="item.type == 'popVehicle'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															@input="(val)=>inputEdit(val,item)"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, 1, 'refSelectLogisticsVehicle')">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--选择司机-->
													<template v-if="item.type == 'popDriver'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															@input="(val)=>inputEdit(val,item)"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, item.multiple ? 2 : 1, 'refSelectLogisticsDriver')">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--选择商品-->
													<template v-if="item.type == 'popGoods'">
														<template v-if="item.fields && item.fields.length > 0">
															<div class="disflex fullWidth sc-table-select--append">
																<div class="flexgs1">
																	<!--物品-->
																	<sc-table-select
																		:ref="'ref'+[item.prop]"
																		v-model="queryForm[item.prop]"
																		customer-value
																		full-width
																		inputable
																		:api-obj="$API.goods.getListByKeyword"
																		:params="{flag: 1}"
																		:height="426"
																		:props="{ label: 'name', value: 'id', keyword: 'name' }"
																		@change="(val) => handleTableSel(val, item.fields)"
																		@clear="handleTableClear(item.fields)"
																	>
																		<el-table-column
																			prop="name"
																			label="物品名称"
																			width="180"
																			show-overflow-tooltip
																		/>
																		<el-table-column
																			prop="code"
																			label="物品编码"
																			width="120"
																			show-overflow-tooltip
																		/>
																		<el-table-column
																			prop="spec"
																			label="规格型号"
																			width="120"
																			show-overflow-tooltip
																		/>
																		<el-table-column
																			prop="mainUnit"
																			label="主单位"
																			width="80"
																			show-overflow-tooltip
																		/>
																		<el-table-column
																			prop="stype"
																			label="类型"
																			width="80"
																			show-overflow-tooltip
																		>
																			<template #default="{ row }">
																				<div v-if="row.stype === 0">
																					原材料
																				</div>
																				<div v-else-if="row.stype === 1">
																					半成品
																				</div>
																				<div v-else-if="row.stype === 2">
																					成品
																				</div>
																			</template>
																		</el-table-column>
																		<template #append>
																			<el-icon
																				@click="popSelect('ref'+[item.prop],item.fields, 1, 'refSelectGoods')">
																				<el-icon-search/>
																			</el-icon>
																		</template>
																	</sc-table-select>
																</div>
															</div>
														</template>
														<template v-else>
															<el-input
																:ref="'ref'+[item.prop]"
																v-model="queryForm[item.prop]"
																@input="(val)=>inputEdit(val,item)"
																clearable
															>
																<template #append>
																	<el-icon
																		@click="popSelect('ref'+[item.prop],item.fields, 1, 'refSelectGoods')">
																		<el-icon-search/>
																	</el-icon>
																</template>
															</el-input>
														</template>
													</template>
													<!--选择工序-->
													<template v-if="item.type == 'popProcedure'">
														<div class="disflex fullWidth sc-table-select--append">
															<div class="flexgs1">
																<sc-table-select
																	:ref="'ref'+[item.prop]"
																	v-model="queryForm[item.prop]"
																	customerValue
																	fullWidth
																	:api-obj="$API.produceCraftProcedure.getList"
																	:params="{flag: 1}"
																	:height="426"
																	:props="{ label: 'name', value: 'id', keyword: 'name' }"
																	@change="(val) => handleTableSel(val, item.fields)"
																	@clear="handleTableClear(item.fields)"
																>
																	<el-table-column
																		prop="name"
																		label="工序名称"
																		width="150"
																	/>
																	<el-table-column
																		prop="code"
																		label="工序编号"
																		width="150"
																	/>
																	<el-table-column
																		prop="workTime"
																		label="工作时长"
																		width="150"
																	/>
																	<template #append>
																		<el-icon
																			@click="popSelect('ref'+[item.prop],item.fields, 1, 'refSelectProcedure')">
																			<el-icon-search/>
																		</el-icon>
																	</template>
																</sc-table-select>
															</div>
														</div>
													</template>
													<!--选择生产人员-->
													<template v-if="item.type == 'popManager'">
														<div class="disflex fullWidth sc-table-select--append">
															<div class="flexgs1">
																<sc-table-select
																	:ref="'ref'+[item.prop]"
																	v-model="queryForm[item.prop]"
																	customerValue
																	fullWidth
																	:multiple="item.multiple"
																	:api-obj="$API.user.getListByKeyword"
																	:params="{flag: 1}"
																	:height="380"
																	:props="{ label: 'name', value: 'id', keyword: 'name' }"
																	@change="(val) => handleTableSel(val, item.fields)"
																	@clear="handleTableClear(item.fields)"
																	@remove-tag="(tag)=>handleRemove(tag,item)"
																>
																	<el-table-column
																		prop="name"
																		label="姓名"
																		width="100"/>
																	<el-table-column
																		prop="fz_departmentName"
																		label="部门"
																		width="100"/>
																	<el-table-column
																		prop="code"
																		label="工号"
																		width="100"/>
																	<template #append>
																		<el-icon
																			@click="popSelect('ref'+[item.prop],item.fields, 2, 'refSelectUser')">
																			<el-icon-search/>
																		</el-icon>
																	</template>
																</sc-table-select>
															</div>
														</div>
													</template>
													<!--选择会计科目-->
													<template v-if="item.type == 'popAccountingTitle'">
														<div class="disflex fullWidth sc-table-select--append">
															<div class="flexgs1">
																<sc-table-select
																	:ref="'ref'+[item.prop]"
																	v-model="queryForm[item.prop]"
																	customerValue
																	fullWidth
																	:multiple="item.multiple"
																	:api-obj="$API.accountingTitle.getList"
																	:params="{flag: 1}"
                                  hide-pagination
																	:props="{ label: 'name', value: 'id', keyword: 'name' }"
                                  :table-width="580"
																	@change="(val) => handleTableSel(val, item.fields)"
																	@clear="handleTableClear(item.fields)"
																	@remove-tag="(tag)=>handleRemove(tag,item)"
																>
                                  <el-table-column
                                      prop="name"
                                      label="科目名称"
                                      :width="220"
                                  />
                                  <el-table-column
                                      prop="number"
                                      label="科目编码"
                                      :width="120"
                                  />
                                  <el-table-column
                                      prop="fz_accountSubject"
                                      label="科目类型"
                                      :width="120"
                                  />
																	<template #append>
																		<el-icon
																			@click="popSelect('ref'+[item.prop],item.fields, 2, 'refSelectUser')">
																			<el-icon-search/>
																		</el-icon>
																	</template>
																</sc-table-select>
															</div>
														</div>
													</template>
													<!--选择仓库-->
													<template v-if="item.type == 'popDepot'">
														<Selects
															v-model="queryForm[item.prop]"
															:defaultAssign="false"
															:api-obj="$API.depot.getAllList"
															show-depot-quantity
															@set-value="(val) => setValue(val, item.prop)"
															@clear="selClear(item)"
														/>
														<!--<el-input
														  :ref="'ref'+[item.prop]"
														  v-model="queryForm[item.prop]"
														>
														  <template #append>
															<el-icon @click="popSelect('ref'+[item.prop],item.fields, 1, 'refSelectDepots')">
															  <el-icon-search />
															</el-icon>
														  </template>
														</el-input>-->
													</template>
													<!--选择账户-->
													<template v-if="item.type == 'popAccount'">
														<Selects
															v-model="queryForm[item.prop]"
															:defaultAssign="false"
															ref="refSelects"
															is-null
															:api-obj="$API.account.getAllList"
															@clear="clear(item.prop)"
															@set-value="(val) => setValue(val, item.prop)"
														/>
														<!--<el-input
														  :ref="'ref'+[item.prop]"
														  v-model="queryForm[item.prop]"
														>
														  <template #append>
															<el-icon @click="popSelect('ref'+[item.prop],item.fields, 1, 'refAccountList')">
															  <el-icon-search />
															</el-icon>
														  </template>
														</el-input>-->
													</template>
													<!--选择物流公司-->
													<template v-if="item.type == 'popLogistics'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															@input="(val)=>inputEdit(val,item)"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, 1, 'refSelectLogistics')">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--选择部门-->
													<template v-if="item.type == 'popDepartment'">
														<el-input
															:ref="'ref'+[item.prop]"
															v-model="queryForm[item.prop]"
															:maxlength="item.max"
															@input="(val)=>inputEdit(val,item)"
														>
															<template #append>
																<el-icon
																	@click="popSelect('ref'+[item.prop],item.fields, item.multiple ? 2 : 1, 'refSelectDepartment')">
																	<el-icon-search/>
																</el-icon>
															</template>
														</el-input>
													</template>
													<!--选择结算方式-->
													<template v-if="item.type == 'popSettlement'">
														<Selects
															v-model="queryForm[item.prop]"
															:api-obj="$API.settlement.getAllList"
															:params="{ pageSize: 100,pageNum: 1}"
															@set-value="(val) => setValue(val, item.prop)"
														/>
													</template>
													<!--选择货位-->
													<template v-if="item.type == 'popPosition'">
														<el-select
															v-model="queryForm['fz_'+item.prop+'Name']"
															value-key="id"
															:disabled="!queryForm.fz_depotName"
															@change="(val)=>setValue(val,item.prop)"
														>
															<el-option
																v-for="position in positionList"
																:key="position.id"
																:value="position"
																:label="position.name+'【库存：'+(position.depotQuantity||0)+'】'"
															/>
														</el-select>
													</template>
													<!--选择批号-->
													<template v-if="item.type == 'popBatchNum'">
														<el-select
															v-model="queryForm[item.prop]"
															value-key="id"
															:disabled="!queryForm.fz_depotName"
															@change="(val)=>setValue(val,item.prop)">
															<el-option
																v-for="(batchNumber, bIndex) in batchNumList"
																:key="bIndex"
																:value="batchNumber.name"
																:label="batchNumber.name+'【库存：'+(batchNumber.depotQuantity||0)+'】'"
															/>
														</el-select>
													</template>
													<!--选择科目类型-->
													<template v-if="item.type == 'popFinanceSub'">
														<Selects
															v-model="queryForm[item.prop]"
															:default-assign="false"
															:api-obj="$API.financeSubject.getAllList"
															:params="{flag: 1}"
															@set-value="(val) => setValue(val, item.prop, item.propId)"
														/>
													</template>
													<!--下拉表格选择-->
													<template v-if="item.type === 'tableSelect'">
														<div class="disflex fullWidth">
															<div class="flexgs1">
																<sc-table-select
																	v-model="queryForm[item.prop]"
																	:api-obj="item.config.apiObj"
																	:params="item.config.params"
																	is-null
																	:customerValue="item.customValue"
																	:table-width="item.config.tableWidth"
																	:props="item.config.props"
																	:multiple="item.config.multiple"
																	:height="item.config.height"
                                  :sel-label="item.selLabel"
																	:hide-pagination="item.config.hidePagination"
																	@clear="(val)=>clear(val,item.config.assignFields?(item.config.assignFields[0].field):item.prop)"
																	@change="(val) => handleSelChange(val, item.config.assignFields || [])"
																>
																	<el-table-column
																		v-for="(col, colindex) in item.config.columns"
																		:key="colindex"
																		:prop="col.prop"
																		:label="col.label"
																		:width="col.width"
																		:show-overflow-tooltip="col.showOverflowTooltip || true"
																	/>
																</sc-table-select>
															</div>
															<div
																v-if="item.config.fastEdit"
																class="flexgs0 selBtnBox"
															>
																<fast-edit
																	ref="popRef1"
																	:config="item.fastOptions"
																/>
															</div>
														</div>
													</template>
												</el-col>
											</el-row>
										</el-form-item>
									</el-col>
								</template>
							</el-row>
						</div>
					</div>
				</div>
			</el-form>
		</div>
		<!--选择用户-->
		<SelectUser
			ref="refUserList"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择入库号-->
		<SelectWarehouseNum
			ref="refWarehouseNumList"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择部门-->
		<SelectDepartment
			ref="refSelectDepartment"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择客户-->
		<SelectCustomer
			ref="refCustomerList"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择单位-->
		<SelectCompany
			ref="refCompanyList"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择供应商-->
		<SelectSupplier
			ref="refSupplierList"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择账户-->
		<SelectAccount
			ref="refAccountList"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<SelectLogistics
			ref="refSelectLogistics"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择商品-->
		<SelectGoods
			ref="refSelectGoods"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择仓库-->
		<SelectDepots
			ref="refSelectDepots"
			:multiple="multiples"
			@set-data="setSelectData"
		/>
		<!--选择车间-->
		<SelectWorkShop
			ref="refSelectWorkShop"
			@set-data="setSelectData"
		/>
		<!--选择工序-->
		<SelectProcedure
			ref="refSelectProcedure"
			@set-data="setSelectData"
		/>
		<!--选择车辆-->
		<SelectLogisticsVehicle
			ref="refSelectLogisticsVehicle"
			@set-data="setSelectData"
		/>
		<!--选择司机-->
		<SelectLogisticsDriver
			ref="refSelectLogisticsDriver"
			@set-data="setSelectData"
		/>
		<!--选择用户-->
		<SelectUser
			ref="refSelectUser"
			@set-data="setSelectData"
		/>
	</el-header>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	onMounted, onBeforeUnmount
} from "vue";
import DictsQuery from "@/views/publicQuery/dict"; //字典选择编辑组件
import {resetNum} from "@/utils/validate";
import FastEdit from "./components/fastEdit";
import SelectGoods from "@/views/publicQuery/selectGoods";
import SelectDepots from "@/views/publicQuery/selectDepots";
import SelectLogistics from "@/views/publicQuery/selectLogistics";

export default defineComponent({
	name: "SyForm",
	components: {SelectLogistics, SelectDepots, SelectGoods, FastEdit, DictsQuery},
	props: {
		//表单显示大小
		size: {type: String, default: "small"},
		//表单配置对象
		configs: {
			type: Object, default: () => {
			}
		},
		//是否显示隐藏项
		showItem: {type: Boolean, default: false}
	},
	//对应父级页面的回调方法
	emits: ["fetch-data", "set-show", "set-value", "clear"],
	//props: 引用当前组件传的参数对象  emit: 触发父页面的函数方法
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//范围选择项列表
			rangeList: ["dateTimeRange", "dateRange",'month'],
			depotList: [],
			multiples: false,
			fields: [],
			popRef1: null,
			form: null,
			span: 6,
			queryForm: {},
			positionList: [],// 货位数据
			batchNumList: [],// 批号数据
			userItems: props.configs.itemList || [],
			showFitem: true,
			area: [],

			//
			dateDefaultShow: "",
			//
			shortCuts: [
				{
					text: "今天",
					value: new Date(),
				},
				{
					text: "昨天",
					value: () => {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						return date;
					},
				},
				{
					text: "一周前",
					value: () => {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
						return date;
					},
				},
			],
			formPanel: null,
		});

		let resizeObserver = null;
		onMounted(() => {
			// 添加键盘按键事件监听器
			const el = document.getElementById("searchForm");
			if (el) {
				el.addEventListener("keyup", function (event) {
					if (event.keyCode === 13) {
						handleSubmit();
					}
				});
			}
			Object.assign(state.queryForm, props.configs.queryForm);
			try {
				// 元素大小监听,改变span
				resizeObserver = new ResizeObserver(entries => {
					for (let entry of entries) {
						const {width, height} = entry.contentRect;
						if (width > 800) {
							state.span = 6;
						} else if (width < 800 && width > 600) {
							state.span = 8;
						} else if (width < 600 && width > 400) {
							state.span = 12;
						} else if (width < 400 && width > 0) {
							state.span = 24;
						}
					}
				});

				resizeObserver.observe(state.formPanel);
			} catch (e) {
				console.log(e);
			}
		});

		onBeforeUnmount(() => {
			//停止监听
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		});

		//年份选择器变化
		const yearHandleChange = (val, item) => {
			// 把年份存在queryForm
			state.queryForm["accountingYear"] = val;
			state.queryForm["accountingYear"];
			state.dateDefaultShow = state.queryForm["accountingYear"]; //日期选择器定位当一年前的今日
			console.log(val);
			console.log(item);
			console.log(state.queryForm);
		};
		let disabledDate = (time) => {
			const year = state.queryForm["accountingYear"]; // 获取当前年份
			const oneYear = Number(year) + 1; // 获取下一年
			const lastYear = Number(year) - 1; // 获取上一年
			const timeYear = new Date(oneYear + "/1/1").getTime(); // 将明年的日期转换成时间戳
			const timeYearlast = new Date(lastYear + "/12/31").getTime(); // 将去年的日期转换成时间戳
			return time.getTime() >= timeYear || time.getTime() <= timeYearlast;
		};
		//表单重置
		const handleReset = () => {
			state["form"].resetFields();
			Object.keys(state.queryForm).forEach((item) => {
				delete state.queryForm[item];
			});
			// 删除下拉选择框的值
			const selects = proxy.$refs.refSelects
			if(selects && selects.length){
				selects.forEach((item)=>{
					item.clearValue()
				})
			}
			state.area = [];
			Object.assign(state.queryForm, props.configs.queryForm);
			emit("fetch-data", state.queryForm);
		};
		//表单提交
		const handleSubmit = () => {
			//把表单对象中的属性值传给父级页面
			emit("fetch-data", state.queryForm);
		};

		//切换显示全部项
		const handleExpandForm = () => {
			state.showFitem = false;
			nextTick(() => {
				state.showFitem = true;
				console.log(state.showFitem, "2");
				emit("set-show", state.showFitem);
				state.userItems.forEach((item) => {
					if (!item.default) {
						item["show"] = true;
					}
				});
			});
		};

		//表格选择回调
		const handleSelChange = (val, fields) => {
			if (fields.length > 0 && fields[0].field) {
				fields.forEach((item) => {
					state.queryForm[item.field] = val[item.value];
				});
			}else{
        state.queryForm[fields[0]] = val.id
        state.queryForm[fields[1]] = val.name
      }
		};

		//数字输入框
		const handleNumIpt = (val, field) => {
			state.queryForm[field] = resetNum(val);
		};

		//比较大小
		const compareBig = (fields) => {
			if (fields) {
				if (state.queryForm[fields[0].prop] && state.queryForm[fields[1].prop]) {
					const start = state.queryForm[fields[0].prop] - 0;
					const end = state.queryForm[fields[1].prop] - 0;
					if (start > end) {
						state.queryForm[fields[1].prop] = start;
					}
				}
			}
		};

		//地区选择变化
		const handleArea = (val, item) => {
			if (val) {
				state.queryForm[item.prop] = val.join("-");
			} else {
				state.queryForm[item.prop] = "";
			}
		};

		//比较前后日期
		const dateRangeChange = (val, fields) => {
			if (state.queryForm[fields[0].prop] && state.queryForm[fields[1].prop]) {
				const start = state.queryForm[fields[0].prop];
				const end = state.queryForm[fields[1].prop];
				if (Date.parse(start) > Date.parse(end)) {
					proxy.$baseMessage("结束日期不能小于开始日期", "error");
					state.queryForm[fields[1].prop] = "";
				}
			}
		};

		//比较前后月份
		const monthRangeChange = (val, fields) => {
			if (state.queryForm[fields[0].prop] && state.queryForm[fields[1].prop]) {
				const start = state.queryForm[fields[0].prop];
				const end = state.queryForm[fields[1].prop];
				if (Date.parse(start) > Date.parse(end)) {
					proxy.$baseMessage("结束月份不能小于开始月份", "error");
					state.queryForm[fields[1].prop] = "";
				}
			}
		};

		//打开用户选择弹窗 ref触发对象 fields赋值的字段集合 type:1单选 2多选
		const popSelect = (ref, fields, type, pop, params) => {
			state.fields = fields;
			let param = state.form[fields[0]];
			if (type === 1) { //单选
				state.multiples = false;
			} else { //多选
				state.multiples = true;
			}
			//赋值弹窗的触发元素
			proxy.$refs[pop].triggerDom = proxy.$refs[ref];
			//打开弹窗
			if (params) {
				proxy.$refs[pop].showDialog(params);
			} else {
				proxy.$refs[pop].showDialog(param);
			}
		};

		const setSelectData = (data) => {
			if (state.fields.length > 0) {
				if (data.length) { //多选
					const ids = data.map((item) => {
						return item.id;
					}).join();
					const names = data.map((item) => {
						return item.name;
					}).join();
					state.queryForm[state.fields[0]] = ids;
					state.queryForm[state.fields[1]] = names;
				} else { //单选
					state.queryForm[state.fields[0]] = data.id;
					if (state.fields[1] === "fz_vehicleName") {
						state.queryForm[state.fields[1]] = data.license;
					} else {
						state.queryForm[state.fields[1]] = data.name;
					}
				}
			}
		};

		const setValue = (val, field, propId) => {
			if (val) {
				state.queryForm[field + "Id"] = val.id;
				state.queryForm["fz_" + field + "Name"] = val.name;
				if (propId) {
					state.queryForm[propId] = val.id;
				}
			} else {
				state.queryForm[field + "Id"] = null;
				state.queryForm["fz_" + field + "Name"] = "";
				if (propId) {
					state.queryForm[propId] = null;
				}
			}
			emit("set-value", {value: val, field: field});
			if (field == "depot") {
				getPositionList();
				getBatchNumList();
			} else if (field == "position") {
				getBatchNumList();
			}
		};
		const getPositionList = async () => {
			state.queryForm["positionId"] = null;
			state.queryForm["fz_positionName"] = "";
			const res = await proxy.$API.freight.getDepotLocation.get(state.queryForm.depotId, state.queryForm.goodsId || "");
			if (res.code === 200 && res.data && res.data.length > 0) {
				//只筛选库存数大于0的货位
				// const data = res.data.filter((item)=>{
				//   return item.depotQuantity && (item.depotQuantity - 0) > 0
				// })
				if (res.data && res.data.length > 0) {
					state.positionList = res.data.map((item) => {
						return {
							name: item.name,
							id: item.id,
							depotQuantity: item.depotQuantity || 0
						};
					});
				}
			} else {
				state.positionList = [];
			}
		};
		const getBatchNumList = async () => {
			state.queryForm["batchNumber"] = "";
			const param = {
				goodsId: state.queryForm.goodsId || "",
				depotId: state.queryForm.depotId,
				positionId: state.queryForm.positionId,
			};
			const res = await proxy.$API.stockRecord.getBatchNum.get(param);
			if (res.code === 200 && res.data.list && res.data.list.length > 0) {
				state.batchNumList = res.data.list.map((item) => {
					return {
						name: item.batchNumber,
						depotQuantity: item.depotQuantity
					};
				});
			} else {
				state.batchNumList = [];
			}
		};

		const clear = (val, prop) => {
			// console.log(val,'===========')
			// console.log(item.config.assignFields[0].field,'===========')
			// if(!val || val == ''){
			//     item.config.assignFields[0].field=null
			// }
      state.queryForm[prop] = null
			emit("clear", prop);
		};
		const selClear = (item) => {
			if (item.prop == "depot") {
				delete state.queryForm["positionId"];
				delete state.queryForm["fz_positionName"];
				delete state.queryForm["batchNumber"];
			}
			emit("clear", item);
		};
		// 带选择输入框在手动输入时清除id
		const inputEdit = (val, item) => {
			// if(!val || val == ''){
			if (item.fields && item.fields[0]) {
				state.queryForm[item.fields[0]] = null;
			}
			// }
		};
		const handleTableSel = (val, fields) => {
			if (val) {
				//多选
				if (val.length && val.length > -1) {
					let ids = "";
					let names = "";
					if (val.length > 0) {
						//取得选中的id串
						ids = val.map((item) => item.id).join();
						names = val.map((item) => item.name).join();
					} else {
						ids = "";
						names = "";
					}
					state.queryForm[fields[0]] = ids;
					state.queryForm[fields[1]] = names;
				} else {
					state.queryForm[fields[0]] = val.id;
					state.queryForm[fields[1]] = val.name;
				}
			} else {
				state.queryForm[fields[0]] = null;
				state.queryForm[fields[1]] = "";
			}
		};
		const handleTableClear = (fields) => {
			state.queryForm[fields[0]] = null;
			state.queryForm[fields[1]] = "";
		};
		const handleRemove = async (tag, item) => {
			if (item.fields && item.fields[0]) {
				state.queryForm[item.fields[0]] = state.queryForm[item.prop].map((item) => item.id).join();
				state.queryForm[item.fields[1]] = state.queryForm[item.prop].map((item) => item.name).join();
			}
		};
		return {
			...toRefs(state),
			monthRangeChange,
			compareBig,
			handleTableSel,
			handleRemove,
			handleTableClear,
			setValue,
			clear,
			selClear,
			setSelectData,
			popSelect,
			dateRangeChange,
			handleNumIpt,
			handleArea,
			handleSubmit,
			handleReset,
			handleExpandForm,
			handleSelChange,
			yearHandleChange,
			pickerOptions: {
				disabledDate,
			},
			inputEdit
		};
	},
});
</script>
<style lang="scss">
.syForm .el-input, .syForm .el-select {
	width: 100%;
}

.syForm .el-form {
	width: 100%;
}

.syForm .el-form-item {
	margin-right: 0;
	margin-bottom: 15px;
	width: 100%;
}

.syForm:deep(.el-form) .el-input.el-date-editor--date {
	width: 100%;
}
</style>
