//数据表格配置

import tool from '@/utils/tool'
import col from "element-plus/es/components/col/src/col2";
import ColumnApi from '@/api/model/cfg/system/column'

export default {
	successCode: 200,												//请求完成代码
	pageSize: 100,													//表格每一页条数
	pageSizes: [10, 20, 50, 100, 500, 1000],								//表格可设置的一页条数
	paginationLayout: "total, sizes, prev, pager, next, jumper",	//表格分页布局，可设置"total, sizes, prev, pager, next, jumper"
	parseData: function (res) {										//数据分析
		return {
			data: res.data,				//分析无分页的数据字段结构
			rows: res.data.list,		//分析行数据字段结构
			total: res.data.total,		//分析总数字段结构
			summary: res.data.summary,	//分析合计行字段结构
			msg: res.msg,			//分析描述字段结构
			code: res.code				//分析状态字段结构
		}
	},
	request: {							    //请求规定字段
		page: 'pageNum',					//规定当前分页字段
		pageSize: 'pageSize',			    //规定一页条数字段
		prop: 'prop',					    //规定排序字段名字段
		order: 'order'					    //规定排序规格字段
	},
	/**
	 * 自定义列保存处理
	 * @tableName scTable组件的props->tableName
	 * @column 用户配置好的列
	 */
	columnSettingSave: function (tableName, column) {
		return new Promise(async (resolve) => {
			if(tableName){
				if(column && column.length > 0){
					column.forEach((item, index) => {
						item.serial = index
					})
				}
				const res = await ColumnApi.insert.post({ typeCode: tableName, list: column })
				if(res.code === 200){
					resolve(true)
				}
				//tool.session.set(tableName, column)
			}
			/*setTimeout(()=>{
				//这里为了演示使用了session和setTimeout演示，开发时应用数据请求

				if(tableName){
					column.insert.post({ tableName: tableName, list: column })
					tool.session.set(tableName, column)
					resolve(true)
				}
			},1000)*/
		})
	},
	/**
	 * 获取自定义列
	 * @tableName scTable组件的props->tableName
	 * @column 组件接受到的props->column
	 */
	columnSettingGet: function (tableName, column) {
		return new Promise(async (resolve) => {
			let res = null
			if(tableName){
				res = await ColumnApi.getAllList.get({ typeCode: tableName })
			}
			if(res && res.data && res.data.length > 0){
				res.data.forEach((item) => {
					if(item.hide === 'true'){
						item.hide = true
					}else{
						item.hide = false
					}
					if(item.sortable === 'true'){
						item.sortable = true
					}else{
						item.sortable = false
					}
					if(item.fixed === 'false'){
						item.fixed = false
					}
				})
				resolve(res.data)
			}else{
				resolve(column)
			}
			/*const userColumn = tool.session.get(tableName)
			if(userColumn){
				resolve(userColumn)
			}else{
				resolve(column)
			}*/
		})
	},
	/**
	 * 重置自定义列
	 * @tableName scTable组件的props->tableName
	 * @column 组件接受到的props->column
	 */
	columnSettingReset: function (tableName, column) {
		return new Promise(async (resolve) => {
			//保存列
			/*if(tableName){
				if(column && column.length > 0){
					column.forEach((item, index) => {
						item.serial = index
					})
				}
				const res = await ColumnApi.insert.post({ typeCode: tableName, list: column })
				if(res.code === 200){
				}
			}*/
			if(tableName){
				const res = await ColumnApi.deleteByTableName.get(tableName)
				if(res.code === 200){
				}
			}
			resolve(column)
			//这里为了演示使用了session和setTimeout演示，开发时应用数据请求
			/*setTimeout(()=>{
				tool.session.remove(tableName)
				resolve(column)
			},1000)*/
		})
	}
}
