import config from "@/config"
import http from "@/utils/request"

export default {
	update: {
		url: `${config.API_URL}/cfg/business/setup/update`,
		name: "保存业务设置",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
    getList: {
        url: `${config.API_URL}/cfg/business/setup/getList`,
        name: "列表",
        get: async function(data={}){
            return await http.post(this.url, data);
        }
    },
    getFinanceState: {
        url: `${config.API_URL}/cfg/business/setup/getState`,
        name: "获取财务初始化状态",
        get: async function(){
            return await http.get(this.url);
        }
    },
    generateGoodsCode: {
        url: `${config.API_URL}/cfg/business/setup/generateGoodsCode`,
        name: "获取自动生成的编码",
        get: async function(classifyId){
            return await http.get(this.url+'?classifyId='+classifyId);
        }
    },
}
