import config from "@/config"
import http from "@/utils/request"
export default {
	getList: {
		url: `${config.API_URL}/erp/sale/order/salesRanking`,
		name: "销售排行榜",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
