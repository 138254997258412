<template>
  <el-tree
    ref="refTree"
    class="menu"
    node-key="id"
    :data="groupData"
    :default-expand-all="expandAll"
    :expand-on-click-node="false"
    :props="property"
    highlight-current
    :current-node-key="activeNode"
    @node-click="nodeClick"
  />
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	name: 'DropClassify',
	emits: ['loaded','node-click'],
	setup(props,{emit}) {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			expandAll: true,
			top: '',
			//加载动画
			loading: true,
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//节点数据
			groupData: [],
			property: {label: 'fz_name', children: 'children'},
			activeNode: '',
		})

		//拉取数据
		const fetchData = async () => {
			const res = await proxy.$API.classify.getList.get()
			if (res.code === 200) {
				state.groupData = res.data.list
			}
			emit('loaded')
		}

		//节点点击
		const nodeClick = (data, node) => {
			state.activeNode = data.id
			emit('node-click',data)
		}

		return {
			...toRefs(state),
			nodeClick,
			fetchData,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

