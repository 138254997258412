var offsetX = 0;
var width = 40;
var height = 30;
var rotate = 0;
var marginX = 2.0;
var marginY = 2.0;
var barCodeWidth = width - marginX * 2
var fontSize = 3.2;
var qrCodeHeight = height - marginY * 2;

//组合打印数据
export function printData(parameterArr) {
	let data = [];
	parameterArr.forEach( s => {
		let printDataArr =  {
			InitDrawingBoardParam: {
				"width": width,
				"height": height,
				"rotate": rotate,
				"path": "ZT001.ttf",
				"verticalShift": 0,
				"HorizontalShift": 0
			},

			"elements": [
				// {
				// 	type: 'barCode',
				// 	json: {
				// 		"x": marginX + offsetX,
				// 		"y": marginY,
				// 		"height": 13,
				// 		"width": barCodeWidth,
				// 		"value": '',
				// 		"codeType": 20,
				// 		"rotate": 0,
				// 		"fontSize": fontSize,
				// 		"textHeight": 2,
				// 		"textPosition": 0,
				// 	}
				// },
				// {
				// 	type: 'text',
				// 	json: {
				// 		"x": 3,
				// 		"y": 15,
				// 		"height": 11,
				// 		//"width": contentWidth-qrCodeWidth-marginX,
				// 		"width": 40,
				// 		"value": s.printValue||("物品名称：" + s.goodsName + "\n数量：" + s.quantity + "\n型号：" + s.goodsSpec+ "\n入库号：" + s.warehouseNum + "\n材质：" + s.fz_goodsMaterial + "\n长度/尺寸：" + s.size + "\n长度-紧固件：" + s.fasteners),
				// 		"fontFamily": "宋体",
				// 		"rotate": 0,
				// 		"fontSize": 2.4,
				// 		"textAlignHorizonral": 0,
				// 		"textAlignVertical": 0,
				// 		"letterSpacing": 0.0,
				// 		"lineSpacing": 1.0,
				// 		"lineMode": 6,
				// 		"fontStyle": [true, false, false, false],
				// 	}
				// },
				{
					type: 'qrCode',
					json: {
						// "x": marginX + offsetX,//水平坐标
						"x": 10,//水平坐标
						// "y": marginY,//垂直坐标
						"y": 5,//垂直坐标
						"height": 20,//高度,单位mm
						"width": 20,//宽度,单位mm
						"value": JSON.stringify(s),// 文本内容
						"rotate": 0,//旋转角度，仅支持0,90,180,270
						"codeType": 31//一维码类型,31:QR_CODE,32:PDF417,33:DATA_MATRIX,34:AZTEC
					}
				},
			]
		};
		data.push(printDataArr);
	})
	return data;
}
