import config from "@/config"
import http from "@/utils/request"
/*打印模板管理*/
export default {
	getList: {
		url: `${config.API_URL}/cfg/system/print/getList`,
		name: "分页查询",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	updateSilent: {
		url: `${config.API_URL}/cfg/system/print/updateSilent`,
		name: "设置默认",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	printLayout: {
		url: `${config.API_URL}/cfg/system/print/printLayout`,
		name: "根据菜单编码查询所有模板",
		get: async function(params){
			return await http.get(this.url + '?menuCode=' + params);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/system/print/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.get(this.url + '?ids=' + data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/system/print/printLayout`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?menuCode=' + params);
		}
	},
	printDetail: {
		url: `${config.API_URL}/cfg/system/print/detail`,
		name: "查询单条数据",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/print/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/system/print/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
}
