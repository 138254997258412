import config from "@/config"
import http from "@/utils/request"

export default {
	supplierInquiryDetail: {
		url: `${config.API_URL}/crm/statistics/supplier/inquiryDetail`,
		name: "管理 - 采购询价单览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleOpportunityFunnel: {
		url: `${config.API_URL}/crm/statistics/sale/opportunityFunnel`,
		name: "销售机会 - 机会漏斗图",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	customerCount: {
		url: `${config.API_URL}/crm/statistics/customer/count`,
		name: "客户管理 - 客户统计",
		get: async function(params){
			return await http.get(this.url + "?type=" + params);
		}
	},
	customerOverdue: {
		url: `${config.API_URL}/crm/statistics/customer/overdue`,
		name: "客户关系 - 超期客户预警",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
