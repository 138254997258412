import config from "@/config"
import http from "@/utils/request"

export default {
	getDeadlineList: {
		url: `${config.API_URL}/cfg/business/deadline/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllList:{
		url: `${config.API_URL}/cfg/business/deadline/getAllList`,
		name: "所有",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	delDeadline: {
		url: `${config.API_URL}/cfg/business/deadline/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailDeadline: {
		url: `${config.API_URL}/cfg/business/deadline/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	insertDeadline: {
		url: `${config.API_URL}/cfg/business/deadline/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateDeadline: {
		url: `${config.API_URL}/cfg/business/deadline/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagDeadline0: {
		url: `${config.API_URL}/cfg/business/deadline/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagDeadline1: {
		url: `${config.API_URL}/cfg/business/deadline/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
