
import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/erp/sn/input/getList`,
        name: "查询序列号",
        get: async function(data={}){
            return await http.post(this.url, data);
        }
    },

    delete: {
        url: `${config.API_URL}/erp/sn/input/delete`,
        name: "删除",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },

    insert: {
        url: `${config.API_URL}/erp/sn/input/insert`,
        name: "新增",
        get: async function(data={}){
            return await http.post(this.url,data);
        }
    },

    update: {
        url: `${config.API_URL}/erp/sn/input/update`,
        name: "修改",
        get: async function(data={}){
            return await http.put(this.url,data);
        }
    },

    detail: {
        url: `${config.API_URL}/erp/sn/input/detail`,
        name: "查询单条数据",
        get: async function(params){
            return await http.get(this.url + '?id=' + params);
        }
    },

    updateFlag1: {
        url: `${config.API_URL}/erp/sn/input/updateFlag1`,
        name: "启用",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },
    updateFlag0: {
        url: `${config.API_URL}/erp/sn/input/updateFlag0`,
        name: "停用",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },

    importExcel:{
        url: `${config.API_URL}/erp/sn/input/importExcel`,
        name: "导入Excel",
        post: async function(data = {},config){
            return await http.post(this.url,data,config);
        }
    },
}
