<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
    :width="width"
    :bef-close="false"
  >
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      class="column3"
    >
      <el-descriptions-item
        label="设备名称"

        :label-align="labelAlign"
      >
        {{ data.name }}
      </el-descriptions-item>

      <el-descriptions-item
        label="设备编号"

        :label-align="labelAlign"
      >
        {{ data.code }}
      </el-descriptions-item>

      <el-descriptions-item
        label="品牌"

        :label-align="labelAlign"
      >
        {{ data.brand }}
      </el-descriptions-item>

<!--      <el-descriptions-item-->
<!--        label="类型"-->
<!--        :label-align="labelAlign"-->
<!--      >-->
<!--        {{ data.stype }}-->
<!--      </el-descriptions-item>-->

      <el-descriptions-item
        label="型号"
        :label-align="labelAlign"
      >
        {{ data.model }}
      </el-descriptions-item>

      <el-descriptions-item
        label="规格"
        :label-align="labelAlign"
      >
        {{ data.spec }}
      </el-descriptions-item>

      <el-descriptions-item
        label="外形尺寸"
        :label-align="labelAlign"
      >
        {{ data.outline }}
      </el-descriptions-item>

      <el-descriptions-item
        label="重量"
        :label-align="labelAlign"
      >
        {{ data.weight }}
      </el-descriptions-item>
      <el-descriptions-item
        label="采购成本[元]"
        :label-align="labelAlign"
      >
        {{ data.cost }}
      </el-descriptions-item>
      <el-descriptions-item
        label="存放地点"
        :label-align="labelAlign"
      >
        {{ data.position }}
      </el-descriptions-item>
      <el-descriptions-item
        label="保养周期"
        :label-align="labelAlign"
      >
        {{ data.maintain }}
      </el-descriptions-item>
      <el-descriptions-item
        label="功率[W]"
        :label-align="labelAlign"
      >
        {{ data.power }}
      </el-descriptions-item>
      <el-descriptions-item
        label="电费[元]"
        :label-align="labelAlign"
      >
        {{ data.powerRate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="负责人"
        :label-align="labelAlign"
      >
        {{ data.fz_managerName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="状态"
        :label-align="labelAlign"
      >
        <el-tag
          v-if="data.flag === 0"
          type="danger"
          :size="$store.state.global.uiSize"
        >
          停用
        </el-tag>
        <el-tag
          v-if="data.flag === 1"
          type="success"
          :size="$store.state.global.uiSize"
        >
          启用
        </el-tag>
        <el-tag
          v-if="data.flag === 2"
          type="warning"
          :size="$store.state.global.uiSize"
        >
          报废
        </el-tag>
        <el-tag
          v-if="data.flag === 3"
          type="info"
          :size="$store.state.global.uiSize"
        >
          封存
        </el-tag>
        <el-tag
          v-if="data.flag === 4"
          type="info"
          :size="$store.state.global.uiSize"
        >
          借出
        </el-tag>
        <el-tag
          v-if="data.flag === 5"
          type="info"
          :size="$store.state.global.uiSize"
        >
          转让
        </el-tag>
        <el-tag
          v-if="data.flag === 6"
          type="info"
          :size="$store.state.global.uiSize"
        >
          维修
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item
        label="出厂日期"

        :label-align="labelAlign"
      >
        {{ data.factoryDate }}
      </el-descriptions-item>

      <el-descriptions-item
        label="采购日期"

        :label-align="labelAlign"
      >
        {{ data.purchaseDate }}
      </el-descriptions-item>

      <el-descriptions-item
        label="投产日期"
        :label-align="labelAlign"
      >
        {{ data.produceDate }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="生产厂家"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        {{ data.supplier }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="用途"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        {{ data.purpose }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="备注"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        {{ data.remark }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="附件"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        <div class="mtf_5">
          <el-tag
            v-for="(file, index) in fileList"
            :key="index"
            class="dtFile"
            @click="previewFile(file)"
          >
            <a href="javascript:">{{ file.name }}</a>
          </el-tag>
        </div>
      </el-descriptions-item>
    </el-descriptions>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
	import {
		defineComponent,
		toRefs,
		reactive,
		getCurrentInstance
	} from 'vue'

	export default defineComponent({
		setup() {
			//获取当前实例对象
			const {proxy} = getCurrentInstance()
			const state = reactive({
				//弹窗宽度
				width: '70%',
        type:"detail",
				//加载动画
				loading: true,
				//标题对齐方式 'left' 'right' 'center'
				labelAlign: 'right',
				//尺寸大小
				size: 'small',
				//控制弹窗显示隐藏
				visible: false,
				//弹窗标题
				title: '设备信息详情',
				data: {},
				//详情数据对象
				deswidth: '25%',
				fileList: [],
			})

			//显示弹窗
			const showDialog = async (data) => {
				state.fileList = []
				state.visible = true
				if (data.id) {
					//根据id查询
					const res = await proxy.$API.device.detail.get(data.id)
					if (res.code === 200) {
						state.data = res.data
						getFileList(res.data.files)
					}
				}
				state.loading = false
			}

			const getFileList = (data) => {
				let arr = []
				if (data) {
					arr = data.split(',')
					if (arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							const iarr = item.split('/')
							obj['url'] = item
							obj['name'] = iarr[iarr.length - 1]
							state.fileList.push(obj)
						})
					}
				}
			}

			const previewFile = async (file) => {
				window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
				await proxy.$API.files.insertFilesDownload.post(file)
			}

			//隐藏弹窗
			const hideDialog = () => {
				state.visible = false
			}

			return {
				...toRefs(state),
				showDialog,
				previewFile,
				hideDialog,
			}
		},
	})
</script>
