import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/erp/produce/workOrder/getList`,
        name: "查询列表数据",
        get: async function(data={}){
            return await http.post(this.url,data);
        }
    },
    // selectWorkGroupDetail: {
    //     url: `${config.API_URL}/erp/produce/workOrder/detail/selectWorkGroupDetail`,
    //     name: "查询列表数据",
    //     post: async function (data = {}) {
    //         return await http.post(this.url, data);
    //     }
    // },
    insert: {
        url: `${config.API_URL}/erp/produce/workOrder/insert`,
        name: "新增",
        post: async function(data={}){
            return await http.post(this.url,data);
        }
    },
    update: {
        url: `${config.API_URL}/erp/produce/workOrder/update`,
        name: "修改",
        put: async function(data={}){
            return await http.put(this.url,data);
        }
    },
    detail: {
        url: `${config.API_URL}/erp/produce/workOrder/detail`,
        name: "查询详情",
        get: async function(params){
            return await http.get(this.url + "?id=" + params);
        }
    },
    delete: {
        url: `${config.API_URL}/erp/produce/workOrder/delete`,
        name: "删除",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },

	selectWorkOrderDetail: {
		url: `${config.API_URL}/erp/produce/workOrder/detail/selectWorkOrderDetail`,
		name: "查询子数据",
		get: async function(params){
			return await http.post(this.url ,params);
		}
	},

	selectDetailByWorkOrderId: {
		url: `${config.API_URL}/erp/produce/workOrder/detail/selectDetailByWorkOrderId`,
		name: "查询子数据",
		get: async function(params){
			return await http.post(this.url ,params);
		}
	},

    selectByProcessId: {
        url: `${config.API_URL}/erp/produce/workOrder/selectByProcessId`,
        name: "查询子数据",
        get: async function(params){
            return await http.post(this.url ,params);
        }
    },
	procdureFinish: {
        url: `${config.API_URL}/erp/produce/workOrder/procdureFinish`,
        name: "工序完工",
        get: async function(params){
            return await http.post(this.url ,params);
        }
    },
    lastProcdureFinish: {
        url: `${config.API_URL}/erp/produce/workOrder/lastProcdureFinish`,
        name: "工序完工1",
        get: async function(params){
            return await http.post(this.url ,params);
        }
    },
    workOrderClose: {
        url: `${config.API_URL}/erp/produce/workOrder/workOrderClose`,
        name: "关闭订单",
        get: async function(ids){
            return await http.get(this.url+'?ids='+ids);
        }
    },
    workOrderStart: {
        url: `${config.API_URL}/erp/produce/workOrder/workOrderStart`,
        name: "开始订单",
        get: async function(ids){
            return await http.get(this.url+'?ids='+ids);
        }
    },
}
