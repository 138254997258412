//用于判断按钮点击 不需要弹出加载的按钮
export default function btnEvals(evt){
    const exceptBtns = [
        'handlePrint',
        'handleExportWord',
        'handleExport',
        'handleEdit',
        'handleQuality',
        'handleInspectionRecord',
        'handleApproval',
        'handleOutStock',
        'handleRecord',
        'handleOut',
        'handleEnter',
        'handleDetail',
        'handleProcessing',
        'handleProducePlan',
        'handleProduceDesign',
        'handleProduceProcess',
        'getHandleProduceProcess',
        'handleOutProcess',
        'handlePurchaseApply',
        'handleAssemble',
        'handleProduceMRP',
        'handleStockMaterial',
        'handleStockRefund',
        'handleRoute',
        'handleAccept',
        'handleCheck',
        'handleTransfer',
        'handleQuantity',
        'handleTsContract',
        'handleTsOrder',
        'handleLevel',
        'handleCostDetail',
        'handleImport',
        'handleSet',
        'handleLocus',
        'handleTsRepair',
        'handleSale',
        'handleTurnCheck',
        'handleTurnVoucher',
        'handleTurnJournal',
        'handleSaleDetail',
        'handleRecDetail',
        'provision',
        'handleVoucher',
        'handleCancel',
        // 'handleEnd',
        'handleLogistics',
        'handleInvoiceRecord',
        'handlePreview',
        'handleRoleWapSet',
        'handleRoleSet',
        'handleUserPowerSet',
        'handleUserPowerSetWap',
        'handleAdd',
        'handleExplain',
        'handleDistribution',
        'handleContractStatus',
        'handleMaterialDetail',
        'handlePurDetail',
        'handleContrast',
        'handleTurnRefund',
        'handleSubm',
        'handleStopExecuteq',
        'handleCostRelation',
        'handleTrackStatus',
        'handleTsInvoice',
        'handlePurchase',
        'handleProduction',
        'handleInvoicing',
        'handleWarehouseNum',
		'handleUpdate',
		'handleGrant',
		'handleHistory',
        'handleQrcode',
		'handleHistoryVersion',
        'handleStart'
    ]
	const str = evt.split('(')
    const btn = exceptBtns.find((item) => {
        return item === str[0]
    })
    return !btn;
}
