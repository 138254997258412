import config from "@/config"
import http from "@/utils/request"
export default {
	getList: {
		url: `${config.API_URL}/cfg/examine/task/currentUserApproval`,
		name: "列表",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},
}
