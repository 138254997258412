import config from "@/config"
import http from "@/utils/request"

export default {
	selectOfferGoods: {
		url: `${config.API_URL}/erp/sale/offer/goods/selectOfferGoods`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getList: {
		url: `${config.API_URL}/erp/sale/offer/goods/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
