import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getSellAdvreceiptMeltDetailList: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMeltDetail/getList`,
		name: "根据预收核销Id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	updateMeltStatus: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/updateMeltStatus`,
		name: "确认核销",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	handleCancel: {
		url: `${config.API_URL}/fms/sell/sellAdvreceiptMelt/handleCancel`,
		name: "作废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}

