<template>
	<sc-dialog
		v-model="visible"
		:title="config.title || '复制信息'"
		:loading="loading"
		:width="config.width || '60%'"
		:height="config.height || '450px'"
		:bef-close="false"
		:trigger-dom="triggerDom"
		:type="type"
	>
		<el-container>
			<el-header class="tableTopBar">
				<div class="right-panel">
					<!--切换显示搜索栏hide-after: 延迟隐藏-->
					<el-tooltip
						:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
						placement="bottom"
						:hide-after="0"
					>
						<el-link
							v-auth="$MENU.code.SALE_ORDER+'::search'"
							@click="handleShowSearch"
						>
							搜索
							<el-icon>
								<template v-if="hideForm">
									<el-icon-arrowDown/>
								</template>
								<template v-else>
									<el-icon-arrowUp/>
								</template>
							</el-icon>
						</el-link>
					</el-tooltip>
				</div>
			</el-header>
			<el-main class="nopadding">
				<el-container
					direction="vertical"
					class="mainPanel"
				>
					<div>
						<sy-search-Form
							ref="refFormBar"
							v-show="!hideForm"
							:configs="config.searchConfig"
							:show-item="showItem"
							@fetch-data="fetchData"
							@set-show="handleFitShow"
						/>
					</div>
					<el-main class="nopadding">
						<splitpanes
							:horizontal="true"
							@resized="handleResized"
						>
							<pane>
								<!--父级-->
								<el-container class="fatherCont">
									<el-main class="nopadding">
										<scTable
											ref="table"
											:api-obj="config.apiObj"
											:column="config.columns"
											:params="config.params"
											row-key="id"
											border
											highlight-current-row
											remote-sort
											:ref-query-form="refFormBar"
											remote-filter
											hide-slot
											@row-dblclick="handleDbclick"
											@current-change="currentChange"
											@row-click="handleRowClick"
											@data-change="fatherDataChange"
										>
											<template #column="{ scope, item }">
												<slot
													:name="item.prop"
													:scope="scope"
													:item="item"
												>
													<span>{{ scope.row[item.prop] }}</span>
												</slot>
											</template>
										</scTable>
									</el-main>
								</el-container>
								<sy-switch-btn
									:text="config.sontext || '物品明细'"
									:opened="leftShow"
									bottom
									@show-left="handleShowLeft"
								/>
							</pane>
							<!--子级-->
							<pane
								v-if="leftShow"
								:size="paneSize"
								max-size="60"
								min-size="20"
							>
								<el-container class="sonCont">
									<el-main class="nopadding">
										<!--明细-->
										<scTable
											ref="tableSon"
											:api-obj="tableSonConfig.apiObj"
											:column="tableSonConfig.columns"
											:params="tableSonConfig.params"
											row-key="id"
											border
											remote-sort
											hide-slot
										>
											<template #column="{ scope, item }">
												<slot
													:name="'son_'+item.prop"
													:scope="scope"
													:item="item"
												>
													<span>{{ scope.row[item.prop] }}</span>
												</slot>
											</template>
										</scTable>
									</el-main>
								</el-container>
							</pane>
						</splitpanes>
					</el-main>
				</el-container>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				type="primary"
				:size="$store.state.global.uiSize"
				:disabled="isDisable"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<!--      <el-button-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        @click="visible = false"-->
			<!--      >-->
			<!--        关闭-->
			<!--      </el-button>-->
		</template>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick, onMounted,
} from "vue";
import {resetZnum} from "@/utils/validate";

export default defineComponent({
	props: {
		config: {
			type: Object, default: () => {
			}
		},
	},
	emits: ["set-form"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			refFormBar: null,
			type: "detail",
			leftShow: true,
			paneSize: 30,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//隐藏搜索
			hideForm: false,
			form: {},
			//触发元素
			triggerDom: null,
			//按钮diable
			isDisable: true,
			//当前弹窗组件
			refScDialog: null,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: "65%",
			//弹窗标题
			title: "复制",
			//表格对象
			table: null,
			//暂存选中行
			curRow: {},
			tableSonConfig: props.config.tableSonConfig || {},
			params: {
				pageNum: 1,
				pageSize: 1000,
			}
		});
		onMounted(() => {
			//消息监听
			state.hideForm = true;
			console.log("------------");
			console.log(props.config);
		});
		//显示弹窗
		const showDialog = (row) => {
			state.visible = true;
			state.loading = false;
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//点击确定按钮
		const handleConfirm = () => {
			if (state.curRow) { //有选中行
				emit("set-form", state.curRow);
				hideDialog();
			} else {
				proxy.$baseMessage("点击选中一行数据", "error");
			}
		};

		//双击行
		const handleDbclick = (row) => {
			if (row) {
				emit("set-form", row);
				hideDialog();
			}
		};

		//输入框清楚按钮点击
		const handleClear = () => {
			//刷新表格
			state.table.reload();
		};

		const fetchData = (data) => {
			state.table.reload(data);
		};

		//选中行变化
		const currentChange = (curRow) => {
			state.curRow = curRow;
			if (state.curRow) {
				state.isDisable = false;
			} else {
				state.isDisable = true;
			}
		};

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool;
		};

		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[1].size;
			}
		};

		const handleShowLeft = (bool) => {
			state.leftShow = bool;
		};

		const handleRowClick = (row) => {
			if (state.leftShow) {
				const obj = {};
				Object.assign(obj, state.params);
				obj[props.config["mainId"]] = row.id;
				proxy.$refs.tableSon.reload(obj);
			}
		};

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			let obj = {};
			if (res.data.list != null && res.data.list.length > 0) {
				//设置第一行选中
				state["table"].setCurrentRow(res.data.list[0]);
				//将接口对象赋给子级表格
				//传入父级ID
				Object.assign(obj, state.params);
				obj[props.config["mainId"]] = res.data.list[0].id;
				state.tableSonConfig.params = obj;
				state.tableSonConfig.apiObj = props.config.tableSonConfig.apiObj;
				//根据主数据加载子数据
				handleRowClick(res.data.list[0]);
			} else {
				obj[props.config["mainId"]] = -1;
				proxy.$refs.tableSon.reload(obj);
			}

		};

		//切换显示搜索栏
		const handleShowSearch = () => {
			state.hideForm = !state.hideForm;
		};

		return {
			...toRefs(state),
			fatherDataChange,
			handleRowClick,
			handleShowLeft,
			handleResized,
			handleFitShow,
			fetchData,
			currentChange,
			handleDbclick,
			handleClear,
			showDialog,
			hideDialog,
			handleConfirm,
			handleShowSearch
		};
	},
});
</script>
<style scoped>
.tableTopBar {
	display: flex;
	justify-content: flex-end;
}

.right-panel {
	margin-right: 10px;
}
</style>
