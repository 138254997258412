<template>
	<el-container class="propertyContainer">
		<el-header>
			<h3>流程属性</h3>
		</el-header>
		<el-main class="bgwhite">
			<el-form
				:label-width="80"
				:form="form"
				ref="refGraphForm"
				size="small"
			>
				<el-form-item
					prop="menu"
					label="选择菜单"
				>
					<template #label><i class="red">*</i>选择菜单</template>
					<sc-table-select
						v-model="form.menu"
						customer-value
						:api-obj="$API.menuapi.getList"
						:table-width="500"
						:props="{ label: 'title', value: 'id', keyword: 'title' }"
						:params="{haveProcess: 1}"
						style="width: 100%;"
						@change="(val) => handleSelChange(val, 'menuCode')"
					>
						<el-table-column
							prop="title"
							label="名称"
						/>
						<el-table-column
							prop="fz_parentName"
							label="父级菜单"
							width="150"
						/>
					</sc-table-select>
				</el-form-item>
				<el-form-item
					prop="name"
					label="流程名称"
				>
					<template #label><i class="red">*</i>流程名称</template>
					<el-input
						v-model="form.name"
						maxlength="50"
						clearable
						show-word-limit
					/>
				</el-form-item>
				<el-form-item
					prop="isExecutable"
					label="是否执行"
				>
					<el-checkbox
						v-model="form.isExecutable"
						true-value="true"
						false-value="false"
					/>
				</el-form-item>
				<!--        <el-form-item
				  prop="-isClosed"
				  label="是否关闭"
				>
				  <el-checkbox
					v-model="form['isClosed']"
					true-label="true"
					false-label="false"
				  />
				</el-form-item>-->
			</el-form>
		</el-main>
	</el-container>
</template>

<script>
import {
	defineComponent,
	reactive,
	toRefs,
	watch,
	getCurrentInstance
} from "vue";

export default defineComponent({
	name: "GraphProperty",
	emits: ["set-graph-data"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			form: {
				name: "",
				isExecutable: "true",
				// menu: {}
			},
			/*rules: {
				 menu: [{required: true, trigger: 'change', message: '必填'}],
				 name: [{required: true, trigger: 'change', message: '必填'}],
			},*/
		});

		watch(
			() => state.form,
			(val) => {
				emit("set-graph-data", val);
			},
			{deep: true}
		);

		const handleSelChange = (data, field) => {
			console.log(data, field);
			if (data && data.id) {
				state.form[field] = data.code;
				state.form["menuId"] = data.id;
				state.form.menu = data.title;
				if (!state.form.name) {
					state.form.name = data.title;
				}
			}
		};

		return {
			...toRefs(state),
			handleSelChange,
		};
	}
});
</script>
