const wapRouter = [
	{
		name: 'WapLayout',
		path: "/wap",
		component: () => import('@/wap/views/layout'),
		meta: {
			title: "首页",
			wap: true,
		},
		redirect: '/wap/index',
		children: [
			{
				name: 'WapIndex',
				path: "index",
				component: () => import('@/wap/views/index'),
				meta: {
					wap: true,
					title: "首页",
				}
			},
			{
				name: 'WapHome',
				path: "home",
				component: () => import('@/wap/views/home'),
				meta: {
					wap: true,
					title: "控制台",
				}
			},
			{
				name: 'WapMessage',
				path: "message",
				component: () => import('@/wap/views/message'),
				meta: {
					wap: true,
					title: "消息"
				}
			},
			{
				name: 'WapUserCenter',
				path: "userCenter",
				component: () => import('@/wap/views/userCenter'),
				meta: {
					wap: true,
					title: "我的"
				}
			},
		]
	},
	{
		name: 'WapTodoList',
		path: "/wap/todoList",
		component: () => import('@/wap/views/toList'),
		meta: {
			wap: true,
			title: "待办"
		}
	},
	{
		name: 'WapSearch',
		path: "/wap/search",
		component: () => import('@/wap/views/search'),
		meta: {
			wap: true,
			title: "搜索"
		}
	},
	{
		name: 'WapPurchaseApply',
		path: "/wap/purchase/apply",
		component: () => import('@/wap/views/erp/purchase/apply'),
		meta: {
			wap: true,
			title: "采购申请"
		},
		children: [
		]
	},
	{
		name: 'WapSaleInvoice',
		path: "/wap/sale/invoice",
		component: () => import('@/wap/views/erp/sale/invoice'),
		meta: {
			wap: true,
			title: "发货单"
		},
		children: [
		]
	},
	{
		name: 'WapSaleInvoiceEdit',
		path: "/wap/sale/invoice/edit",
		component: () => import('@/wap/views/erp/sale/invoice/edit.vue'),
		meta: {
			wap: true,
			title: "发货单编辑"
		}
	},
	{
		name: 'WapSaleRepairInvoice',
		path: "/wap/sale/repairInvoice",
		component: () => import('@/wap/views/erp/sale/repairInvoice'),
		meta: {
			wap: true,
			title: "补发货单"
		},
		children: [
		]
	},
	{
		name: 'WapSaleRepairInvoiceEdit',
		path: "/wap/sale/repairInvoice/edit",
		component: () => import('@/wap/views/erp/sale/repairInvoice/edit.vue'),
		meta: {
			wap: true,
			title: "补发货单编辑"
		}
	},
	{
		name: 'WapSaleOffer',
		path: "/wap/sale/offer",
		component: () => import('@/wap/views/erp/sale/offer'),
		meta: {
			wap: true,
			title: "销售报价"
		},
		children: [
		]
	},
	{
		name: 'WapSaleOfferEdit',
		path: "/wap/sale/offer/edit",
		component: () => import('@/wap/views/erp/sale/offer/edit'),
		meta: {
			wap: true,
			title: "销售报价编辑"
		},
		children: [
		]
	},
	{
		name: 'WapSaleContract',
		path: "/wap/sale/contract",
		component: () => import('@/wap/views/erp/sale/contract'),
		meta: {
			wap: true,
			title: "销售合同"
		},
		children: [
		]
	},
	{
		name: 'WapSaleContractEdit',
		path: "/wap/sale/contract/edit",
		component: () => import('@/wap/views/erp/sale/contract/edit'),
		meta: {
			wap: true,
			title: "销售合同编辑"
		},
		children: [
		]
	},
	{
		name: 'WapSaleOrder',
		path: "/wap/sale/order",
		component: () => import('@/wap/views/erp/sale/order'),
		meta: {
			wap: true,
			title: "销售订单"
		},
		children: [
		]
	},
	{
		name: 'WapSaleOrderEdit',
		path: "/wap/sale/order/edit",
		component: () => import('@/wap/views/erp/sale/order/edit'),
		meta: {
			wap: true,
			title: "销售订单编辑"
		},
		children: [
		]
	},
	{
		name: 'WapPurchaseApplyEdit',
		path: "/wap/purchase/apply/edit",
		component: () => import('@/wap/views/erp/purchase/apply/edit'),
		meta: {
			wap: true,
			title: "采购申请编辑"
		}
	},
	{
		name: 'WapPurchaseRefund',
		path: "/wap/purchase/refund",
		component: () => import('@/wap/views/erp/purchase/refund'),
		meta: {
			wap: true,
			title: "采购退货"
		},
		children: [
		]
	},
	{
		name: 'WapPurchaseRefundEdit',
		path: "/wap/purchase/refund/edit",
		component: () => import('@/wap/views/erp/purchase/refund/edit'),
		meta: {
			wap: true,
			title: "采购退货编辑"
		}
	},
	{
		name: 'WapPurchaseOrder',
		path: "/wap/purchase/order",
		component: () => import('@/wap/views/erp/purchase/order'),
		meta: {
			wap: true,
			title: "采购订单"
		}
	},
	{
		name: 'WapBuyApply',
		path: "/wap/buy/apply",
		component: () => import('@/wap/views/fms/buy/apply'),
		meta: {
			wap: true,
			title: "付款申请单"
		}
	},
	{
		name: 'WapUserInfo',
		path: "/wap/userCenter/userInfo",
		component: () => import('@/wap/views/userCenter/components/userInfo'),
		meta: {
			wap: true,
			title: "个人资料"
		}
	},
	{
		name: 'WapScanOut',
		path: "/wap/stock/scanOut",
		component: () => import('@/wap/views/erp/stock/out'),
		meta: {
			wap: true,
			title: "领料单"
		}
	},
	{
		name: 'WapScan',
		path: "/wap/stock/out/scan",
		component: () => import('@/wap/views/erp/stock/out/scan'),
		meta: {
			title: "扫码出库"
		}
	},
	{
		name: 'WapStockPurchaseReceipt',
		path: "/wap/stock/purchase/receipt",
		component: () => import('@/wap/views/erp/stock/purchase/receipt'),
		meta: {
			wap: true,
			title: "收货单"
		},
		children: [
		]
	},
	{
		name: 'WapStockPurchaseReceiptEdit',
		path: "/wap/stock/purchase/receipt/edit",
		component: () => import('@/wap/views/erp/stock/purchase/receipt/edit'),
		meta: {
			wap: true,
			title: "收货单编辑"
		}
	},
	{
		name: 'WapUserInfo',
		path: "/wap/user/info",
		component: () => import('@/wap/views/userCenter/components/userInfo'),
		meta: {
			title: "个人信息"
		}
	},
	{
		name: 'WapUserPassword',
		path: "/wap/user/updatePassword",
		component: () => import('@/wap/views/userCenter/components/updatePassword'),
		meta: {
			title: "修改密码"
		}
	},
]

export default wapRouter;
