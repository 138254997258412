<template>
	<el-container class="propertyContainer">
		<el-header>
			<h3>{{ title }}</h3>
		</el-header>
		<el-main class="bgwhite">
			<el-form
				ref="refForm"
				:label-width="60"
				size="small"
			>
				<el-form-item
					prop="name"
					label="节点名称"
				>
					<template #label><i class="red">*</i>节点名称</template>
					<el-input
						v-model="form.name"
						maxlength="50"
						clearable
					/>
				</el-form-item>
				<el-form-item
					prop="nodeType"
					label="审核类型"
				>
					<template #label><i class="red">*</i>审核类型</template>
					<el-radio-group
						v-model="form.nodeType"
						@change="handleNodeTypeChange"
					>
						<el-radio label="单人审核" :value="1" />
						<el-radio label="多人审核" :value="2" />
					</el-radio-group>
				</el-form-item>
				<el-form-item
					v-if="form.nodeType === 1"
					prop="assignee"
					label="审核用户"
				>
					<template #label><i class="red">*</i>审核用户</template>
					<sc-table-select
						v-model="form.assign"
						:api-obj="$API.user.getUserList"
						customer-value
						:table-width="500"
						:props="{ label: 'name', value: 'id', keyword: 'name' }"
						style="width: 100%"
						@change="(val) => handleSelChange(val, 'assignee')"
					>
						<el-table-column
							prop="name"
							label="名称"
							width="150"
						/>
						<el-table-column
							prop="fz_departmentName"
							label="部门"
							width="150"
						/>
						<el-table-column
							prop="code"
							label="工号"
							width="150"
						/>
					</sc-table-select>
				</el-form-item>
				<el-form-item
					v-if="form.nodeType === 2"
					prop="candidateUserNames"
					label="审核用户"
				>
					<template #label><i class="red">*</i>审核用户</template>
					<el-input
						ref="refPop"
						v-model="form.candidateUserNames"
						clearable
						readonly
						@click="popUser('refPop')"
					>
						<template #suffix>
							<el-icon @click="popUser('refPop')">
								<el-icon-search/>
							</el-icon>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item
					prop="exclusive"
					label="是否排除"
				>
					<el-checkbox
						v-model="form.exclusive"
						true-value="true"
						false-value="false"
					/>
				</el-form-item>
			</el-form>
		</el-main>
		<UserList
			ref="refUserList"
			:placement="'bottomEnd'"
			multiple
			@set-data="setData"
		/>
	</el-container>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, toRefs, watch} from "vue";
import UserList from "@/views/publicQuery/userList";

export default defineComponent({
	name: "WfProperty",
	components: {UserList},
	emits: ["set-data"],
	setup(props, {emit}) {

		const state = reactive({
			refUserList: null,
			refForm: null,
			visible: false,
			form: {},
			title: "用户任务属性",
		});

		const {proxy} = getCurrentInstance();

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data, field) => {
			if (data.length > -1) { //多选
				let ids = "";
				if (data.length > 0) {
					//取得选中的id串
					ids = data.map((item) => item.id).join();
				} else {
					ids = "";
				}
				state.form[field] = ids;
			} else { //单选
				if (data && data.id) {
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					if (field === "assignee") {
						// setData([data])
						state.form[field] = data.id;
						state.form.assign = data.name;
					}
				}
			}
		};

		//重置表单
		const resetForm = () => {
			state.form = {};

		};

		//监听表单
		watch(state, (val) => {
			if (JSON.stringify(val.form) !== "{}") {
				emit("set-data", val.form);
			}
		});

		//选择用户
		const popUser = (ref) => {
			state["refUserList"].triggerDom = proxy.$refs[ref];
			state["refUserList"].showDialog();
		};

		const setData = (data,type) => {
			if(data){
				//多选用户
				if(type === 'user' || type === 'role'){
					if(data.length > 0){
						// state.form["candidateUserNames"] = data.map((item) => {
						// 	return item.name;
						// }).join();
						// state.form["candidateUsers"] = data.map((item) => {
						// 	return item.id;
						// }).join()
            state.form["candidateUserNames"] = data.map((item) => item.name)
            state.form["candidateUsers"] = data.map((item) => item.id)
					}
				}
				//选择部门
				if(type === 'department'){
					state.form["candidateUserNames"] = data.name
					state.form["departmentId"] = data.id
				}
			}
		};

		//切换节点类型,清空已选用户
		const handleNodeTypeChange = () => {
			state.form.assign = "";
			state.form.assignee = null;
			state.form.candidateUserNames = [];
			state.form.candidateUsers = [];
		};

		return {
			...toRefs(state),
			handleNodeTypeChange,
			setData,
			popUser,
			handleSelChange,
			resetForm,
		};
	}
});
</script>
