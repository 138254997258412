import {h, PolygonNode, PolygonNodeModel} from "@logicflow/core"
import {v4 as uuidv4} from "uuid";
class PolygonModel extends PolygonNodeModel {
	initNodeData(data) {
		super.initNodeData(data);
		this.points = [
			[25, 0],
			[50, 25],
			[25, 50],
			[0, 25],
		]
	}
	createId() {
		return 'ParallelGateway-' + uuidv4()
	}
}
class PolygonView extends PolygonNode {
	getShape() {
		const { x, y, points } = this.props.model;
		const pointStr = points.map((point) => {
			return `${point[0] + x - 25}, ${point[1] + y - 25}`
		}).join(" ");
		const style = this.props.model.getNodeStyle()
		return h('g',{},[
			h("polygon", {
				...style,
				points: pointStr,
			}),
			h("rect", {
				...style,
				x: x - 8,
				y: y - 1,
				width: 16,
				height: 2,
			}),
			h("rect", {
				...style,
				x: x - 1,
				y: y - 8,
				width: 2,
				height: 16,
			})
		])
	}
}

export default {
	type: "bpmn:parallelGateway",
	view: PolygonView,
	model: PolygonModel
}
