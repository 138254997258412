<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
	height="600px"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        :column="3"
        border
        class="column3"
      >
        <el-descriptions-item
          label="客户名称"
          :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>

        <el-descriptions-item
          label="客户编码"
          :label-align="labelAlign"
        >
          {{ data.number }}
        </el-descriptions-item>

        <el-descriptions-item
          label="社会信用代码"
          :label-align="labelAlign"
        >
          {{ data.code }}
        </el-descriptions-item>

        <el-descriptions-item
          label="销售负责人"
          :label-align="labelAlign"
        >
          {{ data.fz_saleDirectorName }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="所属集团"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.fz_groupName }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="公司规模"
          :label-align="labelAlign"
        >
          {{ data.scale }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="所在国家"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.country }}-->
<!--        </el-descriptions-item>-->


        <el-descriptions-item
          label="所在地区"
          :label-align="labelAlign"
        >
          {{ data.area }}
        </el-descriptions-item>

        <el-descriptions-item
          label="详细地址"
          :label-align="labelAlign"
        >
          {{ data.address }}
        </el-descriptions-item>

<!--        <el-descriptions-item
          label="联系手机"
          :label-align="labelAlign"
        >
          {{ data.phone }}
        </el-descriptions-item>-->

        <el-descriptions-item
          label="联系电话"
          :label-align="labelAlign"
        >
          {{ data.tel }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="传真"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.fax }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="邮政编码"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.zipCode }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="电子邮箱"
          :label-align="labelAlign"
        >
          {{ data.email }}
        </el-descriptions-item>

        <el-descriptions-item
          label="网址"
          :label-align="labelAlign"
        >
          {{ data.website }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="QQ"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.qq }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="微信"
          :label-align="labelAlign"
        >
          {{ data.weChat }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="旺旺"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.wangwang }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="法定代表人"
          :label-align="labelAlign"
        >
          {{ data.corporation }}
        </el-descriptions-item>

        <el-descriptions-item
          label="委托代理人"
          :label-align="labelAlign"
        >
          {{ data.entrust }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="分享人"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.fz_shareUserName }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="客户来源"
          :label-align="labelAlign"
        >
          {{ data.customerSource }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="客户类型"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.customerType }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="行业属性"
          :label-align="labelAlign"
        >
          {{ data.industry }}
        </el-descriptions-item>

        <el-descriptions-item
          label="企业性质"
          :label-align="labelAlign"
        >
          {{ data.enterpriseNature }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="销售方式"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.saleMethod }}-->
<!--        </el-descriptions-item>-->

		  <el-descriptions-item
			  v-for="item in dataList"
			  :key="item.value"
			  :label="item.name"
			  label-align="right">
			  {{Array.isArray(data[item.value])?data[item.value].join(','):data[item.value]}}
		  </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="预设售价"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{-->
<!--            data.presetPrice === -1 ? '' : data.presetPrice === 0 ? '参考销售价' : data.presetPrice === 1 ? '一级销售价' : data.presetPrice === 2 ?-->
<!--              '二级销售价'-->
<!--              : data.presetPrice === 3 ? '三级销售价' : data.presetPrice === 4 ? '四级销售价' : data.presetPrice === 5 ?-->
<!--                '五级销售价'-->
<!--                : data.presetPrice === 6 ? '六级销售价' : data.presetPrice === 7 ? '七级销售价' : data.presetPrice === 8 ?-->
<!--                  '八级级销售价'-->
<!--                  : '九级销售价'-->
<!--          }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="提成类型"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{-->
<!--             data.commissionType === -1 ? '' : data.commissionType === 1 ? '按单提成' : data.commissionType === 2 ? '按成交金额提成' : '按利润提成'-->
<!--          }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="意向等级"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{-->
<!--            data.intentionLevel === -1 ? '' : data.intentionLevel === 1 ? '一级' : data.intentionLevel === 2 ? '二级' : data.intentionLevel === 3 ?-->
<!--              '三级' :-->
<!--              data.intentionLevel === 4 ? '四级' : data.intentionLevel === 5 ? '五级' : data.intentionLevel === 6 ?-->
<!--                '六级' :-->
<!--                data.intentionLevel === 7 ? '七级' : data.intentionLevel === 8 ? '八级' : '九级'-->
<!--          }}-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
<!--        <el-descriptions-item-->
<!--          label="企业简介"-->
<!--          label-class-name="noTopBorder"-->
<!--          class-name="noTopBorder"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.profile }}-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="财务信息"
      shadow="never"
      class="mycard mt_10"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="开户行"
          :label-align="labelAlign"
        >
          {{ data.bank }}
        </el-descriptions-item>

        <el-descriptions-item
          label="账户名称"
          :label-align="labelAlign"
        >
          {{ data.accountName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="银行账号"
          :label-align="labelAlign"
        >
          {{ data.accountNumber }}
        </el-descriptions-item>

        <el-descriptions-item
          label="联行号"
          :label-align="labelAlign"
        >
          {{ data.bankNo }}
        </el-descriptions-item>

        <el-descriptions-item
          label="许可证号"
          :label-align="labelAlign"
        >
          {{ data.licenseKey }}
        </el-descriptions-item>

        <el-descriptions-item
          label="税号"
          :label-align="labelAlign"
        >
          {{ data.taxCode }}
        </el-descriptions-item>


        <el-descriptions-item
          label="信用额度"
          :label-align="labelAlign"
        >
          {{data.creditLimit === -1 ? '' : data.creditLimit === 1 ? '高' : data.creditLimit === 2 ? '中' : '低' }}
        </el-descriptions-item>

      </el-descriptions>
    </el-card>
    <el-card
      header="联系人"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableSon"
        :data="data.contactList"
        :column="tableSonConfig.columns"
        row-key="id"
        :height="'auto'"
        border
        remote-sort
        hide-do
        hide-pagination
				@sort-change="contactSortChange"
      >
        <template #gender="{ scope }">
          <span v-if="scope.row['gender'] === 1">男</span>
          <span v-if="scope.row['gender'] === 0">女</span>
        </template>
        <template #importance="{ scope }">
          <span v-if="scope.row['importance'] === 1">关键联系人</span>
          <span v-if="scope.row['importance'] === 2">重要联系人</span>
          <span v-if="scope.row['importance'] === 3">普通联系人</span>
          <span v-if="scope.row['importance'] === 4">普通联系人</span>
          <span v-if="scope.row['importance'] === 5">特别联系人</span>
        </template>
        <template #defaul="{ scope }">
          <span v-if="scope.row['defaul'] === 1">是</span>
          <span v-if="scope.row['defaul'] === 0">否</span>
        </template>
      </scTable>
    </el-card>
    <el-card
      header="收货地址"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableAddress"
        :data="data.addressList"
        :column="tableAddressConfig.columns"
        row-key="id"
        :height="'auto'"
        border
        remote-sort
        hide-do
        hide-pagination
				@sort-change="addressSortChange"
      />
    </el-card>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from 'vue'

export default defineComponent({
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			dataList:[],
			//弹窗宽度
			width: '70%',
      		type:"detail",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '客户详情',
			//详情数据对象
			data: {
				contactList: [],
				addressList: []
			},
			fileList: [],
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '姓名',
						prop: 'name',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '性别',
						prop: 'gender',
						width: 90,
						sortable: 'custom',
					},
					// {
					// 	label: '重要程度',
					// 	prop: 'importance',
					// 	sortable: 'custom',
					// 	width: 120,
					// },
					{
						label: '职位',
						prop: 'position',
						width: 120,
						sortable: 'custom',
					},
					/*{
						label: '联系手机',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},*/
					{
						label: '联系电话',
						prop: 'tel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '电子邮箱',
						prop: 'email',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: 'QQ',
					// 	prop: 'qq',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '微信',
						prop: 'weChat',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '传真',
					// 	prop: 'fax',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '生日',
						prop: 'birthday',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '默认',
						prop: 'defaul',
						sortable: 'custom',
						width: 90,
					},
					// {
					// 	label: '查看人员',
					// 	sortable: 'custom',
					// 	prop: 'fz_shareUserName',
					// 	width: 300,
					// },
				],
			},
			tableAddressConfig: {
				columns: [
					// {
					// 	label: '所在国家',
					// 	prop: 'country',
					// 	width: 150,
					// 	sortable: 'custom',
					// },
					{
						label: '收货人',
						prop: 'contacts',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '联系电话',
						prop: 'tel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '所在地区',
						prop: 'area',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '详细地址',
						prop: 'address',
						width: 180,
						sortable: 'custom',
					},

				]
			}
		})
		onMounted(() => {
			dataListMap()
		})
		// 获取自定义字段
		const dataListMap =async () =>{
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:1,isEnabled:1,menuCode:"cfg_data_customer",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.dataList = res.data.list
			}
		}
		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true
			state.fileList = []
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.customer.detailCustomer.get(data.id)
				if (res.code === 200 && res.data) {
					state.data = []
					Object.assign(state.data, res.data)
					getFileList(res.data.files)
					await getContactList(res.data)
					await getAddressList(res.data)
				}
			}
			state.loading = false
		}
		//重置表单

		//获取联系人
		const getContactList = async (data) => {
			if (data.id) {
				const res = await proxy.$API.customer.getContactList.get({customerId: data.id})
				if (res.code === 200 && res.data) {
					state.data.contactList = res.data
				}
			}
		}

		//获取收货地址
		const getAddressList = async (data) => {
			if (data.id) {
				const res = await proxy.$API.customer.getAddressList.get({customerId: data.id})
				if (res.code === 200 && res.data) {
					state.data.addressList = res.data
				}
			}
		}

		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)

		}


		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		const contactSortChange = async (obj) => {
			if (state.data.id) {
				let param = {}
				if (obj.column && obj.prop && obj.order) {
					param['prop'] = obj.prop
					if (obj.order == 'ascending') {
						param['order'] = 'asc'
					} else if(obj.order == 'descending'){
						param['order'] = "desc";
					} else {
						param['order'] = null
					}
				} else {
					param['prop'] = null
					param['order'] = null
				}
				param['customerId'] = state.data.id
				const res = await proxy.$API.customer.getAddressList.get(param)
				if (res.code === 200 && res.data) {
					state.data.addressList = res.data
				}
			}
		}
		const addressSortChange = async (obj) => {
			if (state.data.id) {
				let param = {}
				if (obj.column && obj.prop && obj.order) {
					param['prop'] = obj.prop
					if (obj.order == 'ascending') {
						param['order'] = 'asc'
					} else if(obj.order == 'descending'){
						param['order'] = "desc";
					} else {
						param['order'] = null
					}
				} else {
					param['prop'] = null
					param['order'] = null
				}
				param['customerId'] = state.data.id
				const res = await proxy.$API.customer.getContactList.get(param)
				if (res.code === 200 && res.data) {
					state.data.contactList = res.data
				}
			}
		}
		return {
			...toRefs(state),
			showDialog,
			hideDialog,
			previewFile,
			contactSortChange,
			addressSortChange
		}
	},
})
</script>
