/**
 * @description 自动import导入所有 api 模块
 */

const files = require.context('./model', true, /\.js$/)
const modules = {}
files.keys().forEach((key) => {
	const arr = key.split('/')
	modules[arr[arr.length-1].replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default modules
