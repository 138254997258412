<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:width="width"
		:before-close="beforeClose"
		:trigger-dom="triggerDom"
		:close-on-click-modal="false"
		@reset="reset"
    	:type="type"
	>
		<div class="syForm">
			<el-form
				ref="formRef"
				:model="form"
				:label-width="90"
				size="small"
				:rules="rules"
				class="diaForm"
			>
				<el-form-item
					label="父级部门"
					prop="fz_parentName"
				>
					<div class="disflex fullWidth sc-table-select--append">
						<div class="flexgs1">
							<sc-table-select
								v-model="form.fz_parentName"
								customerValue
								fullWidth
								:api-obj="$API.department.getListByKeyword"
								:params="{ flag: 1, parentId:0 }"
								:height="426"
                hide-pagination
								:props="{ label: 'name', value: 'id', keyword: 'name' }"
								@change="(val) => handleSelChange(val, ['parentId','fz_parentName'])"
								@clear="inputClear('parent')"
							>
								<el-table-column
									prop="name"
									label="部门名称"
									width="300"
								/>
								<el-table-column
									prop="code"
									label="部门代码"
									width="150"
								/>
								<template #append>
									<el-icon @click="popSelect('refParentIput',['parentId','fz_parentName'], 1, 'refSelectDepartment')">
										<el-icon-search />
									</el-icon>
								</template>
							</sc-table-select>
						</div>
					</div>
				</el-form-item>
				<el-form-item
					prop="name"
					label="部门名称"
				>
					<el-input
						v-model="form.name"
						clearable
						maxlength="255"
						placeholder="请输入"
					/>
				</el-form-item>
				<el-form-item
					prop="type"
					label="部门类型"
				>
					<el-select
						v-model="form.type"
						clearable
						placeholder="请选择"
					>
						<el-option
							label="公司"
							value="1"
						/>
						<el-option
							label="部门"
							value="2"
						/>
					</el-select>
				</el-form-item>
				<el-form-item
					prop="code"
					label="部门代码"
				>
					<el-input
						v-model="form.code"
						clearable
						placeholder="请输入"
						maxlength="255"
						@blur="handleCodeBlur"
					/>
				</el-form-item>
				<el-form-item
					label="部门经理"
					prop="fz_managerName"
				>

					<div class="disflex fullWidth sc-table-select--append">
						<div class="flexgs1">
							<sc-table-select
								v-model="form.fz_managerName"
								customerValue
								fullWidth
								:api-obj="$API.user.getListByKeyword"
								:params="{
                        flag: 1,
                      }"
								:height="426"
								:props="{ label: 'name', value: 'id', keyword: 'name' }"
								@change="(val) => handleSelChange(val, ['managerId','fz_managerName'])"
								@clear="inputClear('manager')"
							>
								<el-table-column
									prop="name"
									label="姓名"
									width="150"/>
								<el-table-column
									prop="fz_departmentName"
									label="部门"
									width="150"/>
								<el-table-column
									prop="code"
									label="工号"
									width="150"/>

								<template #append>
									<el-icon @click="popSelect('refManagerIput',['managerId','fz_managerName'], 1, 'refSelectUser')">
										<el-icon-search />
									</el-icon>
								</template>
							</sc-table-select>
						</div>
					</div>
				</el-form-item>
				<el-form-item
					prop="headcount"
					label="编制人数"
				>
					<el-input
						v-model="form.headcount"
						clearable
						placeholder="请输入"
						maxlength="11"
						@input="(val) => handleZnumIpt(val, 'headcount')"
					/>
				</el-form-item>
				<el-form-item
					prop="post"
					label="部门职能"
				>
					<el-input
						v-model="form.post"
						type="textarea"
						maxlength="1020"
						rows="5"
						show-word-limit
						placeholder="请输入"
					/>
				</el-form-item>
			</el-form>
		</div>
		<template #footer>
			<el-popconfirm
				:title="$TIPS.BOM_SAVE"
				:visible="visibles"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@cancel="visibles = false"
				@confirm="handleConfirm"
			>
				<template #reference>
					<el-button
						:loading="isLoading"
						type="primary"
						:size="$store.state.global.uiSize"
						@click = "valiForm"
					>
						保存数据
					</el-button>
				</template>
			</el-popconfirm>
			<el-popconfirm
				:title="$TIPS.CONFIRM_CLOSE"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@confirm="reset(false)"
			>
				<template #reference>
					<el-button :size="$store.state.global.uiSize">
						重置
					</el-button>
				</template>
			</el-popconfirm>
<!--			<el-popconfirm-->
<!--				:title="$TIPS.CONFIRM_CLOSE"-->
<!--				confirm-button-text="确定"-->
<!--				cancel-button-text="取消"-->
<!--				@confirm="handleConfirm"-->
<!--			>-->
<!--				<template #reference>-->
<!--					<el-button :size="$store.state.global.uiSize">-->
<!--						关闭-->
<!--					</el-button>-->
<!--				</template>-->
<!--			</el-popconfirm>-->
		</template>
		<!--选择部门-->
		<SelectDepartment
			ref="refSelectDepartment"
			@set-data="setSelectData"
		/>
		<!--选择用户-->
		<SelectUser
			ref="refSelectUser"
			@set-data="setSelectData"
		/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import {resetZnum} from "@/utils/validate";
export default defineComponent({
	name: "syDepartmaentDirEdit",
	emits: ['fetch-data'],
	setup(props, {emit}) {
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const {proxy} = getCurrentInstance()
		const state = reactive({
      isLoading: false,
      type:'detail',
			loading: false,
			triggerDom: {},
			visibles:false,
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			codeMsg: '',
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '部门新增',
			//表单当前数据
			form: {
				fz_parentName:"",
			},
			//暂存原始数据
			orgForm: {},
			cacheFields: {},
			rules: {
				name: [{required: true, trigger: 'blur', message: '必填'}],
				// code: [
				// 	{required: true, trigger: 'blur', message: '必填'},
				// 	{validator: validateCode},
				// ],
				type: [{required: true, trigger: 'change', message: '必填'}]
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.department.getList,
				params:{parentId:0},
				//弹窗标题
				title:'复制部门信息',
				width: '50%',
				//表格显示列
				columns: [
					{
						label: '部门名称',
						prop: 'name',
						width: 150,
						sortable: true,
						fixed: 'left',
						showOverflowTooltip: true,
					},
					{
						label: '组织类型',
						prop: 'type',
						width: 110,
						sortable: true,
						filters: [
							{text: '公司', value: '1'},
							{text: '部门', value: '2'}
						],
					},
					{
						label: '部门代码',
						prop: 'code',
						width: 120,
						sortable: true,
						showOverflowTooltip: true,
					},
					{
						label: '部门经理',
						prop: 'fz_managerName',
						width: 100,
						sortable: true,
						showOverflowTooltip: true,
					},
					{
						label: '部门职能',
						prop: 'post',
						sortable: true,
						showOverflowTooltip: true,
					},
					{
						label: '编制人数',
						prop: 'headcount',
						width: 120,
						sortable: true,
						showOverflowTooltip: true,
					},
					// {
					// 	label: '状态',
					// 	prop: 'flag',
					// 	width: 80,
					// 	showOverflowTooltip: true,
					// },
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 120,
						sortable: true,
						showOverflowTooltip: true,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 170,
						showOverflowTooltip: true,
					},
				],
				searchConfig: {
					//表单标题宽度
					labelWidth: 60,
					//表单项配置
					itemList: [
						//文本框
						{
							label: '部门名称',
							prop: 'name',
							type: 'input',
							max: 20,
							default: true,
							span: 8,
						},
						//下拉框
						{
							label: '组织类型',
							prop: 'type',
							type: 'select',
							default: true,
							span: 8,
							data: [
								{value: '1', label: '公司'},
								{value: '2', label: '部门'},
							]
						},
						{
							label: '部门代码',
							prop: 'code',
							type: 'input',
							span: 8,
							max: 20,
							clearable: true,
							default: true,
						},
						{
							label: '部门经理',
							prop: 'manager',
							type: 'tableSelect',
							span: 8,
							config: {
								apiObj: proxy.$API.user.getUserList,
								props: {
									label: 'name',
									value: 'id',
									keyword: "keyword"
								},
								columns: [
									{
										label: '姓名',
										prop: 'name',
									},
									{
										label: '工号',
										prop: 'code',
									},
									{
										label: '上级所属部门',
										prop: 'fz_departmentName',
									},
								],
								//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
								assignFields: [
									{
										//对应表格列表数据字段
										value: 'id',
										//赋值给到的字段名称
										field: 'managerId',
									},
								],
							}
						},
						{
							label: '部门职能',
							prop: 'post',
							type: 'input',
							max: 30,
							span: 8,
						},
						{
							label: '编制人数',
							prop: 'headcount',
							type: 'number',
							max: 10,
							span: 8,
						},
						{
							label: '操作用户',
							prop: 'fz_operateName',
							type: 'popUser',
							short: 12,
							span: 8,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							type: 'dateTimeRange',
							span: 8,
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			}
		})
		const showDialog = (row, type) => {
			state.visible = true
			nextTick(async () => {
        if (JSON.stringify(row) === '{}') { //新增
          state.title = "部门新增"
          state.form.type = '2'
          state.orgForm = Object.assign({}, state.form)
        } else { //编辑
          if(!row.type){
            row.type = '2'
          }
          if (row.id) {
            state.title = "部门编辑"
          } else {
            // if (JSON.stringify(row) == '{}') { //新增
            if (!row.parentId) { //新增顶级模块
              state.title = "部门新增"
            } else {
              state.title = "新增子级"
            }
          }
          state.form = Object.assign({}, row)
          state.orgForm = Object.assign({}, row)
          await getParentNode(row.id)
        }
      })
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.department.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['formRef'].validateField('code')
				}
			}
		}

		//获取父级节点信息
		const getParentNode = async (id) => {
			const res = await proxy.$API.department.detail.get(id)
			if(res.code === 200){
				state.form = Object.assign({}, res.data)
				state.form.parentId = res.data.id
				// state.form.fz_parentName = res.data.name
				state.orgForm.parentId = res.data.id
				state.orgForm.fz_parentName = res.data.name
			}
		}

		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}

		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		const handleConfirm = () => {
			save()
			state.visibles = false
		}
		const valiForm = () => {
			nextTick(()=>{
				state.formRef.validate((valid)=>{
					if(valid){
						state.visibles = true
					}else {
						state.visibles = false
					}
				})
			})
		}
		const hideDialog = () => {
			state.visible = false
      reset(true)
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate(async(valid) => {
				if(valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res
						if(state.form.id){ //修改
							res = await proxy.$API.department.update.put(state.form)
						}else{ //新增
							res = await proxy.$API.department.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					// })
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state.visibles = false
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//只能输入正整数
		const handleZnumIpt = (val, field) => {
			if(val){
				state.form[field] = resetZnum(val)
			}
		}
		//复制信息点击行后返回
		const setFormData = async (data) => {
			state.form = Object.assign({}, data)
			//删除idq
			delete state.form.id
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}
		const handleSelChange =async (data, fields) => {
			if (data.length > -1) { //多选
				let ids = ''
				if (data.length > 0) {
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				} else {
					ids = ''
				}
				state.form[fields[0]] = ids
			} else { //单选
				if (data && data.id) {
					//将选择的数据id赋值给指定的form中的属性 例：groupId
          if(fields[0] === 'parentId' && state.form.id && data.id === state.form.id){
            state.form[fields[1]] = ''
            proxy.$baseMessage('父级部门不能选择当前部门!','error')
            return false
          }
          state.form[fields[0]] = data.id
          state.form[fields[1]] = data.name
				}
			}
		}
		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}
		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			if(field !== 'shareUser'){
				state.form[field + 'Id'] = ''
			}else{
				state.form[field] = ''
			}
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}

		return {
			...toRefs(state),
			setSelectData,
			inputClear,
			inputBlur,
			popSelect,
			copyData,
			setFormData,
			handleZnumIpt,
			save,
			reset,
			beforeClose,
			showDialog,
			handleConfirm,
			valiForm,
			hideDialog,
			handleSelChange,
			handleCodeBlur,
		}
	},
})
</script>

<style scoped>

</style>
