<!--购物车-->
<template>
  <el-drawer
    v-model="drawer"
    direction="btt"
    custom-class="bot-drawer"
    :show-close="false"
  >
    <template #header>
      <div class="disflex">
        <div class="flexgs1">
          <h4>已选物品</h4>
        </div>
        <div class="flexgs">
          <el-button
            size="small"
            :disabled="data.length === 0"
            @click="clearAll"
          >
            <i class="iconfont icon-shanchu" />清空
          </el-button>
        </div>
      </div>
    </template>
    <div class="drawerBox">
      <el-container
        direction="vertical"
      >
        <el-container
          class="flexgs1"
          direction="vertical"
        >
          <div
            v-if="data.length === 0"
            class="no-data"
          >
            <i class="iconfont icon-xiaolian1" />
            还没有选择物品
          </div>
          <el-scrollbar>
            <ul class="shop-car-list">
              <li
                v-for="item in data"
                :key="item.id"
              >
                <div class="list-img-box pdr5">
                  <el-image
                    :src="Logo"
                    fit="contain"
                    class="list-img shopc"
                  />
                </div>
                <div class="left">
                  <h4>{{ item.name }}</h4>
                  <p><b>￥{{ item.price }}</b></p>
                </div>
                <div class="right">
                  <el-button
                    class="shopc-btn"
                    circle
                    @click="addNum(item)"
                  >
                    +
                  </el-button>
                  <el-input
                    v-model="item.quantity"
                    class="num"
                  />
                  <el-button
                    class="shopc-btn"
                    circle
                    @click="minusNum(item)"
                  >
                    -
                  </el-button>
                  <span class="ml4">{{ item.unit }}</span>
                  <el-button
                    class="shopc-btn ml10"
                    circle
                    @click="handleDelete(item)"
                  >
                    <i class="iconfont icon-shanchu" />
                  </el-button>
                </div>
              </li>
            </ul>
          </el-scrollbar>
        </el-container>
      </el-container>
    </div>
  </el-drawer>
</template>
<script>
import {
	defineComponent,
	getCurrentInstance,
	reactive,
	toRefs
} from "vue";
import Logo from '@/assets/img/hbxt.png'
export default defineComponent({
	name: "ShopCar",
	emits: ['fetch-data','set-sel-data'],
	setup(props,{ emit }) {
		const { proxy } = getCurrentInstance()
		const state = reactive({
			data: [],
			drawer: false,
		})

		//显示
		const showDrawer = (data) => {
			state.drawer = true
			state.data = JSON.parse(JSON.stringify(data))
		}

		//隐藏
		const hideDrawer = () => {
			state.drawer = false
		}

		//提交审核
		const submit = () => {
			proxy.$baseConfirm("确认提交?", null, async () => {
				proxy.$baseMessage("操作成功！", 'success')
				hideDrawer()
				emit('fetch-data')
			})
		}

		//增加
		const addNum = (item) => {
			let quantity = item.quantity - 0
			quantity ++
			item.quantity = quantity
			emit('set-sel-data', state.data)
		}

		//减少
		const minusNum = (item) => {
			let quantity = item.quantity - 0
			if(quantity > 1){
				quantity --
			}
			item.quantity = quantity
			emit('set-sel-data', state.data)
		}

		//删除项
		const handleDelete = (item) => {
			const index = state.data.indexOf(item)
			if(item != -1){
				state.data.splice(index,1)
			}
			emit('set-sel-data', state.data)
		}

		const clearAll = () => {
			state.data = []
			emit('set-sel-data', state.data)
		}

		return {
			...toRefs(state),
			clearAll,
			handleDelete,
			addNum,
			minusNum,
			submit,
			showDrawer,
			hideDrawer,
			Logo
		}
	}
})
</script>

<style scoped>

</style>
