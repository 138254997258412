import config from "@/config"
import http from "@/utils/request"

export default {
	selectDetail: {
		url: `${config.API_URL}/erp/sale/enter/goods/selectDetail`,
		name: "根据退货入库id查询明细",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
