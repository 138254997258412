<template>
  <el-select
    v-model="defaultValue"
    value-key="id"
    clearable
    filterable
    @visible-change="visibleChange"
    @change="selectChange"
		@clear="clear"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item"
    />
  </el-select>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	getCurrentInstance,
	watch,
} from 'vue'
export default defineComponent ({
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: null,
	},
	emits: ['update:modelValue', 'set-value','clear'],
	setup(props, {emit}){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			defaultValue: props.modelValue,
			options: [],
		})

		const fetchData = async () => {
			const res = await proxy.$API.depot.getAllList.get()
			if(res.code === 200){
				state.options = res.data
			}
		}

		const visibleChange = (val) => {}

		onMounted(() => {
			fetchData()
		})

		watch(
			() => props.modelValue,
			(val) => {
				state.defaultValue = val
			},
			{deep: true}
		)

		watch(
			() => state.defaultValue,
			(val) => {
				emit('update:modelValue',val)
			},
			{deep: true}
		)

		const selectChange = (val) => {
			emit('set-value', val)
		}

		const clear = () => {
			emit('clear')
		}
		return{
			...toRefs(state),
			selectChange,
			visibleChange,
			clear,
		}
	},
})
</script>
