import config from "@/config"
import http from "@/utils/request"

export default {
	profitStatistics: {
		url: `${config.API_URL}/erp/sale/profit/profitStatistics`,
		name: "销售利润统计 - 销售按单利润表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	profitStatisticsSum: {
		url: `${config.API_URL}/erp/sale/profit/profitStatisticsSum`,
		name: "销售利润统计 - 销售汇总",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
