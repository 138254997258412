
<template>
  <!-- 采购业务=>收货单=> -->
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="订单编号"
          label-align="right"
        >
          {{ data.receiptNo }}
        </el-descriptions-item>
        <el-descriptions-item
          label="采购订单"
          label-align="right"
        >
          <el-link
            type="primary"
            @click="handleDetail(data, 'refPurOrderDetail', 'purOrderId')"
          >
            {{ data.fz_purOrderNo }}
          </el-link>
        </el-descriptions-item>
        <el-descriptions-item
            label="入库状态"
            label-align="right"
        >
          <el-tag
              v-if="data.enterStatus === 0"
              style="color: #666;"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            未入库
          </el-tag>
          <el-tag
              v-if="data.enterStatus === 1"
              type="warning"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            部分入库
          </el-tag>
          <el-tag
              v-if="data.enterStatus === 2"
              type="success"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            全部入库
          </el-tag>
          <el-tag
              v-if="data.enterStatus === 3"
              type="danger"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
						已作废
          </el-tag>
        </el-descriptions-item>
		  <el-descriptions-item
			  label="供应商"
			  label-align="right"
		  >
			  <el-link
				  type="primary"
				  @click="handleDetail(data, 'refSupplierDetail', 'supplierId')"
			  >
				  {{ data.fz_supplierName }}
			  </el-link>
		  </el-descriptions-item>
        <el-descriptions-item
          label="收货人"
          label-align="right"
        >
          {{ data.fz_receiverName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="收货日期"
          label-align="right"
        >
          {{ data.receiptTime }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="质检状态"-->
<!--          label-align="right"-->
<!--        >-->
<!--          <el-tag-->
<!--            v-if="data.qualityStatus === 0"-->
<!--            style="color: #666;"-->
<!--            class="myTag"-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            未检验-->
<!--          </el-tag>-->
<!--          <el-tag-->
<!--            v-if="data.qualityStatus === 1"-->
<!--            type="warning"-->
<!--            class="myTag"-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            检验中-->
<!--          </el-tag>-->
<!--          <el-tag-->
<!--            v-if="data.qualityStatus === 2"-->
<!--            type="success"-->
<!--            class="myTag"-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            检验通过-->
<!--          </el-tag>-->
<!--          <el-tag-->
<!--            v-if="data.qualityStatus === 3"-->
<!--            type="danger"-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            检验不通过-->
<!--          </el-tag>-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
          label="操作用户"
          label-align="right"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="操作时间"
          label-align="right"
        >
          {{ data.addTime }}
        </el-descriptions-item>
		  <el-descriptions-item
			  v-for="item in dataList"
			  :key="item.value"
			  :label="item.name"
			  label-align="right">
			  {{Array.isArray(data[item.value])?data[item.value].join(','):data[item.value]}}
		  </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.illustrate }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="物品明细"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableSon"
        :api-obj="tableSonConfig.apiObj"
        :column="tableSonConfig.columns"
        :params="tableSonConfig.params"
        :height="'330px'"
        row-key="id"
        border
        remote-sort
      >
        <template #fz_goodsName="{ scope }">
          <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
          >
            {{ scope.row.fz_goodsName }}
          </el-link>
        </template>
		  <template #storageStatus="{ scope }">
			  <el-tag
				  v-if="scope.row.storageStatus === 0"
				  style="color: #666;"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  未入库
			  </el-tag>
			  <el-tag
				  v-if="scope.row.storageStatus === 1"
				  type="success"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  已入库
			  </el-tag>
		  </template>
		  <template #enterStatus="{ scope }">
			  <el-tag
				  v-if="scope.row.enterStatus == 0"
				  style="color: #666;"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  未入库
			  </el-tag>
			  <el-tag
				  v-if="scope.row.enterStatus == 1"
				  type="warning"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  部分入库
			  </el-tag>
			  <el-tag
				  v-if="scope.row.enterStatus == 2"
				  type="success"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  全部入库
			  </el-tag>
			  <el-tag
				  v-if="scope.row.enterStatus == 3"
				  type="danger"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  已退库
			  </el-tag>
		  </template>
        <template #qualityStatus="{ scope }">
          <el-tag
            v-if="scope.row.qualityStatus === 0"
            style="color: #666;"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            未检验
          </el-tag>
          <el-tag
            v-if="scope.row.qualityStatus === 1"
            type="warning"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            检验中
          </el-tag>
          <el-tag
            v-if="scope.row.qualityStatus === 2"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            检验通过
          </el-tag>
          <el-tag
            v-if="scope.row.qualityStatus === 3"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            检验不通过
          </el-tag>
        </template>
		  <!--自定义字段-->
		  <template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
			  {{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}
		  </template>
      </scTable>
    </el-card>
<!--    <template #footer>-->
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关 闭-->
<!--      </el-button>-->
<!--    </template>-->
    <GoodsDetail ref="refGoodsDetail" />
    <PurOrderDetail ref="refPurOrderDetail" />
	  <SupplierDetail ref="refSupplierDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from 'vue'
import GoodsDetail from "@/views/cfg/data/goods/detail";
import PurOrderDetail from "@/views/erp/purchase/order/detail";
import SupplierDetail from "@/views/cfg/data/supplier/detail.vue";

export default defineComponent({
	components: {
		SupplierDetail,
		GoodsDetail,
		PurOrderDetail,
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			dataList:[],
			sonDataList:[],
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: '60%',
      		type:"detail",
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '收货单详情',
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '收货仓库',
						prop: 'fz_depotName',
						sortable: 'custom',
						// fixed: 'left',
						width: 120,
					},
					{
						label: '收货数量',
						prop: 'quantity',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},

					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],

			},
		})

		onMounted(() => {
			sonListMap()
			dataListMap()
		})

		// 获取产品明细自定义字段
		const sonListMap =async () =>{

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:2,isEnabled:1,menuCode:"erp_purchase_receipt",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.sonDataList = res.data.list
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: 'custom',
				},)
			})
		}

		// 获取自定义字段
		const dataListMap =async () =>{
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:1,isEnabled:1,menuCode:"erp_purchase_receipt",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.dataList = res.data.list
			}
		}
		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true
			state.fileList = []
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.purchaseReceipt.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					//获取明细表数据
					getGoodsList(res.data.id)
					//组装文件列表
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.params = {receiptId: id}
				state.tableSonConfig.apiObj = proxy.$API.purchaseReceipt.getReceiptGoodsList
				//刷新表格
				proxy.$refs.tableSon.reload({receiptId: id})
			}
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}
		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
			previewFile,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

