import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/getList`,
        name: "产品质检单列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    insert: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/insert`,
        name: "新增",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    update: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/update`,
        name: "修改",
        put: async function (data = {}) {
            return await http.put(this.url, data);
        }
    },

    detail: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/detail`,
        name: "查询单条数据",
        get: async function (params) {
            return await http.get(this.url + '?id=' + params);
        }
    },
    delete: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/delete`,
        name: "删除",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },


    updateFlag1: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/updateFlag1`,
        name: "启用",
        get: async function (data) {
            return await http.get(this.url+'?ids='+data);
        }
    },
    updateFlag0: {
        url: `${config.API_URL}/erp/quality/checkProductDetail/updateFlag0`,
        name: "停用",
        get: async function (data) {
            return await http.get(this.url+'?ids='+data);
        }
    },
	selectCheckProductDetail: {
		url: `${config.API_URL}/erp/quality/checkProductDetail/selectCheckProductDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

}
