<!--选择不良品项组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'450px'"
		custom-class="popTable"
		type="detail"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes @resized="handleResized">
					<pane>
						<el-container
							class="mainPanel"
							:style="multiple && 'padding-right: 10px'"
						>
							<sy-search-Form
								v-show="!hideForm"
								ref="refFormBar"
								:configs="searchConfig"
								:show-item="showItem"
								@fetch-data="fetchData"
								@set-show="handleFitShow"
							/>
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-button
										v-if="showAdd"
										ref="refPopAddBtn"
										type="primary"
										:size="$store.state.global.uiSize"
										@click="handleEdit(1, 'refPopAddBtn')"
									>
										新增
									</el-button>
									<el-link
										v-if="showEdit"
										ref="refEditBtn"
										:type="'primary'"
										:size="$store.state.global.uiSize"
										:disabled="isDisabled"
										@click="handleEdit(2, 'refEditBtn')"
									>
										编辑
									</el-link>
								</div>
								<div class="right-panel">
									<!--切换显示搜索栏hide-after: 延迟隐藏-->
									<el-tooltip
										:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
										placement="bottom"
										:hide-after="0"
									>
										<el-link @click="handleShowSearch">
											搜索
											<el-icon>
												<template v-if="hideForm">
													<el-icon-arrowDown/>
												</template>
												<template v-else>
													<el-icon-arrowUp/>
												</template>
											</el-icon>
										</el-link>
									</el-tooltip>
								</div>
							</el-header>
							<el-main class="nopadding bgwhite">
								<!--主数据表格-->
								<scTable
									ref="refMainTable"
									:api-obj="tableConfig.apiObj"
									:column="tableConfig.columns"
									row-key="id"
									:checkbox="multiple"
									radio-check
									border
									highlight-current-row
									:ref-query-form="refFormBar"
									remote-sort
									remote-filter
									hide-column-setting
									hide-setting
									:pagination-layout="'total, prev, pager, next, jumper'"
									@selection-change="selectChange"
									@current-change="currentChange"
									@row-click="handleRowClick"
								/>
							</el-main>
						</el-container>
					</pane>
					<!--已选数据-->
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumns"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<el-button
				:size="$store.state.global.uiSize"
				@click="visible = false"
			>
				关闭
			</el-button>
		</template>
		<!--新增弹窗-->
		<Edit
			ref="refEdit"
			@fetch-data="fetchData"
		/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
} from "vue";
import Edit from "./edit";  //新增组件
export default defineComponent({
	name: "SelectWaste",
	components: {Edit},
	props: {
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: "选择不良品项"},
	},
	emits: ["set-data"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			refFormBar: null,
			goodsEdition: "",
			goodsEdtitionList: [],
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//编辑按钮显示
			showEdit: false,
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			leftShow: true,
			paneSize: 20,
			paneSize1: 25,
			switchText: "分类",
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: "55%",
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: "渠道名称",
					prop: "name",
					width: 180,
					sortable: true,
				},
			],
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			tableConfig: {
				apiObj: proxy.$API.produceCraftWaste.getList,
				params: {},
				columns: [
					{
						label: "不良品项名称",
						prop: "name",
						width: 260,
						sortable: "custom",
						fixed: "left",
					},
					{
						label: "不良品项编码",
						prop: "code",
						width: 150,
						sortable: "custom",
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: "不良品项名称",
						span: 12,
						prop: "name",
						type: "input",
						max: 32,
						default: true,
					},
					{
						label: "不良品项编码",
						span: 12,
						prop: "code",
						type: "input",
						max: 32,
						default: true,
					},
				],
			},
		});

		//显示弹窗
		const showDialog = () => {
			state.visible = true;
			getShowAdd();
			getShowEdit();
			state.loading = false;
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
			state.selections = [];
			state.selData = [];
		};

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ERP_PRODUCE_CRAFT_WASTE, "add");
			//有结果标识有权限
			state.showAdd = !!res.data;
		};

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ERP_PRODUCE_CRAFT_WASTE, "edit");
			//有结果标识有权限
			state.showEdit = !!res.data;
		};

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool;
		};

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val;
			setSelData();
		};

		//已选数据添加项
		const setSelData = () => {
			state.selections.forEach(item => {
				const index = state.selData.indexOf(item);
				if (index === -1) {
					state.selData.push(item);
				}
			});
		};

		//选中行改变
		const currentChange = (row) => {
			state.curRow = row;
			if (state.curRow && JSON.stringify(state.curRow) !== "{}") {
				state.isDisabled = false;
				if (!props.multiple) {
					state.isConfirm = false;
				}
			} else {
				state.isDisabled = true;
				if (!props.multiple) {
					state.isConfirm = true;
				}
			}
		};

		//确定按钮点击
		const handleConfirm = () => {
			let data;
			if (!props.multiple) { //多选
				data = state.curRow;
			} else {
				data = state.selData;
			}
			//触发父级赋值方法
			emit("set-data", data);
			//隐藏弹窗
			hideDialog();
		};

		//分栏拖动结束
		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[0].size;
			}
		};

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state["table"].reload(data);
		};

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool;
		};

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm;
			});
		};

		/*--------------------已选数据操作---------------------------*/

		//清空已选数据
		const clearSelections = () => {
			state.selData = [];
			proxy.$refs.refMainTable.clearSelection();
		};

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val;
			state.isDelSel = val.length <= 0;
		};

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item);
					const index1 = state.selections.indexOf(item);
					if (index > -1) {
						state.selData.splice(index, 1);
					}
					if (index1 > -1) {
						proxy.$refs.refMainTable.toggleRowSelection(item, false);
					}
				});
			}
		};

		//新增修改弹窗
		const handleEdit = (type, ref) => {
			let param = {};
			if (type === 2 && state.curRow && JSON.stringify(state.curRow) !== "{}") { //编辑
				param = state.curRow;
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref];
			proxy.$refs.refEdit.showDialog(param);
		};

		watch(
			() => state.selData,
			(val) => {
				state.isConfirm = val.length <= 0;
			},
			{deep: true}
		);

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row);
		};

		//表格行点击
		const handleRowClick = (row) => {
			if (state.selections.indexOf(row) === -1) {
				proxy.$refs.refMainTable.toggleRowSelection(row, true);
			} else {
				proxy.$refs.refMainTable.toggleRowSelection(row, false);
				state.selData.splice(state.selData.indexOf(row), 1);
			}
		};
		return {
			...toRefs(state),
			handleRowClick,
			handleDetail,
			tableSelselectChange,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			hideDialog,
		};
	},
});
</script>
