import config from "@/config"
import http from "@/utils/request"

export default {
	detailPool: {
		url: `${config.API_URL}/mobile/stock/report/detailPool`,
		name: "库存明细汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	numbmobileool: {
		url: `${config.API_URL}/mobile/stock/report/numbmobileool`,
		name: "库存数量汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	availableStock: {
		url: `${config.API_URL}/mobile/stock/report/availableStock`,
		name: "可用库存数量汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	dullAnalysis: {
		url: `${config.API_URL}/mobile/stock/report/dullAnalysis`,
		name: "呆滞品分析",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	materialGlance: {
		url: `${config.API_URL}/mobile/stock/report/materialGlance`,
		name: "领料单 - 览表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	outEntmobileool: {
		url: `${config.API_URL}/mobile/stock/report/outEntmobileool`,
		name: "出入库明细",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
