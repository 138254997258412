<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="订单编号"
          label-align="right"
        >
          {{ data.orderNo }}
        </el-descriptions-item>
        <el-descriptions-item
          label="销售订单"
          label-align="right"
        >
          <el-link
            type="primary"
            @click="handleDetail(data, 'refSaleOrderDetail', 'saleOrderId')"
          >
            {{ data.fz_saleOrderNo }}
          </el-link>
        </el-descriptions-item>

				<el-descriptions-item
					label="质检状态"
					label-align="right"
				>
					<el-tag
						v-if="data.qualityStatus === 0"
						style="color: #666"
						:size="$store.state.global.uiSize"
					>
						未检验
					</el-tag>
					<el-tag
						v-if="data.qualityStatus === 1"
						type="danger"
						:size="$store.state.global.uiSize"
					>
						检验驳回
					</el-tag>
					<el-tag
						v-if="data.qualityStatus === 2"
						type="success"
						:size="$store.state.global.uiSize"
					>
						检验通过
					</el-tag>
				</el-descriptions-item>

        <el-descriptions-item
          label="出库状态"
          label-align="right"
        >
          <el-tag
            v-if="data.outStatus === 0"
						style="color: #666"
            :size="$store.state.global.uiSize"
          >
            未出库
          </el-tag>
          <el-tag
            v-if="data.outStatus === 1"
            type="success"
            :size="$store.state.global.uiSize"
          >
            已出库
          </el-tag>
          <el-tag
            v-if="data.outStatus === 2"
            type="danger"
            :size="$store.state.global.uiSize"
          >
            已退库
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item
          label="签收状态"
          label-align="right"
        >
          <el-tag
            v-if="data.signStatus === 0"
						style="color: #666"
            :size="$store.state.global.uiSize"
          >
            未签收
          </el-tag>
          <el-tag
            v-if="data.signStatus === 1"
            type="success"
            :size="$store.state.global.uiSize"
          >
            已签收
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item
            label="发货地址"
            label-align="right"
        >
          {{ data.address }}
        </el-descriptions-item>
				<el-descriptions-item
					label="发货方式"
					label-align="right"
				>
					{{ data.fz_shipMethodValue }}
				</el-descriptions-item>
				<el-descriptions-item
					label="物流公司"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refLogisticsDetail', 'logisticsId')"
					>
						{{ data.fz_logisticsName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="联系人"
					label-align="right"
				>
					{{ data.contactsName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="联系电话"
					label-align="right"
				>
					{{ data.contactsTel }}
				</el-descriptions-item>
        <el-descriptions-item
          label="运单号"
          label-align="right"
        >
          {{ data.waybillNo }}
        </el-descriptions-item>
        <el-descriptions-item
          label="运输费用"
          label-align="right"
        >
          {{ data.transportCost }}
        </el-descriptions-item>
        <el-descriptions-item
          label="付款方式"
          label-align="right"
        >
          <span v-if="data.paymentMethod === 1">现金</span>
          <span v-if="data.paymentMethod === 2">转账</span>
          <span v-if="data.paymentMethod === 3">支票</span>
          <span v-if="data.paymentMethod === 4">其他</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="付款账户"
          label-align="right"
        >
          {{ data.fz_paymentAccountName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货人"
          label-align="right"
        >
          {{ data.fz_deliverName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货时间"
          label-align="right"
        >
          {{ data.deliverTime }}
        </el-descriptions-item>

        <el-descriptions-item
          label="操作用户"
          label-align="right"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="操作时间"
          label-align="right"
        >
          {{ data.addTime }}
        </el-descriptions-item>
		  <el-descriptions-item
			  v-for="item in dataList"
			  :key="item.value"
			  :label="item.name"
			  label-align="right">
			  {{ data[item.value] }}
		  </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.illustrate }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="产品明细"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableSon"
        :api-obj="tableSonConfig.apiObj"
        :column="tableSonConfig.columns"
        :params="tableSonConfig.params"
        :height="'auto'"
        row-key="id"
        border
        remote-sort
      >
        <template #fz_goodsName="{ scope }">
          <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
          >
            {{ scope.row.fz_goodsName }}
          </el-link>
        </template>
      </scTable>
    </el-card>
    <el-card
      header="物流跟踪"
      shadow="never"
      class="mycard mt_10"
    >
      预留
    </el-card>
    <SaleOrderDetail ref="refSaleOrderDetail" />
    <LogisticsDetail ref="refLogisticsDetail" />
    <GoodsDetail ref="refGoodsDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from 'vue'
import SaleOrderDetail from "@/views/erp/sale/order/detail";
import LogisticsDetail from "@/views/cfg/data/logistics/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";

export default defineComponent({
	components: {
		SaleOrderDetail,
		LogisticsDetail,
		GoodsDetail,
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			dataList:[],
			sonDataList:[],
			//条款数据
			clauseList: [],
      		type:"detail",
			//弹窗宽度
			width: '60%',
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '发货单详情',
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '产品编码',
						prop: 'fz_goodsCode',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '发货仓库',
						prop: 'fz_depotName',
						sortable: 'custom',
						fixed: 'left',
						width: 110,
					},
					{
						label: '发货数量',
						prop: 'quantity',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '成本',
						prop: 'cost',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],
			},
		})

		onMounted(() => {
			sonListMap()
			dataListMap()
		})

		// 获取产品明细自定义字段
		const sonListMap =async () =>{

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:2,isEnabled:1,menuCode:"erp_sale_invoice",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					item.value = 'customField' + item.fieldLength
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.sonDataList = res.data.list
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: 'custom',
				},)
			})
		}

		// 获取自定义字段
		const dataListMap =async () =>{
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:1,isEnabled:1,menuCode:"erp_sale_invoice",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					item.value = 'customField' + item.fieldLength
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.dataList = res.data.list
			}
		}
		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true
			state.fileList = []
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.saleInvoice.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					//获取明细表数据
					getGoodsList(res.data.id)
					//组装文件列表
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.apiObj = proxy.$API.saleInvoiceGoods.selectInvoiceGoods
				state.tableSonConfig.params = {invoiceId: id}

				//刷新表格
				proxy.$refs.tableSon.reload({invoiceId: id})
			}
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}
		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
		}
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			if(ref == 'refSaleOrderDetail'){
				proxy.$refs[ref].showDialog(params,true)
			}else {
				proxy.$refs[ref].showDialog(params)
			}
			// proxy.$refs[ref].showDialog(params)
		}
		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
			previewFile,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

