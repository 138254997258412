//websocket

import {ElMessage} from "element-plus";
import {messageDuration} from "@/config";
let g_websocket;

/* //响应数据 */
let ackJsonData;

/**SDK初始化状态 */
let initSdkStatus = false;
/**设备状态 */
let deviceStatus = false;
/**消息列表 */
let MessageList = {};

let timeoutTimer;
const TIMEOUT_DURATION = 10000;
//重连时间
let reconnectTimer = null;
//是否已重连
let isReconnecting = false;
// 是否已停止连接
let isStopConnect = true;

/**通过websocket发送消息 */
export function sendMsg(msg, callback) {
	MessageList[msg.apiName] = callback;
	let data = JSON.stringify(msg);
	let tryTimes = 10;
	if (!g_websocket||g_websocket.readyState!==WebSocket.OPEN) {
		if(callback&&typeof callback ==='function'){
			console.log('打印服务未开启');
			//callback(new Error("打印服务未开启"))
		}

		return;
	}
	// timeoutTimer=setTimeout(function(){
	// 	// //超时关闭打印服务，进入重连机制
	// 	// g_websocket.close();
	// 	if(callback&&typeof callback ==='function'){
	// 		console.log('打印服务消息接收超时');
	// 		//callback(new Error("打印服务消息接收超时"))
	// 	}
	// },TIMEOUT_DURATION);

	for (let i = 0; i < tryTimes; i++) {
		g_websocket.send(data);
		return;
	}
}

//1.1 初始化打印服务
export function getInstance(onServiceConnected, onNotSupportedService, onServiceDisconnected, onPrinterConnectSuccess, onPrinterDisConnect, connectionTimedOut) {
	//是否已连接
	let isConnected = false;
	// 超时时间
	const reconnectTimeout = 7000

	isStopConnect = false

	const connect = () => {
		if ('WebSocket' in window) {
			g_websocket = new WebSocket('ws://127.0.0.1:37989');
			if ('binaryType' in WebSocket.prototype) {
				g_websocket.binaryType = 'arraybuffer';
			}
			if ('timeout' in WebSocket.prototype) {
				g_websocket.timeout = 5000;
			}
			g_websocket.proxy_read_timeout=
				g_websocket.addEventListener('open', (event) => {
					isConnected = true;
					isReconnecting = false;
					console.log('WebSocket connected !');
					clearInterval(reconnectTimer);
					onServiceConnected();
				});
			g_websocket.addEventListener('error', (event) => {
				if(timeoutTimer!=null){
					clearTimeout(timeoutTimer);
				}
				isConnected = false;
				ackJsonData = '';
				if (!isReconnecting) {
					isReconnecting = true;
					onServiceDisconnected();
					reconnect();
				}

			});

			g_websocket.addEventListener('close', (event) => {
				if(timeoutTimer!=null){
					clearTimeout(timeoutTimer);
				}
				isConnected = false;
				ackJsonData = '';
				if (!isReconnecting) {
					isReconnecting = true;
					onServiceDisconnected();
					reconnect();
				}

			});

			g_websocket.addEventListener('message', (event) => {

				if(timeoutTimer!=null){
					clearTimeout(timeoutTimer);
				}

				readCallback(event, () => {
					onPrinterConnectSuccess();
				}, () => {
					onPrinterDisConnect();
				});
			});

		} else {
			onNotSupportedService();
		}

	};

	const reconnect = () => {
		if (!isConnected && isReconnecting && !isStopConnect) {
			clearInterval(reconnectTimer);
			// reconnectTimer = setInterval(connect, 3000);
			let startTime = Date.now();

			reconnectTimer = setInterval(() => {
				// 每次重连时检查是否超时
				if (Date.now() - startTime >= reconnectTimeout) {
					ElMessage({
						message: '打印机连接失败!',
						type: 'error',
						duration: messageDuration,
						showClose: true,
					})
					clearInterval(reconnectTimer);
					isReconnecting = false;
					connectionTimedOut()
					return;
				}
				connect();
			}, 3000);
		}else {
			isReconnecting = false
		}
	};

	connect();
}
// 停止初始化打印服务
export function stopInstance() {
	clearInterval(reconnectTimer)
	isStopConnect = true;
	if(g_websocket){
		g_websocket.close()
	}
}

//1.1.4 读回调
export function readCallback(event, onPrinterConnectSuccess, onPrinterDisConnect) {
	let callBackInfo = event.data;
	console.log('readCallback', callBackInfo);
	ackJsonData = callBackInfo;

	let callbackName;

	if (isJSON(ackJsonData)) {
		let arrParse = JSON.parse(ackJsonData);

		//接口回调
		if (!!MessageList[arrParse.apiName]) {
			MessageList[arrParse.apiName](null,arrParse);
		}

		//回调分发
		if (arrParse.apiName === 'printStatus') {
			if (arrParse['resultAck']['online'] === 'online') {
				onPrinterConnectSuccess();

			} else {
				onPrinterDisConnect();
			}
		} else {
			if (arrParse['resultAck']['callback'] !== undefined) {
				callbackName = arrParse['resultAck']['callback']['name'];

				if (callbackName === 'onConnectSuccess') {
					var printerName = arrParse['resultAck']['callback']['printerName'];
					onConnectSuccess(printerName);
				} else if (callbackName === 'onDisConnect') {
					var printerName = arrParse['resultAck']['callback']['printerName'];
					onDisConnect(printerName);
				} else if (callbackName === 'onCoverStatusChange') {
					let coverStatus = arrParse['resultAck']['callback']['coverStatus'];
					onCoverStatusChange(coverStatus);
				} else if (callbackName === 'onElectricityChange') {
					let powerLever = arrParse['resultAck']['callback']['powerLever'];
					onElectricityChange(powerLever);
				} else if (callbackName === 'onPaperStatusChange') {
					let paperStatus = arrParse['resultAck']['callback']['paperStatus'];
					onPaperStatusChange(paperStatus);
				} else if (callbackName === 'onPrintPageCompleted') {
					onPrintPageCompleted();
				} else if (callbackName === 'onPrintProgress') {
					onPrintProgress();
				} else if (callbackName === 'onAbnormalResponse') {
					onAbnormalResponse();
				}
				else {
					console.log('unknow callback api!');
				}
			}
		}

		ackJsonData = '';
	}
}

//2.1 打印机连接成功回调onConnectSuccess
export function onConnectSuccess(printerName) {
	console.log('打印机连接成功!');
	initSdkStatus = true;
	deviceStatus = true;
}

//2.2 打印机断开回调onDisConnect
export function onDisConnect(printerName) {
	console.log('打印机断开！');
	initSdkStatus = false;
}

//2.3 打印机上盖变化回调onCoverStatusChange
export function onCoverStatusChange(coverStatus) {
	console.log('打印机盒盖有变化！');
}

//2.4 打印机电量变化回调onElectricityChange()
export function onElectricityChange(powerLever) {
	console.log('打印机电量有变化！');
}

//2.5 打印机纸张状态变化回调onPaperStatusChange
export function onPaperStatusChange(paperStatus) {
	console.log('打印机纸张状态有变化！');
}

//3. 初始化SDK
export function initSdk(json, callbackFunction) {
	let jsonObj = {
		apiName: 'initSdk',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//获取所有当前PC上连接的精臣打印机
//4. 获取打印机列表getAllPrinters()
export function getAllPrinters(callbackFunction) {
	//刷新设备时，关闭设备
	//closePrinter();
	let jsonObj = { apiName: 'getAllPrinters' };

	sendMsg(jsonObj, callbackFunction);
}

//5.选择并打开需要使用的打印机名称，及端口号
export function selectPrinter(printerName, port, callbackFunction) {
	let jsonObj = {
		apiName: 'selectPrinter',
		parameter: { printerName: printerName, port: port }
	};
	sendMsg(jsonObj, callbackFunction);
}

//6.1 创建画板
export function InitDrawingBoard(json, callbackFunction) {
	let jsonObj = {
		apiName: 'InitDrawingBoard',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.2 绘制文本
export function DrawLableText(json, callbackFunction) {
	let jsonObj = {
		apiName: 'DrawLableText',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.3 绘制一维码
export function DrawLableBarCode(json, callbackFunction) {
	let jsonObj = {
		apiName: 'DrawLableBarCode',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.4 绘制二维码
export function DrawLableQrCode(json, callbackFunction) {
	let jsonObj = {
		apiName: 'DrawLableQrCode',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.5 绘制线条
export function DrawLableLine(json, callbackFunction) {
	let jsonObj = {
		apiName: 'DrawLableLine',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.6 绘制图形
export function DrawLableGraph(json, callbackFunction) {
	let jsonObj = {
		apiName: 'DrawLableGraph',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.7 绘制图像
export function DrawLableImage(json, callbackFunction) {
	let jsonObj = {
		apiName: 'DrawLableImage',
		parameter: json
	};

	sendMsg(jsonObj, callbackFunction);
}

//6.8 生成预览图
export function generateImagePreviewImage(displayScale, callbackFunction) {
	let jsonObj = {
		apiName: 'generateImagePreviewImage',
		displayScale: displayScale
	};

	sendMsg(jsonObj, callbackFunction);
}

/**
 * 7.开始打印任务
 * @param {number} printDensity 打印浓度
 * @param {number} printLabelType 纸张类型
 * @param {number} printMaterial 材质
 * @param {number} printMode 打印模式
 * @param {number} count 总打印张数
 * @param {*} callbackFunction 回调函数
 */
export function startJob(printDensity, printLabelType, printMode, count, callbackFunction) {
	let jsonObj = {
		apiName: 'startJob',
		parameter: {
			printDensity: printDensity,
			printLabelType: printLabelType,
			printMode: printMode,
			count: count
		}
	};
	sendMsg(jsonObj, callbackFunction);
}

//8.提交打印任务commitJob
export function commitJob(printData, printerImageProcessingInfo, callbackFunction) {
	let printDataJson = eval('(' + printData + ')');
	let printerImageProcessingInfoJson = eval('(' + printerImageProcessingInfo + ')');
	let jsonObj = {
		apiName: 'commitJob',
		parameter: {
			printData: printDataJson,
			printerImageProcessingInfo: printerImageProcessingInfoJson['printerImageProcessingInfo'],
		}
	};
	sendMsg(jsonObj, callbackFunction);
}

//9.结束打印任务endJob
export function endJob(callbackFunction) {
	let jsonObj = { apiName: 'endJob' };
	sendMsg(jsonObj, callbackFunction);
}

//10.取消打印任务cancleJob
export function cancleJob(callbackFunction) {
	let jsonObj = { apiName: 'stopPrint' };
	sendMsg(jsonObj, callbackFunction);
}

//.设置关机时间
//nType--1：15分钟，2:30分钟，3:60分钟，4：never
export function setPrinterAutoShutDownTime(nType, callbackFunction) {
	let jsonObj = {
		apiName: 'setPrinterAutoShutDownTime',
		parameter: { nType: nType }
	};
	sendMsg(jsonObj, callbackFunction);
}

function getResult(tryTime, apiName, errInfo) {
	tryTimes = tryTime;

	let result = {};
	while (tryTimes--) {
		if (!isJSON(ackJsonData)) continue;

		let arrParse = JSON.parse(ackJsonData);
		if (arrParse['apiName'] === apiName) {
			result = arrParse['resultAck'];
			break;
		}
	}

	if (tryTimes <= 0) {
		result['result'] = false;
		result['errorCode'] = 0x12;
		result['info'] = errInfo;
	}
	return result;
}

function isJSON(str) {
	if (typeof str == 'string') {
		try {
			let obj = JSON.parse(str);
			if (typeof obj == 'object' && obj) {
				return true;
			} else {
				return false;
			}

		} catch (e) {
			//console.log('error：'+str+'!!!'+e);
			return false;
		}

	}

	console.log('It is not a string!');
}
