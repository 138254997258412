<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="供应商名称"
        >
          <el-input
            v-model="form.name"
            maxlength="32"
            @blur="handleNameBlur"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="number"
          label="供应商编码"
        >
          <el-input
            v-model="form.number"
            maxlength="32"
            clearable
            @blur="handleCodeBlur"
          />
        </el-form-item>
<!--        <el-form-item-->
<!--          prop="supplieType"-->
<!--          label="供应商类型"-->
<!--        >-->
<!--          <el-select-->
<!--            v-model="form.supplieType"-->
<!--            clearable-->
<!--          >-->
<!--            <el-option-->
<!--              label="设备供应商"-->
<!--              :value="1"-->
<!--            />-->
<!--            <el-option-->
<!--              label="原材料供应商"-->
<!--              :value="2"-->
<!--            />-->
<!--          </el-select>-->
<!--        </el-form-item>-->

<!--        <el-form-item-->
<!--          prop="paymentMethod"-->
<!--          label="付款方式设置"-->
<!--        >-->
<!--          <el-select-->
<!--            v-model="form.paymentMethod"-->
<!--            clearable-->
<!--          >-->
<!--            <el-option-->
<!--              label="款到发货"-->
<!--              :value="1"-->
<!--            />-->
<!--            <el-option-->
<!--              label="发货后X天为收款期限"-->
<!--              :value="2"-->
<!--            />-->
<!--            <el-option-->
<!--              label="开票后X天为收款期限"-->
<!--              :value="3"-->
<!--            />-->
<!--            <el-option-->
<!--              label="每月固定日期收款"-->
<!--              :value="4"-->
<!--            />-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item
          prop="fz_buyerName"
          label="采购员"
        >

			  <div class="disflex fullWidth sc-table-select--append">
				  <div class="flexgs1">
					  <sc-table-select
						  v-model="form.fz_buyerName"
						  customerValue
						  fullWidth
						  :api-obj="$API.user.getListByKeyword"
						  :params="{
                        flag: 1,
                      }"
						  :height="426"
						  :props="{ label: 'name', value: 'id', keyword: 'name' }"
						  @change="(val) => handleSelChange(val, ['buyerId','fz_buyerName'])"
						  @clear="inputClear('buyer')"
					  >
						  <el-table-column
							  prop="name"
							  label="名称"
							  width="150"
						  />
						  <el-table-column
							  prop="fz_departmentName"
							  label="部门"
							  width="150"
						  />
						  <el-table-column
							  prop="code"
							  label="工号"
							  width="150"
						  />
            <template #append>
				<el-icon
					@click="popSelect('popSaledInput',['buyerId','fz_buyerName'], 1, 'refSelectUser')"
				>
					<el-icon-search/>
				</el-icon>
            </template>
					  </sc-table-select>
				  </div>
			  </div>
        </el-form-item>
        <!--文本框-->
        <el-form-item
          prop="remark"
          label="备注"
        >
          <!--
          type="textarea"表示文本输入框
          rows="5"默认行数，越大文本框越高
          -->
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="250"
            rows="5"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
		<el-popconfirm
			:visible="visibles"
			:title="$TIPS.SAVE"
			confirm-button-text="确定"
			cancel-button-text="取消"
			@cancel="visibles = false"
			@confirm="handleConfirm"
		>
			<template #reference>
				<el-button
					type="primary"
					:size="$store.state.global.uiSize"
					:loading="subLoading"
					@click = "valiForm"
				>
					提交
				</el-button>
			</template>
		</el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      remote-sort
      :ref-query-form="refFormBar"
    >
      <template #supplieType="{ scope }">
        <span>{{ scope.row.supplieType === 1?'设备供应商':'原材料供应商' }}</span>
      </template>

        <template #flag="{ scope }">
            <el-tag
                    v-if="scope.row.flag === 1"
                    class="myTag"
                    type="success"
                    :size="$store.state.global.uiSize"
            >
                启用
            </el-tag>
            <el-tag
                    v-if="scope.row.flag === 0"
                    class="myTag"
                    type="danger"
                    :size="$store.state.global.uiSize"
            >
                停用
            </el-tag>
        </template>
        <template #taxRate="{ scope }">
            <el-tag
                    v-if="scope.row.taxRate == '-1'"
                    class="myTag"
                    type="success"
                    :size="$store.state.global.uiSize"
            >
            </el-tag>
            <el-tag
                    v-else
                    class="myTag"
                    type="danger"
                    :size="$store.state.global.uiSize"
            >
                {{ scope.row.taxRate }}
            </el-tag>
        </template>

      <template #paymentMethod="{ scope }">
        <span v-if="scope.row.paymentMethod === 1">款到发货</span>
        <span v-if="scope.row.paymentMethod === 2">发货后X天为收款期限</span>
        <span v-if="scope.row.paymentMethod === 3">开票后X天为收款期限</span>
        <span v-if="scope.row.paymentMethod === 4">每月固定日期收款</span>
      </template>
    </SyCopyDialog>
    <!--选择用户-->
    <SelectUser
      ref="refSelectUser"
      :multiple="multiples"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs,} from 'vue'

export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
    const user = proxy.$TOOL.data.get('USER_INFO')

    //验证code重复
    const validateName = (rule, value, callback) => {
      if (state.nameMsg) {
        callback(new Error(state.nameMsg))
      } else {
        callback()
      }
    }

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      refFormBar:null,
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '用户新增',
			//表单当前数据
			form: {
				manager: 0,
        supplieType: 2,
        paymentMethod: 1,
        buyerId: user.id,
        fz_buyerName: user.name,
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
        name: [{required: true, message: '必填'},{validator: validateName, trigger: 'blur'},],
        number: [{validator: validateCode, trigger: 'blur'}],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.supplier.getSupplierList,
				//弹窗标题
				title:'复制供应商信息',
				//查询匹配的字段
				prop: 'name',
				//表格显示列
				columns: [
					{
						label: '供应商名称',
						prop: 'name',
						width: 200,
						fixed: 'left',
						sortable: 'custom',
					},
					{
						label: '供应商编码',
						prop: 'number',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '社会信用代码',
						prop: 'code',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'flag',
						width:90,
						sortable: 'custom',
					},
					// {
					// 	label: '供应商类型',
					// 	prop: 'supplieType',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '付款方式设置',
					// 	prop: 'paymentMethod',
					// 	width: 150,
					// 	sortable: 'custom',
					// },
					{
						label: '采购员',
						prop: 'fz_buyerName',
						width: 100,
						sortable: 'custom',
					},
					{
						label: '公司规模',
						prop: 'scale',
						width: 110,
						sortable: 'custom',
					},
					// {
					// 	label: '所在国家',
					// 	prop: 'country',
					// 	width: 140,
					// 	sortable: 'custom',
					// },
					{
						label: '所在地区',
						prop: 'area',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '详细地址',
						prop: 'address',
						width: 180,
						sortable: 'custom',
					},
					/*{
						label: '联系手机',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},*/
					{
						label: '联系电话',
						prop: 'tel',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '传真',
					// 	prop: 'fax',
					// 	width: 130,
					// 	sortable: 'custom',
					// },
					{
						label: '邮政编码',
						prop: 'zipCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '电子邮箱',
						prop: 'email',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '网址',
						prop: 'website',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: 'QQ',
					// 	prop: 'qq',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '微信',
						prop: 'weChat',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '旺旺',
					// 	prop: 'wangwang',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '法定代表人',
						prop: 'corporation',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '委托代理人',
						prop: 'entrust',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '共享人',
					// 	prop: 'fz_shareUserName',
					// 	width: 160,
					// 	sortable: 'custom',
					// },
					{
						label: '经营范围',
						prop: 'businessScope',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '企业简介',
						prop: 'profile',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					// {
					// 	label: '预设价格',
					// 	prop: 'presetPrice',
					// 	width: 150,
					// 	sortable: 'custom',
					// },
					{
						label: '关联客户',
						prop: 'fz_customerName',
						width: 120,
						sortable: 'custom',
						max:32
					},
					{
						label: '开户行',
						prop: 'bank',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '账户名称',
						prop: 'accountName',
						width: 120,
					},
					{
						label: '银行账号',
						prop: 'accountNumber',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '联行号',
						prop: 'bankNo',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '许可证号',
						prop: 'licenseKey',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '税号',
						prop: 'taxCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '发票类型',
						prop: 'fz_typeName',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '发票税率(%)',
						prop: 'taxRate',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '预付金额',
						prop: 'advancePayment',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '欠供应商款',
						prop: 'oweAmount',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],

			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增供应商"
				state.form = {
					manager: 0,
					supplieType: 2,
					paymentMethod: 1,
					buyerId: user.id,
					fz_buyerName: user.name,
				}
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑供应商[" + row.name + ']'
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.supplier.detailSupplier.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
			save()
			state.visibles = false
		}
		const valiForm = () => {
			nextTick(()=>{
				state.refForm.validate((valid)=>{
					if(valid){
						state.visibles = true
					}else {
						state.visibles = false
					}
				})
			})
		}
		//提交方法
		const save = () => {
			state['refForm'].validate(async (valid) => {
				if(valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async () => {
						state.subLoading = true
						let res = ''
						if (state.form.id) { //修改
							res = await proxy.$API.supplier.updateSupplier.put(state.form)
						} else {
							res = await proxy.$API.supplier.insertSupplier.post(state.form)
						}
						if (res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					// })
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
          state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}


    //编码重复校验
    const handleNameBlur = async (e) => {
      state.nameMsg = ''
      const val = e.target.value
      if (val !== '') {
        const res = await proxy.$API.supplier.doCheckName.post(state.form)
        if (res.code === 500) {
          state.nameMsg = res.msg
          state.refForm.validateField('name').then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
        }
      }
    }

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.supplier.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
          state.refForm.validateField('number').then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
				}
			}
		}

		//打开选择弹窗
		//参数(ref触发元素，fields赋值字段，type类型区分多选单选，pop弹窗ref对象)
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = state.form[fields[0]]
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				if(pop === 'refSelectCustomerConacts'){
					if(state.form.customerId){
						param = state.form.customerId
						//赋值弹窗的触发元素
						proxy.$refs[pop].triggerDom = proxy.$refs[ref]
						//打开弹窗
						proxy.$refs[pop].showDialog(param)
					}else{
						proxy.$baseMessage('请先选择供应商', 'error')
					}
				}else{
					//赋值弹窗的触发元素
					proxy.$refs[pop].triggerDom = proxy.$refs[ref]
					//打开弹窗
					proxy.$refs[pop].showDialog(param)
				}
			})
		}

		//表单中选择项赋值
		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}


		return {
			...toRefs(state),
			popSelect,
			setSelectData,
			handleCodeBlur,
      handleNameBlur,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			valiForm
		}
	},
})
</script>
