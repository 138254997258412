<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <el-alert
      type="warning"
      class="alertTips"
      :closable="false"
    >
      <p class="ft12">1、excel模板中的*号为必填项，否则将导致导入不成功</p>
      <p class="ft12">2、上传文件不超过5M，且必须为excel表格文件</p>
    </el-alert>
    <!--表单-->
    <div class="formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="第一步:"
        >
          <el-link
            type="primary"
            @click="download"
          >
            点击下载导入模板
          </el-link>
        </el-form-item>
        <el-form-item
          prop="files"
          label="第二步:"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'选择导入excel模板'"
            :accept="'*'"
            :list-type="'text'"
            :limit="1"
            is-file
          >
            <el-button type="primary">
              选择文件
            </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        @click="save"
      >
        提交
      </el-button>
      <!--<el-button
        :size="$store.state.global.uiSize"
        @click="reset(false)"
      >
        重置
      </el-button>-->
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//控制用户多选
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '导入',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				files: [
					{required: true, message: '请选择文件'},
				],
			},
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}

		//下载模板
		const download = () => {
			//下载模板
			const tempUrl = proxy.$CONFIG.API_URL + '/api/Template/入库序列号导入模板.xlsx'
			window.open(tempUrl)
		}


		//提交方法
		const save = () => {
			state['refForm'].validate((valid) => {
				if(valid) {
					proxy.$baseConfirm('是否确认导入？',null, async ()=>{
            proxy.$LOADING.loading();
						state.subLoading = true
						proxy.$API.snRules.importExcel.post(state.form,{timeout: 300000}).then((res)=>{
							if(res.code === 200) {
								proxy.$baseMessage(res.msg,'success')
								emit("fetch-data",res.data);
								state.visible = false;
							} else {
								proxy.$baseMessage(res.msg,'error')
                // if(res.msg){
                //   proxy.$baseMessage(res.msg,'error')
                // }else {
                //   proxy.$importAlert(res.data)
                // }
							}
							state.subLoading = false
              proxy.$LOADING.closed();
						})
					})
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}


		return {
			...toRefs(state),
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			download,
			handleConfirm
		}
	},
})
</script>
