<!--弹窗提示-->
<template>
	<sc-dialog
		v-model="visible"
		:title="title"
		type="detail"
		:bef-close="false"
		:width="width"
		:footer="false"
		:showFullscreen="false"
		@closed="handleClosed"
	>
		<el-container>
			<el-main class="nopadding">
				<div class="mt_f10">
					<el-card
						v-for="item in data"
						class="mt_10"
						shadow="never"
					>
						<div class="alertContent" v-html="item" />
						<!--<template #footer>
							<el-button
								type="primary"
								:size="$store.state.global.uiSize"
								@click="handleClick(item)"
							>
								去处理
							</el-button>
						</template>-->
					</el-card>
				</div>
			</el-main>
		</el-container>
	</sc-dialog>
</template>
<script>
import {defineComponent, toRefs, reactive, getCurrentInstance, nextTick} from "vue";
export default defineComponent({
	name: "SyAlertDialog",
	props: {},
	emits: ['on-closed'],
	setup(props,{emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			title: "保存提示",
			data: '',
			width: 400,
			height: "300px",
			visible: false
		});

		//显示弹窗
		const showDialog = (data) => {
			if (data) {
				let message = []
				data.forEach( item => {
					const text = item.toString();
					const formattedData = text
						.replace(/(\】:)/g, '$1<br>')
						.replace(/(;)/g, '$1<br>');
					message.push(formattedData)
				})
				console.log(data)
				state.data = message
			}
			state.visible = true;
		};
		//
		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
		};
		//弹窗关闭回调
		const handleClosed = () => {
			state.data = ''
		}
		//跳转
		const handleClick = (item) => {

		}
		return {
			...toRefs(state),
			handleClick,
			showDialog,
			hideDialog,
			handleClosed
		};
	}
});
</script>
<style lang="scss" scoped>
.alertContent{
	color: var(--el-color-danger);
}
:deep(.el-card){
	--el-card-padding: 10px;
	.el-card__footer{
		text-align: center;
	}
}
</style>
