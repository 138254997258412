<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<!--label: 标题 -->
				<el-descriptions-item
					label="订单编号"
					label-align="right"
				>
					{{ data.orderNo }}
				</el-descriptions-item>

				<el-descriptions-item
					label="销售合同"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refContractDetail', 'contractId')"
					>
						{{ data.contractNo }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="客户"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refCustomerDetail', 'customerId')"
					>
						{{ data.fz_customerName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="联系人"
					label-align="right"
				>
					{{ data.contacts }}
				</el-descriptions-item>
<!--				<el-descriptions-item
					label="联系手机"
					label-align="right"
				>
					{{ data.phone }}
				</el-descriptions-item>-->
				<el-descriptions-item
					label="送货地址"
					label-align="right"
				>
					{{ data.shipAddress }}
				</el-descriptions-item>

				<el-descriptions-item
					label="发货状态"
					label-align="right"
				>
					<el-tag
						v-if="data.invoiceStatus === 0"
						style="color: #666;"
					>
						未发货
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 1"
						type="warning"
					>
						部分发货
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 2"
						type="success"
					>
						全部发货
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 3"
						type="danger"
					>
						已终止
					</el-tag>

				</el-descriptions-item>

				<el-descriptions-item
					label="生产状态"
					label-align="right"
				>
					<el-tag
						v-if="data.invoiceStatus === 0"
						style="color: #666;"
					>
						未生产
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 1"
						type="warning"
					>
						生产中
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 2"
						type="success"
					>
						已完成
					</el-tag>
				</el-descriptions-item>

				<el-descriptions-item
					label="交货日期"
					label-align="right"
				>
					{{ data.deliveryDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="业务员"
					label-align="right"
				>
					{{ data.fz_salesmanName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="下单日期"
					label-align="right"
				>
					{{ data.creationDate }}
				</el-descriptions-item>

				<el-descriptions-item
					label="单位抬头"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refCompanyDetail', 'companyId')"
					>
						{{ data.fz_companyName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>

				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
				<el-descriptions-item
					v-for="item in dataList"
					:key="item.value"
					:label="item.name"
					label-align="right">
					{{ data[item.value] }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</el-card>

		<el-card
			header="产品明细"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
			>

				<template #invoiceStatus="{ scope }">
					<el-tag
						v-if="scope.row.invoiceStatus === 0"
						style="color: #666;"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未发货
					</el-tag>
					<el-tag
						v-if="scope.row.invoiceStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						部分发货
					</el-tag>
					<el-tag
						v-if="scope.row.invoiceStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						全部发货
					</el-tag>
					<el-tag
						v-if="scope.row.invoiceStatus === 3"
						type="danger"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已终止
					</el-tag>
				</template>

				<!--	生产状态 -->
				<template #processStatus="{ scope }">
					<el-tag
						v-if="scope.row.processStatus === 0"
						style="color: #666;"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未生产
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						生产中
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已完成
					</el-tag>
				</template>

				<template #fz_goodsName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
					>
						{{ scope.row.fz_goodsName }}
					</el-link>
				</template>
				<template #refundStatus="{ scope }">
					<el-tag
						v-if="scope.row.refundStatus === 0"
						style="color: #666;"
						:size="$store.state.global.uiSize"
					>
						未退货
					</el-tag>
					<el-tag
						v-if="scope.row.refundStatus === 1"
						class="warning"
						:size="$store.state.global.uiSize"
					>
						部分退货
					</el-tag>
					<el-tag
						v-if="scope.row.refundStatus === 2"
						class="danger"
						:size="$store.state.global.uiSize"
					>
						全部退货
					</el-tag>
				</template>
				<template #ticketStatus="{ scope }">
					<el-tag
						v-if="scope.row.ticketStatus === 0"
						style="color: #666;"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未开票
					</el-tag>
					<el-tag
						v-if="scope.row.ticketStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						部分开票
					</el-tag>
					<el-tag
						v-if="scope.row.ticketStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						全额开票
					</el-tag>
				</template>
			</scTable>
		</el-card>
		<!--    <el-card-->
		<!--      header="收款期限"-->
		<!--      shadow="never"-->
		<!--      class="mycard mt_10"-->
		<!--    >-->
		<!--      <scTable-->
		<!--        ref="tableCollect"-->
		<!--        :data="data.collectList"-->
		<!--        :column="tablecollectConfig.columns"-->
		<!--        :height="'auto'"-->
		<!--        row-key="id"-->
		<!--        border-->
		<!--        remote-sort-->
		<!--        hide-setting-->
		<!--        hide-pagination-->
		<!--      />-->
		<!--    </el-card>-->
		<!--    <template #footer>-->
		<!--      <div class="text-center">-->
		<!--        <el-button-->
		<!--          :size="$store.state.global.uiSize"-->
		<!--          @click="visible = false"-->
		<!--        >-->
		<!--          关 闭-->
		<!--        </el-button>-->
		<!--      </div>-->
		<!--    </template>-->
		<CustomerDetail ref="refCustomerDetail" />
		<CompanyDetail ref="refCompanyDetail" />
		<GoodsDetail ref="refGoodsDetail" />
		<ContractDetail ref="refContractDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'
import CustomerDetail from "@/views/cfg/data/customer/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import ContractDetail from "@/views/erp/sale/contract/detail.vue";

export default defineComponent({
	components: {
		ContractDetail,
		CustomerDetail,
		CompanyDetail,
		GoodsDetail,
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: '60%',
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			type:"detail",
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '销售订单详情',
			//详情数据对象
			data: null,
			tablecollectConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				columns: [
					{
						label: '期限名称',
						prop: 'name',
						sortable: true,
						fixed: 'left',
						width: 150,
					},
					{
						label: '期限编码',
						prop: 'code',
						sortable: true,
						width: 120,
					},
					{
						label: '收款日期',
						prop: 'collectDate',
						sortable: true,
						width: 130,
					},
					{
						label: '收款金额',
						prop: 'collectAmount',
						sortable: true,
						width: 120,
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						sortable: true,
						width: 180,
						fixed: 'left'
					},
					{
						label: '产品编码',
						prop: 'fz_goodsCode',
						sortable: true,
						width: 120,
					},
					// {
					// 	label: '产品编号',
					// 	prop: 'code',
					// 	sortable: true,
					// 	width: 160,
					// },
					// {
					// 	label: '产品位号',
					// 	prop: 'productBitNumber',
					// 	sortable: true,
					// 	width: 160,
					// },
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						sortable: true,
						width: 120,
					},

					// {
					// 	label: '材质',
					// 	prop: 'fz_goodsMaterial',
					// 	sortable: true,
					// 	width: 170,
					// },
					{
						label: "发货仓库",
						prop: "fz_depotName",
						sortable: true,
						width: 110,
					},
					{
						label: '生产状态',
						prop: 'processStatus',
						sortable: true,
						width: 110,
					},
					//
					// {
					// 	label: '是否生成BOM',
					// 	prop: 'bomStatus',
					// 	width: 150,
					// 	sortable: true,
					// },
					{
						label: '发货状态',
						prop: 'invoiceStatus',
						width: 110,
						sortable: true,
					},

					{
						label: '主单位',
						prop: 'unit',
						width: 110,
						sortable: true,
					},

					{
						label: '数量',
						prop: 'quantity',
						width: 110,
						sortable: true,
					},

					{
						label: '辅助单位',
						prop: 'assistUnit',
						width: 110,
						sortable: true,
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: true,
					},

					{
						label: '已发数量',
						prop: 'invoiceQuantity',
						width: 120,
						sortable: true,
					},
					{
						label: '预发数量',
						prop: 'preInvoiceQuantity',
						width: 120,
						sortable: true,
					},
					{
						label: '未发数量',
						prop: 'notInvoiceQuantity',
						width: 120,
						sortable: true,
					},

					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: true,
					},
				],
			},
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.data = data
			state.visible = true
			state.fileList = []
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.saleOrder.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					//获取明细表数据
					getGoodsList(res.data.id)
					//组装文件列表
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getGoodsList = (id) => {
			if (id) {
				state.tableSonConfig.apiObj = proxy.$API.saleOrderGoods.selectOrderGoods
				state.tableSonConfig.params = {orderId: id}
				proxy.$refs.tableSon.reload({orderId: id})
			}
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
		}
		return {
			...toRefs(state),
			previewFile,
			handleDetail,
			showDialog,
			hideDialog,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

