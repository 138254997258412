<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:bef-close="false"
		:type="type"
		:width="width"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<!--label: 标题 -->
				<el-descriptions-item
					label="派工单号"
					label-align="right"
				>
					{{ data.orderNo }}
				</el-descriptions-item>

				<el-descriptions-item
					label="加工单"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refProduceProcessDetail', 'processId')"
					>
						{{ data.fz_processNo }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="加工状态"
					label-align="right"
				>
					<!--加工状态-->
					<el-tag
						v-if="data.completeType === 0"
						type="primary"
						:size="$store.state.global.uiSize"
					>
						加工中
					</el-tag>
					<el-tag
						v-if="data.completeType === 2"
						type="danger"
						:size="$store.state.global.uiSize"
					>
						异常关闭
					</el-tag>
					<el-tag
						v-if="data.completeType === 1"
						type="success"
						:size="$store.state.global.uiSize"
					>
						已完工
					</el-tag>
					<el-tag
						v-if="data.completeType === 3"
						type="info"
						:size="$store.state.global.uiSize"
					>
						已关闭
					</el-tag>
					<el-tag
						v-if="data.completeType === 4"
						type="info"
						:size="$store.state.global.uiSize"
					>
						未开始
					</el-tag>
				</el-descriptions-item>
				<el-descriptions-item
					label="工序顺序"
					label-align="right"
				>
					<el-tag
						v-if="data.proSeq === 0"
						type="info"
						:size="$store.state.global.uiSize"
					>
						无顺序
					</el-tag>
					<el-tag
						v-if="data.proSeq === 1"
						type="success"
						:size="$store.state.global.uiSize"
					>
						严格顺序
					</el-tag>
				</el-descriptions-item>

				<el-descriptions-item
					label="产品名称"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refGoodsDetail', 'goodsId')"
					>
						{{ data.fz_goodsName }}
					</el-link>
				</el-descriptions-item>

				<el-descriptions-item
					label="产品编号"
					label-align="right"
				>
					{{ data.fz_goodsCode }}
				</el-descriptions-item>

				<el-descriptions-item
					label="规格型号"
					label-align="right"
				>
					{{ data.fz_goodsSpec }}
				</el-descriptions-item>
				<el-descriptions-item
					label="生产数量"
					label-align="right"
				>
					{{ data.quantity }}
				</el-descriptions-item>
				<el-descriptions-item
					label="良品数"
					label-align="right"
				>
					{{ data.completeQty }}
				</el-descriptions-item>
				<el-descriptions-item
					label="计量单位"
					label-align="right"
				>
					{{ data.mainUnit }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remarks }}
				</el-descriptions-item>

			</el-descriptions>
		</el-card>
		<el-card
			header="工序明细"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				height="auto"
				row-key="id"
				border
				remote-sort
			>
				<template #processStatus="{scope}">
					<el-tag
						v-if="scope.row.processStatus === 0"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未开始
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 1"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						准备中
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 2"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						加工中
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 3"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已完工
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 4"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已关闭
					</el-tag>
				</template>

				<!--	是否需要质检 -->
				<template #ipqcType="{ scope }">
					<el-tag
						v-if="scope.row.ipqcType === 0"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						无需质检
					</el-tag>
					<el-tag
						v-if="scope.row.ipqcType === 1"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						需要质检
					</el-tag>
					<el-tag
						v-if="scope.row.ipqcType === 2"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						质检中
					</el-tag>
					<el-tag
						v-if="scope.row.ipqcType === 3"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已质检
					</el-tag>
				</template>

				<template #procedureName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refProcedureDetail', 'processingStepId')"
					>
						{{ scope.row.procedureName }}
					</el-link>
				</template>

			</scTable>
		</el-card>
		<CustomerDetail ref="refCustomerDetail"/>
		<CompanyDetail ref="refCompanyDetail"/>
		<GoodsDetail ref="refGoodsDetail"/>
		<ProduceProcessDetail ref="refProduceProcessDetail"/>
		<ProcedureDetail ref="refProcedureDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from "vue";
import CustomerDetail from "@/views/cfg/data/customer/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
import procedureDetail from "@/views/erp/produce/craft/procedure/detail.vue";
import ProcedureDetail from "@/views/erp/produce/craft/procedure/detail.vue";

export default defineComponent({
	components: {
		CustomerDetail,
		CompanyDetail,
		GoodsDetail,
		ProduceProcessDetail,
		procedureDetail,
		ProcedureDetail
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: "60%",
			//高度
			height: "500px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			type: "detail",
			//弹窗标题
			title: "派工单详情",
			//详情数据对象
			data: null,
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: "加工顺序",
						prop: "procSeq",
						width: 110,
						sortable: "custom",
						fixed: "left"
					},
					{
						label: "加工工序",
						prop: "procedureName",
						width: 120,
						sortable: "custom",
						fixed: "left"
					},

					{
						label: "工序状态",
						prop: "processStatus",
						width: 110,
						sortable: "custom",
					},
					{
						label: "工作组",
						prop: "workGroupName",
						width: 120,
						sortable: "custom",
					},
					{
						label: "生产人员",
						prop: "fz_managerName",
						width: 200,
						sortable: "custom",
					},
					{
						label: "派工数",
						prop: "processedQty",
						width: 100,
						sortable: "custom",
					},
					{
						label: "在加工数",
						prop: "processingQuantity",
						width: 100,
						sortable: "custom",
					},
					  {
						label: "良品数",
						prop: "finishQty",
						width: 100,
						sortable: "custom",
					  },

					// {
					// 	label: '累计接收数量',
					// 	prop: 'totalTransferredIn',
					// 	width: 140,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '全部转出',
					// 	prop: 'fullyTransferred',
					// 	width: 110,
					// 	sortable: 'custom',
					// },

					// {
					// 	label: '返工数',
					// 	prop: 'reworkQuantity',
					// 	width: 100,
					// 	sortable: 'custom',
					// },
					{
						label: "不良品数",
						prop: "scrapMatQty",
						width: 100,
						sortable: "custom",
					},
					{
						label: "预计开始时间",
						prop: "startTime",
						width: 200,
					},
					{
						label: "预计结束时间",
						prop: "endTime",
						width: 200,
					},
					{
						label: "派工号",
						prop: "workOrderNo",
						width: 200,
						sortable: "custom",
					},
					{
						label: "摘要",
						prop: "remarks",
						width: 180,
						sortable: "custom",
					},
				],

			},
		});

		//显示弹窗
		const showDialog = async (data) => {
			console.log('AAAAAAAAAA')
			console.log(data);
			state.data = data;
			state.visible = true;
			state.fileList = [];
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.produceWorkOrder.detail.get(data.id);
				if (res.code === 200) {
					state.data = res.data;
					//获取明细表数据
					getGoodsList(res.data.id);
					//组装文件列表
					getFileList(res.data.files);
				}
			}
			state.loading = false;
		};

		const getGoodsList = (id) => {
			if (id) {
				state.tableSonConfig.apiObj = proxy.$API.produceWorkOrder.selectWorkOrderDetail;
				state.tableSonConfig.params = {workOrderId: id};
				proxy.$refs.tableSon.reload({workOrderId: id});
			}
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};

		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
		};
		return {
			...toRefs(state),
			previewFile,
			handleDetail,
			showDialog,
			hideDialog,
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

