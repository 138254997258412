<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
	:bef-close="false"
	:type="type"
    :width="width"
  >
    <el-descriptions
      :title="''"
      :column="2"
      :size="$store.state.global.uiSize"
      border
      class="column2"
    >
      <el-descriptions-item
        label="机构名称"
        :label-align="labelAlign"
      >
        {{ data.name }}
      </el-descriptions-item>
      <el-descriptions-item
        label="机构类型"
        :label-align="labelAlign"
      >
        {{ data.stype }}
      </el-descriptions-item>
      <el-descriptions-item
        label="机构地址"
        :label-align="labelAlign"
      >
        {{ data.address }}
      </el-descriptions-item>
      <el-descriptions-item
        label="联系人"
        :label-align="labelAlign"
      >
        {{ data.user }}
      </el-descriptions-item>
      <el-descriptions-item
        label="联系电话"
        :label-align="labelAlign"
      >
        {{ data.tel }}
      </el-descriptions-item>
      <el-descriptions-item
        label="备注"
        :label-align="labelAlign"
      >
        {{ data.remark }}
      </el-descriptions-item>
      <el-descriptions-item
        label="操作用户"
        :label-align="labelAlign"
      >
        {{ data.fz_operateName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="操作时间"
        :label-align="labelAlign"
      >
        {{ data.addTime }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="机构简介"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        {{ data.introduction }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="附件"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        <div class="mtf_5">
          <el-tag
            v-for="(file, index) in fileList"
            :key="index"
            class="dtFile"
            @click="previewFile(file)"
          >
            <a href="javascript:">{{ file.name }}</a>
          </el-tag>
        </div>
      </el-descriptions-item>
    </el-descriptions>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '60%',
			type:"detail",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '培训机构详情',
			//详情数据对象
			data: null,
			fileList: [],
		})
		//显示弹窗
		const showDialog = async (data) => {
			state.data = data
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.trainOrganization.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}
		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}
		return {
			...toRefs(state),
			previewFile,
			showDialog,
			hideDialog,
		}
	},
})
</script>
