<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type = "type"
    :loading="loading"
    :width="width"
  >
    <div class="popPane">
      <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
      <el-descriptions
        :title="''"
        :column="2"
        :size="$store.state.global.uiSize"
        border
        class="column2"
      >
        <el-descriptions-item
          label="职位名称"
          :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>

        <el-descriptions-item
          label="职位编号"
          :label-align="labelAlign"
        >
          {{ data.code }}
        </el-descriptions-item>

        <el-descriptions-item
          label="部门"
          :label-align="labelAlign"
        >
          {{ data.fz_departmentName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="直属上级"
          :label-align="labelAlign"
        >
          {{ data.fz_managerName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="类别"
          :label-align="labelAlign"
        >
          {{ data.category }}
        </el-descriptions-item>

        <el-descriptions-item
          label="年龄上限"
          :label-align="labelAlign"
        >
          {{ data.ageLimit }}
        </el-descriptions-item>

        <el-descriptions-item
          label="职责"
          :label-align="labelAlign"
        >
          {{ data.duty }}
        </el-descriptions-item>

        <el-descriptions-item
          label="任职资格"
          :label-align="labelAlign"
        >
          {{ data.qualification }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '60%',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '职位详情',
      type:'detail',
			//详情数据对象
			data: {},
			fileList: [],
		})
		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = []
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.position.detailPosition.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}
		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}
		return {
			...toRefs(state),
			previewFile,
			showDialog,
			hideDialog,
		}
	},
})
</script>
