import config from "@/config"
import http from "@/utils/request"

export default {
	selectPlanGoods: {
		url: `${config.API_URL}/erp/produce/plan/goods/selectPlanGoods`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	sumQueryDetails: {
		url: `${config.API_URL}/erp/produce/plan/goods/sumQueryDetails`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	selectChangeDetail: {
		url: `${config.API_URL}/erp/produce/plan/goods/selectChangeDetail`,
		name: "历史记录-查询明细数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
