<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="title"
      :loading="loading"
      :width="500"
      :type="type"
      @close="close"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
    </template>
    <div class="qr-wrapper">
      <div v-for="(item, index) in data" :key="index" class="qr-container">
        <div :id="'qr-container-' + index"></div>
      </div>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <div class="dialogFooter">
        <div class="left">
          二维码尺寸：
          <el-select
              v-model="downloadSize"
              :size="$store.state.global.uiSize"
              style="width: 120px"
          >
            <el-option
                :value="100"
                label="100*100像素"
            />
            <el-option
                :value="200"
                label="200*200像素"
            />
            <el-option
                :value="500"
                label="500*500像素"
            />
          </el-select>
          <el-button
              type="primary"
              plain
              :size="$store.state.global.uiSize"
              @click="downLoadQr"
          >
            下载
          </el-button>
        </div>
        <div class="right">
          <el-button
              type="primary"
              :loading="printLoading"
              :size="$store.state.global.uiSize"
              @click="printQr"
          >
            打印二维码
          </el-button>
        </div>
      </div>
    </template>
  </sc-dialog>

</template>

<script>
import {defineComponent, toRefs, reactive, getCurrentInstance, nextTick} from 'vue'
import QRCode from 'qrcodejs2'

import {batchPrintJob, getPrinters, init} from "@/barcode/iJcPrinterSdk_third";
import {printData} from "@/barcode/combination";
import {getInstance, stopInstance} from "@/barcode/jcPrinterSdk_api_third";

export default defineComponent({
  components: {},
  emits: [],
  props: {
    path: {type: String, default: ''} // 扫码跳转路径
  },
  setup(props, {emit}) {
    const state = reactive({
      ref:'',
      type:"detail",
      downloadSize: 100, // 下载图片大小
      //弹窗对象
      refScDialog: null,
      //加载动画
      loading: true,
      // 打印按钮加载状态
      printLoading: false,
      //当前弹窗组件 dom
      refDialog: null,
      //提交加载动画
      isLoading: false,
      //全屏显示
      fullScreen: false,
      //表单label宽度
      labelWidth: 90,
      //弹窗显示属性
      visible: false,
      //弹窗宽度
      width: 500,
      //弹窗标题
      title: '已生成二维码',
      data: [],
      goods: {},
    })
    //显示弹窗
    const showDialog = async (data,title) => {
      console.log(data);
      if(title){
        state.title = title
      }
      if(data) {
        state.visible = true
        state.printLoading = false
        state.downloadSize = 100
        state.data = data
        nextTick(() => {
          state.data.forEach((item, index) => {
            // 清空容器
            const qrContainer = document.getElementById(`qr-container-${index}`)
            qrContainer.innerHTML = '';

            const text = {
              id: item.id,
              path: props.path
            }
            // 生成二维码并渲染到 div 中
            new QRCode(qrContainer, {
              text: JSON.stringify(text),
              width: 128,
              height: 128,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            })
          })
        })
        state.loading = false
      }
    }

    const downLoadQr = () => {
      if(state.data && state.data.length > 0){
        state.data.forEach((s,index)=>{
          setTimeout(() => {
            const qrContainer = document.getElementById(`qr-container-${index}`)
            const canvas = qrContainer.querySelector('canvas')

            if (canvas) {
              const originalWidth = canvas.width
              const originalHeight = canvas.height

              // 设置新的下载尺寸
              const downloadWidth = state.downloadSize
              const downloadHeight = state.downloadSize
              // 创建新的 canvas 元素
              const newCanvas = document.createElement('canvas')
              newCanvas.width = downloadWidth
              newCanvas.height = downloadHeight
              const ctx = newCanvas.getContext('2d')

              // 绘制原始 canvas 到新的 canvas 上，调整大小
              ctx.drawImage(canvas, 0, 0, originalWidth, originalHeight, 0, 0, downloadWidth, downloadHeight)

              // 获取新的图像数据 URL
              const qrCodeDataURL = newCanvas.toDataURL('image/png')
              const link = document.createElement('a')
              link.href = qrCodeDataURL
              link.download = `qrcode_${downloadWidth}x${downloadHeight}_${index + 1}.png`
              link.click()
            }
          }, 100 * index) // 添加延迟以确保每次下载不会相互干扰
        })
      }
    }
    const printQr = () => {
      if(state.data && state.data.length > 0) {

        state.printLoading = true

        //页面加载时调用
        getInstance(() => {
          console.log('打印服务连接成功');
          init();
          getPrinters();
          let board = false;
          let parameterArr = [];
          for (const s of state.data) {
            console.log(s.id)
            let printObj = {
              id: s.id,
              path: props.path
            };
            parameterArr.push(printObj)
            board = true;
          }
          if (board) {
            batchPrintJob(printData(parameterArr))
          }
          state.printLoading = false
        }, () => {
          // console.log('当前浏览器不支持打印服务');
        }, () => {
          // console.log('打印服务连接断开');
        }, () => {
          // console.log('打印连接');
        }, () => {
          // console.log('打印连接断开');
        },()=>{
          state.printLoading = false
        });
      }
    }

    const close = () => {
      stopInstance()
    }
    return {
      ...toRefs(state),
      close,
      showDialog,
      downLoadQr,
      printQr,
    }
  },
})
</script>
<style scoped lang="scss">
.qr-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  gap: 10px;               /* 间距，根据需要调整 */
  padding: 10px;           /* 容器内边距 */
}

.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* 包括内边距和边框在内的尺寸计算 */
}
</style>
