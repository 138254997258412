<template>
  <el-drawer
    v-model="drawer"
    direction="btt"
    title="审核"
    custom-class="bot-drawer"
  >
    <el-container
      direction="vertical"
    >
      <el-container
        direction="vertical"
      >
        <el-scrollbar>
          <el-form
            ref="refForm"
            :model="form"
            class="searchForm"
            label-position="left"
            label-width="80px"
            label-suffix=":"
            :rules="rules"
          >
            <el-form-item
              prop="result"
              label="审核结果"
            >
              <el-radio-group
                v-model="form.result"
                size="small"
              >
                <el-radio :label="2">
                  审批通过
                </el-radio>
                <el-radio :label="0">
                  驳回结束
                </el-radio>
                <el-radio :label="1">
                  驳回上级
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="审核意见"
            >
              <el-input
                v-model="form.opinion"
                maxlength="200"
                size="small"
                clearable
                rows="3"
                type="textarea"
              />
            </el-form-item>
          </el-form>
          <el-card
            header="签字确认"
            class="sign"
            shadow="never"
          >
            <template #header>
              <div class="flexh">
                <div class="flexgs1">
                  <h4>签字确认</h4>
                </div>
                <div class="flexgs0">
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleGenerate"
                  >
                    确认签字
                  </el-button>
                  <el-button
                    size="small"
                    @click="handleReset"
                  >
                    重签
                  </el-button>
                </div>
              </div>
            </template>
            <vue-esign
              ref="esign"
              v-model:bg-color="bgColor"
              :height="200"
              :width="cnsWidth"
              :is-crop="isCrop"
              :line-width="lineWidth"
              :line-color="lineColor"
              :is-clear-bg-color="false"
            />
          </el-card>
        </el-scrollbar>
      </el-container>
      <el-header class="footer-bar">
        <div class="flex-h full-width text-center justify-center">
          <el-button
            type="primary"
            :loading="subLoading"
            size="small"
            @click="submit"
          >
            保存
          </el-button>
        </div>
      </el-header>
    </el-container>
  </el-drawer>
</template>
<script>
import {defineComponent, getCurrentInstance, nextTick, onBeforeMount, reactive, toRefs} from "vue";
import API from "@/api";
import config from "@/config/upload";

export default defineComponent({
	name: "Exam",
	emits: ['process-callback'],
	setup(props,{ emit }) {
		const { proxy } = getCurrentInstance()
		const state = reactive({
			form: {
				result: 2,
			},
			drawer: false,
			//提交加载动画
			subLoading: false,
			//表单组件 dom
			refForm: null,
			rules: {},
			//画布背景
			bgColor: '#f7f7f7',
			//是否裁剪空白部分
			isCrop: false,
			//画笔大小
			lineWidth: 2,
			//画笔颜色
			lineColor: '#0092ff',
			//画布宽度
			cnsWidth: 0,
		})

		onBeforeMount(() => {
			const ww = window.innerWidth - 20
			state.cnsWidth = ww
		})

		//显示
		const showDrawer = (row) => {
			if(row.taskId) {
				//在此校验是否为改用户进行审批
				proxy.$API.flowTask.queryApproval.get(row.taskId).then((res) => {
					if(res.data) {
						state.form.instanceId = row.instanceId;
						state.form.taskId = row.taskId;
						state.orgForm = state.form;
						state.visible = true
						state.loading = false
					}
				})
			}
			state.drawer = true
		}

		//隐藏
		const hideDrawer = () => {
			state.drawer = false
		}

		//提交审核
		const submit = () => {
			nextTick(() => {
				state['refForm'].validate((valid) => {
					if(valid) {
						if(!state.form.files){
							proxy.$baseMessage('请签字并确认', 'error')
							return false
						}
						proxy.$baseConfirm(proxy.$TIPS.SUBMIT_,null, async ()=>{
							state.subLoading = true
							const res = await proxy.$API.flowTask.processApproval.post(state.form)
							if(res.code == 200) {
								proxy.$baseMessage(res.msg, 'success')
								emit('process-callback',res.data)
								hideDrawer()
							} else {
								proxy.$baseMessage(res.msg, 'error')
							}
							state.subLoading = false
						})
					}
				})
			})
		}

		//重置画布
		const handleReset = () => {
			delete state.form.files
			proxy.$refs.esign.reset()
		}

		//生产图片
		const handleGenerate = () => {
			proxy.$refs.esign.generate().then(res => {
				const blob = dataURLtoBlob(res)
				const file = blobToFile(blob,'签名文件.png')
				uploadImg(file)
			}).catch(err => {
				proxy.$baseMessage('请留下您的佳作!', 'error')
			})
		}

		const dataURLtoBlob = (dataurl) => {
			let arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: mime });
		}

		const blobToFile = (theBlob, fileName) => {
			theBlob.lastModifiedDate = new Date();  // 文件最后的修改日期
			theBlob.name = fileName;                // 文件名
			return new File([theBlob], fileName, {type: theBlob.type, lastModified: Date.now()});
		}

		//上传图片
		const uploadImg = async (file) => {
			const data = new FormData();
			data.append('file', file);
			if(file){
				const apiObj = proxy.$API.common.upload
				apiObj.post(data, {
				}).then(res => {
					if(res.code === 200){
						state.form.files = res.data.src
					}
				}).catch(err => {
				})
			}
		}

		return {
			...toRefs(state),
			submit,
			showDrawer,
			hideDrawer,
			handleReset,
			handleGenerate
		}
	}
})
</script>

<style lang="scss" scoped>
.el-card.sign{
	border-radius: 0;
	border-left: none;
	border-right: none;
}
.el-card:deep(.el-card__header){
	padding: 10px 10px 0 10px;
}
.el-card:deep(.el-card__body){
	padding: 10px
}
canvas{
	border: 1px solid #ddd;
}
</style>
