<template>
  <div>
    <el-table
      ref="table"
      v-loading="listLoading"
      v-bind="$attrs"
      class="edit_table"
      :data="list"
      border
      :show-summary="config.showSummary"
      :summary-method="(val) => getSummaries(val, config.summary)"
      @cell-click="handleCellCLick"
    >
      <el-table-column
        align="center"
        label="序号"
        width="55"
        fixed="left"
      >
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hideOprate"
        align="center"
        label="操作"
        show-overflow-tooltip
        width="80"
        fixed="left"
      >
        <template #default="{ row }">
          <el-button
            v-if="!hideAddBtn"
            circle
            class="iconBtn"
            plain
            :size="$store.state.global.uiSize"
            type="primary"
            @click="handleAddRow(row)"
          >
            <el-icon><el-icon-plus /></el-icon>
          </el-button>
          <el-button
            circle
            class="iconBtn"
            plain
            :size="$store.state.global.uiSize"
            type="danger"
            @click="handleDelRow(row)"
          >
            <el-icon><el-icon-minus /></el-icon>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in column"
        :key="index"
        :fixed="item.fixed"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.showOverflowTooltip"
        :sortable="item.sortable"
        :width="item.width"
        :class-name="item.disable ? 'bgGray' : ''"
      >
        <template #header="scope">
          <slot
            v-if="item.slotHeader"
            :name="'h_' + item.prop"
            :scope="scope"
            :item="item"
          />
          <span v-else>
            {{ item.label }}
          </span>
        </template>
        <template #default="scope">
          <template v-if="item.disable">
            <span class="cellSp">
              <slot
                :name="item.prop"
                :row="scope.row"
              >
                {{ scope.row[item.prop] }}
              </slot>
            </span>
          </template>
          <template v-else>
            <!--表格选择-->
            <template v-if="item.tableSelect && scope.$index === rowIndex && clickTarget === item.prop">
              <sc-table-select
                v-model="scope.row[item.prop + '_obj']"
                :api-obj="item.config.apiObj"
                :table-width="500"
                :props="item.config.props"
                @change="(val) => handleSelChange(val, item.config.field)"
              >
                <el-table-column
                  v-for="(_col, _index) in item.config.columns"
                  :key="_index"
                  :prop="_col.prop"
                  :label="_col.label"
                  :width="_col.width"
                  show-overflow-tooltip
                />
              </sc-table-select>
            </template>
            <!--编辑框-->
            <template v-if="item.edit && scope.$index === rowIndex && clickTarget === item.prop">
              <template v-if="item.edit === 'find'">
                <el-input
                  :id="item.prop + scope.$index + (config.tableKey || '')"
                  :ref="item.prop + scope.$index + '_input'"
                  v-model="scope.row[item.prop]"
                  readonly
                  :maxlength="item.maxlength"
                  @click="popSelectDetails(scope.row, item)"
                >
                  <template #suffix>
                    <el-icon
                      title="点击选择"
                      @click="popSelectDetails(scope.row, item)"
                    >
                      <el-icon-search />
                    </el-icon>
                  </template>
                </el-input>
              </template>
              <template v-else-if="item.edit === 'findedit'">
                <el-input
                  :id="item.prop + scope.$index + (config.tableKey || '')"
                  :ref="item.prop + scope.$index + '_input'"
                  v-model="scope.row[item.prop]"
                  :maxlength="item.maxlength"
                  :show-word-limit="item.showLimit"
                >
                  <template #suffix>
                    <el-icon
                      title="点击选择"
                      @click="popSelectDetails(scope.row, item)"
                    >
                      <el-icon-search />
                    </el-icon>
                  </template>
                </el-input>
              </template>
              <template v-else-if="item.edit === 'dict'">
                <dicts-query
                  v-model="scope.row[item.prop]"
                  :placement="item.placement"
                  :type-code="item.typeCode"
                />
              </template>
              <template v-else-if="item.edit === 'textarea'">
                <el-input
                  :id="item.prop + scope.$index + (config.tableKey || '')"
                  v-model="scope.row[item.prop]"
                  type="textarea"
                  :maxlength="item.maxlength"
                  :rows="item.rows"
                  :show-word-limit="item.showLimit"
                  @blur="blurEdit(item, scope.row)"
                />
              </template>
              <!--日期选择-->
              <el-date-picker
                v-else-if="item.edit=='date'"
                :id="item.prop + scope.$index + (config.tableKey || '')"
                v-model="scope.row[item.prop]"
                value-format="YYYY-MM-DD"
                clearable
                @visible-change="visibleChange"
                @blur="blurEdit(item, scope.row)"
              />
              <!--日期时间选择-->
              <el-date-picker
                v-else-if="item.edit=='datetime'"
                :id="item.prop + scope.$index + (config.tableKey || '')"
                v-model="scope.row[item.prop]"
                type="datetime"
                value-format="YYYY-MM-DD HH:mm:ss"
                clearable
                @visible-change="visibleChange"
                @blur="blurEdit(item, scope.row)"
              />
              <!--输入框-->
              <el-input
                v-else
                :id="item.prop + scope.$index + (config.tableKey || '')"
                v-model="scope.row[item.prop]"
                clearable
                :maxlength="item.maxlength"
                @input="(val) => handleInput(val, item)"
                @clear="handleClear(item, scope.row)"
                @blur="blurEdit(item, scope.row)"
              />
            </template>
            <!--地区选择 options: json数据  props: 字段映射-->
            <el-cascader
              v-else-if="item.casCader && scope.$index === rowIndex && clickTarget === item.prop"
              :id="item.prop + scope.$index + (config.tableKey || '')"
              v-model="area[item.prop + scope.$index]"
              clearable
              :options="$API.area"
              separator="-"
              placeholder="选择地区"
              :props="{
                label: 'name',
                value: 'name',
                expandTrigger: 'hover',
              }"
              style="width: 100%"
              @change="(val) => handleArea(val, item)"
            />
            <!--下拉选择-->
            <el-select
              v-else-if="item.select && scope.$index === rowIndex && clickTarget === item.prop"
              :id="item.prop + scope.$index + (config.tableKey || '')"
              v-model="scope.row[item.prop]"
              value-key="id"
              filterable
              @change="(val) => elselChange(val, scope.row, item)"
            >
              <template v-if="item.options">
                <el-option
                  v-for="(_option, _index) in item.options"
                  :key="_index"
                  :value="_option.value"
                  :label="_option.label"
                />
              </template>
              <slot
                v-else
                :name="'option_'+ item.prop"
                :item="item"
                :row="scope.row"
              />
            </el-select>
            <!--文字显示-->
            <div
              v-else
              class="cellSp"
            >
              <slot
                :name="item.prop"
                :row="scope.row"
              >
                {{ scope.row[item.prop] }}
              </slot>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	nextTick,
	getCurrentInstance,
} from 'vue'
import {resetNum, resetZnum, handleCutZero} from "@/utils/validate";
export default defineComponent( {
	name: "SyEditTableWap",
	props: {
		//配置项
		config: { type: Object, default: () => {} },
		hideOprate: { type: Boolean, default: false },
		hideAddBtn: { type: Boolean, default: false },
	},
	emits: ['pop-select-detail', 'el-select-change','blur-edit', 'add-row', 'remove-row', 'get-summary'],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			canAdd: true,
			visiblePop: false,
			//暂存地区选择
			area: {},
			//表格数据
			list: [],
			//行数索引
			rowIndex: 0,
			//单元格点击对象
			clickTarget: '',
			//表格配置项
			tableOptions: props.config,
			//加载动画
			listLoading: false,
			panelShow: false,
			//
			column: props.config.columns
		})
		//编辑表格
		//初始数据
		const initList = (bool) => {
			state.listLoading = true
			let list = []
			if (bool) {
				//修改
				const childLength = state.list.length
				const difference = state.tableOptions.initRows - childLength
				state.list.forEach((item) => {
					list.push(item)
				})
				if (difference > 0) {
					for (let i = 0; i < difference; i++) {
						list.push(Object.assign({}, state.tableOptions.blankitem))
					}
				}
			} else {
				//新增
				if(state.tableOptions != null) {
					for (let i = 0; i < state.tableOptions.initRows; i++) {
						list.push(Object.assign({}, state.tableOptions.blankitem))
					}
				}
			}
			state.list = list
			state.listLoading = false
		}

		//单元格点击
		const handleCellCLick = (row, column, cell, event) => {
			event.stopPropagation()
			state.clickTarget = ''
			let gonext = false
			const colprop = column.property
			//获取点击的行索引
			state.rowIndex = state.list.indexOf(row)
			//拼接点击的元素id
			const target = colprop + state.rowIndex + (props.config.tableKey || '')
			if (colprop === state.tableOptions.defaultCol) {
				gonext = true
			} else {
				if (row[state.tableOptions.defaultCol]) {
					gonext = true
				}
			}
			if (gonext) {
				state.clickTarget = colprop
				nextTick(() => {
					const iobj = document.getElementById(target)
					if (iobj) {
						const isRead = iobj.hasAttribute('readonly')
						iobj.focus()
						if(!isRead){
							iobj.select()
						}
						state.tableOptions.isFocus = true
					}
				})
			}
		}

		//输入框输入 val:输入的值， item: 传入的行数据
		const handleInput = (val, item, ref) => {
			//数字-负数、小数
			if (item.edit === 'number') {
				state.list[state.rowIndex][item.prop] = resetNum(val)
			}
			//正整数
			if (item.edit === 'num') {
				state.list[state.rowIndex][item.prop] = resetZnum(val)
			}
		}

		//添加空白行
		const handleAddRow = (row) => {
			if(state.canAdd){
				const index = state.list.indexOf(row)
				state.list.splice(index + 1, 0, Object.assign({}, state.tableOptions.blankitem))
				emit('add-row', index+1)
			}
		}

		//删除行
		const handleDelRow = (row) => {
			const index = state.list.indexOf(row)
			if (state.list.length > 1) {
				state.list.splice(index, 1)
			}
			emit('remove-row')
		}

		//地区选择变化 val:选择后的地区数组对象
		const handleArea = (val, item) => {
			if(val){
				//将数组对象转为字符串
				state.list[state.rowIndex][item.prop] = val.join('-')
			}else{
				//清空
				state.list[state.rowIndex][item.prop] = ''
			}
		}

		//输入框清除
		const handleClear = (item, row) => {
			//清除的输入框是第一个，则清除整行数据
			if(item.prop === state.tableOptions.defaultCol && row){
				Object.keys(row).forEach((key) => {
					row[key] = ''
				})
			}
		}

		//组件挂载完成
		onMounted(() => {
			initList()
			nextTick(() => {
				document.addEventListener('click', () => {
					if(!state.panelShow){
						state.clickTarget = ''
					}
				})
			})
		})

		const handleSelChange = (data) => {}

		//弹出选择
		const popSelectDetails = (row, item) => {
			emit('pop-select-detail', item, props.config.tableKey, row)
		}

		const querySearch = () => {}

		const handleSelect = () => {}

		const showSelGoodSimple = (ref) => {
			proxy.$refs.refSelectGoodsSimple.triggerDom = proxy.$refs[ref]
			proxy.$refs.refSelectGoodsSimple.showDialog()
		}

		//下拉框点击
		const elselChange = (val, row, item) => {
			emit('el-select-change', { row: row, col: item, val: val })
		}

		const blurEdit = (item, row) => {
			emit('blur-edit', { item: item, row: row, key: props.config.tableKey })
		}

		//合计
		const getSummaries = (param, fields) => {
			const sums = []
			const { columns, data } = param
			if (columns.length < 1 || fields.length < 1) return
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计'
					return
				}
				const values = data.map((item) => Number(item[column.property]))
				if (!values.every((value) => isNaN(value))) {
					fields.forEach((field) => {
						if (column.property === field) {
							sums[index] = `${values.reduce((prev, curr) => {
								const value = Number(curr)
								if (!isNaN(value)) {
									return prev + curr
								} else {
									return prev
								}
							}, 0)}`
							sums[index] = handleCutZero((sums[index] - 0).toFixed(3))
						}
					})
				} else {
					sums[index] = ''
				}
			})
			emit('get-summary', sums)
			return sums
		}

		const visibleChange = (bool) => {
			state.panelShow = bool
		}

		return{
			...toRefs(state),
			visibleChange,
			getSummaries,
			blurEdit,
			elselChange,
			showSelGoodSimple,
			handleSelect,
			querySearch,
			popSelectDetails,
			handleSelChange,
			initList,
			handleClear,
			handleArea,
			handleAddRow,
			handleDelRow,
			handleCellCLick,
			handleInput,
		}
	},
})
</script>
