import config from "@/config"
import http from "@/utils/request"

export default {
	processCompute: {
		url: `${config.API_URL}/erp/sharing/process/processCompute`,
		name: "物料计算",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
