import config from "@/config"
import http from "@/utils/request"
/*角色管理*/
export default {
	getList: {
		url: `${config.API_URL}/sys/base/menu/getList`,
		name: "分页菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getButtonList: {
		url: `${config.API_URL}/menu/pc/selectPowerButton`,
		name: "查询按钮",
		get: async function(data){
			return await http.get(this.url + '?menuCode=' + data);
		}
	},
	getSearch: {
		url: `${config.API_URL}/menu/pc/querySearchButton`,
		name: "搜索权限",
		get: async function(menuCode, code){
			return await http.get(this.url + '?menuCode=' + menuCode + '&buttonCode=' + code);
		}
	},
}
