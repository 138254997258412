import config from "@/config"
import http from "@/utils/request"

export default {
	queryProcureSon:{
		url: `${config.API_URL}/erp/stock/barcode/queryProcureSon`,
		name: "查询未生成条码数据",
		get: async function (data={}) {
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/stock/barcode/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	queryProcureSonStop:{
		url: `${config.API_URL}/erp/stock/barcode/queryProcureSonStop`,
		name: "查询已生成条码数据",
		get: async function (data={}) {
			return await http.post(this.url,data);
		}
	},
	getBarcode: {
		url: `${config.API_URL}/erp/stock/barcode/getBarcode`,
		name: "生成条码",
		get: async function (goodsId, menuCode, orderSonId) {
			return await http.get(this.url + "?goodsId=" + goodsId + "&menuCode=" + menuCode + "&orderSonId=" + orderSonId);
		}
	},
	updateReceiptSon:{
		url: `${config.API_URL}/erp/stock/barcode/updateReceiptSon`,
		name: "修改状态",
		get: async function (receiptSonId,number) {
			return await http.get(this.url + "?receiptSonId=" + receiptSonId + "&enterNumber=" + number);
		}
	},
	updateEnterSon:{
		url: `${config.API_URL}/erp/stock/barcode/updateEnterSon`,
		name: "修改状态",
		get: async function (receiptSonId,number) {
			return await http.get(this.url + "?receiptSonId=" + receiptSonId + "&enterNumber=" + number);
		}
	},
	queryEnterSon:{
		url: `${config.API_URL}/erp/stock/barcode/queryEnterSon`,
		name: "查询未生成条码数据",
		get: async function (data={}) {
			return await http.post(this.url,data);
		}
	}
}
