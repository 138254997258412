import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/stock/summary/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListSupplier: {
		url: `${config.API_URL}/mobile/stock/summary/getAllList`,
		name: "查询列表数据,含有供应商",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	queryNumAmount: {
		url: `${config.API_URL}/mobile/stock/summary/queryNumAmount`,
		name: "查询当前库存与平均价",
		get: async function (goodsId, depotId) {
			return await http.get(this.url + "?goodsId=" + goodsId + "&depotId=" + depotId);
		}
	},
	queryNum: {
		url: `${config.API_URL}/mobile/stock/summary/queryNum`,
		name: "查询当前库存根据长度/尺寸、紧固件",
		get: async function (goodsId, depotId, supplierId) {
			return await http.get(this.url + "?goodsId=" + goodsId + "&depotId=" + depotId + "&supplierId=" + supplierId);
		}
	},
	SelectSpreadPool: {
		url: `${config.API_URL}/mobile/stock/summary/SelectSpreadPool`,
		name: "查询库存分布汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
