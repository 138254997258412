<!--选择客户弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :bef-close="false"
    :type="type"
    custom-class="popTable"
    fullscreen
    :show-fullscreen="false"
  >
    <el-container>
      <el-main class="nopadding">
        <el-container
          class="mainPanel"
          :style="multiple && 'padding-right: 10px'"
        >
          <el-header class="tableTopBar">
            <el-input
              v-model="queryForm.keyWords"
              placeholder="搜索"
              style="width: 100%"
              clearable
              @input="handleSearch"
            />
          </el-header>
          <el-main class="nopadding bgwhite">
            <!--主数据表格-->
            <scTable
              ref="table"
              :api-obj="tableConfig.apiObj"
              :column="tableConfig.columns"
              :params="tableConfig.params"
              row-key="id"
              :checkbox="multiple"
              border
              radio-check
              highlight-current-row
              remote-sort
              remote-filter
              hide-column-setting
              hide-setting
              :pagination-layout="'total, prev, pager, next, jumper'"
              @selection-change="selectChange"
			        @select="handleSelect"
              @current-change="currentChange"
			        @select-all="handleSelectAll"
			        @row-click="firClick"
            >
              <template #name="{ scope }">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                >
                  {{ scope.row.name }}
                </el-link>
              </template>

              <template #invoiceType="{ scope }">
                <span>{{ scope.row.invoiceType === 1? '增值税专用发票' : scope.row.invoiceType ===2? '增值税普通发票' :
                  scope.row.invoiceType ===3? '增值税普通发票电子票' : '收据' }}</span>
              </template>

              <template #creditLimit="{ scope }">
                <span>{{ scope.row.creditLimit === 1? '高' : scope.row.creditLimit ===2? '中' : '低' }}</span>
              </template>

              <template #paymentMethod="{ scope }">
                <span>{{ scope.row.paymentMethod === 1? '款到发货' : scope.row.paymentMethod ===2? '发货后X天为收款期限' :
                  scope.row.paymentMethod ===3? '开票后X天为收款期限' : '每月固定日期收款' }}</span>
              </template>

              <template #presetPrice="{ scope }">
                <span>{{ scope.row.presetPrice === 0? '参考销售价' : scope.row.presetPrice ===1? '一级销售价' : scope.row.presetPrice ===2? '二级销售价'
                  : scope.row.presetPrice === 3? '三级销售价' : scope.row.presetPrice ===4? '四级销售价' : scope.row.presetPrice ===5? '五级销售价'
                    : scope.row.presetPrice === 6? '六级销售价' : scope.row.presetPrice ===7? '七级销售价' : scope.row.presetPrice ===8? '八级级销售价'
                      : '九级销售价' }}</span>
              </template>

              <template #commissionType="{ scope }">
                <span>{{ scope.row.commissionType === 1? '按单提成' : scope.row.commissionType ===2? '按成交金额提成' : '按利润提成' }}</span>
              </template>

              <template #intentionLevel="{ scope }">
                <span>{{ scope.row.intentionLevel ===1? '一级' : scope.row.intentionLevel ===2? '二级' : scope.row.intentionLevel === 3? '三级' :
                  scope.row.intentionLevel ===4? '四级' : scope.row.intentionLevel ===5? '五级' : scope.row.intentionLevel === 6? '六级' :
                    scope.row.intentionLevel ===7? '七级' : scope.row.intentionLevel ===8? '八级' : '九级' }}</span>
              </template>

              <template #flag="{ scope }">
                <el-tag
                  v-if="scope.row.flag === 1"
                  type="success"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  启用
                </el-tag>
                <el-tag
                  v-if="scope.row.flag === 0"
                  type="danger"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  停用
                </el-tag>
              </template>
            </scTable>
          </el-main>
        </el-container>
        <!--已选数据-->
        <!--          <pane-->
        <!--            v-if="multiple"-->
        <!--            :size="paneSize1"-->
        <!--          >-->
        <!--            <el-container :style="multiple && 'padding-left: 10px'">-->
        <!--              <el-header class="tableTopBar">-->
        <!--                <div class="left-panel">-->
        <!--                  <el-link-->
        <!--                    type="primary"-->
        <!--                    :disabled="isDelSel"-->
        <!--                    @click="delSelData"-->
        <!--                  >-->
        <!--                    删除-->
        <!--                  </el-link>-->
        <!--                  <el-popconfirm-->
        <!--                    v-if="selData.length > 0"-->
        <!--                    :title="'确定清空已选择的数据?'"-->
        <!--                    confirm-button-text="确定"-->
        <!--                    cancel-button-text="取消"-->
        <!--                    @confirm="clearSelections"-->
        <!--                  >-->
        <!--                    <template #reference>-->
        <!--                      <el-link type="primary">-->
        <!--                        清空-->
        <!--                      </el-link>-->
        <!--                    </template>-->
        <!--                  </el-popconfirm>-->
        <!--                </div>-->
        <!--                <div class="right-panel">-->
        <!--                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>-->
        <!--                </div>-->
        <!--              </el-header>-->
        <!--              <el-main class="nopadding">-->
        <!--                <scTable-->
        <!--                  ref="tableSel"-->
        <!--                  :data="selData"-->
        <!--                  :column="selColumns"-->
        <!--                  row-key="id"-->
        <!--                  checkbox-->
        <!--                  border-->
        <!--                  hide-pagination-->
        <!--                  hide-do-->
        <!--                  @selection-change="tableSelselectChange"-->
        <!--                />-->
        <!--              </el-main>-->
        <!--            </el-container>-->
        <!--          </pane>-->
      </el-main>
    </el-container>
    <template #footer>
      <div class="text-center">
        <el-button
          :size="$store.state.global.uiSize"
          type="primary"
          :disabled="isConfirm"
          @click="handleConfirm"
        >
          确定
        </el-button>
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关闭-->
<!--        </el-button>-->
      </div>
    </template>
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs, watch,onMounted} from 'vue'
import Detail from '@/views/cfg/data/customer/detail'

export default defineComponent ({
	name: "SelectCustomer",
	components: {  Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择客户' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      type:"detail",
			asyncAll: props.asyncAllData,
			isDelSel: true,
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '姓名',
					prop: 'name',
					width: 150,
					sortable: true,
				},
			],
			keyWords: "",
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			tableConfig: {
				apiObj: proxy.$API.customer.getCustomerList,
				params: {},
				columns: [
					{
						label: '客户名称',
						prop: 'name',
						width: 120,
						sortable: true,
					},
					{
						label: '客户编码',
						prop: 'number',
						width: 120,
						sortable: true,
					},
					// {
					// 	label: '统一社会信用代码',
					// 	prop: 'code',
					// 	width: 190,
					// 	sortable: true,
					// },
					// {
					// 	label: '销售负责人',
					// 	prop: 'fz_saleDirectorName',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '所属集团',
					// 	prop: 'fz_groupName',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '公司规模',
					// 	prop: 'scale',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '所在国家',
					// 	prop: 'country',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '所在地区',
					// 	prop: 'area',
					// 	width: 160,
					// 	sortable: true,
					// },
					// {
					// 	label: '详细地址',
					// 	prop: 'address',
					// 	width: 160,
					// 	sortable: true,
					// },
					// {
					// 	label: '联系手机',
					// 	prop: 'phone',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '联系电话',
					// 	prop: 'tel',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '传真',
					// 	prop: 'fax',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '邮政编码',
					// 	prop: 'zipCode',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '电子邮箱',
					// 	prop: 'email',
					// 	width: 140,
					// 	sortable: true,
					// },
					// {
					// 	label: '网址',
					// 	prop: 'website',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: 'QQ',
					// 	prop: 'qq',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '微信',
					// 	prop: 'weChat',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '旺旺',
					// 	prop: 'wangwang',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '法定代表人',
					// 	prop: 'corporation',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '委托代理人',
					// 	prop: 'entrust',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '经营范围',
					// 	prop: 'businessScope',
					// 	width: 150,
					// 	sortable: true,
					// },
					// {
					// 	label: '企业简介',
					// 	prop: 'profile',
					// 	width: 150,
					// 	sortable: true,
					// },
					// {
					// 	label: '备注',
					// 	prop: 'remark',
					// 	width: 150,
					// 	sortable: true,
					// },
					// {
					// 	label: '共享人',
					// 	prop: 'fz_shareUserName',
					// 	width: 160,
					// 	sortable: true,
					// },
					// {
					// 	label: '开户行',
					// 	prop: 'bank',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '账户名称',
					// 	prop: 'accountName',
					// 	width: 130,
					// },
					// {
					// 	label: '银行账号',
					// 	prop: 'accountNumber',
					// 	width: 150,
					// 	sortable: true,
					// },
					// {
					// 	label: '联行号',
					// 	prop: 'bankNo',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '许可证号',
					// 	prop: 'licenseKey',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '税号',
					// 	prop: 'taxCode',
					// 	width: 130,
					// 	sortable: true,
					// },
					// {
					// 	label: '税率(%)',
					// 	prop: 'taxRate',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '发票类型',
					// 	prop: 'invoiceType',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '交往频率超期预警【天】',
					// 	prop: 'frequency',
					// 	width: 220,
					// 	sortable: true,
					// },
					// {
					// 	label: '信用额度',
					// 	prop: 'creditLimit',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '欠款额度【元】',
					// 	prop: 'amountOwed',
					// 	width: 150,
					// 	sortable: true,
					// },
					// {
					// 	label: '客户欠款',
					// 	prop: 'oweAmount',
					// 	width: 160,
					// 	sortable: true,
					// },
					// {
					// 	label: '预收金额',
					// 	prop: 'advanceCollect',
					// 	width: 160,
					// 	sortable: true,
					// },
					// {
					// 	label: '超期无购买提醒【天】',
					// 	prop: 'noPurchase',
					// 	width: 200,
					// 	sortable: true,
					// },
					// {
					// 	label: '收款方式设置',
					// 	prop: 'paymentMethod',
					// 	width: 140,
					// 	sortable: true,
					// },
					// {
					// 	label: '收款方式属性',
					// 	prop: 'paymentAttribute',
					// 	width: 140,
					// 	sortable: true,
					// },
					// {
					// 	label: '客户来源',
					// 	prop: 'customerSource',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '客户类型',
					// 	prop: 'customerType',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '行业属性',
					// 	prop: 'industry',
					// 	width: 120,
					// 	sortable: true,
					// },
					// {
					// 	label: '企业性质',
					// 	prop: 'enterpriseNature',
					// 	width: 110,
					// 	sortable: true,
					// },
					// {
					// 	label: '销售方式',
					// 	prop: 'saleMethod',
					// 	width: 110,
					// 	sortable: true,
					// },
					// {
					// 	label: '预设售价',
					// 	prop: 'presetPrice',
					// 	width: 110,
					// 	sortable: true,
					// },
					// {
					// 	label: '提成类型',
					// 	prop: 'commissionType',
					// 	width: 110,
					// 	sortable: true,
					// },
					// {
					// 	label: '意向等级',
					// 	prop: 'intentionLevel',
					// 	width: 110,
					// 	sortable: true,
					// },
					// {
					// 	label: '状态',
					// 	prop: 'flag',
					// 	width: 80,
					// 	sortable: true,
					// },
					// {
					// 	label: '操作用户',
					// 	prop: 'fz_operateName',
					// 	width: 110,
					// 	sortable: true,
					// },
					// {
					// 	label: '操作时间',
					// 	prop: 'addTime',
					// 	width: 210,
					// 	sortable: true,
					// },
				],
			},
		})

		//显示弹窗
		const showDialog = (param) => {
			state.visible = true
			state.loading = false
			if(param){
				state.tableConfig.params = param
				state.params = Object.assign({}, param)
			}
			// getShowAdd()
			// getShowEdit()
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_CUSTOMER,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_CUSTOMER,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		// const dblClick = (row) => {
		// 	if(row && !props.multiple){
		// 		//传入选中行和赋值的字段
		// 		emit('set-data', row)
		// 		hideDialog()
		// 	}
		// 	if(props.multiple){
		// 		//添加已选数据
		// 		setSelData(row)
		// 	}
		// }


		//行单击
		const firClick = (row) => {
			// //添加已选数据
			// setSelData(row)
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			// 单选
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}
onMounted(()=>{
  state.visible=false
})
		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			//state.excelCondition = data
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}


		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false)
			if (data && index === -1) {
				state.selData.push(data)


			} else {
				state.selData = state.selData.filter(item => item.id !== data.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false

			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()

		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
			// emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		/*------------------------按钮操作start-------------------------------*/
		//弹窗新增编辑
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}
		/*------------------------按钮操作end-------------------------------*/

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}

		//全选
		const handleSelectAll = (selection) => {
			if(selection && selection.length > 0){
				selection.forEach((item) => {
					setSelData(item)
				})
			}else{
				state.selData = []
			}
		}


		return{
			...toRefs(state),
			handleDetail,
			tableSelselectChange,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			firClick,
			hideDialog,
			handleSelect,
			handleSelectAll
		}
	},
})
</script>
