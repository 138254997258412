import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnter/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnter/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnter/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnter/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnter/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	enterStock: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnter/enterStock`,
		name:"入库",
		get: async function (ids) {
			return await http.get(this.url + "?ids=" + ids);
		}
	},
	getOtherEnterDetailList: {
		url: `${config.API_URL}/mobile/stock/stockOtherEnterDetail/getList`,
		name: "根据其他入库单查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	otherEnterDetail:{
		url: `${config.API_URL}/mobile/stock/stockOtherEnterDetail/detail`,
		name:"根据其他入库单查询物品明细",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},

}
