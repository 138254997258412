import config from "@/config"
import http from "@/utils/request"
export default {
	getDepotList: {
		url: `${config.API_URL}/cfg/data/depot/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/mobile/cfg/data/depot/getAllList`,
		name: "所有数据",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/data/depot/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	delDepot: {
		url: `${config.API_URL}/cfg/data/depot/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insertDepot: {
		url: `${config.API_URL}/cfg/data/depot/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	updateDepot: {
		url: `${config.API_URL}/cfg/data/depot/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detailDepot: {
		url: `${config.API_URL}/cfg/data/depot/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	updateFlagDepot0: {
		url: `${config.API_URL}/cfg/data/depot/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagDepot1: {
		url: `${config.API_URL}/cfg/data/depot/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
