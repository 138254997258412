import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/oa/task/taskSchedule/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delete: {
		url: `${config.API_URL}/oa/task/taskSchedule/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/oa/task/taskSchedule/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/oa/task/taskSchedule/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	detail: {
		url: `${config.API_URL}/oa/task/taskSchedule/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	updateStatus: {
		url: `${config.API_URL}/oa/task/taskSchedule/updateStatus`,
		name: "修改执行状态",
		get: async function (ids, executionStatus) {
			return await http.get(this.url + '?ids=' + ids + '&executionStatus=' + executionStatus);
		}
	},

	submitOpinion: {
		url: `${config.API_URL}/oa/task/taskSchedule/submitOpinion`,
		name: "提交反馈",
		get: async function (id, executionStatus, opinion) {
			return await http.get(this.url + '?id=' + id + '&executionStatus=' + executionStatus
				+ '&opinion=' + opinion);
		}
	},

}
