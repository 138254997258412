import config from "@/config"
import http from "@/utils/request"

export default {
	saleProfit: {
		url: `${config.API_URL}/fms/report/statistics/saleProfit`,
		name: "利润表 - 销售利润",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	expenses: {
		url: `${config.API_URL}/fms/report/statistics/expenses`,
		name: "利润表 - 费用支出",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	otherIncome: {
		url: `${config.API_URL}/fms/report/statistics/otherIncome`,
		name: "利润表 - 其他收入",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	saleIncome: {
		url: `${config.API_URL}/fms/report/statistics/saleIncome`,
		name: "月报表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	expensesCensus: {
		url: `${config.API_URL}/fms/report/statistics/expensesCensus`,
		name: "费用科目统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	bankFlow: {
		url: `${config.API_URL}/fms/report/statistics/bankFlow`,
		name: "银行流水账",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
