<template>
  <!--选择用户-->
  <el-main
    class="nopadding"
  >
    <splitpanes @resized="handleResized">
      <pane
        v-if="leftShow"
        min-size="10"
        max-size="60"
        :size="paneSize"
      >
        <el-container>
          <el-header class="clstop">
            <el-button
              :size="$store.state.global.uiSize"
              :class="curCls === 1 ? 'active' : ''"
              @click="handleClsClick(1)"
            >
              部门
            </el-button>
            <el-button
              :size="$store.state.global.uiSize"
              :class="curCls === 2 ? 'active' : ''"
              @click="handleClsClick(2)"
            >
              角色
            </el-button>
          </el-header>
          <el-main class="nopadding">
            <template v-if="curCls === 1">
              <!--树结构组件-->
				<SyDepartmentDir
					:api-obj="$API.department.getList"
					add-top
					hideIcon
					hideTopAddBtn
					placeholder="输入部门名称搜索"
					@fetch-data-node="(val) => fetchDataNode(val, 1)"
				/>
            </template>
            <template v-if="curCls === 2">
              <!--树结构组件-->
              <sy-tree
                :api-obj="$API.role.getList"
                :params="{ pageNum: 1, pageSize: 1000 }"
                hide-icon
                :is-edit="false"
                @fetch-data-node="(val) => fetchDataNode(val, 2)"
              />
            </template>
          </el-main>
        </el-container>
      </pane>
      <pane>
        <!--公用切换按钮，点击切换显示左侧栏
		text:显示文字
		opened:绑定左侧栏显示状态
		show-left:回调函数
		-->
        <sy-switch-btn
          :text="switchText"
          :opened="leftShow"
          @show-left="handleShowLeft"
        />
        <el-container class="mainPanel sw">
          <el-header class="tableTopBar">
            <div class="fullWidth">
              <el-form size="small">
                <div class="disflex">
                  <div class="flexgs1">
                    <el-input
                      v-model="queryForm.name"
                      placeholder="查询用户"
                      clearable
                      @clear="handleSearch"
                    />
                  </div>
                  <div class="flexgs0 ml4">
                    <el-button
                      type="primary"
                      @click="handleSearch"
                    >
                      查询
                    </el-button>
                  </div>
                </div>
              </el-form>
            </div>
          </el-header>
          <el-main class="nopadding bgwhite">
            <scTable
              ref="table"
              :api-obj="tableConfig.apiObj"
              :column="tableConfig.columns"
              row-key="id"
              :checkbox="multiple"
              border
              highlight-current-row
              remote-sort
              remote-filter
              hide-column-setting
              hide-setting
              :pagination-layout="'total, prev, pager, next, jumper'"
              @selection-change="selectChange"
              @current-change="currentChange"
            />
          </el-main>
        </el-container>
      </pane>
    </splitpanes>
  </el-main>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
} from 'vue'
import SyDepartmentDir from "@/components/syDepartmentDir/index.vue";
export default defineComponent ({
	name: "UserListUsers",
	components: {SyDepartmentDir},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
	},
	emits: ['sel-change'],
	setup(props,{ emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			switchText: '部门',
			paneSize: 30,
			curRow: {},
			curCls: 1,
			//控制左侧显隐
			leftShow: true,
			queryForm: {},
			selections: [],
			tableConfig: {
				apiObj: proxy.$API.user.getUserList,
				props: {
					label: 'name',
					value: 'id',
					keyword: "name"
				},
				params: {},
				columns: [
					{
						label: '姓名',
						prop: 'name',
					},
				]
			},
		})

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//切换显示左侧栏内容
		const handleClsClick = (num) => {
			state.curCls = num
			//刷新表格
			proxy.$refs.table.reload()
			//清除查询条件
			state.queryForm = {}
			if(num === 1){
				state.switchText = '部门'
			}else{
				state.switchText = '角色'
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
			if(state.selections.length > 0){
				emit('sel-change', false)
			}else{
				emit('sel-change', true)
			}
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(!props.multiple){
				if(state.curRow){
					emit('sel-change', false)
				}else{
					emit('sel-change', true)
				}
			}
		}

		//用户搜索
		const handleSearch = () => {
			proxy.$refs.table.reload(state.queryForm)
		}

		//左侧树节点点击
		const fetchDataNode = (data, type) => {
			let params = {}
			if(type === 1){ //部门
				if(data.id !== -1){
					params = { departmentId: data.id }
				}
			}else if(type === 2){ //角色
				params = { roleId: data.id }
			}
			if(proxy.$refs.table){
				proxy.$refs.table.reload(params)
			}
		}

		return{
			...toRefs(state),
			fetchDataNode,
			handleSearch,
			handleResized,
			handleClsClick,
			handleShowLeft,
			selectChange,
			currentChange
		}
	}
})
</script>
