<template>
  <sc-dialog
    ref="dialogRef"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="岗位名称"
        >
          <el-input
            v-model="form.name"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="岗位编码"
        >
          <el-input
            v-model="form.code"
            clearable
            maxlength="32"
            placeholder="请输入"
            @blur="handleCodeBlur"
          />
        </el-form-item>
        <el-form-item
          label="所属部门"
          prop="fz_departmentName"
        >
				<div class="disflex fullWidth sc-table-select--append">
					<div class="flexgs1">
						<sc-table-select
							v-model="form.fz_departmentName"
							customerValue
							fullWidth
              hide-pagination
							:api-obj="$API.department.getListByKeyword"
							:params="{
                        flag: 1,parentId:0
                      }"
							:height="426"
							:props="{ label: 'name', value: 'id', keyword: 'name' }"
							@change="(val) => handleSelChange(val, ['departmentId','fz_departmentName'])"
							@clear="inputClear('department')"
						>
							<el-table-column
								prop="name"
								label="部门名称"
								width="300"
							/>
							<el-table-column
								prop="code"
								label="部门代码"
								width="150"
							/>
				<template #append>
					<el-icon
						@click="popSelect('refDepartmentIput',['departmentId','fz_departmentName'], 1, 'refSelectDepartment')"
					>
						<el-icon-search />
					</el-icon>
				</template>
						</sc-table-select>
					</div>
				</div>
        </el-form-item>
        <el-form-item
          prop="fz_managerName"
          label="直属上级"
        >

			  <div class="disflex fullWidth sc-table-select--append">
				  <div class="flexgs1">
					  <sc-table-select
						  v-model="form.fz_managerName"
						  customerValue
						  fullWidth
						  :api-obj="$API.user.getListByKeyword"
						  :params="{
                        flag: 1,
                      }"
						  :height="426"
						  :props="{ label: 'name', value: 'id', keyword: 'name' }"
						  @change="(val) => handleSelChange(val, ['managerId','fz_managerName'])"
						  @clear="inputClear('manager')"
					  >
						  <el-table-column
							  prop="name"
							  label="名称"
							  width="150"
						  />
						  <el-table-column
							  prop="fz_departmentName"
							  label="部门"
							  width="300"
						  />
						  <el-table-column
							  prop="code"
							  label="工号"
							  width="150"
						  />
            <template #append>
              <el-icon @click="popSelect('refManagerIput',['managerId','fz_managerName'], 1, 'refSelectUser')">
                <el-icon-search />
              </el-icon>
            </template>
					  </sc-table-select>
				  </div>
			  </div>
        </el-form-item>
        <el-form-item
          prop="category"
          label="岗位类别"
        >
          <el-select
            v-model="form.category"
            clearable
            placeholder="请选择"
          >
            <el-option
              label="职能支持"
              value="职能支持"
            />
            <el-option
              label="决策/经营"
              value="决策/经营"
            />
            <el-option
              label="研发/技术"
              value="研发/技术"
            />
            <el-option
              label="营销/市场"
              value="营销/市场"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="ageLimit"
          label="年龄上限"
        >
          <el-input
            v-model="form.ageLimit"
            clearable
            placeholder="请输入"
            maxlength="2"
          />
        </el-form-item>
        <el-form-item
          prop="duty"
          label="岗位职责"
        >
          <el-input
            v-model="form.duty"
            clearable
            rows="3"
            type="textarea"
            show-word-limit
            maxlength="1020"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="qualification"
          label="任职资格"
        >
          <el-input
            v-model="form.qualification"
            clearable
            show-word-limit
            maxlength="1020"
            type="textarea"
            rows="1"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            clearable
            type="textarea"
            maxlength="255"
            show-word-limit
            rows="1"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="files"
          label="附件"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'多选附件'"
            :accept="'*'"
            :is-file="true"
            :list-type="'text'"
            :limit="10"
          >
			  <el-button :size="$store.state.global.uiSize">
				  选择文件
			  </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-popconfirm
          :title="$TIPS.SAVE"
          :visible="visibles"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              :loading="subLoading"
              type="primary"
              :size="$store.state.global.uiSize"
              @click="valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
	  <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
<!--	  <SyCopyDialogFtable-->
<!--		  ref="copyDialog"-->
<!--		  :config="copyConfig"-->
<!--		  @set-form="setFormData"-->
<!--	  >-->
<!--	  </SyCopyDialogFtable>-->
    <!--选择部门-->
    <SelectDepartment
      ref="refSelectDepartment"
      @set-data="setSelectData"
    />
    <!--选择用户-->
    <SelectUser
      ref="refSelectUser"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs,} from 'vue'
import {resetNum, resetZnum} from "@/utils/validate";

export default defineComponent({
	name: "SelectPositionEdit",
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const state = reactive({
			triggerDom: {},
			//加载动画
			loading: true,
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			visibles: false,
			codeMsg: '',
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			cacheFields: {},
			rules: {
				name: [{required: true, trigger: 'blur', message: '必填'}],
				code: [{validator: validateCode}],
				department: [{required: true, trigger: 'blur', message: '必填'}],
				fz_departmentName: [{required: true, trigger: 'blur', message: '必填'}],
			}
		})
		//显示弹窗
		const showDialog = async (row, type) => {
			state.visible = true
			if(type === 1){ //新增
				state.title="新增"
				if(row){
					state.form = Object.assign({}, row)
				}
				state.orgForm = Object.assign({}, state.form)
			}else{
				state.title="编辑"
				await getDetailData(row)
			}
			state.loading = false
		}
		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.position.detailPosition.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
				state.cacheFields = Object.assign({}, res.data)
			}
		}

		//只能输入正整数
		const handleNumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetZnum(val)
			}
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.position.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state.formRef.validateField('code').then(result => {
						// 处理验证结果
					}).catch(error => {
						// 处理验证错误
					});
				}
			}
		}

		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		const handleConfirm = () => {
      save()
			state.visibles = false
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate(async(valid) => {
				if (valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, async () => {
						state.subLoading = true
						let res
						if (state.form.id) { //修改
							res = await proxy.$API.position.updatePosition.put(state.form)
						} else { //新增
							res = await proxy.$API.position.insertPosition.post(state.form)
						}
						if (res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
              state.visibles = false
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
              state.visibles = false
						}
						state.subLoading = false
					// })
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if (!isClose) {
				state.form = Object.assign({}, orgForm)
			}
		}

		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}
		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			if (data.fz_departmentName && data.departmentId) {
				state.form['department'] = {
					name: data.fz_departmentName,
					id: data.departmentId
				}
			}
			if (data.fz_managerName && data.managerId) {
				state.form['manager'] = {
					name: data.fz_managerName,
					id: data.managerId
				}
			}
			//删除id
			delete state.form.id
		}
		const handleNumInput = (val, prop) => {
			if (val) {
				state.form[prop] = resetNum(val)
			}
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}
		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			if(field != 'shareUser'){
				state.form[field + 'Id'] = ''
			}else{
				state.form[field] = ''
			}
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}
		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data, field) => {
			if (data.length > -1) { //多选
				let ids
				if (data.length > 0) {
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				} else {
					ids = ''
				}
				state.form[field] = ids
			} else { //单选
				if (data && data.id) {
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					state.form[field[0]] = data.id
				}
			}
		}
    const valiForm = () => {
      nextTick(()=>{
        state.formRef.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		return {
			...toRefs(state),
			setSelectData,
			inputClear,
			inputBlur,
			popSelect,
			handleNumInput,
			setFormData,
			copyData,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			handleNumIpt,
			handleCodeBlur,
      valiForm,
			handleSelChange
		};
	},
})
</script>

<style scoped>

</style>
