import config from "@/config"
import http from "@/utils/request"

export default {
	payable: {
		url: `${config.API_URL}/fms/buy/statistics/payable`,
		name: "应付查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	purPaid: {
		url: `${config.API_URL}/fms/buy/statistics/purPaid`,
		name: "已付查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	relationCount: {
		url: `${config.API_URL}/fms/buy/statistics/relationCount`,
		name: "关联费用统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	paymentPeriod: {
		url: `${config.API_URL}/fms/buy/statistics/paymentPeriod`,
		name: "付款期限表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	overduePayable: {
		url: `${config.API_URL}/fms/buy/statistics/overduePayable`,
		name: "超期应付款",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
