<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="dialogRef"
		v-model="visible"
		:title="title"
		:loading="loading"
		:width="width"
		type="detail"
		:before-close="beforeClose"
		@reset="reset"
	>
		<el-card
			shadow="never"
			class="mycard mt_10"
		>
			<template #header>
			</template>
			<SyEditTable
				ref="refSonDetail"
				:config="configSonDetail"
				:form="dataList"
				hideOprate
				:is-index="false"
				@blur-edit="blurEdit"
			>
				<template #orderNo="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refDetail')"
					>
						{{ scope.row.orderNo }}
					</el-link>
				</template>
				<template #planType="{ scope }">
					<span v-if="scope.row.planType === 1">备货式生产</span>
					<span v-if="scope.row.planType === 2">订单式生产</span>
				</template>
				<template #fz_saleOrderNo="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refSaleOrderDetail', 'saleOrderId')"
					>
						{{ scope.row.fz_saleOrderNo }}
					</el-link>
				</template>
				<template #fz_customerName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refCustomerDetail', 'customerId')"
					>
						{{ scope.row.fz_customerName }}
					</el-link>
				</template>
				<template #material="{ scope }">
					<span v-if="scope.row.material === 1">不拆分</span>
					<span v-if="scope.row.material === 2">拆分到一级</span>
					<span v-if="scope.row.material === 3">拆分到最底层</span>
				</template>
				<template #auditStatus="{ scope }">
					<el-tag
						v-if="scope.row.auditStatus === 0"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未提交
					</el-tag>
					<el-tag
						v-if="scope.row.auditStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						审核中
					</el-tag>
					<el-tag
						v-if="scope.row.auditStatus === 2"
						type="danger"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已驳回
					</el-tag>
					<el-tag
						v-if="scope.row.auditStatus === 3"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						审核通过
					</el-tag>
					<el-tag
						v-if="scope.row.auditStatus === 4"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未确认
					</el-tag>
					<el-tag
						v-if="scope.row.auditStatus === 5"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已确认
					</el-tag>
				</template>

			</SyEditTable>
		</el-card>
<!--		</template>-->
		<ProducePlanDetail ref="refDetail"/>
	</sc-dialog>
</template>

<script>

import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs} from 'vue'
import {handleCutZero} from "@/utils/validate";
import ProducePlanDetail from '@/views/erp/produce/plan/detail.vue'
export default defineComponent({
	emits: ['fetch-data'],
	components: {ProducePlanDetail},
	setup(prop,{emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '30%',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '生产计划',
			//详情数据对象
			form: {},
			dataList:[],
			orgForm: {},
			//现金流项目数据
			cashFlowList: [],
			//定义本表的ref
			refSonDetail: null,
			configSonDetail: {
				columns: [
					{
						label: '计划单号',
						prop: 'orderNo',
						width: 200,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '生产类型',
						prop: 'planType',
						width: 140,
						sortable: 'custom',
					},
				],
			},
		})

		const showDialog = async (row,type) => {
			console.log(row)
			state.visible = true
			// state.form = data
			if(type && type === 'order'){
				getSonLists(row)
			}else {
				getSonList(row)
			}
			state.loading = false
		}

		//获取子集
		const getSonList = (data) => {
			nextTick(async()=>{
				let res = await proxy.$API.produceMrp.showPlan.get(data.applyId,data.goodsId);
				console.log(res)
				if(res.code === 200){
					proxy.$refs.refSonDetail.list = res.data
				}
			})

		}
		const getSonLists = (data) => {
			nextTick(async()=>{
				let res = await proxy.$API.produceMrp.showProducePlan.get(data.mrpPurDocId);
				if(res.code === 200){
					proxy.$refs.refSonDetail.list = [res.data]
				}
			})

		}

		//编辑框失去焦点事件
		const blurEdit = (data) => {
			if(data && data.row && data.item){
				if(data.item.prop == 'inflow' || data.item.prop == 'outflow'){
					if(data.row['inflow'] && data.row['outflow']){
						if(data.item.prop == 'inflow'){
							data.row['outflow'] = 0
						}else {
							data.row['inflow'] = 0
						}
					}
				}
			}
		}

		const setValue = (val,field) => {

		}

		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		const handleConfirm = () => {
			hideDialog()
		}
		const hideDialog = () => {
			state.visible = false
			state.__isBroad = false
		}
		//确定按钮
		const save = () => {
			const sonDetail = proxy.$refs.refSonDetail
			let num = 0
			sonDetail.list.forEach(item=>{
				num = Number(item.quantity) + Number(num)
			})
			if(Number(num) > Number(state.form.quantity)){
				proxy.$baseMessage('申请数量之和不能大于分配数量', 'error')
				return
			}
			emit('suggestionPop ', sonDetail.list)
			//返回列表页
			hideDialog()
		}
		//重置表单
		const reset = () => {
			//重置子表数据
			state.refSonDetail.list = []
			// 重置之后 子表的表格还在
			state.refSonDetail.initList()
		}
		const handleList = async (row) => {
			console.log(row)
			// if (row && row.id) {
			//   const res = await proxy.$API.produceMrpDocument.purchase.get(row.id)
			//   if(res.code === 200 && res.data){
			// 	  proxy.$refs.refIssuedData.showDialog(res.data,'purchase')
			//   }else if(res.code === 500){
			// 	  proxy.$baseMessage(res.msg,'error')
			//   }
			// }
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}
		return {
			...toRefs(state),
			handleDetail,
			handleList,
			showDialog,
			hideDialog,
			blurEdit,
			setValue,
			beforeClose,
			handleConfirm,
			reset,
			save,
		}
	}
})
</script>

<style scoped>
:deep(.el-select){
	width: 100%;
}
</style>
