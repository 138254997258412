<template>
  <el-container class="mainPanel">
    <el-header class="tableTopBar">
      <div class="fullWidth">
        <el-form size="small">
          <div class="disflex">
            <div class="flexgs1">
              <el-input
                v-model="queryForm.name"
                placeholder="角色查询"
                clearable
                @clear="handleSearch"
              />
            </div>
            <div class="flexgs0 ml4">
              <el-button
                type="primary"
                @click="handleSearch"
              >
                查询
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
    </el-header>
    <el-main class="nopadding bgwhite">
      <scTable
        ref="table"
        :api-obj="tableConfig.apiObj"
        :column="tableConfig.columns"
        row-key="id"
        checkbox
        border
        highlight-current-row
        remote-sort
        remote-filter
        hide-column-setting
        hide-setting
        :pagination-layout="'total, prev, pager, next, jumper'"
        @selection-change="selectChange"
        @current-change="currentChange"
      />
    </el-main>
  </el-container>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
} from 'vue'
export default defineComponent ({
	name: "UserListRoles",
	emits: ['sel-change'],
	setup(props,{emit}){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			paneSize: 30,
			curCls: 1,
			//控制左侧显隐
			leftShow: true,
			curRow: {},
			queryForm: {},
			selections: [],
			tableConfig: {
				apiObj: proxy.$API.role.getList,
				tableWidth: 400,
				props: {
					label: 'name',
					value: 'id',
					keyword: "name"
				},
				params: {},
				columns: [
					{
						label: '角色',
						prop: 'name',
						showOverflowTooltip: true,
					},
				]
			},
		})

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//切换显示左侧栏内容
		const handleClsClick = (num) => {
			state.curCls = num
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
			if(state.selections.length > 0){
				emit('sel-change', false)
			}else{
				emit('sel-change', true)
			}
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
		}

		//用户搜索
		const handleSearch = () => {
			proxy.$refs.table.reload(state.queryForm)
		}

		//选用户节点点击
		const fetchDataNode = (data) => {
			proxy.$refs.table.reload()
		}

		return{
			...toRefs(state),
			fetchDataNode,
			handleSearch,
			handleResized,
			handleClsClick,
			handleShowLeft,
			selectChange,
			currentChange
		}
	}
})
</script>
