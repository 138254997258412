const menu = {
	//菜单编码
	code: {
		//======================================== cfg  ========================================================

		/**
		 * 组织机构
		 */
		ORGANIZE_COMPANY: "cfg_organize_company",                   //单位管理
		ORGANIZE_DEPARTMENT: "cfg_organize_department",             //部门管理
		ORGANIZE_POSITION: "cfg_organize_position",                 //岗位管理
		ORGANIZE_USER: "cfg_organize_user",                         //用户管理

		/**
		 * 权限设置
		 */
		POWER_USER_MENU: "cfg_power_user_menu",                     //用户权限
		POWER_ROLE: "cfg_power_role",                               //角色管理

		CFG_SYSTEM_MSG: 'cfg_system_msg',							 //消息中心
		SYS_BASE_SHOP_INITIALIZATION: 'sys_base_shop_initialization',							 //初始向导

		/**
		 * 资料管理
		 */
		DATA_CUSTOMER: "cfg_data_customer",                         //客户管理
		DATA_SUPPLIER: "cfg_data_supplier",                         //供应商管理
		DATA_GOODS: "cfg_data_goods",                               //物品管理
		DATA_CLASSIFY: "cfg_data_classify",                         //物品类别
		DATA_DETECTION:"cfg_data_detection",						//校验项目
		DATA_DEPOT: "cfg_data_depot",                               //仓库管理
		DATA_FREIGHT: "cfg_data_freight",                   		//货位管理
		DATA_LOGISTICS: "cfg_data_logistics",                       //物流公司
		DATA_ACCOUNT: "cfg_data_account",         	                //资金账户
		DATA_ACCOUNT_RECORD: "cfg_data_account_record",         	//资金明细
		DATA_CURRENCY: "cfg_data_currency",                         //币种管理
		DATA_SUBJECT: "cfg_data_subject",                           //费用科目
		DATA_CERT: "cfg_data_cert",                                 //证件管理
		DATA_FILES: "cfg_data_files",                               //附件管理

		/**
		 *  财务资料
		 */
		CFG_FINANCE_VOUCHER_WORD: 'cfg_finance_voucher_word',		//凭证字
		CFG_FINANCE_SUBJECT: 'cfg_finance_subject',					//科目类型
		CFG_FINANCE_ACCOUNTINGTITLE: 'cfg_finance_accountingtitle',  //会计科目
		CFG_FINANCE_CASHFLOW: 'cfg_finance_cashFlow',				//现金流量
		CFG_INITIAL_CASHFLOW: 'cfg_initial_cashFlow',				//现金流量初始化
		CFG_INITIAL_FUND: 'cfg_initial_fund',						//资金初始化
		CFG_INITIAL_FINANACE: 'cfg_initial_finance',				//财务初始化
		CFG_FINANCE_VOUCHER_SETTLEMENT:'cfg_finance_voucher_settlement',  //会计科目
		CFG_FINANCE_PROJECT_MANAGEMENT:'cfg_finance_project_management',  //项目管理

		/**
		 * 财务资料
		 */
		VOUCHER_SETTLEMENT: "cfg_finance_voucher_settlement",                         //客户管理
		VOUCHER_SUMMARY: "cfg_finance_voucher_summary",                         //供应商管理

		/**
		 * 业务配置
		 */
		BUSINESS_DEADLINE: "cfg_business_deadline",                 //首付款期限
		BUSINESS_ORDERNO: "cfg_business_orderno",                   //单号规则
		BUSINESS_DEPOT_SET: "cfg_business_depot_set",               //仓库设置
		BUSINESS_CUSTOMER_SET: "cfg_business_customer_set",         //客户设置
		BUSINESS_SUPPLIER_SET: "cfg_business_supplier_set",         //供应商设置
		BUSINESS_GOODS_PRICE: "cfg_business_goods_price",           //物品价格
		BUSINESS_CLAUSE: "cfg_business_clause",                     //合同条款
		BUSINESS_REMIND: "cfg_business_remind",                     //提醒设置
		BUSINESS_UNIT: "cfg_business_unit",                   		//单位设置

		/**
		 * 系统配置
		 */
		CFG_SYSTEM_COLUMN: "cfg_system_column",
		CFG_SYSTEM_CUSTOM: "cfg_system_custom",                      //自定义字段设置
		SYSTEM_BASIC: "cfg_system_basic",                       //基础设置
		SYSTEM_FIELD: "cfg_system_field",                       //自定义字段
		SYSTEM_DICT: "cfg_system_dict",                         //数据字典表
		SYSTEM_TABLE: "cfg_system_table",                       //表格设置
		SYSTEM_COLUMN: "cfg_system_column",                     //列设置
		CFG_SYSTEM_WORD: "cfg_system_word",                     //word模板
		CFG_SYSTEM_PRINT: "cfg_system_print",                   //打印模板
		CFG_SYSTEM_FINANCE: "cfg_system_finance",                   //打印模板

		/**
		 * 审批设置
		 */
		EXAMINE_FLOW: "cfg_examine_flow",                   //审批流程
		EXAMINE_TASK: "cfg_examine_task",                   //审批任务
		EXAMINE_RECORD: "cfg_examine_record",               //审批记录

		//====================== erp  ==========================

		/**
		 * 采购管理
		 */
		PURCHASE_APPLY: "erp_purchase_apply",            						//采购管理 - 采购申请
		PURCHASE_APPLY_GOODS: "erp_purchase_apply_goods",          			//采购管理 - 采购申请-物品明细
		PURCHASE_INQUIRY: "erp_purchase_inquiry",        						//采购管理 - 采购询价
		PURCHASE_INQUIRY_GOODS: "erp_purchase_inquiry_goods",      			//采购管理 - 采购询价-物品明细
		PURCHASE_CONTRACT: "erp_purchase_contract",      						//采购管理 - 采购合同
		PURCHASE_CONTRACT_GOODS: "erp_purchase_contract_goods",    			//采购管理 - 采购合同-物品明细
		PURCHASE_ORDER: "erp_purchase_order",            						//采购管理 - 采购订单
		PURCHASE_ORDER_GOODS: "erp_purchase_order_goods",          			//采购管理 - 采购订单-物品明细
		ERP_PURCHASE_ORDER_LOGISTICS: "erp_purchase_order_logistics",          //采购管理 - 采购订单-物流跟踪
		PURCHASE_RECEIPT: "erp_purchase_receipt",        						//采购管理 - 收货单
		PURCHASE_REPAIRRECEIPT: "erp_purchase_repairReceipt",        						//采购管理 - 补收货单
		PURCHASE_RECEIPT_GOODS: "erp_purchase_receipt_goods",      			//采购管理 - 收货单-物品明细
		PURCHASE_REFUND: "erp_purchase_refund",          						//采购管理 - 采购退货
		PURCHASE_REFUND_GOODS: "erp_purchase_refund_goods",       				//采购管理 - 采购退货-物品明细
		PURCHASE_OUT: "erp_purchase_out",                						//采购管理 - 采购退货出库
		PURCHASE_ENTER_GOODS: "erp_purchase_enter_goods",          			//采购管理 - 采购退货出库-物品明细
		ERP_PURCHASE_INQUIRY_GOODS: 'erp_purchase_inquiry_goods',             	//采购询价-物品明细
		ERP_PURCHASE_OUT: 'erp_purchase_out',             						//采购退货出库
		ERP_PURCHASE_OUT_GOODS: 'erp_purchase_out_goods',             			//采购退货出库-物品明细

		//个人报表
		ERP_PURCHASE_PERSONAL_PURSUPPLIER: 'erp_purchase_personal_purSupplier',             			//采购统计【按供应商】
		ERP_PURCHASE_PERSONAL_SUPPLIERVERIFY: 'erp_purchase_personal_supplierVerify',             		//供应商对账
		ERP_PURCHASE_PERSONAL_PAYABLE: 'erp_purchase_personal_payable',             					//应付查询
		ERP_PURCHASE_PERSONAL_PAID: 'erp_purchase_personal_paid',             							//已付查询

		//公司报表
		ERP_PURCHASE_COMPANY_PURPRODUCT: 'erp_purchase_company_purProduct',             				//采购统计【按产品】
		ERP_PURCHASE_COMPANY_PURSUPPLIER: 'erp_purchase_company_purSupplier',             				//采购统计【按供应商】
		ERP_PURCHASE_COMPANY_SUPPLIERVRIFY: 'erp_purchase_company_supplierVrify',             			//供应商对账表
		ERP_PURCHASE_COMPANY_RECEIVEVERIFY: 'erp_purchase_company_receiveVerify',             			//订单收货对账表
		ERP_PURCHASE_COMPANY_RECEIVEGLANCE: 'erp_purchase_company_receiveGlance',             			//采购收货一览表
		ERP_PURCHASE_COMPANY_PURORDERNOCOME: 'erp_purchase_company_purorderNocome',             		//采购订单未进明细
		ERP_PURCHASE_COMPANY_PURORDERGLANCE: 'erp_purchase_company_purorderGlance',             		//采购订单一览表
		ERP_PURCHASE_COMPANY_PURDIYREPORT: 'erp_purchase_company_purDiyReport',             			//采购自定义报表
		ERP_PURCHASE_COMPANY_PURREFUNDGLANCE: 'erp_purchase_company_purRefundGlance',             		//采购退货一览表
		ERP_PURCHASE_COMPANY_PURGLANCECOUNT: 'erp_purchase_company_purGlanceCount',             		//采购一览统计表
		ERP_PURCHASE_COMPANY_PURAPPLYGLANCE: 'erp_purchase_company_purApplyGlance',             		//采购申请一览表

		//供应商对账明细
		ERP_PURCHASE_RECONDETAIL1: 'erp_purchase_reconDetail1',             							//供应商对账明细1
		ERP_PURCHASE_RECONDETAIL2: 'erp_purchase_reconDetail2',             							//供应商对账明细2
		ERP_PURCHASE_RECONDETAIL3: 'erp_purchase_reconDetail3',             							//供应商对账明细3

		/**
		 * 销售管理
		 */
		SALE_OFFER: "erp_sale_offer",                  	 							 //销售报价
		SALE_OFFER_GOODS: "erp_sale_offer_goods",      		 						 //销售报价-物品明细
		SALE_CONTRACT: "erp_sale_contract",            	 							 //销售合同
		SALE_CONTRACT_GOODS: "erp_sale_contract_goods",           			 			 //销售合同-物品明细
		SALE_ORDER: "erp_sale_order",                   								 //销售订单
		SALE_ORDER_GOODS: "erp_sale_order_goods",               			    		 //销售订单-物品明细
		SALE_INVOICE: "erp_sale_invoice",               								 //发货单
		SALE_INVOICE_GOODS: "erp_sale_invoice_goods",              					 //发货单-物品明细
		ERP_SALE_INVOICE_LOGISTICS: "erp_sale_invoice_logistics",  					 //发货单-物流跟踪
		SALE_REPAIR_INVOICE: "erp_sale_repairInvoice",									 //销售补货单
		SALE_REFUND: "erp_sale_refund",                 								 //销售退货
		SALE_REFUND_GOODS: "erp_sale_refund_goods",                					 //销售退货-物品明细
		SALE_ENTER: "erp_stock_sale_refund",                   								 //销售退货入库
		SALE_OUT_GOODS: "erp_sale_out_goods",                   						 //销售退货入库-物品明细
		ERP_SALE_RECONDETAIL1: "erp_sale_recondetail1",           						 //客户对账-明细1
		ERP_SALE_RECONDETAIL2: "erp_sale_recondetail2",           						 //客户对账-明细2
		ERP_SALE_RECONDETAIL3: "erp_sale_recondetail3",           						 //客户对账-明细3
		ERP_SALE_INVOICE: 'erp_sale_invoice',                     	//销售发货单

		//个人报表
		ERP_SALE_PERSONAL_SALECOUNT: "erp_sale_personal_salecount",           			 //销售统计【按客户】
		ERP_SALE_PERSONAL_RECONCILIATION: "erp_sale_personal_reconciliation",           //客户对账
		ERP_SALE_PERSONAL_RECEIVABLE: "erp_sale_personal_receivable",           		 //应收查询
		ERP_SALE_PERSONAL_RECEIVED: "erp_sale_personal_received",           		 	 //已收查询

		//公司报表
		ERP_SALE_COMPANY_SALEPRODUCT: "erp_sale_company_saleProduct",           		 		//销售统计【按产品】
		ERP_SALE_COMPANY_SALECUSTOMER: "erp_sale_company_saleCustomer",           		 		//销售统计【按客户】
		ERP_SALE_COMPANY_SALEMAN: "erp_sale_company_saleMan",           		 		 		//销售统计【按业务员】
		ERP_SALE_COMPANY_CUSTOMERVERIFY: "erp_sale_company_customerVerify",           	 		//客户对账
		ERP_SALE_COMPANY_SALEOFFERCONTRAST: "erp_sale_company_saleOfferContrast",       	 	//销售报价单对照
		ERP_SALE_COMPANY_SALECONTRACTCONTRAST: "erp_sale_company_saleContractContrast",        //销售合同对照
		ERP_SALE_COMPANY_SALEORDERGLANCE: "erp_sale_company_saleorderGlance",        			//销售订单一览
		ERP_SALE_COMPANY_SALEORDERUNSHIPPED: "erp_sale_company_saleOrderUnshipped",        	//销售订单未发明细
		ERP_SALE_COMPANY_SALEREFUNDGLANCE: "erp_sale_company_saleRefundGlance",        		//销售退货一览
		ERP_SALE_COMPANY_SALEPURCHASE: "erp_sale_company_salePurChase",        				//销售采购对照
		ERP_SALE_COMPANY_SALEPRODUCE: "erp_sale_company_saleProduce",        					//销售生产追踪
		ERP_SALE_COMPANY_SALEDIYREPORT: "erp_sale_company_saleDiyReport",        				//销售自定义报表
		ERP_SALE_COMPANY_SALEGLANCECOUNT: "erp_sale_company_saleGlanceCount",        			//销售自定义报表

		//销售利润统计
		ERP_SALE_PROFIT_ORDER: "erp_sale_profit_order",        								//销售按单利润统计
		ERP_SALE_PROFIT_TOTAL: "erp_sale_profit_total",        								//销售利润汇总

		//销售提成管理
		ERP_SALE_ROYALTY: "erp_sale_royalty",        				    						//提成设置
		ERP_SALE_COMMISSION: "erp_sale_commission",        									//提成记录
		ERP_SALE_COMMISSION_SETTLED: "erp_sale_commission_settled",        				    //已结提成

		/**
		 * 仓库管理
		 */
		//--------销售业务-----------
		ERP_STOCK_SALE_UNSHIPPED: 'erp_stock_sale_unshipped',                   //未发货查询
		ERP_STOCK_SALE_REFUND: 'erp_stock_sale_refund',                       	//销售退货入库
		ERP_STOCK_SALE_DELIVER: 'erp_stock_sale_deliver',                     	//销售发货单
		ERP_STOCK_SALE_ENTER: 'erp_sale_enter',                     	//销售发货单

		//--------采购业务-----------
		ERP_STOCK_PURCHASE_UNRECEIVED: 'erp_stock_purchase_unreceived',         //未收查询
		ERP_STOCK_PURRECEIPT: 'erp_stock_purchase_receipt',                     //采购收货单
		ERP_STOCK_PURREFUND: 'erp_stock_purchase_refund',                       //采购退货出库
		ERP_STOCK_PURREFUND_GOODS: 'erp_stock_purchase_refund_goods',           //采购退货出库-物品明细

		//--------生产业务-----------
		ERP_STOCK_PRODUCE_CHECK: 'erp_stock_produce_check',                  	 //验收单
		STOCK_MATERIAL: "erp_produce_material", 								 //领料单
		PRODUCE_MATERIAL: "erp_stock_produce_material",
		STOCK_PREPARE_MATERIAL: "erp_stock_produce_material",            		 //备货领料单
		STOCK_MATERIAL_DETAIL: "erp_stock_material_detail",           			 //领料单-明细
		STOCK_REFUND: "erp_stock_produce_refund",               				 //退料单
		STOCK_REFUND_DETAIL: "erp_stock_refund_detail",               	   	     //退料单-明细
		STOCK_OUT_REFUND: "erp_stock_out_refund",               				 //委外退料单
	STOCK_OUT_REFUND_DETAIL: "erp_stock_refund_detail",               	   	     //委外退料单-明细
		ERP_PRODUCE_MRP_OCCUPANCY:"erp_produce_mrp_occupancy",

		//--------委外业务-----------
		ERP_STOCK_OUT_MATERIAL: "erp_stock_out_material",            			//委外出料单-出库
		ERP_STOCK_OUT_CHECK: "erp_stock_out_check",            				//委外验收单

		//--------其他业务-----------
		ERP_STOCK_OTHER_ENTER: 'erp_stock_other_enter',                  	   	 //其他入库
		ERP_STOCK_OTHER_ENTER_DETAIL: 'erp_stock_other_enter_detail',            //其他入库-明细
		ERP_STOCK_OTHER_OUT: 'erp_stock_other_out',                  	    	 //其他出库单
		ERP_STOCK_OTHER_OUT_DETAIL: 'erp_stock_other_out_detail',                //其他出库单-明细
		STOCK_LOSS: "erp_stock_loss",                    						 //报损单
		STOCK_LOSS_DETAIL: "erp_stock_loss_detail",                    		 //报损单-明细
		STOCK_FLOW: "erp_stock_flow",                    						 //报溢单
		STOCK_FLOW_DETAIL: "erp_stock_flow_detail",                    		 //报溢单-明细

		STOCK_SUMMARY: "erp_stock_summary",              						//库存汇总
		STOCK_SUMMARY_SN: "erp_stock_summary_sn",              			//序列号库存表
		STOCK_INVENTORY: "erp_stock_inventory",          						//仓库盘点
		STOCK_INVENTORY_DETAIL: "erp_stock_inventory_detail",          		//仓库盘点-明细
		STOCK_TRANSFER: "erp_stock_transfer",            						//仓库调拨
		STOCK_TRANSFER_DETAIL: "erp_stock_transfer_detail",            		//仓库调拨-明细
		ERP_STOCK_INITIAL: "erp_stock_initial",									//初始库存
		ERP_STOCK_PURAPPLY: "erp_stock_purApply",            			    	//采购申请
		ERP_STOCK_PURAPPLY_GOODS: "erp_stock_purApply_goods",            		//采购申请-物品明细
		ERP_STOCK_QUALITY: "erp_stock_quality",            					//质检记录
		Erp_Stock_Barcode_Record: 'erp_stock_barcode_record',                   // 条码记录
		Erp_Stock_Barcode_PurBarcode: 'erp_stock_barcode_purBarcode',              // 采购收货条码
		ERP_STOCK_BARCODE_ENTERBARCODE: 'erp_stock_barcode_enterBarcode',              // 其他入库条码

		//报表中心
		ERP_STOCK_REPORT_DETAILPOOL: 'erp_stock_report_detailPool',               		 			//库存明细汇总表
		ERP_STOCK_REPORT_NUMBERPOOL: 'erp_stock_report_numberPool',               				 	//库存数量汇总表
		ERP_STOCK_REPORT_NUMBERPOOLCLASSIFY: 'erp_stock_report_numberPoolClassify',                 //库存汇总数量【类别】
		ERP_STOCK_REPORT_SPREADPOOL: 'erp_stock_report_spreadPool',                 				//库存分布汇总表
		ERP_STOCK_REPORT_AVAILABLESTOCK: 'erp_stock_report_availableStock',                 		//可用库存数量汇总表
		ERP_STOCK_REPORT_DULLANALYSIS: 'erp_stock_report_dullAnalysis',                 			//呆滞品分析
		ERP_STOCK_REPORT_MATERIALGLANCE: 'erp_stock_report_materialGlance',                 		//领料一览表
		ERP_STOCK_REPORT_OUTENTERPOOL: 'erp_stock_report_outenterPool',                 			//出入库明细汇总表
		ERP_STOCK_REPORT_OUTENTERNUMBER: 'erp_stock_report_outenterNumber',                 		//出入库数量汇总表
		ERP_STOCK_REPORT_STOCKWARNING: 'erp_stock_report_stockWarning',                 			//库存预警表
		ERP_STOCK_WAREHOUSING: 'erp_stock_warehousing',                 											//入库单
		ERP_STOCK_OUTBOUND: 'erp_stock_outbound',                 														//出库单

		/**
		 * 生产管理 product
		 */
		PRODUCE_NOTICE: "erp_produce_notice",           						//生产管理 - 生产通知
		PRODUCE_NOTICE_GOODS: "erp_produce_notice_goods",           			//生产管理 - 生产通知-产品明细
		PRODUCE_PLAN: "erp_produce_plan",                						//生产管理 - 生产计划
		PRODUCE_PLAN_GOODS: "erp_produce_plan_goods",                			//生产管理 - 生产计划-产品明细
		PRODUCE_DESIGN: "erp_produce_design",           	 					//生产管理 - 产品设计
		PRODUCE_PROCESS: "erp_produce_process",          						//生产管理 - 加工单
		PRODUCE_WORKORDER: "erp_produce_workOrder",          					//生产管理 - 派工单
        PRODUCE_DISPATCHTASKS: "erp_produce_dispatchTasks",          			//生产管理 - 派工任务
		PRODUCE_PROCINSPRECORD: "erp_produce_procInspRecord",          			//生产管理 - 工序检验单
		PRODUCE_DRAWING:"erp_produce_drawing",									//生产管理-图纸管理
		PRODUCE_PROCESS_DETAIL: "erp_produce_process_detail",          		//生产管理 - 加工单-物料明细
		PRODUCE_CRAFT_ROUTE: "erp_produce_craft_route",  						//生产管理 - 工艺路线
		PRODUCE_WORKGROUP: "erp_produce_craft_workgroup",  						//生产管理 - 工艺路线
		PRODUCE_CRAFT_ROUTE_PROCEDURE: "erp_produce_craft_route_procedure",  	//生产管理 - 工艺路线-工序步骤
		PRODUCE_CRAFT_PROCEDURE: "erp_produce_craft_procedure", 				//生产管理 - 制作工序
		PRODUCE_CRAFT_WORKSHOP: "erp_produce_craft_workshop", 	   				//生产管理 - 工作车间
		ERP_PRODUCE_CRAFT_WASTE: "erp_produce_craft_waste", 	   				//生产管理 - 不良品项
		PRODUCE_CHECK: "erp_produce_check",              						//生产管理 - 验收单
		PRODUCE_CHECK_DETAIL: "erp_produce_check_detail",              		//生产管理 - 验收单-产品明细
		PRODUCE_OUTLAY: "erp_produce_outlay",            						//生产管理 - 费用分摊
		PRODUCE_OUTLAY_DETAIL: "erp_produce_outlay_detail",            		//生产管理 - 费用分摊-费用明细
		PRODUCE_OUTLAY_PROCESS: "erp_produce_outlay_process",            		//生产管理 - 费用分摊-分摊明细
		PRODUCE_OUTPROCESS: "erp_produce_outprocess",   						//生产管理 - 委外加工单
		PRODUCE_OUTMATERIAL: "erp_produce_outmaterial",  						//生产管理 - 委外出料单
		PRODUCE_OUTMATERIAL_DETAIL: "erp_produce_outmaterial_detail",  		//生产管理 - 委外出料单-物料明细
		PRODUCE_OUTCHECK: "erp_produce_outcheck",        						//生产管理 - 委外验收单
		PRODUCE_OUTCHECK_DETAIL: "erp_produce_outcheck_detail",        		//生产管理 - 委外验收单-产品明细
		ERP_PRODUCE_BOM: "erp_produce_bzbom",        			    				//生产管理 - 标准BOM
		ERP_PRODUCE_BOM_HISTORY: "erp_produce_bzbom_history",        			    //生产管理 - 标准BOM-历史版本
		ERP_PRODUCE_ORDERBOM: "erp_produce_orderBom",        			    	//生产管理 - 订单BOM
		ERP_PRODUCE_MRP: "erp_produce_mrp",        			    			//生产管理 - MRP运算
		PRODUCE_BOM_DETAIL: "erp_produce_bom_detail",        			    	//生产管理 - 标准BOM-物料明细
		PRODUCE_ORDERBOM_DETAIL: "erp_produce_orderBom_detail",        			//生产管理 - 订单BOM-物料明细
		PRODUCE_ASSEMBLE: "erp_produce_assemble",        						//生产管理 - 组装单
		PRODUCE_ASSEMBLE_DETAIL: "erp_produce_assemble_detail",        		//生产管理 - 组装单-物料明细
		PRODUCE_DISMANTLE: "erp_produce_dismantle",      						//生产管理 - 拆卸单
		PRODUCE_DISMANTLE_DETAIL: "erp_produce_dismantle_detail",      	    //生产管理 - 拆卸单-物料明细
		PRODUCE_PRODUCT: "erp_produce_product",									//生产管理 - 产品计划
		//报表中心
		ERP_PRODUCE_REPORT_PRODUCTCOUNT: "erp_produce_report_productCount",      	    				//加工产品统计表
		ERP_PRODUCE_REPORT_MATERIALCOUNT: "erp_produce_report_materialCount",      	    			//领料统计
		ERP_PRODUCE_REPORT_REFUNDCOUNT: "erp_produce_report_refundCount",      	    				//退料统计
		ERP_PRODUCE_REPORT_OUTMATERIALCOUNT: "erp_produce_report_outMaterialCount",      	    		//委托出料统计
		ERP_PRODUCE_REPORT_OUTCHECKCOUNT: "erp_produce_report_outCheckCount",      	    			//委外验收统计
		ERP_PRODUCE_REPORT_PRODUCTIONPROGRESS: "erp_produce_report_productionProgress",      	    	//生产进度表
		ERP_PRODUCE_REPORT_WASTETOTAL:'erp_produce_report_wasteTotal',									//不良品项汇总
		ERP_PRODUCE_REPORT_ITEMPATIO:'erp_produce_report_itemRatio',									//不良品项汇总

		ERP_PRODUCE_REPORT_PROCESSMATERIAL: "erp_produce_report_processMaterial",      	    		//加工单领料统计
		ERP_PRODUCE_REPORT_PLANSITUATION: "erp_produce_report_planSituation",      	    			//计划单情况表
		ERP_PRODUCE_REPORT_PLANGLANCE: "erp_produce_report_planGlance",      	    					//生产计划一览表
		ERP_PRODUCE_REPORT_PROCEDUREGLANCE: "erp_produce_report_procedureGlance",
		//工序一览表
		ERP_PRODUCE_WORKORDER_PROCHANDOVERREC: "erp_produce_procHandoverRec",      	    		//工序一览表

		/**
		 * 质检管理 quality
		 */
		ERP_QUALITY_SUBMPROCURE: "erp_quality_submProcure",      	    		//原料送检单
		ERP_QUALITY_SUBMOUTSOURCE: "erp_quality_submOutsource",      	    		//委外送检单
		ERP_QUALITY_SUBMPRODUCT: "erp_quality_submProduct",      	    		//产品送检单
		ERP_QUALITY_CHECKPROCURE: "erp_quality_CheckProcure",      	    		//原料检验单
		ERP_QUALITY_CHECKOUTSOURCE: "erp_quality_CheckOutsource",      	    		//委外检验单
		ERP_QUALITY_CHECKPRODUCT: "erp_quality_CheckProduct",      	    		//产品检验单
		/**
		 * 设备管理 device
		 */
		ERP_DEVICE: "erp_devices",           					  //设备管理 - 设备信息
		ERP_DEVICE_INSTALL: "erp_device_install",      		  //设备管理 - 安装记录
		ERP_DEVICE_MAINTAINPLAN: "erp_device_maintainPlan",      //设备管理 - 保养计划
		ERP_DEVICE_MAINTAIN: "erp_device_maintain",      	      //设备管理 - 保养记录
		ERP_DEVICE_FAULT: "erp_device_fault",      	      	  //设备管理 - 故障报告
		ERP_DEVICE_REPAIR: "erp_device_repair",      	      	  //设备管理 - 维修记录

		/**
		 * 物流管理
		 */
		ERP_LOGISTICS_BILL: 'erp_logistics_bill',                						//物流单据
		ERP_LOGISTICS_LOGISTICS: 'erp_logistics_logistics',                				//物流公司
		ERP_LOGISTICS_DRIVER: 'erp_logistics_driver',          							//司机管理
		ERP_LOGISTICS_VEHICLE: 'erp_logistics_vehicle',          						//车辆管理
		ERP_LOGISTICS_DELIVERY: 'erp_logistics_delivery',       						//配送任务
		ERP_LOGISTICS_DELIVERY_DETAIL: 'erp_logistics_delivery_detail',       			//配送任务-配送明细
		ERP_LOGISTICS_TRANSPORT: 'erp_logistics_transport',     						//运输计划
		ERP_LOGISTICS_TRANSPORT_DETAIL: 'erp_logistics_transport_detail',     			//运输计划-运输明细
		ERP_LOGISTICS_TRANSPORT_LOCUS: 'erp_logistics_transport_locus',    			 	//运输计划-车辆轨迹
		ERP_LOGISTICS_EXPRESS: 'erp_logistics_express',          						//其他快递
		//物流费用
		ERP_LOGISTICS_COST_RECEIPT: 'erp_logistics_cost_receipt',          				//收货单费用
		ERP_LOGISTICS_COST_DELIVER: 'erp_logistics_cost_deliver',          				//发货单费用
		ERP_LOGISTICS_COST_TRANSFER: 'erp_logistics_cost_transfer',          			//仓库调拨费用
		ERP_LOGISTICS_COST_PURINVOICE: 'erp_logistics_cost_purInvoice',          		//进项发票费用
		ERP_LOGISTICS_COST_SALEINVOICE: 'erp_logistics_cost_saleInvoice',          		//销项发票费用
		ERP_LOGISTICS_COST_DEALWITH: 'erp_logistics_cost_dealwith',          			//应付费用
		ERP_LOGISTICS_PAYMENT: 'erp_logistics_payment',         						//物流付款
		ERP_LOGISTICS_PAYMENT_DETAIL: 'erp_logistics_payment_detail',         			//物流付款-付款明细

		ERP_LOGISTICS_PURTRACK: 'erp_logistics_purTrack',         						//采购订单跟踪
		ERP_LOGISTICS_PURTRACK_GOODS: 'erp_logistics_purTrack_goods',         			//采购订单跟踪-物品明细
		ERP_LOGISTICS_DELIVERTRACK: 'erp_logistics_deliverTrack',         				//发货单跟踪
		ERP_LOGISTICS_DELIVERTRACK_GOODS: 'erp_logistics_deliverTrack_goods',         	//发货单跟踪-物品明细

		/**
		 * 售后服务
		 */
		ERP_UPHOLD_REGISTER: 'erp_uphold_register',                            //报修登记
		ERP_UPHOLD_REGISTER_PRODUCT: 'erp_uphold_register_product',             //报修登记-产品
		ERP_UPHOLD_REPAIR: 'erp_uphold_repair',								    //售后维修
		ERP_UPHOLD_REPAIR_PRODUCT: 'erp_uphold_repair_product',					//售后维修-产品
		ERP_UPHOLD_REPAIR_MATERIAL: 'erp_uphold_repair_material',				//售后维修-物料
		ERP_UPHOLD_REPAIR_COST: 'erp_uphold_repair_cost',						//售后维修-收取费用
		ERP_UPHOLD_COLLECT: 'erp_uphold_collect',								//费用收款
		ERP_UPHOLD_COLLECT_DETAIL: 'erp_uphold_collect_detail',					//费用收款-明细
		ERP_UPHOLD_COMPLAINT: 'erp_uphold_complaint',							//投诉管理
		ERP_UPHOLD_SERVICECHARGE: 'erp_uphold_serviceCharge',					//费用统计

		/**
		 * 借条管理
		 */
		ERP_BORROW_LEND: 'erp_borrow_lend',                            			//借出单
		ERP_BORROW_LEND_DETAIL: 'erp_borrow_lend_detail',                       //借出单-物品明细
		ERP_BORROW_REVERT: 'erp_borrow_revert',                     			//借出还回
		ERP_BORROW_REVERT_DETAIL: 'erp_borrow_revert_detail',                   //借出还回-物品明细
		ERP_BORROW_ENTER: 'erp_borrow_enter',                  				    //借进单
		ERP_BORROW_ENTER_DETAIL: 'erp_borrow_enter_detail',                  	//借进单-物品明细
		ERP_BORROW_STILL: 'erp_borrow_still',                  			     	//借进还出
		ERP_BORROW_STILL_DETAIL: 'erp_borrow_still_detail',                  	//借进还出-物品明细

		/**
		 * 验收管理
		 */
		ERP_EXAM_WELDING: 'erp_exam_welding',                            		//焊接审批
		ERP_EXAM_RAW: 'erp_exam_raw',                            				//原材料审批
		ERP_EXAM_OUT: 'erp_exam_out',                            				//外购审批
		EXAM_OUT_QUALITY_EXAM: 'exam_out_quality_exam',                            				//外购审批结论

		/**
		 * 质检管理
		 */
		ERP_QUALITY_SUBMPROCURE: 'erp_quality_submProcure',                     //原料送检单
		ERP_QUALITY_CHECKPROCURE: 'erp_quality_checkProcure',                   //原料检验单
		ERP_QUALITY_CHECKOUTSOURCE: 'erp_quality_checkOutsource',               //委外检验单
		ERP_QUALITY_SUBMOUTSOURCE: 'erp_quality_submOutsource',					//委外送检单
		ERP_QUALITY_SUBMPRODUCT: 'erp_quality_submProduct',						//产品送检单
		ERP_QUALITY_CHECKPRODUCT: 'erp_quality_checkProduct',					//产品质检单
		//====================== erp end  ==========================

		//====================== fms start  ==========================

		//---------------销售往来账---------------
		FMS_SELL_ADVRECEIPT: 'fms_sell_advreceipt',                  			 //预收款单
		FMS_SELL_ADVRECEIPT_REFUND: 'fms_sell_advreceipt_refund',                //预收退款
		FMS_SELL_RECEIPT: 'fms_sell_receipt',                					 //收款单
		FMS_SELL_RECEIPT_DETAIL: 'fms_sell_receipt_detail',                		 //收款单-明细
		FMS_SELL_DEBT_CUSTOMER: 'fms_sell_debt_customer',                		 //客户欠款
		FMS_SELL_BORROW_CUSTOMER: 'fms_sell_borrow_customer',                	 //客户借款
		FMS_SELL_BACK_CUSTOMER: 'fms_sell_back_customer',                	 	 //客户还款
		FMS_SELL_RECEIVABLE: 'fms_sell_receivable',                	 	 		 //应收查询
		FMS_SELL_RECEIVED: 'fms_sell_received',                	 	 		 	 //已收查询
		FMS_SELL_REFUNED: 'fms_sell_refuned',                	 	 		 	 //已退查询
		FMS_SELL_ADVRECEIPT_MELT: 'fms_sell_advreceipt_melt',                	 //预收核销
		FMS_SELL_ADVRECEIPT_MELT_DETAIL: 'fms_sell_advreceipt_melt_detail',      //预收核销-明细
		FMS_SELL_RELATION: 'fms_sell_relation',      					 		 //关联费用
		FMS_SELL_RELATIONCOUNT: 'fms_sell_relationCount',      					 //销售-关联费用统计
		FMS_BUY_RELATIONCOUNT: 'fms_buy_relationCount',      					 //采购-关联费用统计
		FMS_SELL_COLLECTPERIOD: 'fms_sell_collectPeriod',      					 //收款账期表
		FMS_SELL_OVERDUERECEIVABLE: 'fms_sell_overdueReceivable',      			 //超期应收款
		FMS_SELL_REFUNDRECEIPT: 'fms_buy_refundReceipt',      			 //退货收款单
		FMS_SELL_REFUNDPAYMENT: 'fms_sell_refundPayment',      			 //退货付款单

		//---------------采购往来账---------------
		FMS_BUY_APPLY: 'fms_buy_apply',                  			 			 //付款申请单
		FMS_BUY_APPLY_DETAIL: 'fms_buy_apply_detail',               			 //付款申请单-订单明细
		FMS_BUY_ADVPAYMENT: 'fms_buy_advpayment',                				 //预付款单
		FMS_BUY_ADVPAYMENT_REFUND: 'fms_buy_advpayment_refund',                	 //预付退款
		FMS_BUY_PAYMENT: 'fms_buy_payment',                		 		 		 //付款单
		FMS_BUY_PAYMENT_DETAIL: 'fms_buy_payment_detail',                	 	 //付款单-订单明细
		FMS_BUY_RELATION: 'fms_buy_relation',                	 	 		     //关联费用
		FMS_BUY_RELATION_DETAIL: 'fms_buy_relation_detail',                	 	 //关联费用- 费用明细
		FMS_BUY_DEBT_SUPPLIER: 'fms_buy_debt_supplier',                	 	 	 //欠款供应商
		FMS_BUY_BORROW_SUPPLIER: 'fms_buy_borrow_supplier',                	 	 //借款供应商
		FMS_BUY_BACK_SUPPLIER: 'fms_buy_back_supplier',                	 	 	 //还款供应商
		FMS_BUY_PAYABLE: 'fms_buy_payable',                	 	 	 			 //应付查询
		FMS_BUY_PAID: 'fms_buy_paid',                	 	 	 				 //已付查询
		FMS_BUY_REFUNDED: 'fms_buy_refunded',                	 	 	 		 //已退查询
		FMS_BUY_PAYMENTPERIOD: 'fms_buy_paymentPeriod',                	 	 	 //付款账期表
		FMS_BUY_OVERDUEPAYABLE: 'fms_buy_overduePayable',                	 	 //超期应付款
		FMS_BUY_ADVPAYMENT_MELT: 'fms_buy_advpayment_melt',                	 	 //预付核销
		FMS_BUY_ADVPAYMENT_MELT_DETAIL: 'fms_buy_advpayment_melt_detail',        //预付核销-明细

		//---------------发票管理---------------
		FMS_INVOICE_SALEINVOICE: 'fms_invoice_saleInvoice',                	 	 //销项发票
		FMS_INVOICE_SALEINVOICE_DETAIL: 'fms_invoice_saleInvoice_detail',        //销项发票-开票明细
		FMS_INVOICE_SALEINVOICE_DELIVER: 'fms_invoice_saleInvoice_deliver',      //销项发票-发货信息
		FMS_INVOICE_SALEOWE: 'fms_invoice_saleOwe',                              //销项欠票
		FMS_INVOICE_PURINVOICE: 'fms_invoice_purInvoice',                        //进项发票
		FMS_INVOICE_PURINVOICE_DETAIL: 'fms_invoice_purInvoice_detail',          //进项发票-开票明细
		FMS_INVOICE_PURINVOICE_DELIVER: 'fms_invoice_purInvoice_deliver',        //进项发票-发货信息
		FMS_INVOICE_PUROWE: 'fms_invoice_purOwe',                                //进项欠票
		FMS_INVOICE_SALEWAIT: 'fms_invoice_salewait',                            //销项待开查询
		FMS_INVOICE_SALEINVOICED: 'fms_invoice_saleInvoiced',                    //销项已开查询
		FMS_INVOICE_PURRECEIVING: 'fms_invoice_purReceiving',                    //进项待收查询
		FMS_INVOICE_PURRECEIVED: 'fms_invoice_purReceived',                      //进项已收查询
		FMS_INVOICE_STOCK: 'fms_invoice_stock',                                   //发票库存查询

		//---------------出纳管理---------------
		FMS_CASHIER_INVENTORY: 'fms_cashier_inventory',								 //现金盘点
		FMS_CASHIER_ACCOUNT: 'fms_cashier_cashierAccount',								 //出纳会计对账
		FMS_CASHIER_JOURNAL: 'fms_cashier_journal',								 //出纳日记
		FMS_CASHIER_CHECKOUT: 'fms_cashier_checkout',								 //出纳日记
		FMS_CASHIER_RECONCILIATION: 'fms_cashier_reconciliation',				//银行对账单
		FMS_CASHIER_CASHIERBANK: 'fms_cashier_cashierBank',						//出纳银行对账单
		FMS_CASHIER_BALANCEADJUSTMENT: 'fms_cashier_balanceAdjustment',			//余额调节表
		FMS_CASHIER_INVENTORY_DETAIL: 'fms_cashier_inventory_detail',			//出纳结账

		//---------------总账管理---------------
		FMS_ACCOUNTING_VOUCHER: 'fms_accounting_voucher',						//会计凭证

		//---------------其他账簿---------------
		FMS_OTHER_COST: 'fms_other_cost',                                        //费用支出
		FMS_OTHER_COST_DETAIL: 'fms_other_cost_detail',                          //费用支出-明细
		FMS_OTHER_INCOME: 'fms_other_income',                          			 //其他收入
		FMS_OTHER_INCOME_DETAIL: 'fms_other_income_detail',                      //其他收入-明细
		FMS_OTHER_WITHDRAW: 'fms_other_withdraw',                                //提现转账
		FMS_OTHER_VOUCHER: 'fms_other_voucher',                                  //借贷凭证
		FMS_OTHER_CAPITAL: 'fms_other_capital',                                  //资金变动
		FMS_ACCOUNTING_CASHBANK: 'fms_accounting_cashBank',                      //现金银行日记账
		FMS_ACCOUNTING_CLASSIFY_DETAIL: 'fms_accounting_classify_detail',        //明细分类账
		FMS_WAGE_PIECE: 'fms_wage_piece',	                								//计件工资

		//---------------固定资产---------------
		FMS_ASSETS: 'fms_assets',                                                //固定资产
		FMS_ASSETS_TRADE: 'fms_assets_trade',                                    //固定资产-买卖
		FMS_ASSETS_TRADE_DETAIL: 'fms_assets_trade_detail',                      //固定资产-买卖-明细
		FMS_ASSETS_CLASSIFY: 'fms_assets_classify',                     		 //固定资产类别
		FMS_ASSETS_MANNER: 'fms_assets_manner',                                  //固定资产增加/减少
		FMS_ASSETS_INCREASE: 'fms_assets_increase',                              //固定资产卡片
		FMS_ASSETS_REDUCE: 'fms_assets_reduce',                                  //固定资产减少
		FMS_ASSETS_WORKLOAD: 'fms_assets_workload',                              //工作量录入
		FMS_ASSETS_PROVISION: 'fms_assets_provision',                            //计提折旧
		FMS_ASSETS_REPORTSUMMARY: 'fms_assets_reportSummary',                    //固定资产及折旧汇总表
		FMS_ASSETS_REPORTDETAIL: 'fms_assets_reportDetail',                      //固定资产及折旧明细表

		//---------------财务报表---------------
		FMS_REPORT_TOTAL: 'fms_report_total',                      			 			   //经营汇总表
		FMS_REPORT_PROFIT: 'fms_report_profit',                      			 			 //利润表
		FMS_REPORT_MONTH: 'fms_report_month',                      			 	 			 //月报表明细
		FMS_REPORT_COST: 'fms_report_cost',                      			 	 				 //费用支出统计
		FMS_REPORT_CASHFLOW: 'fms_report_cashFlow',                      		 		 //现金流量
		FMS_REPORT_BANKFLOW: 'fms_report_bankFlow',                      		 		 //银行流水账
		FMS_REPORT_ACCOUNTBALANCE: 'fms_report_accountBalance',                  //账户余额表
		FMS_REPORT_ACCOUNTBALANCESUMMARY: 'fms_report_accountBalanceSummary',    //科目余额汇总表
		FMS_REPORT_CASHDISTRIBUTION: 'fms_report_cashDistribution',              //现金流量分配表
		FMS_REPORT_TRIALBALANCE: 'fms_report_trialBalance',              				 //试算平衡表
		FMS_REPORT_ASSETLIABILITY: 'fms_report_assetLiability',              		 //资产负债表

		//====================== fms end  ==========================

		//====================== CRM 客户关系 start  ==========================
		//-----------客户关系-------------
		CRM_CUSTOMER_VISIT: 'crm_customer_visit',                      			 //拜访记录
		CRM_CUSTOMER_PUBLIC: 'crm_customer_public',                      		 //公共池
		CRM_CUSTOMER_FILE: 'crm_customer_file',                      		 	 //档案
		CRM_CUSTOMER_BIRTHDAY: 'crm_customer_birthday',                      	 //生日查询
		CRM_CUSTOMER_CARE: 'crm_customer_care',                      			 //客户关怀
		CRM_CUSTOMER_TRANSFER: 'crm_customer_transfer',                      	 //客户转移
		CRM_CUSTOMER_EXPLAIN: 'crm_customer_explain',                      	 	 //追加说明
		CRM_CUSTOMER_INTEGRAL: 'crm_customer_integral',                      	 //积分管理
		CRM_CUSTOMER_EXCHANGE: 'crm_customer_exchange',                      	 //积分兑换
		CRM_CUSTOMER_EXCHANGE_GOODS: 'crm_customer_exchange_goods',              //积分兑换-物品明细
		CRM_CUSTOMER_FUNNEL: 'crm_customer_funnel',              				 //漏斗图
		CRM_CUSTOMER_OVERDUE: 'crm_customer_overdue',              				 //超期客户预警
		CRM_CUSTOMER_COUNT: 'crm_customer_count',              					 //客户统计

		//---------------销售机会---------
		CRM_SALE_OPPORTUNITY: 'crm_sale_opportunity',             				 //销售商机
		CRM_SALE_OPPORTUNITY_GOODS: 'crm_sale_opportunity_goods',             	 //销售商机-物品明细
		CRM_SALE_SOLUTION: 'crm_sale_solution',             	 				 //解决方案
		CRM_SALE_INQUIRY: 'crm_sale_inquiry',             	 				 	 //询价报价
		CRM_SALE_INQUIRY_DETAIL: 'crm_sale_inquiry_detail',             	 	 //询价报价-询价明细
		CRM_SALE_ACTIVITY: 'crm_sale_activity',             	 				 //市场活动
		CRM_SALE_OFFER: 'crm_sale_offer',             	 				 	     //销售报价单
		CRM_SALE_OFFER_DETAIL: 'crm_sale_offer_detail',             	 		 //报价明细查询
		CRM_SALE_CONTRACT: 'crm_sale_contract',             	 				 //销售合同
		CRM_SALE_CONTRACT_GOODS: 'crm_sale_contract_goods',             	 	 //销售合同-物品明细
		CRM_SALE_OPPOTUNITYFUNNEL: 'crm_sale_oppotunityFunnel',             	 //机会漏斗图

		//--------------竞争对手-----------------
		CRM_COMPETITOR_MANAGE: 'crm_competitor_manage',             	 		 //竞争对手管理
		CRM_COMPETITOR_ANALYZY: 'crm_competitor_analyzy',             	 		 //竞争对手分析

		//--------------产品管理-----------------
		CRM_PRODUCT_MANAGE: 'crm_product_manage',             	 				 //产品管理
		CRM_PRODUCT_CLASSIFY: 'crm_product_classify',             	 			 //产品类别
		CRM_PRODUCT_PRICE: 'crm_product_price',             	 			 	 //产品价格查询
		CRM_PRODUCT_PURPRICE: 'crm_product_purPrice',             	 			 //采购价格查询
		CRM_PRODUCT_SALEPRICE: 'crm_product_salePrice',             	 		 //销售价格查询
		CRM_PRODUCT_UPDATE: 'crm_product_update',             	 		 		 //批量更新
		CRM_PRODUCT_SUIT: 'crm_product_suit',             	 				 	 //套装设置
		CRM_PRODUCT_SUIT_GOODS: 'crm_product_suit_goods',             	 		 //套装设置-物品明细

		//---------------供应商管理---------------
		CRM_SUPPLIER_MANAGE: 'crm_supplier_manage',             	 			  //供应商
		CRM_SUPPLIER_BIRTHDAY: 'crm_supplier_birthday',             	 		  //供应商生日查询
		CRM_SUPPLIER_INQUIRY: 'crm_purchase_inquiry',             	 		 	  //采购询价单
		CRM_SUPPLIER_INQUIRY_GOODS: 'crm_supplier_inquiry_goods',             	  //采购询价单-明细
		CRM_SUPPLIER_INQUIRYDETAIL: 'crm_supplier_inquiryDetail',             	  //采购询价一览

		//====================== CRM end  ==========================

		//====================== hr start  ==========================
		//---------------人事管理---------------
		HR_PERSONNEL_DOSSIER: 'hr_personnel_dossier',             	 			 		//员工档案
		HR_PERSONNEL_ENTER: 'hr_personnel_enter',             	 			 			//员工入职
		HR_PERSONNEL_REGULAR: 'hr_personnel_regular',             	 			 		//员工转正
		HR_PERSONNEL_TRANSFER: 'hr_personnel_transfer',             	 			 	//岗位调动
		HR_PERSONNEL_RECORD: 'hr_personnel_record',             	 			 		//变动记录
		HR_PERSONNEL_RESIGN: 'hr_personnel_resign',             	 			 		//员工离职

		//---------------招聘管理---------------
		HR_RECRUIT_CHANNEL: 'hr_recruit_channel',             	 			 			//招聘渠道
		HR_RECRUIT_DEMAND: 'hr_recruit_demand',             	 			 			//招聘需求
		HR_RECRUIT_PROGRAMME: 'hr_recruit_programme',             	 			 		//招聘计划
		HR_RECRUIT_APPLICANT: 'hr_recruit_applicant',             	 			 		//应聘管理
		HR_RECRUIT_ALTERNATIVE: 'hr_recruit_alternative',             	 			 	//人才备选

		//---------------劳务合同---------------
		HR_LABOR_CONTRACT: 'hr_labor_contract',             	 			 			//合同管理
		HR_LABOR_CONTRACT_CHANGE: 'hr_labor_contract_change',             	 			//合同变更
		HR_LABOR_CONTRACT_STOP: 'hr_labor_contract_stop',             	 				//解除合同
		HR_LABOR_CONTRACT_RENEWAL: 'hr_labor_contract_renewal',             	 		//续签合同

		//-------协议管理----
		HR_LABOR_SECRECY: 'hr_labor_secrecy',             	 			 				//保密协议
		HR_LABOR_TRAINTREATY: 'hr_labor_trainTreaty',             	 			 		//培训协议
		HR_LABOR_POSTTREATY: 'hr_labor_postTreaty',             	 			 		//岗位协议

		HR_LABOR_DISPUTE: 'hr_labor_dispute',             	 			 				//劳动纠纷

		//---------------培训管理---------------
		//培训资源
		HR_TRAIN_ORGANIZATION: 'hr_train_organization',             	 			 	//培训机构
		HR_TRAIN_TEACHER: 'hr_train_teacher',             	 			 				//培训教师
		HR_TRAIN_CURRICULUM: 'hr_train_curriculum',             	 			 		//培训课程
		HR_TRAIN_MATERIAL: 'hr_train_material',             	 			 			//培训资料
		HR_TRAIN_FACILITIE: 'hr_train_facilitie',             	 			 			//培训设施

		HR_TRAIN_DEMAND: 'hr_train_demand',             	 			 				//培训需求
		HR_TRAIN_PLAN: 'hr_train_plan',             	 			 					//培训计划
		HR_TRAIN_ACTIVITY: 'hr_train_activity',             	 			 			//培训活动
		HR_TRAIN_ASSESS: 'hr_train_assess',             	 			 				//培训评估

		//---------------社保福利---------------
		//福利方案
		HR_WELFARE_SOCIAL_SCHEME: 'hr_welfare_social_scheme',             	 			//社保方案
		HR_WELFARE_HOUSING_SCHEME: 'hr_welfare_housing_scheme',             	 		//公积金方案
		HR_WELFARE_OTHER_SCHEME: 'hr_welfare_other_scheme',             	 			//其他福利方案

		HR_WELFARE_WORKER: 'hr_welfare_worker',             	 						//员工福利

		//---------------考勤管理---------------
		HR_ATTENDANCE_MACHINE: 'hr_attendance_machine',	             	 			 	//考勤机
		HR_ATTENDANCE_RULE: 'hr_attendance_rule',	             	 			 		//考勤规则
		HR_ATTENDANCE_SHIFT: 'hr_attendance_shift',	             	 			 		//考勤班次
		HR_ATTENDANCE_SCHEDULE: 'hr_attendance_schedule',	             	 			//员工排班
		HR_ATTENDANCE_PUNCH: 'hr_attendance_punch',	             	 					//打卡记录
		HR_ATTENDANCE_DAILYACCOUNT: 'hr_attendance_dailyAccount',	             	 	//考勤日账
		HR_ATTENDANCE_MONTHLYACCOUNT: 'hr_attendance_monthlyAccount',	                //考勤月账

		//---------------考勤管理---------------
		HR_PERFORMANCE_TARGET: 'hr_performance_target',	                				//考核指标
		HR_PERFORMANCE_SCHEME: 'hr_performance_scheme',	                				//考核方案
		HR_PERFORMANCE_BATCH: 'hr_performance_batch',	                				//考核批次
		HR_PERFORMANCE_ASSESS: 'hr_performance_assess',	                				//员工考核
		HR_PERFORMANCE_SCORE: 'hr_performance_score',	                				//考核评分
		HR_PERFORMANCE_SUMMARY: 'hr_performance_summary',	                			//计分汇总

		//---------------工资管理---------------
		HR_WAGE_SUBJECT: 'hr_wage_subject',	                							//工资科目
		HR_WAGE_SALARYSUBJECT: 'hr_wage_salarySubject',	                					//员工科目
		HR_WAGE_PACKAGE: 'hr_wage_package',	                							//工资账套
		HR_WAGE_SALARY: 'hr_wage_salary',	                							//员工工资
		HR_WAGE_PAYROLL: 'hr_wage_payroll',	                							//工资单
		HR_WAGE_TAX: 'hr_wage_tax',	                									//个税税率
		HR_WAGE_DRAW: 'hr_wage_draw',	                								//存支记录

		//====================== hr end  ==========================

		//====================== OA start  ==========================
		//---------------任务计划---------------
		OA_TASK_SCHEDULE: 'oa_task_schedule',										  	//日程安排
		OA_TASK_REPORT: 'oa_task_report',										  		//工作汇报
		OA_TASK_WORK: 'oa_task_work',										  			//任务管理
		OA_TASK_PLAN: 'oa_task_plan',										  			//工作计划

		//---------------行政管理---------------
		OA_POLITIC_TRAVEL: 'oa_politic_travel',										  	//出差管理
		OA_POLITIC_OVERTIME: 'oa_politic_overtime',										//加班管理
		OA_POLITIC_LEAVE: 'oa_politic_leave',											//请假管理
		OA_POLITIC_PUNISH: 'oa_politic_punish',											//处罚管理
		OA_POLITIC_ANNUAL: 'oa_politic_annual',											//年假管理
		OA_POLITIC_ANNUAL_RECORD: 'oa_politic_annual_record',							//年假管理-休息记录
		OA_POLITIC_MEET: 'oa_politic_meet',												//会议管理

		//---------------项目管理---------------
		OA_PROJECT: 'oa_project',														//项目管理
		OA_PROJECT_CHECK: 'oa_project_check',											//验收
		OA_PROJECT_AFTERSALE: 'oa_project_aftersale',									//售后服务
		OA_PROJECT_AFTERSALE_RECORD: 'oa_project_aftersale_record',						//售后服务-缴费记录

		//---------------文档管理---------------
		OA_ARCHIVE: 'oa_archive',														//文档管理
		OA_ARCHIVE_DIRECTORY: 'oa_archive_directory',									//文档目录

		//---------------内部消息---------------
		OA_MESSAGE: 'oa_message',

		//====================== OA end  ==========================

	},
	//按钮编码
	button: {
		ADD: 'add',                   			//添加
		ADDSON: 'addson',                   	//新增子部门
		DELETE: 'delete',			  			//删除
		EDIT: 'edit',				  			//编辑
		EDITSELECT: 'editSelect',				//批量编辑
		SEARCH: 'search',  			  			//搜索
		CONTACT: 'contact',  			  	    //联系人
		ROLEPOWER: 'rolepower',  			  	//角色权限
		POWERSET: 'powerset',  			  	    //权限设置
		ENABLE: 'enable',			  			//启用
		UNABLE: 'unable',			  			//停用
		EXPORT: 'export',			  			//导出
		IMPORT: 'import',			 			//导入
		PRINT: 'print',			  	 			//打印
		EXAM: 'exam',			  				//审批
		RECORD: 'record',			  			//历史审批记录
		DEPLOY: 'deploy',		    			//部署
		CANCLEDEPLOY: 'cancledeploy',		    //取消部署

	}
}
module.exports = menu
