import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/oa/politic/politicTravel/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delete: {
		url: `${config.API_URL}/oa/politic/politicTravel/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/oa/politic/politicTravel/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/oa/politic/politicTravel/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	detail: {
		url: `${config.API_URL}/oa/politic/politicTravel/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	updateStatus: {
		url: `${config.API_URL}/oa/politic/politicTravel/updateStatus`,
		name: "补助",
		get: async function (ids, isExecute) {
			return await http.get(this.url + '?ids=' + ids + '&isExecute=' + isExecute);
		}
	},

	submitOpinion: {
		url: `${config.API_URL}/oa/politic/politicTravel/submitOpinion`,
		name: "提交反馈",
		get: async function (id, status, opinion) {
			return await http.get(this.url + '?id=' + id + '&status=' + status + '&opinion=' + opinion);
		}
	},

}
