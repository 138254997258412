<!--选择加工单弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'650px'"
		custom-class="popTable"
		:type="type"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<splitpanes
							:horizontal="true"
							@resized="handleResized"
						>
							<pane>
								<el-container
									class="fatherCont"
									:style="multiple && 'padding-right: 10px'"
								>
									<sy-search-Form
										v-show="!hideForm"
										ref="refFormBar"
										v-auth="$MENU.code.DATA_DETECTION+'::search'"
										:configs="searchConfig"
										:show-item="showItem"
										@fetch-data="fetchData"
										@set-show="handleFitShow"
									/>
									<el-header class="tableTopBar">
										<div class="left-panel" />
										<div class="right-panel" >
											<!--切换显示搜索栏hide-after: 延迟隐藏-->
											<el-tooltip
												:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
												placement="bottom"
												:hide-after="0"
											>
												<el-link @click="handleShowSearch">
													搜索
													<el-icon>
														<template v-if="hideForm">
															<el-icon-arrowDown />
														</template>
														<template v-else>
															<el-icon-arrowUp />
														</template>
													</el-icon>
												</el-link>
											</el-tooltip>
										</div>
									</el-header>
									<el-main class="nopadding bgwhite">
										<!--主数据表格-->
										<scTable
											ref="table"
											:api-obj="tableConfig.apiObj"
											:column="tableConfig.columns"
											:params="tableConfig.params"
											:table-name="$MENU.code.DATA_DETECTION"
											row-key="id"
											:checkbox="multiple"
											radio-check
											border
											highlight-current-row
											remote-sort
											:ref-query-form="refFormBar"
											remote-filter
											hide-column-setting
											hide-setting
											:pagination-layout="'total, prev, pager, next, jumper'"
											@selection-change="selectChange"
											@current-change="currentChange"
											@row-click="firClick"
											@row-dblclick="dblClick"
											@data-change="fatherDataChange"
											:tree-props="{ children: 'children' }"
										>
											<!--自定义列-->
											<template #name="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row)"
												>
													{{ scope.row.name }}
												</el-link>
											</template>

											<template #procure="{scope}">
												<span v-if="scope.row.procure===1">开启</span>
												<span v-else>未开启</span>
											</template>
											<template #produce="{scope}">
												<span v-if="scope.row.produce===1">开启</span>
												<span v-else>未开启</span>
											</template>
											<template #outsourcing="{scope}">
												<span v-if="scope.row.outsourcing===1">开启</span>
												<span v-else>未开启</span>
											</template>
											<template #end="{scope}">
												<span v-if="scope.row.end===1">是</span>
												<span v-else>否</span>
											</template>
											<template #cashFlow="{scope}">
												<span v-if="scope.row.cashFlow==='1'">√</span>
												<span v-else-if="scope.row.cashFlow==='0'"></span>
											</template>
											<!--			  数据禁用状态-->
											<template #flag="{scope}">
												<el-tag
													v-if="scope.row.flag === 1"
													class="myTag"
													type="success"
													:size="$store.state.global.uiSize"
												>
													启用
												</el-tag>
												<el-tag
													v-if="scope.row.flag === 0"
													class="myTag"
													type="danger"
													:size="$store.state.global.uiSize"
												>
													停用
												</el-tag>
											</template>
										</scTable>
									</el-main>
									<sy-switch-btn
										:text="'产品明细'"
										:opened="leftShow"
										bottom
										@show-left="handleShowLeft"
									/>
								</el-container>
							</pane>
							<!--已选数据-->
<!--							<pane-->
<!--								v-if="leftShow"-->
<!--								:size="paneSize"-->
<!--								max-size="70"-->
<!--								min-size="20"-->
<!--							>-->
<!--								<el-container-->
<!--									class="sonCont"-->
<!--									:style="multiple && 'padding-right: 10px'"-->
<!--								>-->
<!--									<el-main class="nopadding">-->
<!--										&lt;!&ndash;明细&ndash;&gt;-->
<!--										<scTable-->
<!--											ref="tableSon"-->
<!--											:api-obj="tableSonConfig.apiObj"-->
<!--											:column="tableSonConfig.columns"-->
<!--											:params="tableSonConfig.params"-->
<!--											row-key="id"-->
<!--											border-->
<!--											remote-sort-->
<!--										>-->
<!--											<template #fz_goodsName="{ scope }">-->
<!--												<el-link-->
<!--													type="primary"-->
<!--													@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"-->
<!--												>-->
<!--													{{ scope.row.fz_goodsName }}-->
<!--												</el-link>-->
<!--											</template>-->

<!--											<template #fz_stype="{ scope }">-->
<!--												<span v-if="scope.row.fz_stype === '1'">半成品</span>-->
<!--												<span v-if="scope.row.fz_stype === '0'">原材料</span>-->
<!--												<span v-if="scope.row.fz_stype === '2'">成品</span>-->
<!--											</template>-->
<!--										</scTable>-->
<!--									</el-main>-->
<!--								</el-container>-->
<!--							</pane>-->
						</splitpanes>
					</pane>
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumn"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
<!--			<el-button-->
<!--				:size="$store.state.global.uiSize"-->
<!--				@click="visible = false"-->
<!--			>-->
<!--				关闭-->
<!--			</el-button>-->
		</template>
		<Detail ref="refDetail" />
		<GoodsDetail ref="refGoodsDetail" />
		<ProducePlanDetail ref="refProducePlanDetail" />
		<ProduceProcessDetail ref="refProduceProcessDetail" />
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
export default defineComponent ({
	components: {GoodsDetail, Detail ,ProducePlanDetail,ProduceProcessDetail},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择校验' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar: null,
			type:'detail',
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '检验名称',
					prop: 'name',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.detection.getList,
				//默认传参
				params: {
					pageSize: 1000,
					pageNum: 1
				},
				//数据表格列配置
				columns: [
					{
						label: '检验名称',
						prop: 'name',
						fixed: 'left',
						width: 200,
					},
					{
						label: '检验编号',
						prop: 'number',
						type: 'input',
						max: 20,
						// default: true,
						width: 120,
					},
					{
						label: '检验标准',
						width: 120,
						prop: 'standard',
					},
					{
						label: '状态',
						width: 110,
						prop: 'flag',
					},
					{
						label: '采购',
						prop: 'procure',
						type: 'input',
						width: 110,
					},
					{
						label: '生产',
						prop: 'produce',
						type: 'input',
						width: 110,
					},
					{
						label: '委外',
						prop: 'outsourcing',
						type: 'input',
						width: 110,
					},
					// {
					// 	label: '商户id',
					// 	prop: 'shopId',
					// 	sortable: 'custom',
					// 	width: 110,
					// },
					// {
					// 	label: '父类id',
					// 	prop: 'parentId',
					// 	sortable: 'custom',
					// 	width: 110,
					// },
					// {
					// 	label: '是否终节点',
					// 	width: 100,
					// 	prop: 'end',
					// },
					{
						label: '检验员',
						width: 110,
						prop: 'detectionUserId',
					},
					{
						label: '标准值',
						width: 110,
						prop: 'meanVlaue',
					},
					{
						label: '正负差',
						width: 110,
						prop: 'positiveDeviation',
					},
					{
						label: '备注',
						width: 180,
						prop: 'remarks',
					},
				],
			},
			// tableSonConfig: {
			// 	//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
			// 	apiObj: null,
			// 	params: {},
			// 	//数据表格列配置
			// 	columns: [
			// 		{
			// 			label: '物料名称',
			// 			prop: 'fz_goodsName',
			// 			sortable: 'custom',
			// 			fixed: 'left',
			// 			width: 160,
			// 		},
			//
			// 		{
			// 			label: '物料编码',
			// 			prop: 'fz_goodsCode',
			// 			width: 180,
			// 			sortable: 'custom',
			// 		},
			//
			// 		{
			// 			label: '物料规格',
			// 			prop: 'fz_goodsSpec',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '物料型号',
			// 			prop: 'fz_goodsModel',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '产品类型',
			// 			prop: 'fz_stype',
			// 			sortable: 'custom',
			// 			width: 120,
			// 		},
			// 		{
			// 			label: '需求数量',
			// 			prop: 'demandQuantity',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '计量单位',
			// 			prop: 'unit',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '耗损率(%)',
			// 			prop: 'wearRate',
			// 			width: 130,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '实际数量',
			// 			prop: 'actualQuantity',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '辅计单位',
			// 			prop: 'assistUnit',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '换算数量',
			// 			prop: 'assistQuantity',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '来源',
			// 			prop: 'source',
			// 			width: 100,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '位置号',
			// 			prop: 'location',
			// 			width: 150,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '坏料尺寸',
			// 			prop: 'billetSize',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '坏料数量',
			// 			prop: 'billetNumber',
			// 			width: 120,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '提前期',
			// 			prop: 'leadTime',
			// 			width: 140,
			// 			sortable: 'custom',
			// 		},
			// 		{
			// 			label: '摘要',
			// 			prop: 'remark',
			// 			width: 150,
			// 			sortable: 'custom',
			// 		},
			// 	],
			// },
			//搜索表单配置
			//查询表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				queryForm: {},
				//表单项配置
				itemList: [
					//文本框
					{
						label: '检验名称',
						prop: 'name',
						type: 'input',
						max: 20,
						default: true,
					},
					{
						label: '检验编码',
						prop: 'number',
						type: 'input',
						max: 20,
						default: true,
					},
					{
						label: '状态',
						prop: 'flag',
						type: 'select',
						default: true,
						//下拉选项
						data: [
							{value: 1, label: '启用'},
							{value: 0, label: '停用'},
						]
					},
				],
			},
			isAllSon: false,
		})

		//显示弹窗
		const showDialog = (data,bool=false) => {
			state.visible = true
			state.selections = []
			state.selData = []
			if(data){
				Object.assign(state.tableConfig.params, data)
			}
			state.isAllSon = bool
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
			if(state.isAllSon && val.children){
				// selectAllSon(val.children)
			}
		}
		const selectAllSon = (list) => {
			list.forEach((child)=>{
				state.table.toggleRowSelection(child,true)
				if(child.children && child.children.length > 0){
					selectAllSon(child.children)
				}
			})
		}
		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		const findTree = (list,data) => {
			let sonList = []
			list.forEach((item)=>{
				if(item.id == data.id && item.children){
					sonList = findSonList(item.children)
				}
			})
			return sonList
		}
		const findSonList = (list) => {
			let sonList = []
			list.forEach((item)=>{
				sonList.push(item)
				if(item.children && item.children.length > 0){
					const graSonList = findSonList(item.children)
					if(graSonList){
						sonList.push(...graSonList)
					}
				}
			})
			return sonList
		}
		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				if(state.isAllSon){
					let findData = []
					state.selData.forEach((sel)=>{
						const sonList = findTree(state.table.tableData,sel)
						if(sonList){
							findData.push(...sonList)
						}
					})
					findData.forEach((item)=>{
						let found = state.selData.find(sel=>sel.id === item.id)
						if(!found){
							state.selData.push(item)
						}
					})
				}
				data = state.selData
			}
			console.log(data)
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}


		//行单击
		const firClick = (row) => {
			// //添加已选数据
			// setSelData(row)
			// if(row && !props.multiple){
			// 	//传入选中行和赋值的字段
			// 	emit('set-data', row)
			// 	hideDialog()
			// }
			// 多选
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
			handleRowClick(row)
		}

		//行双击
		const dblClick = (row) => {
			// 单选
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}

		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				// state['tableSon'].reload({processId: row.id})
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//将接口对象赋给子级表格
				// state.tableSonConfig.apiObj = proxy.$API.produceProcessDetail.selectDetail
				//传入父级ID
				// state.tableSonConfig.params = { processId: res.data.list[0].id }
				//根据主数据加载子数据
				handleRowClick(res.data.list[0])
			}else{
				proxy.$refs.tableSon.reload({ processId: -1 })
			}

		}

		// 全选
		const selectAll = (data) => {
			let count = -1; // 计数器
			function traverse(arr) {
				count++; // 每遍历到一个对象，计数器加1
				if (arr.children && arr.children.length > 0) {
					// 对象有 children 属性且 children 不为空数组
					for (let i = 0; i < arr.children.length; i++) {
						traverse(arr.children[i]); // 递归遍历子对象
					}
				}
			}
			for (let i = 0; i < data.length; i++) {
				traverse(data[i]); // 遍历每个对象
			}
			if (state.selections.length < count) {
				if (data.length > 0) {
					data.forEach((item) => {
						toggleSelect(item, true);
					});
				}
			} else if(count === state.selections.length) {
				proxy.$refs.table.clearSelection()
			}
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}


		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			console.log(data)
			/*todo 不知道需不需要删除子数组*/
			let selData = Object.assign({},data)
			if (selData.children) {
				delete selData.children
			}
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(selData));
			proxy.$refs.tableSel.toggleRowSelection(selData, false)
			if (selData && index === -1) {
				state.selData.push(selData)
			} else {
				state.selData = state.selData.filter(item => item.id !== selData.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		return{
			...toRefs(state),
			clearSelections,
			delSelData,
			tableSelselectChange,
			addToRight,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			firClick,
			hideDialog,
		}
	},
})
</script>
