
import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sn/output/getList`,
		name: "查询序列号",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
