//菜单对照表格列
const tableColumn = {
	//销售报价
	erp_sale_offer: {
		main: [
			{
				label: '报价单号',
				prop: 'orderNo',
				width: 155,
				sortable: true,
				fixed: 'left'
			},
			{
				label: '客户',
				prop: 'fz_customerName',
				width: 155,
				sortable: true,
			},
			{
				label: '跟踪状态',
				prop: 'trackStatus',
				data: [
					{label: '未报价',value: 0},
					{label: '已报价',value: 1},
					{label: '已结转',value: 2},
					{label: '已作废',value: 3},
				],
				width: 120,
				sortable: true,
			},
			{
				label: '联系人',
				prop: 'contacts',
				width: 80,
				sortable: true,
			},
			{
				label: '联系手机',
				prop: 'phone',
				width: 110,
				sortable: true,
			},
			{
				label: '联系电话',
				prop: 'tel',
				width: 100,
				sortable: true,
			},
			{
				label: '客户地址',
				prop: 'customerAddress',
				width: 170,
				sortable: true,
			},
			{
				label: '报价人',
				prop: 'fz_salesmanName',
				width: 80,
				sortable: true,
			},
			{
				label: '负责部门',
				prop: 'fz_departmentName',
				width: 90,
				sortable: true,
			},
			{
				label: '报价日期',
				prop: 'offerDate',
				width: 110,
				sortable: true,
			},

			{
				label: "有效期限",
				prop: "effectiveDate",
				width: 130,
				sortable: "custom",
			},
			{
				label: "合计金额",
				prop: "totalAmount",
				width: 110,
				sortable: "custom",
			},
			{
				label: "优惠金额",
				prop: "discount",
				width: 110,
				sortable: "custom",
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '计算方式',
				prop: 'priceMethod',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '交货日期',
				prop: 'deliveryDate',
				width: 100,
				sortable: true,
			},
			{
				label: '报价有效期',
				prop: 'effectiveDate',
				width: 110,
				sortable: true,
			},
			{
				label: '报价阶段',
				prop: 'fz_offerStageValue',
				width: 100,
				sortable: true,
			},
			{
				label: '销售商机',
				prop: 'fz_opportunityName',
				width: 100,
				sortable: true,
			},
			{
				label: '发票类型',
				prop: 'invoiceType',
				width: 140,
				sortable: true,
			},

			{
				label: '整单折扣(%)',
				prop: 'discount',
				width: 120,
				sortable: true,
			},
			{
				label: '下次提醒',
				prop: 'remind',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '单位抬头',
				prop: 'fz_companyName',
				width: 210,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 95,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 160,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				sortable: true,
				width: 150,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				sortable: true,
				width: 120,
			},
			{
				label: '数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 100,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 120,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '摘要',
				prop: 'summary',
				width: 150,
				sortable: true,
			},
		],
		clause: [
			{
				label: '条款主题',
				prop: 'theme',
				width: 200,
			},
			{
				label: '内容',
				prop: 'content',
				width: 300,
			},
		]
	},
	//销售合同
	erp_sale_contract: {
		main: [
			{
				label: '合同单号',
				prop: 'contractNo',
				width: 160,
				sortable: true,
				fixed: 'left'
			},
			{
				label: '客户',
				prop: 'fz_customerName',
				width: 150,
				sortable: true,
			},
			{
				label: '联系人',
				prop: 'contacts',
				width: 80,
				sortable: true,
			},
			{
				label: '联系手机',
				prop: 'phone',
				width: 110,
				sortable: true,
			},
			{
				label: '联系电话',
				prop: 'tel',
				width: 100,
				sortable: true,
			},
			{
				label: '送货地址',
				prop: 'shipAddress',
				width: 160,
				sortable: true,
			},
			{
				label: '合同状态',
				prop: 'contractStatus',
				width: 160,
				sortable: true,
			},
			{
				label: '开始日期',
				prop: 'startDate',
				width: 130,
				sortable: 'custom',
			},
			{
				label: '结束日期',
				prop: 'endDate',
				width: 130,
				sortable: 'custom',
			},
			{
				label: '签约日期',
				prop: 'creationDate',
				width: 130,
				sortable: 'custom',
			},
			{
				label: '合计金额',
				prop: 'collectAmount',
				width: 110,
				sortable: 'custom',
			},
			{
				label: '优惠金额',
				prop: 'discount',
				width: 110,
				sortable: 'custom',
			},
			{
				label: '发票类型',
				prop: 'invoiceType',
				width: 135,
				sortable: true,
			},
			{
				label: '交货日期',
				prop: 'deliveryDate',
				width: 100,
				sortable: true,
			},
			{
				label: '业务员',
				prop: 'fz_salesmanName',
				width: 90,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 90,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '整单折扣(%)',
				prop: 'discount',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '合同类型',
				prop: 'contractType',
				data: [
					{label: '单次销售',value: 0},
					{label: '年度框架',value: 1},
				],
				width: 100,
				sortable: true,
			},
			{
				label: '收款期限',
				prop: 'collectDate',
				width: 100,
				sortable: true,
			},
			{
				label: '收款金额',
				prop: 'collectAmount',
				width: 110,
				sortable: true,
			},
			{
				label: '单位抬头',
				prop: 'fz_companyName',
				width: 200,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 160,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				sortable: true,
				width: 150,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				sortable: true,
				width: 120,
			},
			{
				label: '数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 140,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 120,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 140,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '摘要',
				prop: 'summary',
				width: 150,
				sortable: true,
			},
		],
		clause: [
			{
				label: '条款主题',
				prop: 'theme',
				width: 200,
			},
			{
				label: '内容',
				prop: 'content',
				width: 300,
			},
		]
	},
	//销售订单
	erp_sale_order: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '销售合同',
				prop: 'contractNo',
				width: 200,
				sortable: true,
				//fixed: 'left',
			},
			{
				label: '客户',
				prop: 'fz_customerName',
				width: 150,
				sortable: true,
			},
			{
				label: '联系人',
				prop: 'contacts',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '联系手机',
			// 	prop: 'phone',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '联系电话',
				prop: 'tel',
				width: 120,
				sortable: true,
			},
			{
				label: '送货地址',
				prop: 'shipAddress',
				width: 150,
				sortable: true,
			},
			{
				label: '发票类型',
				prop: 'invoiceType',
				width: 100,
				sortable: true,
			},
			{
				label: '生产状态',
				prop: 'processStatus',
				width: 100,
				sortable: true,
			},
			// {
			// 	label: '交货日期',
			// 	prop: 'deliveryDate',
			// 	width: 130,
			// 	sortable: true,
			// },
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '业务员',
				prop: 'fz_salesmanName',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '下单日期',
				prop: 'creationDate',
				width: 140,
				sortable: true,
			},
			// {
			// 	label: '整单折扣[%]',
			// 	prop: 'discount',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '订单类型',
			// 	prop: 'orderType',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '收款期限',
				prop: 'collectDate',
				width: 130,
				sortable: true,
			},
			{
				label: '收款金额',
				prop: 'collectAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '开票状态',
				prop: 'ticketStatus',
				width: 130,
				sortable: true,
			},
			{
				label: '开票金额',
				prop: 'ticketAmount',
				width: 150,
				sortable: true,
			},
			{
				label: "交货日期",
				prop: "deliveryDate",
				width: 130,
				sortable: "custom",
			},

			{
				label: '单位抬头',
				prop: 'fz_companyName',
				width: 150,
				sortable: true,
			},
			{
				label: '发货方式',
				prop: 'fz_shipMethodValue',
				width: 120,
				sortable: true,
			},

			{
				label: '物流公司',
				prop: 'fz_logisticsName',
				width: 140,
				sortable: true,
			},
			{
				label: '物流联系人',
				prop: 'contactsName',
				width: 130,
				sortable: true,
			},
			{
				label: '物流电话',
				prop: 'contactsTel',
				width: 130,
				sortable: true,
			},
			{
				label: '运单号',
				prop: 'waybillNo',
				width: 130,
				sortable: true,
			},
			{
				label: '运输费用',
				prop: 'transportCost',
				width: 120,
				sortable: true,
			},
			{
				label: '付款方式',
				prop: 'paymentMethod',
				width: 120,
				sortable: true,
			},
			{
				label: '付款账户',
				prop: 'fz_paymentAccountName',
				width: 130,
				sortable: true,
			},
			{
				label: '发货状态',
				prop: 'invoiceStatus',
				data: [
					{label: '未发货', value: 0},
					{label: '部分发货', value: 1},
					{label: '全部发货', value: 2},
					{label: '已终止', value: 3}
				],
				width: 120,
				sortable: true,
			},
			{
				label: '终止原因',
				prop: 'reason',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '退货状态',
			// 	prop: 'refundStatus',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '退货金额',
			// 	prop: 'refundAmount',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '收款状态',
				prop: 'receiptStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '合计金额',
				prop: 'totalAmount',
				width: 120,
				sortable: true,
			},
			{
				label: "优惠金额",
				prop: "discount",
				width: 110,
				sortable: "custom",
			},
			{
				label: "实际金额",
				prop: "actualAmount",
				width: 110,
				sortable: "custom",
			},
			// {
			// 	label: '减免金额',
			// 	prop: 'reductionAmount',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '冲抵金额',
			// 	prop: 'offsetAmount',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: "退货金额",
				prop: "refundAmount",
				width: 110,
				sortable: "custom",
			},
			{
				label: '应收金额',
				prop: 'receivableAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '已收金额',
				prop: 'receiptAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '未收金额',
				prop: 'uncollectAmount',
				width: 120,
				sortable: true,
			},

			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				sortable: true,
				width: 150,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				sortable: true,
				width: 120,
			},
			{
				label: '主单位',
				prop: 'unit',
				sortable: true,
				width: 120,
			},

			{
				label: '发货仓库',
				prop: 'fz_depotName',
				sortable: true,
				width: 130,
			},
			// {
			// 	label: '物品批号',
			// 	prop: 'batchNumber',
			// 	sortable: true,
			// 	width: 130,
			// },
			// {
			// 	label: 'SN序号',
			// 	prop: 'snNumber',
			// 	sortable: true,
			// 	width: 130,
			// },
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},

			{
				label: '辅助单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅助单位数量',
				prop: 'assistQuantity',
				width: 140,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 100,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 120,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '发货状态',
				prop: 'invoiceStatus',
				data: [
					{label: '未发货', value: 0},
					{label: '部分发货', value: 1},
					{label: '全部发货', value: 2},
					{label: '已终止', value: 3}
				],
				width: 120,
				sortable: true,
			},
			{
				label: '已发数量',
				prop: 'invoiceQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '预发数量',
				prop: 'preInvoiceQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '未发数量',
				prop: 'notInvoiceQuantity',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '退货状态',
			// 	prop: 'refundStatus',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '已退数量',
			// 	prop: 'refundQuantity',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '预退数量',
			// 	prop: 'preReturnQuantity',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '未退数量',
			// 	prop: 'notRefundQuantity',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//销售发货
	erp_sale_invoice: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
				fixed: 'left'
			},
			{
				label: '销售订单',
				prop: 'fz_saleOrderNo',
				width: 180,
				sortable: true,
			},
			{
				label: '客户',
				prop: 'fz_customerName',
				sortable: 'custom',
				width: 200,
			},
			{
				label: '发货方式',
				prop: 'fz_shipMethodValue',
				width: 120,
				sortable: true,
			},
			{
				label: '物流公司',
				prop: 'fz_logisticsName',
				width: 130,
				sortable: true,
			},
			{
				label: '联系人',
				prop: 'contactsName',
				width: 120,
				sortable: true,
			},
			{
				label: '联系电话',
				prop: 'contactsTel',
				width: 140,
				sortable: true,
			},
			{
				label: '发货地址',
				prop: 'address',
				width: 140,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				data: [
					{label: '未出库',value: 0},
					{label: '已出库',value: 1},
					{label: '已退库',value: 2},
				],
				width: 120,
				sortable: true,
			},
			{
				label: '签收状态',
				prop: 'signStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '质检状态',
				prop: 'qualityStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '运单号',
				prop: 'waybillNo',
				width: 140,
				sortable: true,
			},
			{
				label: '运输费用',
				prop: 'transportCost',
				width: 120,
				sortable: true,
			},
			{
				label: '付款方式',
				prop: 'paymentMethod',
				data: [
					{label: '现金',value: 1},
					{label: '转账',value: 2},
					{label: '支票',value: 3},
					{label: '其他',value: 4},
				],
				width: 120,
				sortable: true,
			},
			{
				label: '付款账户',
				prop: 'fz_paymentAccountName',
				width: 140,
				sortable: true,
			},
			{
				label: '发货人',
				prop: 'fz_deliverName',
				width: 120,
				sortable: true,
			},
			{
				label: '发货时间',
				prop: 'deliverTime',
				width: 150,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'illustrate',
				width: 160,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				sortable: true,
				width: 150,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				sortable: true,
				width: 120,
			},
			{
				label: '发货仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '物品批号',
				prop: 'batchNumber',
				width: 130,
				sortable: true,
			},
			{
				label: 'SN序号',
				prop: 'snNumber',
				width: 130,
				sortable: true,
			},
			{
				label: '发货数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '小计成本(￥)',
				prop: 'sumCost',
				width: 120,
				sortable: true,
			},
			{
				label: '小计金额(￥)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//销售退货
	erp_sale_refund: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '销售订单',
				prop: 'fz_saleOrderNo',
				width: 180,
				sortable: true,
			},
			{
				label: '客户',
				prop: 'fz_customerName',
				width: 150,
				sortable: true,
			},
			{
				label: '业务员',
				prop: 'fz_salesmanName',
				width: 85,
				sortable: true,
			},
			{
				label: '质检状态',
				prop: 'qualityStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '退货日期',
				prop: 'refundDate',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '整单折扣[%]',
				prop: 'discount',
				width: 120,
				sortable: true,
			},
			{
				label: '退货类型',
				prop: 'refundType',
				data: [
					{label: '换货补发', value: 0},
					{label: '退货抵扣', value: 1},
					{label: '退货退款', value: 2},
					{label: '未收货退款', value: 3},
				],
				width: 100,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				width: 110,
				data: [
					{label: '未入库',value: 0},
					{label: '无需入库',value: 1},
					{label: '已入库',value: 2},
				],
				sortable: 'custom',
			},
			{
				label: '付款方式',
				prop: 'paymentMethod',
				width: 100,
				sortable: true,
			},
			{
				label: '退款账户',
				prop: 'fz_refundAccountName',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 150,
			// 	sortable: true,
			// },
			{
				label: '单位抬头',
				prop: 'fz_companyName',
				width: 160,
				sortable: true,
			},
			{
				label: '退货状态',
				prop: 'refundStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '终止原因',
				prop: 'reason',
				width: 120,
				sortable: true,
			},
			{
				label: '付款状态',
				prop: 'paymentStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '合计金额',
				prop: 'totalAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '减免金额',
				prop: 'reductionAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '应付金额',
				prop: 'payableAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '已付金额',
				prop: 'paymentAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '未付金额',
				prop: 'unpaidAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 170,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				sortable: true,
				width: 150,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				sortable: true,
				width: 120,
			},
			{
				label: '收货仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '物品批号',
				prop: 'batchNumber',
				width: 130,
				sortable: true,
			},
			{
				label: 'SN序号',
				prop: 'snNumber',
				width: 130,
				sortable: true,
			},
			{
				label: '退货数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 100,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 120,
				sortable: true,
			},
			{
				label: '未税成本(￥)',
				prop: 'cost',
				width: 120,
				sortable: true,
			},
			{
				label: '含税成本(￥)',
				prop: 'taxCost',
				width: 120,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				width: 120,
				disable: true,
			},
			{
				label: '入库数量',
				prop: 'enterQuantity',
				width: 120,
				disable: true,
			},
			{
				label: '摘要',
				prop: 'summary',
				width: 150,
				sortable: true,
			},
		],
	},
	//采购申请
	erp_purchase_apply: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 210,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '产品名称',
				prop: "fz_saleOrderSonIdsName",
				width: 180,
				sortable: true,
			},
			{
				label: '申请人',
				prop: 'fz_applyUserName',
				width: 100,
				sortable: true,
			},
			{
				label: '业务部门',
				prop: 'fz_departmentName',
				width: 120,
				sortable: true,
			},
			{
				label: '申请事由',
				prop: 'reason',
				width: 160,
				sortable: true,
			},
			{
				label: '采购人',
				prop: 'fz_purUserName',
				width: 95,
				sortable: true,
			},
			{
				label: '采购期限',
				prop: 'effectiveDate',
				width: 200,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},
			{
				label: "结转状态",
				prop: "trackStatus",
				data: [
					{label: '未结转',value: 0},
					{label: '部分结转',value: 1},
					{label: '全部结转',value: 2},
				],
				width: 110,
				sortable: "custom",
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 130,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 220,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品类别',
				prop: 'fz_classifyName',
				width: 120,
			},
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 200,
				sortable: true,
			},
			{
				label: '材料材质',
				prop: 'fz_goodsMaterial',
				width: 160,
			},
			{
				label: '牌号',
				prop: 'fz_brandNumber',
				width: 140,
			},
			{
				label: '引用标准',
				prop: 'standard',
				sortable: true,
				width: 120,
			},
			{
				label: '供应商',
				prop: 'fz_supplierName',
				width: 160,
			},
			{
				label: '收货仓库',
				prop: 'fz_depotName',
				width: 120,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: "结转状态",
				prop: "turnState",
				data: [
					{label: '未结转',value: 0},
					{label: '部分结转',value: 1},
					{label: '全部结转',value: 2},
				],
				width: 110,
				sortable: "custom",
			},
			{
				label: '当前库存',
				prop: 'stock',
				width: 110,
				sortable: true,
			},
			{
				label: '申请数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: "已结转数",
				prop: "transferQty",
				width: 110,
				sortable: "custom",
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 120,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '其他要求',
			// 	prop: 'other',
			// 	width: 150,
			// 	sortable: true,
			// },
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//采购合同
	erp_purchase_contract: {
		main: [
			{
				label: '合同编号',
				prop: 'contractNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '供应商',
				prop: 'fz_supplierName',
				width: 130,
				sortable: true,
			},
			{
				label: '联系人',
				prop: 'contacts',
				width: 100,
				sortable: true,
			},
			{
				label: '联系手机',
				prop: 'phone',
				width: 120,
				sortable: true,
			},
			{
				label: '联系电话',
				prop: 'tel',
				width: 120,
				sortable: true,
			},
			{
				label: '交货地址',
				prop: 'deliveryAddress',
				width: 150,
				sortable: true,
			},
			{
				label: '合同状态',
				prop: 'contractStatus',
				data: [
					{label: '未签约',value: 0},
					{label: '执行中',value: 1},
					{label: '已结束',value: 2},
					{label: '已作废',value: 3},
				],
				width: 160,
				sortable: true,
			},
			{
				label: '签约日期',
				prop: 'creationDate',
				width: 130,
				sortable: "custom",
			},
			{
				label: '合计金额',
				prop: 'totalAmount',
				width: 110,
				sortable: "custom",
			},
			{
				label: '优惠金额',
				prop: 'discount',
				sortable: "custom",
				width: 110,
			},
			{
				label: '发票类型',
				prop: 'invoiceType',
				width: 120,
				sortable: true,
			},
			{
				label: '业务员',
				prop: 'fz_salesmanName',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 120,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 140,
				sortable: true,
			},
			{
				label: '整单折扣(%)',
				prop: 'discount',
				width: 120,
				sortable: true,
			},
			{
				label: '交货日期',
				prop: 'deliveryDate',
				width: 150,
				sortable: true,
			},
			{
				label: '付款期限',
				prop: 'paymentDate',
				width: 140,
				sortable: true,
			},
			{
				label: '付款金额',
				prop: 'paymentAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '预付款',
				prop: 'advanceAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '保证金',
				prop: 'bondAmount',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '单位抬头',
				prop: 'fz_companyName',
				width: 150,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 170,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '采购数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 150,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 100,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 150,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 150,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 150,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 150,
				sortable: true,
			},
			{
				label: '摘要',
				prop: 'abstracts',
				width: 150,
				sortable: true,
			},
		],
		clause: [
			{
				label: '条款主题',
				prop: 'theme',
				width: 200,
			},
			{
				label: '内容',
				prop: 'content',
				width: 300,
			},
		]
	},
	//采购订单
	erp_purchase_order: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				sortable: true,
				fixed: 'left',
			},
			{
				label: '采购合同',
				prop: 'contractNo',
				width: 200,
				sortable: true,
			},
			{
				label: '供应商',
				prop: 'fz_supplierName',
				sortable: true,
			},
			{
				label: '交货地址',
				prop: 'deliveryAddress',
				width: 150,
				sortable: true,
			},
			{
				label: '发票类型',
				prop: 'invoiceType',
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				sortable: true,
			},
			{
				label: '业务员',
				prop: 'fz_salesmanName',
				width: 100,
				sortable: true,
			},
			{
				label: '业务部门',
				prop: 'fz_departmentName',
				width: 120,
				sortable: true,
			},
			{
				label: '交货日期',
				prop: 'deliveryDate',
				width: 130,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 120,
				sortable: true,
			},
			{
				label: '下单日期',
				prop: 'creationDate',
				width: 140,
				sortable: true,
			},
			{
				label: '付款期限',
				prop: 'paymentDate',
				width: 130,
				sortable: true,
			},
			{
				label: '支付方式',
				prop: 'freightPayment',
				width: 120,
				sortable: true,
			},
			{
				label: '资金账户',
				prop: 'fz_freightAccountName',
				width: 150,
				sortable: true,
			},
			{
				label: '订单金额',
				prop: 'paymentAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '开票状态',
				prop: 'ticketStatus',
				width: 150,
				sortable: true,
			},
			{
				label: '开票金额',
				prop: 'ticketAmount',
				width: 150,
				sortable: true,
			},
			{
				label: '收货状态',
				prop: 'receiptStatus',
				data: [
					{label: '未收货',value: 0},
					{label: '部分收货',value: 1},
					{label: '全部收货',value: 2},
					{label: '已终止',value: 3},
				],
				width: 120,
				sortable: true,
			},
			{
				label: '终止原因',
				prop: 'reason',
				width: 120,
				sortable: true,
			},

			{
				label: '退货状态',
				prop: 'refundStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '退货金额',
				prop: 'refundAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '付款状态',
				prop: 'paymentStatus',
				data: [
					{label: '未付款',value: 0},
					{label: '部分付款',value: 1},
					{label: '全部付款',value: 2},
				],
				width: 120,
				sortable: true,
			},
			{
				label: '合计金额',
				prop: 'paymentAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '优惠金额',
				prop: 'discount',
				width: 110,
				sortable: 'custom',
			},
			{
				label: '冲抵金额',
				prop: 'offsetAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '应付金额',
				prop: 'copewithAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '已付金额',
				prop: 'amountPaid',
				width: 120,
				sortable: true,
			},
			{
				label: '未付金额',
				prop: 'unpaidAmount',
				width: 120,
				sortable: true,
			},

			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 210,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '材料材质',
				prop: "fz_goodsMaterial",
				width: 120,
			},
			{
				label: "牌号",
				prop:"fz_brandNumber",
				width: 120
			},
			{
				label: '引用标准',
				prop: "standard",
				width: 120,
			},
			{
				label: '数量',
				prop: "quantity",
				width: 120,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅助单位',
				prop: 'assistUnit',
				width: 130,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 150,
				sortable: true,
			},
			{
				label: '收货状态',
				prop: 'receiptStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '已收数量',
				prop: 'receiptQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '预收数量',
				prop: 'preReceiptQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '未收数量',
				prop: 'notReceiptQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 150,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 120,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 150,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 150,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 150,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 150,
				sortable: true,
			},
			{
				label: '退货状态',
				prop: 'refundStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '已退数量',
				prop: 'refundQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '预退数量',
				prop: 'preReturnQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '未退数量',
				prop: 'notRefundQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '开票状态',
				prop: 'ticketStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '已开票数',
				prop: 'ticketQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '预开票数',
				prop: 'preTicketQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '未开票数',
				prop: 'notTicketQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'abstracts',
				width: 150,
				sortable: true,
			},
		],
		clause: [
			{
				label: '条款主题',
				prop: 'theme',
				width: 200,
			},
			{
				label: '内容',
				prop: 'content',
				width: 300,
			},
		]
	},
	//采购收货
	erp_purchase_receipt: {
		main: [
			{
				label: '订单编号',
				prop: 'receiptNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '采购订单',
				prop: 'fz_purOrderNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '收货人',
				prop: 'fz_receiverName',
				width: 90,
				sortable: true,
			},
			{
				label: '收货日期',
				prop: 'receiptTime',
				width: 180,
				sortable: true,
			},
			{
				label: '收货说明',
				prop: 'illustrate',
				width: 190,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				data: [
					{label: '未入库',value: 0},
					{label: '部分入库',value: 1},
					{label: '全部入库',value: 2},
					{label: '已退库',value: 3},
				],
				width: 100,
				sortable: true,
			},
			{
				label: "供应商",
				prop: "fz_supplierName",
				width: 200,
			},
			{
				label: '质检状态',
				prop: 'qualityStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'illustrate',
				width: 180,
				sortable: 'custom',
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '收货仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '物品批号',
				prop: 'batchNumber',
				width: 130,
				sortable: true,
			},
			{
				label: 'SN序号',
				prop: 'snNumber',
				width: 130,
				sortable: true,
			},
			{
				label: '收货数量',
				prop: 'quantity',
				width: 130,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '成本(￥)',
				prop: 'cost',
				width: 120,
				sortable: true,
			},
			{
				label: '小计金额(￥)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//采购退货
	erp_purchase_refund: {
		main: [
			{
				label: '订单编号',
				prop: 'refundNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '采购订单',
				prop: 'fz_purOrderNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '供应商',
				prop: 'fz_supplierName',
				width: 150,
				sortable: true,
			},
			{
				label: '业务员',
				prop: 'fz_salesmanName',
				width: 100,
				sortable: true,
			},
			{
				label: '退货日期',
				prop: 'refundDate',
				width: 100,
				sortable: true,
			},
			{
				label: '质检状态',
				prop: 'qualityStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 140,
				sortable: true,
			},
			{
				label: '整单折扣(%)',
				prop: 'discount',
				width: 120,
				sortable: true,
			},
			{
				label: '退货类型',
				prop: 'refundType',
				data: [
					{label: '换货补发',value: 0},
					{label: '退货抵扣',value: 1},
					{label: '退货退款',value: 2},
					{label: '未收货退款',value: 3},
				],
				width: 100,
				sortable: true,
			},
			{
				label: '付款方式',
				prop: 'paymentMethod',
				width: 100,
				sortable: true,
			},
			{
				label: '退款账户',
				prop: 'fz_refundAccountName',
				width: 140,
				sortable: true,
			},
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 150,
			// 	sortable: true,
			// },
			{
				label: '单位抬头',
				prop: 'fz_companyName',
				width: 130,
				sortable: true,
			},
			{
				label: '退货状态',
				prop: 'refundStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				data: [
					{label: '未出库',value: 0},
					{label: '无需出库',value: 1},
					{label: '已出库',value: 2},
				],
				width: 110,
				sortable: 'custom',
			},
			{
				label: '终止原因',
				prop: 'reason',
				width: 120,
				sortable: true,
			},
			{
				label: '收款状态',
				prop: 'receiptStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '合计金额',
				prop: 'totalAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '减免金额',
				prop: 'reductionAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '应收金额',
				prop: 'receivableAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '已收金额',
				prop: 'receiptAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '未收金额',
				prop: 'uncollectAmount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '出货仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '物品批号',
				prop: 'batchNumber',
				width: 130,
				sortable: true,
			},
			{
				label: 'SN序号',
				prop: 'snNumber',
				width: 130,
				sortable: true,
			},
			{
				label: '退货数量',
				prop: 'quantity',
				width: 150,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 180,
				sortable: true,
			},
			{
				label: '未税单价(￥)',
				prop: 'price',
				width: 150,
				sortable: true,
			},
			{
				label: '税率(%)',
				prop: 'taxRate',
				width: 100,
				sortable: true,
			},
			{
				label: '含税单价(￥)',
				prop: 'taxPrice',
				width: 150,
				sortable: true,
			},
			{
				label: '税额(￥)',
				prop: 'taxAmount',
				width: 180,
				sortable: true,
			},
			{
				label: '货款(￥)',
				prop: 'goodsAmount',
				width: 180,
				sortable: true,
			},
			{
				label: '小计(￥)',
				prop: 'subtotal',
				width: 180,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				width: 120,
				disable: true,
			},
			{
				label: '出库数量',
				prop: 'outQuantity',
				width: 120,
				disable: true,
			},
			{
				label: '摘要',
				prop: 'abstracts',
				width: 150,
				sortable: true,
			},
		],
	},
	//库存汇总
	erp_stock_summary: {
		main: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 200,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '物品类型',
				prop: 'fz_goodsStype',
				width: 100,
				sortable: true,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				width: 130,
				sortable: true,
			},
			{
				label: '仓库名称',
				prop: 'fz_depotName',
				width: 150,
				sortable: true,
			},
			{
				label: '仓库编码',
				prop: 'fz_depotCode',
				width: 130,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'fz_mainUnit',
				width: 80,
				sortable: true,
			},
			{
				label: '库存数',
				prop: 'number',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 130,
				sortable: true,
			},
			{
				label: '重量',
				prop: 'weight',
				sortable: true,
				width: 120,
			},
		],
	},
	//领料单
	erp_stock_produce_material: {
		main: [
			{
				label: '领料单号',
				prop: 'orderNo',
				width: 170,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '加工单',
				prop: 'fz_processNo',
				width: 170,
				sortable: true,
			},
			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 90,
				sortable: true,
			},
			{
				label: '领料日期',
				prop: 'eventDate',
				width: 100,
				sortable: true,
			},
			{
				label: '领料类型',
				prop: 'collarType',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '领取状态',
				prop: 'collarStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				data: [
					{label: '未出库',value: 0},
					{label: '部分出库',value: 1},
					{label: '全部出库',value: 2},
					{label: '已退库',value: 3},
				],
				width: 110,
				sortable: 'custom',
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物料名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '出货仓库',
				prop: 'fz_depotName',
				width: 120,
				sortable: true,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '物料型号',
				prop: 'fz_goodsModel',
				width: 120,
				sortable: true,
			},
			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '领取数量',
				prop: 'collarQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 110,
				sortable: 'custom',
			},
			{
				label: '申领数量',
				prop: 'collarQuantity',
				width: 110,
				sortable: 'custom',
			},
			{
				label: '出库数量',
				prop: 'outQuantity',
				width: 110,
				sortable: 'custom',
			},
			{
				label: '成本单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '领料备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				width: 120,
				sortable: true,
			},
		],
	},
	//退料单
	erp_stock_produce_refund: {
		main: [
			{
				label: '退料单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '加工单',
				prop: 'fz_processNo',
				width: 160,
				sortable: true,
			},
			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 90,
				sortable: true,
			},
			{
				label: '退料日期',
				prop: 'eventDate',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 95,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 95,
				sortable: true,
			},
			{
				label: '退还状态',
				prop: 'refundStatus',
				width: 95,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物料名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '收货仓库',
				prop: 'fz_depotName',
				width: 120,
				sortable: true,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},

			{
				label: '退料数量',
				prop: 'refundQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '成本单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '退料备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				width: 120,
				sortable: true,
			},
		],
	},
	//报损单
	erp_stock_loss: {
		main: [
			{
				label: '报损单号',
				prop: 'lossNo',
				width: 160,
				sortable: true,
			},
			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 90,
				sortable: true,
			},
			{
				label: '报损日期',
				prop: 'eventDate',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '确认状态',
				prop: 'confirmStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '报损仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '报损数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//报溢单
	erp_stock_flow: {
		main: [
			{
				label: '报溢单号',
				prop: 'flowNo',
				width: 160,
				sortable: true,
			},
			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 90,
				sortable: true,
			},
			{
				label: '报溢日期',
				prop: 'eventDate',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '确认状态',
				prop: 'confirmStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '报溢仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '报溢数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//仓库盘点
	erp_stock_inventory: {
		main: [
			{
				label: '盘点单号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
			},
			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 100,
				sortable: true,
			},
			{
				label: '盘点日期',
				prop: 'eventDate',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '确认状态',
				prop: 'confirmStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '盘点仓库',
				prop: 'fz_depotName',
				sortable: true,
				fixed: 'left',
				width: 130,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},

			{
				label: '当前库存数',
				prop: 'depotQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '供应商',
				prop: 'fz_supplierName',
				width: 160,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '实际数量',
				prop: 'actualQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '盘点差值',
				prop: 'differQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},

			{
				label: '单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//仓库调拨
	erp_stock_transfer: {
		main: [
			{
				label: '调拨单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
			},
			{
				label: '调入仓库',
				prop: 'fz_depotName',
				width: 130,
				sortable: true,
			},
			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 100,
				sortable: true,
			},
			{
				label: '调拨日期',
				prop: 'eventDate',
				width: 100,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 100,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 100,
				sortable: true,
			},
			{
				label: '确认状态',
				prop: 'confirmStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '调出仓库',
				prop: 'fz_depotName',
				width: 120,
				sortable: true,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '调拨数量',
				prop: 'transferQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '物品材质',
				prop: 'fz_goodMaterial',
				sortable: true,
				width: 120,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//生产计划
	erp_produce_plan: {
		main: [
			{
				label: '计划单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '销售订单',
				prop: 'fz_saleOrderNo',
				width: 160,
				sortable: true,
			},
			{
				label: '客户',
				prop: 'fz_customerName',
				width: 150,
				sortable: true,
			},
			{
				label: '交货日期',
				prop: 'deliveryDate',
				width: 110,
				sortable: true,
			},
			{
				label: '计划开始日期',
				prop: 'startDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划结束日期',
				prop: 'endDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划工时',
				prop: 'workHour',
				width: 100,
				sortable: true,
			},
			{
				label: '加工方式',
				prop: 'method',
				width: 100,
				sortable: true,
			},
			{
				label: '物料拆分',
				prop: 'material',
				width: 100,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '生产状态',
				prop: 'produceStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '是否申购',
				prop: 'isPurchase',
				width: 100,
				sortable: true,
			},
			{
				label: '是否设计',
				prop: 'isDesign',
				width: 100,
				sortable: true,
			},
			{
				label: '是否加工',
				prop: 'isProcess',
				width: 100,
				sortable: true,
			},
			{
				label: '是否组装',
				prop: 'isAssemble',
				width: 100,
				sortable: true,
			},
			{
				label: '是否有子计划',
				prop: 'isSubplan',
				width: 120,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '生产数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '摘要',
				prop: 'summary',
				width: 150,
				sortable: true,
			},
		],
	},
	//产品设计
	erp_produce_design: {
		main: [
			{
				label: '设计单号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '生产计划单号',
				prop: 'fz_planNo',
				width: 170,
				sortable: true,
			},
			{
				label: '计划开始日期',
				prop: 'startDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划结束日期',
				prop: 'endDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划工时',
				prop: 'workHour',
				width: 100,
				sortable: true,
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 100,
				sortable: true,
			},
			{
				label: '设计师',
				prop: 'fz_designerName',
				width: 180,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 200,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '生产数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '设计内容',
				prop: 'designContent',
				width: 180,
				sortable: true,
			},
			{
				label: '生产工艺',
				prop: 'fz_routeName',
				width: 150,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '设计状态',
				prop: 'designStatus',
				width: 110,
				sortable: true,
			},
			{
				label: '是否申购',
				prop: 'isPurchase',
				width: 110,
				sortable: true,
			},
			{
				label: '是否生成加工单',
				prop: 'isProcess',
				width: 130,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 90,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 170,
			},
		],
	},
	//加工单
	erp_produce_process: {
		main: [
			{
				label: '加工单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '生产计划',
				prop: 'fz_planNo',
				width: 160,
				sortable: true,
			},
			{
				label: '计划开始日期',
				prop: 'startDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划结束日期',
				prop: 'endDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划工时',
				prop: 'workHour',
				width: 100,
				sortable: true,
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 160,
			// 	sortable: true,
			// },
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 180,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '生产数量',
				prop: 'quantity',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '设计内容',
				prop: 'designContent',
				width: 160,
				sortable: true,
			},
			{
				label: '质检要求',
				prop: 'qualityCheck',
				width: 160,
				sortable: true,
			},
			{
				label: '生产工艺',
				prop: 'fz_routeName',
				width: 160,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '加工状态',
				prop: 'processStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '终止原因',
				prop: 'reason',
				width: 120,
				sortable: true,
			},
			{
				label: '是否申购',
				prop: 'isPurchase',
				width: 100,
				sortable: true,
			},
			{
				label: '原料成本【元】',
				prop: 'materialCost',
				width: 150,
				sortable: true,
			},
			{
				label: '人工成本【元】',
				prop: 'laborCost',
				width: 150,
				sortable: true,
			},
			{
				label: '分摊费用【元】',
				prop: 'shareCost',
				width: 150,
				sortable: true,
			},
			{
				label: '合计成本【元】',
				prop: 'totalCost',
				width: 150,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物料类型',
				prop: 'fz_stype',
				sortable: true,
				fixed: 'left',
				width: 120,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 200,
				sortable: true,
			},
			{
				label: '仓库',
				prop: 'fz_depotName',
				width: 120,
				sortable: true,
			},

			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '领用数量',
				prop: 'issueQty',
				width: 120,
				sortable: true,
			},
			{
				label: '使用数量',
				prop: 'usageQty',
				width: 120,
				edit: 'number'
			},

			{
				label: '已退数量',
				prop: 'refundQty',
				width: 120,
			},

			{
				label: '退料状态',
				prop: 'refundStatus',
				width: 120,
			},

			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '实际数量',
			// 	prop: 'actualQuantity',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '耗损数量',
				prop: 'lossQuantity',
				disable: true,
				width: 120,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 120,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '摘要',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//验收单
	erp_produce_check: {
		main: [
			{
				label: '验收单号',
				prop: 'orderNo',
				width: 210,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '加工单号',
				prop: 'fz_processNo',
				width: 210,
				sortable: true,
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 120,
				sortable: true,
			},
			{
				label: '验收日期',
				prop: 'checkDate',
				width: 130,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 150,
				sortable: true,
			},
			{
				label: '生产数量',
				prop: 'produceQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '合格数量',
				prop: 'qualifyQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '合格率',
				prop: 'qualifyRate',
				width: 100,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 210,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品类型',
				prop: 'fz_goodsStype',
				width: 120,
				sortable: true,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},

			{
				label: '未用数量',
				prop: 'remainingQuantity',
				width: 140,
				sortable: true,
			},
			{
				label: '本次使用数量',
				prop: 'currentUsageQuantity',
				width: 140,
				sortable: true,
			},

			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 120,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '驳回原因',
				prop: 'reason',
				width: 150,
				sortable: true,
			},
		],
	},
	//委外加工单
	erp_produce_outprocess: {
		main: [
			{
				label: '加工单号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
				fixed: 'left'
			},
			{
				label: '生产计划单号',
				prop: 'fz_planNo',
				width: 180,
				sortable: true,
			},
			{
				label: '计划开始日期',
				prop: 'startDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划结束日期',
				prop: 'endDate',
				width: 130,
				sortable: true,
			},
			{
				label: '计划工时',
				prop: 'workHour',
				width: 100,
				sortable: true,
			},
			{
				label: '委外工厂',
				prop: 'fz_supplierName',
				width: 180,
				sortable: true,
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 100,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 150,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '生产数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '单价',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '合计金额',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '加工费',
				prop: 'processCost',
				width: 120,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '生产状态',
				prop: 'processStatus',
				width: 110,
				sortable: true,
			},
			// {
			// 	label: '共享人',
			// 	prop: 'fz_shareUserName',
			// 	width: 150,
			// 	sortable: true,
			// },
			{
				label: '质检要求',
				prop: 'qualityCheck',
				width: 150,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 90,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 170,
			},
		],
	},
	//委外出料单
	erp_produce_outmaterial: {
		main: [
			{
				label: '出料单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '委外加工单号',
				prop: 'fz_processNo',
				width: 180,
				sortable: true,
			},
			{
				label: '委托单位',
				prop: 'fz_supplierName',
				width: 150,
				sortable: true,
			},
			{
				label: '工厂地址',
				prop: 'address',
				width: 150,
				sortable: true,
			},
			{
				label: '联系人',
				prop: 'contacts',
				width: 100,
				sortable: true,
			},
			{
				label: '联系电话',
				prop: 'phone',
				width: 120,
				sortable: true,
			},
			{
				label: '出料人',
				prop: 'fz_outUserName',
				width: 120,
				sortable: true,
			},
			{
				label: '出料日期',
				prop: 'outDate',
				width: 130,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '出料状态',
				prop: 'outStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物料名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物料类型',
				prop: 'fz_stype',
				sortable: true,
				fixed: 'left',
				width: 100,
			},

			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '物料型号',
				prop: 'fz_goodsModel',
				width: 120,
				sortable: true,
			},
			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '单价(元)',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '小计金额(元)',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//委外验收单
	erp_produce_outcheck: {
		main: [
			{
				label: '验收单号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '委外加工单号',
				prop: 'fz_processNo',
				width: 180,
				sortable: true,
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 100,
				sortable: true,
			},
			{
				label: '验收日期',
				prop: 'checkDate',
				width: 110,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 150,
				sortable: true,
			},
			{
				label: '生产数量',
				prop: 'produceQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '合格数量',
				prop: 'qualifyQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '合格率',
				prop: 'qualifyRate',
				width: 100,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品类型',
				prop: 'fz_goodsStype',
				width: 100,
				sortable: true,
			},
			{
				label: '产品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '产品规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '产品型号',
				prop: 'fz_goodsModel',
				width: 120,
				sortable: true,
			},
			{
				label: '收货仓库',
				prop: 'fz_depotName',
				width: 150,
				sortable: true,
			},
			{
				label: '入库数量',
				prop: 'refundQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '成本单价(元)',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额(元)',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '驳回原因',
				prop: 'reason',
				width: 150,
				sortable: true,
			},
		],
	},
	//BOM清单
	erp_produce_bom: {
		main: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '成品率【%】',
				prop: 'yieldrate',
				width: 120,
				sortable: true,
			},
			{
				label: '版本号',
				prop: 'edition',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物料名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物品类型',
				prop: 'fz_goodsStype',
				sortable: true,
				width: 120,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '物料型号',
				prop: 'fz_goodsModel',
				width: 120,
				sortable: true,
			},
			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '耗损率(%)',
				prop: 'wearRate',
				width: 120,
				sortable: true,
			},
			{
				label: '实际数量',
				prop: 'actualQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '单价(元)',
				prop: 'price',
				width: 100,
				sortable: true,
			},
			{
				label: '小计金额(元)',
				prop: 'subtotal',
				width: 120,
				sortable: true,
			},
			{
				label: '来源',
				prop: 'source',
				width: 100,
				sortable: true,
			},
			{
				label: '位置号',
				prop: 'location',
				width: 150,
				sortable: true,
			},
			{
				label: '坏料尺寸',
				prop: 'billetSize',
				width: 150,
				sortable: true,
			},
			{
				label: '坏料数量',
				prop: 'billetNumber',
				width: 120,
				sortable: true,
			},
			{
				label: '提前期',
				prop: 'leadTime',
				width: 160,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//组装单
	erp_produce_assemble: {
		main: [
			{
				label: '组装单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '生产计划',
				prop: 'fz_planNo',
				width: 160,
				sortable: true,
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 120,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 180,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '组装数量',
				prop: 'quantity',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '入库仓库',
				prop: 'fz_depotName',
				width: 150,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '组装状态',
				prop: 'assembleStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 120,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物料名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品类型',
				prop: 'fz_stype',
				sortable: true,
				width: 100,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '物料型号',
				prop: 'fz_goodsModel',
				width: 120,
				sortable: true,
			},
			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '单价(元)',
				prop: 'price',
				width: 120,
				sortable: true,
			},
			{
				label: '金额(元)',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '驳回原因',
				prop: 'reason',
				width: 150,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	//拆卸单
	erp_produce_dismantle: {
		main: [
			{
				label: '拆卸单号',
				prop: 'orderNo',
				width: 160,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '负责人',
				prop: 'fz_managerName',
				width: 100,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 180,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 100,
				sortable: true,
			},
			{
				label: '拆卸数量',
				prop: 'quantity',
				width: 100,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '出库仓库',
				prop: 'fz_depotName',
				width: 150,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '拆卸状态',
				prop: 'dismantleStatus',
				width: 100,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
			{
				label: '操作用户',
				prop: 'fz_operateName',
				width: 100,
				sortable: true,
			},
			{
				label: '操作时间',
				prop: 'addTime',
				width: 180,
				sortable: true,
			},
		],
		son: [
			{
				label: '物料名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '产品类型',
				prop: 'fz_stype',
				sortable: true,
				width: 100,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '物料型号',
				prop: 'fz_goodsModel',
				width: 120,
				sortable: true,
			},
			{
				label: '拆卸数量',
				prop: 'demandQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅计单位',
				prop: 'assistUnit',
				width: 100,
				sortable: true,
			},
			{
				label: '换算数量',
				prop: 'assistQuantity',
				width: 100,
				sortable: true,
			},
			{
				label: '成本单价(元)',
				prop: 'costPrice',
				width: 120,
				sortable: true,
			},
			{
				label: '金额(元)',
				prop: 'amount',
				width: 120,
				sortable: true,
			},
			{
				label: '入库状态',
				prop: 'enterStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '驳回原因',
				prop: 'reason',
				width: 150,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	erp_exam_raw: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
			},
			{
				label: '收货单号',
				prop: 'receiptNo',
				width: 200,
				sortable: true,
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				width: 200,
				sortable: true,
			},
			{
				label: '牌号',
				prop: 'brandNumber',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '生产厂',
				prop: 'maker',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '出厂日期',
				prop: 'makerDate',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '质量证明书号',
				prop: 'qualityBook',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '炉批号',
				prop: 'stove',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '材料标准',
				prop: 'material',
				sortable: true,
				width: 130,
			},
			{
				label: '供货状态',
				prop: 'supplyStatus',
				width: 130,
				sortable: true,
			},
			{
				label: '计划用途',
				prop: 'plan',
				width: 120,
				sortable: true,
			},
			{
				label: "质量证明书",
				prop: "quality",
				width: 120,
				sortable: true,
			},
			{
				label: "订货合同",
				prop: "orderContract",
				width: 120,
				sortable: true,
			},
			{
				label: "技术协议",
				prop: "technology",
				width: 120,
				sortable: true,
			},
			{
				label: "保管员",
				prop: "fz_custodianName",
				width: 120,
				sortable: true,
			},
			{
				label: "保管日期",
				prop: "cusDate",
				width: 120,
				sortable: true,
			},
			{
				label: "检验员",
				prop: "fz_inspectorName",
				width: 120,
				sortable: true,
			},
			{
				label: "检验日期",
				prop: "insDate",
				width: 120,
				sortable: true,
			},
			{
				label: "检验状态",
				prop: "insStatus",
				width: 120,
				sortable: true,
			},
			{
				label: "检验结果",
				prop: "insCon",
				width: 120,
				sortable: true,
			},
			{
				label: "材料责任人",
				prop: "fz_responsibleName",
				width: 120,
				sortable: true,
			},
			{
				label: "审批日期",
				prop: "examDate",
				width: 120,
				sortable: true,
			},
			{
				label: "审批状态",
				prop: "examStatus",
				width: 120,
				sortable: true,
			},
			{
				label: "审批结果",
				prop: "examCon",
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
			{
				label: '创建时间',
				prop: 'addTime',
				width: 150,
				sortable: true,
			},
		],
	},
	erp_exam_welding: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 120,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '收货单号',
				prop: 'receiptNo',
				width: 120,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				width: 160,
			},
			{
				label: '数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '牌号',
				prop: 'brandNumber',
				width: 120,
				sortable: true,
			},
			{
				label: '焊材标准',
				prop: 'welding',
				width: 120,
				sortable: true,
			},
			{
				label: '生产厂',
				prop: 'maker',
				sortable: true,
				width: 130,
			},
			{
				label: '出厂日期',
				prop: 'makerDate',
				width: 130,
				sortable: true,
			},
			{
				label: '质量证明书号',
				prop: 'quality',
				width: 120,
				sortable: true,
			},
			{
				label: '炉批号',
				prop: 'identifier',
				width: 120,
				sortable: true,
			},
			{
				label: "质量证明书",
				prop: "qualityStatus",
				width: 120,
				sortable: true,
			},
			{
				label: "保管员",
				prop: "fz_custodianName",
				width: 120,
				sortable: true,
			},
			{
				label: "保管日期",
				prop: "cusDate",
				width: 120,
				sortable: true,
			},
			{
				label: "资料齐全质量证明书及标准与实物相符",
				prop: "accord",
				width: 250,
				sortable: true,
			},
			{
				label: "焊接工艺性能",
				prop: "technology",
				width: 120,
				sortable: true,
			},
			{
				label: "熔敷金属成分",
				prop: "metal",
				width: 120,
				sortable: true,
			},
			{
				label: "力学性能",
				prop: "mechanics",
				width: 120,
				sortable: true,
			},
			{
				label: "扩散氢含量",
				prop: "hydrogen",
				width: 120,
				sortable: true,
			},
			{
				label: "角焊缝",
				prop: "horn",
				width: 120,
				sortable: true,
			},
			{
				label: "存在的问题",
				prop: "issues",
				width: 120,
				sortable: true,
			},
			{
				label: "实验报告号",
				prop: "report",
				width: 120,
				sortable: true,
			},
			{
				label: "材料检验员",
				prop: "fz_inspectorName",
				width: 120,
				sortable: true,
			},
			{
				label: "检验日期",
				prop: "insDate",
				width: 120,
				sortable: true,
			},
			{
				label: "符合标准",
				prop: "standard",
				width: 120,
				sortable: true,
			},
			{
				label: "实物",
				prop: "standarsdStatus",
				width: 120,
				sortable: true,
			},
			{
				label: "材料责任人",
				prop: "fz_responsibleName",
				width: 120,
				sortable: true,
			},
			{
				label: "审批日期",
				prop: "examDate",
				width: 120,
				sortable: true,
			},
			{
				label: "质量证明书",
				prop: "cer",
				width: 120,
				sortable: true,
			},
			{
				label: "试验报告",
				prop: "trial",
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
	erp_exam_out: {
		main: [
			{
				label: '订单编号',
				prop: 'orderNo',
				width: 120,
				sortable: true,
			},
			{
				label: '收货单号',
				prop: 'receiptNo',
				width: 120,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '零件名称',
				prop: 'fz_goodsName',
				sortable: true,
				width: 120,
			},
			{
				label: '产品编号',
				prop: 'fz_goodsCode',
				width: 120,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 120,
				sortable: true,
			},
			{
				label: '数量',
				prop: 'quantity',
				width: 120,
				sortable: true,
			},
			{
				label: '计量单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '材料牌号',
				prop: 'brandNumber',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '验收标准',
				prop: 'standard',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '产品图号',
				prop: 'product',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '件号',
				prop: 'part',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '检验件号',
				prop: 'partExam',
				edit: 'input',
				width: 100,
				max: 255,
			},
			{
				label: '合格件数',
				prop: 'qualified',
				width: 130,
				sortable: true,
			},
			{
				label: '进货日期',
				prop: 'makerDate',
				width: 120,
				sortable: true,
			},
			{
				label: '生产厂家',
				prop: 'maker',
				width: 120,
				sortable: true,
			},
			{
				label: '材质证书号',
				prop: 'material',
				width: 120,
				sortable: true,
			},
			{
				label: "质量证明书",
				prop: "quality",
				width: 120,
				sortable: true,
			},
			{
				label: "订货合同",
				prop: "orderContract",
				width: 120,
				sortable: true,
			},
			{
				label: "技术协议",
				prop: "technology",
				width: 120,
				sortable: true,
			},
			{
				label: "保管员",
				prop: "fz_custodianName",
				width: 120,
				sortable: true,
			},
			{
				label: "保管日期",
				prop: "cusDate",
				width: 120,
				sortable: true,
			},
			{
				label: "材质证书审查",
				prop: "materialCer",
				width: 120,
				sortable: true,
			},
			{
				label: "标记铭牌复核",
				prop: "markingName",
				width: 120,
				sortable: true,
			},
			{
				label: "外观质量",
				prop: "appearance",
				width: 120,
				sortable: true,
			},
			{
				label: "主要几何尺寸",
				prop: "geometry",
				width: 120,
				sortable: true,
			},
			{
				label: "补项复验结果",
				prop: "supplement",
				width: 120,
				sortable: true,
			},
			{
				label: "存在问题及处理意见",
				prop: "opinion",
				width: 120,
				sortable: true,
			},
			{
				label: "符合标准",
				prop: "criteria",
				width: 120,
				sortable: true,
			},
			// {
			// 	label: "厂内编号",
			// 	prop: "factory",
			// 	width: 120,
			// 	sortable: true
			// },
			{
				label: "检验员",
				prop: "fz_inspectorName",
				width: 120,
				sortable: true,
			},
			{
				label: "检验日期",
				prop: "insDate",
				width: 120,
				sortable: true,
			},
			{
				label: "材料责任人",
				prop: "fz_responsibleName",
				width: 120,
				sortable: true,
			},
			{
				label: "审批日期",
				prop: "examDate",
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
		son: [
			{
				label: '项目名称',
				prop: 'name',
				width: 120,
				maxlength: 20,
			},
			{
				label: '标准规定',
				prop: 'require',
				maxlength: 20,
				width: 120,
			},
			{
				label: '实测值',
				prop: 'measured',
				maxlength: 20,
				width: 120,
			},
			{
				label: '验收结果',
				prop: 'results',
				maxlength: 20,
				width: 120,
			},
			{
				label: '备注',
				prop: 'remarks',
				maxlength: 20,
				width: 120,
			},
		],
	},
	//生产领料单
	erp_produce_material: {
		main: [
			{
				label: '领料单号',
				prop: 'orderNo',
				width: 180,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				width: 170,
				sortable: true,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 170,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 210,
				sortable: true,
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 130,
				sortable: true,
			},
			// {
			// 	prop: 'collarStatus',
			// 	width: 120,
			// 	sortable: true,
			// },
			{
				label: '出库状态',
				prop: 'outStatus',
				width: 120,
				sortable: true,
			},

			{
				label: '经办人',
				prop: 'fz_agentName',
				width: 120,
				sortable: true,
			},
			{
				label: '领料日期',
				prop: 'eventDate',
				width: 180,
				sortable: true,
			},
			{
				label: '领料类型',
				prop: 'collarType',
				width: 120,
				sortable: true,
			},
			{
				label: '是否补单',
				prop: 'supplement',
				width: 120,
				sortable: true,
			},
			{
				label: '创建日期',
				prop: 'creationDate',
				width: 180,
				sortable: true,
			},


			// {
			// 	label: '操作用户',
			// 	prop: 'fz_operateName',
			// 	width: 120,
			// 	sortable: true,
			// },
			// {
			// 	label: '操作时间',
			// 	prop: 'addTime',
			// 	width: 210,
			// 	sortable: true,
			// },
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
		son: [
			{
				label: '物品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '供应商',
				prop: 'fz_supplierName',
				width:200,
				sortable: true,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 180,
				sortable: true,
			},
			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 190,
				sortable: true,
			},
			{
				label: '出库状态',
				prop: 'outStatus',
				width: 120,
				sortable: true,
			},
			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '申领数量',
				prop: 'collarQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '出库数量',
				prop: 'outQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '总出库数量',
				prop: 'totalQuantity',
				width: 120,
				sortable: true,
			},
			{
				label: '出货仓库',
				prop: 'fz_depotName',
				width: 120,
				sortable: true,
			},
			// {
			// 	label: '库存数量',
			// 	prop: 'fz_depotQuantity',
			// 	width: 120,
			// 	disable: true,
			// },
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},

			{
				label: '辅助单位',
				prop: 'assistUnit',
				width: 130,
				sortable: true,
			},
			{
				label: '辅助单位数量',
				prop: 'assistQuantity',
				width: 140,
				sortable: true,
			},

			{
				label: '入库号',
				prop: 'warehouseNum',
				width: 120,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark',
				width: 150,
				sortable: true,
			},
		],
	},
    //标准bom
	erp_produce_bzbom: {
		main: [
			{
				label: '标准BOM编号',
				prop: 'bomNum',
				width: 210,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},

			{
				label: '物品名称',
				prop: 'fz_goodsName',
				width: 160,
				sortable: true,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 160,
				sortable: true,
			},

			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 210,
				sortable: true,
			},

			{
				label: '版本号',
				prop: 'edition',
				width: 120,
				sortable: true,
			},

			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				sortable: true,
				fixed: 'left',
				width: 150,
			},

			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 220,
				sortable: true,
			},

			{
				label: '物料版本号',
				prop: 'edition' ,
				width: 120,
				sortable: true,
			},
			{
				label: '物料类型',
				prop: 'fz_goodsStype',
				width: 180,
				sortable: true,
				filters: [
					{text: '产品', value: '1'},
					{text: '物料', value: '0'},
				]
			},

			{
				label: '数量',
				prop: 'demandQuantity',
				width: 140,
				sortable: true,
			},
			{
				label: '耗损率(%)',
				prop: 'wearRate',
				width: 130,
				sortable: true,
			},
			{
				label: '耗损数量',
				prop: 'lossQuantity',
				disable: true,
				width: 140,
				sortable: true,
			},

			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},

			{
				label: '辅助单位',
				prop: 'assistUnit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅助单位数量',
				prop: 'assistQuantity',
				width: 140,
				sortable: true,
			},

			{
				label: '备注',
				prop: 'remark' ,
				width: 160,
				sortable: true,
			},
		],
	},
    //订单bom
	erp_produce_orderBom: {
		main: [
			{
				label: '设置状态',
				prop: 'fz_notBom',
				width: 120,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '订单BOM编号',
				prop: 'bomNum',
				width: 210,
				sortable: true,
				fixed: 'left',
			},
			{
				label: '审核状态',
				prop: 'auditStatus',
				width: 120,
				sortable: true,
			},

			{
				label: '物品名称',
				prop: 'fz_goodsName',
				width: 160,
				sortable: true,
			},
			{
				label: '物品编码',
				prop: 'fz_goodsCode',
				width: 160,
				sortable: true,
			},

			{
				label: '规格型号',
				prop: 'fz_goodsSpec',
				width: 210,
				sortable: true,
			},

			// {
			// 	label: '材质',
			// 	prop: 'fz_goodsMaterial',
			// 	width: 180,
			// 	sortable: true,
			// },

			// },
			// {
			// 	label: '操作时间',
			// 	prop: 'addTime',
			// 	width: 220,
			// 	sortable: true,
			// },
			{
				label: '备注',
				prop: 'remark',
				width: 200,
				sortable: true,
			},
		],
		son: [
			{
				label: '产品名称',
				prop: 'fz_goodsName',
				sortable: true,
				fixed: 'left',
				width: 160,
			},
			{
				label: '物料编码',
				prop: 'fz_goodsCode',
				sortable: true,
				fixed: 'left',
				width: 150,
			},

			{
				label: '物料规格',
				prop: 'fz_goodsSpec',
				width: 220,
				sortable: true,
			},

			{
				label: '物品类型',
				prop: 'fz_goodsStype',
				width: 180,
				sortable: true,
				filters: [
					{text: '产品', value: '1'},
					{text: '物料', value: '0'},
				]
			},
			// {
			// 	label: '材料牌号',
			// 	prop: 'fz_goodsBrandNumber',
			// 	sortable: true,
			// 	width: 120,
			// },

			// {
			// 	label: '净重 ',
			// 	prop: 'fz_goodsModel',
			// 	width: 120,
			// 	sortable: true,
			// },

			{
				label: '需求数量',
				prop: 'demandQuantity',
				width: 140,
				sortable: true,
			},
			{
				label: '耗损率(%)',
				prop: 'wearRate',
				width: 130,
				sortable: true,
			},
			{
				label: '实际数量',
				prop: 'actualQuantity',
				disable: true,
				width: 140,
				sortable: true,
			},
			{
				label: '主单位',
				prop: 'unit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅助单位',
				prop: 'assistUnit',
				width: 120,
				sortable: true,
			},
			{
				label: '辅助单位数量',
				prop: 'assistQuantity',
				width: 140,
				sortable: true,
			},
			{
				label: '备注',
				prop: 'remark' ,
				width: 160,
				sortable: true,
			},
		],
	},

}
module.exports = tableColumn
