<!--选择加工单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'500px'"
    custom-class="popTable"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
					  <div class="left-panel" />
                    <div class="right-panel" >
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @current-change="currentChange"
                      @row-click="firClick"
                      @row-dblclick="dblClick"
                    >
                      <template #qualityStatus="{scope}">
                        <el-tag
                            v-if="scope.row.qualityStatus === 0"
                            class="myTag"
                            style="color: #666;"
                            :size="$store.state.global.uiSize"
                        >
                          未检测
                        </el-tag>
                        <el-tag
                            v-if="scope.row.qualityStatus === 1"
                            type="warning"
                            class="myTag"
                            :size="$store.state.global.uiSize"
                        >
                          检测中
                        </el-tag>
                        <el-tag
                            v-if="scope.row.qualityStatus === 2"
                            type="success"
                            class="myTag"
                            :size="$store.state.global.uiSize"
                        >
                          已检测
                        </el-tag>
                      </template>
                      <template #submType="{scope}">
                        <span v-if="scope.row.submType == 1">自行质检</span>
                        <span v-if="scope.row.submType == 2">第三方机构</span>
                        <span v-if="scope.row.submType == 3">混合质检</span>
                      </template>
                    </scTable>
                  </el-main>
                </el-container>
              </pane>
              <!--已选数据-->
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="70"
                min-size="20"
              >
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <ProducePlanDetail ref="refProducePlanDetail" />
    <ProduceProcessDetail ref="refProduceProcessDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
export default defineComponent ({
	components: {GoodsDetail, Detail ,ProducePlanDetail,ProduceProcessDetail},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择产品送检单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '送检单号',
					prop: 'fz_submSonNo',
					// sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: null,
				params:{},
				//数据表格列配置
        columns: [
          {
            label: '送检单号',
            prop: 'fz_submSonNo',
            width: 200,
            sortable: 'custom',
            fixed: 'left',
          },
          {
            label: '加工单',
            prop: 'fz_processNo',
            sortable: 'custom',
            width: 180,
          },
          {
            label: '物品名称',
            prop: 'fz_goodsName',
            sortable: 'custom',
            width: 180,
          },
          {
            label: '物品编码',
            prop: 'fz_goodsCode',
            sortable: 'custom',
            width: 120,
          },
          {
            label: '规格型号',
            prop: 'fz_goodsSpec',
            sortable: 'custom',
            width: 120,
          },

          {
            label: '送检数量',
            prop: 'number',
            sortable: 'custom',
            width: 110,
          },
          {
            label: '检测类型',
            prop: 'submType',
            sortable: 'custom',
            width: 110,
          },
          {
            label: '质检状态',
            prop: 'qualityStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '备注',
            prop: 'remarks',
            width: 180,
            sortable: 'custom',
          },
        ],
      },
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 100,
        queryForm: {
          pageNum: 1,
          pageSize: 1000
        },
        //查询表单项配置
        itemList: [
          {
            label: '制单日期',
            prop: 'makeDate',
            type: 'date',
            default: true,
          },
          {
            label: '检验单号',
            prop: 'orderNum',
            type: 'input',
            max: 30,
            default: true,
          },
          {
            label: '加工供应商',
            prop: 'fz_supplierName',
            type: 'popSupplier',
            default: true,
            fields: ['supplierId', 'fz_supplierName']
          },
          {
            label: '送检类型',
            prop: 'submType',
            type: 'select',
            default: true,
            data: [
              {label: '自行质检',value: 1},
              {label: '第三方机构',value: 2},
              {label: '混合质检',value: 3},
            ]
          },
        ],
			},
		})

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
      state.loading = false
			if(data){
				Object.assign(state.tableConfig.params, data)
			}
      state.tableConfig.apiObj = proxy.$API.qualitySubmProductDetail.getList
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}


		//行单击
		const firClick = (row) => {
			// //添加已选数据
			// setSelData(row)
			// if(row && !props.multiple){
			// 	//传入选中行和赋值的字段
			// 	emit('set-data', row)
			// 	hideDialog()
			// }
			// 多选
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
			handleRowClick(row)
		}

		//行双击
		const dblClick = (row) => {
			// 单选
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}

		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			// if(state.leftShow){
			// 	state['tableSon'].reload({submId: row.id})
			// }

      if(row){
        proxy.$refs.table.toggleRowSelection(row)
      }
		}


    const handleDetail = (row, ref, field) => {
      const params = Object.assign({}, row)
      if (field) {
        params.id = row[field]
      }
      proxy.$refs[ref].showDialog(params)
    }

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}


		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false)
			if (data && index === -1) {
				state.selData.push(data)


			} else {
				state.selData = state.selData.filter(item => item.id !== data.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false

			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		return{
			...toRefs(state),
			clearSelections,
			delSelData,
			tableSelselectChange,
			addToRight,
			handleDetail,
			handleRowClick,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			firClick,
			hideDialog,
		}
	},
})
</script>
