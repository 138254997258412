import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	invoiceReject: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/invoiceReject`,
		name: "修改审批状态",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	invoiceCancel: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/invoiceCancel`,
		name: "发票作废",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	invoiceReceived: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/invoiceReceived`,
		name: "签收",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	alreadyMailOut: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/alreadyMailOut`,
		name: "修改状态为已寄出",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getSaleInvoiceData: {
		url: `${config.API_URL}/fms/invoice/saleInvoice/detail/getSaleInvoiceData`,
		name: "根据销售订单查询",
		get: async function(saleOrderId){
			return await http.get(this.url + '?saleOrderId=' + saleOrderId);
		}
	},
}
