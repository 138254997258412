<!--选择商品带仓库弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'600px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes @resized="handleResized">
          <pane
            v-if="leftShow"
            min-size="10"
            max-size="60"
            :size="paneSize"
          >
            <el-container>
              <el-main class="nopadding">
                <sy-tree
                  :api-obj="$API.classify.findAll"
                  :params="{ businessType: 'cfg_data_goods' }"
                  :is-edit="false"
                  @fetch-data-node="(val) => fetchDataNode(val, 1)"
                />
              </el-main>
            </el-container>
          </pane>
          <pane>
            <!--公用切换按钮，点击切换显示左侧栏
			text:显示文字
			opened:绑定左侧栏显示状态
			show-left:回调函数
			-->
            <sy-switch-btn
              :text="switchText"
              :opened="leftShow"
              @show-left="handleShowLeft"
            />
            <el-container
              class="mainPanel sw"
              :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <!--                <div class="flexgs0">
                  <span class="mr3 font">{{ fieldDepot }}</span>
                  <el-select
                    v-model="depot"
                    class="w150"
                    value-key="id"
                    :size="$store.state.global.uiSize"
                    placeholder="仓库"
                  >
                    <el-option
                      v-for="(item,index) in depotList"
                      :key="index"
                      :value="item"
                      :label="item.name"
                    />
                  </el-select>
                </div>-->
                <div class="flexgs1">
                  <el-button
                    v-if="showAdd"
                    ref="refPopAddBtn"
                    type="primary"
                    class="mr10"
                    :size="$store.state.global.uiSize"
                    @click="handleEdit(1, 'refPopAddBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                    v-if="showEdit"
                    ref="refEditBtn"
                    class="mr10"
                    :type="'primary'"
                    :size="$store.state.global.uiSize"
                    :disabled="isDisabled"
                    @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>
                  <el-link
                    v-if="multiple"
                    type="primary"
                    :disabled="selections.length === 0"
                    @click="addToRight"
                  >
                    添加选择<el-icon><el-icon-right /></el-icon>
                  </el-link>
                </div>
                <div class="flexgs1 text-right">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  remote-sort
									:ref-query-form="refFormBar"
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
                  @current-change="currentChange"
                  @row-dblclick="dblClick"
                >
                  <template #name="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                    >
                      {{ scope.row.name }}
                    </el-link>
                  </template>
                  <template #flag="{ scope }">
                    <span>{{ scope.row.flag == 1 ? '正常' : '停用' }}</span>
                  </template>
                  <template #assembly="{ scope }">
                    <span>{{ scope.row.assembly == 1 ? '是' : '否' }}</span>
                  </template>
                  <template #stype="{ scope }">
                    <span>{{ scope.row.stype == 1 ? '产品' : '物料' }}</span>
                  </template>
                  <template #guarantee="{ scope }">
                    <span>{{ scope.row.guarantee }}天</span>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <span class="mr3 font">
        {{ fieldDepot }}
        <el-select
          v-model="depot"
          class="w150"
          value-key="id"
          :size="$store.state.global.uiSize"
          placeholder="仓库"
        >
          <el-option
            v-for="(item,index) in depotList"
            :key="index"
            :value="item"
            :label="item.name"
          />
        </el-select>
      </span>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
    <!--新增弹窗-->
    <Edit
      ref="refEdit"
      @fetch-data="fetchData"
    />
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
} from 'vue'
import Edit from './edit'  //新增组件
import Detail from '@/views/cfg/data/goods/detail'
export default defineComponent ({
	name: "SelectStockGoods",
	components: { Edit, Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '物品选择' },
		fieldDepot: { type: String, default: '仓库' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar: null,
			depot: {},
			depotList: [],
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//编辑按钮显示
			showEdit: false,
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			leftShow: true,
			paneSize: 20,
			paneSize1: 20,
			switchText: '部门',
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '物品名称',
					prop: 'name',
					sortable: 'custom',
				},
			],
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			tableConfig: {
				apiObj: proxy.$API.goods.queryApprovedGoods,
				params: {},
				columns: [
					{
						label: '物品类型',
						prop: 'stype',
						width: 110,
						sortable: 'custom',
						filters: [
							{text: '产品', value: 1},
							{text: '物料', value: 0},
						]
					},
					{
						label: '物品名称',
						prop: 'name',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '物品编码',
						prop: 'code',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格',
						prop: 'spec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '型号',
						prop: 'model',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '库存数',
						prop: 'fz_depotQuantity',
						width: 110,
					},
					{
						label: '所属仓库',
						prop: 'fz_depotName',
						width: 110,
					},
					{
						label: '物品类别',
						prop: 'fz_classifyName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '保质期',
						prop: 'guarantee',
						width: 130,
					},
					{
						label: '主单位计量',
						prop: 'mainUnit',
						width: 110,
					},
					{
						label: '最小采购量',
						prop: 'purchaseQuantity',
						width: 110,
					},
					// {
					// 	label: '参考成本价',
					// 	prop: 'referenceCost',
					// 	width: 120,
					// },
					// {
					// 	label: '参考销售价',
					// 	prop: 'referencePrice',
					// 	width: 120,
					// },
					// {
					// 	label: '税率【%】',
					// 	prop: 'taxRate',
					// 	width: 120,
					// },
					{
						label: '库存上限',
						prop: 'inventoryCap',
						width: 110,
					},
					{
						label: '库存下限',
						prop: 'inventoryLower',
						width: 110,
					},
					// {
					// 	label: '组装拆卸',
					// 	prop: 'assembly',
					// 	width: 150,
					// 	sortable: 'custom',
					// 	filters: [
					// 		{text: '是', value: '1'},
					// 		{text: '否', value: '0'},
					// 	]
					// },
					{
						label: '启用状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
						filters: [
							{text: '正常', value: '1'},
							{text: '停用', value: '0'},
						]
					},
					{
						label: '自动停用日期',
						prop: 'deactivationDate',
						width: 130,
					},
					// {
					// 	label: '描述',
					// 	prop: 'represent',
					// 	width: 120,
					// },
					{
						label: '用途',
						prop: 'purpose',
						width: 120,
					},
					// {
					// 	label: '装箱规格',
					// 	prop: 'packing',
					// 	width: 120,
					// },
					// {
					// 	label: '存储条件',
					// 	prop: 'storage',
					// 	width: 120,
					// },
					{
						label: '备注',
						prop: 'remark',
						width: 180,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '物品名称',
						span: 12,
						prop: 'name',
						type: 'input',
						//最大字符数，可不设
						max: 100,
						//默认显示
						default: true,
					},
					{
						label: '物品编码',
						span: 12,
						prop: 'code',
						type: 'input',
					},
					{
						label: '规格',
						span: 12,
						prop: 'spec',
						type: 'input',
					},
					{
						label: '型号',
						span: 12,
						prop: 'model',
						type: 'input',
					},
					{
						label: '物品类型',
						span: 12,
						short: 12,
						prop: 'stype',
						type: 'select',
						default: true,
						//下拉选项
						data: [
							{value: 1, label: '产品'},
							{value: 0, label: '物料'},
						]
					},
					{
						label: '物品类别',
						span: 12,
						prop: 'classifys',
						type: 'tableSelect',
						//下拉表格选择配置项
						config: {
							//数据接口对象 对象名称：getList
							apiObj: proxy.$API.classify.getList,
              hidePagination: true,
							props: {
								//映射label字段，即显示的字段
								label: 'name',
								//映射value字段
								value: 'id',
								//映射搜索字段
								keyword: 'name',
							},
							//表格显示的列配置
							columns: [
								{
									label: '名称',
									prop: 'name',
									width: 200,
								},
								{
									label: 'ID',
									prop: 'id',
								},
							],
							//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
							assignFields: [
								{
									//对应表格列表数据字段
									value: 'id',
									//赋值给到的字段名称
									field: 'classifyId',
								},
							],
						},
					},
					{
						label: '所属仓库',
						span: 12,
						prop: 'depots',
						type: 'tableSelect',
						//下拉表格选择配置项
						config: {
							//数据接口对象 对象名称：getList
							apiObj: proxy.$API.depot.getDepotList,
							props: {
								//映射label字段，即显示的字段
								label: 'name',
								//映射value字段
								value: 'id',
								//映射搜索字段
								keyword: 'name',
							},
							//表格显示的列配置
							columns: [
								{
									label: '名称',
									prop: 'name',
									width: 200,
								},
								{
									label: 'ID',
									prop: 'id',
								},
							],
							//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
							assignFields: [
								{
									//对应表格列表数据字段
									value: 'id',
									//赋值给到的字段名称
									field: 'depotId',
								},
							],
						},
					},
					{
						label: '主计量单位',
						span: 12,
						prop: 'mainUnit',
						type: 'input',
						//默认显示
					},
					{
						label: '税率',
						span: 12,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'fz_taxRate1',
								max: 100,
							},
							{
								prop: 'fz_taxRate2',
								max: 100,
							}
						],
					},
					{
						label: '最小采购量',
						span: 12,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'fz_purchaseQuantity1',
								max: 100,
							},
							{
								prop: 'fz_purchaseQuantity2',
								max: 100,
							}
						],
					},
					// {
					// 	label: '参考成本价',
					// 	span: 12,
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_referenceCost1',
					// 			max: 100,
					// 		},
					// 		{
					// 			prop: 'fz_referenceCost2',
					// 			max: 100,
					// 		}
					// 	],
					// },
					// {
					// 	label: '参考销售价',
					// 	span: 12,
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_referencePrice1',
					// 			max: 100,
					// 		},
					// 		{
					// 			prop: 'fz_referencePrice2',
					// 			max: 100,
					// 		}
					// 	],
					// },
					{
						label: '库存上限',
						span: 12,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'fz_inventoryCap1',
								max: 100,
							},
							{
								prop: 'fz_inventoryCap2',
								max: 100,
							}
						],
					},
					{
						label: '库存下限',
						span: 12,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'fz_inventoryLower1',
								max: 100,
							},
							{
								prop: 'fz_inventoryLower2',
								max: 100,
							}
						],
					},
					{
						label: '保质期',
						span: 12,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'fz_guarantee1',
								max: 100,
							},
							{
								prop: 'fz_guarantee2',
								max: 100,
							}
						],
					},
					{
						label: '组装拆卸',
						span: 12,
						prop: 'assembly',
						type: 'select',
						//下拉选项
						data: [
							{value: '1', label: '是'},
							{value: '0', label: '否'},
						]
					},
					{
						label: '启用状态',
						span: 12,
						prop: 'flag',
						type: 'select',
						//下拉选项
						data: [
							{value: '1', label: '正常'},
							{value: '0', label: '停用'},
						]
					},
					{
						label: '自动停止日期',
						span: 12,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_deactivationDate1',
							},
							{
								prop: 'fz_deactivationDate2',
							},
						],
					},
					// {
					// 	label: '描述',
					// 	span: 12,
					// 	prop: 'represent',
					// 	type: 'input',
					// },
					{
						label: '用途',
						span: 12,
						prop: 'purpose',
						type: 'input',
					},
					// {
					// 	label: '装箱规格',
					// 	span: 12,
					// 	prop: 'packing',
					// 	type: 'input',
					// },
					// {
					// 	label: '装箱条件',
					// 	span: 12,
					// 	prop: 'storage',
					// 	type: 'input',
					// },
					{
						label: '备注',
						span: 12,
						prop: 'remark',
						type: 'input',
					},
					{
						label: '操作日期',
						span: 12,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_addTimeSmall',
							},
							{
								prop: 'fz_addTimeBig',
							},
						],
					},
					{
						label: '操作用户',
						span: 12,
						prop: 'fz_operateName',
            max:32,
						type: 'popUser',
						short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
				],
			},
		})

		//显示弹窗
		const showDialog = (params) => {
			state.visible = true
			nextTick(() => {
				if(params && Object.keys(params).length > 0){
					fetchData(params)
					Object.assign(proxy.$refs.refFormBar.queryForm, params)
				}
				//获取新增权限
				getShowAdd()
				//获取编辑权限
				getShowEdit()
				//获取所有仓库数据
				getDepotList()
			})
			state.loading = false
		}

		//获取仓库数据
		const getDepotList = async () => {
			const res = await proxy.$API.depot.getAllList.get()
			if(res.data && res.data.length > 0){
				state.depotList = res.data
				state.depot = res.data[0]
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
			state.depot = {}
			state.depotList = []
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_GOODS,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_GOODS,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(props.multiple){ //多选
				state.selData.forEach((item) => {
					item['fz_depotName'] = state.depot.name
					item['depotId'] = state.depot.id
				})
				data = state.selData
			}else{ //单选
				data = state.curRow
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//树节点点击
		const fetchDataNode = (data) => {
			proxy.$refs.table.reload({ classify: data.coordinate })
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			nextTick(() => {
				state['table'].reload(data)
			})
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}

		//新增修改弹窗
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		return{
			...toRefs(state),
			handleDetail,
			tableSelselectChange,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			fetchDataNode,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
		}
	},
})
</script>
