import config from "./config"
import tips from "./config/tipsConfig"
import menuCode from './config/menuCode'
import api from './api'
import tool from './utils/tool'
import http from "./utils/request"
import tableColumn from './config/tableColumn'
import loadingService from './config/loadingService'
import { permission, rolePermission } from './utils/permission'
import BtnEvals from '@/utils/btnEval'

import wwOpenData from './views/weChat/wwOpenData.vue'
import scTable from './components/scTable'
import scTableColumn from './components/scTable/column.js'
import scFilterBar from './components/scFilterBar'
import scUpload from './components/scUpload'
import scUploadMultiple from './components/scUpload/multiple'
import scUploadFile from './components/scUpload/file'
import scFormTable from './components/scFormTable'
import scTableSelect from './components/scTableSelect'
import scPageHeader from './components/scPageHeader'
import scSelect from './components/scSelect'
import scDialog from './components/scDialog'
import scForm from './components/scForm'
import scTitle from './components/scTitle'
import scWaterMark from './components/scWaterMark'
import scQrCode from './components/scQrCode'
import ScEditor from './components/scEditor'
import sySearchForm from './components/sySearchForm'
import sySwitch from './components/sySwitchBtn'
import syTree from './components/syTree'
import syArchivesDir from './components/syArchivesDir'
import SyGoodsDir from './components/syGoodsDir'
import SyDepartmentDir from './components/syDepartmentDir'
import syForm from './components/syForm'
import syCopyDialog from './components/syCopyDialog'
import syCopyDialogFtable from './components/syCopyDialogFtable'
import syEditTable from './components/syEditTable'
import syTableSelect from './components/syTableSelect'
import syFastEdit from './components/syFastEdit'
import syEmpty from './components/syEmpty'
import syQrCode from './components/syQrCode'
import SelectUser from './views/publicQuery/selectUser'
import SelectCustomer from "@/views/publicQuery/selectCustomer";
import SelectGoods from "@/views/publicQuery/selectGoods";
import SelectStockGoods from "@/views/publicQuery/selectStockGoods";
import SelectDepots from "@/views/publicQuery/selectDepots";
import SelectFinanceSubject from "@/views/publicQuery/selectFinanceSubject";
import SelectGoodsPrice from "@/views/publicQuery/selectGoodsPrice";
import SelectCompany from "@/views/publicQuery/selectCompany";
import DictsQuery from "@/views/publicQuery/dict";
import SelectCustomerConacts from "@/views/publicQuery/selectCustomerConacts";
import SelectCustomerAddress from "@/views/publicQuery/selectCustomerAddress";
import SelectClause from "@/views/publicQuery/selectClause";
import SelectAccount from "@/views/publicQuery/selectAcounts";
import SelectDeadline from "@/views/publicQuery/selectDeadline";
import SelectSupplier from "@/views/publicQuery/selectSupplier";
import SelectSaleOrder from "@/views/publicQuery/selectSaleOrder";
import SelectSaleInvoice from "@/views/publicQuery/selectSaleInvoice";
import dispatchList from "@/views/publicQuery/dispatchList";
import workingGroup from "@/views/publicQuery/workingGroup";
import SelectSaleOrderGoods from "@/views/publicQuery/selectSaleOrderGoods";
import SelectPurOrder from "@/views/publicQuery/selectPurOrder";
import SelectPurReceipt from "@/views/publicQuery/selectPurReceipt";
import SelectLogistics from "@/views/publicQuery/selectLogistics";
import SelectLogisticsContacts from "@/views/publicQuery/selectLogisticsContacts";
import SelectSupplierConacts from "@/views/publicQuery/SelectSupplierConacts";
import SelectProducePlan from "@/views/publicQuery/selectProducePlan";
import SelectProducePlanGoods from "@/views/publicQuery/selectProducePlanGoods";
import SelectWorkShop from "@/views/publicQuery/selectWorkShop";
import SelectDepartment from "@/views/publicQuery/selectDepartment";
import SelectWarehouseNum from "@/views/publicQuery/selectWarehouseNum";
import SelectDevice from "@/views/publicQuery/selectDevice";
import SelectProcedure from "@/views/publicQuery/selectProcedure";
import SelectProduceCraftRoute from "@/views/publicQuery/selectProduceCraftRoute";
import SelectProduceProcess from "@/views/publicQuery/selectProduceProcess";
import SelectProduceProcessGoods from "@/views/publicQuery/selectProduceProcessGoods";
import SelectProduceOutprocess from "@/views/publicQuery/selectProduceOutprocess";
import SelectProduceBom from "@/views/publicQuery/selectProduceBom";
import SelectLogisticsVehicle from "@/views/publicQuery/selectLogisticsVehicle";
import SelectLogisticsDriver from "@/views/publicQuery/selectLogisticsDriver";
import SelectLogisticsDelivery from "@/views/publicQuery/selectLogisticsDelivery";
import SelectDict from "@/views/publicQuery/selectDict";
import SelectUpholdRepair from "@/views/publicQuery/selectUpholdRepair";
import SelectSellAdvreceipt from "@/views/publicQuery/selectSellAdvreceipt";
import SelectBuyAdvpayment from "@/views/publicQuery/selectBuyAdvpayment";
import SelectBuyApply from "@/views/publicQuery/selectBuyApply";
import SelectSubject from "@/views/publicQuery/selectSubject";
import SelectAssets from "@/views/publicQuery/selectAssets";
import SelectPurRefund from "@/views/publicQuery/selectPurRefund";
import SelectQualitySubmGoods from "@/views/publicQuery/selectQualitySubmGoods";
import SelectQuaSubmOutGoods from "@/views/publicQuery/selectQuaSubmOutGoods";
import SelectQualitySubmProcure from "@/views/publicQuery/selectQualitySubmProcure";
import SelectSaleRefund from "@/views/publicQuery/selectSaleRefund";
import SelectDepot from "@/views/publicQuery/selectDepot";
import Selects from "@/views/publicQuery/selects";
import SelectBom from "@/views/publicQuery/selectBom";
import selectContract from "@/views/publicQuery/selectContract";
import SelectPosition from "@/views/publicQuery/selectPosition";
import SelectTrainOrganization from "@/views/publicQuery/selectTrainOrganization";
import SelectTrainCurriculum from "@/views/publicQuery/selectTrainCurriculum";
import SelectTrainActivity from "@/views/publicQuery/selectTrainActivity";
import SelectWelfareSocialScheme from "@/views/publicQuery/selectWelfareSocialScheme";
import SelectWelfareHousingScheme from "@/views/publicQuery/selectWelfareHousingScheme";
import SelectWelfareOtherScheme from "@/views/publicQuery/selectWelfareOtherScheme";
import SelectAattendanceShift from "@/views/publicQuery/selectAattendanceShift";
import SelectPerformanceTarget from "@/views/publicQuery/selectPerformanceTarget";
import SelectPerformanceBatch from "@/views/publicQuery/selectPerformanceBatch";
import SelectWageSubject from "@/views/publicQuery/selectWageSubject";
import SelectWageTax from "@/views/publicQuery/selectWageTax";
import SelectOaProject from "@/views/publicQuery/selectOaProject";
import selectOutprocess from "@/views/publicQuery/selectOutprocess";
import SelectDetection from "@/views/publicQuery/selectDetection";
import SelectProductSubm from "@/views/publicQuery/selectProductSubm";
import SelectQualitySubmProduct from "@/views/publicQuery/selectQualitySubmProduct";
import SnInput from "@/views/publicQuery/snInput";
import SnOutput from "@/views/publicQuery/snOutput";
import SyAlertDialog from './components/syAlertDialog'
import SelectWaste from '@/views/publicQuery/selectWaste'

//手机版选择
import WapSelectUser from '@/wap/views/publicQuery/selectUser'
import WapSelectCustomer from '@/wap/views/publicQuery/selectCustomer'
import WapSelectGoods from '@/wap/views/publicQuery/selectGoods'
import WapSelectSaleOrder from '@/wap/views/publicQuery/selectSaleOrder'
import WapSelectSaleOrderGoods from '@/wap/views/publicQuery/selectSaleOrderGoods'
import WapSelectLogistics from '@/wap/views/publicQuery/selectLogistics'
import WapSelectPurchaseApply from '@/wap/views/publicQuery/selectPurchaseApply'
import WapSelectPurchaseOrder from '@/wap/views/publicQuery/selectPurchaseOrder'
import WapSelectBuyApply from '@/wap/views/publicQuery/selectBuyApply'
import WapSelectSupplier from '@/wap/views/publicQuery/selectSupplier'
import WapSelectCustomerConacts from '@/wap/views/publicQuery/selectCustomerConacts'
import WapSelectCompany from '@/wap/views/publicQuery/selectCompany'
import WapSelectDepots from '@/wap/views/publicQuery/selectDepots'
import WapSelectAccount from '@/wap/views/publicQuery/selectAccount'
import syEditTableWap from './components/syEditTableWap'
import exam from '@/wap/views/publicQuery/exam'
import BetterScroll from '@/wap/views/components/scroll'

import scStatusIndicator from './components/scMini/scStatusIndicator'
import scTrend from './components/scMini/scTrend'
import { Splitpanes, Pane } from 'splitpanes'

import auth from './directives/auth'
import role from './directives/role'
import time from './directives/time'
import copy from './directives/copy'
import resizeHeight from './directives/resizeHeight'
import errorHandler from './utils/errorHandler'
import print from 'vue3-print-nb'

import * as elIcons from '@element-plus/icons-vue'
import * as scIcons from './assets/icons'

import * as socket from '@/utils/websocket'
import globalSocket from '@/utils/globalSocket'

export default {
	install: function (app) {
		//挂载全局对象
		app.config.globalProperties.$CONFIG = config;
		app.config.globalProperties.$TIPS = tips;
		app.config.globalProperties.$MENU = menuCode;
		app.config.globalProperties.$TOOL = tool;
		app.config.globalProperties.$HTTP = http;
		app.config.globalProperties.$API = api;
		app.config.globalProperties.$tableColumn = tableColumn;
		app.config.globalProperties.$AUTH = permission;
		app.config.globalProperties.$ROLE = rolePermission;
		app.config.globalProperties.$SOCKET = socket;
		app.config.globalProperties.$GLOBALSOCKET = globalSocket;
		//全局加载
		app.config.globalProperties.$LOADING = loadingService;
		app.config.globalProperties.BtnEvals = BtnEvals;

		//注册全局组件
		app.component('WwOpenData', wwOpenData);
		app.component('ScTable', scTable);
		app.component('ScTableColumn', scTableColumn);
		app.component('ScFilterBar', scFilterBar);
		app.component('ScUpload', scUpload);
		app.component('ScUploadMultiple', scUploadMultiple);
		app.component('ScUploadFile', scUploadFile);
		app.component('ScFormTable', scFormTable);
		app.component('ScTableSelect', scTableSelect);
		app.component('ScPageHeader', scPageHeader);
		app.component('ScSelect', scSelect);
		app.component('ScDialog', scDialog);
		app.component('ScForm', scForm);
		app.component('ScTitle', scTitle);
		app.component('ScWaterMark', scWaterMark);
		app.component('ScQrCode', scQrCode);
		app.component('ScEditor', ScEditor);
		app.component('ScStatusIndicator', scStatusIndicator);
		app.component('ScTrend', scTrend);
		app.component('SySearchForm', sySearchForm);
		app.component('SySwitchBtn', sySwitch);
		app.component('SyTree', syTree);
		app.component('Splitpanes', Splitpanes);
		app.component('Pane', Pane);
		app.component('SyForm', syForm);
		app.component('SyCopyDialog', syCopyDialog);
		app.component('SyCopyDialogFtable', syCopyDialogFtable);
		app.component('SyEditTable', syEditTable);
		app.component('SyTableSelect', syTableSelect);
		app.component('SyFastEdit', syFastEdit);
		app.component('SyEmpty', syEmpty);
		app.component('SyQrCode', syQrCode);
		app.component('SelectUser', SelectUser);
		app.component('SelectCustomer', SelectCustomer);
		app.component('SelectGoods', SelectGoods);
		app.component('SelectStockGoods', SelectStockGoods);
		app.component('SelectDepots', SelectDepots);
		app.component('SelectFinanceSubject', SelectFinanceSubject);
		app.component('SelectGoodsPrice', SelectGoodsPrice);
		app.component('SelectCompany', SelectCompany);
		app.component('DictsQuery', DictsQuery);
		app.component('SelectCustomerConacts', SelectCustomerConacts);
		app.component('SelectCustomerAddress', SelectCustomerAddress);
		app.component('SelectClause', SelectClause);
		app.component('SelectAccount', SelectAccount);
		app.component('SelectDeadline', SelectDeadline);
		app.component('SelectSupplier', SelectSupplier);
		app.component('SelectSaleOrder', SelectSaleOrder);
		app.component('SelectSaleInvoice', SelectSaleInvoice);
		app.component('dispatchList', dispatchList);
		app.component('workingGroup', workingGroup);
		app.component('SelectSaleOrderGoods', SelectSaleOrderGoods);
		app.component('SelectLogistics', SelectLogistics);
		app.component('SelectLogisticsContacts', SelectLogisticsContacts);
		app.component('SelectPurOrder', SelectPurOrder);
		app.component('SelectPurReceipt', SelectPurReceipt);
		app.component('SelectSupplierConacts', SelectSupplierConacts);
		app.component('SelectProducePlan', SelectProducePlan);
		app.component('SelectProducePlanGoods', SelectProducePlanGoods);
		app.component('SelectWorkShop', SelectWorkShop);
		app.component('SelectDepartment', SelectDepartment);
		app.component('SelectWarehouseNum', SelectWarehouseNum);
		app.component('SelectDevice', SelectDevice);
		app.component('SelectProcedure', SelectProcedure);
		app.component('SelectProduceCraftRoute', SelectProduceCraftRoute);
		app.component('SelectProduceProcess', SelectProduceProcess);
		app.component('SelectProduceProcessGoods', SelectProduceProcessGoods);
		app.component('SelectProduceOutprocess', SelectProduceOutprocess);
		app.component('SelectProduceBom', SelectProduceBom);
		app.component('SelectLogisticsVehicle', SelectLogisticsVehicle);
		app.component('SelectLogisticsDriver', SelectLogisticsDriver);
		app.component('SelectLogisticsDelivery', SelectLogisticsDelivery);
		app.component('SelectDict', SelectDict);
		app.component('SelectUpholdRepair', SelectUpholdRepair);
		app.component('SelectSellAdvreceipt', SelectSellAdvreceipt);
		app.component('SelectBuyAdvpayment', SelectBuyAdvpayment);
		app.component('SelectBuyApply', SelectBuyApply);
		app.component('SelectSubject', SelectSubject);
		app.component('SelectAssets', SelectAssets);
		app.component('SelectPurRefund', SelectPurRefund);
		app.component('SelectQualitySubmGoods', SelectQualitySubmGoods);
		app.component('SelectQualitySubmProduct', SelectQualitySubmProduct);
		app.component('SelectProductSubm', SelectProductSubm);
		app.component('SelectQuaSubmOutGoods', SelectQuaSubmOutGoods);
		app.component('SelectQualitySubmProcure', SelectQualitySubmProcure);
		app.component('SelectSaleRefund', SelectSaleRefund);
		app.component('SelectDepot', SelectDepot);
		app.component('Selects', Selects);
		app.component('SelectBom', SelectBom);
		app.component('SelectContract', selectContract);
		app.component('SelectPosition', SelectPosition);
		app.component('SelectTrainOrganization', SelectTrainOrganization);
		app.component('SelectTrainCurriculum', SelectTrainCurriculum);
		app.component('SelectTrainActivity', SelectTrainActivity);
		app.component('SelectWelfareSocialScheme', SelectWelfareSocialScheme);
		app.component('SelectWelfareHousingScheme', SelectWelfareHousingScheme);
		app.component('SelectWelfareOtherScheme', SelectWelfareOtherScheme);
		app.component('SelectAattendanceShift', SelectAattendanceShift);
		app.component('SelectPerformanceTarget', SelectPerformanceTarget);
		app.component('SelectPerformanceBatch', SelectPerformanceBatch);
		app.component('SelectWageSubject', SelectWageSubject);
		app.component('SelectWageTax', SelectWageTax);
		app.component('SelectOaProject', SelectOaProject);
		app.component('SyArchivesDir', syArchivesDir);
		app.component('SyGoodsDir', SyGoodsDir);
		app.component('SyDepartmentDir', SyDepartmentDir);
		app.component('selectOutprocess', selectOutprocess);
		app.component('SelectDetection', SelectDetection);
		app.component('SnInput', SnInput)
		app.component('SnOutput', SnOutput)
		app.component('SyAlertDialog', SyAlertDialog)
		app.component('SelectWaste', SelectWaste)

		//手机版组件
		app.component('WapSelectUser', WapSelectUser);
		app.component('WapSelectCustomer', WapSelectCustomer);
		app.component('WapSelectSaleOrder', WapSelectSaleOrder);
		app.component('WapSelectSaleOrderGoods', WapSelectSaleOrderGoods);
		app.component('WapSelectLogistics', WapSelectLogistics);
		app.component('WapSelectGoods', WapSelectGoods);
		app.component('WapSelectPurchaseApply', WapSelectPurchaseApply);
		app.component('WapSelectPurchaseOrder', WapSelectPurchaseOrder);
		app.component('WapSelectBuyApply', WapSelectBuyApply);
		app.component('WapSelectSupplier', WapSelectSupplier);
		app.component('WapSelectCustomerConacts', WapSelectCustomerConacts);
		app.component('WapSelectCompany', WapSelectCompany);
		app.component('WapSelectDepots', WapSelectDepots);
		app.component('WapSelectAccount', WapSelectAccount);
		app.component('Exam', exam);
		app.component('SyEditTableWap', syEditTableWap);
		app.component('BetterScroll', BetterScroll);

		//注册全局指令
		app.directive('auth', auth)
		app.directive('role', role)
		app.directive('time', time)
		app.directive('copy', copy)
		app.directive('print', print)
		app.directive('resizeHeight', resizeHeight)

		//统一注册el-icon图标
		for (let icon in elIcons) {
			app.component(`ElIcon${icon}`, elIcons[icon])
		}
		//统一注册sc-icon图标
		for (let icon in scIcons) {
			app.component(`ScIcon${icon}`, scIcons[icon])
		}

		// 加载插件
		const Plugins = require.context('./syVab/plugins', true, /\.js$/)
		Plugins.keys().forEach((key) => Plugins(key).setup(app))

		//关闭async-validator全局控制台警告
		window.ASYNC_VALIDATOR_NO_WARNING = 1

		//全局代码错误捕捉
		app.config.errorHandler = errorHandler
	}
}
