<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
	:bef-close="false"
    :width="width"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="物流公司名称"
          :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>

        <el-descriptions-item
          label="物流公司编码"
          :label-align="labelAlign"
        >
          {{ data.number }}
        </el-descriptions-item>

        <el-descriptions-item
          label="所在地区"
          :label-align="labelAlign"
        >
          {{ data.area }}
        </el-descriptions-item>

        <el-descriptions-item
          label="详细地址"
          :label-align="labelAlign"
        >
          {{ data.address }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="联系手机"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.phone }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="联系电话"
          :label-align="labelAlign"
        >
          {{ data.tel }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="传真"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.fax }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="邮政编码"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.zipCode }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="电子邮箱"
          :label-align="labelAlign"
        >
          {{ data.email }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="QQ"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.qq }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="微信"
          :label-align="labelAlign"
        >
          {{ data.weChat }}
        </el-descriptions-item>

        <el-descriptions-item
          label="法定代表人"
          :label-align="labelAlign"
        >
          {{ data.corporation }}
        </el-descriptions-item>

        <el-descriptions-item
          label="委托代理人"
          :label-align="labelAlign"
        >
          {{ data.entrust }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="经营范围"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.businessScope }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="企业简介"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.profile }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
	  <el-card
		  header="财务信息"
		  shadow="never"
		  class="mycard mt_10"
	  >
		  <el-descriptions
			  :title="''"
			  :column="3"
			  :size="$store.state.global.uiSize"
			  border
			  class="column3"
		  >

			  <el-descriptions-item
				  label="开户行"
				  :label-align="labelAlign"
			  >
				  {{ data.bank }}
			  </el-descriptions-item>

			  <el-descriptions-item
				  label="账户名称"
				  :label-align="labelAlign"
			  >
				  {{ data.accountName }}
			  </el-descriptions-item>

			  <el-descriptions-item
				  label="银行账号"
				  :label-align="labelAlign"
			  >
				  {{ data.accountNumber }}
			  </el-descriptions-item>

			  <el-descriptions-item
				  label="联行号"
				  :label-align="labelAlign"
			  >
				  {{ data.bankNo }}
			  </el-descriptions-item>

			  <el-descriptions-item
				  label="许可证号"
				  :label-align="labelAlign"
			  >
				  {{ data.licenseKey }}
			  </el-descriptions-item>

			  <el-descriptions-item
				  label="税号"
				  :label-align="labelAlign"
			  >
				  {{ data.taxCode }}
			  </el-descriptions-item>
			  <el-descriptions-item
				  label="发票类型"
				  :label-align="labelAlign"
			  >
				  {{ data.fz_typeName }}
			  </el-descriptions-item>
			  <el-descriptions-item
				  label="税率"
				  :label-align="labelAlign"
			  >
				  {{ data.taxRate }}
			  </el-descriptions-item>
		  </el-descriptions>
	  </el-card>
    <el-card
      header="联系人"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableSon"
        :data="data.contactList"
        :column="tableSonConfig.columns"
        :height="'auto'"
        row-key="id"
        border
        remote-sort
        hide-do
        hide-pagination
				@sort-change="sortChange"
      >
        <template #gender="{ scope }">
          <span v-if="scope.row['gender'] === 1">男</span>
          <span v-if="scope.row['gender'] === 0">女</span>
        </template>
        <template #importance="{ scope }">
          <span v-if="scope.row['importance'] === 1">关键联系人</span>
          <span v-if="scope.row['importance'] === 2">重要联系人</span>
          <span v-if="scope.row['importance'] === 3">普通联系人</span>
          <span v-if="scope.row['importance'] === 4">普通联系人</span>
          <span v-if="scope.row['importance'] === 5">特别联系人</span>
        </template>
        <template #defaul="{ scope }">
          <span v-if="scope.row['defaul'] === 1">是</span>
          <span v-if="scope.row['defaul'] === 0">否</span>
        </template>
      </scTable>
    </el-card>

<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
	import {
		defineComponent,
		toRefs,
		reactive,
		getCurrentInstance
	} from 'vue'
	export default defineComponent({
		setup(){
			//获取当前实例对象
			const { proxy } = getCurrentInstance()
			const state = reactive({
				//弹窗宽度
				width: '60%',
        type:"detail",
				//加载动画
				loading: true,
				//标题对齐方式 'left' 'right' 'center'
				labelAlign: 'right',
				//尺寸大小
				size: 'small',
				//控制弹窗显示隐藏
				visible: false,
				//弹窗标题
				title: '物流公司详情',
				//详情数据对象
				data: null,
				fileList: [],
				tableSonConfig: {
					//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
					apiObj: null,
					params: {},
					//数据表格列配置
					columns: [
						{
							label: '姓名',
							prop: 'name',
							width: 120,
							sortable: 'custom',
							fixed: 'left'
						},
						{
							label: '性别',
							prop: 'gender',
							width: 90,
							sortable: 'custom',
						},
						// {
						// 	label: '重要程度',
						// 	prop: 'importance',
						// 	sortable: 'custom',
						// 	width: 120,
						// },
						{
							label: '职位',
							prop: 'position',
							width: 120,
							sortable: 'custom',
						},
						// {
						// 	label: '联系手机',
						// 	prop: 'phone',
						// 	width: 120,
						// 	sortable: 'custom',
						// },
						{
							label: '联系电话',
							prop: 'tel',
							width: 120,
							sortable: 'custom',
						},
						{
							label: '电子邮箱',
							prop: 'email',
							width: 120,
							sortable: 'custom',
						},
						// {
						// 	label: 'QQ',
						// 	prop: 'qq',
						// 	width: 120,
						// 	sortable: 'custom',
						// },
						{
							label: '微信',
							prop: 'weChat',
							width: 120,
							sortable: 'custom',
						},
						// {
						// 	label: '传真',
						// 	prop: 'fax',
						// 	width: 120,
						// 	sortable: 'custom',
						// },
						// {
						// 	label: '生日',
						// 	prop: 'birthday',
						// 	width: 160,
						// 	sortable: 'custom',
						// },
						{
							label: '备注',
							prop: 'remark',
							width: 180,
							sortable: 'custom',
						},
						{
							label: '默认',
							prop: 'defaul',
							sortable: 'custom',
							width: 90,
						},
					],
				},
			})
			//显示弹窗
			const showDialog = async (data) => {
				state.fileList = []
				state.data = data
				state.visible = true
				if(data.id){
					//根据id查询
					const res = await proxy.$API.logistics.detailLogistics.get(data.id)
					if(res.code === 200){
						state.data = res.data
						await getContactList(res.data)
						getFileList(res.data.files)
					}
				}
				state.loading = false
			}

			//获取联系人
			const getContactList = async (data) => {
				if(data.id){
					const res = await proxy.$API.logistics.getContactList.get({ logisticsId: data.id })
					if(res.code === 200 && res.data && res.data.length > 0){
						state.data.contactList = res.data
					}
				}
			}

			//隐藏弹窗
			const hideDialog = () => {
				state.visible = false
			}

			const getFileList = (data) => {
				let arr = []
				if (data) {
					arr = data.split(',')
					if (arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							const iarr = item.split('/')
							obj['url'] = item
							obj['name'] = iarr[iarr.length - 1]
							state.fileList.push(obj)
						})
					}
				}
			}

			const previewFile = async (file) => {
				window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
				await proxy.$API.files.insertFilesDownload.post(file)
			}
			const sortChange = async (obj) => {
				if (state.data.id) {
					let param = {}
					if (obj.column && obj.prop && obj.order) {
						param['prop'] = obj.prop
						if (obj.order == 'ascending') {
							param['order'] = 'asc'
						} else if(obj.order == 'descending'){
							param['order'] = "desc";
						} else {
							param['order'] = null
						}
					} else {
						param['prop'] = null
						param['order'] = null
					}
					param['logisticsId'] = state.data.id
					const res = await proxy.$API.logistics.getContactList.get(param)
					if(res.code === 200 && res.data && res.data.length > 0){
						state.data.contactList = res.data
					}
				}
			}
			return{
				...toRefs(state),
				showDialog,
				hideDialog,
				previewFile,
				sortChange
			}
		},
	})
</script>
