<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
    :type="type"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >

				<el-form-item
					prop="license"
					label="车牌号"
				>
					<el-input
						v-model="form.license"
						clearable
						placeholder="请输入"
						maxlength="32"
						@blur="handleLicenseBlur"
					/>
				</el-form-item>
				<el-form-item
					prop="frame"
					label="车架号"
				>
					<el-input
						v-model="form.frame"
						clearable
						placeholder="请输入"
						maxlength="32"
						@blur="handleFrameBlur"
					/>
				</el-form-item>
        <el-form-item
          prop="fz_logisticsName"
          label="物流公司"
        >
            <div class="disflex fullWidth sc-table-select--append">
              <div class="flexgs1">
                <sc-table-select
                    v-model="form.fz_logisticsName"
                    customerValue
                    fullWidth
                    :api-obj="$API.logistics.getListByKeyword"
                    :params="{
                        flag: 1,
                      }"
                    :height="426"
                    :props="{ label: 'name', value: 'id', keyword: 'name' }"
                    @change="(val) => handleSelChange(val, ['logisticsId','fz_logisticsName'])"
                    @clear="inputClear('logistics')"
                >
                  <el-table-column
                      prop="name"
                      label="物流名称"
                      width="200"
                  />
                  <el-table-column
                      prop="number"
                      label="物流编码"
                      width="200"
                  />
            <template #append>
              <el-icon @click="popSelect('refLogisticsInput',['logisticsId','fz_logisticsName'], 1, 'refSelectLogistics')">
                <el-icon-search />
              </el-icon>
            </template>
                </sc-table-select>
              </div>
            </div>
        </el-form-item>
        <el-form-item
          prop="engine"
          label="发动机号"
        >
          <el-input
            v-model="form.engine"
            clearable
            placeholder="请输入"
            maxlength="32"
            @blur="handleEngineBlur"
          />
        </el-form-item>
        <el-form-item
          prop="brand"
          label="品牌"
        >
          <el-input
            v-model="form.brand"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="model"
          label="型号"
        >
          <el-input
            v-model="form.model"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
				<el-form-item
					prop="owner"
					label="所有人"
				>
					<el-input
						v-model="form.owner"
						clearable
						placeholder="请输入"
						maxlength="32"
					/>
				</el-form-item>
				<el-form-item
					prop="useNature"
					label="使用性质"
				>
					<el-input
						v-model="form.useNature"
						clearable
						placeholder="请输入"
						maxlength="32"
					/>
				</el-form-item>
        <el-form-item
          prop="vehicleType"
          label="车辆性质"
        >
          <el-select
            v-model="form.vehicleType"
            clearable
          >
            <el-option
              value="客车"
              label="客车"
            />
            <el-option
              value="货车"
              label="货车"
            />
            <el-option
              value="吊车"
              label="吊车"
            />
            <el-option
              value="叉车"
              label="叉车"
            />
            <el-option
              value="其他"
              label="其他"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="checkQuality"
          label="核定载重"
        >
          <el-input
            v-model="form.checkQuality"
            clearable
            placeholder="请输入"
            maxlength="6"
            @input="(val) => handleNumIpt(val, 'checkQuality')"
          >
            <template #suffix>
              KG
            </template>
          </el-input>
        </el-form-item>
				<el-form-item
				prop="loadingLength"
				label="装载长度"
			>
				<el-input
					v-model="form.loadingLength"
					clearable
					placeholder="请输入"
					maxlength="3"
					@input="(val) => handleNumIpt(val, 'loadingLength')"
				>
					<template #suffix>
						米
					</template>
				</el-input>
			</el-form-item>
				<el-form-item
					prop="loadingWidth"
					label="装载宽度"
				>
					<el-input
						v-model="form.loadingWidth"
						clearable
						placeholder="请输入"
						maxlength="3"
						@input="(val) => handleNumIpt(val, 'loadingWidth')"
					>
						<template #suffix>
							米
						</template>
					</el-input>
				</el-form-item>
				<el-form-item
					prop="loadingHigh"
					label="装载高度"
				>
					<el-input
						v-model="form.loadingHigh"
						clearable
						placeholder="请输入"
						maxlength="3"
						@input="(val) => handleNumIpt(val, 'loadingHigh')"
					>
						<template #suffix>
							米
						</template>
					</el-input>
				</el-form-item>
				<el-form-item
					prop="registerDate"
					label="注册日期"
				>
					<el-date-picker
						v-model="form.registerDate"
						type="date"
						value-format="YYYY-MM-DD"
						clearable
					/>
				</el-form-item>
				<el-form-item
					prop="verificationDate"
					label="年检日期"
				>
					<el-date-picker
						v-model="form.verificationDate"
						type="date"
						value-format="YYYY-MM-DD"
						clearable
					/>
				</el-form-item>
				<el-form-item
					prop="verificationValidate"
					label="年检有效期"
				>
					<el-date-picker
						v-model="form.verificationValidate"
						type="date"
						value-format="YYYY-MM-DD"
						clearable
					/>
				</el-form-item>
				<el-form-item
					prop="flag"
					label="状态"
				>
					<el-select
						v-model="form.flag"
						clearable
					>
						<el-option
							:value="0"
							label="停用"
						/>
						<el-option
							:value="1"
							label="启用"
						/>
						<el-option
							:value="2"
							label="报废"
						/>
						<el-option
							:value="3"
							label="封存"
						/>
						<el-option
							:value="4"
							label="借出"
						/>
						<el-option
							:value="5"
							label="转让"
						/>
						<el-option
							:value="6"
							label="维修"
						/>
					</el-select>
				</el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            clearable
            type="textarea"
            maxlength="255"
            rows="2"
          />
        </el-form-item>
        <el-form-item
          prop="files"
          label="图片"
        >
          <sc-upload-multiple
            v-model="form.images"
            :limit="10"
          />
        </el-form-item>
        <el-form-item
          prop="files"
          label="附件"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'附件'"
            :accept="'*'"
            :is-file="true"
            :list-type="'text'"
            :limit="10"
          >
            <el-button :size="$store.state.global.uiSize" >
              选择文件
            </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    />
  </sc-dialog>
	<SelectLogistics
		ref="refSelectLogistics"
		@set-data="setSelectData"
	/>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import {resetNum, resetZnum} from "@/utils/validate";
import {useRouter} from "vue-router"; //resetZnum返回正整数的方法
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		//验证车牌号重复
		const validateLicense = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		//验证车架号重复
		const validateFrame = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		//验证发动机重复
		const validateEngine = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const {proxy} = getCurrentInstance()
		const state = reactive({
      refFormBar: null,
      type:'detail',
      visibles: false,
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增车辆',
			//表单当前数据
			form: {
				manager: 0,
        fz_logisticsName:''
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				license: [{required: true, message: '必填'},
					{validator: validateLicense}],
        frame: [
          {validator: validateFrame}],
        engine: [
          {validator: validateEngine}],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.logisticsVehicle.getList,
				//弹窗标题
				title:'复制车辆信息',
				height: '500px',
				//表格显示列
				columns: [
					{
						label: '车牌号',
						prop: 'license',
						width: 150,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '物流公司',
						prop: 'fz_logisticsName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '车架号',
						prop: 'frame',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '发动机号',
						prop: 'engine',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '品牌',
						prop: 'brand',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '型号',
						prop: 'model',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '所有人',
						prop: 'owner',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '使用性质',
						prop: 'useNature',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '车辆性质',
						prop: 'vehicleType',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '核定载重(KG)',
						prop: 'checkQuality',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '装载长度(米)',
						prop: 'loadingLength',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '装载宽度(米)',
						prop: 'loadingWidth',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '装载高度(米)',
						prop: 'loadingHigh',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '注册日期',
						prop: 'registerDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '年检日期',
						prop: 'verificationDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '年检有效期',
						prop: 'verificationValidate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
				//查询表单配置
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//查询表单项配置
					itemList: [
						//文本框
						{
							label: '物流公司',
							span: 8,
							prop: 'fz_logisticsName',
							type: 'popLogistics',
							fields: ['logisticsId', 'fz_logisticsName'],
							default: true,
						},
						{
							label: '车牌号',
							span: 8,
							prop: 'license',
							type: 'input',
							max: 32,
							default: true,
						},
						{
							label: '车架号',
							span: 8,
							prop: 'frame',
							type: 'input',
							max: 32,
							default: true,
						},
						{
							label: '发动机号',
							span: 8,
							prop: 'engine',
							type: 'input',
							max: 32,
						},
						{
							label: '品牌',
							span: 8,
							prop: 'brand',
							type: 'input',
							max: 255,
						},
						{
							label: '型号',
							span: 8,
							prop: 'model',
							type: 'input',
							max: 255,
						},
						{
							label: '所有人',
							span: 8,
							prop: 'owner',
							type: 'input',
							max: 255,
						},
						{
							label: '使用性质',
							span: 8,
							prop: 'useNature',
							type: 'input',
							max: 255,
						},
						{
							label: '车辆性质',
							span: 8,
							prop: 'vehicleType',
							type: 'select',
							data: [
								{
									label: '客车',
									value: '客车',
								},
								{
									label: '货车',
									value: '货车',
								},
								{
									label: '吊车',
									value: '吊车',
								},
								{
									label: '叉车',
									value: '叉车',
								},
								{
									label: '其他',
									value: '其他',
								},
							]
						},
						{
							label: '核定载重',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_checkQuality0',
									max: 20,
									append: 'KG',
								},
								{
									prop: 'fz_checkQuality1',
									max: 20,
									append: 'KG',
								},
							]
						},
						{
							label: '装载长度',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_loadingLength0',
									max: 20,
									append: '米',
								},
								{
									prop: 'fz_loadingLength1',
									max: 20,
									append: '米',
								},
							]
						},
						{
							label: '装载宽度',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_loadingWidth0',
									max: 20,
									append: '米',
								},
								{
									prop: 'fz_loadingWidth1',
									max: 20,
									append: '米',
								},
							]
						},
						{
							label: '装载高度',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_loadingHigh0',
									max: 20,
									append: '米',
								},
								{
									prop: 'fz_loadingHigh1',
									max: 20,
									append: '米',
								},
							]
						},
						{
							label: '注册日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_registerDate0',
								},
								{
									prop: 'fz_registerDate1',
								},
							],
						},
						{
							label: '年检日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_verificationDate0',
								},
								{
									prop: 'fz_verificationDate1',
								},
							],
						},
						{
							label: '年检有效期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_verificationValidate0',
								},
								{
									prop: 'fz_verificationValidate1',
								},
							],
						},
						{
							label: '状态',
							span: 8,
							prop: 'flag',
							type: 'select',
							short: 12,
							data: [
								{
									label: '停用',
									value: 0,
								},
								{
									label: '启用',
									value: 1,
								},
								{
									label: '报废',
									value: 2,
								},
								{
									label: '封存',
									value: 3,
								},
								{
									label: '借出',
									value: 4,
								},
								{
									label: '转让',
									value: 5,
								},
								{
									label: '维修',
									value: 6,
								},
							]
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					]
				},
			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增车辆"
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑车辆[" + row.name + ']'
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.user.detailUser.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}



		const handleLicenseBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.logisticsVehicle.doCheckLicense.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state.refForm.validateField('license').then(result => {
						// 处理验证结果
					}).catch(error => {
						// 处理验证错误
					});
				}
			}
		}


		const handleFrameBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.logisticsVehicle.doCheckFrame.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state.refForm.validateField('frame').then(result => {
						// 处理验证结果
					}).catch(error => {
						// 处理验证错误
					});
				}
			}
		}


		const handleEngineBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.logisticsVehicle.doCheckEngine.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state.refForm.validateField('engine').then(result => {
						// 处理验证结果
					}).catch(error => {
						// 处理验证错误
					});
				}
			}
		}
    const handleConfirm = () => {
      save()
      state.visibles = false
    }

    const valiForm = () => {
      nextTick(()=>{
        state.refForm.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		//提交方法
		const save = () => {
			state['refForm'].validate(async (valid) => {
        if (valid) {
          // proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
          state.subLoading = true
          let res = null
          if (state.form.id) { //修改
            res = await proxy.$API.logisticsVehicle.update.put(state.form)
          } else { //新增
            res = await proxy.$API.logisticsVehicle.insert.post(state.form)
          }
          if (res.code === 200) {
            proxy.$baseMessage(res.msg, 'success')
            emit('fetch-data')
            hideDialog()
          } else {
            proxy.$baseMessage(res.msg, 'error')
          }
          state.subLoading = false
          // })
        }
      })
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//输入框失去焦点
		const inputBlur = (field) => {
			state.form['fz_' + field + 'Name'] = state.cacheFields['fz_' + field + 'Name']
		}


		//输入框清除
		const inputClear = (field) => {
			state.form['fz_' + field + 'Name'] = ''
			state.cacheFields['fz_' + field + 'Name'] = ''
		}

		//打开选择弹窗
		//参数(ref触发元素，fields赋值字段，type类型区分多选单选，pop弹窗ref对象)
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = state.form[fields[0]]
				state.fields = fields
				if (type === 1) { //单选
					state.multiples = false
				} else { //多选
					state.multiples = true
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}


		//只能输入数字（负数、小数）
		const handleNumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetNum(val)
			}
		}

		//表单中选择项赋值x
		const setSelectData = async (data) => {
			console.log(data)
			if (state.fields.length > 0) {
				if (data.length) { //多选
					const ids = data.map((item) => {
						return item.id
					}).join()
					const names = data.map((item) => {
						return item.name
					}).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				} else { //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}

		return {
			...toRefs(state),
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			inputBlur,
			inputClear,
			popSelect,
			handleLicenseBlur,
			setSelectData,
			handleNumIpt,
			handleFrameBlur,
			handleEngineBlur,
      valiForm
		}
	},
})
</script>
