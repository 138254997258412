import config from "@/config"
import http from "@/utils/request"

export default {
	getLogisticsList: {
		url: `${config.API_URL}/cfg/data/logistics/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/data/logistics/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	delLogistics: {
		url: `${config.API_URL}/cfg/data/logistics/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailLogistics: {
		url: `${config.API_URL}/cfg/data/logistics/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/data/logistics/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	insertLogistics: {
		url: `${config.API_URL}/cfg/data/logistics/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateLogistics: {
		url: `${config.API_URL}/cfg/data/logistics/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagLogistics0: {
		url: `${config.API_URL}/cfg/data/logistics/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagLogistics1: {
		url: `${config.API_URL}/cfg/data/logistics/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	getContactList: {
		url: `${config.API_URL}/cfg/data/logistics/getContactList`,
		name: "获取联系人列表",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},
}
