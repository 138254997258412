<template>
  <sc-dialog
      ref="dialogRef"
      v-model="visible"
      :title="title"
      :loading="loading"
      :width="width"
      :showFullscreen="false"
      :before-close="beforeClose"
      :trigger-dom="triggerDom"
      :close-on-click-modal="false"
      @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
          v-if="!form.id"
          ref="copyBtn"
          class="ml10"
          :size="$store.state.global.uiSize"
          @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm formset">
      <el-form
          ref="formRef"
          :model="form"
          :label-width="$CONFIG.LABEL_WIDTH"
          label-suffix="："
          :size="$store.state.global.uiSize"
          :rules="rules"
      >
        <el-form-item
            prop="name"
            label="不良品项名称"
        >
          <el-input
              v-model="form.name"
              clearable
              placeholder="请输入"
              @blur="handleNameBlur"
              maxlength="255"
          />
        </el-form-item>
        <el-form-item
            prop="code"
            label="不良品项编码"
        >
          <el-input
              v-model="form.code"
              clearable
              placeholder="请输入"
              maxlength="32"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click="valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
          :title="$TIPS.CONFIRM_RESET"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @confirm="reset(false)"
      >
        <template #reference>
          <el-button
              :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
    </template>
    <SyCopyDialog
        ref="copyDialog"
        :config="copyConfig"
        @set-form="setFormData"
        :ref-query-form="refFormBar"
        remote-sort
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs,} from "vue";
export default defineComponent({
  name: "ProcedureEdit",
  emits: ["fetch-data"],
  setup(props, {emit}) {
    const {proxy} = getCurrentInstance();

    //验证名称重复
    const validateName = (rule, value, callback) => {
      if (state.nameMsg) {
        callback(new Error(state.nameMsg));
      } else {
        callback();
      }
    };

    //验证code重复
    const validateCode = (rule, value, callback) => {
      if (state.codeMsg) {
        callback(new Error(state.codeMsg));
      } else {
        callback();
      }
    };
    const state = reactive({
      refFormBar: null,
      visibles: false,
      triggerDom: {},
      //加载动画
      loading: true,
      //提交加载动画
      subLoading: false,
      dialogRef: null,
      //表单label宽度
      labelWidth: 70,
      formRef: null,
      //弹窗显示属性
      visible: false,
      nameMsg: "",
      //弹窗宽度
      width: 400,
      //弹窗标题
      title: "新增",
      //表单当前数据
      form: {},
      cacheFields: {},
      //暂存原始数据
      orgForm: {},
      rules: {
        name: [
          {required: true, message: "必填"},
          {validator: validateName, rigger: "blur"},
        ],
        code: [{validator: validateCode, trigger: "blur"}],
      },
      //复制信息配置
      copyConfig: {
        //数据接口对象
        apiObj: proxy.$API.produceCraftWaste.getList,
        //弹窗标题
        title: "复制不良品项信息",
        //表格显示列
        columns: [
          {
            label: "不良品项名称",
            prop: "name",
            width: 200,
            sortable: "custom",
            fixed: "left",
          },
          {
            label: "不良品项编码",
            prop: "code",
            width: 150,
            sortable: "custom",
          },
          {
            label: "操作用户",
            prop: "fz_operateName",
            width: 110,
          },
          {
            label: "操作时间",
            prop: "addTime",
            width: 200,
          },
        ],
        searchConfig: {
          //表单标题宽度
          labelWidth: 100,
          //表单项配置
          itemList: [
            {
              label: "不良品项名称",
              prop: "name",
              type: "input",
              max: 20,
              default: true,
            },
            {
              label: "不良品项名称",
              prop: "code",
              type: "input",
              max: 20,
              default: true,
            },
          ],
        },
      }
    });
    const showDialog = async (row) => {
      state.visible = true;
      if (JSON.stringify(row) === "{}") {
        state.title = "新增";
        state.orgForm = Object.assign({}, state.form);
      } else { //编辑
        state.title = "编辑";
        //根据id查询
        const res = await proxy.$API.produceCraftWaste.detail.get(row.id);
        if (res.code === 200) {
          state.form = Object.assign({}, res.data);
          state.orgForm = Object.assign({}, state.form);
        }
      }
      state.loading = false;
    };

    //编码重复校验
    const handleCodeBlur = async (e) => {
      state.codeMsg = "";
      const val = e.target.value;
      if (val !== "") {
        const res = await proxy.$API.produceCraftWaste.doCheckCode.post(state.form);
        if (res.code === 500) {
          state.codeMsg = res.msg;
          state.formRef.validateField("code").then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
        }
      }
    };

    const handleNameBlur = async (e) => {
      state.nameMsg = "";
      const val = e.target.value;
      if (val !== "") {
        const res = await proxy.$API.produceCraftWaste.doCheckName.post(state.form);
        if (res.code === 500) {
          state.nameMsg = res.msg;
          state.formRef.validateField("name").then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
        }
      }
    };

    const copyData = () => {
      nextTick(() => {
        //把触发元素传给弹窗
        proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn;
        proxy.$refs.copyDialog.showDialog();
      });
    };
    //复制信息点击行后返回
    const setFormData = (data) => {
      state.form = Object.assign({}, data);
      //删除id
      delete state.form.id;
    };

    //关闭弹窗
    const hideDialog = () => {
      state.visible = false;
      reset(true);
    };
    const beforeClose = (done) => {
      nextTick(() => {
        proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
          done();
          reset(true);
        });
      });
    };
    const handleConfirm = () => {
      save();
      state.visibles = false;
    };
    const valiForm = () => {
      nextTick(() => {
        state.formRef.validate((valid) => {
          if (valid) {
            state.visibles = true;
          } else {
            state.visibles = false;
          }
        });
      });
    };
    //确定按钮
    const save = () => {
      state["formRef"].validate(async (valid) => {
        if (valid) {
          // proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, async () => {
          state.subLoading = true;
          let res;
          if (state.form.id) { //修改
            res = await proxy.$API.produceCraftWaste.update.put(state.form);
          } else { //新增
            res = await proxy.$API.produceCraftWaste.insert.post(state.form);
          }
          if (res.code === 200) {
            proxy.$baseMessage(res.msg, "success");
            emit("fetch-data");
            hideDialog();
          } else {
            proxy.$baseMessage(res.msg, "error");
          }
          state.subLoading = false;
          // })
        }
      });
    };
    //重置表单
    const reset = (isClose) => {
      state["formRef"].resetFields();
      const orgForm = state.orgForm;
      Object.keys(state.form).forEach((item) => {
        delete state.form[item];
      });
      if (!isClose) {
        state.form = Object.assign({}, orgForm);
      }
    };

    //详情
    const handleDetail = (row) => {
      proxy.$refs.refDetail.showDialog(row);
    };

    return {
      ...toRefs(state),
      handleDetail,
      copyData,
      setFormData,
      valiForm,
      save,
      reset,
      beforeClose,
      showDialog,
      hideDialog,
      handleConfirm,
      handleCodeBlur,
      handleNameBlur,
    };
  },
});
</script>

<style scoped>

</style>
