import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/assets/reduce/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getAllList: {
		url: `${config.API_URL}/fms/assets/reduce/getAllList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/assets/reduce/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	del: {
		url: `${config.API_URL}/fms/assets/reduce/del`,
		name: "查询",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/assets/reduce/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/assets/reduce/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/assets/reduce/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	updateFlag: {
		url: `${config.API_URL}/fms/assets/reduce/updateFlag`,
		name: "修改状态",
		put: async function (data = {}) {
			return await http.put(this.url + "?id=" + data);
		}
	},
	create: {
		url: `${config.API_URL}/fms/assets/reduce/create`,
		name: "生成凭证",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	createVoucher: {
		url: `${config.API_URL}/fms/assets/reduce/createVoucher`,
		name: "生成凭证",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
