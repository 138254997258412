import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/finance/CashFlow/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/finance/CashFlow/getAllList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

    insert: {
        url: `${config.API_URL}/cfg/finance/CashFlow/insert`,
        name: "新增",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
	},
    update: {
        url: `${config.API_URL}/cfg/finance/CashFlow/update`,
        name: "修改",
        put: async function (data = {}) {
            return await http.put(this.url, data);
        }
	},
    detail: {
        url: `${config.API_URL}/cfg/finance/CashFlow/detail`,
        name: "查询详情",
        get: async function (params) {
            return await http.get(this.url + "?id=" + params);
        }
    },
    delete: {
        url: `${config.API_URL}/cfg/finance/CashFlow/delete`,
        name: "删除",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },
	checkNumber: {
		url: `${config.API_URL}/cfg/finance/CashFlow/checkNumber`,
		name: "编码唯一校验",
		get: async function(data){
			return await http.post(this.url, data);
		}
	},
	checkName: {
		url: `${config.API_URL}/cfg/finance/CashFlow/checkName`,
		name: "名称唯一校验",
		get: async function(data){
			return await http.post(this.url, data);
		}
	},
	queryBottom: {
		url: `${config.API_URL}/cfg/finance/CashFlow/queryBottom`,
		name: "现金流初始化列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getParent: {
		url: `${config.API_URL}/cfg/finance/CashFlow/getParent`,
		name: "查询父级类别",
		get: async function (data = {}) {
			return await http.get(this.url + "?parentId=" + data);
		}
	},
	beginUpdate: {
		url: `${config.API_URL}/cfg/finance/CashFlow/beginUpdate`,
		name: "现金流初始化金额修改",
		get: async function (data = {}) {
			return await http.put(this.url,data);
		}
	},
}
