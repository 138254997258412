import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/examine/flow/getList`,
		name: "查询流程",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	deploymentGetList: {
		url: `${config.API_URL}/cfg/examine/flow/deploymentGetList`,
		name: "查询已部署的流程",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	selectDetail: {
		url: `${config.API_URL}/cfg/examine/flow/selectDetail`,
		name: "查询流程",
		get: async function(data={}){
			return await http.get(this.url + '?id=' + data);
		}
	},
	createFile: {
		url: `${config.API_URL}/cfg/examine/flow/create`,
		name: "创建流程",
		post: async function(params){
			return await http.post(this.url, params);
		}
	},
	update:{
		url: `${config.API_URL}/cfg/examine/flow/update`,
		name: "修改流程",
		put: async function(params){
			return await http.put(this.url, params);
		}
	},
	deploy:{
		url: `${config.API_URL}/cfg/examine/flow/deploy`,
		name: "流程部署",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	cancelDeploy:{
		url: `${config.API_URL}/cfg/examine/flow/cancelDeploy`,
		name: "流程部署",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/examine/flow/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/examine/flow/selectDetail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

}
