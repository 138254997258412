import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/sale/contract/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/sale/contract/insert`,
		name: "新增销售报价",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/sale/contract/update`,
		name: "修改销售报价",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/sale/contract/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/mobile/sale/contract/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateContractStatus: {
		url: `${config.API_URL}/mobile/sale/contract/updateContractStatus`,
		name: "修改合同状态",
		get: async function(ids,contractStatus){
			return await http.get(this.url + '?ids=' + ids + '&contractStatus=' + contractStatus);
		}
	},

}
