<!--选择加工单弹窗组件-->
<template>
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="dftitle"
      :loading="loading"
      :width="width"
      :trigger-dom="triggerDom"
      :bef-close="false"
			height="400px"
      custom-class="popTable"
  >
    <el-container>
			<el-header class="tableTopBar">
				<div class="left-panel">
					<el-button
							:type="'primary'"
							:size="$store.state.global.uiSize"
							@click="handleEdit(0)"
					>
						新增
					</el-button>
					<el-link
							:type="'primary'"
							:size="$store.state.global.uiSize"
							:disabled="isEdit"
							@click="handleEdit(1)"
					>
						修改
					</el-link>
					<el-link
							:type="'primary'"
							:size="$store.state.global.uiSize"
							:disabled="delDisable"
							@click="handleDelete"
					>
						删除
					</el-link>
					<el-link
							:type="'primary'"
							:size="$store.state.global.uiSize"
							:disabled="isDef"
							@click="handleDefault"
					>
						设为默认
					</el-link>
				</div>
			</el-header>
			<el-container>
				<el-aside width="30%">
					<template v-for="(item,index) in preRuleList" :key="index">
						<div
							v-loading="listLoading"
							:class="actNumber === item.number ? 'active' : ''"
							class="left-item"
							@click="itemClick(item)"
						>
							{{item.number}}  {{item.name}}
						</div>
					</template>
				</el-aside>
				<el-main>
					<el-tabs
						:tab-position="'top'"
						class="demo-tabs"
						v-model="activeName"
						@tab-change="tabChange"
					>
						<el-tab-pane
							name="baseSet"
							label="基础信息配置"
						>
							<el-form ref="myForm" :model="form" :rules="rules" :size="$store.state.global.uiSize" :label-width="60" label-suffix="：">
								<el-form-item label="名称" prop="name">
									<el-input
										v-model="form.name"
										:disabled="disForm"
										@blur="handleCheckName"
									/>
								</el-form-item>
								<el-form-item label="编码" prop="number">
									<el-input
										v-model="form.number"
										:disabled="disForm"
										:maxlength="11"
										@blur="handleCheckNumber"
									/>
								</el-form-item>
							</el-form>
						</el-tab-pane>
						<el-tab-pane name="ruleSet" label="规则配置" style="width: 100%!important;">
							<el-form :size="$store.state.global.uiSize" label-width="40px" label-position="right">
								<el-row>
									<el-col :span="11">
										<el-form-item :label="1">
											<el-select
												v-model="form.term1"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												:disabled="disForm"
												style="width: 100%;"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="11">
										<el-form-item :label="2">
											<el-select
												v-model="form.term2"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term1"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="11">
										<el-form-item :label="3">
											<el-select
												v-model="form.term3"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term2"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="11">
										<el-form-item :label="4">
											<el-select
												v-model="form.term4"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term3"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="11">
										<el-form-item :label="5">
											<el-select
												v-model="form.term5"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term4"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="11">
										<el-form-item :label="6">
											<el-select
												v-model="form.term6"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term5"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="11">
										<el-form-item :label="7">
											<el-select
												v-model="form.term7"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term6"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="11">
										<el-form-item :label="8">
											<el-select
												v-model="form.term8"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term7"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
											<!--                                </el-col>-->
										</el-form-item>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="11">
										<el-form-item :label="9">
											<el-select
												v-model="form.term9"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term8"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="11">
										<el-form-item :label="10">
											<el-select
												v-model="form.term10"
												clearable
												filterable
												allow-create
												placeholder="请选择"
												style="width: 100%;"
												:disabled="disForm || !form.term9"
												@blur="(val)=>blurChange(val)"
												@change="(val)=>optionChange(val)"
											>
												<el-option
													v-for="rule in ruleList"
													:key="rule.value"
													:label="rule.label"
													:value="rule.value"
												/>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
								<!--                            </template>-->
							</el-form>
						</el-tab-pane>
						<el-tab-pane name="choose" label="流水号选项">
							<el-form :size="$store.state.global.uiSize" label-position="right" label-width="50px">
								<h3 style="margin-bottom: 20px; padding-left: 10px">流水号选项</h3>
								<el-radio-group v-model="form.option" :disabled="disForm">
									<el-radio :label="0" class="mt_5">自定义编号递增</el-radio>
									<el-radio :label="1" class="mt_5">全年编号递增</el-radio>
									<el-radio :label="2" class="mt_5">每月编号递增</el-radio>
									<el-radio :label="3" class="mt_5">每日编号递增</el-radio>
								</el-radio-group>
							</el-form>
						</el-tab-pane>
					</el-tabs>
				</el-main>
			</el-container>
    </el-container>
    <template #footer>
      <el-button
          :size="$store.state.global.uiSize"
          type="primary"
          :disabled="isConfirm"
          @click="handleConfirm"
      >
        保存
      </el-button>
      <el-button
          :size="$store.state.global.uiSize"
          @click="visible = false"
      >
        退出
      </el-button>
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <ProducePlanDetail ref="refProducePlanDetail" />
    <ProduceProcessDetail ref="refProduceProcessDetail" />
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
import {setColSpan} from "@/utils/window";
import {getNowDate} from "@/utils/date";
export default defineComponent ({
  name: "BatchEntry",
  components: {GoodsDetail, Detail ,ProducePlanDetail,ProduceProcessDetail},
  props: {
    //传入标题
    title: { type: String, default: '序列号自动生成规则' },
  },
  emits: [ 'set-data' ],
  setup(props, { emit }){
    const { proxy } = getCurrentInstance()
    //验证code重复
    const validateCode = (rule, value, callback) => {
      if (state.numberMsg) {
        callback(new Error(state.numberMsg))
      } else {
        callback()
      }
    }
    //验证name重复
    const validateName = (rule, value, callback) => {
      if (state.nameMsg) {
        callback(new Error(state.nameMsg))
      } else {
        callback()
      }
    }
    const state = reactive({
      //表单项占比
      span: setColSpan(),
      //表单验证
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '必填'},
          {validator: validateName, trigger: 'blur'},
        ],
        number: [
          {required: true, trigger: 'blur', message: '必填'},
          {validator: validateCode, trigger: 'blur'},
        ],
      },
			numberMsg: '',
      nameMsg: '',
			actNumber: null,
			myForm: null,
			goodsId: null,
			listLoading: false,
      //选项卡当前选中页
      activeName: 'baseSet',
      isDelSel: true,
      paneSizeT: 10,
      paneSizeB: 60,
      paneSize: 20,
      paneSize1: 50,
      leftShow: true,

      preRuleList: [],
      ruleList: [
        {label:'年份('+getNowDate().substring(0,4)+')',value:'*年份'},
        {label:'月('+getNowDate().substring(5,7)+')',value:'*月'},
        {label:'日('+getNowDate().substring(8,10)+')',value:'*日'},
        {label:'年月日('+getNowDate().substring(0,4)+getNowDate().substring(5,7)+getNowDate().substring(8,10)+')',value:'*年月日'},
        {label:'物品编号',value:'*物品编号'},
        {label:'流水号[0]',value:'*流水号[0]'},
        {label:'流水号[00]',value:'*流水号[00]'},
        {label:'流水号[000]',value:'*流水号[000]'},
        {label:'流水号[0000]',value:'*流水号[0000]'},
      ],
      form:{
        // 规则项目
        term1: '',
        term2: '',
        term3: '',
        term4: '',
        term5: '',
        term6: '',
        term7: '',
        term8: '',
        term9: '',
        term10: '',
        //流水号选项
				option: 0,
      },
      //隐藏搜索表单
      hideForm: true,
      //是否显示查询表单隐藏项
      showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
      //查询表单
      queryForm: {},
      //弹窗标题
      dftitle: props.title,
      //打开弹窗触发元素
      triggerDom: {},
      //加载动画
      loading: true,
      //弹窗显示属性
      visible: false,
      //弹窗宽度
      width: '50%',
			//选中行
			curRow: null,
      //确定按钮状态
      isConfirm: true,
			//编辑按钮
			isEdit: true,
			//删除按钮
			delDisable: true,
			//设为默认
			isDef: true,
			//表单项
			disForm: true,
    })

    const getPreRuleList = async ()=> {
			state.listLoading = true
			if(state.goodsId){
				const res = await proxy.$API.snRules.getList.get({goodsId: state.goodsId})
				if (res.code == 200 && res.data && res.data.list) {
					state.preRuleList = res.data.list
					if(res.data.list.length > 0){
						itemClick(res.data.list[0])
					}
					console.log(res)
				}
			}
			state.listLoading = false
    }
    //显示弹窗
    const showDialog = (goodsId) => {
      state.visible = true
      if(goodsId) {
        state.goodsId = goodsId
        //在打开弹窗的时候查规则列表，数据先传给了tree再得到查询结果赋值改变，，需要的是先查询结果改变了值再传给tree
        getPreRuleList()
      }
      state.loading = false
    }

		const itemClick = (item) => {
			if(item){
				state.curRow = item
				state.isEdit = false
				state.delDisable = false
				state.isDef = !(item.flag === 0)
				state.actNumber = item.number
				state.disForm = true
				state.form = Object.assign({},item)
			}
		}

    //关闭弹窗
    const hideDialog = () => {
      state.visible = false
    }

    //切换显示左侧边栏
    const handleShowLeft = (bool) => {
      state.leftShow = bool
    }
    const tabChange = (name) => {
      console.log(name)
    }

    //名字校验
    const handleCheckName = async (e) => {
      state.nameMsg = ''
      const val = state.form.name
      if (val !== '') {
        //如果是新增就只传name
        let preRule = {}
        preRule.name = val
        if (state.form.id) {
          //如果是编辑校验就需要把数据的id，name之类的都拿到
          preRule = Object.assign({}, state.form)
        }
        const res = await proxy.$API.snRules.checkName.get(preRule)
        console.log(res)
        if (res.code === 500) {
          state.nameMsg = res.msg
          state.myForm.validateField('name').then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
          // proxy.$baseMessage(res.msg, 'error')
        } else {
          // proxy.$baseMessage('校验通过', 'success')
          // state.subLoading = false
        }
      }else {
        return
      }
    }
    //编码校验
    const handleCheckNumber = async (e) => {
      state.numberMsg = ''
      const val = state.form.number - 0
      if (val !== '') {
        //如果是新增就只传name
        let preRule = {}
        preRule.number = val
        if (state.form.id) {
          //如果是编辑校验就需要把数据的id，name之类的都拿到
          preRule = Object.assign({}, state.form)
        }
        const res = await proxy.$API.snRules.checkNumber.get(preRule)
        console.log(res)
        if (res.code === 500) {
          state.numberMsg = res.msg
          state.myForm.validateField('number').then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
          // proxy.$baseMessage(res.msg, 'error')
        } else {
          // proxy.$baseMessage('校验通过', 'success')
          // state.subLoading = false
        }
      }else {
        return
      }
    }

    //按钮操作
    // 设置默认
    const handleDefault = async ()=>{
      // 传选中规则的id，设置默认
			if(state.curRow){
				const res = await proxy.$API.snRules.setDefault.get(state.curRow.id)
				console.log(res)
				if(res.code==200){
					proxy.$baseMessage('设为默认成功！','success')
					await getPreRuleList(state.goodsId)
					//触发父级赋值方法，这边新增成功，父级弹窗的预设规则选框选项应该重新请求获取
					// emit('set-data')
					emit('repre-rule')
				}else{
					proxy.$baseMessage(res.msg, 'error');
				}
			}
    }

    // 下拉选择规则
    const optionChange = (val) => {
      console.log(val)
      console.log(state.form)
    }
    // 手动输入规则
    const blurChange = (val)=>{
      console.log(val)
    }

    const handleEdit = async (flag)=>{
			state.isConfirm = false
      if(flag==0){
        //新增，基础配置表单解除禁用，输入名字和编码后可以新增
        state.activeName = 'baseSet'
        console.log(state.activeName)
        state.form = Object.assign({},{goodsId: state.goodsId,option: 0})
      }else{
				//编辑
        // if(state.curRow){
				// 	state.form = Object.assign({},state.curRow)
				// }
      }
			state.isEdit = true
			state.delDisable = true
			state.isDef = true
			state.disForm = false
    }
		const handleDelete = () => {
			if (state.curRow) {
				proxy.$baseConfirm('是否确认删除？', null, async () => {
					const res = await proxy.$API.snRules.delete.get(state.curRow.id)
					if(res.code === 200){
						proxy.$baseMessage('删除成功！','success')
						await getPreRuleList(state.goodsId)
						//触发父级赋值方法，这边新增成功，父级弹窗的预设规则选框选项应该重新请求获取
						// emit('set-data')
						emit('repre-rule')
					}else {
						proxy.$baseMessage(res.msg, 'error')
					}
				})
			}
		}
    //确定按钮点击
    const handleConfirm = () => {
			console.log(state.form)
			nextTick(() => {
				state.myForm.validate((valid) => {
					if (valid) {
						proxy.$baseConfirm('是否确认保存？', null, async () => {
							let res = ''
							if (state.form.id) {
								res = await proxy.$API.snRules.update.get(state.form)
							} else {
								res = await proxy.$API.snRules.insert.get(state.form)
							}
							if (res.code == 200) {
								proxy.$baseMessage(res.msg, 'success')
								//设置成功后应该刷新本页面
								await getPreRuleList(state.goodsId)
								//触发父级赋值方法，这边新增成功，父级弹窗的预设规则选框选项应该重新请求获取
								// emit('set-data')
								emit('repre-rule')
							} else {
								proxy.$baseMessage(res.msg, 'error')
							}
						})
					}else {
						// proxy.$baseMessage('请完善必填项！','error')
						state.activeName = 'baseSet'
					}
				})
			})
    }

    //保存表单显示状态
    const handleFitShow = (bool) => {
      state.showItem = bool
    }

    //切换显示搜索栏
    const handleShowSearch = () => {
      nextTick(() => {
        state.hideForm = !state.hideForm
      })
    }
    return{
      ...toRefs(state),
      showDialog,
      hideDialog,
      handleShowLeft,
      handleCheckName,
      handleCheckNumber,
      tabChange,
      handleEdit,
			handleDelete,
      handleConfirm,
      handleDefault,
      optionChange,
      blurChange,
      handleFitShow,
      handleShowSearch,
			itemClick,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-radio-group{
  flex-direction: column;
  align-items: baseline;
  padding-left: 10px;
}
.el-tabs{
  width: 100%!important;
}
:deep(.el-tabs__nav-scroll){
  line-height: 44px!important;
  position: relative;
  &::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
    bottom: 0;
    left: 0;
  }
}
.left-item{
 	width: 100%;
	padding: 10px;
	cursor: pointer;
	&:hover{
		background: #ebecf3;
	}
}
.active{
	background: #ebecf3;
}
</style>
