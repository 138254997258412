import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/quality/submOutsource/getList`,
		name: "委外送检单列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getOutProcessList: {
		url: `${config.API_URL}/erp/quality/submOutsource/getOutProcessList`,
		name: "查询未在生成状态下的委外加工单",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/quality/submOutsource/insert`,
		name: "新增",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/erp/quality/submOutsource/update`,
		name: "修改",
		get: async function (data) {
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/quality/submOutsource/detail`,
		name: "查询单条数据详情",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	delete: {
		url: `${config.API_URL}/erp/quality/submOutsource/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	getDetailList:{
		url: `${config.API_URL}/erp/quality/submOutsourceDetail/getList`,
		name: "查询详情的列表",
		get: async function (params={}) {
			return await http.post(this.url,params);
		}
	},
	getSonDetail:{
		url: `${config.API_URL}/erp/quality/submOutsourceDetail/detail`,
		name: "查询子表",
		get: async function (params={}) {
			return await http.post(this.url,params);
		}
	},
	getDetail:{
		url: `${config.API_URL}/erp/quality/submOutsource/detail`,
		name: "查询子表",
		get: async function (params={}) {
			return await http.get(this.url + '?id=' + params)
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/quality/submOutsourceDetail/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
