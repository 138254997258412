<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
    :type="type"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
<!--      <el-button-->
<!--        v-if="!form.id"-->
<!--        ref="copyBtn"-->
<!--        class="ml10"-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="copyData"-->
<!--      >-->
<!--        复制信息-->
<!--      </el-button>-->
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
		  <el-form-item
			  prop="name"
			  label="司机姓名"
		  >
			  <el-input
				  v-model="form.name"
				  clearable
				  placeholder="请输入"
				  maxlength="255"
			  />
		  </el-form-item>

        <el-form-item
          prop="fz_logisticsName"
          label="物流公司"
        >
          <div class="disflex fullWidth sc-table-select--append">
            <div class="flexgs1">
              <sc-table-select
                  v-model="form.fz_logisticsName"
                  customerValue
                  fullWidth
                  :api-obj="$API.logistics.getListByKeyword"
                  :params="{
                        flag: 1,
                      }"
                  :height="426"
                  :props="{ label: 'name', value: 'id', keyword: 'name' }"
                  @change="(val) => handleSelChange(val, ['logisticsId','fz_logisticsName'])"
                  @clear="inputClear('logistics')"
              >
                <el-table-column
                    prop="name"
                    label="物流名称"
                    width="200"
                />
                <el-table-column
                    prop="number"
                    label="物流编码"
                    width="200"
                />
            <template #append>
              <el-icon @click="popSelect('refLogisticsInput',['logisticsId','fz_logisticsName'], 1, 'refSelectLogistics')">
                <el-icon-search />
              </el-icon>
            </template>
              </sc-table-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          prop="gender"
          label="性别"
        >
          <el-radio-group
            v-model="form.gender"
          >
            <el-radio :label="1">
              男
            </el-radio>
            <el-radio :label="0">
              女
            </el-radio>
          </el-radio-group>
        </el-form-item>
		  <el-form-item
			  prop="age"
			  label="年龄"
		  >
			  <el-input
				  v-model="form.age"
				  clearable
				  placeholder="请输入"
				  maxlength="3"
				  @input="(val) => handleZnumIpt(val, 'age')"
			  />
		  </el-form-item>
        <el-form-item
          prop="code"
          label="身份证号"
        >
          <el-input
            v-model="form.code"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="phone"
          label="联系电话"
        >
          <el-input
            v-model="form.phone"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="driveType"
          label="驾照类型"
          class="seterror"
        >
          <el-select
            v-model="form.driveType"
            clearable
          >
            <el-option
              value="A1"
              label="A1"
            />
            <el-option
              value="A2"
              label="A2"
            />
            <el-option
              value="A3"
              label="A3"
            />
            <el-option
              value="B1"
              label="B1"
            />
            <el-option
              value="B2"
              label="B2"
            />
            <el-option
              value="B3"
              label="B3"
            />
            <el-option
              value="C1"
              label="C1"
            />
            <el-option
              value="C2"
              label="C2"
            />
            <el-option
              value="C3"
              label="C3"
            />
            <el-option
              value="C4"
              label="C4"
            />
            <el-option
              value="其他"
              label="其他"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="vehicleType"
          label="准驾车型"
        >
          <el-input
            v-model="form.vehicleType "
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="issueDate"
          label="领证日期"
        >
          <el-date-picker
            v-model="form.issueDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
		  <el-form-item
			  prop="drilicense"
			  label="驾驶证号"
		  >
			  <el-input
				  v-model="form.drilicense"
				  clearable
				  placeholder="请输入"
				  maxlength="32"
			  />
		  </el-form-item>
        <el-form-item
          prop="verificationDate"
          label="年检日期"
        >
          <el-date-picker
            v-model="form.verificationDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
		  <el-form-item
			  prop="licenseAgency"
			  label="发证机构"
		  >
			  <el-input
				  v-model="form.licenseAgency"
				  clearable
				  placeholder="请输入"
				  maxlength="255"
			  />
		  </el-form-item>
		  <el-form-item
			  prop="licenseDate"
			  label="发证日期"
		  >
			  <el-date-picker
				  v-model="form.licenseDate"
				  type="date"
				  value-format="YYYY-MM-DD"
				  clearable
			  />
		  </el-form-item>
		  <el-form-item
			  prop="invalidDate"
			  label="过期日期"
		  >
			  <el-date-picker
				  v-model="form.invalidDate"
				  type="date"
				  value-format="YYYY-MM-DD"
				  clearable
			  />
		  </el-form-item>
		  <el-form-item
			  prop="flag"
			  label="状态"
		  >
			  <el-select
				  v-model="form.flag"
				  clearable
			  >
				  <el-option
					  :value="0"
					  label="停用"
				  />
				  <el-option
					  :value="1"
					  label="启用"
				  />
				  <el-option
					  :value="2"
					  label="过期"
				  />
			  </el-select>
		  </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            clearable
            type="textarea"
            maxlength="255"
            rows="1"
          />
        </el-form-item>
		  <el-form-item
			  prop="imgs"
			  label="图片"
		  >
			  <sc-upload-multiple
				  v-model="form.imgs"
				  :limit="10"
			  />
		  </el-form-item>
        <el-form-item
          prop="files"
          label="附件"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'附件'"
            :accept="'*'"
            :is-file="true"
            :list-type="'text'"
            :limit="10"
          >
            <el-button :size="$store.state.global.uiSize" >
              选择文件
            </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-popconfirm
        :visible="visibles"
        :title="$TIPS.SAVE"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @cancel="visibles = false"
        @confirm="handleConfirm"
    >
      <template #reference>
        <el-button
            type="primary"
            :size="$store.state.global.uiSize"
            :loading="subLoading"
            @click = "valiForm"
        >
          提交
        </el-button>
      </template>
    </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
		>

			<template #name="{ scope }">
				<el-link
					type="primary"
					@click="handleDetail(scope.row, 'refDetail')"
				>
					{{ scope.row.name }}
				</el-link>
			</template>

			<template #gender="{ scope }">
				<span>{{ scope.row.gender == 1 ? '男' : '女' }}</span>
			</template>

			<!--物流公司-->
			<template #fz_logisticsName="{ scope }">
				<el-link
					type="primary"
					@click="handleDetail(scope.row, 'refLogisticsDetail')"
				>
					{{ scope.row.fz_logisticsName }}
				</el-link>
			</template>
			<template #flag="{ scope }">
				<el-tag
					v-if="scope.row.flag === 0"
					type="danger"
					class="myTag"
					:size="$store.state.global.uiSize"
				>
					停用
				</el-tag>
				<el-tag
					v-if="scope.row.flag === 1"
					type="success"
					class="myTag"
					:size="$store.state.global.uiSize"
				>
					启用
				</el-tag>
				<el-tag
					v-if="scope.row.flag === 2"
					type="info"
					class="myTag"
					:size="$store.state.global.uiSize"
				>
					过期
				</el-tag>
			</template>
		</SyCopyDialog>

	  <!--选择物流公司-->
	  <SelectLogistics
		  ref="refSelectLogistics"
		  @set-data="setSelectData"
	  />

  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import {resetZnum} from "@/utils/validate";
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		//验证车牌号重复
		const validateLicense = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		//验证车架号重复
		const validateFrame = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		//验证发动机重复
		const validateEngine = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const {proxy} = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
      type:'detail',
      visibles: false,
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			cacheFields: {},
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增车辆',
			//表单当前数据
			form: {
				manager: 0,
        fz_logisticsName:'',
				gender: 1
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				// fz_logisticsName: [{required: true, trigger: 'blur', message: '必填'}],
				name: [{required: true, message: '必填'}],
				// gender: [{required: true, trigger: 'change', message: '必填'}],
				// age: [{required: true, message: '必填'}],
				// code: [{required: true, message: '必填'}],
				// phone: [{required: true, message: '必填'}],
				// driveType: [{required: true, message: '必填'}],
				// vehicleType: [{required: true, message: '必填'}],
				// issueDate: [{required: true, message: '必填'}],
				// drilicense: [{required: true, message: '必填'}],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				//TODO 修改接口
				apiObj: proxy.$API.logisticsDriver.getList,
				//弹窗标题
				title:'复制司机信息',
				height: '500px',
				//表格显示列
				columns: [
					{
						label: '物流公司',
						prop: 'fz_logisticsName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '姓名',
						prop: 'name',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '性别',
						prop: 'gender',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '年龄',
						prop: 'age',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '身份证号',
						prop: 'code',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '联系电话',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '驾照类型',
						prop: 'driveType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '准驾车型',
						prop: 'vehicleType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '领证日期',
						prop: 'issueDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '驾驶证号',
						prop: 'drilicense',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '年检日期',
						prop: 'verificationDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '发证机构',
						prop: 'licenseAgency',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '发证日期',
						prop: 'licenseDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '过期日期',
						prop: 'invalidDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
				//查询表单配置
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//查询表单项配置
					itemList: [
						//文本框
						{
							label: '物流公司',
							span: 8,
							prop: 'fz_logisticsName',
							type: 'popLogistics',
							fields: ['logisticsId', 'fz_logisticsName'],
							default: true,
						},
						{
							label: '司机姓名',
							span: 8,
							prop: 'name',
							type: 'input',
							max: 255,
							default: true,
						},
						{
							label: '性别',
							span: 8,
							prop: 'gender',
							type: 'select',
							default: true,
							short: 12,
							data: [
								{
									label: '男',
									value: 1,
								},
								{
									label: '女',
									value: 0,
								},
							]
						},
						{
							label: '年龄',
							type: 'rangeNum',
							span: 8,
							fields: [
								{
									prop: 'fz_age0',
									max: 3,
									append: '岁',
								},
								{
									prop: 'fz_age1',
									max: 3,
									append: '岁',
								},
							]
						},
						{
							label: '身份证号',
							span: 8,
							prop: 'code',
							max: 255,
							type: 'input',
						},
						{
							label: '联系电话',
							span: 8,
							prop: 'phone',
							max: 32,
							type: 'number',
						},
						{
							label: '驾照类型',
							span: 8,
							prop: 'driveType',
							type: 'select',
							data: [
								{
									label: 'A1',
									value: 'A1',
								},
								{
									label: 'A2',
									value: 'A2',
								},
								{
									label: 'A3',
									value: 'A3',
								},
								{
									label: 'B1',
									value: 'B1',
								},
								{
									label: 'B2',
									value: 'B2',
								},
								{
									label: 'B3',
									value: 'B3',
								},
								{
									label: 'C1',
									value: 'C1',
								},
								{
									label: 'C2',
									value: 'C2',
								},
								{
									label: 'C3',
									value: 'C3',
								},
								{
									label: 'C4',
									value: 'C4',
								},
								{
									label: '其他',
									value: '其他',
								},
							]
						},
						{
							label: '准驾车型',
							span: 8,
							prop: 'vehicleType',
							max: 255,
							type: 'input',
						},
						{
							label: '领证日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_issueDate0',
								},
								{
									prop: 'fz_issueDate1',
								},
							],
						},
						{
							label: '驾驶证号',
							span: 8,
							prop: 'drilicense',
							max: 32,
							type: 'input',
						},
						{
							label: '年检日期',
							type: 'dateRange',
							span: 8,
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_verificationDate0',
								},
								{
									prop: 'fz_verificationDate1',
								},
							],
						},
						{
							label: '发证机构',
							span: 8,
							prop: 'licenseAgency',
							max: 255,
							type: 'input',
						},
						{
							label: '发证日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_licenseDate0',
								},
								{
									prop: 'fz_licenseDate1',
								},
							],
						},
						{
							label: '过期日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_invalidDate0',
								},
								{
									prop: 'fz_invalidDate1',
								},
							],
						},
						{
							label: '状态',
							prop: 'flag',
							span: 8,
							type: 'select',
							short: 12,
							data: [
								{
									label: '停用',
									value: 0,
								},
								{
									label: '启用',
									value: 1,
								},
								{
									label: '过期',
									value: 2,
								},
							]
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
              max:32,
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					]
				},
			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增司机"
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑司机[" + row.name + ']'
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.logisticsDriver.detail.get(row.id)
			if(res.code === 200) {
				console.log(res.data)
				state.form = Object.assign({}, res.data)
				state.form.logistics = {id:res.data.logisticsId,name:res.data.fz_logisticsName}
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

    const handleConfirm = () => {
      save()
      state.visibles = false
      state.subLoading = false
    }

    const valiForm = () => {
      nextTick(()=>{
        state.refForm.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		//提交方法
		const save = () => {
			state['refForm'].validate(async (valid) => {
        if (valid) {
          state.subLoading = true
          let res = null
          if (state.form.id) { //修改
            res = await proxy.$API.logisticsDriver.update.put(state.form)
          } else {
            res = await proxy.$API.logisticsDriver.insert.post(state.form)
          }
          if (res.code === 200) {
            proxy.$baseMessage(res.msg, 'success')
            emit('fetch-data')
            hideDialog()
          } else {
            proxy.$baseMessage(res.msg, 'error')
          }
          state.subLoading = false
        }
      })
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}


		const setSelectData = (data) => {
			if (state.fields.length > 0) {
				if (data.length > 1) { //多选
					const ids = data.map((item) => {
						return item.id
					}).join()
					const names = data.map((item) => {
						return item.name
					}).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				} else { //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}


		//输入框失去焦点
		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			state.form[field + 'Id'] = ''
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}



		//打开选择弹窗
		//参数(ref触发元素，fields赋值字段，type类型区分多选单选，pop弹窗ref对象)
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}


		//只能输入正整数
		const handleZnumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetZnum(val)
			}
		}

		return {
			...toRefs(state),
			handleZnumIpt,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			inputBlur,
			inputClear,
			popSelect,
			setSelectData,
      valiForm
		}
	},
})
</script>
