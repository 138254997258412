<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="物流公司名称"
        >
          <el-input
            v-model="form.name"
            maxlength="32"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="number"
          label="物流公司编码"
        >
          <el-input
            v-model="form.number"
            maxlength="32"
            clearable
            @blur="handleCodeBlur"
          />
        </el-form-item>
        <el-form-item
          prop="area"
          label="所在地区"
        >
          <el-cascader
            v-model="areaObj"
            clearable
            :options="$API.area"
            separator="-"
            placeholder="选择地区"
            :props="{
              label: 'name',
              value: 'name',
              expandTrigger: 'hover',
            }"
            style="width: 100%"
            @change="(val) => handleArea(val, 'area')"
          />
        </el-form-item>

        <el-form-item
          prop="address"
          label="详细地址"
        >
          <el-input
            v-model="form.address"
            maxlength="255"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="phone"
          label="联系手机"
        >
          <el-input
            v-model="form.phone"
            maxlength="32"
            clearable
            @input="(val) => handleZnumIpt(val, 'phone')"
          />
        </el-form-item>
        <el-form-item
          prop="tel"
          label="联系电话"
        >
          <el-input
            v-model="form.tel"
            maxlength="32"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="fax"
          label="传真"
        >
          <el-input
            v-model="form.fax"
            maxlength="32"
            clearable
            @input="(val) => handleZnumIpt(val, 'fax')"
          />
        </el-form-item>
        <el-form-item
          prop="zipCode"
          label="邮政编码"
        >
          <el-input
            v-model="form.zipCode"
            maxlength="10"
            clearable
            @input="(val) => handleZnumIpt(val, 'zipCode')"
          />
        </el-form-item>
        <el-form-item
          prop="email"
          label="电子邮箱"
        >
          <el-input
            v-model="form.email"
            maxlength="64"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="qq"
          label="QQ"
        >
          <el-input
            v-model="form.qq"
            maxlength="32"
            clearable
            @input="(val) => handleZnumIpt(val, 'qq')"
          />
        </el-form-item>
        <el-form-item
          prop="weChat"
          label="微信"
        >
          <el-input
            v-model="form.weChat"
            maxlength="32"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="corporation"
          label="法定代表人"
        >
          <el-input
            v-model="form.corporation"
            maxlength="255"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="entrust"
          label="委托代理人"
        >
          <el-input
            v-model="form.entrust"
            maxlength="255"
            clearable
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      remote-sort
      :ref-query-form="refFormBar"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetZnum } from "@/utils/validate"; //resetZnum返回正整数的方法
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      refFormBar:null,
      visibles: false,
			areaObj: [],
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增物流公司',
			//表单当前数据
			form: {
				manager: 0,
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				name: [{required: true, message: '必填'}],
				// number: [
				// 	{required: true, message: '必填'},
				// 	{validator: validateCode},
				// ],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.logistics.getLogisticsList,
				//弹窗标题
				title: '复制客户信息',
				//表格显示列
				columns: [
					{
						label: '物流名称',
						prop: 'name',
						width: 150,
						fixed: 'left',
						sortable: 'custom',
					},
					{
						label: '物流编码',
						prop: 'number',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '所在地区',
						prop: 'area',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '详细地址',
						prop: 'address',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '联系手机',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '联系电话',
						prop: 'tel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '传真',
						prop: 'fax',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '邮政编码',
						prop: 'zipCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '电子邮箱',
						prop: 'email',
						width: 120,
						sortable: 'custom',
					},
					{
						label: 'QQ',
						prop: 'qq',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '微信',
						prop: 'weChat',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '法定代表人',
						prop: 'corporation',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '委托代理人',
						prop: 'entrust',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '经营范围',
						prop: 'businessScope',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '企业简介',
						prop: 'profile',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '开户行',
						prop: 'bank',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '账户名称',
						prop: 'accountName',
						width: 130,
					},
					{
						label: '银行账号',
						prop: 'accountNumber',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '联行号',
						prop: 'bankNo',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '许可证号',
						prop: 'licenseKey',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '税号',
						prop: 'taxCode',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '税率(%)',
						prop: 'taxRate',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//查询表单项配置
					itemList: [
						{
							label: '物流公司名称',
							prop: 'name',
							type: 'input',
							max: 100,
							span: 8,
							default: true,
						},
						{
							label: '物流公司编码',
							prop: 'number',
							type: 'input',
							max: 32,
							default: true,
							span: 8,
						},
						{
							label: '所在地区',
							prop: 'area',
							type: 'area',
							default: true,
							span: 8,
						},
						{
							label: '详细地址',
							prop: 'address',
							type: 'input',
							max: 100,
							span: 8,
						},
						{
							label: '联系手机',
							prop: 'phone',
							type: 'number',
							max: 32,
							span: 8,
						},
						{
							label: '联系电话',
							prop: 'tel',
							type: 'number',
							max: 32,
							span: 8,
						},
						{
							label: '传真',
							prop: 'fax',
							type: 'input',
							max: 32,
							span: 8,
						},
						{
							label: '邮政编码',
							prop: 'zipCode',
							type: 'number',
							max: 32,
							span: 8,
						},
						{
							label: '电子邮箱',
							prop: 'email',
							type: 'input',
							max: 32,
							span: 8,
						},
						{
							label: 'QQ',
							prop: 'qq',
							type: 'input',
							max: 32,
							span: 8,
						},
						{
							label: '微信',
							prop: 'weChat',
							type: 'input',
							max: 32,
							span: 8,
						},
						{
							label: '法定代表人',
							prop: 'corporation',
							type: 'input',
							max: 255,
							span: 8,
						},
						{
							label: '委托代理人',
							prop: 'entrust',
							type: 'input',
							max: 255,
							span: 8,
						},
						{
							label: '经营范围',
							prop: 'businessScope',
							type: 'input',
							max: 255,
							span: 8,
						},
						{
							label: '状态',
							prop: 'flag',
							type: 'select',
							span: 8,
							short: 12,
							data: [
								{
									label: '正常',
									value: 1,
								},
								{
									label: '锁定',
									value: 0,
								},
							]
						},
						{
							label: '操作用户',
							prop: 'fz_operateName',
              max:32,
							type: 'popUser',
							span: 8,
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							type: 'dateTimeRange',
							span: 8,
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增物流公司"
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑物流公司[" + row.name + ']'
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.logistics.detailLogistics.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				console.log("OO")
				console.log(state.form)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

    const handleConfirm = () => {
      save()
		state.visibles = false
    }
    const valiForm = () => {
      nextTick(()=>{
        state.refForm.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		//提交方法
		const save = () => {
			state['refForm'].validate(async (valid) => {
				if(valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res = null
						if(state.form.id){ //修改
							res = await proxy.$API.logistics.updateLogistics.put(state.form)
						}else{
							res = await proxy.$API.logistics.insertLogistics.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					// })
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.company.CheckCode.post(state.form)
				if (res.code === 500) {
					proxy.$baseMessage(res.msg, 'error')
					state.form.number = ''
					// state.codeMsg = res.msg
					// await state['myForm'].validateField('number')
				}
			}
		}


		//地区选择变化
		const handleArea = (val, field) => {
			if(val){
				state.form[field] = val.join('-')
			}else{
				state.form[field] = ''
			}
		}

		//只能输入正整数
		const handleZnumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetZnum(val)
			}
		}

		return {
			...toRefs(state),
			handleCodeBlur,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
      valiForm,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			handleArea,
			handleZnumIpt
		}
	},
})
</script>
