import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	getReceiptGoodsList: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceiptGoods/getList`,
		name: "根据采购收获id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getRepairReceiptGoodsById: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceiptGoods/getListByReceiptId`,
		name: "根据采购收获id查询物品明细",
		get: async function (params) {
			return await http.get(this.url + '?receiptId=' + params);
		}
	},
	enterStock: {
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/enterStock`,
		name: "入库",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	exportExcel:{
		url: `${config.API_URL}/mobile/purchase/purchaseRepairReceipt/exportExcel`,
		name: "导出Excel",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	}

}
