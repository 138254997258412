import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/purchase/order/getList`,
		name: "获取所有采购订单",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	goodsGetList: {
		url: `${config.API_URL}/mobile/purchase/orderGoods/getList`,
		name: "获取采购订单物品详情",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/purchase/order/detail`,
		name: "获取采购订单详情",
		get: async function (id) {
			return await http.get(this.url + '?id='+ id);
		}
	},
	delete:{
		url: `${config.API_URL}/mobile/purchase/order/delete`,
		name: "删除采购订单",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	}
}
