import config from "@/config"
import http from "@/utils/request"

export default {
	warehousingDetails: {
		url: `${config.API_URL}/mobile/scan/warehousingDetails`,
		name: "扫码出库",
		get: async function (param) {
			return await http.get(this.url + "?enterNumber=" + encodeURIComponent(param));
		}
	},
	Warehousing: {
		url: `${config.API_URL}/mobile/scan/warehousing`,
		name: "确定出库",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	}
}
