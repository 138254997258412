import config from "@/config"
import http from "@/utils/request"
/*word模板管理*/
export default {
	getList: {
		url: `${config.API_URL}/cfg/system/word/getList`,
		name: "分页查询word模板",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllListByMenuCode: {
		url: `${config.API_URL}/cfg/system/word/wordLayout`,
		name: "根据菜单编码查询所有模板",
		get: async function(params){
			return await http.get(this.url + '?menuCode=' + params);
		}
	},
	updateSilent: {
		url: `${config.API_URL}/cfg/system/word/updateSilent`,
		name: "设置默认",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/system/word/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.get(this.url + '?ids=' + data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/system/word/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/word/insert`,
		name: "批量新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/system/word/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
}
