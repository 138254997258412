import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/getList`,
		name: "查询列表数据(分页)",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},


	getAllList: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/getAllList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	insert: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/finance/voucherSummary/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	importExcel:{
		url: `${config.API_URL}/cfg/finance/voucherSummary/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},

}
