<template>
	<div class="disflex fullWidth">
		<div class="flexgs1">
			<el-select
				v-model="defaultValue"
				clearable
				:filterable="filterable"
				:disabled="disabled"
				@visible-change="visibleChange"
			>
				<el-option
					v-for="(item, index) in options"
					:key="index"
					:label="item.optionValue"
					:value="item.optionCode"
				/>
			</el-select>
		</div>
		<!--    <div class="flexgs0">-->
		<!--      <sy-fast-edit :config="dictConfig">-->
		<!--        <template #formItems="{ formData }">-->
		<!--          <el-form-item-->
		<!--            label="选项编码"-->
		<!--            prop="optionCode"-->
		<!--          >-->
		<!--            <el-input-->
		<!--              v-model="formData.optionCode"-->
		<!--              maxlength="255"-->
		<!--              clearable-->
		<!--            />-->
		<!--          </el-form-item>-->
		<!--          <el-form-item-->
		<!--            label="选项值"-->
		<!--            prop="optionValue"-->
		<!--          >-->
		<!--            <el-input-->
		<!--              v-model="formData.optionValue"-->
		<!--              maxlength="255"-->
		<!--              clearable-->
		<!--            />-->
		<!--          </el-form-item>-->
		<!--        </template>-->
		<!--      </sy-fast-edit>-->
		<!--    </div>-->
	</div>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	getCurrentInstance,
	watch,
} from "vue";

export default defineComponent({
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: null,
		typeCode: {type: String, default: ""},
		typeName: {type: String, default: ""},
		placement: {type: String, default: ""},
		setNull: {type: Boolean, default: false},
		disabled: {type: Boolean, default: false},
		filterable: {type: Boolean, default: false},
	},
	emits: ["update:modelValue"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			defaultValue: props.modelValue,
			options: [],
			dictConfig: {
				isDict: true,
				apiObj: proxy.$API.dict,
				placement: props.placement,
				params: {
					typeCode: props.typeCode,
					typeName: props.typeName,
				},
				formConfig: {
					labelWidth: 80,
					rules: {
						optionCode: [{required: true, trigger: "change", message: "必填"}],
						optionValue: [{required: true, trigger: "change", message: "必填"}],
					}
				},
				columns: [{
					label: "选项编码",
					prop: "optionCode",
					width: 120,
				},
					{
						label: "选项值",
						prop: "optionValue",
					},

					{
						label: "操作",
						prop: "operation",
						width: 100,
					},
				],
			},
		});

		const fetchData = async () => {
			const res = await proxy.$API.dict.getListByTypeCode.get(props.typeCode);
			if (res.code === 200) {
				state.options = res.data;
			}
		};

		const visibleChange = (val) => {
			if (val) {
				fetchData();
			}
		};

		onMounted(() => {
			fetchData();
		});

		watch(
			() => props.modelValue,
			async (val) => {
				if (props.setNull) {
					state.defaultValue = val ? (val + "") : "";
				} else if (val) {
					state.defaultValue = val + "";
				}
			},
			{deep: true}
		);

		watch(
			() => state.defaultValue,
			(val) => {
				emit("update:modelValue", val);
			},
			{deep: true}
		);

		return {
			...toRefs(state),
			visibleChange,
		};
	},
});
</script>
