<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="title"
      :loading="loading"
      :type="type"
      :bef-close="false"
      :width="width"
  >
    <el-card
        header="基本信息"
        shadow="never"
        class="mycard"
    >
      <el-descriptions
          :title="''"
          :column="3"
          :size="$store.state.global.uiSize"
          border
          class="column3"
      >
        <el-descriptions-item
            label="物品名称"
            label-align="right"
        >
          {{ data.name }}
        </el-descriptions-item>

        <el-descriptions-item
            label="物品类别"
            label-align="right"
        >
          {{ data.fz_classifyName }}
        </el-descriptions-item>

        <el-descriptions-item
            label="物品编码"
            label-align="right"
        >
          {{ data.code }}
        </el-descriptions-item>

        <el-descriptions-item
            label="物品类型"
            label-align="right"
        >
          <span>{{ data.stype == 0 ? "原材料" : data.stype == 1 ? "半成品" : "成品" }}</span>
        </el-descriptions-item>

        <el-descriptions-item
            label="所属仓库"
            label-align="right"
        >
          {{ data.fz_depotName }}
        </el-descriptions-item>
				<el-descriptions-item
            label="货位"
            label-align="right"
        >
          {{ data.fz_freightName }}
        </el-descriptions-item>

        <el-descriptions-item
            label="规格型号"
            label-align="right"
        >
          {{ data.spec }}
        </el-descriptions-item>

        <el-descriptions-item
            label="生产方式"
            label-align="right"
        >
          {{
            data.productType == "1" ? "采购" : data.productType == "2" ? "委外" : data.productType == "3" ? "自制" : " "
          }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--            label="自动停用日期"-->
<!--            label-align="right"-->
<!--        >-->
<!--          {{ data.deactivationDate }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
            label="用途"
            label-align="right"
        >
          {{ data.purpose }}
        </el-descriptions-item>

        <el-descriptions-item
            label="库存上限"
            label-align="right"
        >
          {{ data.inventoryCap }}
        </el-descriptions-item>
        <el-descriptions-item
            label="库存下限"
            label-align="right"
        >
          {{ data.inventoryLower }}
        </el-descriptions-item>
        <el-descriptions-item
            label="启用状态"
            label-align="right"
        >
          <el-tag
              v-if="data.flag === 1"
              type="success"
              :size="$store.state.global.uiSize"
          >
            正常
          </el-tag>
          <el-tag
              v-if="data.flag === 0"
              type="danger"
              :size="$store.state.global.uiSize"
          >
            停用
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item
            label="检验方式"
            label-align="right"
        >
					<span v-if="data.checkMethod == 0">未启用</span>
					<span v-if="data.checkMethod == 1">全检</span>
					<span v-if="data.checkMethod == 2">抽检</span>
					<span v-if="data.checkMethod == 3">免检</span>
        </el-descriptions-item>

        <el-descriptions-item
            label="批次管理"
            label-align="right"
        >
          <el-tag
              v-if="data.batchManage === 0"
              type="danger"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            否
          </el-tag>
          <el-tag
              v-if="data.batchManage === 1"
              type="success"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            是
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item
            label="序列号管理"
            label-align="right"
        >
          <el-tag
              v-if="data.serialNumber === 0"
              type="danger"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            否
          </el-tag>
          <el-tag
              v-if="data.serialNumber === 1"
              type="success"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            是
          </el-tag>
        </el-descriptions-item>
		  <el-descriptions-item
			  label="出库顺序"
			  label-align="right"
		  >
        <span v-if="data.stockIntroduction === '0'">先进先出</span>
        <span v-if="data.stockIntroduction === '1'">先到期先出</span>
		  </el-descriptions-item>
        <el-descriptions-item
            label="组合件"
            label-align="right"
        >
          <el-tag
              v-if="data.goodsGroup === 0"
              type="danger"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            否
          </el-tag>
          <el-tag
              v-if="data.goodsGroup === 1"
              type="success"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            是
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item
            label="物料舍入策略"
            label-align="right"
        >
          <span v-if="data.materialRoundingPolicy === 2">整数舍入策略</span>
          <span v-if="data.materialRoundingPolicy === 1">精确舍入策略</span>
        </el-descriptions-item>

        <el-descriptions-item
            label="操作用户"
            label-align="right"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
            label="操作时间"
            label-align="right"
            :span="16"
        >
          {{ data.addTime }}
        </el-descriptions-item>
		  <el-descriptions-item
			  v-for="item in dataList"
			  :key="item.value"
			  :label="item.name"
			  label-align="right">
			  {{Array.isArray(data[item.value])?data[item.value].join(','):data[item.value]}}
		  </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
          :title="''"
          :size="$store.state.global.uiSize"
          border
          :column="1"
          class="column1"
      >
        <el-descriptions-item
            label="备注"
            label-class-name="noTopBorder"
            class-name="noTopBorder"
            :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
        header="计量单位"
        shadow="never"
        class="mycard mt_10"
    >
      <el-descriptions
          :title="''"
          :column="1"
          :size="$store.state.global.uiSize"
          border
          class="column1"
      >
        <el-descriptions-item
            label="主计量单位"
            label-align="right"
        >
          {{ data.mainUnit }}
        </el-descriptions-item>
        <el-descriptions-item
            label="辅计量单位1"
            label-align="right"
        >
          {{ data.assistUnit1 }}
          <span v-if="data.assistUnit1 && data.assistRatio1">
            【1 {{ data.assistUnit1 }} = {{ data.assistRatio1 }} {{ data.mainUnit }}】
          </span>
        </el-descriptions-item>
        <el-descriptions-item
            label="辅计量单位2"
            label-align="right"
        >
          {{ data.assistUnit2 }}
          <span v-if="data.assistUnit2 && data.assistRatio2">
            【1 {{ data.assistUnit2 }} = {{ data.assistRatio2 }} {{ data.mainUnit }}】
          </span>
        </el-descriptions-item>
        <el-descriptions-item
            label="辅计量单位3"
            label-align="right"
        >
          {{ data.assistUnit3 }}
          <span v-if="data.assistUnit3 && data.assistRatio3">
            【1 {{ data.assistUnit3 }} = {{ data.assistRatio3 }} {{ data.mainUnit }}】
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
		<el-card
        header="图片资料"
        shadow="never"
        class="mycard mt_10"
    >
      <el-descriptions
          :title="''"
          :column="1"
          :size="$store.state.global.uiSize"
          border
          class="column1"
      >
				<div class="mtf_4">
					<el-descriptions-item label="封面" label-align="right">
						<el-image
							v-if="data.img"
							fit="cover"
							class="imgViewItem"
							:preview-src-list="[$CONFIG.API_URL + '/'+'api/' +data.img ]"
							hide-on-click-modal
							:z-index="9999"
							:src="$CONFIG.API_URL + '/'+'api/' +data.img"
						/>
					</el-descriptions-item>
				</div>

				<div class="mtf_5">
					<el-descriptions-item label="图片" label-class-name="noTopBorder" class-name="noTopBorder"
																:label-align="labelAlign">
						<el-image
							v-for="(file, index) in imgsList"
							:key="index"
							fit="cover"
							class="imgViewItem"
							:preview-src-list="imgsPreviewList"
							hide-on-click-modal
							:z-index="9999"
							:src="$CONFIG.API_URL + '/'+'api/' +file.url"
						/>
					</el-descriptions-item>
				</div>
      </el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
    </el-card>
    <!--    <el-card-->
    <!--      header="预售价格"-->
    <!--      shadow="never"-->
    <!--      class="mycard mt_10"-->
    <!--    >-->
    <!--      <el-table-->
    <!--        :data="dataGoodsPrice"-->
    <!--        border-->
    <!--        :size="$store.state.global.uiSize"-->
    <!--      >-->
    <!--        <el-table-column-->
    <!--          prop="fz_goodsPriceName"-->
    <!--          width="200"-->
    <!--          label="物品价格名称"-->
    <!--        />-->
    <!--        <el-table-column-->
    <!--          prop="stype"-->
    <!--          label="价格类型"-->
    <!--          width="100"-->
    <!--        >-->
    <!--          <template #default="scope">-->
    <!--            <span>{{ scope.row.stype == 1 ? '销售价' : '成本价' }}</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--          prop="price"-->
    <!--          label="单价"-->
    <!--        />-->
    <!--      </el-table>-->
    <!--    </el-card>-->
    <!--    <el-card-->
    <!--      header="库存限量"-->
    <!--      shadow="never"-->
    <!--      class="mycard mt_10"-->
    <!--    >-->
    <!--      <el-table-->
    <!--        :data="productLimitList"-->
    <!--        :size="$store.state.global.uiSize"-->
    <!--        border-->
    <!--      >-->
    <!--        <el-table-column-->
    <!--          prop="fz_depotName"-->
    <!--          label="仓库名称"-->
    <!--          width="180"-->
    <!--        />-->
    <!--        <el-table-column-->
    <!--          prop="upper"-->
    <!--          label="数量上限"-->
    <!--          width="120"-->
    <!--        />-->
    <!--        <el-table-column-->
    <!--          prop="lower"-->
    <!--          label="数量下限"-->
    <!--          width="120"-->
    <!--        />-->
    <!--        <el-table-column-->
    <!--          prop="fz_upperUserName"-->
    <!--          label="上限提醒人"-->
    <!--          width="200"-->
    <!--        />-->
    <!--        <el-table-column-->
    <!--          prop="fz_lowerUserName"-->
    <!--          label="下限提醒人"-->
    <!--          width="200"-->
    <!--        />-->
    <!--      </el-table>-->
    <!--    </el-card>-->

    <el-card
        header="组合件"
        shadow="never"
        class="mycard mt_10"
        v-if="data.goodsGroup"
    >
      <el-table
          :data="goodsLinks"
          :size="$store.state.global.uiSize"
          border
      >
		  <el-table-column
			  align="center"
			  label="序号"
			  width="55"
			  fixed="left"
			  prop="sort"
		  >
			  <template #default="{ $index }">
				  {{ $index + 1 }}
			  </template>
		  </el-table-column>

        <el-table-column
            prop="fz_goodsName"
            label="物品名称"
            width="180"

        >
          <template #default="{ row }">
            <el-link
              type="primary"
              @click="handleDetail(row, 'refGoodsDetail', 'goodsId')"
            >
              {{row.fz_goodsName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            prop="fz_goodsCode"
            label="物品编码"
            width="120"
        />

        <el-table-column
            prop="fz_goodsSpec"
            label="规格型号"
            width="120"
        />

        <el-table-column
            prop="mainUnit"
            label="主单位"
            width="110"
        />
        <el-table-column
            prop="quantity"
            label="数量"
            width="110"
        />
        <el-table-column
            prop="mainUnit"
            label="辅助单位"
            width="110"
        />
        <el-table-column
            prop="assistQuantity"
            label="换算数量"
            width="110"
        />
        <el-table-column
            prop="remark"
            label="备注"
            width="180"
        />
      </el-table>
    </el-card>
    <!--详情-->
    <GoodsDetail ref="refGoodsDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from "vue";
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";

export default defineComponent({
  name: "GoodsDetail",
  components: {GoodsDetail},
  setup() {
    //获取当前实例对象
    const {proxy} = getCurrentInstance();
    const state = reactive({
		dataList:[],
      //弹窗宽度
      width: "60%",
      type: "detail",
      //高度
      height: "500px",
      //加载动画
      loading: true,
      //标题对齐方式 'left' 'right' 'center'
      labelAlign: "right",
      //尺寸大小
      size: "small",
      //控制弹窗显示隐藏
      visible: false,
      //弹窗标题
      title: "物品详情",
      //详情数据对象
      data: {},
      dataGoodsPrice: [],
      goodsLinks: [],
      productLimitList: [],
      fileList: [],
      imgsList: [],
      imgsPreviewList: [],
    });

	  onMounted(() => {
		  dataListMap()
	  })
	  // 获取自定义字段
	  const dataListMap =async () =>{
		  const params = {
			  pageNum: 1, pageSize: 1000, tableHeaderCode:1,isEnabled:1,menuCode:"cfg_data_goods",
		  }
		  let res = await proxy.$API.custom.getList.get(params)
		  if (res.code === 200) {
			  res.data.list.forEach(item => {
				  if(item.stype == 4){
					  item.value = 'customField' + item.fieldLength + 'List'
				  }else {
					  item.value = 'customField' + item.fieldLength
				  }
				  // 是否必填
				  if(item.isRequired === 1){
					  item.rules = {required: true, message: '必填'}
				  }
			  })
			  state.dataList = res.data.list
		  }
	  }
    //显示弹窗
    const showDialog = async (data) => {
      state.fileList = [];
      state.imgsList = [];
      state.imgsPreviewList = [];
      state.dataGoodsPrice = [];
      state.productLimitList = [];
      state.visible = true;
      if (data.id) {
        //根据id查询
        const res = await proxy.$API.goods.detail.get(data.id);
        if (res.code === 200) {
          state.dataGoodsPrice = res.data.goodsPriceList;
          state.productLimitList = res.data.productLimitList;
          state.goodsLinks = res.data.goodsLinks;
          state.data = res.data;
			if(state.data.classifyId === -1){
				state.data.fz_classifyName = '全部类别'
			}
          getFileList(res.data.imgs, "imgsList");
          getFileList(res.data.files, "fileList");
        }
      }
      state.loading = false;
    };

    //组装文件列表
    const getFileList = (data, list) => {
      let arr = [];
      if (data) {
        arr = data.split(",");
        if (arr.length > 0) {
          arr.forEach((item) => {
            let obj = {};
            const iarr = item.split("/");
            obj["url"] = item;
            obj["name"] = iarr[iarr.length - 1];
            state[list].push(obj);
            if (list === "imgsList") {
              state.imgsPreviewList.push(proxy.$CONFIG.API_URL + "/" + "api/" + item);
            }
          });
        }
      }
    };

    const previewFile = async (file) => {
      window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
      await proxy.$API.files.insertFilesDownload.post(file);
    };

    //隐藏弹窗
    const hideDialog = () => {
      state.visible = false;
    };

    const handleDetail = (row, ref, field) => {
      const params = Object.assign({}, row);
      if (field) {
        params.id = row[field];
      }
      proxy.$refs[ref].showDialog(params);
    };

    return {
      ...toRefs(state),
      showDialog,
      previewFile,
      hideDialog,
      handleDetail
    };
  },
});
</script>

<style scoped>
.my-label {
  background: var(--el-color-success-light-9);
}

.my-content {
  background: var(--el-color-danger-light-9);
}

</style>

