<!--选择商品弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :bef-close="false"
    custom-class="wapDialog"
    fullscreen
    :show-fullscreen="false"
    @closed="handleDialogClosed"
  >
    <el-container class="wap-container">
      <el-main class="nopadding">
        <el-container
          class="mainPanel"
        >
          <el-header class="sel-search">
            <el-input
              v-model="queryForm.keyWords"
              placeholder="搜索"
              style="width: 100%"
              clearable
              size="small"
              @input="handleSearch"
            />
          </el-header>
          <el-header class="sel-filter">
            <div
              class="filter-item"
              :class="queryForm.classifyId ? 'active' : ''"
              @click="showDropBox('refClassify')"
            >
              {{ curClassify }}
              <i
                class="iconfont icon-xiala"
                :class="activeDrop == 'refClassify' ? 'active' : ''"
              />
            </div>
            <div
              class="filter-item"
              :class="queryForm.depotId ? 'active' : ''"
              @click="showDropBox('refDropDepot')"
            >
              {{ curDepot }}
              <i
                class="iconfont icon-xiala"
                :class="activeDrop == 'refDropDepot' ? 'active' : ''"
              />
            </div>
            <!--<div class="filter-item">
              排序方式<i class="iconfont icon-xiala" />
            </div>-->
            <!--<div class="filter-item">
              筛选<i class="iconfont icon-shaixuan" />
            </div>-->
          </el-header>
          <el-main class="nopadding bgwhite">
            <el-container
              class="nopadding"
              direction="column"
            >
              <!--分类栏-->
              <el-aside
                v-loading="classLoading"
                class="class"
              >
                <el-scrollbar>
                  <ul class="ul-class">
                    <li
                      v-for="item in classifyData"
                      :key="item.id"
                      :class="item.name === activeClass ? 'active' : ''"
                      @click="handleNodeClick(item)"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </el-scrollbar>
              </el-aside>
              <el-main style="padding: 0 10px">
                <el-container
                  class="flexgs1"
                  direction="vertical"
                  style="overflow: hidden"
                >
                  <BetterScroll
                    ref="refBetterScroll"
                    class="scrollWrapper"
                    pull-up
                    pull-down
                    @pull-down="pullDown"
                    @pull-up="pullUp"
                  >
                    <ul class="list-ul">
                      <li
                        v-for="item in goodsData"
                        :key="item.id"
                      >
                        <el-card
                          class="list-item cs-goods npd"
                          shadow="never"
                        >
                          <div class="disflex">
                            <div class="item-img pdl0 flexgs0">
                              <el-image
                                :src="item.img || Logo"
                                fit="contain"
                                class="list-img"
                              />
                            </div>
                            <div class="item-box flexgs1">
                              <div class="dis-flex mt-f5">
                                <div class="item-prop full">
                                  <h4>{{ item.name }}</h4>
                                </div>
                                <div class="item-prop full">
                                  <span class="label">编码：</span>{{ item.code }}
                                </div>
                                <div class="item-prop full">
                                  <span class="label">库存：</span>{{ item.stock }}
                                </div>
                                <div class="item-prop">
                                  <span class="label">单价：</span>{{ item.referencePrice }}
                                </div>
                              </div>
                            </div>
                            <div class="item-arr">
								<template v-if="multiple">
									<el-icon
										v-if="multiple && item.quantity"
										color="#409eff"
										:size="24"
										@click="subFromCar(item)"
									>
										<el-icon-removeFilled/>
									</el-icon>
									<span style="width: 20px">{{item.quantity?item.quantity:0}}</span>
								<i
									class="iconfont icon-tianjia1 add"
									@click="addToCar($event,item)"
								/>
								</template>
								<template v-else>
									<i
										class="iconfont icon-xuanzekuangmoren radio"
										:class="activeItem === item.id ? 'checked' : ''"
										@click="radioChoose(item)"
									>
										<i class="iconfont icon-31xuanze checked"/>
									</i>
								</template>
							</div>
						  </div>
						</el-card>
					  </li>
					</ul>
					  <div
						  v-if="goodsData.length === 0"
						  class="no-data"
					  >
						  <i class="iconfont icon-xiaolian"/>暂无数据
					  </div>
				  </BetterScroll>
				</el-container>
			  </el-main>
			</el-container>
		  </el-main>
		</el-container>
	  </el-main>
	</el-container>
	  <template #footer>
		  <div
			  v-if="multiple"
			  class="shop-car"
			  @click="showShopCar"
		  >
			  <i class="iconfont icon-caigoujian"/>
			  <div class="num">
				  {{ selTotal > 99 ? '99+' : selTotal }}
			  </div>
		  </div>
		  <div
			  class="disflex fullWidth"
			  :class="multiple && 'cs-foot'"
		  >
			  <div class="flexgs1 disflex text-left it-align-center">
				  <div class="autoHeight">
					  已选:<b class="blue">{{ selTotal }}</b>
					  <!--总价:<b class="blue">{{ selTotalPrice }}</b>-->
				  </div>
			  </div>
			  <div class="flexgs0 text-right">
				  <el-button
					  :size="$store.state.global.uiSize"
					  type="primary"
					  :disabled="isConfirm"
					  @click="handleConfirm"
				  >
					  确定
				  </el-button>
			  </div>
		  </div>
	  </template>
	  <ShopCar
		  ref="refShopCar"
		  @set-sel-data="setSelData"
	  />
	  <!--下拉条件-->
	  <DropSel
		  ref="refDropSel"
		  @node-click="nodeClick"
	  />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	computed, onMounted,
} from 'vue'
import Logo from '@/assets/img/hbxt.png'
import ShopCar from './shopCar'
import DropSel from './dropSel'
import multiple from "@/components/scUpload/multiple";

export default defineComponent({
	name: "WapSelectGoods",
	components: {
		ShopCar,
		DropSel
	},
	props: {
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: '物品'},
	},
	emits: ['set-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
			activeClass: '物品类别',
			//是否初始化完成
			isMounted: false,
			//动画起点
			startPoint: {
				x: 0,
				y: 0
			},
			endPoint: {
				x: 0,
				y: 0,
			},
			queryForm: {
				pageNum: 1,
				pageSize: 10,
			},
			//弹窗标题
			dftitle: props.title,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//已选数量
			selTotal: 0,
			//已选总价
			selTotalPrice: 0,
			//已选数据记录
			selData: [],
			//列表数据
			goodsData: [],
			//分类数据
			classifyData: [],
			classLoading: true,
			showDrop: false,
			activeDrop: '',
			curClassify: '物品分类',
			curDepot: '仓库',
			activeItem: '',
		})

		//显示弹窗
		const showDialog = () => {
			state.visible = true
			state.loading = false
			fetchData('refresh')
			fetchClassifyData()
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//加入购物车动画
		const animation = (e) => {
			//滚动高度
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
			//起点
			state.startPoint.x = e.pageX
			state.startPoint.y = e.pageY
			//重点
			let target = document.querySelector('.shop-car')
			let targetParent = target.offsetParent
			state.endPoint.y = targetParent.offsetTop + scrollTop
			state.endPoint.x = target.offsetWidth / 2
			let ball = document.createElement('div')
			ball.className = 'ball'
			ball.style.top = state.startPoint.y + 'px'
			ball.style.left = state.startPoint.x + 'px'
			document.body.appendChild(ball)
			// 使用requestAnimationFrame回调函数确保动画在下一帧渲染之前执行
			requestAnimationFrame(() => {
				ball.style.top = state.startPoint.y + 'px';
				ball.style.left = state.startPoint.x + 'px';

				requestAnimationFrame(() => {
					ball.style.top = state.endPoint.y + 'px';
					ball.style.left = state.endPoint.x + 'px';
				});
			});

			// 绑定过渡结束事件，确保ball元素被删除
			ball.addEventListener('transitionend', () => {
				ball.remove();
			});
		}

		//按钮减少购物车
		const subFromCar = (item) => {
			//判断已选中是否存在当前减少的数据
			const hasItem = state.selData.find((d) => {
				return d.id === item.id
			})
			//有重复项 减少数量
			if (hasItem && item.quantity !== 1) {
				let quantity = hasItem.quantity - 0
				quantity--
				item.quantity = quantity
				hasItem.quantity = quantity
			} else if (hasItem && item.quantity == 1) {
				let quantity = hasItem.quantity - 0
				quantity--
				item.quantity = quantity
				hasItem.quantity = quantity
				let index = state.selData.indexOf(hasItem)
				if (index > -1) {
					state.selData.splice(index, 1)
				}
			}
			countTotal()
		}

		//按钮添加购物车
		const addToCar = (e, item) => {
			animation(e);
			setTimeout(() => {
				//判断已选中是否存在当前添加的数据
				const hasItem = state.selData.find((d) => {
					return d.id === item.id
				})
				//有重复项 增加数量
				if (hasItem) {
					let quantity = hasItem.quantity - 0
					quantity++
					item.quantity = quantity
					hasItem.quantity = quantity
				} else {
					const obj = Object.assign({}, item)
					item.quantity = 1
					obj['quantity'] = 1
					obj['unit'] = item.mainUnit
					obj['price'] = item.referencePrice
					state.selData.push(obj)
				}
				countTotal()
			}, 300)
		}

		//购物车数据回调
		const setSelData = (data) => {
			state.goodsData.forEach((item) => {
				let foundMatch = false;
				data.forEach((item1) => {
					if (item.id === item1.id) {
						item.quantity = item1.quantity
						foundMatch = true
					}
				})
				if (!foundMatch) {
					item.quantity = 0
				}
			})

			if (data && data.length > 0) {
				state.selData = JSON.parse(JSON.stringify(data))
			} else {
				state.selData = []
			}
			countTotal()
		}

		//合计数据计算
		const countTotal = () => {
			state.selTotal = state.selData.length;
		}

		//显示购物车
		const showShopCar = () => {
			proxy.$refs.refShopCar.showDrawer(state.selData)
		}

		//加载分类数据
		const fetchClassifyData = async () => {
			const res = await proxy.$API.classify.getList.get()
			if (res.code == 200) {
				tileClass(res.data.list)
			}
			state.classLoading = false
		}

		//平铺分类
		const tileClass = (data) => {
			if (data.length > 0) {
				data.forEach((item, index) => {
					state.classifyData.push(item)
					if (item.children != null) {
						if (item.children.length && item.children.length > 0) {
							tileClass(item.children)
						}
					}
				})
			}
		}

		//分类点击
		const handleNodeClick = (item) => {
			//当前分类
			state.activeClass = item.name
			state.queryForm.classifyId = item.id
			if (item.name === '物品类别') {
				delete state.queryForm.classifyId
			}
			initData()
			fetchData('refresh')
		}

		//加载商品数据
		const fetchData = async (type) => {
			const res = await proxy.$API.goods.getList.get(state.queryForm)
			if (res.code === 200) {
				const reqData = res.data.list
				if (reqData && reqData.length > 0) {
					//拼接数据
					state.goodsData = JSON.parse(JSON.stringify(state.goodsData)).concat(reqData)
					state.queryForm.pageNum++
				} else {
					//没有更多数据 设置无法加载
					proxy.$refs.refBetterScroll.noMore = true
				}
				if (type === 'refresh') {
					//数据获取成功后 设置加载动画结束
					proxy.$refs.refBetterScroll.isLoading = true
				}
				if (type === 'load') {
					//数据获取成功后 设置加载动画结束
					proxy.$refs.refBetterScroll.isPullUpLoad = true
				}
				if(state.selData.length > 0){
					setSelData(state.selData)
				}
			}
		}

		//初始化数据及查询条件
		const initData = () => {
			state.queryForm.pageNum = 1
			state.goodsData = []
		}

		//搜索
		const handleSearch = () => {
			initData()
			fetchData('refresh')
		}

		//确定
		const handleConfirm = () => {
			emit('set-data', state.selData)
			state.visible = false
		}

		//弹窗关闭时调用
		const handleDialogClosed = () => {
			initData()
			delete state.queryForm.classifyId
			delete state.queryForm.depotId
		}

		//下拉条件选择 ref选择类型
		const showDropBox = (ref) => {
			if (state.activeDrop === ref) {
				proxy.$refs.refDropSel.hideDialog()
				state.activeDrop = ''
			} else {
				state.activeDrop = ref
				proxy.$refs.refDropSel.showDialog(ref)
				setStyle()
			}
		}

		const setStyle = () => {
			const dhead = document.querySelector('.el-dialog__header')
			const sbar = document.querySelector('.sel-search')
			const ftbar = document.querySelector('.sel-filter')
			proxy.$refs.refDropSel.setStyle(dhead.offsetHeight + sbar.offsetHeight + ftbar.offsetHeight)
		}

		//分类节点点击
		const nodeClick = (node, type) => {
			//隐藏下拉框
			proxy.$refs.refDropSel.hideDialog()
			state.activeDrop = ''
			if (type === 'refClassify') {
				state.queryForm.classifyId = node.id
				if (node.name === '物品类别') {
					delete state.queryForm.classifyId
				}
				state.activeClass = node.name
				state.curClassify = node.name
			}
			if (type === 'refDropDepot') {
				state.queryForm.depotId = node.id
				state.curDepot = node.name
			}
			initData()
			fetchData('refresh')
		}

		//单选
		const radioChoose = (item) => {
			state.activeItem = item.id
			state.selData = [Object.assign({}, item)]
			countTotal()
		}

		//计算确定按钮状态
		const isConfirm = computed(() => {
			if (props.multiple) {
				return state.selData.length == 0
			} else {
				return state.activeItem === ''
			}
		})


		//下拉刷新
		const pullDown = () => {
			state.queryForm.pageNum = 1
			state.data = []
			fetchData('refresh')
		}

		//上拉加载
		const pullUp = () => {
			fetchData('load')
		}

		return {
			...toRefs(state),
			pullDown,
			pullUp,
			isConfirm,
			radioChoose,
			nodeClick,
			handleDialogClosed,
			showDropBox,
			showShopCar,
			subFromCar,
			addToCar,
			handleConfirm,
			handleSearch,
			showDialog,
			hideDialog,
			Logo,
			handleNodeClick,
			setSelData
		}
	},
})
</script>
