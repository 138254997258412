<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
    :width="width"
    :bef-close="false"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        :column="3"
        border
        class="column3"
      >
        <el-descriptions-item
          label="配送单号"
          :label-align="labelAlign"
        >
          {{ data.orderNo }}
        </el-descriptions-item>
        <el-descriptions-item
          label="物流公司"
          :label-align="labelAlign"
        >
          <el-link
            type="primary"
            @click="handleDetail(data, 'refLogisticsDetail' ,'logisticsId')"
          >
            {{ data.fz_logisticsName }}
          </el-link>
        </el-descriptions-item>
        <el-descriptions-item
          label="配送类型"
          :label-align="labelAlign"
        >
          <span v-if="data.stype === 1">一般</span>
          <span v-if="data.stype === 2">紧急</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="业务类型"
          :label-align="labelAlign"
        >
          <span v-if="data.businessType === 1">出库</span>
          <span v-if="data.businessType === 2">调拨</span>
          <span v-if="data.businessType === 3">移库</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="要求送达时间"
          :label-align="labelAlign"
        >
          {{ data.deliveryTime }}
        </el-descriptions-item>
        <el-descriptions-item
          label="是否需要吊装"
          :label-align="labelAlign"
        >
          <span v-if="data.needLifting === 1">是</span>
          <span v-if="data.needLifting === 0">否</span>
        </el-descriptions-item>

        <el-descriptions-item
          label="发货仓库"
          :label-align="labelAlign"
        >
          {{ data.fz_fromDepotName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货地址"
          :label-align="labelAlign"
        >
          {{ data.fromAddress }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货联系人"
          :label-align="labelAlign"
        >
          {{ data.fromContacts }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货联系电话"
          :label-align="labelAlign"
        >
          {{ data.fromPhone }}
        </el-descriptions-item>
        <el-descriptions-item
          label="收货仓库"
          :label-align="labelAlign"
        >
          {{ data.fz_receiverDepotName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="收货地址"
          :label-align="labelAlign"
        >
          {{ data.receiverAddress }}
        </el-descriptions-item>
        <el-descriptions-item
          label="收货联系人"
          :label-align="labelAlign"
        >
          {{ data.receiverContacts }}
        </el-descriptions-item>
        <el-descriptions-item
          label="收货联系电话"
          :label-align="labelAlign"
        >
          {{ data.receiverPhone }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="配送明细"
      shadow="never"
      class="mycard mt_10"
    >
      <scTable
        ref="tableSon"
        :api-obj="tableSonConfig.apiObj"
        :column="tableSonConfig.columns"
        :params="tableSonConfig.params"
        :height="'auto'"
        row-key="id"
        border
        remote-sort
      >
      <template #fz_goodsName="{ scope }">
        <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
        >
          {{ scope.row.fz_goodsName }}
        </el-link>
      </template>
      </scTable>
    </el-card>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
    <LogisticsDetail ref="refLogisticsDetail" />
    <GoodsDetail ref="refGoodsDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'
import LogisticsDetail from "@/views/cfg/data/logistics/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
export default defineComponent({
	components: { LogisticsDetail,GoodsDetail },
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '70%',
      type:"detail",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '配送任务详情',
			data: {},
			//详情数据对象
			deswidth: '25%',
			fileList: [],
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '物品型号',
					// 	prop: 'fz_goodsModel',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '长【米】',
						prop: 'length',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '宽【米】',
						prop: 'width',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '高【米】',
						prop: 'high',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '重量【KG】',
						prop: 'weight',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],
			},
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = []
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.logisticsDelivery.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					getGoodsList(res.data.id)
				}
			}
			state.loading = false
		}

		const getGoodsList = (id) => {
			if(id){
				//将接口对象赋给表格
				state.tableSonConfig.apiObj = proxy.$API.logisticsDelivery.getDeliveryDetailList
				state.tableSonConfig.params = { orderId: id }
				//刷新表格
				proxy.$refs.tableSon.reload({ orderId: id })
			}
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
		}
	},
})
</script>
