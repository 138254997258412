import config from "@/config"
import http from "@/utils/request"
/*角色管理*/
export default {
	getList: {
		url: `${config.API_URL}/cfg/system/finance/getList`,
		name: "财务设置",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/finance/insert`,
		name: "财务设置更新",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
