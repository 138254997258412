import config from "@/config"
import http from "@/utils/request"

export default {
	stockAlert: {
		url: `${config.API_URL}/erp/stock/report/stockAlert`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	exportExcel: {
		url: `${config.API_URL}/erp/stock/report/exportExcel`,
		name: "导出Excel",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
