import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/stock/warehousing/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/stock/warehousing/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/stock/warehousing/insert`,
		name: "新增销售订单",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	// update: {
	// 	url: `${config.API_URL}/erp/stock/warehousing/update`,
	// 	name: "修改销售订单",
	// 	put: async function(data={}){
	// 		return await http.put(this.url,data);
	// 	}
	// },
	detail: {
		url: `${config.API_URL}/erp/stock/warehousing/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	// delete: {
	// 	url: `${config.API_URL}/erp/stock/warehousing/delete`,
	// 	name: "删除",
	// 	get: async function(params){
	// 		return await http.get(this.url + '?ids=' + params);
	// 	}
	// },
	transferEnter: {
		url: `${config.API_URL}/erp/stock/transfer/transferEnter  `,
		name: "入库单新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
