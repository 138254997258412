<template>
  <sc-dialog
    ref="dialogRef"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm formset">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="工序名称"
        >
          <el-input
            v-model="form.name"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="工序编号"
        >
          <el-input
            v-model="form.code"
            clearable
            placeholder="请输入"
            maxlength="32"
            @blur="handleCodeBlur"
          />
        </el-form-item>

        <el-form-item
          prop="fz_workshopName"
          label="生产车间"
        >
          <el-input
            ref="refWorkshopIput"
            v-model="form.fz_workshopName"
            clearable
            placeholder="请选择"
            @blur="inputBlur('workshop')"
            @clear="inputClear('workshop')"
            maxlength="32"
          >
            <template #append>
              <el-icon @click="popSelect('refWorkshopIput',['workshopId','fz_workshopName'], 1, 'refSelectWorkShop')">
                <el-icon-search />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item
          prop="fz_deviceName"
          label="设备"
        >
          <el-input
            ref="refDeviceIput"
            v-model="form.fz_deviceName"
            clearable
            placeholder="请选择"
            @blur="inputBlur('device')"
            @clear="inputClear('device')"
            maxlength="32"
          >
            <template #append>
              <el-icon @click="popSelect('refDeviceIput',['deviceId','fz_deviceName'], 1, 'refSelectDevice')">
                <el-icon-search />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <div class="fullWidth">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                prop="workTime"
                label="工作时长"
              >
                <el-input
                  v-model="form.workTime"
                  clearable
                  placeholder="请输入"
                  maxlength="20"
                  @input="(val) => handleNumIpt(val, 'workTime')"
                >
                  <template #suffix>
                    分钟
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="fz_managerName"
                label="负责人"
              >
                <el-input
                  ref="refManagerIput"
                  v-model="form.fz_managerName"
                  clearable
                  placeholder="请选择"
                  @blur="inputBlur('manager')"
                  @clear="inputClear('manager')"
                  maxlength="32"
                >
                  <template #append>
                    <el-icon @click="popSelect('refManagerIput',['managerId','fz_managerName'], 1, 'refSelectUser')">
                      <el-icon-search />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="price"
                label="参考单价"
              >
                <el-input
                  v-model="form.price"
                  clearable
                  placeholder="请输入"
                  maxlength="20"
                  @input="(val) => handleNumIpt(val, 'price')"
                >
                  <template #suffix>
                    ￥
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-form-item
          prop="requirement"
          label="工序要求"
        >
          <el-input
            v-model="form.requirement"
            type="textarea"
            rows="2"
            show-word-limit
            placeholder="请输入"
            maxlength="250"
          />
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
            rows="2"
            clearable
            show-word-limit
            placeholder="请输入"
            maxlength="255"
          />
        </el-form-item>
        <el-form-item
          prop="files"
          label="附件"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'附件'"
            :accept="'*'"
            :is-file="true"
            :list-type="'text'"
            :limit="10"
          >
            <el-button :size="$store.state.global.uiSize" >
              选择文件
            </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        @click="save"
      >
        提交
      </el-button>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_CLOSE"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            关闭
          </el-button>
        </template>
      </el-popconfirm>
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #name="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row)"
        >
          {{ scope.row.name }}
        </el-link>
      </template>
    </SyCopyDialog>
    <Detail ref="refDetail" />
    <!--选择用户-->
    <SelectUser
      ref="refSelectUser"
      @set-data="setSelectData"
    />
    <!--选择车间-->
    <SelectWorkShop
      ref="refSelectWorkShop"
      @set-data="setSelectData"
    />
    <!--选择设备-->
    <SelectDevice
      ref="refSelectDevice"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs,} from 'vue'
import {resetNum, resetZnum} from "@/utils/validate";
import Detail from '@/views/erp/produce/craft/procedure/detail'
export default defineComponent({
	name: "SelectProduceOutprocessEdit",
	components: { Detail },
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const state = reactive({
      refFormBar:null,
			triggerDom: {},
			//加载动画
			loading: true,
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			cacheFields: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				name: [
					{required: true, trigger: 'blur', message: '必填'},
				],
				code: [
					{required: true, message: '必填'},
					{validator: validateCode},
				],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.produceCraftProcedure.getList,
				//弹窗标题
				title:'复制工序步骤信息',
				//表格显示列
				columns: [
					{
						label: '工序名称',
						prop: 'name',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '工序编号',
						prop: 'code',
						width: 120,
						sortable: 'custom',
						showOverflowTooltip: true,
					},
					{
						label: '工作时长[分钟]',
						prop: 'workTime',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '生产车间',
						prop: 'fz_workshopName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '负责人',
						prop: 'fz_managerName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '设备名称',
						prop: 'fz_deviceName',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '参考单价[元]',
						prop: 'price',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '工序要求',
						prop: 'requirement',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						showOverflowTooltip: true,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						showOverflowTooltip: true,
					},
				],
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//表单项配置
					itemList: [
						//文本框
						{
							label: '工序名称',
							prop: 'name',
							type: 'input',
							max: 20,
							default: true,
							span: 8,
						},
						{
							label: '工序编号',
							prop: 'code',
							type: 'input',
							max: 20,
							default: true,
							span: 8,
						},
						{
							label: '工作时长',
							type: 'rangeNum',
							default: true,
							span: 8,
							fields: [
								{
									prop: 'fz_workTime0'
								},
								{
									prop: 'fz_workTime1'
								},
							]
						},
						{
							label: '生产车间',
							prop: 'fz_workshopName',
							type: 'popWorkShop',
							span: 8,
							fields: ['workshopId','fz_workshopName']
						},
						{
							label: '负责人',
							prop: 'fz_managerName',
							type: 'popUser',
							span: 8,
							fields: ['managerId','fz_managerName']
						},
						{
							label: '设备',
							prop: 'fz_deviceName',
							type: 'popDevice',
							span: 8,
							fields: ['deviceId','fz_deviceName']
						},
						{
							label: '参考单价',
							type: 'rangeNum',
							span: 8,
							fields: [
								{
									prop: 'fz_price0'
								},
								{
									prop: 'fz_price1'
								},
							]
						},
						{
							label: '工序要求',
							prop: 'requirement',
							type: 'input',
							max: 50,
							span: 8,
						},
						{
							label: '备注',
							prop: 'remark',
							type: 'input',
							max: 50,
							span: 8,
						},
						{
							label: '操作用户',
							prop: 'fz_operateName',
							type: 'popUser',
							short: 12,
							span: 8,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '日期时间范围',
							type: 'dateTimeRange',
							span: 8,
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			}
		})
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) === '{}') {
				state.title = '新增'
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title = '编辑'
				//根据id查询
				const res = await proxy.$API.produceCraftProcedure.detail.get(row.id)
				if(res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
				}
			}
			state.loading = false
		}
		//只能输入正整数
		const handleNumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetZnum(val)
			}
		}
		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.produceCraftProcedure.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['formRef'].validateField('number')
				}
			}
		}
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}
		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id
			delete state.form.id
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate((valid) => {
				if (valid) {
					proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, async () => {
						state.subLoading = true
						let res
						if (state.form.id) { //修改
							res = await proxy.$API.produceCraftProcedure.update.put(state.form)
						} else { //新增
							res = await proxy.$API.produceCraftProcedure.insert.post(state.form)
						}
						if (res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					})
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if (!isClose) {
				state.form = Object.assign({}, orgForm)
			}
		}
		const handleNumInput = (val, prop) => {
			if (val) {
				state.form[prop] = resetNum(val)
			}
		}

		//详情
		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}

		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			if(field != 'shareUser'){
				state.form[field + 'Id'] = ''
			}else{
				state.form[field] = ''
			}
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}

		return {
			...toRefs(state),
			inputClear,
			inputBlur,
			setSelectData,
			popSelect,
			handleDetail,
			handleNumInput,
			copyData,
			setFormData,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			handleNumIpt,
			handleCodeBlur,
		};
	},
})
</script>

<style scoped>

</style>
