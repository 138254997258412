import config from "@/config"
import http from "@/utils/request"

export default {
	insertUnit: {
		url: `${config.API_URL}/cfg/business/unit/insert`,
		name: "添加单位",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getUnitList: {
		url: `${config.API_URL}/cfg/business/unit/getList`,
		name: "获取单位",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/business/unit/getListByKeyword`,
		name: "获取单位",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	updateUnit: {
		url: `${config.API_URL}/cfg/business/unit/update`,
		name: "修改单位",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	deleteUnit: {
		url: `${config.API_URL}/cfg/business/unit/delete`,
		name: "删除单位",
		get: async function(params){
			return await http.get(this.url+ '?ids=' + params);
		}
	},
	checkName: {
		url: `${config.API_URL}/cfg/business/unit/checkName`,
		name: "单位校验",
		get: async function(params){
			return await http.post(this.url, params);
		}
	},
}
