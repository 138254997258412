export default {
	state: {
		iframeList: [],
		curFrame: null,
	},
	mutations: {
		setCurFrame(state, route){
			state.curFrame = Object.assign({}, route)// 点击切换标签刷新表格数据
			if(document.getElementById(route.name)){
				document.getElementById(route.name).contentWindow.postMessage({
					cmd: "refresh"
				}, "*");
			}
		},
		setIframeList(state, route){
			state.iframeList = []
			state.iframeList.push(route)
		},
		pushIframeList(state, route){
			let target = state.iframeList.find((item) => item.path === route.path)
			if(!target){
				state.iframeList.push(route)
			}
		},
		removeIframeList(state, route){
			state.iframeList.forEach((item, index) => {
				if (item.path === route.path){
					state.iframeList.splice(index, 1)
				}
			})
		},
		refreshIframe(state, route){
			state.iframeList.forEach((item) => {
				if (item.path === route.path){
					const url = route.path;
					item.path = '';
					setTimeout(function() {
						item.path = url
					}, 0);
				}
			})
		},
		clearIframeList(state){
			state.iframeList = []
		}
	}
}
