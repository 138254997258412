import config from "@/config"
import http from "@/utils/request"

export default {
	token: {
		url: `${config.API_URL}/mobile/token/login`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	user: {
		url: `${config.API_URL}/demo/sa`,
		name: "获取用户",
		post: async function(data={}){
			return await http.get(this.url, data);
		}
	},
	loginOut: {
		url: `${config.API_URL}/token/logout`,
		name: "退出登录",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},
	tokenByPhone: {
		url: `${config.API_URL}/token/login/phone`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getWapMenuList: {
		url: `${config.API_URL}/mobile/menu/wap/getWapMenuPower`,
		name: "查询菜单",
		get: async function(data){
			return await http.get(this.url + '?menuCode=' + data);
		}
	},
	getMenuPowerButton: {
		url: `${config.API_URL}/mobile/menu/wap/getMenuPowerButton`,
		name: "查询按钮",
		get: async function(data){
			return await http.get(this.url + '?menuCode=' + data);
		}
	},
	getWapSearch: {
		url: `${config.API_URL}/mobile/menu/wap/querySearchButton`,
		name: "搜索权限",
		get: async function(menuCode, code){
			return await http.get(this.url + '?menuCode=' + menuCode + '&buttonCode=' + code);
		}
	},
}
