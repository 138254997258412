const pdaRouter = [
	{
		path: "/pda_login",
		component: () => import('@/wap/pda/login'),
		meta: {
			title: "PDA登录",
		}
	},
	{
		path: "/pda_scan",
		component: () => import('@/wap/pda/scan'),
		meta: {
			title: "PDA扫描",
			wap: true,
		}
	},
]

export default pdaRouter;
