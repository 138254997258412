import config from "@/config"
import http from "@/utils/request"

export default {
	handCashierJournal: {
		url: `${config.API_URL}/fms/cashier/cashierBank/handCashierJournal`,
		name: "出纳日记手动勾对",
		get: async function (bkRecon, id) {
			return await http.get(this.url + '?bkRecon=' + bkRecon + '&id=' + id);
		}
	},
	handBank: {
		url: `${config.API_URL}/fms/cashier/cashierBank/handBank`,
		name: "银行手动勾对",
		get: async function (bkRecon, id) {
			return await http.get(this.url + "?bkRecon=" + bkRecon + "&id=" + id);
		}
	},
	automatic: {
		url: `${config.API_URL}/fms/cashier/cashierBank/automatic`,
		name: "自动对账",
		get: async function (bkList, journalList) {
			return await http.get(this.url + "?bkList=" + bkList + "&journalList=" + journalList);
		}
	},
	cancel: {
		url: `${config.API_URL}/fms/cashier/cashierBank/cancel`,
		name: "取消勾对",
		get: async function (data) {
			return await http.post(this.url, data);
		}
	},
	end: {
		url: `${config.API_URL}/fms/cashier/cashierBank/end`,
		name: "结束对账",
		get: async function (bkList, journalList) {
			return await http.get(this.url + "?bkList=" + bkList + "&journalList=" + journalList);
		}
	},
	backEnd: {
		url: `${config.API_URL}/fms/cashier/cashierBank/backEnd`,
		name: "反对账",
		get: async function (dataList) {
			return await http.post(this.url,dataList);
		}
	},
}
