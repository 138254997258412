import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/organize/department/getList`,
		name: "分页查询部门管理",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/organize/department/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/organize/department/getAllList`,
		name: "所有部门",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	selectCondition: {
		url: `${config.API_URL}/cfg/organize/department/selectCondition`,
		name: "条件查询部门管理",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/organize/department/delete`,
		name: "删除部门管理",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/organize/department/insert`,
		name: "添加",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/organize/department/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	getParent: {
		url: `${config.API_URL}/cfg/organize/department/getParent`,
		name: "获取父级节点",
		get: async function(id){
			return await http.get(this.url + '?parentId=' +id);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/organize/department/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/organize/department/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
