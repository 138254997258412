<template>
  <ww-open-data :type="dataType" :openid="openId" />
</template>
<script>
import {defineComponent, toRefs, reactive, onMounted, watch} from "vue";
export default defineComponent({
  name: "wwOpenData",
  props: {
    // 类型
    dataType: {type: String, default: ''},
    // openId
    openId: {type: Number, default: null},
  },
  setup(props){
    const state = reactive({

    })

    watch(
        () => props.openId,
        (val) => {
          if(val){
            WWOpenData.bind(document.querySelector('ww-open-data'));
          }
        },
        { deep: true }
    )

    onMounted(()=>{
      WWOpenData.bind(document.querySelector('ww-open-data'));
    })

    return{
      ...toRefs(state)
    }
  }
})
</script>
