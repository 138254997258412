import config from "@/config"
import http from "@/utils/request"

export default {
	saleCount: {
		url: `${config.API_URL}/erp/sale/personal/saleCount`,
		name: "销售统计",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleCountDetail: {
		url: `${config.API_URL}/erp/sale/personal/saleCountDetail`,
		name: "销售统计 - 明细",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	reconciliation: {
		url: `${config.API_URL}/erp/sale/personal/reconciliation`,
		name: "客户对账",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleOrderAccount: {
		url: `${config.API_URL}/erp/sale/personal/saleOrderAccount`,
		name: "客户对账 - 销售订单对账",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleGoodsAccount: {
		url: `${config.API_URL}/erp/sale/personal/saleGoodsAccount`,
		name: "客户对账 - 销售产品对账",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	detailReconciliationData: {
		url: `${config.API_URL}/erp/sale/personal/detailReconciliationData`,
		name: "客户对账 - 明细对账 - Data数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
