import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/quality/submProduct/getList`,
		name: "产品送检列单表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/quality/submProduct/insert`,
		name: "新增",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/quality/submProduct/update`,
		name: "编辑",
		get: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/quality/submProduct/detail`,
		name: "详情",
		get: async function (data) {
			return await http.get(this.url + '?id=' + data);
		}
	},

	delete: {
		url: `${config.API_URL}/erp/quality/submProduct/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

    updateFlag1: {
		url: `${config.API_URL}/erp/quality/submProduct/updateFlag1`,
		name: "启用",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

    updateFlag0: {
		url: `${config.API_URL}/erp/quality/submProduct/updateFlag0`,
		name: "停用",
		get: async function (data) {
			return await http.get(this.url + '?ids=' + data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/quality/submProductDetail/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

}
