import config from "@/config"
import http from "@/utils/request"

export default {
	getSupplierSetList: {
		url: `${config.API_URL}/cfg/business/supplierSet/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	delSupplierSet: {
		url: `${config.API_URL}/cfg/business/supplierSet/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	detailSupplierSet: {
		url: `${config.API_URL}/cfg/business/supplierSet/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	insertSupplierSet: {
		url: `${config.API_URL}/cfg/business/supplierSet/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateSupplierSet: {
		url: `${config.API_URL}/cfg/business/supplierSet/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
}
