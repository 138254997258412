import config from "@/config"
import http from "@/utils/request"
/*表格设置*/
export default {
	detail: {
		url: `${config.API_URL}/cfg/system/systemTable/detailByTypeCode`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?typeCode=' + params);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/system/systemTable/getAllList`,
		name: "查询所有表格配置项",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/systemTable/insert`,
		name: "新增修改",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
