import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/commission/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	commissionGrant: {
		url: `${config.API_URL}/erp/sale/commission/commissionGrant`,
		name: "提成发放",
		get: async function(params){
			return await http.get(this.url+ "?ids=" + params);
		}
	},
}
