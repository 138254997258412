<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:width="width"
		:before-close="beforeClose"
		:trigger-dom="triggerDom"
		:close-on-click-modal="false"
		@reset="reset"
	>
		<div class="syForm">
			<el-form
				ref="formRef"
				:model="form"
				:label-width="90"
				size="small"
				:rules="rules"
				class="diaForm"
			>
				<el-form-item
					prop="fz_parentName"
					label="父级"
				>
					<sc-table-select
						v-model="form.fz_parentName"
            customer-value
						ref="refParentTableSelect"
						:api-obj="$API.classify.getList"
						:classify="true"
						hide-pagination
						:params="{flag: 1,businessType: businessType}"
						:props="{ label: 'name', value: 'id', keyword: 'name' }"
						:table-width="500"
						@change="(val) => handleSelChange(val, ['parentId','fz_parentName'])"
						@clear="clear('parent')"
					>
						<el-table-column
							prop="name"
							label="名称"
							width="300"
						/>
					</sc-table-select>
				</el-form-item>
				<el-form-item
					prop="name"
					label="分类名称"
				>
					<el-input
						v-model.trim="form.name"
						clearable
						maxlength="32"
						placeholder="请输入"
					/>
				</el-form-item>
				<el-form-item
					prop="code"
					v-if="showCode && !noCode"
					label="编码"
				>
					<el-input
						v-model.trim="form.code"
						clearable
						placeholder="请输入"
					/>
				</el-form-item>
				<el-form-item
					prop="codeed"
					v-if="!showCode && !noCode"
					label="编码"
				>
					<el-input
						v-model="form.codes"
						clearable
						placeholder="请输入"
						style="width: 30%"
						disabled
					/>
					<el-input
						v-model="form.codeed"
						clearable
						style="width: 69%"
						placeholder="请输入"
					/>
				</el-form-item>
			</el-form>
		</div>
		<template #footer>
			<el-popconfirm
				:visible="visibles"
				:title="$TIPS.SAVE"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@cancel="visibles = false"
				@confirm="handleConfirm"
			>
				<template #reference>
					<el-button
						type="primary"
						:size="$store.state.global.uiSize"
						:loading="subLoading"
						@click="valiForm"
					>
						提交
					</el-button>
				</template>
			</el-popconfirm>
			<el-popconfirm
				:title="$TIPS.CONFIRM_RESET"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@confirm="reset(false)"
			>
				<template #reference>
					<el-button :size="$store.state.global.uiSize">
						重置
					</el-button>
				</template>
			</el-popconfirm>
		</template>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick, watch,
} from "vue";

export default defineComponent({
	name: "GoodsDirEdit",
	emits: ["fetch-data"],
	props: {
		printData: {
			type: [Object, String], default: () => {
			}
		},
		// 取消编码
		noCode: {type: Boolean, default: false},
		// 业务编码
		businessType: {type: String, default: ""},
	},
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg));
			} else {
				callback();
			}
		};

		const state = reactive({
			visibles: false,
			//编码验证消息
			codeMsg: "",
			showCode: true,
			//加载动画
			loading: false,
			//触发元素
			triggerDom: {},
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: "新增",
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				code: [
					{required: true, trigger: "blur", message: "必填"},
					{validator: validateCode, trigger: "blur"},
				],
				codeed: [
					{required: true, trigger: "blur", message: "必填"},
					{validator: validateCode, trigger: "blur"},
				],
				name: [
					{required: true, trigger: "blur", message: "必填"},
				],

			},
		});

		watch(
			() => state.form.fz_parentName,
			(val) => {
				if (val) {
					state.showCode = false;
					// state.form.codeed = state.form.code
				} else {
					state.form.parentId = "";
					state.showCode = true;
					state.form.codes = "";
					if (state.form.codeed) {
						state.form.code = state.form.codeed;
					}
				}
			}
		);
		const showDialog = async (row) => {
			console.log(props.printData, "props.printData");
			state.visible = true;
			nextTick(async () => {
				//编辑
				if (row) {
					if (row.id) {
						state.title = "编辑";
						const res = await proxy.$API.classify.detail.get(row.id);
						console.log(res);
						if (res.code === 200) {
							state.form = Object.assign({}, res.data);
							state.orgForm = Object.assign({}, res.data);
							state.form.parent = {
								name: res.data.fz_parentName,
								id: res.data.parentId,
							};
							state.form["parentId"] = res.data.parentId;
							state.form["fz_parentName"] = res.data.fz_parentName;
							if (!props.noCode) {
								let code = res.data.code;
								let code2 = code.substring(res.data.fz_parentCode && res.data.fz_parentCode.length);
								state.form["codes"] = res.data.fz_parentCode;
								if (code2 && code2.length > 0) {
									state.form["codeed"] = code2;
								}
								if (res.data.fz_parentCode && res.data.fz_parentCode.length === res.data.code.length) {
									state.form["codeed"] = res.data.code;
								}
							}
						}
					} else {
						if (!row.parentId) { //新增顶级模块
							state.title = "新增";
							Object.assign(state.form, row);
							state.orgForm = Object.assign({}, state.form);
						} else {
							state.title = "新增子级";
							state.form = Object.assign({}, row);
							console.log(state.form);
							// await getParentNode(row.parentId)
							state.form.parent = {
								name: props.printData.name,
								id: props.printData.id,
								// coordinate: props.printData.coordinate
							};
							state.form["parentId"] = props.printData.id;
							state.form["fz_parentName"] = props.printData.name;
							state.form.codes = props.printData.code;
							state.orgForm = Object.assign({}, state.form);
						}
					}
				}
			});
		};

		//获取父级节点信息
		const getParentNode = async (id) => {
			const res = await proxy.$API.classify.del.get(id);
			if (res.code === 200) {
				state.form.parentId = res.data.id;
				state.form.parentName = res.data.name;
				state.orgForm.parentId = res.data.id;
				state.orgForm.parentName = res.data.name;
			}
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
			reset(true);
		};

		//关闭弹窗前确认
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done();
					reset(true);
				});
			});
		};

		//确定关闭
		// const handleConfirm = () => {
		// 	hideDialog()
		// }

		const handleConfirm = () => {
			save(false);
			state.visibles = false;
		};
		const valiForm = () => {
			nextTick(() => {
				state.formRef.validate((valid) => {
					if (valid) {
						if (!props.noCode) {
							if (state.form.level == 1) {
								if (state.form.codeed.length > 2) {
									proxy.$baseMessage("一级分类编码长度不能超过2位", "error");
									state.subLoading = false;
									return;
								}
							} else if (state.form.level == 2) {
								if (state.form.codeed.length > 2) {
									proxy.$baseMessage("二级分类编码长度不能超过2位", "error");
									state.subLoading = false;
									return;
								}
							} else if (state.form.level == 3) {
								if (state.form.codeed.length > 3) {
									proxy.$baseMessage("三级分类编码长度不能超过3位", "error");
									state.subLoading = false;
									return;
								}
							} else if (state.form.level == 4) {
								if (state.form.codeed.length > 4) {
									proxy.$baseMessage("四级分类编码长度不能超过4位", "error");
									state.subLoading = false;
									return;
								}
							}
						}
						state.visibles = true;
					} else {
						state.visibles = false;
					}
				});
			});
		};
		//确定按钮
		const save = () => {
			if (props.noCode) {
				delete state.form.codes;
				delete state.form.codeed;
			} else if (state.form.codes) {
				state.form.code = state.form.codes + state.form.codeed;
			}
			state["formRef"].validate(async (valid) => {
				if (valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
					state.subLoading = true;
					if (!props.noCode) {
						if (state.form.level == 1) {
							if (state.form.codeed.length > 2) {
								proxy.$baseMessage("一级分类编码长度不能超过2位", "error");
								state.subLoading = false;
								return;
							}
						} else if (state.form.level == 2) {
							if (state.form.codeed.length > 2) {
								proxy.$baseMessage("二级分类编码长度不能超过2位", "error");
								state.subLoading = false;
								return;
							}
						} else if (state.form.level == 3) {
							if (state.form.codeed.length > 3) {
								proxy.$baseMessage("三级分类编码长度不能超过3位", "error");
								state.subLoading = false;
								return;
							}
						} else if (state.form.level == 4) {
							if (state.form.codeed.length > 4) {
								proxy.$baseMessage("四级分类编码长度不能超过4位", "error");
								state.subLoading = false;
								return;
							}
						}
					}
					let res = {};
					if (state.form.id) {
						res = await proxy.$API.classify.update.put(state.form);
					} else {
						res = await proxy.$API.classify.insert.post(state.form);
					}
					if (res.code === 200) {
						proxy.$baseMessage(res.msg, "success");
						emit("fetch-data");
						hideDialog();
					} else {
						proxy.$baseMessage(res.msg, "error");
					}
					state.subLoading = false;
					// })
				}
			});
		};

		//重置表单
		const reset = (isClose) => {
			state.visibles = false;
			state["formRef"].resetFields();
			const orgForm = Object.assign({}, state.orgForm);
			Object.keys(state.form).forEach((item) => {
				delete state.form[item];
			});
			if (!isClose) {
				state.form = Object.assign({}, orgForm);
			}
		};
		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data, field) => {
			if (data.length > -1) { //多选
				let ids;
				if (data.length > 0) {
					//取得选中的id串
					ids = data.map((item) => item.id).join();
				} else {
					ids = "";
				}
				state.form[field] = ids;
			} else { //单选
				if (data && data.id) {
					if (field[0] === "parentId") {
						if (state.form.id && state.form.id === data.id) {
							//重置选择后的值为空
							proxy.$refs.refParentTableSelect.updateDefault("");
							proxy.$baseMessage("父级不能是自己!", "error");
							return false;
						}
						console.log(data);
						state.form.codes = data.code;
						state.form["parentId"] = data.id;
						state.form["fz_parentName"] = data.name;
						if (!!state.form.parentId) {
							state.showCode = false;
						} else {
							state.showCode = true;
						}
					}
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					state.form[field] = data.id;
				}
			}
		};
		const clear = (field) => {
			state.form[field + "Id"] = null;
			state.form["fz_" + field + "Name"] = "";
		};
		const parentEnet = (val) => {
			if (val) {
				state.showCode = false;
			} else {
				state.showCode = true;
			}
		};
		return {
			...toRefs(state),
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			handleSelChange,
			clear,
			valiForm,
			parentEnet
		};
	},
});
</script>

<style scoped>

</style>
