<template>
  <span
    ref="swBtn"
    class="switch_l_btn"
    :class="bottom ? 'bottom' : ''"
    @click="switchStatus"
  >
    <el-icon v-if="bottom">
      <el-icon-arrowDown v-if="isOpen" />
      <el-icon-arrowUp v-else />
    </el-icon>
    <el-icon v-else>
      <el-icon-arrowLeft v-if="isOpen" />
      <el-icon-arrowRight v-else />
    </el-icon>
    <em>{{ text }}</em>
  </span>
</template>
<script>
import {
	getCurrentInstance,
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	nextTick, watch,
} from 'vue'
export default defineComponent({
	name: "SwitchBtn",
	props: {
		//显示文字
		text: { type: String, default: '分类' },
		menu: { type: String, default: '' },
		//打开状态
		opened: { type: Boolean, default: true },
		//true则显示在底部 默认左侧
		bottom: { type: Boolean, default: false }
	},
	emits: ['show-left'],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			swBtn: null,
			isOpen: props.opened,
		})
		//控制显示
		const switchStatus = () => {
			state.isOpen = !state.isOpen
			emit('show-left',state.isOpen)
			seDetail()
		}
		//保存子集显示状态
		const seDetail = async () => {

			let detail = 1
			if(!state.isOpen){
				detail = 0
			}
			if(props.menu != ''){
				const res = await proxy.$API.systemTable.insert.post({
					typeCode: props.menu,
					detail: detail
				})
				if(res.code === 200){
					//保存成功后，把值存入本地缓存
					proxy.$TOOL.data.set('detail_'+props.menu, {
						typeCode: props.menu,
						detail: detail
					})
				}
			}
		}
		//获取显示状态
		const getDetail = async () => {
			if(props.menu != ''){
				//从本地缓存中获取
				const res = proxy.$TOOL.data.get('detail_'+props.menu)
				if(res){
					if(res.detail === 0){
						state.isOpen = false
					}else{
						state.isOpen = true
					}
					emit('show-left',state.isOpen)
				}
			}
		}
		onMounted(() => {
			nextTick(() => {
				getDetail()
			})
		})
		watch(
			() => props.opened,
			(val) => {
				state.isOpen = val
			}
		)
		return{
			...toRefs(state),
			switchStatus
		}
	}
})
</script>

