import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/fms/cashier/journal/getList`,
        name: "查询列表数据",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
	getAllList: {
        url: `${config.API_URL}/fms/cashier/journal/getAllList`,
        name: "查询列表数据",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    insert: {
        url: `${config.API_URL}/fms/cashier/journal/insert`,
        name: "新增",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    update: {
        url: `${config.API_URL}/fms/cashier/journal/update`,
        name: "修改",
        put: async function (data = {}) {
            return await http.put(this.url, data);
        }
    },
    detail: {
        url: `${config.API_URL}/fms/cashier/journal/detail`,
        name: "查询详情",
        get: async function (params) {
            return await http.get(this.url + "?id=" + params);
        }
    },
    delete: {
        url: `${config.API_URL}/fms/cashier/journal/delete`,
        name: "删除",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },
		importExcel: {
		url: `${config.API_URL}/fms/cashier/journal/importExcel`,
		name: "导入",
		post: async function (data={},config) {
			return await http.post(this.url, data,config);
		}
	},

	getVoucherNumber: {
		url: `${config.API_URL}/fms/cashier/journal/getVoucherNumber`,
		name: "获取凭证号",
		get: async function(params){
			return await http.get(this.url + '?month=' + params);
		}
	},
}
