<!--选择客户联系人弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:type="type"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'450px'"
		custom-class="popTable"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<el-container
							class="mainPanel"
							:style="multiple && 'padding-right: 10px'"
						>
							<sy-search-Form
								v-show="!hideForm"
								ref="refFormBar"
								:configs="searchConfig"
								:show-item="showItem"
								@fetch-data="fetchData"
								@set-show="handleFitShow"
							/>
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-button
										v-if="showAdd"
										ref="refPopAddBtn"
										type="primary"
										:size="$store.state.global.uiSize"
										@click="handleEdit(1, 'refPopAddBtn')"
									>
										新增
									</el-button>
									<el-link
										v-if="showEdit"
										ref="refEditBtn"
										:type="'primary'"
										:size="$store.state.global.uiSize"
										:disabled="isDisabled"
										@click="handleEdit(2, 'refEditBtn')"
									>
										编辑
									</el-link>
									<el-link
										v-if="multiple"
										type="primary"
										:disabled="selections.length === 0"
										@click="addToRight"
									>
										添加到已选
										<el-icon>
											<el-icon-right/>
										</el-icon>
									</el-link>
								</div>
								<div class="right-panel">
									<!--切换显示搜索栏hide-after: 延迟隐藏-->
									<el-tooltip
										:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
										placement="bottom"
										:hide-after="0"
									>
										<el-link @click="handleShowSearch">
											搜索
											<el-icon>
												<template v-if="hideForm">
													<el-icon-arrowDown/>
												</template>
												<template v-else>
													<el-icon-arrowUp/>
												</template>
											</el-icon>
										</el-link>
									</el-tooltip>
								</div>
							</el-header>
							<el-main class="nopadding bgwhite">
								<!--主数据表格-->
								<scTable
									ref="table"
									:api-obj="tableConfig.apiObj"
									:column="tableConfig.columns"
									:params="tableConfig.params"
									row-key="id"
									:checkbox="multiple"
									radio-check
									border
									highlight-current-row
									remote-sort
									:ref-query-form="refFormBar"
									remote-filter
									hide-column-setting
									hide-setting
									:pagination-layout="'total, prev, pager, next, jumper'"
									@selection-change="selectChange"
									@current-change="currentChange"
									@row-dblclick="dblClick"
								>
									<!--					<template #importance="{ scope }">-->
									<!--						<span v-if="scope.row.importance === 1">一般</span>-->
									<!--						<span v-if="scope.row.importance === 2">重要</span>-->
									<!--						<span v-if="scope.row.importance === 3">非常重要</span>-->
									<!--					</template>-->

									<template #gender="{ scope }">
										{{ scope.row.gender === 0 ? "女" : "男" }}
									</template>
									<template #manager="{ scope }">
										{{ scope.row.manager === 0 ? "否" : "是" }}
									</template>
								</scTable>
							</el-main>
						</el-container>
					</pane>
					<!--已选数据-->
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumns"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<!--      <el-button-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        @click="visible = false"-->
			<!--      >-->
			<!--        关闭-->
			<!--      </el-button>-->
		</template>
		<!--新增弹窗-->
		<Edit
			ref="refEdit"
			@fetch-data="fetchData"
		/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
	onBeforeMount,
} from "vue";
import Edit from "./edit";  //新增组件
export default defineComponent({
	name: "SelectCustomerAddress",
	components: {Edit},
	props: {
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: "选择地址"},
	},
	emits: ["set-data"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			refFormBar: null,
			isDelSel: true,
			type: "detail",
			//新增按钮显示与否
			showAdd: false,
			//显示编辑那妞
			showEdit: false,
			//隐藏搜索表单
			hideForm: true,
			//编辑按钮状态
			isDisabled: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: "55%",
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: "姓名",
					prop: "conacts",
					sortable: "custom",
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: null,
				params: {},
				columns: [
					{
						label: '收货人',
						prop: 'contacts',
						width: 120,
						sortable: "custom",
					},
					{
						label: '联系电话',
						prop: 'tel',
						width: 160,
						sortable: "custom",
					},
					{
						label: '所在地区',
						prop: 'area',
						width: 220,
						sortable: "custom",
					},
					{
						label: '详细地址',
						prop: 'address',
						width: 220,
						sortable: "custom",
					},
				]
			},
			//搜索表单配置
			searchConfig: {
				labelWidth: 100,
				itemList: [
					{
						label: "收货人",
						prop: "contacts",
						type: "input",
						max: 10,
						default: true,
						span: 8,
					},
					{
						label: "联系电话",
						prop: "tel",
						type: "input",
						max: 20,
						default: true,
						span: 8,
					},
					{
						label: "所在地区",
						prop: "area",
						type: "input",
						max: 255,
						span: 8,
						default: true,
					},
					{
						label: "收货地址",
						prop: "shipAddress",
						type: "input",
						max: 255,
						span: 8,
					},
				]
			},
		});

		//显示弹窗
		const showDialog = (customerId) => {
			state.visible = true;
			if (!customerId) {
				customerId = "";
			}
			state.tableConfig.params = {customerId: customerId};
			state.tableConfig.apiObj = proxy.$API.customer.getCustomerAddress;
			state.loading = false;
			getShowAdd();
			getShowEdit();
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_CUSTOMER, "add");
			//有结果标识有权限
			if (res.data) {
				state.showAdd = true;
			} else {
				state.showAdd = false;
			}
		};

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_CUSTOMER, "edit");
			//有结果标识有权限
			if (res.data) {
				state.showEdit = true;
			} else {
				state.showEdit = false;
			}
		};

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool;
		};

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val;
		};

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item);
			});
		};

		//选中行改变
		const currentChange = (row) => {
			state.curRow = row;
			if (state.curRow && JSON.stringify(state.curRow) != "{}") {
				state.isDisabled = false;
				if (!props.multiple) {
					state.isConfirm = false;
				}
			} else {
				state.isDisabled = true;
				if (!props.multiple) {
					state.isConfirm = true;
				}
			}
		};

		//确定按钮点击
		const handleConfirm = () => {
			let data;
			if (!props.multiple) { //多选
				data = state.curRow;
				data.completeAddress = data.area || '' + data.address || ''
				console.log(data);
			} else {
				data = state.selData;
			}
			//触发父级赋值方法
			emit("set-data", data);
			//隐藏弹窗
			hideDialog();
		};

		//行双击
		const dblClick = (row) => {
			if (row && !props.multiple) {
				//传入选中行和赋值的字段
				row.completeAddress = row.area || '' + row.address || ''
				emit("set-data", row);
				hideDialog();
			}
			if (props.multiple) {
				//添加已选数据
				setSelData(row);
			}
		};

		//分栏拖动结束
		const handleResized = (res) => {
			if (state.leftShow) {
				state.paneSize = res[0].size;
			}
		};

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state["table"].reload(data);
		};

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool;
		};

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm;
			});
		};

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if (!props.multiple) return false;
			if (data && state.selData.indexOf(data) === -1) {
				state.selData.push(data);
			}
			if (state.selData.length > 0) {
				state.isConfirm = false;
			} else {
				state.isConfirm = true;
			}
		};

		//清空已选数据
		const clearSelections = () => {
			state.selData = [];
			emit("set-data", state.selData);
		};

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val;
			if (val.length > 0) {
				state.isDelSel = false;
			} else {
				state.isDelSel = true;
			}
		};

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item);
					if (index !== -1) {
						state.selData.splice(index, 1);
					}
				});
			}
		};

		watch(
			() => state.selData,
			(val) => {
				if (val.length > 0) {
					state.isConfirm = false;
				} else {
					state.isConfirm = true;
				}
			},
			{deep: true}
		);

		/*------------------------按钮操作start-------------------------------*/
		//弹窗新增编辑
		const handleEdit = (type, ref) => {
			let param = {
				customerId: state.tableConfig.params.customerId
			};
			if (type === 2 && state.curRow && JSON.stringify(state.curRow) != "{}") { //编辑
				param = state.curRow;
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref];
			proxy.$refs.refEdit.showDialog(param, type);
		};
		/*------------------------按钮操作end-------------------------------*/

		return {
			...toRefs(state),
			tableSelselectChange,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
		};
	},
});
</script>
