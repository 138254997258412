import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	del: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	isEnd: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/isEnd`,
		name: "终止",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detailGoods: {
		url: `${config.API_URL}/erp/purchase/purchaseReceiptGoods/detail`,
		name: "查询物品数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	getReceiptGoodsList: {
		url: `${config.API_URL}/erp/purchase/purchaseReceiptGoods/getList`,
		name: "根据采购收获id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getQualityDetails: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/getQualityDetails`,
		name: "列表",
		get: async function (id) {
			return await http.get(this.url + '?orderId=' + id);
		}
	},
	getReceiptGoodsById: {
		url: `${config.API_URL}/erp/purchase/purchaseReceiptGoods/getListByReceiptId`,
		name: "根据采购收获id查询物品明细",
		get: async function (params) {
			return await http.get(this.url + '?receiptId=' + params);
		}
	},
	enterStock: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/enterStock`,
		name: "入库",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	exportExcel:{
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/exportExcel`,
		name: "导出Excel",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getInfoByIds: {
		url: `${config.API_URL}/erp/purchase/purchaseReceipt/getInfoByIds`,
		name: "根据 ids 查询",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},



	updateBarcodeGeneration: {
		url: `${config.API_URL}/erp/purchase/purchaseReceiptGoods/updateBarcodeGeneration`,
		name: "生成条码的状态为是",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	checkState: {
		url: `${config.API_URL}/erp/purchase/purchaseReceiptGoods/checkState`,
		name: "修改检验状态",
		get: async function(id,status){
			return await http.get(this.url + '?id=' + id + '&status=' + status);
		}
	},

	getDetailListByKeyword: {
		url: `${config.API_URL}/erp/purchase/purchaseReceiptGoods/getDetailListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

}
