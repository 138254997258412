<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="2"
				:size="$store.state.global.uiSize"
				border
				class="column2"
			>
				<!--label: 标题 -->
				<el-descriptions-item
					label="订单编号"
					label-align="right"
				>
					{{ data.orderNo }}
				</el-descriptions-item>
				<el-descriptions-item
					label="申请人"
					label-align="right"
				>
					{{ data.fz_applyUserName }}
				</el-descriptions-item>

				<!--        <el-descriptions-item-->
				<!--          label="业务部门"-->
				<!--          label-align="right"-->
				<!--        >-->
				<!--          {{ data.fz_departmentName }}-->
				<!--        </el-descriptions-item>-->

<!--				<el-descriptions-item-->
<!--					label="采购人"-->
<!--					label-align="right"-->
<!--				>-->
<!--					{{ data.fz_purUserName }}-->
<!--				</el-descriptions-item>-->
				<el-descriptions-item
					label="采购期限"
					label-align="right"
				>
					{{ data.effectiveDate }}
				</el-descriptions-item>

				<el-descriptions-item
					label="审批状态"
					label-align="right"
				>
					<el-tag
						v-if="data.auditStatus === 0"
						style="color: #666;"
					>
						未提交
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 1"
						type="warning"
					>
						审核中
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 2"
						type="danger"
					>
						已驳回
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 3"
						type="success"
					>
						审核通过
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 4"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未确认
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 5"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已确认
					</el-tag>
				</el-descriptions-item>

				 <el-descriptions-item
					label="结转状态"
					label-align="right"
				  >
					<el-tag
					  v-if="data.trackStatus === 0"
					  type="info"
					>
					  未结转
					</el-tag>
					<el-tag
					  v-if="data.trackStatus === 1"
					  type="warning"
					>
					  部分结转
					</el-tag>
					<el-tag
					  v-if="data.trackStatus === 2"
					  type="success"
					>
            全部结转
					</el-tag>
				 </el-descriptions-item>

				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
				<el-descriptions-item
					v-for="item in dataList"
					:key="item.value"
					:label="item.name"
					label-align="right">
					{{Array.isArray(data[item.value])?data[item.value].join(','):data[item.value]}}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="申请事由"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.reason }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="物品明细"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
				@dataChange="dataChange"
			>
				<template #applyStatus="{ scope }">
					<span v-if="scope.row.applyStatus == 1">单据新增</span>
					<el-link
						v-if="scope.row.applyStatus ==  2"
						type="primary"
						@click="handleList(scope.row)"
					>
						生产计划
					</el-link>
				</template>
        <template #files="{ scope }">
          <div class="mtf_5">
            <el-tag
                v-for="(file, index) in scope.row.sonFileList"
                :key="index"
                class="dtFile"
                @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </template>

        <template #fz_goodsName="{ scope }">
          <el-link
              type="primary"
              @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
          >
            {{ scope.row.fz_goodsName }}
          </el-link>
        </template>

        <template #turnState="{ scope }">
          <el-tag
              v-if="scope.row.turnState == 0"
              style="color: #666;"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            未结转
          </el-tag>
          <el-tag
              v-if="scope.row.turnState == 1"
              type="warning"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            部分结转
          </el-tag>
          <el-tag
              v-if="scope.row.turnState == 2"
              type="success"
              class="myTag"
              :size="$store.state.global.uiSize"
          >
            全部结转
          </el-tag>
        </template>

        <!--自定义字段-->
        <template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
          {{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}
        </template>
			</scTable>
		</el-card>
<!--		<el-card-->
<!--			header="关联单据"-->
<!--			shadow="never"-->
<!--			class="mycard mt_10"-->
<!--		>-->
<!--			<scTable-->
<!--				ref="tableOrderSon"-->
<!--				:data="data.orderList"-->
<!--				:column="tableOrderSonConfig.columns"-->
<!--				:height="'auto'"-->
<!--				row-key="id"-->
<!--				border-->
<!--				remote-sort-->
<!--				hide-setting-->
<!--				hide-pagination-->
<!--			>-->
<!--				<template #orderNo="{ scope }">-->
<!--					<el-link-->
<!--						type="primary"-->
<!--						@click="handleDetail(scope.row, 'refSaleOrderDetail','saleOrderId')"-->
<!--					>-->
<!--						{{ scope.row.orderNo }}-->
<!--					</el-link>-->
<!--				</template>-->

<!--				<template #fz_goodsName="{ scope }">-->
<!--					<el-link-->
<!--						type="primary"-->
<!--						@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"-->
<!--					>-->
<!--						{{ scope.row.fz_goodsName }}-->
<!--					</el-link>-->
<!--				</template>-->

<!--				&lt;!&ndash;自定义字段&ndash;&gt;-->
<!--				<template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">-->
<!--					{{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}-->
<!--				</template>-->

<!--			</scTable>-->
<!--		</el-card>-->

		<!--    <template #footer>-->
		<!--      <el-button-->
		<!--        :size="$store.state.global.uiSize"-->
		<!--        @click="visible = false"-->
		<!--      >-->
		<!--        关 闭-->
		<!--      </el-button>-->
		<!--    </template>-->
		<GoodsDetail ref="refGoodsDetail"/>
		<SaleOrderDetail ref="refSaleOrderDetail"/>
		<suggestionPop ref="refSuggestionPop" ></suggestionPop>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	onMounted
} from "vue";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import SaleOrderDetail from "@/views/erp/sale/order/detail";
import suggestionPop from "@/views/erp/purchase/apply/suggestionPop.vue";
export default defineComponent({
	components: { suggestionPop, GoodsDetail, SaleOrderDetail },
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			dataList: [],
			sonDataList: [],
			//弹窗宽度
			width: "60%",
			type: "detail",
			//高度
			height: "500px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			sonFileList: [],
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "采购申请详情",
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					// {
					// 	label: '物品类别',
					// 	prop: 'fz_classifyName',
					// 	width: 120,
					// },
          {
            label: "物品名称",
            prop: "fz_goodsName",
            sortable: "custom",
            fixed: "left",
            width: 180,
          },
          {
            label: "物品编码",
            prop: "fz_goodsCode",
            width: 120,
            sortable: "custom",
          },

          {
            label: "规格型号",
            prop: "fz_goodsSpec",
            width: 120,
            sortable: "custom",
          },
          // {
          // 	label: '产品位号',
          // 	prop: 'productBitNumber',
          // 	width: 180,
          // 	sortable: 'custom',
          // },
          {
            label: "结转状态",
            prop: "turnState",
            width: 110,
            sortable: "custom",
          },

          // {
          // 	label: '材质',
          // 	prop: 'fz_goodsMaterial',
          // 	width: 160,
          // },
          {
            label: "申请数量",
            prop: "quantity",
            width: 110,
            sortable: "custom",
          },
					{
						label: "申请来源",
						prop: "applyStatus",
						width: 110,
						sortable: "custom",
					},
          {
            label: "已结转数",
            prop: "transferQty",
            width: 110,
            sortable: "custom",
          },
          {
            label: "收货仓库",
            prop: "fz_depotName",
            width: 110,
            sortable: "custom",
          },
          {
            label: "主单位",
            prop: "unit",
            width: 110,
            sortable: "custom",
          },
          {
            label: "辅助单位",
            prop: "assistUnit",
            width: 110,
            sortable: "custom",
          },
          {
            label: "换算数量",
            prop: "assistQuantity",
            width: 110,
            sortable: "custom",
          },

          // {
          // 	label: '牌号',
          // 	prop: 'fz_brandNumber',
          // 	width: 140,
          // },

          // {
          // 	label: '供应商',
          // 	prop: 'fz_supplierName',
          // 	width: 160,
          // },

          // {
          // 	label: '当前库存',
          // 	prop: 'stock',
          // 	width: 120,
          // 	sortable: 'custom',
          // },

          // {
          // 	label: '其他要求',
          // 	prop: 'other',
          // 	width: 150,
          // 	sortable: 'custom',
          // },

          {
            label: "附件",
            prop: "files",
            sortable: "custom",
            width: 150,
          },
          {
            label: "备注",
            prop: "remark",
            width: 180,
            sortable: "custom",
          },
				],
			},
			tableOrderSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				columns: [
					{
						label: "销售订单编号",
						prop: "orderNo",
						sortable: "custom",
						fixed: "left",
						edit: "find",
						width: 200,
					},
					{
						label: "物品名称",
						prop: "fz_goodsName",
						fixed: "left",
						width: 180,
					},
					{
						label: "物品编码",
						prop: "fz_goodsCode",
						width: 120,
					},
					{
						label: "规格型号",
						prop: "fz_goodsSpec",
						width: 120,
					},
					{
						label: "产品编号",
						prop: "code",
						width: 120,
					},
					{
						label: "产品位号",
						prop: "productBitNumber",
						width: 120,
						sortable: "custom",
					},
					{
						label: "数量",
						prop: "quantity",
						width: 110,
						sortable: "custom",
					},
					{
						label: "主单位",
						disable: true,
						prop: "unit",
						width: 110,
						sortable: "custom",
					},
				],
			},
		});

		onMounted(() => {
			sonListMap();
			dataListMap();
		});

		// 获取产品明细自定义字段
		const sonListMap = async () => {

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 2, isEnabled: 1, menuCode: "erp_purchase_apply",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {
					item.value = "customField" + item.fieldLength;
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.sonDataList = res.data.list;
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: "custom",
				},);
			});
		};

		// 获取自定义字段
		const dataListMap = async () => {
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 1, isEnabled: 1, menuCode: "erp_purchase_apply",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {
					item.value = "customField" + item.fieldLength;
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.dataList = res.data.list;
			}
		};

		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = [];
			state.sonFileList = [];
			state.visible = true;
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.purchaseApply.detailApply.get(data.id);
				if (res.code === 200) {
					state.data = res.data;
					//获取明细表数据
					getGoodsList(res.data.id);
					//组装文件列表
					getFileList(res.data.files);
				}
			}
			state.loading = false;
		};

		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.params = {applyId: id};
				state.tableSonConfig.apiObj = proxy.$API.purchaseApply.getApplyGoodsList;
				proxy.$refs.tableSon.reload({applyId: id});
			}
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
			await proxy.$API.files.insertFilesDownload.post(file);
		};

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};
		const dataChange = (res) => {
			if (res.data.list.length > 0) {
				res.data.list.forEach((item) => {
					item["sonFileList"] = [];
					const file = item.files;
					if (file) {
						let arr = [];
						let sonFileList = [];
						if (file.length > 0) {
							arr = file.split(",");
							arr.forEach((item) => {
								let obj = {};
								const iarr = item.split("/");
								obj["url"] = item;
								obj["name"] = iarr[iarr.length - 1];
								sonFileList.push(obj);
							});
						}
						item.sonFileList = sonFileList;
					}
				});
			}
		};
		const handleList = async (row) => {
			console.log(row)
			row['applyId'] = row.applyId
			proxy.$refs.refSuggestionPop.showDialog(row)
			// if (row && row.id) {
			//   const res = await proxy.$API.produceMrpDocument.purchase.get(row.id)
			//   if(res.code === 200 && res.data){
			// 	  proxy.$refs.refIssuedData.showDialog(res.data,'purchase')
			//   }else if(res.code === 500){
			// 	  proxy.$baseMessage(res.msg,'error')
			//   }
			// }
		}
		return {
			...toRefs(state),
			handleDetail,
			handleList,
			showDialog,
			hideDialog,
			previewFile,
			dataChange,
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

