import config from "@/config"
import http from "@/utils/request"

export default {
	productCount: {
		url: `${config.API_URL}/erp/produce/journaling/productCount`,
		name: "加工产品统计表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	materialCount: {
		url: `${config.API_URL}/erp/produce/journaling/materialCount`,
		name: "领料统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	refundCount: {
		url: `${config.API_URL}/erp/produce/journaling/refundCount`,
		name: "退料统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	outMaterialCount: {
		url: `${config.API_URL}/erp/produce/journaling/outMaterialCount`,
		name: "委外出料统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	outCheckCount: {
		url: `${config.API_URL}/erp/produce/journaling/outCheckCount`,
		name: "委外验收统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	processMaterial: {
		url: `${config.API_URL}/erp/produce/journaling/processMaterial`,
		name: "加工单领料统计",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	planGlance: {
		url: `${config.API_URL}/erp/produce/journaling/planGlance`,
		name: "生产计划 - 览表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
