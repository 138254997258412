import config from "@/config"
import http from "@/utils/request"

export default {
	selectDetail: {
		url: `${config.API_URL}/fms/buy/relation/detail/selectDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getList: {
		url: `${config.API_URL}/fms/buy/relation/detail/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getDetailBySaleId: {
		url: `${config.API_URL}/fms/buy/relation/detail/getDetailBySaleId`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getDetailByPurId: {
		url: `${config.API_URL}/fms/buy/relation/detail/getDetailByPurId`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	}
}
