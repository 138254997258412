import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/produce/orderbom/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getOrderBomGoodsEdition: {
		url: `${config.API_URL}/erp/produce/orderbom/selectGoodsEditionNum`,
		name: "查询版本号",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	queryVersionNo: {
		url: `${config.API_URL}/erp/produce/orderbom/queryVersionNo`,
		name: "查询版本号",
		get: async function(params){
			return await http.get(this.url + "?goodsId=" + params);
		}
	},
	viewLevel: {
		url: `${config.API_URL}/erp/produce/orderbom/viewLevel`,
		name: "层级",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	getCost: {
		url: `${config.API_URL}/erp/produce/orderbom/getCost`,
		name: "成本明细",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	getOrderBomListByGoodsIdAndEdit: {
		url: `${config.API_URL}/erp/produce/orderbom/queryOrderBomDetailByGoodsIdAndEdit`,
		name: "查询orderBom清单",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	queryOrderBomId: {
		url: `${config.API_URL}/erp/produce/orderbom/queryOrderBomId`,
		name: "查询orderBom清单",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/produce/orderbom/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/orderbom/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/orderbom/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/orderbom/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	calculationList: {
		url: `${config.API_URL}/erp/produce/orderbom/calculationList`,
		name: "mrp运算",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	maximumProduction: {
		url: `${config.API_URL}/erp/produce/orderbom/maximumProduction`,
		name: "MRP最大生产查询",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/produce/orderbom/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateProcessInfo: {
		url: `${config.API_URL}/erp/produce/orderbom/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	getGoodsDetail: {
		url: `${config.API_URL}/erp/produce/orderbom/getGoodsDetail`,
		name: "查询物料明细",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	importExcel:{
		url: `${config.API_URL}/erp/produce/orderbom/importExcel`,
		name: "导入",
		post: async function(data={},config){
			return await http.post(this.url,data,config);
		}
	},

	getEditions: {
		url: `${config.API_URL}/erp/produce/orderbom/getEditions`,
		name: "查获取版本号",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/produce/orderbom/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
