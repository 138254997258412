import config from "@/config";
import http from "@/utils/request";

export default {
	getList: {
		url: `${config.API_URL}/erp/stock/materiaReceive/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	outStock: {
		url: `${config.API_URL}/erp/stock/materiaReceive/outStock`,
		name: "出库",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/stock/materiaReceive/detail`,
		name: "详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/stock/materiaReceive/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
