<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
  >
    <el-tabs
      class="myTabs"
      type="border-card"
    >
      <el-tab-pane label="审批信息">
        <el-card
          shadow="never"
          class="mycard form"
          header="当前节点"
        >
          <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
          <el-descriptions
            :title="''"
            :column="2"
            :size="$store.state.global.uiSize"
            border
            class="column2"
          >
            <el-descriptions-item
              label="当前审批节点"
              label-align="right"
              :min-width="120"
            >
              {{ data.currentNode }}
            </el-descriptions-item>
            <el-descriptions-item
              label="是否完成"
              label-align="right"
              :min-width="120"
            >
              {{ data.approvalStatus }}
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
        <el-card
          shadow="never"
          class="mycard form mt_10"
          header="历史记录"
        >
          <el-table
            :data="recordList"
            border
            :size="$store.state.global.uiSize"
          >
            <el-table-column
              label="序号"
              width="60"
              align="center"
              fixed="left"
            >
              <template #default="{ $index }">
                {{ $index + 1 }}
              </template>
            </el-table-column>
<!--            <el-table-column-->
<!--              prop="fz_definitionName"-->
<!--              label="流程名称"-->
<!--              :width="150"-->
<!--              fixed="left"-->
<!--            />-->
<!--            <el-table-column-->
<!--              prop="fz_applicantName"-->
<!--              label="申请人"-->
<!--              :width="90"-->
<!--            />-->
			  <el-table-column
				  prop="fz_operateName"
				  label="审批人"
				  :width="90"
			  />
			  <el-table-column
				  prop="result"
				  label="审批结果"
				  :width="100"
			  >
				  <template #default="scope">
					  <el-tag
						  v-if="scope.row.result === 0"
						  type="info"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  已驳回
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 1"
						  type="info"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  提交审批
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 2"
						  type="success"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  审批通过
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 3"
						  type="danger"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  驳回上级
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 4"
						  type="success"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  通过结束
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 5"
						  type="danger"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  驳回结束
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 6"
						  type="warning"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  反审核
					  </el-tag>
					  <el-tag
						  v-if="scope.row.result === 10"
						  type="success"
						  class="myTag"
						  :size="$store.state.global.uiSize"
					  >
						  已确认
					  </el-tag>

					  <!--<span>{{ scope.row.result==1?'驳回上级':scope.row.result==2?'审批通过':'驳回结束' }}</span>-->
				  </template>
			  </el-table-column>
			  <el-table-column
				  prop="addTime"
				  label="审批时间"
				  :width="200"
			  />
            <el-table-column
              prop="nodeName"
              label="审批节点"
              :width="110"
            />
            <el-table-column
              prop="opinion"
              label="审批意见"
              :width="200"
            />
			  <el-table-column
				  prop="fileList"
				  label="附件"
				  :width="150"
			  >
				  <template #default="{ row }">
					  <div class="mtf_5">
						  <el-tag
							  v-for="(file, index) in row.fileList"
							  :key="index"
							  class="dtFile"
							  @click="previewFile(file)"
						  >
							  <a href="javascript:">{{ file.name }}</a>
						  </el-tag>
					  </div>
				  </template>
			  </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="流程图">
        <div class="h500">
          <sy-work-flow
            ref="refWorkFlow"
            :edit-able="false"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'
import SyWorkFlow from '@/components/syWorkFlow'
export default defineComponent({
	components: { SyWorkFlow },
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '65%',
			type:"detail",
			//图片预览
			srcList: [],
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '历史审批记录',
			//详情数据对象
			data: null,
			recordList: [],
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.srcList = [];
			state.data = data
			state.visible = true
			let params = {
				instanceId : data.instanceId,
				taskId : data.taskId,
				menuCode : data.menuCode,
				businessId: data.id
			}
			console.info(params);
			const returnData = await proxy.$API.flowTask.historyRecord.get(params);
			if(returnData.code == 200) {
				state.recordList = returnData.data.recordList
				state.recordList.forEach((item)=>{
					item['fileList'] = []
				})
				state.recordList.forEach((item) => {
					const data = item.files;
					if (data) {
						let arr = []
						let fileList = []
						if (data.length > 0) {
							arr = data.split(',')
							arr.forEach((item) => {
								let obj = {}
								const iarr = item.split('/')
								obj['url'] = item
								obj['name'] = iarr[iarr.length - 1]
								fileList.push(obj)
							})
						}
						item.fileList = fileList;
					}
				});
				state.data = returnData.data
				state.data.url = proxy.$CONFIG.API_URL + '/' + "api/" + returnData.data.imgPath
				state.srcList.push(state.data.url);
				await fetchData(params)
			}
			state.loading = false
		}

		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
		}

		const fetchData = async (params) => {
			const res = await proxy.$API.flowTask.historyRecord.get(params)
			if(res.code === 200 && res.data && res.data.flowData){
				proxy.$refs.refWorkFlow.renderData(JSON.parse(res.data.flowData))
			}
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}
		return {
			...toRefs(state),
			showDialog,
			hideDialog,
			previewFile,
		}
	},
})
</script>

<style scoped>
.demo-image__placeholder .block {
	padding: 30px 0;
	text-align: center;
	/*border-right: solid 1px var(--el-border-color);*/
	display: inline-block;
	width: 100%;
	box-sizing: border-box;
	vertical-align: top;
}
.demo-image__placeholder .demonstration {
	display: block;
	color: var(--el-text-color-secondary);
	font-size: 14px;
	margin-bottom: 20px;
}
.demo-image__placeholder .el-image {
	padding: 0 5px;
	max-width: 300px;
	max-height: 200px;
}

.demo-image__placeholder.image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: var(--el-fill-color-light);
	color: var(--el-text-color-secondary);
	font-size: 14px;
}
.demo-image__placeholder .dot {
	animation: dot 2s infinite steps(3, start);
	overflow: hidden;
}
</style>

