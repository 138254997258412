import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/business/orderno/getList`,
		name: "查询单号规则",
		get: async function(){
			return await http.get(this.url);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/business/orderno/update`,
		name: "保存单号规则",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
}
