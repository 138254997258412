import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/stock/stockFlow/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/mobile/stock/stockFlow/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/stock/stockFlow/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/stock/stockFlow/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/stock/stockFlow/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	getFlowGoodsList: {
		url: `${config.API_URL}/mobile/stock/stockFlowDetail/getList`,
		name: "根据采购合同id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getFlowGoodsById: {
		url: `${config.API_URL}/mobile/stock/stockFlowDetail/getListByFlowId`,
		name: "根据采购合同id查询物品明细",
		get: async function (params) {
			return await http.get(this.url + '?flowId=' + params);
		}
	},
	determine: {
		url: `${config.API_URL}/mobile/stock/stockFlow/determine`,
		name: "确认报溢",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

}
