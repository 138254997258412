import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/buy/backSupplier/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/buy/backSupplier/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/buy/backSupplier/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/buy/backSupplier/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/buy/backSupplier/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/fms/buy/backSupplier/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/fms/buy/backSupplier/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	updatePaymentStatus: {
		url: `${config.API_URL}/fms/buy/backSupplier/updatePaymentStatus`,
		name: "确认",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	queryBySupplierId: {
		url: `${config.API_URL}/fms/buy/backSupplier/queryBySupplierId`,
		name: "根据id查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/fms/buy/backSupplier/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	//导入
	importExcel: {
		url: `${config.API_URL}/fms/buy/backSupplier/importExcel`,
		name: "导入",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},
}
