<template>
  <ul class="drop-list">
    <li
      v-for="item in data"
      :key="item.id"
      @click="nodeClick(item)"
    >
      {{ item.name }}
    </li>
  </ul>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	name: 'DropDepot',
	emits: ['loaded','node-click'],
	setup(props,{emit}) {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			expandAll: true,
			top: '',
			//加载动画
			loading: true,
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//节点数据
			data: [],
		})

		//拉取数据
		const fetchData = async () => {
			const res = await proxy.$API.depot_wpa.getDepotList.get({pageSize: 10000, pageNum: 1})
			if (res.code === 200) {
				state.data = res.data.list
			}
			emit('loaded')
		}

		//节点点击
		const nodeClick = (data) => {
			emit('node-click',data)
		}

		return {
			...toRefs(state),
			nodeClick,
			fetchData,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

