<!--选择采购单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
    :type="type"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
                    <div class="left-panel" />
                    <div class="right-panel">
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
											origin-sel-all
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @select="handleSelect"
                      @select-all="handleSelectAll"
                      @current-change="currentChange"
                      @row-dblclick="dblClick"
                      @row-click="handleRowClick"
                      @data-change="fatherDataChange"
                    >
											<template #receiptNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refDetail')"
												>
													{{ scope.row.receiptNo }}
												</el-link>
											</template>

											<template #fz_supplierName="{ scope }">
												<el-link
													type="primary"

													@click="handleDetail(scope.row, 'refSupplierDetail', 'supplierId')"
												>
													{{ scope.row.fz_supplierName }}
												</el-link>
											</template>

											<template #fz_purOrderNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refPurOrderDetail', 'purOrderId')"
												>
													{{ scope.row.fz_purOrderNo }}
												</el-link>
											</template>
											<template #enterStatus="{ scope }">
												<el-tag
													v-if="scope.row.enterStatus == 0"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未入库
												</el-tag>
												<el-tag
													v-if="scope.row.enterStatus == 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分入库
												</el-tag>
												<el-tag
													v-if="scope.row.enterStatus == 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部入库
												</el-tag>
											</template>
											<template #qualityStatus="{ scope }">
												<el-tag
													v-if="scope.row.qualityStatus === 0"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													检测中
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 3"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													无需检测
												</el-tag>
											</template>
                    </scTable>
                  </el-main>
                  <sy-switch-btn
                    :text="'物品明细'"
                    :opened="leftShow"
                    bottom
                    @show-left="handleShowLeft"
                  />
                </el-container>
              </pane>
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="50"
                min-size="20"
              >
                <el-container
                  class="sonCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <el-main class="nopadding">
                    <!--明细-->
                    <scTable
                      ref="tableSon"
                      :api-obj="tableSonConfig.apiObj"
                      :column="tableSonConfig.columns"
                      :params="tableSonConfig.params"
                      row-key="id"
											:checkbox="sonCheck"
                      border
                      remote-sort
											@select="handleSonSelect"
											@selection-change="sonSelChange"
											@select-all="handleSonSelectAll"
											@row-click="handleSonRowClick"
											@data-change="sonDataChange"
                    >
											<template #fz_goodsName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
												>
													{{ scope.row.fz_goodsName }}
												</el-link>
											</template>

											<template #storageStatus="{ scope }">
												<el-tag
													v-if="scope.row.storageStatus === 0"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未入库
												</el-tag>
												<el-tag
													v-if="scope.row.storageStatus === 1"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已入库
												</el-tag>
											</template>

											<template #qualityStatus="{ scope }">
												<el-tag
													v-if="scope.row.qualityStatus === 0"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													检测中
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 3"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													无需检测
												</el-tag>
											</template>
                    </scTable>
                  </el-main>
                </el-container>
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <SupplierDetail ref="refSupplierDetail" />
    <CompanyDetail ref="refCompanyDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from "@/views/erp/stock/purchase/receipt/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import SupplierDetail from "@/views/cfg/data/supplier/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
export default defineComponent ({
	name: "SelectPurReceipt",
	components: {
		Detail,
		GoodsDetail,
		SupplierDetail,
		CompanyDetail,
	},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择采购收货单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
      type:'detail',
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '订单编号',
					prop: 'receiptNo',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {
				pageNum: 1,
				pageSize: 1000,
			},
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: null,
				//数据表格列配置
				columns: [
					{
						label: '订单编号',
						prop: 'receiptNo',
						width: 200,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '采购订单',
						prop: 'fz_purOrderNo',
						width: 200,
						sortable: 'custom',
						// fixed: 'left',
					},
					{
						label: '质检状态',
						prop: 'qualityStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '入库状态',
						prop: 'enterStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: "供应商",
						prop: "fz_supplierName",
						width: 200,
					},
					{
						label: '收货人',
						prop: 'fz_receiverName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '收货日期',
						prop: 'receiptTime',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'illustrate',
						width: 180,
						sortable: 'custom',
					},
				// {
				// 	label: '操作用户',
				// 	prop: 'fz_operateName',
				// 	width: 120,
				// 	sortable: 'custom',
				// },
				// {
				// 	label: '操作时间',
				// 	prop: 'addTime',
				// 	width: 210,
				// 	sortable: 'custom',
				// },
				]

      },
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '收货仓库',
						prop: 'fz_depotName',
						sortable: 'custom',
						width: 110,
					},
					{
						label: "质检状态",
						prop: "qualityStatus",
						width: 110,
					},
					{
						label: "入库状态",
						prop: "storageStatus",
						width: 110,
					},

					{
						label: '收货数量',
						prop: 'quantity',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '主单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅助单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},

					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
        ],

			},
			tableSonConfigCheck: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
        columns: [
          {
            label: '物品名称',
            prop: 'fz_goodsName',
            sortable: 'custom',
            fixed: 'left',
            width: 180,
          },
          {
            label: '物品编码',
            prop: 'fz_goodsCode',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '规格型号',
            prop: 'fz_goodsSpec',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '数量',
            prop: "quantity",
            width: 110,
          },
          {
            label: '主单位',
            prop: 'unit',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '辅助单位',
            prop: 'assistUnit',
            width: 110,
            sortable: 'custom',
          },

          {
            label: '换算数量',
            prop: 'assistQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '备注',
            prop: 'abstracts',
            width: 180,
            sortable: 'custom',
          },
        ],

			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '订单编号',
						prop: 'receiptNo',
						type: 'input',
						default: true,
					},
					{
						label: '采购订单',
						prop: 'fz_purOrderNo',
						type: 'input',
						default: true,
					},
					{
						label: "供应商",
						prop: "fz_supplierName",
						type: "popSupplier",
						default: true,
					},
					{
						label: '收货人',
						prop: 'fz_receiverName',
						type: 'popUser',
						default: true,
						// short: 12,
						fields: ['receiverId', 'fz_receiverName'],
					},
					{
						label: '收货日期',
						type: 'dateTimeRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_receiptTime0',
							},
							{
								prop: 'fz_receiptTime1',
							},
						],
					},
					{
						label: '备注',
						type: 'input',
						max: 255,
						prop: 'illustrate',
					},
					{
						label: '入库状态',
						prop: 'enterStatus',
						type: 'select',
						// short: 12,
						data: [
							{
								label: '全部入库',
								value: 2,
							},
							{
								label: '部分入库',
								value: 1,
							},
							{
								label: '未入库',
								value: 0,
							},
						],
					},
					// {
					// 	label: '质检状态',
					// 	prop: 'qualityStatus',
					// 	type: 'select',
					// 	// short: 12,
					// 	data: [
					// 		{
					// 			label: '未检验',
					// 			value: 0,
					// 		},
					// 		{
					// 			label: '检验中',
					// 			value: 1,
					// 		},
					//
					// 		{
					// 			label: '检验完成',
					// 			value: 2,
					// 		},
					// 	],
					// },
					{
						label: '操作用户',
						prop: 'fz_operateName',
						type: 'popUser',
						// short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_addTime0',
							},
							{
								prop: 'fz_addTime1',
							},
						],
					},
				],
			},
			//是否选择相同供应商
			isSameSup: false,
			// 是否能进行明细选择
			sonCheck: false,
			apiObj: null,
		})

		//显示弹窗
		const showDialog = (data,isSameSup=false,sonCheck=false,apiObj,isCheck=false) => {
			if(isCheck){
				state.tableSonConfig = Object.assign({},state.tableSonConfigCheck)
			}
			if(apiObj){
				state.tableConfig.apiObj = apiObj.table
			}else {
				state.tableConfig.apiObj = proxy.$API.purchaseReceipt.getList
			}
			state.apiObj = apiObj
			state.isSameSup = isSameSup
			state.sonCheck = sonCheck
			state.visible = true
			state.selections = []
			if (state.selData && state.selData.length > 0){
				state.selData = []
			}
			state.isConfirm = true
			state.loading = false
			state.tableConfig.params = {}
			if(data) {
				Object.assign(state.tableConfig.params, data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}
		const sonSelChange = (val) => {
			console.log(val)

		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(state.sonCheck && !data.receiptId) return
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			if (data && index === -1) {
				state.selData.push(data)
			} else {
				state.selData = state.selData.filter(item => JSON.stringify(item) !== JSON.stringify(data))
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
			if(state.sonCheck){
				showSonChoose()
			}else {
				showChooseData()
			}
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) !== '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				if(state.isSameSup){
					let isDiff = false
					state.selData.forEach((item)=>{
						if(item.fz_supplierName != state.selData[0].fz_supplierName){
							isDiff = true
						}
					})
					if (isDiff) {
						proxy.$baseMessage('请选择供应商相同的订单！', 'warning')
						return
					}
				}
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			data = {
				...data,
				...state.tableConfig.params
			}
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if (state.leftShow) {
				const param = Object.assign({}, state.params)
				param['receiptId'] = row.id
				state['tableSon'].reload(param)
			}
			if (props.multiple) {
				//添加已选数据
				setSelData(row)
			}
		}
		//子表行点击事件
		const handleSonRowClick = (row) => {
			if (props.multiple && state.sonCheck) {
				//添加已选数据
				setSelData(row)
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				if(!props.multiple) {   // 多选
					//设置第一行选中
					state['table'].setCurrentRow(res.data.list[0])
				}
				//传入父级ID
				Object.assign(state.tableSonConfig.params, state.params)
				state.tableSonConfig.params['receiptId'] = res.data.list[0].id
				//将接口对象赋给子级表格
				if(state.apiObj){
					state.tableSonConfig.apiObj = state.apiObj.tableSon
				}else {
					state.tableSonConfig.apiObj = proxy.$API.purchaseReceipt.getReceiptGoodsList
				}
				//根据主数据加载子数据
				// handleRowClick(res.data.list[0])
			}else{
				if(state.leftShow){
					proxy.$refs.tableSon.reload({ receiptId: -1 })
				}
			}
			showChooseData()
		}
		const sonDataChange = () => {
			showSonChoose()
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//复选框选择
		const handleSelect = async (selection, row) => {
			if (state.sonCheck) {
				//选中，取消时都会调用
				const res = await state.tableSonConfig.apiObj.get({
					receiptId: row.id,
					pageSize: 100,
					pageNum: 1
				})
				if (res.code === 200) {
					res.data.list.forEach((son) => {
						setSelData(son)
					})
				}
			} else {
				const index = selection.indexOf(row)
				const index1 = state.selData.indexOf(row)
				if (index < 0) {
					state.selData.splice(index1, 1)
				} else {
					if (index1 < 0) {
						state.isConfirm = false
						state.selData.push(row)
					}
				}
			}
		}
		//子表复选框选择
		const handleSonSelect = (selection, row) => {
			const sonData = state.tableSon.tableData
			const order = state.table.tableData.find(item=>item.id === sonData[0].receiptId)
			if(selection.length < 1){
				proxy.$refs.table.toggleRowSelection(order, false)
			}else if(selection.length === sonData.length){
				proxy.$refs.table.toggleRowSelection(order, true)
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.isConfirm = false
					state.selData.push(row)
				}
			}
		}

		//全选
		const handleSelectAll = async (selection) => {
			if (selection && selection.length > 0) {
				if (state.sonCheck) {
					for (const item of selection) {
						const res = await state.tableSonConfig.apiObj.get({
							receiptId: item.id,
							pageSize: 100,
							pageNum: 1
						})
						if (res.code === 200) {
							res.data.list.forEach((son) => {
								setSelData(son)
							})
						}
					}
					showSonChoose()
				}
				selection.forEach((item) => {
					setSelData(item)
				})
			} else {
				state.selData = []
				if(state.sonCheck){
					showSonChoose()
				}
			}
		}
		//子表全选
		const handleSonSelectAll = async (selection) => {
			if (selection && selection.length > 0) {
				selection.forEach((item) => {
					setSelData(item)
				})
			} else {
				state.selData = []
			}
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//子表回显选中状态
		const showSonChoose = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.tableSon
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return JSON.stringify(son) === JSON.stringify(item)
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			clearSelections,
			delSelData,
			handleDetail,
			handleRowClick,
			handleSonRowClick,
			fatherDataChange,
			sonDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			sonSelChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
			handleSelectAll,
			handleSonSelectAll,
			showChooseData,
			handleSelect,
			handleSonSelect
		}
	},
})
</script>
