import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/fms/assets/provision/getList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    getAllList: {
        url: `${config.API_URL}/fms/assets/provision/getAllList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    insert: {
        url: `${config.API_URL}/fms/assets/provision/insert`,
        name: "计提折旧-开始",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    reverse: {
        url: `${config.API_URL}/fms/assets/provision/reverse`,
        name: "反计提折旧",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    delete: {
        url: `${config.API_URL}/fms/assets/provision/delete`,
        name: "删除",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },
    del: {
        url: `${config.API_URL}/fms/assets/provision/del`,
        name: "查询",
        get: async function (params) {
            return await http.get(this.url + '?id=' + params);
        }
    },
    detail: {
        url: `${config.API_URL}/fms/assets/provision/detail`,
        name: "查询单条数据",
        get: async function (params) {
            return await http.get(this.url + '?id=' + params);
        }
    },
    update: {
        url: `${config.API_URL}/fms/assets/provision/update`,
        name: "修改",
        put: async function (data = {}) {
            return await http.put(this.url, data);
        }
    },
    updateFlag: {
        url: `${config.API_URL}/fms/assets/provision/updateFlag`,
        name: "修改状态",
        put: async function (data = {}) {
            return await http.put(this.url + "?id=" + data);
        }
    },
}
