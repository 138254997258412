import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/initial/finance/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/cfg/initial/finance/update`,
		name: "修改",
		get: async function (data = {},config) {
			return await http.post(this.url, data,config);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/cfg/initial/fund/updateFlag0`,
		name: "状态禁用",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/cfg/initial/fund/updateFlag1`,
		name: "状态启用",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
