import config from "@/config"
import http from "@/utils/request"

export default {
	getBuyList: {
		url: `${config.API_URL}/erp/smartSignBoard/buy`,
		name: "采购看板数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getSellList: {
		url: `${config.API_URL}/erp/smartSignBoard/sell`,
		name: "销售看板数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getCustomerList: {
		url: `${config.API_URL}/erp/smartSignBoard/getCustomerKanban`,
		name: "客户信息看板数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getStockList: {
		url: `${config.API_URL}/erp/smartSignBoard/stock`,
			name: "库存看板数据",
			get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getFundList: {
		url: `${config.API_URL}/erp/smartSignBoard/fund`,
		name: "资金看板数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getProduceList: {
		url: `${config.API_URL}/erp/smartSignBoard/produce`,
		name: "生产信息看板数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

}
