import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/purchase/contract/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	del: {
		url: `${config.API_URL}/mobile/purchase/contract/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detail: {
		url: `${config.API_URL}/mobile/purchase/contract/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/mobile/purchase/contract/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/mobile/purchase/contract/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	getContractGoodsList: {
		url: `${config.API_URL}/mobile/purchase/contractGoods/getList`,
		name: "根据采购合同id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getContractGoodsById: {
		url: `${config.API_URL}/mobile/purchase/contractGoods/getListByContractId`,
		name: "根据采购合同id查询物品明细",
		get: async function (params) {
			return await http.get(this.url + '?contractId=' + params);
		}
	},


	getContractClauseById: {
		url: `${config.API_URL}/mobile/purchase/contractClause/getListByContractId`,
		name: "根据采购合同id查询合同条款",
		get: async function (params) {
			return await http.get(this.url + '?contractId=' + params);
		}
	},


	getContractPaymentById: {
		url: `${config.API_URL}/mobile/purchase/contractPayment/getListByContractId`,
		name: "根据采购合同id查询首付款期限",
		get: async function (params) {
			return await http.get(this.url + '?contractId=' + params);
		}
	},

	updateContractStatus: {
		url: `${config.API_URL}/mobile/purchase/contract/updateContractStatus`,
		name: "修改合同状态",
		get: async function(ids,contractStatus){
			return await http.get(this.url + '?ids=' + ids + '&contractStatus=' + contractStatus);
		}
	},
}
