<template>
  <div class="syForm">
    <el-form
      ref="myForm"
      :model="form"
      :label-width="config.labelWidth || 90"
      :size="config.size || 'small'"
      :rules="config.rules || {}"
    >
      <el-row :gutter="20">
        <template
          v-for="(item, index) in config.formItems"
          :key="index"
        >
          <el-col
            class="border"
            :span="item.span"
          >
            <el-form-item
              :prop="item.prop"
              :label="item.label"
            >
              <template v-if="item.component=='input'">
                <el-input
                  v-model="form[item.prop]"
                  :maxlength="item.options.max"
                  clearable
                  show-word-limit
                />
              </template>
              <template v-if="item.component=='select'">
                <el-select
                  v-model="form[item.prop]"
                  clearable
                >
                  <template
                    v-for="(_item, _index) in item.options.items"
                    :key="_index"
                  >
                    <el-option
                      :label="_item.label"
                      :value="_item.value"
                    />
                  </template>
                </el-select>
              </template>
              <template v-if="item.component=='datepicker'">
                <el-date-picker
                  v-model="form[item.prop]"
                  :type="item.options.type"
                  :value-format="item.options.valueFormat"
                  :disabled-date="item.options.disabledDate"
                  clearable
                />
              </template>
              <template v-if="item.component=='area'">
                <el-cascader
                  v-model="area"
                  clearable
                  :options="areaOptions"
                  separator="-"
                  placeholder="选择地区"
                  :props="{
                    label: 'name',
                    value: 'name',
                    expandTrigger: 'hover',
                  }"
                  style="width: 100%"
                  @change="(val) => handleArea(val, item)"
                />
              </template>
              <template v-if="item.component=='checkbox'">
                <el-checkbox v-model="form[item.prop]">
                  {{ item.options.name }}
                </el-checkbox>
              </template>
              <template v-if="item.component=='checkboxGroup'">
                <el-checkbox-group v-model="form[item.prop]">
                  <el-checkbox
                    v-for="(_item, _index) in item.options.items"
                    :key="_index"
                    :label="_item.label"
                  >
                    {{ _item.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </template>
              <template v-if="item.component=='cascader'">
                <el-cascader
                  v-model="form[item.prop]"
                  :options="item.options.items"
                  clearable
                />
              </template>
              <template v-if="item.component=='switch'">
                <el-switch v-model="form[item.prop]" />
              </template>
              <template v-if="item.component=='radio'">
                <el-radio-group v-model="form[item.prop]">
                  <el-radio
                    v-for="_item in item.options.items"
                    :key="_item.value"
                    :label="_item.value"
                  >
                    {{ _item.label }}
                  </el-radio>
                </el-radio-group>
              </template>
              <template v-if="item.component=='upload'">
                <sc-upload
                  v-model="form[item.prop]"
                  :title="item.label"
                />
              </template>
              <template v-if="item.component=='multiUpload'">
                <sc-upload-multiple
                  v-model="form[item.prop]"
                  :title="item.label"
                  :accept="item.accept"
                  :is-file="item.options.isFile"
                  :list-type="item.options.listType"
                >
                  <el-button
                    v-if="item.options.isFile"
                  >
                    选择文件
                  </el-button>
                </sc-upload-multiple>
              </template>
              <template v-if="item.component=='number'">
                <el-input-number
                  v-model="form[item.prop]"
                  controls-position="right"
                />
              </template>
              <template v-if="item.component=='rate'">
                <el-rate
                  v-model="form[item.prop]"
                />
              </template>
              <template v-if="item.component=='slider'">
                <el-slider
                  v-model="form[item.prop]"
                  :min="item.options.min"
                  :max="item.options.max"
                  :marks="item.options.marks || {}"
                />
              </template>
              <template v-else-if="item.component=='tableselect'">
                <tableselect-render
                  v-model="form[item.prop]"
                  :item="item"
                />
              </template>
              <template v-else-if="item.component=='editor'">
                <sc-editor
                  v-model="form[item.prop]"
                  placeholder="请输入"
                  :height="400"
                />
              </template>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	defineAsyncComponent,
	getCurrentInstance,
	onMounted,
	nextTick,
} from 'vue'
import areaOptions from '@/api/model/area'
const tableselectRender = defineAsyncComponent(() => import('./items/tableselect'))
const scEditor = defineAsyncComponent(() => import('@/components/scEditor'))
export default defineComponent({
	name: "SyForm",
	components: { tableselectRender, scEditor },
	props: {
		config: { type: Object, default: () => {} },
	},
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			myForm: null,
			area: null,
			//表单数据对象
			form: {},
			//存储原始数据
			orgForm: {},
			checkList: [],
		})
		//地区选择变化
		const handleArea = (val, item) => {
			if(val){
				state.form[item.prop] = val.join('-')
			}else{
				state.form[item.prop] = ''
			}
		}
		const submit = () => {
			state['myForm'].validate((valid) => {
				if(valid){
					proxy.$baseConfirm('确认提交？', null, () =>{
						if(state.form.area){
							if(typeof state.form.area == 'object'){
								const arr = Object.values(state.form.area)
								state.form.area = arr[0] + '-' + arr[1]  + '-' + arr[2]
							}
						}
						const res = props.config.apiObj.post(state.form)
						return res
					})
				}
			})
		}
		//重置表单
		const resetForm = (isClose) => {
			state['myForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}
		return {
			...toRefs(state),
			submit,
			areaOptions,
			handleArea,
			resetForm
		}
	}
})
</script>

<style scoped>
  .syForm .sceditor,.syForm .el-cascader,.syForm .el-input-number{
	  width: 100%;
  }
</style>
