import config from "@/config"
import http from "@/utils/request"

export default {
	upload: {
		url: `${config.API_URL}/upload/annex`,
		name: "文件上传",
		post: async function(data, config={}){
			return await http.post(this.url, data, config);
		}
	},
	uploadWord: {
		url: `${config.API_URL}/upload/wordUpload`,
		name: "word文件上传",
		post: async function(data, config={}){
			return await http.post(this.url, data, config);
		}
	},
	queryWordFile: {
		url: `${config.API_URL}/upload/queryWordFile`,
		name: "查询自定义模板文件是否存在",
		get: async function(data){
			return await http.get(this.url + '?filePath=' + data);
		}
	},
	uploadFile: {
		url: `${config.API_URL}/uploadFile`,
		name: "附件上传",
		post: async function(data, config={}){
			return await http.post(this.url, data, config);
		}
	},
	deleteFile:{
		url: `${config.API_URL}/upload/deleteFile`,
		name: "附件上传",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	exportFile: {
		url: `${config.API_URL}/fileExport`,
		name: "导出附件",
		get: async function(data, config={}){
			return await http.get(this.url, data, config);
		}
	},
	importFile: {
		url: `${config.API_URL}/fileImport`,
		name: "导入附件",
		post: async function(data, config={}){
			return await http.post(this.url, data, config);
		}
	},
	file: {
		menu: {
			url: `${config.API_URL}/file/menu`,
			name: "获取文件分类",
			get: async function(){
				return await http.get(this.url);
			}
		},
		list: {
			url: `${config.API_URL}/file/list`,
			name: "获取文件列表",
			get: async function(params){
				return await http.get(this.url, params);
			}
		}
	}
}
