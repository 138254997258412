export default {
	mounted(el, binding) {
		let height = ""
		function isResize(){
			const style = el.offsetHeight
			if(height != style){
				binding.value({height: style})
			}
			height = style
		}
		el._vueSetInterval_ = setInterval(isResize, 100)
	},
	unmounted(el){
		clearInterval(el._vueSetInterval_)
	}
};
