<!--弹出式详情-->
<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :bef-close="false"
    fullscreen
    :show-fullscreen="false"
  >
    <el-container
      direction="vertical"
      style="overflow: hidden"
    >
      <BetterScroll
        ref="refBetterScroll"
        class="scrollWrapper"
        pull-down
        @pull-down="pullDown"
      >
        <div class="wap-container">
          <el-card
            header="基本信息"
            shadow="never"
            class="mycard"
          >
            <div
              v-if="data.auditStatus === 1"
              class="examResult yellow"
            >
              <i class="iconfont icon-shenhezhong" />
            </div>
            <div
              v-if="data.auditStatus === 2"
              class="examResult red"
            >
              <i class="iconfont icon-yijujue" />
            </div>
            <div
              v-if="data.auditStatus === 3"
              class="examResult green"
            >
              <i class="iconfont icon-yitongguo" />
            </div>
            <el-descriptions
              :title="''"
              :column="1"
              border
              class="column1"
              size="small"
            >
              <el-descriptions-item
                label="订单编号"
                label-align="right"
              >
                {{ data.orderNo }}
              </el-descriptions-item>
              <el-descriptions-item
                label="客户"
                label-align="right"
              >
                {{ data.fz_customerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="订单总价"
                label-align="right"
              >
                {{ data.totalAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="业务员"
                label-align="right"
              >
                {{ data.fz_salesmanName }}
              </el-descriptions-item>
              <el-descriptions-item
                label="是否补单"
                label-align="right"
              >
                <span v-if="data.supplement===1"
                      style="color: red"
                > 是 </span>
                <span v-else> 否 </span>
              </el-descriptions-item>
              <el-descriptions-item
                label="创建日期"
                label-align="right"
              >
                {{ data.creationDate }}
              </el-descriptions-item>
              <el-descriptions-item
                label="付款方式"
                label-align="right"
              >
                <span v-if="data.paymentMethod === 1">现金</span>
                <span v-if="data.paymentMethod === 2">转账</span>
                <span v-if="data.paymentMethod === 3">支票</span>
                <span v-if="data.paymentMethod === 4">其他</span>
              </el-descriptions-item>

              <el-descriptions-item
                label="审批状态"
                label-align="right"
              >
                <el-tag
                  v-if="data.auditStatus === 0"
                  style="color: #666;"
                  class="myTag"
                >
                  未提交
                </el-tag>
                <el-tag
                  v-if="data.auditStatus === 1"
                  type="warning"
                  class="myTag"
                >
                  审核中
                </el-tag>
                <el-tag
                  v-if="data.auditStatus === 2"
                  type="danger"
                  class="myTag"
                >
                  已驳回
                </el-tag>
                <el-tag
                  v-if="data.auditStatus === 3"
                  type="success"
                  class="myTag"
                >
                  审核通过
                </el-tag>
              </el-descriptions-item>
              <el-descriptions-item
                label="生产状态"
                label-align="right"
              >
                <el-tag
                  v-if="data.processStatus === 0"
                  style="color: #666;"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  未生产
                </el-tag>
                <el-tag
                  v-if="data.processStatus === 1"
                  type="warning"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  生产中
                </el-tag>
                <el-tag
                  v-if="data.processStatus === 2"
                  type="success"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  已完成
                </el-tag>
              </el-descriptions-item>
              <el-descriptions-item
                label="发货状态"
                label-align="right"
              >
                <el-tag
                  v-if="data.refundStatus === 0"
                  style="color: #666;"
                  class="myTag"
                >
                  未发货
                </el-tag>
                <el-tag
                  v-if="data.refundStatus === 1"
                  type="warning"
                  class="myTag"
                >
                  部分发货
                </el-tag>
                <el-tag
                  v-if="data.refundStatus === 2"
                  type="success"
                  class="myTag"
                >
                  全部发货
                </el-tag>
              </el-descriptions-item>

              <!--              <el-descriptions-item
                label="跟踪状态"
                label-align="right"
              >
                <el-tag
                  v-if="data.trackStatus === 0"
                  type="info"
                >
                  未结转
                </el-tag>
                <el-tag
                  v-if="data.trackStatus === 1"
                  type="success"
                >
                  已结转
                </el-tag>
                <el-tag
                  v-if="data.trackStatus === 2"
                  type="danger"
                >
                  已作废
                </el-tag>
              </el-descriptions-item>-->

              <el-descriptions-item
                label="操作用户"
                label-align="right"
              >
                {{ data.fz_operateName }}
              </el-descriptions-item>
              <el-descriptions-item
                label="操作时间"
                label-align="right"
              >
                {{ data.addTime }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions
              :title="''"
              :size="$store.state.global.uiSize"
              border
              :column="1"
              class="column1"
            >
              <el-descriptions-item
                label="备注"
                label-class-name="noTopBorder"
                class-name="noTopBorder"
                :label-align="labelAlign"
              >
                {{ data.remark }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions
              :title="''"
              :size="$store.state.global.uiSize"
              border
              :column="1"
              class="column1"
            >
              <el-descriptions-item
                label="附件"
                label-class-name="noTopBorder"
                class-name="noTopBorder"
                :label-align="labelAlign"
              >
                <div class="mtf_5">
                  <el-tag
                    v-for="(file, index) in fileList"
                    :key="index"
                    class="dtFile"
                    @click="previewFile(file)"
                  >
                    <a href="javascript:">{{ file.name }}</a>
                  </el-tag>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-card
            v-for="item in collectList"
            :key="item.id"
            header="收款期限"
            shadow="always"
            class="mycard mt_10"
          >
            <el-row :span="1">
              <span class="label">期限名称：</span>{{ item.name }}
            </el-row>
            <el-row :span="1">
              <span class="label">编码：</span>{{ item.code }}
            </el-row>
            <el-row :span="1">
              <span class="label">日期：</span>{{ item.addTime }}
            </el-row>
            <el-row :span="1">
              <span class="label">金额：</span>{{ item.collectAmount }}
            </el-row>
          </el-card>
          <el-card
            header="物品明细"
            shadow="never"
            class="mycard mt_10"
          >
            <ul class="list-ul">
              <li
                v-for="item in goodsData"
                :key="item.id"
              >
                <el-card
                  class="list-item cs-goods npd"
                  shadow="never"
                >
                  <div class="disflex">
                    <div class="item-img pdl0 flexgs0">
                      <el-image
                        :src="item.fz_img || Logo"
                        fit="contain"
                        class="list-img"
                      />
                    </div>
                    <div class="item-box flexgs1">
                      <div class="dis-flex mt-f5">
                        <div class="item-prop full">
                          <el-link
                            type="primary"
                            @click="handleDetail(item, 'refGoodsDetail', 'goodsId')"
                          >
                            {{ item.fz_goodsName }}
                          </el-link>
                        </div>
                        <div class="item-prop full">
                          <span class="label">物品编号：</span>{{ item.fz_goodsCode }}
                        </div>
                        <div class="item-prop full">
                          <span class="label">规格型号：</span>{{ item.fz_goodsSpec }}
                        </div>
                        <div class="item-prop full">
                          <span class="label">产品编号：</span>{{ item.code }}
                        </div>
                        <div class="item-prop full">
                          <span class="label">产品位号：</span>{{ item.productBitNumber }}
                        </div>
<!--                        <div class="item-prop full">-->
<!--                          <span class="label">材质：</span>{{ item.fz_goodsMaterial }}-->
<!--                        </div>-->
                        <div class="item-prop full">
                          <span class="label">发货仓库：</span>{{ item.fz_depotName }}
                        </div>
                        <div class="item-prop full">
                          <span class="label">生产状态：</span>
                          <el-tag
                            v-if="item.processStatus === 0"
                            style="color: #666;"
                            class="myTag"
                            :size="$store.state.global.uiSize"
                          >
                            未生产
                          </el-tag>
                          <el-tag
                            v-if="item.processStatus === 1"
                            type="warning"
                            class="myTag"
                            :size="$store.state.global.uiSize"
                          >
                            生产中
                          </el-tag>
                          <el-tag
                            v-if="item.processStatus === 2"
                            type="success"
                            class="myTag"
                            :size="$store.state.global.uiSize"
                          >
                            已完成
                          </el-tag>
                        </div>
                        <div class="item-prop full">
                          <span class="label">发货状态：</span>
                          <el-tag
                            v-if="item.refundStatus === 0"
                            style="color: #666;"
                            class="myTag"
                          >
                            未发货
                          </el-tag>
                          <el-tag
                            v-if="item.refundStatus === 1"
                            type="warning"
                            class="myTag"
                          >
                            部分发货
                          </el-tag>
                          <el-tag
                            v-if="item.refundStatus === 2"
                            type="success"
                            class="myTag"
                          >
                            全部发货
                          </el-tag>
                        </div>
                        <div class="item-prop full">
                          <span class="label">备注：</span>{{ item.remark }}
                        </div>
                        <div class="item-prop full">
                          <span class="label">数量：</span>{{ item.quantity }}{{ item.unit }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-card>
              </li>
            </ul>
          </el-card>
        </div>
      </BetterScroll>
    </el-container>
    <template #footer>
      <div class="text-center">
        <el-button
          v-if="state.adopt"
          size="small"
          type="primary"
          @click="handleExam"
        >
          审核
        </el-button>
        <el-button
          size="small"
          @click="visible = false"
        >
          关 闭
        </el-button>
      </div>
    </template>
    <Exam
      ref="refExam"
      @process-callback="processCallback"
    />
    <GoodsDetail
      ref="refGoodsDetail"
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, toRefs} from 'vue'
import Exam from "@/wap/views/publicQuery/exam/index.vue";
import Logo from '@/assets/img/hbxt.png'
import GoodsDetail from "@/wap/views/publicQuery/selectGoods/detail.vue";

export default defineComponent({
	name: 'WapSaleOrderDetail',
	components: {GoodsDetail, Exam },
	setup() {
		//获取当前实例对象
		const { proxy } = getCurrentInstance()
		const state = reactive({
			isLoading: false,
			//弹窗宽度
			width: '60%',
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '销售订单详情',
			//详情数据对象
			data: {
				auditStatus: 0,
			},
			goodsData: [],
			collectList: [],
			dataGoodsPrice: [],
			productLimitList: [],
			fileList: [],
			imgsList: [],
			imgsPreviewList: [],
		})

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			if (data.id) {
				//根据id查询
				fetchData(data)
			}
			state.loading = false
		}

		//审批回调
		const processCallback = async (res) => {
			await proxy.$API.saleOrder_wpa.updateProcessInfo.put(res)
			await fetchData();
		}

		const getGoodsList = async (id) => {
			if(id){
				const res = await proxy.$API.apply_wpa.goodsGetList.get({
					applyId: id,
					pageSize: 1000,
					pageNum: 1,
				})
				if(res.code === 200){
					state.goodsData = res.data.list
				}
			}
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if(data){
				arr = data.split(',')
				if(arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}
		//审核
		const handleExam = () => {
			let param = {
				name: state.data.orderNo,
				taskId: state.data.taskId,
				instanceId: state.data.instanceId,
			}
			proxy.$refs.refExam.showDrawer(param)
		}

		//刷新数据
		const fetchData = async (data) => {
			data = data || state.data
			if (data.taskId){
				const resDate = await proxy.$API.flowTask.queryApproval.get(data.taskId)
				state.adopt = resDate.data
			}
			const res = await proxy.$API.saleOrder_wpa.detail.get(data.id)
			if (res.code === 200) {
				state.data = res.data
				state.goodsData = res.data.sonList
				state.collectList = res.data.collectList
				//获取明细表数据
				// getGoodsList(res.data.id)
				//组装文件列表
				getFileList(res.data.files)
				//数据获取成功后 设置加载动画结束
				proxy.$refs.refBetterScroll.isLoading = true
			}
		}

		//下拉刷新
		const pullDown = () => {
			fetchData()
		}

		return {
			...toRefs(state),
			pullDown,
			fetchData,
			handleExam,
			handleDetail,
			showDialog,
			previewFile,
			hideDialog,
			processCallback,
			Logo,
			state
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

