
import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/cfg/business/snRules/getList`,
        name: "分页查询序列号",
        get: async function(data={}){
            return await http.post(this.url, data);
        }
    },

    insert: {
        url: `${config.API_URL}/cfg/business/snRules/insert`,
        name: "新增",
        get: async function(data={}){
            return await http.post(this.url,data);
        }
    },

    update: {
        url: `${config.API_URL}/cfg/business/snRules/update`,
        name: "修改",
        get: async function(data={}){
            return await http.put(this.url,data);
        }
    },

    delete: {
        url: `${config.API_URL}/cfg/business/snRules/delete`,
        name: "删除",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },


    detail: {
        url: `${config.API_URL}/cfg/business/snRules/detail`,
        name: "查询单条数据",
        get: async function(params){
            return await http.get(this.url + '?id=' + params);
        }
    },

    updateFlag1: {
        url: `${config.API_URL}/cfg/business/snRules/updateFlag1`,
        name: "启用",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },
    updateFlag0: {
        url: `${config.API_URL}/cfg/business/snRules/updateFlag0`,
        name: "停用",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },

    batchEntry: {
        url: `${config.API_URL}/cfg/business/snRules/batchEntry`,
        name: "（生成）批量录入序列号",
        get: async function(data={}){
            return await http.post(this.url, data);
        }
    },

    setDefault: {
        url: `${config.API_URL}/cfg/business/snRules/setDefault`,
        name: "设置默认序列号生成规则",
        get: async function(data){
            return await http.get(this.url + '?id=' + data);
        }
    },

    checkNumber: {
        url: `${config.API_URL}/cfg/business/snRules/checkNumber`,
        name: "序列号生成规则校验编码",
        get: async function(data={}){
            return await http.post(this.url, data);
        }
    },

    checkName: {
        url: `${config.API_URL}/cfg/business/snRules/checkName`,
        name: "序列号生成规则校验名字",
        get: async function(data={}){
            return await http.post(this.url, data);
        }
    },

		importExcel:{
			url: `${config.API_URL}/cfg/business/snRules/importNumberExcel`,
			name: "导入Excel",
			post: async function(data = {},config){
				return await http.post(this.url,data,config);
			}
		},

}
