import config from "@/config"
import http from "@/utils/request"

export default {
	selectCashierInventoryDetail: {
		url: `${config.API_URL}/fms/cashier/inventory/detail/selectCashierInventoryDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
