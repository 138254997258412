import config from "@/config"
import http from "@/utils/request"

export default {
	selectDetail: {
		url: `${config.API_URL}/erp/produce/process/detail/selectDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	selectDetailNotPage: {
		url: `${config.API_URL}/erp/produce/process/detail/selectDetailNotPage`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateQuantity: {
		url: `${config.API_URL}/erp/produce/process/detail/updateQuantity`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},



}
