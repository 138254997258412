<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type = "type"
    :loading="loading"
    :width="width"
    :bef-close="false"
  >
    <div class="popPane">
      <!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="单位名称"
          :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>

        <el-descriptions-item
          label="单位编码"
          :label-align="labelAlign"
        >
          {{ data.number }}
        </el-descriptions-item>

        <el-descriptions-item
          label="英文名称"
          :label-align="labelAlign"
        >
          {{ data.englishName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="社会信用代码"
          :label-align="labelAlign"
        >
          {{ data.code }}
        </el-descriptions-item>

        <el-descriptions-item
          label="所在地区"
          :label-align="labelAlign"
        >
          {{ data.area }}
        </el-descriptions-item>

        <el-descriptions-item
          label="详细地址"
          :label-align="labelAlign"
        >
          {{ data.address }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="联系手机"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.phone }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="联系电话"
          :label-align="labelAlign"
        >
          {{ data.tel }}
        </el-descriptions-item>

        <el-descriptions-item
          label="电子邮箱"
          :label-align="labelAlign"
        >
          {{ data.email }}
        </el-descriptions-item>

        <el-descriptions-item
          label="网址"
          :label-align="labelAlign"
        >
          {{ data.website }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="旺旺"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.wangwang }}-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
          label="法定代表人"
          :label-align="labelAlign"
        >
          {{ data.corporation }}
        </el-descriptions-item>

        <el-descriptions-item
          label="委托代理人"
          :label-align="labelAlign"
        >
          {{ data.entrust }}
        </el-descriptions-item>

        <el-descriptions-item
          label="开户行"
          :label-align="labelAlign"
        >
          {{ data.fz_bankName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="账户名称"
          :label-align="labelAlign"
        >
          {{ data.accountName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="银行账号"
          :label-align="labelAlign"
        >
          {{ data.accountNumber }}
        </el-descriptions-item>

        <el-descriptions-item
          label="联行号"
          :label-align="labelAlign"
        >
          {{ data.bankNo }}
        </el-descriptions-item>

        <el-descriptions-item
          label="许可证号"
          :label-align="labelAlign"
        >
          {{ data.licenseKey }}
        </el-descriptions-item>

        <el-descriptions-item
          label="税号"
          :label-align="labelAlign"
        >
          {{ data.taxCode }}
        </el-descriptions-item>

        <el-descriptions-item
          label="发票类型"
          :label-align="labelAlign"
        >
					{{ data.fz_typeName }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="采购专票税率"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.purchaseSpecial }}%-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="采购普票税率"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.purchaseGeneral }}%-->
<!--        </el-descriptions-item>-->

        <el-descriptions-item
            label="发票税率"
            :label-align="labelAlign"
        >
          {{ data.saleSpecial === '-1' ? '' : data.saleSpecial && data.saleSpecial + '%' }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="销售普票税率"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.saleGeneral }}%-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="经营范围"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.businessScope }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="企业简介"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.profile }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '70%',
			//加载动画
			loading: true,
			type:'detail',
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '单位详情',
			data: {},
			//详情数据对象
			deswidth: '25%',
			fileList: [],
		})

		//显示弹窗
		const showDialog = async (data) => {
			console.log(data)
			state.fileList = []
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.company.detailCompany.get(data.id)
        console.log(res);
				if (res.code === 200) {
					state.data = res.data
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		return {
			...toRefs(state),
			showDialog,
			previewFile,
			hideDialog,
		}
	},
})
</script>
