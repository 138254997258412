import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/getList`,
		name: "查询目录",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	pageHelper: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/pageHelper`,
		name: "分页查询目录",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/delete`,
		name: "删除",
		get: async function (id) {
			return await http.get(this.url + '?id=' + id);
		}
	},
	insert: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	getParent: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/getParent`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	topLevel: {
		url: `${config.API_URL}/oa/archive/archiveDirectory/topLevel`,
		name: "查询顶级目录",
		get: async function () {
			return await http.get(this.url);
		}
	},
}
