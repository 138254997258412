import config from "@/config"
import http from "@/utils/request"

export default {
    getList: {
        url: `${config.API_URL}/erp/quality/submProcure/getList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
	submProcureDetailGetList: {
        url: `${config.API_URL}/erp/quality/submProcureDetail/getList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
	getPurchaseList: {
        url: `${config.API_URL}/erp/quality/submProcure/getPurchaseList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
	getPurchaseGoodsList: {
        url: `${config.API_URL}/erp/quality/submProcure/getPurchaseGoodsList`,
        name: "列表",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    del: {
        url: `${config.API_URL}/erp/quality/submProcure/delete`,
        name: "删除",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },

    detail: {
        url: `${config.API_URL}/erp/quality/submProcure/detail`,
        name: "查询单条数据",
        get: async function (params) {
            return await http.get(this.url + '?id=' + params);
        }
    },

    insert: {
        url: `${config.API_URL}/erp/quality/submProcure/insert`,
        name: "新增",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    update: {
        url: `${config.API_URL}/erp/quality/submProcure/update`,
        name: "修改",
        put: async function (data = {}) {
            return await http.put(this.url, data);
        }
    },

    getOrderGoodsList: {
        url: `${config.API_URL}/erp/quality/submProcureGoods/getList`,
        name: "根据检验单id查询物品明细 +（分页）",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

    getOrderGoodsById: {
        url: `${config.API_URL}/erp/quality/submProcureGoods/getListByOrderId`,
        name: "根据检验单id查询物品明细",
        get: async function (params) {
            return await http.get(this.url + '?orderId=' + params);
        }
    },
	selectSubmProductDetail: {
		url: `${config.API_URL}/erp/quality/submProductDetail/selectSubmProductDetail`,
		name: "查询明细数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/quality/submProcure/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeywords: {
		url: `${config.API_URL}/erp/quality/submProcureDetail/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

}
