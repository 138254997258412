<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
    :width="width"
    :bef-close="false"
  >
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="2"
      class="column2"
    >
      <el-descriptions-item
        label="预收单号"
        :label-align="labelAlign"
      >
        {{ data.orderNo }}
      </el-descriptions-item>

      <el-descriptions-item
        label="客户"
        :label-align="labelAlign"
      >
        <el-link
          type="primary"
          @click="handleDetail(data, 'refCustomerDetail', 'customerId')"
        >
          {{ data.fz_customerName }}
        </el-link>
      </el-descriptions-item>

      <el-descriptions-item
        label="预收金额"
        :label-align="labelAlign"
      >
        {{ data.advrecAmount }}
      </el-descriptions-item>

      <el-descriptions-item
        label="收款日期"
        :label-align="labelAlign"
      >
        {{ data.receiptTime }}
      </el-descriptions-item>
      <el-descriptions-item
        label="收款方式"
        :label-align="labelAlign"
      >
        <span v-if="data.method === 1">现金</span>
        <span v-if="data.method === 2">转账</span>
        <span v-if="data.method === 3">支票</span>
        <span v-if="data.method === 4">其他</span>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="data.method === 2"
        label="收款账户"
        :label-align="labelAlign"
      >
        {{ data.fz_accountName }}
      </el-descriptions-item>
<!--      <el-descriptions-item-->
<!--        label="是否补单"-->
<!--        :label-align="labelAlign"-->
<!--      >-->
<!--        {{ data.supplement === 1 ? '是' : '否' }}-->
<!--      </el-descriptions-item>-->

      <el-descriptions-item
        label="单位抬头"
        :label-align="labelAlign"
      >
        <el-link
          type="primary"
          @click="handleDetail(data, 'refCompanyDetail', 'companyId')"
        >
          {{ data.fz_companyName }}
        </el-link>
      </el-descriptions-item>
<!--      <el-descriptions-item-->
<!--        label="创建日期"-->
<!--        :label-align="labelAlign"-->
<!--      >-->
<!--        {{ data.creationDate }}-->
<!--      </el-descriptions-item>-->
    </el-descriptions>
    <el-descriptions-item
      label="收款状态"
      label-align="right"
    >
      <el-tag
        v-if="data.collectStatus === 0"
        type="info"
      >
        未收款
      </el-tag>
      <el-tag
        v-if="data.collectStatus === 1"
        type="success"
      >
        已收款
      </el-tag>
      <el-tag
        v-if="data.collectStatus === 2"
        type="danger"
      >
        已作废
      </el-tag>
    </el-descriptions-item>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="备注"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        {{ data.remark }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      :title="''"
      :size="$store.state.global.uiSize"
      border
      :column="1"
      class="column1"
    >
      <el-descriptions-item
        label="附件"
        label-class-name="noTopBorder"
        class-name="noTopBorder"
        :label-align="labelAlign"
      >
        <div class="mtf_5">
          <el-tag
            v-for="(file, index) in fileList"
            :key="index"
            class="dtFile"
            @click="previewFile(file)"
          >
            <a href="javascript:">{{ file.name }}</a>
          </el-tag>
        </div>
      </el-descriptions-item>
    </el-descriptions>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
    <CustomerDetail ref="refCustomerDetail" />

    <CompanyDetail ref="refCompanyDetail" />
  </sc-dialog>
</template>

<script>
	import {
		defineComponent,
		toRefs,
		reactive,
		getCurrentInstance
	} from 'vue'
	import CustomerDetail from "@/views/cfg/data/customer/detail";
	import CompanyDetail from "@/views/cfg/organize/company/detail";
	export default defineComponent({
		components: { CustomerDetail, CompanyDetail },
		setup() {
			//获取当前实例对象
			const {proxy} = getCurrentInstance()
			const state = reactive({
				//弹窗宽度
				width: '70%',
        type:"detail",
				//加载动画
				loading: true,
				//标题对齐方式 'left' 'right' 'center'
				labelAlign: 'right',
				//尺寸大小
				size: 'small',
				//控制弹窗显示隐藏
				visible: false,
				//弹窗标题
				title: '预收款单详情',
				data: {},
				//详情数据对象
				deswidth: '25%',
				fileList: [],
			})

			//显示弹窗
			const showDialog = async (data) => {
				state.fileList = []
				state.visible = true
				if (data.id) {
					//根据id查询
					const res = await proxy.$API.sellAdvreceipt.detail.get(data.id)
					if (res.code === 200) {
						state.data = res.data
						getFileList(res.data.files)
					}
				}
				state.loading = false
			}

			const getFileList = (data) => {
				let arr = []
				if (data) {
					arr = data.split(',')
					if (arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							const iarr = item.split('/')
							obj['url'] = item
							obj['name'] = iarr[iarr.length - 1]
							state.fileList.push(obj)
						})
					}
				}
			}

			const previewFile = async (file) => {
				window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
				await proxy.$API.files.insertFilesDownload.post(file)
			}

			//隐藏弹窗
			const hideDialog = () => {
				state.visible = false
			}

			const handleDetail = (row, ref, field) => {
				const params = Object.assign({}, row)
				if(field){
					params.id = row[field]
				}
				proxy.$refs[ref].showDialog(params)
			}

			return {
				...toRefs(state),
				handleDetail,
				showDialog,
				previewFile,
				hideDialog,
			}
		},
	})
</script>
