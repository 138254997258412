<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<el-descriptions-item
					label="加工单号"
					label-align="right"
				>
					{{ data.orderNo }}
				</el-descriptions-item>
				<el-descriptions-item
					label="生产计划"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refPlanDetail', 'planId')"
					>
						{{ data.fz_planNo }}
					</el-link>
				</el-descriptions-item>

				<el-descriptions-item
					label="产品名称"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refGoodsDetail', 'goodsId')"
					>
						{{ data.fz_goodsName }}
					</el-link>
				</el-descriptions-item>

				<el-descriptions-item
					label="计划开始日期"
					label-align="right"
				>
					{{ data.startDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="计划结束日期"
					label-align="right"
				>
					{{ data.endDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="计划工时"
					label-align="right"
				>
					{{ data.workHour }}
				</el-descriptions-item>
				<el-descriptions-item
					label="审核状态"
					label-align="right"
				>
					<el-tag
						v-if="data.auditStatus === 0"
						type="info"
						:size="$store.state.global.uiSize"
					>
						未提交
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 1"
						type="warning"
						:size="$store.state.global.uiSize"
					>
						审核中
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 2"
						type="danger"
						:size="$store.state.global.uiSize"
					>
						已驳回
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 3"
						type="success"
						:size="$store.state.global.uiSize"
					>
						审核通过
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 4"
						type="info"
						:size="$store.state.global.uiSize"
					>
						未确认
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 5"
						type="success"
						:size="$store.state.global.uiSize"
					>
						已确认
					</el-tag>
				</el-descriptions-item>
				<el-descriptions-item
					label="质检状态"
					label-align="right"
				>
					<el-tag
						v-if="data.qualityStatus === 0"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						无需质检
					</el-tag>
					<el-tag
						v-if="data.qualityStatus === 1"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未质检
					</el-tag>
					<el-tag
						v-if="data.qualityStatus === 2"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						质检中
					</el-tag>
					<el-tag
						v-if="data.qualityStatus === 3"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						质检完成
					</el-tag>
				</el-descriptions-item>
				<el-descriptions-item
					label="验收状态"
					label-align="right"
				>

					<el-tag
						v-if="data.checkStatus === 0"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未验收
					</el-tag>
					<el-tag
						v-if="data.checkStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						部分验收
					</el-tag>
					<el-tag
						v-if="data.checkStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						全部验收
					</el-tag>
				</el-descriptions-item>
				<el-descriptions-item
					label="工序管理"
					label-align="right"
				>
					<span v-if="data.processManagement === 0">无工序管理</span>
					<span v-if="data.processManagement === 1">开启工序管理</span>
				</el-descriptions-item>
				<el-descriptions-item
					label="生产工艺"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refRouteDetail', 'routeId')"
					>
						{{ data.fz_routeName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="生产数量"
					label-align="right"
				>
					{{ data.quantity }}
				</el-descriptions-item>
				<el-descriptions-item
					label="在加工数量"
					label-align="right"
				>
					{{ data.processingQty }}
				</el-descriptions-item>
				<el-descriptions-item
					label="已生产数量"
					label-align="right"
				>
					{{ data.finishQty }}
				</el-descriptions-item>
				<el-descriptions-item
					label="已验收数量"
					label-align="right"
				>
					{{ data.checkQuality }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
			</el-descriptions>
			<!--      <el-descriptions-->
			<!--        :title="''"-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        border-->
			<!--        :column="1"-->
			<!--        class="column1"-->
			<!--      >-->
			<!--        <el-descriptions-item-->
			<!--          label="质检要求"-->
			<!--          label-class-name="noTopBorder"-->
			<!--          class-name="noTopBorder"-->
			<!--          :label-align="labelAlign"-->
			<!--        >-->
			<!--          {{ data.qualityCheck }}-->
			<!--        </el-descriptions-item>-->
			<!--      </el-descriptions>-->
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<el-tag
						v-for="(file, index) in fileList"
						:key="index"
						@click="previewFile(file)"
					>
						<a href="javascript:">{{ file.name }}</a>
					</el-tag>
				</el-descriptions-item>
			</el-descriptions>
			<!--      <el-descriptions-->
			<!--        :title="''"-->
			<!--        :size="$store.state.global.uiSize"-->
			<!--        border-->
			<!--        :column="1"-->
			<!--        class="column1"-->
			<!--      >-->
			<!--        <el-descriptions-item-->
			<!--          label="设计内容"-->
			<!--          label-class-name="noTopBorder"-->
			<!--          class-name="noTopBorder"-->
			<!--          :label-align="labelAlign"-->
			<!--        >-->
			<!--          <div v-html="data.designContent" />-->
			<!--        </el-descriptions-item>-->
			<!--      </el-descriptions>-->
		</el-card>
		<el-card
			header="业务信息"
			shadow="never"
			class="mycard mt_10"
		>
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<el-descriptions-item
					label="加工状态"
					label-align="right"
				>
					<el-tag
						v-if="data.processStatus === 0"
						type="info"
						:size="$store.state.global.uiSize"
					>
						未加工
					</el-tag>
					<el-tag
						v-if="data.processStatus === 1"
						type="warning"
						:size="$store.state.global.uiSize"
					>
						生产中
					</el-tag>
					<el-tag
						v-if="data.processStatus === 2"
						type="success"
						:size="$store.state.global.uiSize"
					>
						已完成
					</el-tag>
					<el-tag
						v-if="data.processStatus === 3"
						type="danger"
						:size="$store.state.global.uiSize"
					>
						已终止
					</el-tag>
					<el-tag
						v-if="data.processStatus === 4"
						type="info"
						:size="$store.state.global.uiSize"
					>
						已关闭
					</el-tag>
				</el-descriptions-item>

				<!--        <el-descriptions-item-->
				<!--          label="终止原因"-->
				<!--          label-align="right"-->
				<!--        >-->
				<!--          {{ data.reason }}-->
				<!--        </el-descriptions-item>-->

				<el-descriptions-item
					label="原料成本"
					label-align="right"
				>
					{{ data.materialCost }}
				</el-descriptions-item>

				<el-descriptions-item
					label="计件工资"
					label-align="right"
				>
					{{ data.laborCost }}
				</el-descriptions-item>

				<el-descriptions-item
					label="分摊费用"
					label-align="right"
				>
					{{ data.shareCost }}
				</el-descriptions-item>

				<el-descriptions-item
					label="合计成本"
					label-align="right"
				>
					{{ data.totalCost }}
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="物品明细"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:data="tableSonConfig.data"
				:column="tableSonConfig.columns"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
			>
				<template #fz_goodsName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refGdoosDetail', 'goodsId')"
					>
						{{ scope.row.fz_goodsName }}
					</el-link>
				</template>
				<template #source="{ scope }">
					<span v-if="scope.row.source == 0">外购</span>
					<span v-if="scope.row.source == 1">本厂加工</span>
					<span v-if="scope.row.source == 2">委外加工</span>
					<span v-if="scope.row.source == 3">其他</span>
				</template>

				<template #fz_stype="{ scope }">
					<span v-if="scope.row['fz_stype'] === '0'">原材料</span>
					<span v-if="scope.row['fz_stype'] === '1'">半成品</span>
					<span v-if="scope.row['fz_stype'] === '2'">成品</span>
				</template>
				<template #refundStatus="{ scope }">
					<span v-if="scope.row.refundStatus == 0">无需退还</span>
					<span v-if="scope.row.refundStatus == 1">未退还</span>
					<span v-if="scope.row.refundStatus == 2">已退还</span>
				</template>
			</scTable>
		</el-card>
		<el-card
			header="工序步骤"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="refTableProcedure"
				:data="tableProcedureConfig.data"
				:column="tableProcedureConfig.columns"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
				hide-do
				hide-pagination
			>
				<template #fz_procedureName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refProcedureDetail', 'procedureId')"
					>
						{{ scope.row.fz_procedureName }}
					</el-link>
				</template>
				<template #ipqc="{scope}">
					<!--          <span v-if="scope.row.ipqc == 1">开启</span>-->
					<!--          <span v-if="scope.row.ipqc == 0">关闭</span>-->
					<span v-if="scope.row.ipqc === 1" style="color: #67c23a">是</span>
					<span v-else-if="scope.row.ipqc === 0" style="color: #f67777">否</span>
				</template>
			</scTable>
		</el-card>
		<PlanDetail ref="refPlanDetail"/>
		<GoodsDetail ref="refGoodsDetail"/>
		<GdoosDetail ref="refGdoosDetail"/>
		<ProcedureDetail ref="refProcedureDetail"/>
		<RouteDetail ref="refRouteDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from "vue";
import PlanDetail from "@/views/erp/produce/plan/detail";
import GdoosDetail from "@/views/cfg/data/goods/detail";
import ProcedureDetail from "@/views/erp/produce/craft/procedure/detail";
import RouteDetail from "@/views/erp/produce/craft/route/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";

export default defineComponent({
	components: {
		PlanDetail,
		GdoosDetail,
		ProcedureDetail,
		RouteDetail,
		GoodsDetail
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//条款数据
			clauseList: [],
			type: "detail",
			//弹窗宽度
			width: "60%",
			//高度
			height: "500px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "加工单详情",
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				data: [],
				//数据表格列配置
				columns: [
					{
						label: "物品名称",
						prop: "fz_goodsName",
						sortable: "custom",
						fixed: "left",
						width: 180,
					},
					{
						label: "物品编码",
						prop: "fz_goodsCode",
						width: 120,
						sortable: "custom",
					},
					{
						label: "规格型号",
						prop: "fz_goodsSpec",
						sortable: "custom",
						width: 120,
					},
					{
						label: "仓库",
						prop: "fz_depotName",
						width: 110,
						sortable: "custom",
					},

					{
						label: "净需求",
						prop: "demandQuantity",
						width: 110,
						sortable: "custom",
					},
					{
						label: "领料数量",
						prop: "issueQty",
						width: 110,
						sortable: "custom",
					},

					{
						label: "退料数量",
						prop: "refundQty",
						width: 110,
					},

					{
						label: "使用数量",
						prop: "usageQty",
						width: 110,
						edit: "number"
					},
					{
						label: "退料状态",
						prop: "refundStatus",
						width: 110,
					},

					{
						label: "计量单位",
						prop: "unit",
						width: 110,
						sortable: "custom",
					},
					// {
					// 	label: '实际数量',
					// 	prop: 'actualQuantity',
					// 	width: 120,
					// 	sortable: "custom",
					// },
					{
						label: "辅计单位",
						prop: "assistUnit",
						width: 110,
						sortable: "custom",
					},
					{
						label: "换算数量",
						prop: "assistQuantity",
						width: 110,
						sortable: "custom",
					},
					{
						label: "损耗量",
						prop: "lossQuantity",
						disable: true,
						width: 120,
					},
					{
						label: "摘要",
						prop: "remark",
						sortable: "custom",
						width: 180,
					},
				],

			},
			tableProcedureConfig: {
				data: [],
				//数据表格列配置
				columns: [
					{
						label: "工序名称",
						prop: "fz_procedureName",
						width: 180,
						sortable: "custom",
					},
					{
						label: "加工顺序",
						prop: "sequence",
						width: 120,
						sortable: "custom",
					},
					{
						label: "工作组",
						prop: "workGroupName",
						width: 150,
					},
					{
						label: "生产车间",
						prop: "fz_workshopName",
						width: 150,
						edit: "find",
					},
					{
						label: "生产人员",
						prop: "fz_managerName",
						width: 110,
						sortable: "custom",
					},
					{
						label: "设备名称",
						prop: "fz_deviceName",
						sortable: "custom",
						width: 150,
					},
					{
						label: "应加工数量",
						prop: "shouldProcQty",
						width: 120,
					},
					{
						label: "已加工数量",
						prop: "processedAmount",
						width: 120,
					},
					{
						label: "派工数量",
						prop: "dispatchedWorkload",
						width: 120,
					},
					{
						label: "工序要求",
						prop: "requirement",
						sortable: "custom",
						width: 200,
					},
					{
						label: "备注",
						prop: "remark",
						sortable: "custom",
						width: 180,
					},
				],
			},
		});

		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true;
			state.fileList = [];
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.produceProcess.detail.get(data.id);
				if (res.code === 200) {
					state.data = res.data;
					state.tableProcedureConfig.data = res.data.procedureList;
					state.tableSonConfig.data = res.data.sonList;
					//组装文件列表
					getFileList(res.data.files);
				}
			}
			state.loading = false;
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};
		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
		};
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};
		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
			previewFile,
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

