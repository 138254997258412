import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/buy/relation/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	getBuyList: {
		url: `${config.API_URL}/fms/buy/relation/getBuyList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	getSellList: {
		url: `${config.API_URL}/fms/buy/relation/getSellList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	insert: {
		url: `${config.API_URL}/fms/buy/relation/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/buy/relation/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/buy/relation/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/buy/relation/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/fms/buy/relation/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateAudit: {
		url: `${config.API_URL}/fms/buy/relation/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	updatePaymentStatus: {
		url: `${config.API_URL}/fms/buy/relation/updatePaymentStatus`,
		name: "确认付款",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/fms/buy/relation/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
