import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/offer/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/sale/offer/insert`,
		name: "新增销售报价",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/sale/offer/update`,
		name: "修改销售报价",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/sale/offer/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/sale/offer/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateTrackStatus: {
		url: `${config.API_URL}/erp/sale/offer/updateTrackStatus`,
		name: "修改跟踪状态",
		get: async function(id,trackStatus){
			return await http.get(this.url + '?id=' + id + '&trackStatus=' + trackStatus);
		}
	},
}
