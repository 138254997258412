//单页数据打印份数
import result from "element-plus/es/components/result/src/result2";
import {ElMessageBox} from 'element-plus'

var jsonObj = {
	"printerImageProcessingInfo": {
		"printQuantity": 1,
	}
};


//SDK初始化参数
var data = {
	initSdkParam: {
		"fontDir": "",
	}
}

let selectDensity = {};
let selectLabelType = {};
let selectPrintMode = {};
let selectAutoShutDown = null;
let isPrinterConnected = false;
let isPrinterOnline = true;
import {
	getInstance, selectPrinter, initSdk, getAllPrinters,
	setPrinterAutoShutDownTime, startJob, InitDrawingBoard, commitJob,
	DrawLableQrCode, DrawLableLine, DrawLableGraph, DrawLableImage,
	generateImagePreviewImage, DrawLableBarCode, DrawLableText,
	endJob, cancleJob, readCallback
} from './jcPrinterSdk_api_third'
import * as stockBarcode from '@/api/model/erp/stock/stockBarcode'

window.onload = function () {
	// let isConnected = false;
	// let isSupported = true;
	//连接打印服务
	// getInstance(() => {
	// 	isConnected = true;
	// 	console.log('打印服务连接成功');
	// }, () => {
	// 	isSupported = false;
	// 	console.log('当前浏览器不支持打印服务');
	// }, () => {
	// 	isConnected = false;
	// 	console.log('打印服务连接断开');
	// }, () => {
	// 	isPrinterOnline = true;
	// 	console.log('打印连接');
	// }, () => {
	// 	isPrinterOnline = false;
	// 	console.log('打印连接断开');
	// });

	// setInterval(() => {
		// if (isConnected) {
		// 	console.log('打印服务状态：已连接');
		// } else if (!isSupported) {
		// 	console.log('打印服务状态：不支持');
		// } else {
		// 	console.log('打印服务状态：未连接');
		// }

		// if (isPrinterConnected && isPrinterOnline) {
		// 	console.info('打印机连接状态：已连接');
		// } else {
		// 	console.info('打印机连接状态：未连接');
		// }
	// }, 500);

	//初始化浓度、纸张类型、打印模式默认值
	selectDensity.selectedIndex = 2;
	selectLabelType.selectedIndex = 0;
	selectPrintMode.selectedIndex = 0;
	selectPrintMode.selectedIndex = 0;

	setPrinterAutoShutDownTime(4, function (error, data) {
		if (error) {
			return alert(error.message);
		}
		const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
		if (errorCode === 0) {
			console.log('设置成功');
			return alert('设置成功');
		} else {
			return alert(info);
		}

	});
}


//初始化SDK
export function init() {
	initSdk(data.initSdkParam, function (error, data) {
		if (error)
			return alert(error.message);
		const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;

		if (errorCode === 0) {
			console.log('初始化成功');
		} else {
			console.log('初始化失败');
			return alert(info);
		}
	});
}

var allPrinters;

//更新打印机列表
export function getPrinters() {
	getAllPrinters(function (error, data) {
		if (error) {
			return alert(error.message);
		}
		try {
			const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
			if (errorCode === 0) {
				allPrinters = JSON.parse(info);
				const allPrintersName = Object.keys(allPrinters);
				const select = document.getElementById("printers");
				if (allPrintersName.length > 0 && select.options.length > 0) {
					select.removeChild(select.options[0])
				}

				allPrintersName.forEach((name) => {
					const option = document.createElement('option');
					option.value = name;
					option.text = name;
					select.appendChild(option);
				});
			} else {
				ElMessageBox.confirm(
					'请连接打印机',
					'提示', {
						confirmButtonText: 'OK',
						type: 'warning',
					}).catch(() => {});
				// const select = document.getElementById("printers");
				// select.innerHTML = "";
				// const option = document.createElement('option')
				// option.value = '请选择打印机';
				// option.text = '请选择打印机';
				// select.appendChild(option);
			}
		} catch (err) {
		}
	});


}


//选择在线打印机
export function selectOnLinePrinter() {
	isPrinterConnected = false;

	if (allPrinters) {
		const select = document.getElementById("printers");
		const allPrintersName = Object.keys(allPrinters);
		const allPrintersValue = Object.values(allPrinters);
		selectPrinter(allPrintersName[select.selectedIndex], parseInt(allPrintersValue[select.selectedIndex]), function (error, data) {
			if (error) {
				console.log('连接失败')
				return alert(error.message);
			}

			const {errorCode} = JSON.parse(JSON.stringify(data)).resultAck;


			if (errorCode === 0) {
				console.log('连接成功')
				isPrinterConnected = true;
			} else {
				console.log('连接失败')
				alert('连接失败');
			}

		})
	} else {
		console.log('未选择打印机')
	}
}

export function startBatchPrintJobTest(content) {
	if (content == null || content.length == 0) {
		return;
	}
	batchPrintJob(content.data);
}

export function startPrintJobTest(content) {
	let contentArr = [];
	contentArr.push(content);
	batchPrintJob(contentArr);
}


export function previewTest(content) {
	const previewImg = document.querySelector('#preview');
	if (previewImg) {
		previewImg.remove();
	}

	const self = this
	if (content == null) {
		return;
	}

	let contentArr = [];
	contentArr.push(content);
	self.printTag(contentArr, 0, true);

}
export function  batchPrintJob(list){
	const self = this

	if (list == null || list.length === 0) {
		return;
	}

	//打印总份数
	var printQuantity = jsonObj.printerImageProcessingInfo.printQuantity

	let density = 3;
	let labelType = 1;
	let printMode = 1;
	if (selectDensity != null) {
		density = parseInt(selectDensity.value);
	}
	startJob(density, labelType, printMode, list.length * printQuantity, function (error, data) {
		if (error) {
			console.info(error.message);
		}
		const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
		if (errorCode !== 0) {
			if (errorCode === 23) {
				ElMessageBox.confirm(
					'打印机未连接，请检查打印机连接状态' + '\n' +
					'请前往条码记录中进行补打条码',
					'提示', {
						confirmButtonText: 'OK',
						type: 'warning',
					}).catch(() => {});
			}
			console.info('打印出错了:' + info);
		}
		if(errorCode === 0) {
			printTag(list, 0, false);
		}
	});
}

function printTag(list, x, isPreview) {
	console.info('执行了printTag 打印方法');
	//设置画布尺寸
	InitDrawingBoard(list[x].InitDrawingBoardParam, function (error, data) {
		if (error) {
			return alert(error.message);
		}
		const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
		if (errorCode !== 0) {
			return alert(info);
		}

		// 元素控件绘制
		printItem(list, x, list[x].elements, 0, isPreview);
	});
}


export function printItem(list, x, item, i, isPreview) {
	if (i < item.length) {
		switch (item[i].type) {
			case 'text':
				//绘制文本
				DrawLableText(item[i].json, function (error, data) {
					if (error) {
						return alert(error.message);
					}
					const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
					if (errorCode !== 0) {
						return alert(info);
					}

					i++;
					printItem(list, x, item, i, isPreview);
				});
				break;
			case 'qrCode':
				//绘制二维码
				DrawLableQrCode(item[i].json, function (error, data) {
					if (error) {
						return alert(error.message);
					}
					const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
					if (errorCode !== 0) {
						return alert(info);
					}

					i++;
					printItem(list, x, item, i, isPreview);
				});

				break;
			case 'barCode':
				//绘制一维码
				DrawLableBarCode(item[i].json, function (error, data) {
					if (error) {
						return alert(error.message);
					}
					const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
					if (errorCode !== 0) {
						return alert(info);
					}

					i++;
					printItem(list, x, item, i, isPreview);
				});
				break;
			case 'line':
				//绘制线条
				DrawLableLine(item[i].json, function (error, data) {
					if (error) {
						return alert(error.message);
					}
					const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
					if (errorCode !== 0) {
						return alert(info);
					}

					i++;
					printItem(list, x, item, i, isPreview);
				});
				break;
			case 'graph':
				//绘制边框
				DrawLableGraph(item[i].json, function (error, data) {
					if (error) {
						return alert(error.message);
					}
					const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
					if (errorCode !== 0) {
						return alert(info);
					}

					i++;
					printItem(list, x, item, i, isPreview);
				});
				break;
			case 'image':
				//绘制边框
				DrawLableImage(item[i].json, function (error, data) {
					if (error) {
						return alert(error.message);
					}
					const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
					if (errorCode !== 0) {
						return alert(info);
					}

					i++;
					printItem(list, x, item, i, isPreview);
				});
				break;
		}
	} else { //遍历完成，开始打印
		// let index = Object.assign(x);
		// var jsonObj = {
		// 		"printerImageProcessingInfo": {
		// 		"width": width,
		// 		"height": height,
		// 		"margin": [0,0,0,0],
		// 		"printQuantity":1,
		// 		"epc":"1234"
		// 	}
		// };

		console.log('是否预览' + isPreview);
		if (isPreview) {
			//B32和T8等300点机型倍率填12，其他机器填8，默认值8
			generateImagePreviewImage(8, function (error, data) {
				if (error) {
					return alert(error.message);
				}

				const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
				if (errorCode !== 0) {
					return alert(info);
				}

				const imageData = "data:image/jpeg;base64," + JSON.parse(info).ImageData;
				const img = new Image();
				img.src = imageData;
				img.id = 'preview';
				document.body.appendChild(img);

			});

			return;
		}

		commitJob(null, JSON.stringify(jsonObj), function (error, data) {
			if (error) {
				return alert(error.message);
			}

			const {errorCode, info, printQuantity, onPrintPageCompleted} = JSON.parse(JSON.stringify(data)).resultAck;
			var resultInfo = "commitJob ok";
			//异常导致打印终止
			if (errorCode !== 0) {
				return alert(info);
			}


			//回调与传参定义相反，考虑接入较多客户暂不修改为一致
			//var jsonObj = {		"printerImageProcessingInfo": {"printQuantity":2,}}; 提交任务的打印份数
			//printQuantity 回调打印页数的进度（一次commitJob提交为1页，内容可以不一样）
			//onPrintPageCompleted 回调打印份数的进度（一个commit的内容打印多张，内容一样）

			//回调页码为数据总长度且回调打印份数数据等于当前页需要打印的份数数据时，结束打印任务
			if (printQuantity == list.length && onPrintPageCompleted == jsonObj.printerImageProcessingInfo.printQuantity) {
				//结束打印任务
				endJob(function (error, data) {
					if (error) {
						alert(error.message);
					} else {
						var arrParse = JSON.parse(JSON.stringify(data));
						if (String(arrParse.resultAck.info).indexOf("endJob ok") > -1) {

						}
					}

				});
				return;
			}

			//当前页数据提交完成，但是未完所有页数据提交，继续发送下一页数据
			if (String(info).indexOf(resultInfo) > -1 && x < list.length - 1) {
				//数据提交成功，数据下标+1
				console.log("发送下一页打印数据： ")
				x++;
				printTag(list, x);


			}

		});
	}
}


export function printerDetails(printModel) {
	switch (printModel) {
		case 'B3S':
			alert('B3S支持范围说明:\n打印模式支持：热敏\n打印浓度范围：1-5，建议值为3\n打印纸张类型支持：间隙纸、黑标纸、连续纸、透明纸');
			break;
		case 'B1':
			alert('B1支持范围说明:\n打印模式支持：热敏\n打印浓度范围：1-5，建议值为3\n打印纸张类型支持：间隙纸、黑标纸、透明纸');
			break;
		case 'B203':
			alert('B203支持范围说明:\n打印模式支持：热敏\n打印浓度范围：1-5，建议值为3\n打印纸张类型支持：间隙纸、黑标纸、透明纸');
			break;
		case 'B21':
			alert('B21支持范围说明:\n打印模式支持：热敏\n打印浓度范围：1-5，建议值为3\n打印纸张类型支持：间隙纸、黑标纸、连续纸、透明纸');
			break;
		case 'D11/D101/D110/B16':
			alert('D11/D101/D110/B16支持范围说明:\n打印模式支持：热敏\n打印浓度范围：1-3，建议值为2\n打印纸张类型支持：间隙纸、透明纸');
			break;
		case 'B32':
			alert('B32支持范围说明:\n打印模式支持：热转印\n打印浓度范围：1-15，建议值为8\n打印纸张类型支持：间隙纸、透明纸');
			break;
		case 'Z401':
			alert('Z401支持范围说明:\n打印模式支持：热转印\n打印浓度范围：1-15，建议值为8\n打印纸张类型支持：间隙纸、透明纸');
			break;
		case 'B50/B50W':
			alert('B50/B50W支持范围说明:\n打印模式支持：热转印\n打印浓度范围：1-15，建议值为8\n打印纸张类型支持：间隙纸');
			break;
		case 'B18':
			alert('B18支持范围说明:\n打印模式支持：热转印\n打印浓度范围：1-3，建议值为2\n打印纸张类型支持：间隙纸');
			break;
		default:
			break;
	}
}

