<template>
  <div
    v-loading="isSave"
    class="columnSet"
  >
    <div class="list">
      <el-table
        :data="list"
        :height="300"
        row-key="label"
        :size="$store.state.global.uiSize"
      >
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template #default="scope">
            <el-tag
              v-if="item.prop === 'drag' && !scope.row.fixed"
              class="move"
              style="cursor: move;"
            >
              <el-icon-d-caret style="width: 1em; height: 1em;" />
            </el-tag>
            <span v-if="item.prop === 'label'">
              {{ scope.row.label }}
            </span>
            <!--            <el-input
              v-if="item.prop == 'label'"
              v-model="scope.row.label"
              :size="$CONFIG.UI_SIZE"
              clearable
            />-->
            <el-switch
              v-if="item.prop === 'hide'"
              v-model="scope.row.hide"
              :active-value="false"
              :inactive-value="true"
              :size="$store.state.global.uiSize"
            />
            <el-input
              v-if="item.prop === 'width'"
              v-model="scope.row.width"
              :size="$store.state.global.uiSize"
              maxlength="4"
			  @blur="(e) => widthBlur(e,scope.row)"
			  @input="(val) => widthInput(val,scope.row)"
            />
            <el-switch
              v-if="item.prop === 'sortable'"
              v-model="scope.row.sortable"
              :active-value="getActiveValue(scope.row)"
              :inactive-value="false"
              :size="$store.state.global.uiSize"
            />
            <el-radio-group
              v-if="item.prop === 'fixed'"
              v-model="scope.row.fixed"
              :size="$store.state.global.uiSize"
            >
              <el-radio-button :value="false">
                不固定
              </el-radio-button>
              <el-radio-button value="left" @change="leftFixed(scope.row)">
                左侧
              </el-radio-button>
              <el-radio-button value="right" @change="rightFixed(scope.row)">
                右侧
              </el-radio-button>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="setting-column__bottom">
      <el-popconfirm
        :title="$TIPS.COLUMN_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="backDefaul"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            初始化
          </el-button>
        </template>
      </el-popconfirm>

      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        @click="save"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
	import Sortable from 'sortablejs'
	import {resetPositiveNum} from "@/utils/validate";

	export default {
		components: {
			// eslint-disable-next-line vue/no-unused-components
			Sortable,
		},
		props: {
			column: { type: Object, default: () => {} }
		},
		emits: ['userChange','back','save'],
		data() {
			return {
				isSave: false,
				isDisabled: true,
				list: JSON.parse(JSON.stringify(this.column||[])),
				columns: [
					{
						label: '拖动顺序',
						prop: 'drag',
						width: 80,
					},
					{
						label: '列名',
						prop: 'label',
						width: 120,
					},
					{
						label: '显示',
						prop: 'hide',
						width: 70,
					},
					{
						label: '宽度',
						prop: 'width',
						width: 80,
					},
					{
						label: '排序',
						prop: 'sortable',
						width: 70,
					},
					{
						label: '固定',
						prop: 'fixed',
					},
				],
			}
		},
		watch:{
			list: {
				handler(){
					this.$emit('userChange', this.list)
				},
				deep: true
			}
		},
		mounted() {
			this.list.length > 0 && this.rowDrop()
		},
		methods: {
			rowDrop(){
				const _this = this
				const tbody = document.querySelector('.columnSet .el-table__body-wrapper tbody')
				Sortable.create(tbody, {
					handle: ".move",
					animation: 300,
					ghostClass: "ghost",
					onEnd({ newIndex, oldIndex }) {
						const currRow = _this.list.splice(oldIndex, 1)[0]
						_this.list.splice(newIndex, 0, currRow)
					}
				})
			},
			widthBlur(e,row){
				const val = e.target.value
				if(val === ''){
					row['width'] = 100
				}
			},
			widthInput(val,row){
				row['width'] = resetPositiveNum(val)
			},
			backDefaul(){
				this.$emit('back', this.list)
			},
			save(){
				this.$emit('save', this.list)
			},
      rightFixed(row){
        const rightList = this.list.filter(item=>item.fixed == 'right')
        if(rightList && rightList.length > 3){
          this.$baseMessage('最多只能三个固定右侧!','warning')
          row['fixed'] = false
        }
      },
      leftFixed(row){
        const leftList = this.list.filter(item=>item.fixed == 'left')
        if(leftList && leftList.length > 3){
          this.$baseMessage('最多只能三个固定左侧!','warning')
          row['fixed'] = false
        }
      },
			getActiveValue(row){
				if(row.sortable === true || row.sortable == 'custom'){
					return row.sortable
				}else {
					return true
				}
			}
		}
	}
</script>

<style scoped>
	.setting-column {}
	.setting-column__title {border-bottom: 1px solid #EBEEF5;padding-bottom:15px;}
	.setting-column__title span {display: inline-block;font-weight: bold;color: #909399;font-size: 12px;}
	.setting-column__title span.move_b {width: 30px;margin-right:15px;}
	.setting-column__title span.show_b {width: 60px;}
	.setting-column__title span.name_b {width: 140px;}
	.setting-column__title span.width_b {width: 60px;margin-right:15px;}
	.setting-column__title span.sortable_b {width: 60px;}
	.setting-column__title span.fixed_b {width: 60px;}

	.setting-column__list {max-height:314px;overflow: auto;}
	.setting-column__list li {list-style: none;margin:10px 0;display: flex;align-items: center;}
	.setting-column__list li>span {display: inline-block;font-size: 12px;}
	.setting-column__list li span.move_b {width: 30px;margin-right:15px;}
	.setting-column__list li span.show_b {width: 60px;}
	.setting-column__list li span.name_b {width: 140px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;cursor:default;}
	.setting-column__list li span.width_b {width: 60px;margin-right:15px;}
	.setting-column__list li span.sortable_b {width: 60px;}
	.setting-column__list li span.fixed_b {width: 60px;}
	.setting-column__list li.ghost {opacity: 0.3;}

	.setting-column__bottom {padding-top:10px;text-align: right;}

	.dark .setting-column__title {border-color: var(--el-border-color-light);}
	.dark .setting-column__bottom {border-color: var(--el-border-color-light);}
</style>
