<!--选择预付款单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'450px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <el-container
              class="mainPanel"
              :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-button
                    v-if="showAdd"
                    ref="refPopAddBtn"
                    type="primary"
                    :size="$store.state.global.uiSize"
                    @click="handleEdit(1, 'refPopAddBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                    v-if="showEdit"
                    ref="refEditBtn"
                    :type="'primary'"
                    :size="$store.state.global.uiSize"
                    :disabled="isDisabled"
                    @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>
                  <el-link
                    v-if="multiple"
                    type="primary"
                    :disabled="selections.length === 0"
                    @click="addToRight"
                  >
                    添加到已选<el-icon><el-icon-right /></el-icon>
                  </el-link>
                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  remote-sort
									:ref-query-form="refFormBar"
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
                  @current-change="currentChange"
                  @row-dblclick="dblClick"
                >
                  <template #orderNo="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refDetail')"
                    >
                      {{ scope.row.orderNo }}
                    </el-link>
                  </template>
                  <template #fz_supplierName="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refSupplierDetail', 'supplierId')"
                    >
                      {{ scope.row.fz_supplierName }}
                    </el-link>
                  </template>
                  <template #fz_companyName="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refCompanyDetail', 'companyId')"
                    >
                      {{ scope.row.fz_companyName }}
                    </el-link>
                  </template>
                  <template #fz_purOrderNo="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refPurOrderDetail', 'purOrderId')"
                    >
                      {{ scope.row.fz_purOrderNo }}
                    </el-link>
                  </template>
                  <template #supplement="{ scope }">
                    {{ scope.row.supplement === 1 ? '是' : '否' }}
                  </template>
                  <template #paymentMethod="{ scope }">
                    <span v-if="scope.row.paymentMethod === 1">现金</span>
                    <span v-if="scope.row.paymentMethod === 2">转账</span>
                    <span v-if="scope.row.paymentMethod === 3">支票</span>
                    <span v-if="scope.row.paymentMethod === 4">其他</span>
                  </template>
                  <template #auditStatus="{ scope }">
                    <el-tag
                      v-if="scope.row.auditStatus === 0"
                      type="info"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      未提交
                    </el-tag>
                    <el-tag
                      v-if="scope.row.auditStatus === 1"
                      type="warning"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
											审核中
                    </el-tag>
                    <el-tag
                      v-if="scope.row.auditStatus === 2"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      已驳回
                    </el-tag>
                    <el-tag
                      v-if="scope.row.auditStatus === 3"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      审核通过
                    </el-tag>
										<el-tag
											v-if="scope.row.auditStatus === 4"
											type="info"
											class="myTag"
											:size="$store.state.global.uiSize"
										>
											未确认
										</el-tag>
										<el-tag
											v-if="scope.row.auditStatus === 5"
											type="success"
											class="myTag"
											:size="$store.state.global.uiSize"
										>
											已确认
										</el-tag>
                  </template>
                  <template #useStatus="{ scope }">
                    <el-tag
                      v-if="scope.row.useStatus === 0"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      未核销
                    </el-tag>
                    <el-tag
                      v-if="scope.row.useStatus === 1"
                      type="warning"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      部分核销
                    </el-tag>
                    <el-tag
                      v-if="scope.row.useStatus === 2"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      全部核销
                    </el-tag>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
    <!--新增弹窗-->
    <Edit
      ref="refEdit"
      @fetch-data="fetchData"
    />
    <detail ref="refDetail" />
    <SupplierDetail ref="refSupplierDetail" />
    <PurOrderDetail ref="refPurOrderDetail" />
    <CompanyDetail ref="refCompanyDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
} from 'vue'
import Edit from './edit'
import Detail from "@/views/fms/sell/advreceipt/detail";
import SupplierDetail from "@/views/cfg/data/supplier/detail";
import PurOrderDetail from "@/views/erp/purchase/order/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
export default defineComponent ({
	name: "SelectBuyAdvpayment",
	components: {
		Edit,
		Detail,
		SupplierDetail,
		PurOrderDetail,
		CompanyDetail,
	},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择预付款单' },
		//菜单编码
		menuCode: { type: String, default: '' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar: null,
			paneSize1: 30,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//显示编辑那妞
			showEdit: false,
			//隐藏搜索表单
			hideForm: true,
			//编辑按钮状态
			isDisabled: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '55%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '预付单号',
					prop: 'orderNo',
					width: 200,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.buyAdvpayment.getList,
				//数据表格列配置
				params:{},
				columns: [
					{
						label: '预付单号',
						prop: 'orderNo',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '开户行',
						prop: 'bank',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '银行账号',
						prop: 'accountNumber',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '联行号',
						prop: 'bankNo',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '预付金额',
						prop: 'advpayAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退款金额',
						prop: 'refundAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已核销金额',
						prop: 'useAmount',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '剩余金额',
						prop: 'surAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '审核状态',
						prop: 'auditStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '核销状态',
						prop: 'useStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '付款时间',
						prop: 'paymentTime',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '付款方式',
						prop: 'paymentMethod',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '付款账户',
						prop: 'fz_paymentAccountName',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '是否补单',
						prop: 'supplement',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '创建日期',
						prop: 'creationDate',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '采购订单',
						prop: 'fz_purOrderNo',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '单位抬头',
						prop: 'fz_companyName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					//文本框
					{
						label: '预付单号',
						span: 8,
						prop: 'orderNo',
						type: 'input',
						max: 32,
						default: true,
					},
					{
						label: '供应商',
						span: 8,
						default: true,
						prop: 'fz_supplierName',
						type: 'popSupplier',
						fields: ['supplierId', 'fz_supplierName'],
					},
					{
						label: '开户行',
						span: 8,
						prop: 'bank',
						type: 'input',
						max: 255,
						default: true,
					},
					{
						label: '银行账号',
						span: 8,
						prop: 'accountNumber',
						type: 'number',
						max: 32,
					},
					{
						label: '联行号',
						span: 8,
						prop: 'bankNo',
						type: 'number',
						max: 32,
					},
					{
						label: '预付金额',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_advpayAmount0',
								max: 26,
								append: '元',
							},
							{
								prop: 'fz_advpayAmount1',
								max: 26,
								append: '元',
							},
						]
					},
					{
						label: '退款金额',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_refundAmount0',
								max: 26,
								append: '元',
							},
							{
								prop: 'fz_refundAmount1',
								max: 26,
								append: '元',
							},
						]
					},
					{
						label: '已核销金额',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_useAmount0',
								max: 26,
								append: '元',
							},
							{
								prop: 'fz_useAmount1',
								max: 26,
								append: '元',
							},
						]
					},
					{
						label: '剩余金额',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_surAmount0',
								max: 26,
								append: '元',
							},
							{
								prop: 'fz_surAmount1',
								max: 26,
								append: '元',
							},
						]
					},
					{
						label: '审核状态',
						span: 8,
						prop: 'auditStatus',
						short: 12,
						type: 'select',
						data: [
							{
								label: '未提交',
								value: 0,
							},
							{
								label: '审核中',
								value: 1,
							},
							{
								label: '已驳回',
								value: 2,
							},
							{
								label: '审核通过',
								value: 3,
							},
						]
					},
					{
						label: '核销状态',
						span: 8,
						prop: 'useStatus',
						short: 12,
						type: 'select',
						data: [
							{
								label: '未核销',
								value: 0,
							},
							{
								label: '部分核销',
								value: 1,
							},
							{
								label: '完全核销',
								value: 2,
							},
						]
					},
					{
						label: '付款时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_paymentTime0',
							},
							{
								prop: 'fz_paymentTime1',
							},
						],
					},
					{
						label: '付款方式',
						span: 8,
						prop: 'paymentMethod',
						type: 'select',
						short: 12,
						data: [
							{
								label: '现金',
								value: 0,
							},
							{
								label: '转账',
								value: 1,
							},
							{
								label: '支票',
								value: 2,
							},
							{
								label: '其他',
								value: 2,
							},
						]
					},
					{
						label: '付款账户',
						span: 8,
						prop: 'fz_paymentAccountName',
						type: 'popAccount',
						fields: ['paymentAccountId', 'fz_paymentAccountName'],
					},
					{
						label: '是否补单',
						span: 8,
						prop: 'supplement',
						type: 'select',
						short: 12,
						data: [
							{
								label: '否',
								value: 0,
							},
							{
								label: '是',
								value: 1,
							},
						]
					},
					{
						label: '创建日期',
						span: 8,
						type: 'dateRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_creationDate0',
							},
							{
								prop: 'fz_creationDate1',
							},
						],
					},
					{
						label: '采购订单号',
						span: 8,
						prop: 'fz_purOrderNo',
						max: 32,
						type: 'input',
					},
					{
						label: '单位抬头',
						span: 8,
						prop: 'fz_companyName',
						type: 'popCompany',
						short: 12,
						fields: ['companyId', 'fz_companyName'],
					},
					{
						label: '备注',
						span: 8,
						prop: 'remark',
						type: 'input',
						max: 255,
					},
					{
						label: '操作用户',
						span: 8,
						prop: 'fz_operateName',
						type: 'popUser',
						short: 12,
            max:32,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_addTime0',
							},
							{
								prop: 'fz_addTime1',
							},
						],
					},
				]
			},
		})

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			state.loading = false
			if(data) {
				Object.assign(state.tableConfig.params, data)
			}
			getShowAdd()
			getShowEdit()
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.FMS_BUY_ADVPAYMENT,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.FMS_BUY_ADVPAYMENT,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		/*------------------------按钮操作start-------------------------------*/
		//弹窗新增编辑
		const handleEdit = (type, ref) => {
			let param = {
				menuCode: props.menuCode
			}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param, type)
		}
		/*------------------------按钮操作end-------------------------------*/

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			handleDetail,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
		}
	},
})
</script>
