<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="width"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <div class="syForm">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="parent"
          label="父级"
        >
<!--          <el-input-->
<!--            v-model="form.parentName"-->
<!--            readonly-->
<!--          />-->
<!--			$API.classify.findAll.get({ businessType: 'cfg_data_subject' })-->
			<sc-table-select
				v-model="form.parent"
				:api-obj="$API.classify.findAll"
				:table-width="500"
				hide-pagination
				:params="{ businessType: 'cfg_data_subject' }"
				:props="{ label: 'name', value: 'id', keyword: 'name' }"
				style="width: 100%;"
				@change="(val) => handleSelChange(val, 'parentId')"
			>
				<el-table-column
					prop="name"
					label="名称"
				/>
			</sc-table-select>
        </el-form-item>
        <el-form-item
          prop="name"
          label="分类名称"
        >
          <el-input
            v-model="form.name"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
		  <el-form-item
			  prop="code"
			  label="分类编码"
		  >
			  <el-input
				  v-model="form.code"
				  clearable
				  placeholder="请输入"
			  />
		  </el-form-item>
      </el-form>
    </div>
    <template #footer>
		<el-popconfirm
			:visible="visibles"
			:title="$TIPS.SAVE"
			confirm-button-text="确定"
			cancel-button-text="取消"
			@cancel="visibles = false"
			@confirm="handleConfirm"
		>
			<template #reference>
				<el-button
					type="primary"
					:size="$store.state.global.uiSize"
					:loading="subLoading"
					@click="valiForm"
				>
					提交
				</el-button>
			</template>
		</el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_CLOSE"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button :size="$store.state.global.uiSize">
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button :size="$store.state.global.uiSize">-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
export default defineComponent({
	name: "ClassifyEdit",
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
			visibles: false,
			//编码验证消息
			codeMsg: '',
			//加载动画
			loading: false,
			//触发元素
			triggerDom: {},
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				// code: [
				// 	{required: true, trigger: 'blur', message: '必填'},
				// 	{validator: validateCode, trigger: 'blur'},
				// ],
				name: [
					{required: true, trigger: 'blur', message: '必填'},
				],
			},
		})
		const showDialog = async (row) => {
			state.visible = true
			nextTick(async () => {
				console.log(row)
				if(row.number){
					row.code = row.number
				}
				//编辑
				if(row) {
					if (row.id) {
						state.title = "编辑"
						// const res = await proxy.$API.subject.detailClassfy.get(row.id)
						// if(res.code === 200 && res.data !== null){
						// 	if(res.data){
						// 		res.data.parent = {
						// 			name:res.data.fz_parentName,
						// 			id:res.data.parentId,
						// 		}
						// 		state.form = Object.assign({}, res.data)
						// 	}
						// }
						row.parent = {
							name:row.fz_parentName,
							id:row.parentId,
						}
						state.form = Object.assign({}, row)
						state.orgForm = Object.assign({}, row)
						if (row.parentId !== 0) {
							await getParentNode(row.parentId)
						}
					} else {
						// if (JSON.stringify(row) == '{}') { //新增
						if (!row.parentId) { //新增顶级模块
							state.title = "新增"
							Object.assign(state.form, row)
							state.orgForm = Object.assign({}, state.form)
						} else {
							state.title = "新增子级"
							row.parent = {
								name:row.parentName,
								id:row.parentId,
							}
							state.form = Object.assign({}, row)
							state.orgForm = Object.assign({}, row)
							await getParentNode(row.parentId)
						}
					}
				}
			})
		}

		//获取父级节点信息
		const getParentNode = async (id) => {
			const res = await proxy.$API.classify.getParent.get(id)
			if(res.code === 200){
				console.log(res.data)
				state.form.parentId = res.data.id
				state.form.parentName = res.data.name
				state.orgForm.parentId = res.data.id
				state.orgForm.parentName = res.data.name
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//关闭弹窗前确认
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}

		const handleConfirm = () => {
			save()
			state.visibles = false
		}
		const valiForm = () => {
			nextTick(()=>{
				state.formRef.validate((valid)=>{
					if(valid){
						state.visibles = true
					}else {
						state.visibles = false
					}
				})
			})
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate(async(valid) => {
				console.log(valid)
				if(valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res = {}
						if(state.form.id){
							//state.form没有id就是新增，有id就是编辑
							res = await proxy.$API.classify.update.put(state.form)
						}else{
							res = await proxy.$API.classify.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					// })
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state.visibles = false
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.classify.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['formRef'].validateField('code')
				}
			}
		}//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					state.form[field] = data.id
				}
			}
		}
		return {
			...toRefs(state),
			handleCodeBlur,
			handleSelChange,
			save,
			valiForm,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm
		}
	},
})
</script>

<style scoped>

</style>
