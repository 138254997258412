import config from "@/config"
import http from "@/utils/request"

export default {
	getApplyList: {
		url: `${config.API_URL}/erp/purchase/apply/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delApply: {
		url: `${config.API_URL}/erp/purchase/apply/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailApply: {
		url: `${config.API_URL}/erp/purchase/apply/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	insertApply: {
		url: `${config.API_URL}/erp/purchase/apply/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateApply: {
		url: `${config.API_URL}/erp/purchase/apply/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/purchase/apply/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/erp/purchase/apply/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},

	getApplyGoodsList: {
		url: `${config.API_URL}/erp/purchase/applyGoods/getList`,
		name: "根据采购申请id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getListByApplyId: {
		url: `${config.API_URL}/erp/purchase/applyGoods/getListByApplyId`,
		name: "根据采购申请id查询物品明细",
		get: async function (applyId) {
			return await http.get(this.url + '?applyId=' + applyId);
		}
	},

	getApplyGoodsListByMap: {
		url: `${config.API_URL}/erp/purchase/applyGoods/getApplyGoodsListByMap`,
		name: "根据采购申请id查询物品明细",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},



	updateTrackStatus: {
		url: `${config.API_URL}/erp/purchase/apply/updateTrackStatus`,
		name: "修改跟踪状态",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	selectByIds:{
		url: `${config.API_URL}/erp/purchase/apply/selectByIds`,
		name: "根据id查询采购申请",
		get: async function(id){
			return await http.get(this.url + '?ids=' + id);
		}
	},

	updateFlag: {
		url: `${config.API_URL}/erp/purchase/applyGoods/updateFlag1`,
		name: "修改已结转状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	returnProcess: {
		url: `${config.API_URL}/erp/purchase/apply/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	}


}
