<!--选择采购退货单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
                    <div class="left-panel">
                      <el-link
                        v-if="multiple"
                        type="primary"
                        :disabled="selections.length === 0"
                        @click="addToRight"
                      >
                        添加到已选
                        <el-icon><el-icon-right /></el-icon>
                      </el-link>
                    </div>
                    <div class="right-panel">
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @current-change="currentChange"
                      @row-dblclick="dblClick"
                      @row-click="handleRowClick"
                      @data-change="fatherDataChange"
                    >
											<template #refundNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refDetail')"
												>
													{{ scope.row.refundNo }}
												</el-link>
											</template>
											<template #fz_purOrderNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refPurOrderDetail', 'purOrderId')"
												>
													{{ scope.row.fz_purOrderNo }}
												</el-link>
											</template>
											<template #fz_supplierName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refSupplierDetail', 'supplierId')"
												>
													{{ scope.row.fz_supplierName }}
												</el-link>
											</template>
											<template #fz_companyName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refCompanyDetail', 'companyId')"
												>
													{{ scope.row.fz_companyName }}
												</el-link>
											</template>
											<template #supplement="{ scope }">
												{{ scope.row.supplement == 1? '是':'否' }}
											</template>
											<template #smallint="{ scope }">
												{{ scope.row.smallint == 1? '含税':'未税' }}
											</template>
											<template #refundType="{ scope }">
												<span v-if="scope.row.refundType == 0">换货补发</span>
												<span v-if="scope.row.refundType == 1">退货抵扣</span>
												<span v-if="scope.row.refundType == 2">退货退款</span>
												<span v-if="scope.row.refundType == 3">未收货退款</span>
											</template>
											<template #paymentMethod="{ scope }">
												<span v-if="scope.row.paymentMethod == 1">现金</span>
												<span v-if="scope.row.paymentMethod == 2">转账</span>
												<span v-if="scope.row.paymentMethod == 3">支票</span>
												<span v-if="scope.row.paymentMethod == 4">其他</span>
											</template>
											<!--退货状态-->
											<template #refundStatus="{ scope }">
												<el-tag
													v-if="scope.row.refundStatus === 0"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 3"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已终止
												</el-tag>
											</template>
											<!--出库状态-->
											<template #outStatus="{ scope }">
												<el-tag
													v-if="scope.row.outStatus === 0"
													style="color: #666"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未出库
												</el-tag>
												<el-tag
													v-if="scope.row.outStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													无需出库
												</el-tag>
												<el-tag
													v-if="scope.row.outStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已出库
												</el-tag>
											</template>
											<!--审核状态-->
											<template #auditStatus="{ scope }">
												<el-tag
													v-if="scope.row.auditStatus === -1"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													待编辑
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 0"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未提交
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													审核中
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 2"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已驳回
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 3"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													审核通过
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 4"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未确认
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 5"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已确认
												</el-tag>
											</template>
											<!--自定义字段-->
											<template v-for="item in dataList" :key="item.value" #[item.value]="{ scope }">
												{{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}
											</template>
											<!--收款状态-->
											<template #receiptStatus="{ scope }">
												<el-tag
													v-if="scope.row.receiptStatus === 0"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未收款
												</el-tag>
												<el-tag
													v-if="scope.row.receiptStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分收款
												</el-tag>
												<el-tag
													v-if="scope.row.receiptStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部收款
												</el-tag>
											</template>
                    </scTable>
                  </el-main>
                  <sy-switch-btn
                    :text="'物品明细'"
                    :opened="leftShow"
                    bottom
                    @show-left="handleShowLeft"
                  />
                </el-container>
              </pane>
              <!--已选数据-->
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="50"
                min-size="20"
              >
                <el-container
                  class="sonCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <el-main class="nopadding">
                    <!--明细-->
                    <scTable
                      ref="tableSon"
                      :api-obj="tableSonConfig.apiObj"
                      :column="tableSonConfig.columns"
                      :params="tableSonConfig.params"
                      row-key="id"
                      border
                      remote-sort
                    >
											<template #fz_goodsName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
												>
													{{ scope.row.fz_goodsName }}
												</el-link>
											</template>
											<template #outStatus="{ scope }">
												<el-tag
													v-if="scope.row.outStatus === 0"
													class="myTag"
													style="color: #666"
													:size="$store.state.global.uiSize"
												>
													未出库
												</el-tag>
												<el-tag
													v-if="scope.row.outStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分出库
												</el-tag>
												<el-tag
													v-if="scope.row.outStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部出库
												</el-tag>
											</template>
											<!--自定义字段-->
											<template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
												{{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}
											</template>
                    </scTable>
                  </el-main>
                </el-container>
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <SupplierDetail ref="refSupplierDetail" />
    <CompanyDetail ref="refCompanyDetail" />
    <PurOrderDetail ref="refPurOrderDetail" />
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick, onMounted,
} from 'vue'
import Detail from "@/views/erp/purchase/order/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import SupplierDetail from "@/views/cfg/data/supplier/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import PurOrderDetail from "@/views/erp/purchase/order/detail";
export default defineComponent ({
	name: "SelectPurRefund",
	components: {
		Detail,
		GoodsDetail,
		SupplierDetail,
		CompanyDetail,
		PurOrderDetail,
	},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择采购退货单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '订单编号',
					prop: 'orderNo',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {
				pageNum: 1,
				pageSize: 1000,
			},
			//暂存选中行
			curRow: {},
			//查询表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '订单编号',
						prop: 'refundNo',
						type: 'input',
						default: true,
					},
					{
						label: '采购订单',
						prop: 'fz_purOrderNo',
						type: 'input',
						default: true,
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						type: 'popSupplier',
						default: true,
						fields: ['supplierId', 'fz_supplierName']
					},
					{
						label: '审核状态',
						prop: 'auditStatus',
						// short: 12,
						type: 'select',
						data: [
							{value: 0, label: '未提交'},
							{value: 1, label: '审核中'},
							{value: 2, label: '已驳回'},
							{value: 3, label: '审核通过'},
							{value: 4, label: '未确认'},
							{value: 5, label: '已确认'},
						]
					},
					{
						label: '业务员',
						prop: 'fz_salesmanName',
						type: 'popUser',
						default: true,
						// short: 12,
						fields: ['salesmanId', 'fz_salesmanName'],
					},
					// {
					// 	label: '业务部门',
					// 	prop: 'fz_departmentName',
					// 	type: 'popDepartment',
					// 	fields: ['departmentId', 'fz_departmentName'],
					// },
					{
						label: '退货日期',
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_refundDate0',
							},
							{
								prop: 'fz_refundDate1',
							},
						],
					},
					// {
					// 	label: '是否补单',
					// 	prop: 'supplement',
					// 	type: 'select',
					// 	// short: 12,
					// 	data: [
					// 		{
					// 			label: '是',
					// 			value: 1,
					// 		},
					// 		{
					// 			label: '否',
					// 			value: 0,
					// 		},
					// 	],
					// },
					// {
					// 	label: '创建日期',
					// 	type: 'dateRange',
					// 	//开始结束日期字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_creationDate0',
					// 		},
					// 		{
					// 			prop: 'fz_creationDate1',
					// 		},
					// 	],
					// },
					// {
					// 	label: '整单折扣',
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_discount0',
					// 			max: 5,
					// 			append: '%'
					// 		},
					// 		{
					// 			prop: 'fz_discount1',
					// 			max: 5,
					// 			append: '%'
					// 		}
					// 	],
					// },
					{
						label: '退货类型',
						prop: 'refundType',
						type: 'select',
						//下拉选项
						data: [
							{value: 0, label: '换货补发'},
							{value: 1, label: '退货抵扣'},
							{value: 2, label: '退货退款'},
							{value: 3, label: '未收货退款'},
						]
					},
					// {
					// 	label: '收款方式',
					// 	prop: 'paymentMethod',
					// 	type: 'select',
					// 	//下拉选项
					// 	data: [
					// 		{value: '1', label: '现金'},
					// 		{value: '2', label: '转账'},
					// 		{value: '3', label: '支票'},
					// 		{value: '4', label: '其他'},
					// 	]
					// },
					// {
					// 	label: '收款账户',
					// 	prop: 'refundAccount',
					// 	type: 'popAccount',
					// },

					// {
					// 	label: '共享人',
					// 	prop: 'fz_shareUserName',
					// 	type: 'popUser',
					// 	fields: ['shareUser', 'fz_shareUserName'],
					// },
					{
						label: '单位抬头',
						prop: 'company',
						type: 'popCompany',
					},
					// {
					// 	label: '退货状态',
					// 	prop: 'refundStatus',
					// 	type: 'select',
					// 	data: [
					// 		{value: 0, label: '未发货'},
					// 		{value: 1, label: '部分发货'},
					// 		{value: 2, label: '全部发货'},
					// 		{value: 3, label: '已终止'},
					// 	]
					// },
					{
						label: '出库状态',
						prop: 'outStatus',
						type: 'select',
						data: [
							{value: 0, label: '未出库'},
							{value: 1, label: '无需出库'},
							{value: 2, label: '已出库'},
						]
					},
					// {
					// 	label: '终止原因',
					// 	prop: 'reason',
					// 	type: 'input',
					// 	max: 100,
					// },
					{
						label: '收款状态',
						prop: 'receiptStatus',
						type: 'select',
						data: [
							{value: 0, label: '未收款'},
							{value: 1, label: '部分收款'},
							{value: 2, label: '全部收款'},
						]
					},
					{
						label: '合计金额',
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'totalAmount0',
								max: 10,
							},
							{
								prop: 'totalAmount1',
								max: 10,
							}
						],
					},
					// {
					// 	label: '减免金额',
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'reductionAmount0',
					// 			max: 10,
					// 		},
					// 		{
					// 			prop: 'reductionAmount1',
					// 			max: 10,
					// 		}
					// 	],
					// },
					// {
					// 	label: '应收金额',
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'receivableAmount0',
					// 			max: 10,
					// 		},
					// 		{
					// 			prop: 'receivableAmount1',
					// 			max: 10,
					// 		}
					// 	],
					// },
					// {
					// 	label: '已收金额',
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'receiptAmount0',
					// 			max: 10,
					// 		},
					// 		{
					// 			prop: 'receiptAmount1',
					// 			max: 10,
					// 		}
					// 	],
					// },
					// {
					// 	label: '未收金额',
					// 	type: 'rangeNum',
					// 	//开始结束字段
					// 	fields: [
					// 		{
					// 			prop: 'uncollectAmount0',
					// 			max: 10,
					// 		},
					// 		{
					// 			prop: 'uncollectAmount1',
					// 			max: 10,
					// 		}
					// 	],
					// },
					{
						label: '备注',
						prop: 'remark',
						type: 'input',
						max: 100,
					},
					// {
					// 	label: '操作用户',
					// 	prop: 'fz_operateName',
					// 	type: 'popUser',
					// 	// short: 12,
					// 	fields: ['operateId', 'fz_operateName'],
					// },
					// {
					// 	label: '操作时间',
					// 	type: 'dateTimeRange',
					// 	//开始结束时间字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_addTime0',
					// 		},
					// 		{
					// 			prop: 'fz_addTime1',
					// 		},
					// 	],
					// },
				],
			},
			//数据表格配置项
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.purchaseRefund.getList,
				//数据表格列配置
				columns: [
					{
						label: '订单编号',
						prop: 'refundNo',
						width: 200,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '采购订单',
						prop: 'fz_purOrderNo',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '审核状态',
						prop: 'auditStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '业务员',
						prop: 'fz_salesmanName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '业务部门',
						prop: 'fz_departmentName',
						width: 110,
						sortable: 'custom',
					},
					// {
					// 	label: '退货状态',
					// 	prop: 'refundStatus',
					// 	width: 110,
					// 	sortable: 'custom',
					// },
					{
						label: '出库状态',
						prop: 'outStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退货日期',
						prop: 'refundDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '退货类型',
						prop: 'refundType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '付款方式',
						prop: 'paymentMethod',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退款账户',
						prop: 'fz_refundAccountName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '单位抬头',
						prop: 'fz_companyName',
						width: 200,
						sortable: 'custom',
					},

					{
						label: '终止原因',
						prop: 'reason',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '收款状态',
						prop: 'receiptStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '合计金额',
						prop: 'totalAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '减免金额',
						prop: 'reductionAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '实际金额',
						prop: 'actualAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '应收金额',
						prop: 'receivableAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已收金额',
						prop: 'receiptAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未收金额',
						prop: 'uncollectAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '出货仓库',
						prop: 'fz_depotName',
						sortable: 'custom',
						fixed: 'left',
						width: 110,
					},
					// {
					// 	label: '物品批号',
					// 	prop: 'batchNumber',
					// 	width: 130,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: 'SN序号',
					// 	prop: 'snNumber',
					// 	width: 130,
					// 	sortable: 'custom',
					// },
					{
						label: '退货数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '出库状态',
						prop: 'outStatus',
						width: 110,
						disable: true,
					},
					{
						label: '出库数量',
						prop: 'outQuantity',
						width: 110,
						disable: true,
					},
					{
						label: '预出数量',
						prop: 'preOutQuantity',
						width: 110,
						disable: true,
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未税单价',
						prop: 'price',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '税率(%)',
						prop: 'taxRate',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '含税单价',
						prop: 'taxPrice',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '税额(￥)',
						prop: 'taxAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '货款(￥)',
						prop: 'goodsAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '小计(￥)',
						prop: 'subtotal',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '摘要',
						prop: 'abstracts',
						width: 180,
						sortable: 'custom',
					},
				],
			},
		})
    onMounted(() => {
      state.hideForm=true
    })
		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			state.selections = []
			state.selData = []
			state.isConfirm = true
			state.loading = false
			if(data) {
				Object.assign(state.tableConfig.params, data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				const param = Object.assign({}, state.params)
				param['refundId'] = row.id
				state['tableSon'].reload(param)
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//传入父级ID
				Object.assign(state.tableSonConfig.params, state.params)
				state.tableSonConfig.params['refundId'] = res.data.list[0].id
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.purchaseRefund.getRefundGoodsList
				//根据主数据加载子数据
				handleRowClick(res.data.list[0])
			}else{
				if (state.leftShow) {
					proxy.$refs.tableSon.reload({refundId: -1})
				}
			}

		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			clearSelections,
			delSelData,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
		}
	},
})
</script>
