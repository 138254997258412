import config from "@/config"
import http from "@/utils/request"

export default {
	detail: {
		url: `${config.API_URL}/cfg/data/goodsLink/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},


}
