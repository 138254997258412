<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="width"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="物品名称"
        >
          <el-input
            v-model="form.name"
            maxlength="32"
            clearable
            @input="(val) => handleInput(val, 'name')"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="物品编码"
        >
          <el-input
            v-model="form.code"
            maxlength="32"
            clearable
            @blur="handleCodeBlur"
          />
        </el-form-item>
        <el-form-item
          label="所属仓库"
          prop="fz_depotName"
        >
          <el-input
            ref="refDepotInput"
            v-model="form.fz_depotName"
            clearable
            placeholder="请选择"
            @blur="inputBlur('depot')"
            @clear="inputClear('depot')"
            maxlength="32"
          >
            <template #append>
              <el-icon @click="popSelect('refDepotInput',['depotId','fz_depotName'], 1, 'refSelectDepotList')">
                <el-icon-search />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="stype"
          label="物品类型"
        >
          <el-select
            v-model="form.stype"
            clearable
          >
            <el-option
              label="产品"
              :value="1"
            />
            <el-option
              label="物料"
              :value="0"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="spec"
          label="规格型号"
        >
          <el-input
            v-model="form.spec"
            maxlength="32"
            clearable
          />
        </el-form-item>
<!--        <el-form-item-->
<!--          prop="model"-->
<!--          label="型号"-->
<!--        >-->
<!--          <el-input-->
<!--            v-model="form.model"-->
<!--            maxlength="32"-->
<!--            clearable-->
<!--          />-->
<!--        </el-form-item>-->
        <!--文本框-->
        <el-form-item
          prop="remark"
          label="备注"
        >
          <!--
          type="textarea"表示文本输入框
          rows="5"默认行数，越大文本框越高
          -->
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="255"
            rows="5"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        @click="save"
      >
        提交
      </el-button>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
    />
    <SelectDepots
      ref="refSelectDepotList"
      :multiple="multiples"
      hide-async-update
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetZnum } from "@/utils/validate"; //resetZnum返回正整数的方法
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const {proxy} = getCurrentInstance()
		const state = reactive({
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '用户新增',
			//表单当前数据
			form: {
				manager: 0,
			},
			//暂存原始数据
			orgForm: {},
			cacheFields: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				name: [{required: true, message: '必填'}],
				code: [
					{required: true,trigger: 'blur' ,message: '必填'},
					{validator: validateCode,trigger: 'blur'},
				],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.goods.getList,
				//弹窗标题
				title: '复制物品信息',
				//表格显示列
				columns: [
					{
						label: '物品名称',
						prop: 'name',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '物品编码',
						prop: 'code',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '物品类型',
						prop: 'stype',
						width: 120,
						sortable: 'custom',
						filters: [
							{text: '产品', value: '1'},
							{text: '物料', value: '0'},
						]
					},
					{
						label: '规格',
						prop: 'spec',
						width: 120,
					},
					{
						label: '型号',
						prop: 'model',
						width: 120,
					},
					{
						label: '物品类别',
						prop: 'fz_classifyName',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '所属仓库',
						prop: 'fz_depotName',
						width: 120,
					},
					{
						label: '保质期',
						prop: 'guarantee',
						width: 130,
					},
					{
						label: '主单位计量',
						prop: 'mainUnit',
						width: 110,
					},
					{
						label: '最小采购量',
						prop: 'purchaseQuantity',
						width: 110,
					},
					// {
					// 	label: '参考成本价',
					// 	prop: 'referenceCost',
					// 	width: 120,
					// },
					// {
					// 	label: '参考销售价',
					// 	prop: 'referencePrice',
					// 	width: 120,
					// },
					{
						label: '税率【%】',
						prop: 'taxRate',
						width: 110,
					},
					{
						label: '库存上限',
						prop: 'inventoryCap',
						width: 110,
					},
					{
						label: '库存下限',
						prop: 'inventoryLower',
						width: 110,
					},
					{
						label: '组装拆卸',
						prop: 'assembly',
						width: 110,
						sortable: 'custom',
						filters: [
							{text: '是', value: '1'},
							{text: '否', value: '0'},
						]
					},
					{
						label: '启用状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
						filters: [
							{text: '正常', value: '1'},
							{text: '停用', value: '0'},
						]
					},
					{
						label: '自动停用日期',
						prop: 'deactivationDate',
						width: 130,
					},
					{
						label: '描述',
						prop: 'represent',
						width: 120,
					},
					{
						label: '用途',
						prop: 'purpose',
						width: 120,
					},
					// {
					// 	label: '装箱规格',
					// 	prop: 'packing',
					// 	width: 120,
					// },
					// {
					// 	label: '存储条件',
					// 	prop: 'storage',
					// 	width: 120,
					// },
					{
						label: '备注',
						prop: 'remark',
						width: 180,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//查询表单项配置
					itemList: [
						{
							label: '物品名称',
							prop: 'name',
							type: 'input',
							//最大字符数，可不设
							max: 100,
							//默认显示
							default: true,
							span: 8,
						},
						{
							label: '物品编码',
							prop: 'code',
							type: 'input',
							//默认显示
							default: true,
							span: 8,
						},
						{
							label: '规格',
							prop: 'spec',
							type: 'input',
							//默认显示
							default: true,
							span: 8,
						},
						{
							label: '型号',
							prop: 'model',
							type: 'input',
							span: 8,
						},
						{
							label: '物品类型',
							prop: 'stype',
							type: 'select',
							span: 8,
							//下拉选项
							data: [
								{value: '1', label: '产品'},
								{value: '0', label: '物料'},
							]
						},
						{
							label: '物品类别',
							prop: 'classifys',
							type: 'tableSelect',
							span: 8,
							//下拉表格选择配置项
							config: {
								//数据接口对象 对象名称：getList
								apiObj: proxy.$API.classify.getList,
                hidePagination: true,
								props: {
									//映射label字段，即显示的字段
									label: 'name',
									//映射value字段
									value: 'id',
									//映射搜索字段
									keyword: 'name',
								},
								//表格显示的列配置
								columns: [
									{
										label: '名称',
										prop: 'name',
										width: 200,
									},
									{
										label: 'ID',
										prop: 'id',
									},
								],
								//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
								assignFields: [
									{
										//对应表格列表数据字段
										value: 'id',
										//赋值给到的字段名称
										field: 'classifyId',
									},
								],
							},
						},
						{
							label: '所属仓库',
							prop: 'fz_depotName',
							type: 'popDepot',
							span: 8,
							field: ['depotId', 'fz_depotName']
						},
						{
							label: '主计量单位',
							prop: 'mainUnit',
							type: 'input',
							span: 8,
						},
						{
							label: '税率',
							type: 'rangeNum',
							span: 8,
							//开始结束字段
							fields: [
								{
									prop: 'fz_taxRate1',
									max: 100,
								},
								{
									prop: 'fz_taxRate2',
									max: 100,
								}
							],
						},
						{
							label: '最小采购量',
							type: 'rangeNum',
							span: 8,
							//开始结束字段
							fields: [
								{
									prop: 'fz_purchaseQuantity1',
									max: 100,
								},
								{
									prop: 'fz_purchaseQuantity2',
									max: 100,
								}
							],
						},
						// {
						// 	label: '参考成本价',
						// 	type: 'rangeNum',
						// 	span: 8,
						// 	//开始结束字段
						// 	fields: [
						// 		{
						// 			prop: 'fz_referenceCost1',
						// 			max: 100,
						// 		},
						// 		{
						// 			prop: 'fz_referenceCost2',
						// 			max: 100,
						// 		}
						// 	],
						// },
						// {
						// 	label: '参考销售价',
						// 	type: 'rangeNum',
						// 	span: 8,
						// 	//开始结束字段
						// 	fields: [
						// 		{
						// 			prop: 'fz_referencePrice1',
						// 			max: 100,
						// 		},
						// 		{
						// 			prop: 'fz_referencePrice2',
						// 			max: 100,
						// 		}
						// 	],
						// },
						{
							label: '库存上限',
							type: 'rangeNum',
							span: 8,
							//开始结束字段
							fields: [
								{
									prop: 'fz_inventoryCap1',
									max: 100,
								},
								{
									prop: 'fz_inventoryCap2',
									max: 100,
								}
							],
						},
						{
							label: '库存下限',
							type: 'rangeNum',
							span: 8,
							//开始结束字段
							fields: [
								{
									prop: 'fz_inventoryLower1',
									max: 100,
								},
								{
									prop: 'fz_inventoryLower2',
									max: 100,
								}
							],
						},
						{
							label: '保质期',
							type: 'rangeNum',
							span: 8,
							//开始结束字段
							fields: [
								{
									prop: 'fz_guarantee1',
									max: 100,
								},
								{
									prop: 'fz_guarantee2',
									max: 100,
								}
							],
						},
						{
							label: '组装拆卸',
							prop: 'assembly',
							type: 'select',
							span: 8,
							//下拉选项
							data: [
								{value: '1', label: '是'},
								{value: '0', label: '否'},
							]
						},
						{
							label: '启用状态',
							prop: 'flag',
							type: 'select',
							span: 8,
							//下拉选项
							data: [
								{value: '1', label: '正常'},
								{value: '0', label: '停用'},
							]
						},
						{
							label: '自动停止日期',
							type: 'dateRange',
							span: 8,
							//开始结束日期字段
							fields: [
								{
									prop: 'fz_deactivationDate1',
								},
								{
									prop: 'fz_deactivationDate2',
								},
							],
						},
						{
							label: '描述',
							prop: 'represent',
							span: 8,
							type: 'input',
						},
						{
							label: '用途',
							prop: 'purpose',
							type: 'input',
							span: 8,
						},
						// {
						// 	label: '装箱规格',
						// 	prop: 'packing',
						// 	type: 'input',
						// 	span: 8,
						// },
						// {
						// 	label: '装箱条件',
						// 	prop: 'storage',
						// 	type: 'input',
						// 	span: 8,
						// },
						{
							label: '备注',
							prop: 'remark',
							type: 'input',
							span: 8,
						},
						{
							label: '操作用户',
							prop: 'fz_operateName',
							span: 8,
							type: 'popUser',
              max:32,
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							type: 'dateRange',
							span: 8,
							//开始结束日期字段
							fields: [
								{
									prop: 'fz_addTimeSmall',
								},
								{
									prop: 'fz_addTimeBig',
								},
							],
						},
					],
				},
			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增物品"
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑物品"
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.goods.detail.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}

		//提交方法
		const save = () => {
			state['refForm'].validate((valid) => {
				if(valid) {
					proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res = null
						if(state.form.id){ //修改
							res = await proxy.$API.goods.update.put(state.form)
						}else{
							res = await proxy.$API.goods.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					})
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}

		//打开选择弹窗
		//参数(ref触发元素，fields赋值字段，type类型区分多选单选，pop弹窗ref对象)
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = state.form[fields[0]]
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		//表单中选择项赋值
		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}

		//输入框失去焦点
		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			if(field != 'shareUser'){
				state.form[field + 'Id'] = ''
			}else if(field === 'supplier'){
				state.form[field + 'Ids'] = ''
			}else if(field === 'customer'){
				state.form[field + 'Ids'] = ''
			}else{
				state.form[field] = ''
			}
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			const val = e.target.value
			if (val !== '') {
				var object = {
					id: state.form.id,
					code: state.form.code
				}
				const res = await proxy.$API.goods.doCheckCode.post(object)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['refForm'].validateField('code')
				} else {
					state.codeMsg = null;
					var str = state.form.code;
					state['refForm'].resetFields('code')
					state.form.code = str
				}
			}
		}

		return {
			...toRefs(state),
			handleCodeBlur,
			inputBlur,
			inputClear,
			popSelect,
			setSelectData,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm
		}
	},
})
</script>
