<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="width"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="科目名称"
        >
          <el-input
            v-model="form.name"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="科目编码"
        >
          <el-input
            v-model="form.code"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
		  <el-form-item
			  prop="sort"
			  label="排序"
		  >
			  <el-select v-model="form.sort" style="width: 86px;">
				  <el-option
					  v-for="item in sortList"
					  :label="item"
					  :value="item"
				  />
			  </el-select>
		  </el-form-item>
		  <el-form-item
			  prop="special"
			  label="专项附加扣除"
		  >
			  <el-radio-group v-model="form.special">
				  <el-radio :value="1">是</el-radio>
				  <el-radio :value="0">否</el-radio>
			  </el-radio-group>
		  </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="255"
            rows="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
		<el-popconfirm
			:visible="visibles"
			:title="$TIPS.SAVE"
			confirm-button-text="确定"
			cancel-button-text="取消"
			@cancel="visibles = false"
			@confirm="handleConfirm"
		>
			<template #reference>
				<el-button
					type="primary"
					:size="$store.state.global.uiSize"
					:loading="subLoading"
					@click = "valiForm"
				>
					提交
				</el-button>
			</template>
		</el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button :size="$store.state.global.uiSize">
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
    >
		<template #special="{ scope }">
			<el-tag class="myTag" type="danger" v-if="scope.row.special === 1">是</el-tag>
			<el-tag class="myTag" type="info" v-if="scope.row.special === 0">否</el-tag>
		</template>
	</SyCopyDialog>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick, onMounted,
} from "vue";
import { resetNum } from "@/utils/validate";
export default defineComponent({
	name: "SelectWageSubjectEdit",
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		const state = reactive({
			sortList: [],
			codeMsg: '',
			loading: false,
			triggerDom: {},
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				name: [{required: true, message: '必填'}],
			},
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.wageSubject.getList,
				//弹窗标题
				title:'复制工资科目信息',
				width: 680,
				//表格显示列
				columns: [
					{
						label: '科目名称',
						prop: 'name',
						width: 200,
						sortable: 'custom',
						fixed: 'left'
					},
					{
						label: '科目编码',
						prop: 'code',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '专项附加扣除',
						prop: 'special',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 150,
						sortable: 'custom',
					},
				],
				searchConfig: {
					//表单项配置
					itemList: [
						{
							label: '科目名称',
							span: 12,
							prop: 'name',
							type: 'input',
							max: 255,
							default: true,
						},
						{
							label: '科目编码',
							span: 12,
							prop: 'code',
							type: 'input',
							max: 32,
							default: true,
						},
						{
							label: "专项附加扣除",
							span: 12,
							prop: "special",
							type: "select",
							data: [
								{
									label: "是",
									value: 1
								},
								{
									label: "否",
									value: 0
								}
							]
						},
						{
							label: '备注',
							span: 12,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
					],
				},
			}
		})

		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) === '{}') {
				state.title = '新增'
				state.form.special = 0
				state.form.sort = 1
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title = '编辑'
				//根据id查询
				const res = await proxy.$API.wageSubject.detail.get(row.id);
				if (res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
				}
			}
			state.loading = false
		}

		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		const handleConfirm = () => {
			save()
			state.visibles = false
		}
		const valiForm = () => {
			nextTick(()=>{
				state.formRef.validate((valid)=>{
					if(valid){
						state.visibles = true
					}else {
						state.visibles = false
					}
				})
			})
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate(async (valid) => {
				if(valid) {
						state.subLoading = true
						let res
						if(state.form.id){
							res = await proxy.$API.wageSubject.update.put(state.form)
						}else{
							res = await proxy.$API.wageSubject.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//只能输入数字小数符数
		const handleNumInput = (val, field) => {
			if(val){
				state.form[field] = resetNum(val)
			}
		}

		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id
			delete state.form.id
		}

		onMounted(() => {
			setSortList()
		})

		//初始化排序选项值
		const setSortList = () => {
			for (let i = 1; i <= 30; i++) {
				state.sortList.push(i);
			}
		}

		return {
			...toRefs(state),
			setFormData,
			copyData,
			handleNumInput,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			valiForm
		}
	},
})
</script>
