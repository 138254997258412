import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/sell/refundPayment/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/sell/refundPayment/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	delete: {
		url: `${config.API_URL}/fms/sell/refundPayment/delete`,
		name: "删除",
		get: async function(ids){
			return await http.get(this.url + "?ids=" + ids);
		}
	},

	detail: {
		url: `${config.API_URL}/fms/sell/refundPayment/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},

	update: {
		url: `${config.API_URL}/fms/sell/refundPayment/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updatePaymentStatus: {
		url: `${config.API_URL}/fms/sell/refundPayment/updatePaymentStatus`,
		name: "确认付款",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},


}
