import config from "@/config"
import http from "@/utils/request"

export default {
	token: {
		url: `${config.API_URL}/token/login`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	user: {
		url: `${config.API_URL}/demo/sa`,
		name: "获取用户",
		post: async function(data={}){
			return await http.get(this.url, data);
		}
	},
	loginOut: {
		url: `${config.API_URL}/token/logout`,
		name: "退出登录",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},
	tokenByPhone: {
		url: `${config.API_URL}/token/login/phone`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
