import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/produce/bom/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getBomGoodsEdition: {
		url: `${config.API_URL}/erp/produce/bom/selectGoodsEditionNum`,
		name: "查询版本号",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	queryVersionNo: {
		url: `${config.API_URL}/erp/produce/bom/queryVersionNo`,
		name: "查询版本号",
		get: async function(params){
			return await http.get(this.url + "?goodsId=" + params);
		}
	},
	viewLevel: {
		url: `${config.API_URL}/erp/produce/bom/viewLevel`,
		name: "层级",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	getCost: {
		url: `${config.API_URL}/erp/produce/bom/getCost`,
		name: "成本明细",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
	getBomListByGoodsIdAndEdit: {
		url: `${config.API_URL}/erp/produce/bom/queryBomDetailByGoodsIdAndEdit`,
		name: "查询bom清单",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	queryBomId: {
		url: `${config.API_URL}/erp/produce/bom/queryBomId`,
		name: "查询bom清单",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/produce/bom/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/bom/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/bom/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/bom/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	calculationList: {
		url: `${config.API_URL}/erp/produce/bom/calculationList`,
		name: "mrp运算",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	maximumProduction: {
		url: `${config.API_URL}/erp/produce/bom/maximumProduction`,
		name: "MRP最大生产查询",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/produce/bom/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateProcessInfo: {
		url: `${config.API_URL}/erp/produce/bom/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	getGoodsDetail: {
		url: `${config.API_URL}/erp/produce/bom/getGoodsDetail`,
		name: "查询物料明细",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	importExcel:{
		url: `${config.API_URL}/erp/produce/bom/importExcel`,
		name: "导入",
		post: async function(data={},config){
			return await http.post(this.url,data,config);
		}
	},

	getEditions: {
		url: `${config.API_URL}/erp/produce/bom/getEditions`,
		name: "查获取版本号",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/produce/bom/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/produce/bom/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	queryBomDetailByGoodsIdAndEdit: {
		url: `${config.API_URL}/erp/produce/bom/queryBomDetailByGoodsIdAndEdit`,
		name: "",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	processCompute: {
		url: `${config.API_URL}/erp/produce/bom/processCompute`,
		name: "加工单计算",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	customTemplates: {
		url: `${config.API_URL}/erp/produce/bom/customTemplates`,
		name: "自定义模板",
		get: async function(){
			return await http.get(this.url);
		}
	},
	showOwnBomGoods: {
		url: `${config.API_URL}/erp/produce/bom/showOwnBomGoods`,
		name: "查询有BOM的物品",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	showGoodsAllBOM: {
		url: `${config.API_URL}/erp/produce/bom/showGoodsAllBOM`,
		name: "查询物品的BOM列表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	showHistoricalBomVersion: {
		url: `${config.API_URL}/erp/produce/bom/showHistoricalBomVersion`,
		name: "BOM历史版本",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	showHistoryDetail: {
		url: `${config.API_URL}/erp/produce/bom/showHistoryDetail`,
		name: "查看历史BOM详情",
		get: async function(historyBomId,bomId){
			return await http.get(this.url+'?historyBomId='+historyBomId+'&bomId='+bomId);
		}
	},
	selectHistoryBomDetail: {
		url: `${config.API_URL}/erp/produce/bom/Detail/selectHistoryBomDetail`,
		name: "查询BOM详情子集带分页",
		get: async function(data = {}){
			return await http.post(this.url,data);
		}
	},
}
