import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/sale/order/getList`,
		name: "获取所有销售订单",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/sale/order/insert`,
		name: "新增销售订单",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/sale/order/update`,
		name: "修改销售订单",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/sale/order/detail`,
		name: "获取销售订单详情",
		get: async function (id) {
			return await http.get(this.url + '?id='+ id);
		}
	},
	delete:{
		url: `${config.API_URL}/mobile/sale/order/delete`,
		name: "删除销售订单",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/mobile/sale/order/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/mobile/sale/order/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
}
