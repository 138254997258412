import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/produce/process/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/produce/process/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/produce/process/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/process/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	updateQuantity: {
		url: `${config.API_URL}/erp/produce/process/updateQuality`,
		name: "修改数量",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/process/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/process/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/erp/produce/process/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/erp/produce/process/updateProcessInfo`,
		name: "修改审批信息",
		put: async function(data ={}){
			return await http.put(this.url,data);
		}
	},
	updateProcessing: {
		url: `${config.API_URL}/erp/produce/process/updateProcessing`,
		name: "修改加工状态",
		get: async function(ids,state){
			return await http.get(this.url + '?ids=' + ids + '&state=' + state);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/produce/process/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	//转派工单
	helpFunction: {
		url: `${config.API_URL}/erp/produce/process/helpFunction`,
		name: "转派工单",
		get: async function(data){
			return await http.post(this.url,data);
		}
	},
	processCompute: {
		url: `${config.API_URL}/erp/produce/process/processCompute`,
		name: "加工单计算",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	trackRecord: {
		url: `${config.API_URL}/erp/produce/process/trackRecord`,
		name: "跟踪-首页数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	trackDetails: {
		url: `${config.API_URL}/erp/produce/process/trackDetails`,
		name: "跟踪-详情数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	processClose: {
		url: `${config.API_URL}/erp/produce/process/processClose`,
		name: "关闭订单",
		get: async function(ids){
			return await http.get(this.url+'?ids='+ids);
		}
	},
	processOpen: {
		url: `${config.API_URL}/erp/produce/process/processOpen`,
		name: "开启订单",
		get: async function(ids){
			return await http.get(this.url+'?ids='+ids);
		}
	},
}
