<template>
	<div class="sc-upload-multiple">
		<el-upload
			ref="uploader"
			:list-type="listType"
			:auto-upload="autoUpload"
			:disabled="disabled"
			:action="action"
			:name="name"
			:data="data"
			:http-request="request"
			v-model:file-list="defaultFileList"
			:show-file-list="showFileList"
			:accept="accept"
			:multiple="multiple"
			:limit="limit"
			:before-upload="before"
			:on-success="success"
			:on-error="error"
			:on-preview="handlePreview"
			:on-exceed="handleExceed"
			:class="(isFlex?'dis-flex':'')+' '+(fullWidth?'upload-full-width':'')"
		>
			<slot>
				<el-icon>
					<el-icon-plus/>
				</el-icon>
			</slot>
			<template #tip>
				<div
					v-if="tip"
					class="el-upload__tip"
				>
					{{ tip }}
				</div>
			</template>
			<template #file="{ file }">
				<div
					v-if="isFile"
					class="upload-file-item"
					:class="isFlex?'':'mt_5'"
					style="font-size: 12px"
				>
					<template v-if="file.status=='success'">
						<a
							href="javascript:;"
							@click="handlePreview(file)"
						>
							{{ file.name }}
						</a>
						<el-icon
							v-if="!disabled && file.status=='success'"
							class="del"
							@click="handleRemove(file)"
						>
							<el-icon-delete/>
						</el-icon>
					</template>
					<template v-if="file.status=='ready' || file.status=='uploading'">
						文件上传中，请稍等...
					</template>
				</div>
				<div
					v-else
					class="sc-upload-list-item"
				>
					<el-image
						class="el-upload-list__item-thumbnail"
						:src="$CONFIG.API_URL + '/'+'api/' +file.url"
						fit="cover"
						:preview-src-list="preview"
						:initial-index="preview.findIndex(n => n == ($CONFIG.API_URL + '/' +'api/'+ file.url))"
						hide-on-click-modal
						append-to-body
						:z-index="9999"
					>
						<template #placeholder>
							<div class="sc-upload-multiple-image-slot">
								Loading...
							</div>
						</template>
					</el-image>
					<div
						v-if="!disabled && file.status=='success'"
						class="sc-upload__item-actions"
					>
            <span
				class="del"
				@click="handleRemove(file)"
			>
              <el-icon><el-icon-delete/></el-icon>
            </span>
					</div>
					<div
						v-if="file.status=='ready' || file.status=='uploading'"
						class="sc-upload__item-progress"
					>
						<el-progress
							:percentage="file.percentage"
							:text-inside="true"
							:stroke-width="16"
						/>
					</div>
				</div>
			</template>
		</el-upload>
		<span style="display:none!important"><el-input v-model="value"/></span>
	</div>
</template>

<script>
import config from "@/config/upload"
import Sortable from 'sortablejs'
import API from "@/api"

export default {
	props: {
		//model值
		modelValue: {type: String, default: ""},
		//提示文字
		tip: {type: String, default: ""},
		//提交接口
		action: {type: String, default: ""},
		//提交接口对象
		apiObj: {
			type: Object, default: () => {
			}
		},
		//文件名
		name: {type: String, default: config.filename},
		//默认数据
		data: {
			type: Object, default: () => {
			}
		},
		//接受的上传类型
		accept: {type: String, default: "image/gif, image/jpeg, image/png"},
		//文件大小上传限制
		maxSize: {type: Number, default: config.maxSizeFile},
		//上传个数限制
		limit: {type: Number, default: 0},
		//自动上传，选择完毕后上传
		autoUpload: {type: Boolean, default: true},
		//显示文件列表
		showFileList: {type: Boolean, default: true},
		//多选
		multiple: {type: Boolean, default: true},
		//是否可用
		disabled: {type: Boolean, default: false},
		//是否显示选择按钮
		hideBtn: {type: Boolean, default: false},
		//是否可拖动
		draggable: {type: Boolean, default: false},
		//上传成功后回调
		onSuccess: {
			type: Function, default: () => {
				return true
			}
		},
		//是否是文件上传
		isFile: {type: Boolean, default: false},
		//文件显示列表类型
		listType: {type: String, default: 'picture-card'},
		//上传word文件名
		fileName: {type: String, default: ''},
		//菜单编码
		menuCode: {type: String, default: ''},
		//按钮与文件布局
		isFlex: {type: Boolean, default: false},
		//宽度100%
		fullWidth: {type: Boolean, default: false}
	},
	emits: ['update:modelValue'],
	//当前页数据绑定
	data() {
		return {
			value: "",
			defaultFileList: [],
		}
	},
	computed: {
		//计算预览列表
		preview() {
			return this.defaultFileList.map(v => this.$CONFIG.API_URL + '/' + 'api/' + v.url)
		}
	},
	//监听
	watch: {
		//监听model值
		modelValue(val) {
			if (val != this.toStr(this.defaultFileList)) {
				this.defaultFileList = this.toArr(val)
				this.value = val
			}
		},
		//监听默认文件列表
		defaultFileList: {
			handler(val) {
				this.$emit('update:modelValue', this.toStr(val))
				this.value = this.toStr(val)
			},
			deep: true
		}
	},
	//组件挂载完成
	mounted() {
		this.value = this.modelValue
		this.defaultFileList = this.toArr(this.modelValue)
		if (!this.disabled && this.draggable) {
			this.rowDrop()
		}
	},
	methods: {
		//默认值转换为数组
		toArr(str) {
			let _arr = [];
			if (str && typeof str === 'string') {
				let arr = str.split(",");
				arr.forEach(item => {
					if (item) {
						const urlArr = item.split('/');
						const fileName = urlArr[urlArr.length - 1];
						_arr.push({
							name: fileName,
							url: item,
						});
					}
				});
			}
			return _arr;
		},
		//数组转换为原始值
		toStr(arr) {
			return arr.map(v => v.url).join(",")
		},
		//拖拽
		rowDrop() {
			const _this = this
			const itemBox = this.$refs.uploader.$el.querySelector('.el-upload-list')
			Sortable.create(itemBox, {
				handle: ".el-upload-list__item",
				animation: 200,
				ghostClass: "ghost",
				onEnd({newIndex, oldIndex}) {
					const tableData = _this.defaultFileList
					const currRow = tableData.splice(oldIndex, 1)[0]
					tableData.splice(newIndex, 0, currRow)
				}
			})
		},
		//上传前校验
		before(file) {
			if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type) && !this.isFile) {
				this.$message.warning(`选择的文件类型 ${file.type} 非图像类文件`);
				return false;
			}
			const maxSize = file.size / 1024 / 1024 < this.maxSize;
			if (!maxSize) {
				this.$message.warning(`上传文件大小不能超过 ${this.maxSize}MB!`);
				return false;
			}
		},
		//上传成功后调用
		success(res, file) {
			const os = this.onSuccess(res, file)
			if (os != undefined && os == false) {
				return false
			}
			const response = config.parseData(res)
			file.name = response.fileName
			file.url = response.src

		},
		//上传失败调用
		error(err) {
			this.$notify.error({
				title: '上传文件未成功',
				message: err
			})
		},
		//删除前调用
		beforeRemove(uploadFile) {
			return this.$confirm(`是否移除 ${uploadFile.name} ?`, '提示', {
				type: 'warning',
			}).then(() => {
				return true
			}).catch(() => {
				return false
			})
		},
		//删除后调用
		handleRemove(file) {
			this.$refs.uploader.handleRemove(file)
			var str = {
				path: file.url
			}
			// 删除服务器上的附件
			// API.common.deleteFile.post(str);
			//this.defaultFileList.splice(this.defaultFileList.findIndex(item => item.uid===file.uid), 1)
		},
		//超出限制上传数后调用
		handleExceed() {
			this.$message.warning(`当前设置最多上传 ${this.limit} 个文件，请移除后上传!`)
		},
		//预览调用
		handlePreview(uploadFile) {
			window.open(this.$CONFIG.API_URL + '/api/' + uploadFile.url)
		},
		//请求上传
		request(param) {
			let apiObj = config.apiObj;
			if (this.apiObj) {
				apiObj = this.apiObj;
			}
			const data = new FormData();
			data.append(param.filename, param.file);
			for (const key in param.data) {
				data.append(key, param.data[key]);
			}
			if (this.fileName) {
				data.append('fileName', this.fileName)
			}
			if (this.menuCode) {
				data.append('menuCode', this.menuCode)
			}
			apiObj.post(data, {
				onUploadProgress: e => {
					const complete = parseInt(((e.loaded / e.total) * 100) | 0, 10)
					param.onProgress({percent: complete})
				}
			}).then(res => {
				const response = config.parseData(res);
				if (response.code == config.successCode) {
					param.onSuccess(res)
				} else {
					param.onError(response.msg || "未知错误")
				}
			}).catch(err => {
				param.onError(err)
			})
		},
	},
	// 	组件销毁
	beforeDestroy() {
		// 在组件销毁之前，将成功状态的文件设置为非成功状态
		this.defaultFileList.forEach(file => {
			if (file.status === 'success') {
				file.status = 'error';
				// 这里可以根据需要清空其他属性，如链接等
			}
		});
	}
}
</script>

<style scoped lang="scss">
.dis-flex {
	display: flex;
	align-items: center;

	:deep(.el-upload-list__item) {
		margin-bottom: 0 !important;
	}
}

.sc-upload-multiple {
	width: 100%;
}

.el-form-item.is-error .sc-upload-multiple:deep(.el-upload--picture-card) {
	border-color: var(--el-color-danger);
}

:deep(.el-upload-list__item) {
	transition: none;
	border-radius: 0;
}

:deep(.el-upload-list) {
	margin-top: 0px !important;
}

.sc-upload-multiple:deep(.el-upload-list__item.el-list-leave-active) {
	position: static !important;
}

.sc-upload-multiple:deep(.el-upload--picture-card) {
	border-radius: 0;
}

.sc-upload-list-item {
	width: 100%;
	height: 100%;
	position: relative;
}

.sc-upload-multiple .el-image {
	display: block;
}

.sc-upload-multiple .el-image:deep(img) {
	-webkit-user-drag: none;
}

.sc-upload-multiple-image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 12px;
}

.sc-upload-multiple .el-upload-list__item:hover .sc-upload__item-actions {
	display: block;
}

.sc-upload__item-actions {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
}

.sc-upload__item-actions span {
	display: flex;
	justify-content: center;
	align-items: center;;
	width: 16px;
	height: 16px;
	cursor: pointer;
	color: #fff;
}

.sc-upload__item-actions span i {
	font-size: 12px;
}

.sc-upload__item-actions .del {
	background: #F56C6C;
}

.el-upload--picture-card i {
	font-size: 20px;
}

.sc-upload-multiple:deep(.el-upload-list--text) .el-upload-list__item {
	display: inline-block;
	width: auto;
	margin-right: 10px;
}

.upload-file-item {
	position: relative;
	padding: 5px 10px;
	border: 1px solid #eee;
	padding-right: 30px;
}

.upload-file-item .del {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px;
	cursor: pointer;
}

.upload-file-item:hover a {
	color: var(--el-color-primary);
	text-decoration: underline;
}

.upload-file-item .del:hover {
	color: #ff0000;
}

.sc-upload__item-progress {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.upload-full-width{
	width: 100%;
  :deep(.el-upload-list--text .el-upload-list__item){
		width: 100%!important;
	}
}
</style>
