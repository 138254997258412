<template>
  <sc-dialog
    ref="dialogRef"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm formset">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="批次名称"
        >
          <el-input
            v-model="form.name"
            clearable
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="cycle"
          label="周期"
        >
          <el-input
            v-model="form.cycle"
            clearable
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="stype"
          label="考核类型"
        >
          <dicts-query
            v-model="form.stype"
            :type-code="'hr_performance_batch-stype'"
          />
        </el-form-item>
        <el-form-item
          prop="startDate"
          label="开始日期"
        >
          <el-date-picker
            v-model="form.startDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="endDate"
          label="结束日期"
        >
          <el-date-picker
            v-model="form.endDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
        <el-form-item
          label="负责人"
          prop="fz_handlerName"
        >
            <div class="disflex fullWidth sc-table-select--append">
              <div class="flexgs1">
                <sc-table-select
                    v-model="form.fz_handlerName"
                    customerValue
                    fullWidth
                    :api-obj="$API.user.getListByKeyword"
                    :params="{
                        flag: 1,
                      }"
                    :height="426"
                    :props="{ label: 'name', value: 'id', keyword: 'name' }"
                    @change="(val) => handleSelChange(val, ['handlerId','fz_handlerName'])"
                    @clear="inputClear('handler')"
                >
                  <el-table-column
                      prop="name"
                      label="姓名"
                      width="150"/>
                  <el-table-column
                      prop="fz_departmentName"
                      label="部门"
                      width="150"/>
                  <el-table-column
                      prop="code"
                      label="工号"
                      width="150"/>
            <template #append>
              <el-icon
                @click="popSelect('refHandlerIput',['handlerId','fz_handlerName'], 1, 'refSelectUser')"
              >
                <el-icon-search />
              </el-icon>
            </template>
                </sc-table-select>
              </div>
            </div>
        </el-form-item>
        <el-form-item
          prop="task"
          label="考核任务"
        >
          <el-input
            v-model="form.task"
            clearable
            type="textarea"
            rows="4"
            maxlength="250"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          prop="formula"
          label="计算公式"
        >
          <el-input
            v-model="form.formula"
            type="textarea"
            clearable
            maxlength="250"
            rows="4"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            clearable
            type="textarea"
            maxlength="255"
            rows="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #name="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row, 'refDetail')"
        >
          {{ scope.row.name }}
        </el-link>
      </template>
      <template #scoreStatus="{ scope }">
        <el-tag
          v-if="scope.row.scoreStatus === 0"
          type="danger"
          class="myTag"
        >
          未开始
        </el-tag>
        <el-tag
          v-if="scope.row.scoreStatus === 1"
          type="success"
          class="myTag"
        >
          已开始
        </el-tag>
        <el-tag
          v-if="scope.row.scoreStatus === 2"
          type="info"
          class="myTag"
        >
          评分已结束
        </el-tag>
      </template>
    </SyCopyDialog>
    <Detail ref="refDetail" />
    <!--选择用户-->
    <SelectUser
      ref="refSelectUser"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>
<script>
import {
	defineComponent,
	getCurrentInstance,
	nextTick,
	reactive,
	toRefs,
} from 'vue'
import {resetNum} from "@/utils/validate";
import Detail from "@/views/hr/performance/batch/detail";
export default defineComponent({
	name: "SelectPerformanceBatchEdit",
	components: {Detail},
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		const user = proxy.$TOOL.data.get('USER_INFO')
		const state = reactive({
      refFormBar:null,
			fields: [],
			triggerDom: {},
			//加载动画
			loading: true,
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			cacheFields: {},
			rules: {
				name: [{required: true, message: '必填'}],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.performanceBatch.getList,
				//弹窗标题
				title: '复制考核批次信息',
				//表格显示列
				columns: [
					{
						label: '批次名称',
						prop: 'name',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '周期',
						prop: 'cycle',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '考核类型',
						prop: 'stype',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '考核任务',
						prop: 'task',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '开始日期',
						prop: 'startDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '结束日期',
						prop: 'endDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '负责人',
						prop: 'fz_handlerName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'scoreStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计算公式',
						prop: 'formula',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
				searchConfig: {
					itemList: [
						{
							label: '批次名称',
							span: 8,
							prop: 'name',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '周期',
							span: 8,
							prop: 'cycle',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '考核类型',
							span: 8,
							prop: 'stype',
							default: true,
							type: 'selectDicts',
							typeCode: 'hr_performance_batch-stype'
						},
						{
							label: '考核任务',
							span: 8,
							prop: 'task',
							type: 'input',
							max: 255,
						},
						{
							label: '开始日期',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_startDate0',
								},
								{
									prop: 'fz_startDate1',
								},
							],
						},
						{
							label: '结束日期',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_endDate0',
								},
								{
									prop: 'fz_endDate1',
								},
							],
						},
						{
							label: '负责人',
							span: 8,
							prop: 'fz_handlerName',
							type: 'popUser',
							fields: ['handlerId', 'fz_handlerName']
						},
						{
							label: '状态',
							span: 8,
							prop: 'scoreStatus',
							type: 'select',
							data: [
								{value: 0, label: '未开始'},
								{value: 1, label: '进行中'},
								{value: 2, label: '评分已结束'},
							]
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '计算公式',
							span: 8,
							prop: 'formula',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},

			}
		})
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) === '{}') {
				state.title = '新增'
				state.form.handlerId = user.id
				state.form.fz_handlerName = user.name
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title = '编辑'
				//根据id查询
				const res = await proxy.$API.performanceBatch.detail.get(row.id)
				if (res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
				}
			}
			state.loading = false
		}
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}
		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({}, data)
			//删除id
			delete state.form.id
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
    //关闭确认
    const handleConfirm = () => {
      save()
      state.visibles = false
    }
    const valiForm = () => {
      nextTick(()=>{
        state.formRef.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		//确定按钮
		const save = () => {
			state['formRef'].validate(async (valid) => {
				if (valid) {
						state.subLoading = true
						let res
						if (state.form.id) { //修改
							res = await proxy.$API.performanceBatch.update.put(state.form)
						} else { //新增
							res = await proxy.$API.performanceBatch.insert.post(state.form)
						}
						if (res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if (!isClose) {
				state.form = Object.assign({}, orgForm)
			}
		}
		const handleNumInput = (val, prop) => {
			if (val) {
				state.form[prop] = resetNum(val)
			}
		}

		//详情
		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}


		//输入框失去焦点
		const inputBlur = (field) => {
			state.form['fz_' + field + 'Name'] = state.cacheFields['fz_' + field + 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			state.form['fz_' + field + 'Name'] = ''
			state.cacheFields['fz_' + field + 'Name'] = ''
		}

		//打开选择弹窗
		//参数(ref触发元素，fields赋值字段，type类型区分多选单选，pop弹窗ref对象)
		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if (type === 1) { //单选
					state.multiples = false
				} else { //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		//表单中选择项赋值x
		const setSelectData = async (data) => {
			if (state.fields.length > 0) {
				if (data.length) { //多选
					const ids = data.map((item) => {
						return item.id
					}).join()
					const names = data.map((item) => {
						return item.name
					}).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				} else { //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}
//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
    const handleSelChange =async (data, field) => {
      console.log(data)
      if (data.length > -1) { //多选
        let ids = ''
        if (data.length > 0) {
          //取得选中的id串
          ids = data.map((item) => item.id).join()
        } else {
          ids = ''
        }
        state.form[field] = ids
      } else { //单选
        let item = field[0]
        if (data && data.id) {
          //将选择的数据id赋值给指定的form中的属性 例：groupId
          state.form[field[0]] = data.id
        }
      }
    }
		return {
			...toRefs(state),
			inputBlur,
			inputClear,
			popSelect,
			setSelectData,
			handleDetail,
			handleNumInput,
			copyData,
			setFormData,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
      valiForm,
      handleSelChange

		};
	},
})
</script>
