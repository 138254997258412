
import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	delete: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	update: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	detail: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/hr/recruit/recruitChannel/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
