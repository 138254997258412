import http from "@/utils/request"
/**
 * 导出数据
 */

/**
 * @param {Object} fileName 导出文件名称
 * @param {Object} path 下载地址
 */
export function exportExcel(url, data) {
	return http.excelPost(url, data).then((res) => {
		const link = document.createElement('a')
		let blob = new Blob([res], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		})
		link.style.display = 'none'
		link.href = URL.createObjectURL(blob)
		link.download = data.fileName
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	})
}

/**
 * 导出Word
 * @param url
 * @param data
 * @returns {Promise<void>}
 */
export function exportWord(url, data) {
	return http.excelPost(url, data).then((res) => {
		const link = document.createElement('a')
		let blob = new Blob([res], {
			type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8',
		})
		link.style.display = 'none'
		link.href = URL.createObjectURL(blob)
		link.download = data.fileName
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	})
}

//下载文件
export async function downLoad(fileUrl, fileName){
	let res = await http.get(fileUrl,null,{
		responseType: 'blob'
	})
	let url = window.URL.createObjectURL(res)
	let link = document.createElement("a"); //创建a标签
	link.style.display = "none"; //使其隐藏
	link.href = url
	link.download = fileName
	link.click()
	link.remove()
	window.URL.revokeObjectURL(url)
}
