import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/activiti/process/getList`,
		name: "查询流程",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	deploymentGetList: {
		url: `${config.API_URL}/cfg/activiti/process/deploymentGetList`,
		name: "查询已部署的流程",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	selectDetail: {
		url: `${config.API_URL}/cfg/activiti/process/selectDetail`,
		name: "查询流程",
		get: async function(data={}){
			return await http.get(this.url + '?id=' + data);
		}
	},
}
