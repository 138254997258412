import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/other/capital/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/other/capital/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/other/capital/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/other/capital/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/other/capital/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateImplement: {
		url: `${config.API_URL}/fms/other/capital/updateImplement`,
		name: "确认执行",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	handleCancel: {
		url: `${config.API_URL}/fms/other/capital/handleCancel`,
		name: "作废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
