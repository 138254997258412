import {createRouter, createWebHashHistory} from 'vue-router'
import {ElNotification} from 'element-plus'
import config from "@/config"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import tool from '@/utils/tool'
import systemRouter from './systemRouter'
import userRoutes from '@/config/route'
import pdaRouter from './pdaRouter'
import wapRouter from './wapRouter'
import {isMobile} from '@/utils/mobile'
import {afterEach, beforeEach} from './scrollBehavior'
import LOADING from '@/config/loadingService'

//PDA路由
const pdaRoutes = pdaRouter
//手机版路由
const wapRoutes = wapRouter

//系统路由
// const routes = systemRouter
const routes = systemRouter.concat(pdaRoutes).concat(wapRoutes)

//系统特殊路由
const routes_404 = {
	path: "/:pathMatch(.*)*",
	hidden: true,
	component: () => import(/* webpackChunkName: "404" */ '@/layout/other/404'),
}
let routes_404_r = ()=>{}
const router = createRouter({
	history: createWebHashHistory(),
	routes: routes
})

//设置标题
document.title = config.APP_NAME

//判断是否已加载过动态/静态路由
let isGetRouter = false;

router.beforeEach(async (to, from, next) => {
	LOADING.loading()
	NProgress.start()
	//动态标题
	// document.title = to.meta.title ? `${to.meta.title} - ${config.APP_NAME}` : `${config.APP_NAME}`
	document.title = to.meta.title ? `${to.meta.title}`: `${config.APP_NAME}`
	router['fromPath'] = from
	let token = tool.cookie.get("TOKEN");
	//判断是否在移动端
	// const mobile = isMobile()
	const mobile = false
	if(!token && to.path === '/'){
		if(!mobile){
			next({
				path: '/login'
			})
		}else{
			next({
				path: '/pda_login'
			})
		}
		return false
	}
	if(to.path === "/login" || to.path === "/pda_login"){
		//删除路由(替换当前layout路由)
		router.addRoute(routes[0])
		//删除路由(404)
		routes_404_r()
		isGetRouter = false;
		next();
		return false;
	}
	if(routes.findIndex(r => r.path === to.path) >= 0){
		// if(mobile){
		// 	if(to.path === '/'){
		// 		next({
		// 			path: '/wap/index'
		// 		})
		// 	}else{
		// 		next()
		// 	}
		// }else{
		// 	next();
		// }
		next()
		return false;
	}
	if(!token && self.frameElement && self.frameElement.tagName === 'IFRAME'){
		window.parent.postMessage({
			cmd: 'toLogin',
			path: '/login'
		}, '*')
		next({
			path: '/login'
		})
		return false
	}
	if(!token && mobile){
		next({
			path: '/pda_login'
		})
		return false
	}
	//整页路由处理
	if(to.meta.fullpage){
		to.matched = [to.matched[to.matched.length-1]]
	}

	//加载动态/静态路由
	if(!isGetRouter){
		let apiMenu = tool.data.get("MENU") || []
		const btnPath = tool.data.get('BTN_PATH')
		if(btnPath){
			apiMenu.push(...btnPath)
		}
		let userInfo = tool.data.get("USER_INFO")
		let userMenu = treeFilter(userRoutes, node => {
			return node.meta.role ? node.meta.role.filter(item=>userInfo.role.indexOf(item)>-1).length > 0 : true
		})
		let menu = [...apiMenu, ...userMenu]
		let menuRouter = filterAsyncRouter(menu)
		menuRouter = flatAsyncRoutes(menuRouter)
		menuRouter.forEach(item => {
			router.addRoute("layout", item)
		})
		routes_404_r = router.addRoute(routes_404)
		if (to.matched.length === 0) {
			await router.push(to.fullPath);
		}
		isGetRouter = true;
	}
	beforeEach(to, from)
	next();
	// if(to.meta && to.meta.type == 'menu'){
	// 	LOADING.loading()
	// }
});

router.afterEach((to, from) => {
	afterEach(to, from)
	NProgress.done()
	// if(to.meta && to.meta.type == 'menu'){
		setTimeout(()=>{
			if(LOADING.loader){
				//TODO 请求后端记录加载超时接口
				console.log('进入超时')
				LOADING.closed()
			}
		},100)
	// }
});

router.onError((error) => {
	NProgress.done();
	ElNotification.error({
		title: '路由错误',
		message: error.message
	});
});

//入侵追加自定义方法、对象 拼接前台路由 和 后端路由
router.sc_getMenu = () => {
	const apiMenu = tool.data.get("MENU") || []
	let userInfo = tool.data.get("USER_INFO")
	let userMenu = treeFilter(userRoutes, node => {
		return node.meta.role ? node.meta.role.filter(item=>userInfo.role.indexOf(item)>-1).length > 0 : true
	})
	return [...userMenu, ...apiMenu]
}
//获取后端路由
router.sc_getMenu_b = () => {
	const apiMenu = tool.data.get("MENU") || []
	return [...apiMenu]
}
//获取所有route
router.getAllroutes = () => {
	const apiMenu = tool.data.get("MENU") || []
	const btnPath = tool.data.get('BTN_PATH')
	if(btnPath){
		apiMenu.forEach(item1 => {
			btnPath.forEach(item2 => {
				if (item2.path.includes(item1.path)) {
					item1['children'].push(item2)
				}
			})
		})
	}
	let menu = [...apiMenu]
	let menuRouter = filterAsyncRouter(menu)
	menuRouter = flatAsyncRoutes(menuRouter)
	return menuRouter
}

//转换
function filterAsyncRouter(routerMap) {
	const accessedRouters = []
	routerMap.forEach(item => {
		item.meta = item.meta?item.meta:{};
		//处理外部链接特殊路由
		if(item.meta.type=='iframe'){
			item.meta.url = item.path;
			item.path = `/i/${item.name}`;
		}
		item.meta.icon = item.icon
		//MAP转路由对象
		let route = {
			path: item.path,
			name: item.name,
			meta: item.meta,
			redirect: item.redirect,
			children: item.children ? filterAsyncRouter(item.children) : null,
			component: loadComponent(item.component)
		}
		accessedRouters.push(route)
	})
	return accessedRouters
}
function loadComponent(component){
	if(component){
		return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}`)
	}else{
		return () => import(`@/layout/other/empty`)
	}

}

//路由扁平化
function flatAsyncRoutes(routes, breadcrumb=[]) {
	let res = []
	routes.forEach(route => {
		const tmp = {...route}
		if (tmp.children) {
			let childrenBreadcrumb = [...breadcrumb]
			childrenBreadcrumb.push(route)
			let tmpRoute = { ...route }
			tmpRoute.meta.breadcrumb = childrenBreadcrumb
			delete tmpRoute.children
			res.push(tmpRoute)
			let childrenRoutes = flatAsyncRoutes(tmp.children, childrenBreadcrumb)
			childrenRoutes.map(item => {
				res.push(item)
			})
		} else {
			let tmpBreadcrumb = [...breadcrumb]
			tmpBreadcrumb.push(tmp)
			tmp.meta.breadcrumb = tmpBreadcrumb
			res.push(tmp)
		}
	})
	return res
}

//过滤树
function treeFilter(tree, func) {
	return tree.map(node => ({ ...node })).filter(node => {
		node.children = node.children && treeFilter(node.children, func)
		return func(node) || (node.children && node.children.length)
	})
}

export default router
