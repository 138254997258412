import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/exam/examWelding/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	weldingQuality:{
		url: `${config.API_URL}/erp/exam/examWelding/weldingQuality`,
		name: "验收",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	weldingExam:{
		url: `${config.API_URL}/erp/exam/examWelding/weldingExam`,
		name: "审批",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/exam/examWelding/detail`,
		name: "详情",
		get: async function (param = {}) {
			return await http.get(this.url + '?id=' + param);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/exam/examWelding/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
