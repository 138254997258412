<!--选择销售单弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'500px'"
		custom-class="popTable"
		:type="type"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<splitpanes
							:horizontal="true"
							@resized="handleResized"
						>
							<pane>
								<el-container
									class="fatherCont"
									:style="multiple && 'padding-right: 10px'"
								>
									<sy-search-Form
										v-show="!hideForm"
										ref="refFormBar"
										:configs="searchConfig"
										:show-item="showItem"
										@fetch-data="fetchData"
										@set-show="handleFitShow"
									/>
									<el-header class="tableTopBar">
										<div class="left-panel"/>
										<div class="right-panel">
											<!--切换显示搜索栏hide-after: 延迟隐藏-->
											<el-tooltip
												:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
												placement="bottom"
												:hide-after="0"
											>
												<el-link @click="handleShowSearch">
													搜索
													<el-icon>
														<template v-if="hideForm">
															<el-icon-arrowDown />
														</template>
														<template v-else>
															<el-icon-arrowUp />
														</template>
													</el-icon>
												</el-link>
											</el-tooltip>
										</div>
									</el-header>
									<el-main class="nopadding bgwhite">
										<!--主数据表格-->
										<scTable
											ref="table"
											:api-obj="tableConfig.apiObj"
											:column="tableConfig.columns"
											:params="tableConfig.params"
											row-key="id"
											:checkbox="multiple"
											radio-check
											border
											highlight-current-row
                      :ref-query-form="refFormBar"
											remote-sort
											remote-filter
											hide-column-setting
											hide-setting
											:pagination-layout="'total, prev, pager, next, jumper'"
											@selection-change="selectChange"
											@current-change="currentChange"
											@row-dblclick="dblClick"
											@row-click="handleRowClick"
											@select="handleSelect"
											@data-change="fatherDataChange"
										>

											<template #orderNo="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refDetail')"
												>
													{{ scope.row.orderNo }}
												</el-link>
											</template>
											<!--收款状态-->
											<template #receiptStatus="{ scope }">
												<el-tag
													v-if="scope.row.receiptStatus === 0"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未收款
												</el-tag>
												<el-tag
													v-if="scope.row.receiptStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分收款
												</el-tag>
												<el-tag
													v-if="scope.row.receiptStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部收款
												</el-tag>
											</template>
											<!--发货状态-->
											<template #invoiceStatus="{ scope }">
												<el-tag
													v-if="scope.row.invoiceStatus === 0"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未发货
												</el-tag>
												<el-tag
													v-if="scope.row.invoiceStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分发货
												</el-tag>
												<el-tag
													v-if="scope.row.invoiceStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部发货
												</el-tag>
												<el-tag
													v-if="scope.row.invoiceStatus === 3"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已终止
												</el-tag>
											</template>
											<!--退货状态-->
											<template #refundStatus="{ scope }">
												<el-tag
													v-if="scope.row.refundStatus === 0"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 2"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部退货
												</el-tag>
											</template>
											<template #supplement="{ scope }">
												<span>{{ scope.row.supplement == 0? "否":"是" }}</span>
											</template>
											<template #invoiceType="{ scope }">
												<span v-if="scope.row.invoiceType == 1">增值税专用发票</span>
												<span v-if="scope.row.invoiceType == 2">增值税普通发票</span>
												<span v-if="scope.row.invoiceType == 3">增值税普通发票电子票</span>
												<span v-if="scope.row.invoiceType == 4">收据</span>
												<span v-if="scope.row.invoiceType == 5">其他</span>
											</template>
											<template #orderType="{ scope }">
												<span v-if="scope.row.orderType == 1">合约销售</span>
												<span v-if="scope.row.orderType == 2">直接销售</span>
												<span v-if="scope.row.orderType == 3">批量销售</span>
												<span v-if="scope.row.orderType == 4">其他</span>
											</template>
											<template #paymentMethod="{ scope }">
												<span v-if="scope.row.paymentMethod == 1">现金</span>
												<span v-if="scope.row.paymentMethod == 2">转账</span>
												<span v-if="scope.row.paymentMethod == 3">支票</span>
												<span v-if="scope.row.paymentMethod == 4">其他</span>
											</template>
											<template #priceMethod="{ scope }">
												<span v-if="scope.row.priceMethod == 1">含税</span>
												<span v-if="scope.row.priceMethod == 0">未税</span>
											</template>


										</scTable>
									</el-main>
									<sy-switch-btn
										:text="'明细'"
										:opened="leftShow"
										bottom
										@show-left="handleShowLeft"
									/>
								</el-container>
							</pane>
							<!--明细数据-->
							<pane
								v-if="leftShow"
								:size="paneSize"
								max-size="70"
								min-size="20"
							>
								<el-container
									class="sonCont"
									:style="multiple && 'padding-right: 10px'"
								>
									<el-main class="nopadding">
										<!--明细-->
										<scTable
											ref="tableSon"
											:api-obj="tableSonConfig.apiObj"
											:column="tableSonConfig.columns"
											:params="tableSonConfig.params"
											row-key="id"
											border
											remote-sort
										>
											<template #invoiceStatus="{ scope }">
												<el-tag
													v-if="scope.row.invoiceStatus === 0"
													class="myTag"
													type="danger"
													:size="$store.state.global.uiSize"
												>
													未发货
												</el-tag>
												<el-tag
													v-if="scope.row.invoiceStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分发货
												</el-tag>
												<el-tag
													v-if="scope.row.invoiceStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部发货
												</el-tag>
											</template>
											<template #refundStatus="{ scope }">
												<el-tag
													v-if="scope.row.refundStatus === 0"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分退货
												</el-tag>
												<el-tag
													v-if="scope.row.refundStatus === 2"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全部退货
												</el-tag>
											</template>
											<template #ticketStatus="{ scope }">
												<el-tag
													v-if="scope.row.ticketStatus === 0"
													type="danger"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未开票
												</el-tag>
												<el-tag
													v-if="scope.row.ticketStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													部分开票
												</el-tag>
												<el-tag
													v-if="scope.row.ticketStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													全额开票
												</el-tag>
											</template>
											<template #fz_goodsName="{ scope }">
												<el-link
													type="primary"
													@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
												>
													{{ scope.row.fz_goodsName }}
												</el-link>
											</template>
										</scTable>
									</el-main>
								</el-container>
							</pane>
						</splitpanes>
					</pane>
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumn"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
<!--			<el-button-->
<!--				:size="$store.state.global.uiSize"-->
<!--				@click="visible = false"-->
<!--			>-->
<!--				关闭-->
<!--			</el-button>-->
		</template>
		<Detail ref="refDetail" />
		<GoodsDetail ref="refGoodsDetail" />
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/sale/order/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
export default defineComponent ({
	name: "workingGroup",
	components: {GoodsDetail, Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择工作组' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			type:"detail",
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '订单编号',
					prop: 'orderNo',
					width: 200,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.produceCraftWorkgroup.getList,
				params: {},
				columns: [
          {
            label: '工作组名称',
            prop: 'name',
            width: 250,
            fixed: 'left',
            sortable: 'custom',
          },	{
						label: '工作组编号',
						prop: 'number',
						width: 250,
						fixed: 'left',
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '职员全名',
						prop: 'workerName',
						edit: 'find',
						width: 150,
						sortable: 'custom',

					},
					{
						label: '职员工号',
						prop: 'code',
						width: 120,
						disable: true,
						sortable: 'custom',

					},
					{
						label: '职员电话',
						prop: 'phone',
						width: 120,
						disable: true,
						sortable: 'custom',

					},
					{
						label: '职员部门',
						prop: 'departmentName',
						width: 120,
						disable: true,
						sortable: 'custom',

					},
				],
			},
			//搜索表单配置
			searchConfig: {
				labelWidth: 80,
				itemList: [
					{
						label: '订单编号',
						prop: 'orderNo',
						type: 'input',
						default: true,
						span: 8,
						max: 32,
					},
					{
						label: '客户',
						span: 8,
						prop: 'fz_customerName',
						type: 'popCustomer',
						default: true,
						fields: ['customerId','fz_customerName']
					},
					{
						label: '联系人',
						span: 8,
						prop: 'contacts',
						type: 'input',
						short: 12,
						max: 32,
						default: true,
					},
					{
						label: '联系手机',
						span: 8,
						prop: 'phone',
						type: 'input',
						max: 32,
						default: true,
					},
					{
						label: '联系电话',
						span: 8,
						prop: 'tel',
						type: 'input',
						max: 32,
					},
					{
						label: '送货地址',
						span: 8,
						prop: 'shipAddress',
						type: 'input',
						max: 32,
					},
					{
						label: '发票类型',
						span: 8,
						prop: 'invoiceType',
						type: 'select',
						data: [
							{value: 1, label: '增值税专用发票'},
							{value: 2, label: '增值税普通发票'},
							{value: 3, label: '增值税普通发票电子票'},
							{value: 4, label: '收据'},
							{value: 5, label: '其他'},
						]
					},
					{
						label: '交货日期',
						span: 8,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'deliveryDate0',
							},
							{
								prop: 'deliveryDate1',
							},
						],
					},
					{
						label: '业务员',
						span: 8,
            max:32,
						prop: 'fz_salesmanName',
						type: 'popUser',
						fields: ['salesmanId','fz_salesmanName']
					},
					{
						label: '是否补单',
						span: 8,
						prop: 'supplement',
						type: 'select',
						short: 12,
						data: [
							{
								label: '是',
								value: 1,
							},
							{
								label: '否',
								value: 0,
							},
						],
					},
					{
						label: '创建日期',
						span: 8,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'creationDate0',
							},
							{
								prop: 'creationDate1',
							},
						],
					},
					{
						label: '整单折扣',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'discount0',
								max: 5,
								append: '%'
							},
							{
								prop: 'discount1',
								max: 5,
								append: '%'
							}
						],
					},
					// {
					// 	label: '共享人',
					// 	span: 8,
					// 	prop: 'fz_shareUserName',
					// 	type: 'popUser',
					// 	short: 12,
					// 	fields: ['shareUser', 'fz_shareUserName'],
					// },
					{
						label: '订单类型',
						span: 8,
						prop: 'orderType',
						type: 'select',
						data: [
							{value: 1, label: '合约销售'},
							{value: 2, label: '直接销售'},
							{value: 3, label: '批量销售'},
							{value: 4, label: '其他'},
						]
					},
					{
						label: '收款期限',
						span: 8,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'collectDate0',
							},
							{
								prop: 'collectDate1',
							},
						],
					},
					{
						label: '收款金额',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'collectAmount0',
								max: 10,
							},
							{
								prop: 'collectAmount1',
								max: 10,
							}
						],
					},
					{
						label: '发货方式',
						span: 8,
						prop: 'shipMethod',
						type: 'selectDicts',
						//字典类型编码
						typeCode: 'erp_sale_order-shipMethod',
					},
					{
						label: '物流公司',
						span: 8,
						prop: 'fz_logisticsName',
						type: 'input',
						max: 32,
					},
					{
						label: '物流联系人',
						span: 8,
						prop: 'contactsName',
						type: 'input',
						max: 32,
					},
					{
						label: '物流电话',
						span: 8,
						prop: 'contactsTel',
						type: 'input',
						max: 32,
					},
					{
						label: '运单号',
						span: 8,
						prop: 'waybillNo',
						type: 'input',
						max: 32,
					},
					{
						label: '运输费用',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'transportCost0',
								max: 10,
							},
							{
								prop: 'transportCost1',
								max: 10,
							}
						],
					},
					{
						label: '付款方式',
						span: 8,
						prop: 'paymentMethod',
						type: 'select',
						data: [
							{value: 1, label: '现金'},
							{value: 2, label: '转账'},
							{value: 3, label: '支票'},
							{value: 4, label: '其他'},
						]
					},
					{
						label: '付款账户',
						span: 8,
						prop: 'paymentAccount',
						type: 'popAccount',
					},
					{
						label: '发货状态',
						span: 8,
						prop: 'invoiceStatus',
						type: 'select',
						data: [
							{value: 0, label: '未发货'},
							{value: 1, label: '部分发货'},
							{value: 2, label: '全部发货'},
						]
					},
					{
						label: '退货状态',
						span: 8,
						prop: 'refundStatus',
						type: 'select',
						data: [
							{value: 0, label: '未退货'},
							{value: 1, label: '部分退货'},
							{value: 2, label: '全部退货'},
						]
					},
					{
						label: '备注',
						span: 8,
						prop: 'remark',
						type: 'input',
						max: 100,
					},
					{
						label: '操作用户',
						span: 8,
						prop: 'fz_operateName',
						type: 'popUser',
						short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'addTime0',
							},
							{
								prop: 'addTime1',
							},
						],
					},
				]
			},
		})

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			state.selections = []
			state.selData = []
			if(data){
				Object.assign(state.tableConfig.params, data)
			}
			state.isConfirm = true
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行单击
		// const firClick = (row) => {
		// 	if(props.multiple){
		// 		//添加已选数据
		// 		setSelData(row)
		// 	}
		// }

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}

		}

		const firClick = (row) => {
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}

		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false)
			if (data && index === -1) {
				state.selData.push(data)


			} else {
				state.selData = state.selData.filter(item => item.id !== data.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false

			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()

		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			if(!data){
				data = {}
			}
			Object.assign(data, state.tableConfig.params)
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if (row){
				firClick(row)
			}
			if(state.leftShow){
				state['tableSon'].reload({ workGroupId: row.id })
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				console.log(6)
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.produceCraftWorkgroup.selectWorkGroupDetail
				//传入父级ID
				state.tableSonConfig.params = { workGroupId: res.data.list[0].id }
				console.log(proxy.$refs.tableSon)
				//根据主数据加载子数据
				// handleRowClick(res.data.list[0])
			}
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
			// emit('set-data', state.selData)
		}

		return{
			...toRefs(state),
			clearSelections,
			delSelData,
			tableSelselectChange,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			hideDialog,
			handleSelect,
			dblClick,
			firClick
		}
	},
})
</script>
