// 全局加载
import { ElLoading } from 'element-plus'
import Config from '@/config'

let loadingService = {
    loader: null,
    loading: () => {
        console.log('开始加载')
        loadingService.loader = ElLoading.service({
            lock: true,
            text: Config.loadingText || '正在处理中，请稍等...',
            background: '#ffffff',
        })
    },
    closed: () => {
        if(loadingService.loader){
            console.log('关闭加载')
            loadingService.loader.close()
            loadingService.loader = null
        }
    }
}

export default loadingService
