import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/system/custom/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/cfg/system/custom/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/system/custom/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/custom/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/system/custom/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/cfg/system/custom/updateFlag0`,
		name: "状态禁用",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/cfg/system/custom/updateFlag1`,
		name: "状态启用",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	getMenus: {
		url: `${config.API_URL}/sys/base/menu/getMenus`,
		name: "菜单列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getCustomField: {
		url: `${config.API_URL}/cfg/system/custom/getCustomField`,
		name: "转单查询自定义字段",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getListByKeyword: {
		url: `${config.API_URL}/sys/base/menu/getListByKeyWord`,
		name: "适用单据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	queryCustomExist: {
		url: `${config.API_URL}/cfg/system/custom/queryCustomExist`,
		name: "是否存在自定义字段",
		get: async function(menuCode){
			return await http.get(this.url+'?menuCode='+menuCode);
		}
	},
}
