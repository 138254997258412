import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateRefundStatus: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/updateRefundStatus`,
		name: "确认退款",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/submitProcess`,
		name: "提交审核",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	handleCancel: {
		url: `${config.API_URL}/fms/sell/advreceiptRefund/handleCancel`,
		name: "作废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
