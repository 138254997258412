import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/excel/template/getList`,
		name: "查询模板数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/excel/template/insert`,
		name: "模板新增",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/excel/template/update`,
		name: "模板编辑",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	queryData: {
		url: `${config.API_URL}/fms/excel/template/queryData`,
		name: "数据回填",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	exportExcel: {
		url: `${config.API_URL}/fms/excel/template/exportExcel`,
		name: "数据回填",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	codeCheck: {
		url: `${config.API_URL}/fms/excel/template/check`,
		name: "语法检查",
		get: async function (code) {
			return await http.get(this.url + '?code=' + code);
		}
	},
	excelDefault: {
		url: `${config.API_URL}/fms/excel/template/excelDefault`,
		name: "语法检查",
		get: async function (data={}) {
			return await http.post(this.url, data);
		}
	}
}
