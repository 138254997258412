import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/activiti/goodsApproval/getList`,
		name: "查看所有审核任务",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	queryTask: {
		url: `${config.API_URL}/cfg/activiti/goodsApproval/queryTask`,
		name: "查询流程",
		get: async function(id){
			return await http.get(this.url + "?id=" + id);
		}
	},
	completeTask: {
		url: `${config.API_URL}/cfg/activiti/goodsApproval/completeTask`,
		name: "通过流程审核",
		put: async function(id){
			return await http.put(this.url + "?id=" + id);
		}
	},
	rejectTask: {
		url: `${config.API_URL}/cfg/activiti/goodsApproval/rejectTask`,
		name: "驳回流程审核",
		put: async function(id){
			return await http.put(this.url + "?id=" + id);
		}
	},
	rejectTaskUpper: {
		url: `${config.API_URL}/cfg/activiti/goodsApproval/rejectTaskUpper`,
		name: "驳回到上一级",
		put: async function(id){
			return await http.put(this.url + "?id=" + id);
		}
	},

}
