import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	del: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detail: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},


	getCollectDetailList: {
		url: `${config.API_URL}/erp/uphold/upholdCollectDetail/getList`,
		name: "根据费用收款id查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},


	submitProcess: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateAudit: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/uphold/upholdCollect/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
