import config from "@/config"
import http from "@/utils/request"

export default {
	saleProduct: {
		url: `${config.API_URL}/erp/sale/company/saleProduct`,
		name: "销售统计 - 按产品",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleProductDetail: {
		url: `${config.API_URL}/erp/sale/company/saleProductDetail`,
		name: "销售统计 - 按产品 - 明细",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleMan: {
		url: `${config.API_URL}/erp/sale/company/saleMan`,
		name: "销售统计 - 按业务员",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleManDetail: {
		url: `${config.API_URL}/erp/sale/company/saleManDetail`,
		name: "销售统计 - 按业务员 - 明细",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleOrderGlance: {
		url: `${config.API_URL}/erp/sale/company/saleOrderGlance`,
		name: "销售订单 - 览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleRefundGlance: {
		url: `${config.API_URL}/erp/sale/company/saleRefundGlance`,
		name: "销售退货 - 览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	saleProduce: {
		url: `${config.API_URL}/erp/sale/company/saleProduce`,
		name: "销售生产追踪表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

}
