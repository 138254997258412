<!--选择用户弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :bef-close="false"
    custom-class="popTable"
    fullscreen
    :show-fullscreen="false"
  >
    <el-container>
      <el-main class="nopadding">
        <el-container
          class="mainPanel"
        >
          <el-header class="sel-search">
            <el-input
              v-model="queryForm.keyWords"
              placeholder="搜索"
              style="width: 100%"
              clearable
              @input="handleSearch"
            />
          </el-header>
          <el-main class="nopadding bgwhite">
            <!--主数据表格-->
            <scTable
              ref="table"
              :api-obj="tableConfig.apiObj"
              :column="tableConfig.columns"
              :params="tableConfig.params"
              row-key="id"
              :checkbox="multiple"
              radio-check
              border
              highlight-current-row
              remote-sort
              remote-filter
              hide-column-setting
              hide-setting
              :pagination-layout="'total, prev, pager, next, jumper'"
              @selection-change="selectChange"
              @current-change="currentChange"
              @row-dblclick="dblClick"
            >
              <template #gender="{ scope }">
                <span>{{ scope.row.gender === 1 ? '男' : '女' }}</span>
              </template>
              <template #jobStatus="{ scope }">
                <span>{{ scope.row.jobStatus === 1 ? '转正' : '试用' }}</span>
              </template>
              <template #manager="{ scope }">
                <span>{{ scope.row.manager === 1 ? '是' : '否' }}</span>
              </template>
              <template #login="{ scope }">
                <span>{{ scope.row.login === 1 ? '是' : '否' }}</span>
              </template>
              <!--自定义单元格显示 #{字段名称} -->
              <template #name="{ scope }">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                >
                  {{ scope.row.name }}
                </el-link>
              </template>
              <template #flag="{ scope }">
                <el-tag
                  v-if="scope.row.flag === 1"
                  type="success"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  启用
                </el-tag>
                <el-tag
                  v-if="scope.row.flag === 0"
                  type="danger"
                  class="myTag"
                  :size="$store.state.global.uiSize"
                >
                  停用
                </el-tag>
              </template>
            </scTable>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
    <template #footer>
      <div class="text-center">
        <el-button
          :size="$store.state.global.uiSize"
          type="primary"
          :disabled="isConfirm"
          @click="handleConfirm"
        >
          确定
        </el-button>
        <el-button
          :size="$store.state.global.uiSize"
          @click="visible = false"
        >
          关闭
        </el-button>
      </div>
    </template>
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, toRefs, watch,} from 'vue'
import Detail from './detail'

export default defineComponent ({
	name: "WapSelectUser",
	components: { Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '用户选择' },
		//隐藏设置更新复选
		hideAsyncUpdate: { type: Boolean, default: true },
		//
		asyncAllData: { type: Number, default: 0 },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			asyncAll: props.asyncAllData,
			isDelSel: true,
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '姓名',
					prop: 'name',
					width: 150,
					sortable: true,
				},
			],
			keyWords: "",
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			tableConfig: {
				apiObj: proxy.$API.user.getUserList,
				params: {},
				columns: [
					{
						label: '姓名',
						prop: 'name',
						sortable: true,
					},
				],
			},
		})

		//显示弹窗
		const showDialog = (param) => {
			state.visible = true
			if(param){
				state.tableConfig.params = param
				state.params = Object.assign({}, param)
			}
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//用户搜索
		const handleSearch = () => {
			proxy.$refs.table.reload(state.queryForm)
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}

		//新增修改弹窗
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			handleDetail,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			fetchData,
			handleSearch,
			currentChange,
			selectChange,
			handleConfirm,
			showDialog,
			dblClick,
			hideDialog,
		}
	},
})
</script>
