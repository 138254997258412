import config from "@/config"
import http from "@/utils/request"

export default {
	getList:{
		url:`${config.API_URL}/cfg/data/detection/getList`,
		name:'获取校验列表',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	insertDetection:{
		url:`${config.API_URL}/cfg/data/detection/insert`,
		name:'新增校验',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	updateDetection:{
		url:`${config.API_URL}/cfg/data/detection/update`,
		name:'修改校验',
		get: async function(data={}){
			return await http.put(this.url,data)
		}
	},
	deleteDetection:{
		url:`${config.API_URL}/cfg/data/detection/delete`,
		name:'删除校验',
		get: async function(ids){
			return await http.get(this.url + '?ids=' + ids)
		}
	},
	getDetail:{
		url:`${config.API_URL}/cfg/data/detection/detail`,
		name:'查询校验详情',
		get: async function(id){
			return await http.get(this.url + '?id=' + id)
		}
	},
	getParent:{
		url:`${config.API_URL}/cfg/data/detection/getParent`,
		name:'根据父id查询',
		get: async function(id){
			return await http.get(this.url + '?parentId=' + id)
		}
	},
	updateFlag1:{
		url:`${config.API_URL}/cfg/data/detection/updateFlag1`,
		name:'启用校验',
		get: async function(ids){
			return await http.get(this.url + '?ids=' + ids)
		}
	},
	updateFlag0:{
		url:`${config.API_URL}/cfg/data/detection/updateFlag0`,
		name:'停用校验',
		get: async function(ids){
			return await http.get(this.url + '?ids=' + ids)
		}
	},
	checkName:{
		url:`${config.API_URL}/cfg/data/detection/checkName`,
		name:'校验名称',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	checkNumber:{
		url:`${config.API_URL}/cfg/data/detection/checkNumber`,
		name:'校验编码',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	queryEnd:{
		url:`${config.API_URL}/cfg/data/detection/queryEnd`,
		name:'查询终结点',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/data/detection/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
