<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="客户名称"
        >
          <el-input
            v-model="form.name"
            maxlength="32"
						@blur="handleNameBlur"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="number"
          label="客户编码"
        >
          <el-input
            v-model="form.number"
            maxlength="32"
            clearable
            @blur="handleCodeBlur"
          />
        </el-form-item>
        <el-form-item
            prop="contacts"
            label="联系人"
        >
          <el-input
              v-model="form.contacts"
              maxlength="32"
              clearable
              @blur="handleCodeBlur"
          />
        </el-form-item>
<!--        <el-form-item
            prop="phone"
            label="联系手机"
        >
          <el-input
              v-model="form.phone"
              maxlength="32"
              clearable
              @blur="handleCodeBlur"
          />
        </el-form-item>-->
        <el-form-item
          prop="code"
          label="社会信用代码"
        >
          <el-input
            v-model="form.code"
            maxlength="32"
            clearable
          />
        </el-form-item>
        <!--文本框-->
        <el-form-item
          prop="remark"
          label="备注"
        >
          <!--
          type="textarea"表示文本输入框
          rows="5"默认行数，越大文本框越高
          -->
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="250"
            rows="5"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-filter
    >


      <template #flag="{ scope }">
        <el-tag
            v-if="scope.row.flag === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          启用
        </el-tag>
        <el-tag
            v-if="scope.row.flag === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          停用
        </el-tag>
      </template>


      <template #creditLimit="{ scope }">
        <span v-if="scope.row.creditLimit === -1"></span>
        <span v-if="scope.row.creditLimit === 1">高</span>
        <span v-if="scope.row.creditLimit === 2">中</span>
        <span v-if="scope.row.creditLimit === 3">低</span>
      </template>

      <template #name="{ scope }">
        <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refCustomerDetail', 'id')"
        >
          {{ scope.row.name}}
        </el-link>
      </template>



      <template #paymentMethod="{ scope }">
        <span v-if="scope.row.paymentMethod === 1">款到发货</span>
        <span v-if="scope.row.paymentMethod === 2">发货后X天为收款期限</span>
        <span v-if="scope.row.paymentMethod === 3">开票后X天为收款期限</span>
        <span v-if="scope.row.paymentMethod === 4">每月固定日期收款</span>
      </template>

      <template #commissionType="{ scope }">
        <span v-if="scope.row.commissionType === 1">按单提成</span>
        <span v-if="scope.row.commissionType === 2">按成交金额提成</span>
        <span v-if="scope.row.commissionType === 3">按利润提成</span>
      </template>
      <template #invoiceType="{ scope }">
        <span v-if="scope.row.invoiceType === 1">增值税专用发票</span>
        <span v-if="scope.row.invoiceType === 2">增值税普通发票</span>
        <span v-if="scope.row.invoiceType === 3">增值税普通发票电子票</span>
        <span v-if="scope.row.invoiceType === 4">收据</span>
        <span v-if="scope.row.invoiceType === 5">其他</span>
      </template>
      <template #intentionLevel="{ scope }">
        <span v-if="scope.row.intentionLevel === 1">一级</span>
        <span v-if="scope.row.intentionLevel === 2">二级</span>
        <span v-if="scope.row.intentionLevel === 3">三级</span>
        <span v-if="scope.row.intentionLevel === 4">四级</span>
        <span v-if="scope.row.intentionLevel === 5">五级</span>
        <span v-if="scope.row.intentionLevel === 6">五级</span>
        <span v-if="scope.row.intentionLevel === 6">六级</span>
        <span v-if="scope.row.intentionLevel === 7">七级</span>
        <span v-if="scope.row.intentionLevel === 8">八级</span>
        <span v-if="scope.row.intentionLevel === 9">九级</span>
      </template>
    </SyCopyDialog>
    <CustomerDetail ref="refCustomerDetail"/>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetZnum } from "@/utils/validate"; //resetZnum返回正整数的方法
import CustomerDetail from "@/views/cfg/data/customer/detail.vue"
export default defineComponent({
	emits: ['fetch-data'],
  components:{CustomerDetail},
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const validateName = (rule, value, callback) => {
			if (state.nameMsg) {
				callback(new Error(state.nameMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      refFormBar:null,
      visibles: false,
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			nameMsg:'',
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '用户新增',
			//表单当前数据
			form: {
				manager: 0,
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				name: [{required: true, message: '必填'},{validator: validateName, trigger: 'blur'},],
        number: [{validator: validateCode, trigger: 'blur'}],

			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.customer.getCustomerList,
				//弹窗标题
				title:'复制客户信息',
				//查询匹配的字段
				prop: 'name',
        height: '600px',
				//表格显示列
        columns: [
          {
            label: "客户名称",
            prop: "name",
            width: 200,
            fixed: "left",
            sortable: "custom",
          },
          {
            label: "客户编码",
            prop: "number",
            width: 120,
            sortable: "custom",
          },
          {
            label: "状态",
            prop: "flag",
            width: 90,
            sortable: "custom",
          },
          {
            label: "社会信用代码",
            prop: "code",
            width: 180,
            sortable: "custom",
          },
          {
            label: "销售负责人",
            prop: "fz_saleDirectorName",
            width: 120,
            sortable: "custom",
          },
          // {
          // 	label: '所属集团',
          // 	prop: 'fz_groupName',
          // 	width: 200,
          // 	sortable: 'custom',
          // },
          {
            label: "公司规模",
            prop: "scale",
            width: 120,
            sortable: "custom",
          },
          // {
          // 	label: '所在国家',
          // 	prop: 'country',
          // 	width: 130,
          // 	sortable: 'custom',
          // },
          {
            label: "所在地区",
            prop: "area",
            width: 180,
            sortable: "custom",
          },
          {
            label: "详细地址",
            prop: "address",
            width: 180,
            sortable: "custom",
          },
          /*{
            label: "联系手机",
            prop: "phone",
            width: 120,
            sortable: "custom",
          },*/
          {
            label: "联系电话",
            prop: "tel",
            width: 120,
            sortable: "custom",
          },
          // {
          // 	label: '传真',
          // 	prop: 'fax',
          // 	width: 130,
          // 	sortable: 'custom',
          // },
          // {
          // 	label: '邮政编码',
          // 	prop: 'zipCode',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          {
            label: "电子邮箱",
            prop: "email",
            width: 120,
            sortable: "custom",
          },
          {
            label: "网址",
            prop: "website",
            width: 120,
            sortable: "custom",
          },
          // {
          // 	label: 'QQ',
          // 	prop: 'qq',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          {
            label: "微信",
            prop: "weChat",
            width: 120,
            sortable: "custom",
          },
          // {
          // 	label: '旺旺',
          // 	prop: 'wangwang',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          {
            label: "法定代表人",
            prop: "corporation",
            width: 120,
            sortable: "custom",
          },
          {
            label: "委托代理人",
            prop: "entrust",
            width: 120,
            sortable: "custom",
          },
          {
            label: "备注",
            prop: "remark",
            width: 180,
            sortable: "custom",
          },
          {
            label: "开户行",
            prop: "bank",
            width: 130,
            sortable: "custom",
          },
          {
            label: "账户名称",
            prop: "accountName",
            width: 130,
          },
          {
            label: "银行账号",
            prop: "accountNumber",
            width: 150,
            sortable: "custom",
          },
          {
            label: "联行号",
            prop: "bankNo",
            width: 130,
            sortable: "custom",
          },
          {
            label: "许可证号",
            prop: "licenseKey",
            width: 130,
            sortable: "custom",
          },
          {
            label: "税号",
            prop: "taxCode",
            width: 130,
            sortable: "custom",
          },
          // {
          // 	label: '税率(%)',
          // 	prop: 'taxRate',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          // {
          // 	label: '发票类型',
          // 	prop: 'invoiceType',
          // 	width: 160,
          // 	sortable: 'custom',
          // },

          {
            label: "信用额度",
            prop: "creditLimit",
            width: 120,
            sortable: "custom",
          },

          {
            label: "客户来源",
            prop: "customerSource",
            width: 120,
            sortable: "custom",
          },
          {
            label: "行业属性",
            prop: "industry",
            width: 120,
            sortable: "custom",
          },
          {
            label: "企业性质",
            prop: "enterpriseNature",
            width: 110,
            sortable: "custom",
          },
          {
            label: "操作用户",
            prop: "fz_operateName",
            width: 110,
            sortable: "custom",
          },
          {
            label: "操作时间",
            prop: "addTime",
            width: 200,
            sortable: "custom",
          },
        ],

			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增客户"
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑客户[" + row.name + ']'
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.customer.detailCustomer.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
      		save()
			state.visibles = false
		}
    const valiForm = () => {
      nextTick(()=>{
        state.refForm.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }

		//提交方法
		const save = () => {
			state['refForm'].validate(async(valid) => {
				if(valid) {
						state.subLoading = true
						let res = null
						if(state.form.id){ //修改
							res = await proxy.$API.customer.updateCustomer.put(state.form)
						}else{
							res = await proxy.$API.customer.insertCustomer.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
              state.visibles = false
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}


    const handleCodeBlur = async (e) => {
      state.codeMsg = ''
      const val = e.target.value
      if (val !== '') {
        const res = await proxy.$API.customer.doCheckCode.post(state.form)
        if (res.code === 500) {
          state.codeMsg = res.msg
          state.refForm.validateField('number').then(result => {
            // 处理验证结果
          }).catch(error => {
            // 处理验证错误
          });
        }
      }
    }

		//编码重复校验
		const handleNameBlur = async (e) => {
			state.nameMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.customer.doCheckName.post(state.form)
				console.log(res.code)
				if (res.code === 500) {
					state.nameMsg = res.msg
					state.refForm.validateField('name').then(result => {
						// 处理验证结果
					}).catch(error => {
						// 处理验证错误
					});
				}

			}
		}

    //打开详情
    const handleDetail = (row, ref, field) => {
      const params = Object.assign({}, row)
      if (field) {
        params.id = row[field]
      }
      proxy.$refs[ref].showDialog(params)
    }

		return {
			...toRefs(state),
			handleCodeBlur,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
      valiForm,
			handleNameBlur,
      handleDetail
		}
	},
})
</script>
