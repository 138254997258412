import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/order/goods/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	selectOrderGoods: {
		url: `${config.API_URL}/erp/sale/order/goods/selectOrderGoods`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	queryByOrderIds: {
		url: `${config.API_URL}/erp/sale/order/goods/queryByOrderIds`,
		name: "查询列表数据",
		get: async function (orderIds) {
			return await http.get(this.url + "?orderIds=" + orderIds);
		}
	},
	queryByCustomerId: {
		url: `${config.API_URL}/erp/sale/order/goods/queryByCustomerId`,
		name: "根据商户id查询",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/sale/order/goods/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	sumQueryDetails: {
		url: `${config.API_URL}/erp/sale/order/goods/sumQueryDetails`,
		name: "数据查询",
		get: async function(ids){
			return await http.post(this.url,ids);
		}
	},
	selectChangeDetail: {
		url: `${config.API_URL}/erp/sale/order/goods/selectChangeDetail`,
		name: "历史记录详情-明细",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
