<template>
  <sc-dialog
      ref="dialogRef"
      v-model="visible"
      :title="title"
      :loading="loading"
      :width="500"
      :before-close="beforeClose"
      :trigger-dom="triggerDom"
      :close-on-click-modal="false"
      @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
          v-if="!form.id"
          ref="copyBtn"
          class="ml10"
          :size="$store.state.global.uiSize"
          @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm formset">
      <el-form
          ref="formRef"
          :model="form"
          :label-width="90"
          size="small"
          :rules="rules"
      >
        <el-form-item label="父级部门">
          <sc-table-select
              v-model="form.fz_parentName"
              :api-obj="$API.department.getList"
              :params={parentId:0}
              hide-pagination
              :props="{ label: 'name', value: 'id', keyword: 'name' }"
              :table-width="500"
              @change="(val) => handleSelChange(val, ['parentId','fz_parentName'])"
          >
            <el-table-column
                prop="name"
                width="180"
                label="部门名称"
            />
            <el-table-column
                prop="code"
                width="80"
                label="部门代码"
            />
            <el-table-column
                prop="type"
                width="70"
                label="部门类型"
            >
              <template #default="scope">
                <span v-if="scope.row.type == 1">公司</span>
                <span v-else-if="scope.row.type == 2">部门</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="fz_managerName"
                label="部门经理"
            />
          </sc-table-select>
        </el-form-item>
        <el-form-item
            prop="name"
            label="部门名称"
        >
          <el-input
              v-model="form.name"
              clearable
              placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
            prop="type"
            label="部门类型"
        >
          <el-select
              v-model="form.type"
              clearable
              placeholder="请选择"
              style="width: 100%;"
          >
            <el-option
                label="公司"
                value="1"
            />
            <el-option
                label="部门"
                value="2"
            />
          </el-select>
        </el-form-item>
        <el-form-item
            prop="code"
            label="部门代码"
        >
          <el-input
              v-model="form.code"
              clearable
              placeholder="请输入"
              @blur="handleCodeBlur"
              maxlength="32"
          />
        </el-form-item>
        <el-form-item
            prop="manager"
            label="部门经理"
        >
          <sc-table-select
              v-model="form.manager"
              :api-obj="$API.user.getUserList"
              :props="{ label: 'name', value: 'id', keyword: 'name' }"
              :table-width="500"
              @change="(val) => handleSelChange(val, ['managerId'])"
          >
            <el-table-column
                prop="name"
                label="姓名"
            />
            <el-table-column
                prop="code"
                label="工号"
            />
            <el-table-column
                prop="fz_departmentName"
                label="部门"
            />
          </sc-table-select>
        </el-form-item>
        <el-form-item
            prop="headcount"
            label="编制人数"
        >
          <el-input
              v-model="form.headcount"
              clearable
              placeholder="请输入"
              @input="(val) => handleZnumIpt(val, 'headcount')"
          />
        </el-form-item>
        <el-form-item
            prop="post"
            label="部门职能"
        >
          <el-input
              v-model="form.post"
              type="textarea"
              placeholder="请输入"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-popconfirm
          :title="$TIPS.SAVE"
          :visible="visibles"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click="valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
          :title="$TIPS.CONFIRM_RESET"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @confirm="reset(false)"
      >
        <template #reference>
          <el-button
              :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--      <el-popconfirm-->
      <!--        :title="$TIPS.CONFIRM_CLOSE"-->
      <!--        confirm-button-text="确定"-->
      <!--        cancel-button-text="取消"-->
      <!--        @confirm="handleConfirm"-->
      <!--      >-->
      <!--        <template #reference>-->
      <!--          <el-button-->
      <!--            :size="$store.state.global.uiSize"-->
      <!--          >-->
      <!--            关闭-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
        ref="copyDialog"
        :config="copyConfig"
        @set-form="setFormData"
        remote-sort
        :ref-query-form="refFormBar"
    />
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick,
} from "vue";
import {resetZnum} from "@/utils/validate";

export default defineComponent({
  name: "SelectDepartmentEdit",
  emits: ["fetch-data"],
  setup(props, {emit}) {
    //验证code重复
    const validateCode = (rule, value, callback) => {
      if (state.codeMsg) {
        callback(new Error(state.codeMsg));
      } else {
        callback();
      }
    };
    const {proxy} = getCurrentInstance();
    const state = reactive({
      refFormBar:null,
      visibles: false,
      loading: false,
      triggerDom: {},
      //提交加载动画
      subLoading: false,
      dialogRef: null,
      //表单label宽度
      labelWidth: 70,
      formRef: null,
      codeMsg: "",
      //弹窗显示属性
      visible: false,
      //弹窗宽度
      width: 500,
      //弹窗标题
      title: "新增",
      //表单当前数据
      form: {},
      //暂存原始数据
      orgForm: {},
      rules: {
        name: [
          {required: true, trigger: "blur", message: "必填"},
        ],
        // code: [
        // 	{required: true, trigger: 'blur', message: '请输入部门代码'},
        // 	{validator: validateCode},
        // ],
        type: [
          {required: true, trigger: "change", message: "必填"},
        ]
      },
      //复制信息配置
      copyConfig: {
        //数据接口对象
        apiObj: proxy.$API.department.getList,
        hidePagination: true,
        params: {parentId: 0},
        //弹窗标题
        title: "复制部门信息",
        width: "50%",
        //表格显示列
        columns: [
          {
            label: "部门名称",
            prop: "name",
            width: 180,
            sortable: "custom",
            fixed: "left",
            showOverflowTooltip: true,
          },
          {
            label: "组织类型",
            prop: "type",
            width: 110,
            sortable: "custom",
            filters: [
              {text: "公司", value: "1"},
              {text: "部门", value: "2"}
            ],
          },
          {
            label: "部门代码",
            prop: "code",
            width: 110,
            sortable: "custom",
            showOverflowTooltip: true,
          },
          {
            label: "部门经理",
            prop: "fz_managerName",
            width: 110,
            sortable: "custom",
            showOverflowTooltip: true,
          },
          // {
          // 	label: '部门职能',
          // 	prop: 'post',
          // 	sortable: 'custom',
          // 	showOverflowTooltip: true,
          // },
          // {
          // 	label: '编制人数',
          // 	prop: 'headcount',
          // 	width: 120,
          // 	sortable: 'custom',
          // 	showOverflowTooltip: true,
          // },
          // {
          // 	label: '状态',
          // 	prop: 'flag',
          // 	width: 80,
          // 	showOverflowTooltip: true,
          // },
          // {
          // 	label: '操作用户',
          // 	prop: 'fz_operateName',
          // 	width: 120,
          // 	sortable: 'custom',
          // 	showOverflowTooltip: true,
          // },
          // {
          // 	label: '操作时间',
          // 	prop: 'addTime',
          // 	width: 170,
          // 	showOverflowTooltip: true,
          // },
        ],
        searchConfig: {
          //表单标题宽度
          labelWidth: 60,
          //表单项配置
          itemList: [
            //文本框
            {
              label: "部门名称",
              prop: "name",
              type: "input",
              max: 20,
              default: true,
              span: 8,
            },
            //下拉框
            {
              label: "组织类型",
              prop: "type",
              type: "select",
              default: true,
              span: 8,
              data: [
                {value: "1", label: "公司"},
                {value: "2", label: "部门"},
              ]
            },
            {
              label: "部门代码",
              prop: "code",
              type: "input",
              span: 8,
              max: 20,
              clearable: true,
              default: true,
            },
            {
              label: "部门经理",
              prop: "manager",
              type: "tableSelect",
              span: 8,
              config: {
                apiObj: proxy.$API.user.getUserList,
                props: {
                  label: "name",
                  value: "id",
                  keyword: "keyword"
                },
                columns: [
                  {
                    label: "姓名",
                    prop: "name",
                  },
                  {
                    label: "工号",
                    prop: "code",
                  },
                  {
                    label: "上级所属部门",
                    prop: "fz_departmentName",
                  },
                ],
                //赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
                assignFields: [
                  {
                    //对应表格列表数据字段
                    value: "id",
                    //赋值给到的字段名称
                    field: "managerId",
                  },
                ],
              }
            },
            {
              label: "部门职能",
              prop: "post",
              type: "input",
              max: 30,
              span: 8,
            },
            {
              label: "编制人数",
              prop: "headcount",
              type: "number",
              max: 10,
              span: 8,
            },
            {
              label: "操作用户",
              prop: "fz_operateName",
              type: "popUser",
              short: 12,
              span: 8,
              fields: ["operateId", "fz_operateName"],
            },
            {
              label: "操作时间",
              type: "dateTimeRange",
              span: 8,
              //开始结束时间字段
              fields: [
                {
                  prop: "fz_addTime0",
                },
                {
                  prop: "fz_addTime1",
                },
              ],
            },
          ],
        },
      }
    });
    const showDialog = async (row) => {
      state.visible = true;
      nextTick(async () => {
        if (JSON.stringify(row) === "{}") { //新增
          state.title = "新增";
          state.orgForm = Object.assign({}, state.form);
        } else { //编辑
          state.title = "编辑";
          if (row.parentId) {  //添加子级
            //根据id查询
            const res = await proxy.$API.department.detail.get(row.id);
            state.form = Object.assign({}, res.data);
            state.orgForm = Object.assign({}, res.data);
            await getParentNode(row);
            await setManager(row);
          } else {
            const res = await proxy.$API.department.detail.get(row.id);
            if (res.code === 200) {
              state.form = Object.assign({}, res.data);
              state.orgForm = Object.assign({}, res.data);
            }
            await setManager(row);
            state.form["parent"] = row.parent;
            state.orgForm["parent"] = row.parent;
          }
        }
      });
    };

    //编码重复校验
    const handleCodeBlur = async (e) => {
      state.codeMsg = "";
      const val = e.target.value;
      if (val !== "") {
        const res = await proxy.$API.department.doCheckCode.post(state.form);
        if (res.code === 500) {
          state.codeMsg = res.msg;
          state["formRef"].validateField("code");
        }
      }
    };

    const getParentNode = async (row) => {
      if (row.parentId && row.parentId !== 0) {
        const res = await proxy.$API.department.getParent.get(row.parentId);
        if (res.data) {
          state.form["parent"] = res.data;
          state.orgForm["parent"] = res.data;
        }
      }
    };

    //根据id查询部门经理
    const setManager = async (row) => {
      //初始化集团选择对象
      if (row.managerId) {
        const res = await proxy.$API.user.detailUser.get(row.managerId);
        if (res.code === 200) {
          state.form["manager"] = res.data;
        }
      }
    };

    const copyData = () => {
      nextTick(() => {
        //把触发元素传给弹窗
        proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn;
        proxy.$refs.copyDialog.showDialog();
      });
    };

    const beforeClose = (done) => {
      nextTick(() => {
        proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
          done();
          reset(true);
        });
      });
    };
    const hideDialog = () => {
      state.visible = false;
      reset(true);
    };
    const handleConfirm = () => {
      save();
      state.visibles = false;
    };
    const valiForm = () => {
      nextTick(() => {
        state.formRef.validate((valid) => {
          if (valid) {
            state.visibles = true;
          } else {
            state.visibles = false;
          }
        });
      });
    };
    //确定按钮
    const save = () => {
      console.log(state.form, "state.form");
      state["formRef"].validate(async (valid) => {
        if (valid) {
          // proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
          state.subLoading = true;
          let res;
          if (state.form.id) { //修改
            res = await proxy.$API.department.update.put(state.form);
          } else { //新增
            res = await proxy.$API.department.insert.post(state.form);
          }
          if (res.code === 200) {
            proxy.$baseMessage(res.msg, "success");
            emit("fetch-data");
            hideDialog();
          } else {
            proxy.$baseMessage(res.msg, "error");
          }
          state.subLoading = false;
          // })
        }
      });
    };
    //重置表单
    const reset = (isClose) => {
      state["formRef"].resetFields();
      const orgForm = state.orgForm;
      Object.keys(state.form).forEach((item) => {
        delete state.form[item];
      });
      if (!isClose) {
        state.form = Object.assign({}, orgForm);
      }
    };

    const handleSelChange = (data, fields) => {
      if (data.length > -1) { //多选
        let ids;
        if (data.length > 0) {
          //取得选中的id串
          ids = data.map((item) => item.id).join();
        } else {
          ids = "";
        }
        state.form[fields[0]] = ids;
      } else { //单选
        if (data && data.id) {
          //将选择的数据id赋值给指定的form中的属性 例：groupId
          state.form[fields[0]] = data.id;
          state.form[fields[1]] = data.name;
        }
      }
    };
    //只能输入正整数
    const handleZnumIpt = (val, field) => {
      if (val) {
        state.form[field] = resetZnum(val);
      }
    };
    //复制信息点击行后返回
    const setFormData = async (data) => {
      state.form = Object.assign({}, data);
      if (data.parentId && data.parentId !== 0) {
        const res = await proxy.$API.department.getParent.get(data.parentId);
        if (res.data) {
          state.form["parent"] = res.data;
          state.orgForm["parent"] = res.data;
        }
      }
      if (data.fz_managerName && data.managerId) {
        state.form["manager"] = {
          name: data.fz_managerName,
          id: data.managerId
        };
      }
      //删除idq
      delete state.form.id;
    };
    return {
      ...toRefs(state),
      copyData,
      setFormData,
      handleZnumIpt,
      handleSelChange,
      save,
      reset,
      valiForm,
      beforeClose,
      showDialog,
      handleConfirm,
      hideDialog,
      handleCodeBlur,
    };
  },
});
</script>

<style scoped>

</style>
