import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/oa/project/project/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/oa/project/project/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	delete: {
		url: `${config.API_URL}/oa/project/project/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/oa/project/project/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/oa/project/project/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/oa/project/project/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	distribution: {
		url: `${config.API_URL}/oa/project/project/distribution`,
		name: "提成发放",
		get: async function (ids) {
			return await http.get(this.url + '?ids=' + ids);
		}
	},
}
