import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/produce/plan/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/produce/plan/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/produce/plan/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/plan/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/plan/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},
	detailById: {
		url: `${config.API_URL}/erp/produce/plan/detailById`,
		name: "查询详情",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/plan/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/erp/produce/plan/submitProcess`,
		name: "提交审批",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/erp/produce/plan/updateAudit`,
		name: "修改审批状态",
		get: async function (id, auditStatus) {
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	updateProduceStatus: {
		url: `${config.API_URL}/erp/produce/plan/updateProduceStatus`,
		name: "修改生产状态",
		get: async function (ids, produceStatus) {
			return await http.get(this.url + '?ids=' + ids + '&produceStatus=' + produceStatus);
		}
	},

	modifyProduceStatus: {
		url: `${config.API_URL}/erp/produce/plan/modifyProduceStatus`,
		name: "修改生产状态",
		get: async function (id, produceStatus) {
			return await http.get(this.url + '?id=' + id + '&produceStatus=' + produceStatus);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/produce/plan/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	mrpDetails: {
		url: `${config.API_URL}/erp/produce/plan/mrpDetails`,
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	transferProcess: {
		url: `${config.API_URL}/erp/produce/plan/transferProcess`,
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},

	// 生产计划--MRP运算
	mrpSaleOrder: {
		url: `${config.API_URL}/erp/produce/plan/mrpSaleOrder`,
		name: "查询销售订单",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	mrpProducePlan: {
		url: `${config.API_URL}/erp/produce/plan/mrpProducePlan`,
		name: "查询生产计划",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	mrpCompute: {
		url: `${config.API_URL}/erp/produce/plan/mrpCompute`,
		name: "计算",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	mrpInsert: {
		url: `${config.API_URL}/erp/produce/plan/mrpInsert`,
		name: "新增",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	mrpUpdate: {
		url: `${config.API_URL}/erp/produce/plan/mrpUpdate`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	mrpGetList: {
		url: `${config.API_URL}/erp/produce/plan/mrpGetList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	mrpDetail: {
		url: `${config.API_URL}/erp/produce/plan/mrpDetail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	mrpOccupancy: {
		url: `${config.API_URL}/erp/produce/plan/mrpOccupancy`,
		name: "预计占用库存",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	mrpProInsert: {
		url: `${config.API_URL}/erp/produce/plan/mrpProInsert`,
		name: "生产建议新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	mrpOutInsert: {
		url: `${config.API_URL}/erp/produce/plan/mrpOutInsert`,
		name: "委外建议新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	mrpPurOrderInsert: {
		url: `${config.API_URL}/erp/produce/plan/mrpPurOrderInsert`,
		name: "采购建议-采购订单新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	mrpPurApplyInsert: {
		url: `${config.API_URL}/erp/produce/plan/mrpPurApplyInsert`,
		name: "采购建议-采购申请新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	mrpProcessCompute: {
		url: `${config.API_URL}/erp/produce/plan/mrpProcessCompute`,
		name: "加工单计算",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	trackRecord: {
		url: `${config.API_URL}/erp/produce/plan/trackRecord`,
		name: "跟踪-首页数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	trackDetails: {
		url: `${config.API_URL}/erp/produce/plan/trackDetails`,
		name: "跟踪-详情数据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	getPlanDetailId: {
		url: `${config.API_URL}/erp/produce/plan/goods/queryDetailId`,
		name: "查找生产计划明细id",
		get: async function(planId,goodsId){
			return await http.get(this.url+'?planId='+planId +'&goodsId='+goodsId);
		}
	},
	producePlanClose: {
		url: `${config.API_URL}/erp/produce/plan/producePlanClose`,
		name: "关闭订单",
		get: async function(ids){
			return await http.get(this.url+'?ids='+ids);
		}
	},
	producePlanOpen: {
		url: `${config.API_URL}/erp/produce/plan/producePlanOpen`,
		name: "开启订单",
		get: async function(ids){
			return await http.get(this.url+'?ids='+ids);
		}
	},
	producePlanChange: {
		url: `${config.API_URL}/erp/produce/plan/planChange`,
		name: "生产计划变更",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	showHistoricalPlanVersion: {
		url: `${config.API_URL}/erp/produce/plan/showHistoricalPlanVersion`,
		name: "历史记录",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	showHistoryDetail: {
		url: `${config.API_URL}/erp/produce/plan/showHistoryDetail`,
		name: "生产计划历史记录详情",
		get: async function(historyPlanId,planId){
			return await http.get(this.url+'?historyPlanId='+historyPlanId+'&planId='+planId);
		}
	},
}
