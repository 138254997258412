<!--选择采购单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
    :type="type"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
                    <div class="left-panel" />
                    <div class="right-panel">
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
											origin-sel-all
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @select="handleSelect"
                      @select-all="handleSelectAll"
                      @current-change="currentChange"
                      @row-dblclick="dblClick"
                      @row-click="handleRowClick"
                      @data-change="fatherDataChange"
                    >
                      <template #orderNo="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refDetail')"
                        >
                          {{ scope.row.orderNo }}
                        </el-link>
                      </template>

											<template #qualityStatus="{ scope }">
												<el-tag
													v-if="scope.row.qualityStatus === 0"
													style="color: #666;"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 1"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													检测中
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 2"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 3"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													无需检测
												</el-tag>
											</template>

                      <template #fz_supplierName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refSupplierDetail', 'supplierId')"
                        >
                          {{ scope.row.fz_supplierName }}
                        </el-link>
                      </template>
                      <template #fz_companyName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refCompanyDetail', 'companyId')"
                        >
                          {{ scope.row.fz_companyName }}
                        </el-link>
                      </template>
                      <template #auditStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.auditStatus === 0"
                          style="color: #666;"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未提交
                        </el-tag>
                        <el-tag
                          v-if="scope.row.auditStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          审核中
                        </el-tag>
                        <el-tag
                          v-if="scope.row.auditStatus === 2"
                          type="danger"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          已驳回
                        </el-tag>
                        <el-tag
                          v-if="scope.row.auditStatus === 3"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          审核通过
                        </el-tag>
						  <el-tag
							  v-if="scope.row.auditStatus === 4"
							  type="info"
							  class="myTag"
							  :size="$store.state.global.uiSize"
						  >
							  未确认
						  </el-tag>
						  <el-tag
							  v-if="scope.row.auditStatus === 5"
							  type="success"
							  class="myTag"
							  :size="$store.state.global.uiSize"
						  >
							  已确认
						  </el-tag>
                      </template>
                      <template #receiptStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.receiptStatus === 0"
                          style="color: #666;"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未收货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.receiptStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分收货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.receiptStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部收货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.receiptStatus === 3"
                          type="danger"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          已终止
                        </el-tag>
                      </template>
                      <template #refundStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.refundStatus === 0"
                          style="color: #666;"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未退货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分退货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 2"
                          type="danger"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部退货
                        </el-tag>
                      </template>
                      <template #supplement="{ scope }">
                        <span>{{ scope.row.supplement == 0? "否":"是" }}</span>
                      </template>
                      <template #invoiceType="{ scope }">
                        <span v-if="scope.row.invoiceType == 1">增值税专用发票</span>
                        <span v-if="scope.row.invoiceType == 2">增值税普通发票</span>
                        <span v-if="scope.row.invoiceType == 3">增值税普通发票电子票</span>
                        <span v-if="scope.row.invoiceType == 4">收据</span>
                        <span v-if="scope.row.invoiceType == 5">其他</span>
                      </template>
                      <template #paymentStatus="{ scope }">
                        <span v-if="scope.row.paymentStatus == 0">未付款</span>
                        <span v-if="scope.row.paymentStatus == 1">部分付款</span>
                        <span v-if="scope.row.paymentStatus == 2">全部付款</span>
                      </template>
                      <template #orderType="{ scope }">
                        <span v-if="scope.row.orderType == 1">原料采购</span>
                        <span v-if="scope.row.orderType == 2">合同采购</span>
                        <span v-if="scope.row.orderType == 3">直接采购</span>
                        <span v-if="scope.row.orderType == 4">生产采购</span>
                        <span v-if="scope.row.orderType == 5">其他</span>
                      </template>
                      <template #freightPayment="{ scope }">
                        <span v-if="scope.row.freightPayment == 1">无运费</span>
                        <span v-if="scope.row.freightPayment == 2">现金</span>
                        <span v-if="scope.row.freightPayment == 3">转账</span>
                      </template>
                    </scTable>
                  </el-main>
                  <sy-switch-btn
                    :text="'物品明细'"
                    :opened="leftShow"
                    bottom
                    @show-left="handleShowLeft"
                  />
                </el-container>
              </pane>
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="50"
                min-size="20"
              >
                <el-container
                  class="sonCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <el-main class="nopadding">
                    <!--明细-->
                    <scTable
                      ref="tableSon"
                      :api-obj="tableSonConfig.apiObj"
                      :column="tableSonConfig.columns"
                      :params="tableSonConfig.params"
                      row-key="id"
											:checkbox="sonCheck"
                      border
                      remote-sort
											@select="handleSonSelect"
											@selection-change="sonSelChange"
											@select-all="handleSonSelectAll"
											@row-click="handleSonRowClick"
											@data-change="sonDataChange"
                    >
                      <template #fz_goodsName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
                        >
                          {{ scope.row.fz_goodsName }}
                        </el-link>
                      </template>
                      <template #receiptStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.receiptStatus === 0"
                          style="color: #666;"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未收货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.receiptStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分收货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.receiptStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部收货
                        </el-tag>
                      </template>
                      <template #refundStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.refundStatus === 0"
                          class="myTag"
                          style="color: #666;"
                          :size="$store.state.global.uiSize"
                        >
                          未退货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分退货
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 2"
                          type="danger"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部退货
                        </el-tag>
                      </template>
                    </scTable>
                  </el-main>
                </el-container>
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <SupplierDetail ref="refSupplierDetail" />
    <CompanyDetail ref="refCompanyDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from "@/views/erp/purchase/order/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import SupplierDetail from "@/views/cfg/data/supplier/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
export default defineComponent ({
	name: "SelectPurOrder",
	components: {
		Detail,
		GoodsDetail,
		SupplierDetail,
		CompanyDetail,
	},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择采购订单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
      type:'detail',
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '订单编号',
					prop: 'orderNo',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {
				pageNum: 1,
				pageSize: 1000,
			},
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: null,
				params: {},
        columns: [
          {
            label: '订单编号',
            prop: 'orderNo',
            width: 200,
            sortable: 'custom',
            fixed: 'left',
          },
          {
            label: '采购合同',
            prop: 'contractNo',
            width: 200,
            sortable: 'custom',
          },
          {
            label: '供应商',
            prop: 'fz_supplierName',
            width: 200,
            sortable: 'custom',
          },
          {
            label: '审核状态',
            prop: 'auditStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '质检状态',
            prop: 'qualityStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '收货状态',
            prop: 'receiptStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '订单金额',
            prop: 'paymentAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '付款状态',
            prop: 'paymentStatus',
            width: 110,
            sortable: 'custom',
          },

          // {
          //   label: '开票状态',
          //   prop: 'ticketStatus',
          //   width: 150,
          //   sortable: 'custom',
          // },
          {
            label: '开票金额',
            prop: 'ticketAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '发票类型',
            prop: 'invoiceType',
            width: 150,
            sortable: 'custom',
          },
          {
            label: '退货状态',
            prop: 'refundStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '业务员',
            prop: 'fz_salesmanName',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '业务部门',
            prop: 'fz_departmentName',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '交货日期',
            prop: 'deliveryDate',
            width: 130,
            sortable: 'custom',
          },
          {
            label: '交货地址',
            prop: 'deliveryAddress',
            width: 180,
            sortable: 'custom',
          },
          // {
          // 	label: '是否补单',
          // 	prop: 'supplement',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          // {
          //   label: '创建日期',
          //   prop: 'creationDate',
          //   width: 140,
          //   sortable: 'custom',
          // },
          {
            label: '付款期限',
            prop: 'paymentDate',
            width: 130,
            sortable: 'custom',
          },
          {
            label: '支付方式',
            prop: 'freightPayment',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '资金账户',
            prop: 'fz_freightAccountName',
            width: 150,
            sortable: 'custom',
          },
          {
            label: '合计金额',
            prop: 'totalAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '终止原因',
            prop: 'reason',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '退货金额',
            prop: 'refundAmount',
            width: 110,
            sortable: 'custom',
          },
          /*{
            label: '冲抵金额',
            prop: 'offsetAmount',
            width: 120,
            sortable: 'custom',
          },*/
          {
            label: '应付金额',
            prop: 'copewithAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '已付金额',
            prop: 'amountPaid',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '未付金额',
            prop: 'unpaidAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '备注',
            prop: 'remark',
            width: 180,
            sortable: 'custom',
          },
          // {
          // 	label: '操作用户',
          // 	prop: 'fz_operateName',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          // {
          // 	label: '操作时间',
          // 	prop: 'addTime',
          // 	width: 210,
          // 	sortable: 'custom',
          // },
        ],

      },
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
        columns: [
          {
            label: '物品名称',
            prop: 'fz_goodsName',
            sortable: 'custom',
            fixed: 'left',
            width: 180,
          },
          {
            label: '物品编码',
            prop: 'fz_goodsCode',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '规格型号',
            prop: 'fz_goodsSpec',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '数量',
            prop: "quantity",
            width: 110,
          },
          {
            label: '主单位',
            prop: 'unit',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '辅助单位',
            prop: 'assistUnit',
            width: 110,
            sortable: 'custom',
          },

          {
            label: '换算数量',
            prop: 'assistQuantity',
            width: 110,
            sortable: 'custom',
          },

          {
            label: '收货状态',
            prop: 'receiptStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '已收数量',
            prop: 'receiptQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '预收数量',
            prop: 'preReceiptQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '未收数量',
            prop: 'notReceiptQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '未税单价',
            prop: 'price',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '税率(%)',
            prop: 'taxRate',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '含税单价',
            prop: 'taxPrice',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '税额(￥)',
            prop: 'taxAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '货款(￥)',
            prop: 'goodsAmount',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '小计(￥)',
            prop: 'subtotal',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '退货状态',
            prop: 'refundStatus',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '已退数量',
            prop: 'refundQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '预退数量',
            prop: 'preReturnQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '未退数量',
            prop: 'notRefundQuantity',
            width: 110,
            sortable: 'custom',
          },
          // {
          //   label: '开票状态',
          //   prop: 'ticketStatus',
          //   width: 120,
          //   sortable: 'custom',
          // },
          {
            label: '已开票数',
            prop: 'ticketQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '预开票数',
            prop: 'preTicketQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '未开票数',
            prop: 'notTicketQuantity',
            width: 110,
            sortable: 'custom',
          },
          // {
          // 	label: '附件',
          // 	prop: 'files',
          // 	width: 180,
          // 	sortable: 'custom',
          // },
          {
            label: '备注',
            prop: 'abstracts',
            width: 180,
            sortable: 'custom',
          },
        ],

			},
			tableSonConfigCheck: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
        columns: [
          {
            label: '物品名称',
            prop: 'fz_goodsName',
            sortable: 'custom',
            fixed: 'left',
            width: 180,
          },
          {
            label: '物品编码',
            prop: 'fz_goodsCode',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '规格型号',
            prop: 'fz_goodsSpec',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '数量',
            prop: "quantity",
            width: 110,
          },
          {
            label: '主单位',
            prop: 'unit',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '辅助单位',
            prop: 'assistUnit',
            width: 110,
            sortable: 'custom',
          },

          {
            label: '换算数量',
            prop: 'assistQuantity',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '备注',
            prop: 'abstracts',
            width: 180,
            sortable: 'custom',
          },
        ],

			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '订单编号',
						prop: 'orderNo',
						type: 'input',
						default: true,
						max: 30,
						span: 8,
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						type: 'popSupplier',
						default: true,
						span: 8,
						fields: ['supplierId','fz_supplierName']
					},
					{
						label: '联系人',
						prop: 'contacts',
						type: 'input',
						short: 12,
						max: 32,
						span: 8,
						default: true,
					},
					{
						label: '联系手机',
						prop: 'phone',
						type: 'input',
						max: 32,
						span: 8,
					},
					{
						label: '联系电话',
						prop: 'tel',
						type: 'input',
						max: 32,
						span: 8,
					},
					{
						label: '交货地址',
						prop: 'deliveryAddress',
						type: 'input',
						max: 255,
						span: 8,
					},
					{
						label: '发票类型',
						prop: 'invoiceType',
						type: 'select',
						span: 8,
						data: [
							{value: 1, label: '增值税专用发票'},
							{value: 2, label: '增值税普通发票'},
							{value: 3, label: '增值税普通发票电子票'},
							{value: 4, label: '收据'},
							{value: 5, label: '其他'},
						]
					},
					{
						label: '交货日期',
						type: 'dateRange',
						span: 8,
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_deliveryDate0',
							},
							{
								prop: 'fz_deliveryDate1',
							},
						],
					},
					{
						label: '业务员',
						prop: 'fz_salesmanName',
						type: 'popUser',
            max:32,
						span: 8,
						short: 12,
						fields: ['salesmanId','fz_salesmanName']
					},
					// {
					// 	label: '是否补单',
					// 	prop: 'supplement',
					// 	type: 'select',
					// 	short: 12,
					// 	span: 8,
					// 	data: [
					// 		{
					// 			label: '是',
					// 			value: 1,
					// 		},
					// 		{
					// 			label: '否',
					// 			value: 0,
					// 		},
					// 	],
					// },
					{
						label: '创建日期',
						type: 'dateRange',
						span: 8,
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_creationDate0',
							},
							{
								prop: 'fz_creationDate1',
							},
						],
					},
					{
						label: '整单折扣',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_discount0',
								max: 5,
								append: '%'
							},
							{
								prop: 'fz_discount1',
								max: 5,
								append: '%'
							}
						],
					},
					// {
					// 	label: '共享人',
					// 	prop: 'fz_shareUserName',
					// 	type: 'popUser',
					// 	span: 8,
					// 	fields: ['shareUser', 'fz_shareUserName'],
					// },
					{
						label: '订单类型',
						prop: 'orderType',
						type: 'select',
						span: 8,
						short: 12,
						data: [
							{value: 1, label: '原料采购'},
							{value: 2, label: '合同采购'},
							{value: 3, label: '直接采购'},
							{value: 4, label: '生产采购'},
							{value: 5, label: '其他'},
						]
					},
					{
						label: '付款期限',
						type: 'dateRange',
						span: 8,
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_paymentDate0',
							},
							{
								prop: 'fz_paymentDate1',
							},
						],
					},
					{
						label: '付款金额',
						type: 'rangeNum',
						span: 8,
						short: 12,
						//开始结束字段
						fields: [
							{
								prop: 'fz_paymentAmount0',
								max: 10,
							},
							{
								prop: 'fz_paymentAmount1',
								max: 10,
							}
						],
					},
					{
						label: '预付款',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_advanceAmount0',
								max: 10,
							},
							{
								prop: 'fz_advanceAmount1',
								max: 10,
							}
						],
					},
					{
						label: '保证金',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_bondAmount0',
								max: 10,
							},
							{
								prop: 'fz_bondAmount1',
								max: 10,
							}
						],
					},
					{
						label: '收货方式',
						prop: 'collectMethod',
						short: 12,
						span: 8,
						type: 'selectDicts',
						//字典类型编码
						typeCode: 'erp_purchase_order-collectMethod',
					},
					{
						label: '合计运费',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_freightAmount0',
								max: 10,
							},
							{
								prop: 'fz_freightAmount1',
								max: 10,
							}
						],
					},
					{
						label: '支付方式',
						prop: 'freightPayment',
						type: 'select',
						span: 8,
						short: 12,
						data: [
							{value: 1, label: '无运费'},
							{value: 2, label: '现金'},
							{value: 3, label: '转账'},
						]
					},
					{
						label: '资金账户',
						prop: 'freightAccount',
						type: 'popAccount',
						span: 8,
					},
					{
						label: '发货方名称',
						prop: 'shipperName',
						type: 'selectDicts',
						span: 8,
						//字典类型编码
						typeCode: 'erp_purchase_order-shipperName',
					},
					{
						label: '发货方电话',
						prop: 'shipperTel',
						type: 'input',
						span: 8,
						max: 32,
					},
					{
						label: '发货单号',
						prop: 'shipmentNo',
						type: 'input',
						span: 8,
						max: 32,
					},
					{
						label: '合计金额',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_totalAmount0',
								max: 10,
							},
							{
								prop: 'fz_totalAmount1',
								max: 10,
							}
						],
					},
					{
						label: '退货金额',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_refundAmount0',
								max: 10,
							},
							{
								prop: 'fz_refundAmount1',
								max: 10,
							}
						],
					},
					{
						label: '减免金额',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_reductionAmount0',
								max: 10,
							},
							{
								prop: 'fz_reductionAmount1',
								max: 10,
							}
						],
					},
					{
						label: '冲抵金额',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_offsetAmount0',
								max: 10,
							},
							{
								prop: 'fz_offsetAmount1',
								max: 10,
							}
						],
					},
					{
						label: '已付金额',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_amountPaid0',
								max: 10,
							},
							{
								prop: 'fz_amountPaid1',
								max: 10,
							}
						],
					},
					{
						label: '未付金额',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_unpaidAmount0',
								max: 10,
							},
							{
								prop: 'fz_unpaidAmount1',
								max: 10,
							}
						],
					},
					{
						label: '收货状态',
						prop: 'receiptStatus',
						type: 'select',
						span: 8,
						short: 12,
						data: [
							{value: 0, label: '未收货'},
							{value: 1, label: '部分收货'},
							{value: 2, label: '全部收货'},
						]
					},
					{
						label: '退货状态',
						prop: 'refundStatus',
						type: 'select',
						short: 12,
						span: 8,
						data: [
							{value: 0, label: '未退货'},
							{value: 1, label: '部分退货'},
							{value: 2, label: '全部退货'},
						]
					},
					{
						label: '备注',
						prop: 'remark',
						type: 'input',
						span: 8,
						max: 100,
					},
					// {
					// 	label: '操作用户',
					// 	prop: 'fz_operateName',
					// 	type: 'popUser',
					// 	span: 8,
					// 	short: 12,
					// 	fields: ['operateId', 'fz_operateName'],
					// },
					// {
					// 	label: '操作时间',
					// 	type: 'dateTimeRange',
					// 	span: 8,
					// 	//开始结束时间字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_addTime0',
					// 		},
					// 		{
					// 			prop: 'fz_addTime1',
					// 		},
					// 	],
					// },
				],
			},
			//是否选择相同供应商
			isSameSup: false,
			// 是否能进行明细选择
			sonCheck: false,
			apiObj: null,
		})

		//显示弹窗
		const showDialog = (data,isSameSup=false,sonCheck=false,apiObj,isCheck=false) => {
			if(isCheck){
				state.tableSonConfig = Object.assign({},state.tableSonConfigCheck)
			}
			if(apiObj){
				state.tableConfig.apiObj = apiObj.table
			}else {
				state.tableConfig.apiObj = proxy.$API.purchaseOrder.getPurchaseOrder
			}
			state.apiObj = apiObj
			state.isSameSup = isSameSup
			state.sonCheck = sonCheck
			state.visible = true
			state.selections = []
			if (state.selData && state.selData.length > 0){
				state.selData = []
			}
			state.isConfirm = true
			state.loading = false
			state.tableConfig.params = {}
			if(data) {
				Object.assign(state.tableConfig.params, data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}
		const sonSelChange = (val) => {
			console.log(val)

		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(state.sonCheck && !data.orderId) return
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			if (data && index === -1) {
				state.selData.push(data)
			} else {
				state.selData = state.selData.filter(item => JSON.stringify(item) !== JSON.stringify(data))
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
			if(state.sonCheck){
				showSonChoose()
			}else {
				showChooseData()
			}
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) !== '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				if(state.isSameSup){
					let isDiff = false
					state.selData.forEach((item)=>{
						if(item.fz_supplierName != state.selData[0].fz_supplierName){
							isDiff = true
						}
					})
					if (isDiff) {
						proxy.$baseMessage('请选择供应商相同的订单！', 'warning')
						return
					}
				}
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			data = {
				...data,
				...state.tableConfig.params
			}
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if (state.leftShow) {
				const param = Object.assign({}, state.params)
				param['orderId'] = row.id
				state['tableSon'].reload(param)
			}
			if (props.multiple) {
				//添加已选数据
				setSelData(row)
			}
		}
		//子表行点击事件
		const handleSonRowClick = (row) => {
			if (props.multiple && state.sonCheck) {
				//添加已选数据
				setSelData(row)
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				if(!props.multiple) {   // 多选
					//设置第一行选中
					state['table'].setCurrentRow(res.data.list[0])
				}
				//传入父级ID
				Object.assign(state.tableSonConfig.params, state.params)
				state.tableSonConfig.params['orderId'] = res.data.list[0].id
				//将接口对象赋给子级表格
				if(state.apiObj){
					state.tableSonConfig.apiObj = state.apiObj.tableSon
				}else {
					state.tableSonConfig.apiObj = proxy.$API.purchaseOrder.getOrderGoodsList
				}
				//根据主数据加载子数据
				// handleRowClick(res.data.list[0])
			}else{
				if(state.leftShow){
					proxy.$refs.tableSon.reload({ orderId: -1 })
				}
			}
			showChooseData()
		}
		const sonDataChange = () => {
			showSonChoose()
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//复选框选择
		const handleSelect = async (selection, row) => {
			if (state.sonCheck) {
				//选中，取消时都会调用
				const res = await state.tableSonConfig.apiObj.get({
					orderId: row.id,
					pageSize: 100,
					pageNum: 1
				})
				if (res.code === 200) {
					res.data.list.forEach((son) => {
						setSelData(son)
					})
				}
			} else {
				const index = selection.indexOf(row)
				const index1 = state.selData.indexOf(row)
				if (index < 0) {
					state.selData.splice(index1, 1)
				} else {
					if (index1 < 0) {
						state.isConfirm = false
						state.selData.push(row)
					}
				}
			}
		}
		//子表复选框选择
		const handleSonSelect = (selection, row) => {
			const sonData = state.tableSon.tableData
			const order = state.table.tableData.find(item=>item.id === sonData[0].orderId)
			if(selection.length < 1){
				proxy.$refs.table.toggleRowSelection(order, false)
			}else if(selection.length === sonData.length){
				proxy.$refs.table.toggleRowSelection(order, true)
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.isConfirm = false
					state.selData.push(row)
				}
			}
		}

		//全选
		const handleSelectAll = async (selection) => {
			if (selection && selection.length > 0) {
				if (state.sonCheck) {
					for (const item of selection) {
						const res = await state.tableSonConfig.apiObj.get({
							orderId: item.id,
							pageSize: 100,
							pageNum: 1
						})
						if (res.code === 200) {
							res.data.list.forEach((son) => {
								setSelData(son)
							})
						}
					}
					showSonChoose()
				}
				selection.forEach((item) => {
					setSelData(item)
				})
			} else {
				state.selData = []
				if(state.sonCheck){
					showSonChoose()
				}
			}
		}
		//子表全选
		const handleSonSelectAll = async (selection) => {
			if (selection && selection.length > 0) {
				selection.forEach((item) => {
					setSelData(item)
				})
			} else {
				state.selData = []
			}
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//子表回显选中状态
		const showSonChoose = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.tableSon
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return JSON.stringify(son) === JSON.stringify(item)
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			clearSelections,
			delSelData,
			handleDetail,
			handleRowClick,
			handleSonRowClick,
			fatherDataChange,
			sonDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			sonSelChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
			handleSelectAll,
			handleSonSelectAll,
			showChooseData,
			handleSelect,
			handleSonSelect
		}
	},
})
</script>
