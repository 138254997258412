import config from "@/config"
import http from "@/utils/request"

export default {
	process: {
		url: `${config.API_URL}/erp/produce/mrp/document/process`,
		name: "查询生产建议下达单据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	outProcess: {
		url: `${config.API_URL}/erp/produce/mrp/document/outProcess`,
		name: "查询委外建议下达单据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
	purchase: {
		url: `${config.API_URL}/erp/produce/mrp/document/purchase`,
		name: "查询采购建议下达单据",
		get: async function(id){
			return await http.get(this.url+'?id='+id);
		}
	},
}
