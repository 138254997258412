import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/stock/material/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/stock/material/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/stock/material/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/stock/material/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/stock/material/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/erp/stock/material/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/erp/stock/material/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},
	receive: {
		url: `${config.API_URL}/erp/stock/material/receive`,
		name: "领取",
		post: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/stock/material/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	getProcessId: {
		url: `${config.API_URL}/erp/stock/material/getProcessId`,
		name: "查询加工单id",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	isEnd: {
		url: `${config.API_URL}/erp/stock/material/isEnd`,
		name: "终止",
		get: async function(ids){
			return await http.get(this.url + "?ids=" + ids);
		}
	},

}
