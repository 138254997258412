import config from "@/config"
import http from "@/utils/request"

export default {
	purSupplier: {
		url: `${config.API_URL}/erp/purchase/personal/purSupplier`,
		name: "采购统计 - 按",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	purSupplierDetail: {
		url: `${config.API_URL}/erp/purchase/personal/purSupplierDetail`,
		name: "采购统计 - 按 - 明细",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	supplierVerify: {
		url: `${config.API_URL}/erp/purchase/personal/supplierVerify`,
		name: "对账",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	supplierVerifyDetailOne: {
		url: `${config.API_URL}/erp/purchase/personal/supplierVerifyDetailOne`,
		name: "对账 - 对账明细1",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	supplierVerifyDetailTwo: {
		url: `${config.API_URL}/erp/purchase/personal/supplierVerifyDetailTwo`,
		name: "对账 - 对账明细2",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	personalPaid: {
		url: `${config.API_URL}/erp/purchase/personal/personalPaid`,
		name: "已付查询",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	detailReconciliationData: {
		url: `${config.API_URL}/erp/purchase/personal/detailReconciliationData`,
		name: "对账 - 明细对账 - Data数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	purGoodsAccount: {
		url: `${config.API_URL}/erp/purchase/personal/purGoodsAccount`,
		name: "供应商对账 - 采购物品对账",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
