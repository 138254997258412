import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	getAllList: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getAllList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	// 会计凭证新增，查询所有会计科目
	getAllListNoPage: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getAllListNoPage`,
		name: "查询所有会计科目列表数据",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},

	getListNoPid: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getListNoPid`,
		name: "查询所有会计科目列表数据",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},

	insert: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/detail`,
		name: "查询详情",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},


	updateFlag1: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/updateFlag1`,
		name: "修改状态",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/updateFlag0`,
		name: "修改状态",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	checkCode: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/checkCode`,
		name: "查询编码是否唯一",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	checkName: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/checkName`,
		name: "查询名称是否唯一",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getParent: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getParent`,
		name: "查询父级类别",
		get: async function (data = {}) {
			return await http.get(this.url + "?parentId=" + data);
		}
	},
	getListByVoucher: {
		url: `${config.API_URL}/cfg/finance/accountingtitle/getListByVoucher`,
		name: "查询有凭证数据的类别",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},
	importExcel:{
		url: `${config.API_URL}/cfg/finance/accountingtitle/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},
}
