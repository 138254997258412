import config from "@/config"
import http from "@/utils/request"

export default {
	selectRecord: {
		url: `${config.API_URL}/mobile/stock/record/selectRecord`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getList: {
		url: `${config.API_URL}/mobile/stock/record/getList`,
		name: "分页菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
