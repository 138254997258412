<template>
  <el-select
      v-model="defaultValue"
      value-key="id"
      :clearable="clearable"
      :size="$store.state.global.uiSize"
      filterable
      @clear="selectClear"
      @visible-change="visibleChange"
      @change="selectChange"
  >
    <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="customLabel?(item.name+'('+customLabel.label+':'+item[customLabel.prop]+')'):(showBalance ? item.name + '(余额:' + handleCutZero(item.balance) +')' : item.name)"
        :value="item"
    >
      <div
          v-if="showBalance"
          class="account"
      >
        <div class="flexgs1">
          {{ item.name }}
        </div>
        <div class="text-right flexgs0">
          {{
            customLabel ? (item[customLabel.prop] ? ("(" + customLabel.label + ":" + item[customLabel.prop] + ")") : "") : ("(余额:" + handleCutZero(item.balance) + ")")
          }}
        </div>
      </div>
      <div
          v-else-if="showDepotQuantity"
          class="account"
      >
        <div class="flexgs1">
          {{ item.name + "【库存：" + (item.depotQuantity || 0) + "】" }}
        </div>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  watch,
} from "vue";
import {handleCutZero} from "@/utils/validate";

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    modelValue: null,
    apiObj: {
      type: Object, default: () => {
      }
    },
    params: {
      type: Object, default: () => { }
    },
    showBalance: {type: Boolean, default: false},
    showDepotQuantity: {type: Boolean, default: false},
    customLabel: {type: Object, default: null},
    defaultAssign: {type: Boolean, default: true}, // 是否自动赋值默认数据
    firAssign: {type: Boolean, default: true}, // 是否自动赋值第一条
    defaultProp: {type: String, default: "defaul"}, // 是否默认字段
    clearable: {type: Boolean, default: true}, // 是否可以清除
    isNull: {type: Boolean, default: false}, // 是否可以为空
  },
  emits: ["update:modelValue", "set-value"],
  setup(props, {emit}) {
    const state = reactive({
      defaultValue: props.modelValue,
      isLoading: true,
      options: [],
    });

    const fetchData = async () => {
      const res = await props.apiObj.get(props.params);
      if (res.code === 200 && res.data && res.data.length > 0) {
        state.options = res.data;
        if (props.defaultAssign) {
          //查找默认项
          const defaults = res.data.find((item) => {
            return item[props.defaultProp] === 1;
          });
          if (defaults && !state.defaultValue) {
            state.defaultValue = defaults;
          } else if (props.firAssign && !state.defaultValue) {
            state.defaultValue = res.data[0];
          }
					emit("update:modelValue", state.defaultValue);
					emit("set-value", state.defaultValue);
        }
      }
    };

    const visibleChange = (val) => {};

    onMounted(() => {
      fetchData();
    });

    watch(
        () => props.modelValue,
        (val) => {
          if (val) {
            state.defaultValue = val;
          }else if(props.isNull){
            state.defaultValue = null
          }
        },
        {deep: true}
    );

    // watch(
    //     () => state.defaultValue,
    //     (val) => {
    //       if (val) {
    //         emit("update:modelValue", val);
		// 				if(!props.notAssignSet){
		// 					emit("set-value", val);
		// 				}
    //       }
    //     },
    //     {deep: true}
    // );

    const selectChange = (val) => {
      emit("set-value", val);
    };
    /*可能会删*/
    const selectClear = () => {
      emit("clear");
    };
		const clearValue = () => {
			state.defaultValue = null
		}
    return {
      ...toRefs(state),
			clearValue,
      selectChange,
      selectClear,
      handleCutZero,
      visibleChange,
    };
  },
});
</script>
<style scoped>
.account {
  display: flex;
  align-content: space-between;
}

.account div.flexgs0 {
  color: #888;
}
</style>
