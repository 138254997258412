import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/produce/design/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/produce/design/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/design/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/design/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/design/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/erp/produce/design/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/erp/produce/design/updateProcessInfo`,
		name: "修改审批信息(审批状态和审批人)",
		put: async function(data ={}){
			return await http.put(this.url ,data);
		}
	},
	updateDesignState: {
		url: `${config.API_URL}/erp/produce/design/updateDesignState`,
		name: "修改设计状态",
		get: async function(ids,state){
			return await http.get(this.url + '?ids=' + ids + '&state=' + state);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/produce/design/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
