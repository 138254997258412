<!--选择加工单弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'650px'"
		custom-class="popTable"
		:type="type"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<splitpanes
							:horizontal="true"
							@resized="handleResized"
						>
							<pane>
								<el-container
									class="fatherCont"
									:style="multiple && 'padding-right: 10px'"
								>
									<sy-search-Form
										v-show="!hideForm"
										ref="refFormBar"
										:configs="searchConfig"
										:show-item="showItem"
										@fetch-data="fetchData"
										@set-show="handleFitShow"
									/>
									<el-header class="tableTopBar">
										<div class="left-panel" />
										<div class="right-panel" >
											<!--切换显示搜索栏hide-after: 延迟隐藏-->
											<el-tooltip
												:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
												placement="bottom"
												:hide-after="0"
											>
												<el-link @click="handleShowSearch">
													搜索
													<el-icon>
														<template v-if="hideForm">
															<el-icon-arrowDown />
														</template>
														<template v-else>
															<el-icon-arrowUp />
														</template>
													</el-icon>
												</el-link>
											</el-tooltip>
										</div>
									</el-header>
									<el-main class="nopadding bgwhite">
										<!--主数据表格-->
										<scTable
											ref="table"
											:api-obj="tableConfig.apiObj"
											:column="tableConfig.columns"
											:params="tableConfig.params"
											row-key="id"
											:checkbox="multiple"
											radio-check
											border
											highlight-current-row
                                            :ref-query-form="refFormBar"
											remote-sort
											remote-filter
											hide-column-setting
											hide-setting
											:pagination-layout="'total, prev, pager, next, jumper'"
											@selection-change="selectChange"
											@current-change="currentChange"
											@row-click="firClick"
											@row-dblclick="dblClick"
											@data-change="fatherDataChange"
										>
											<template #submType="{ scope }">
												<el-tag
													v-if="scope.row.submType === 1"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													无损检测
												</el-tag>
												<el-tag
													v-if="scope.row.submType === 2"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													有损检测
												</el-tag>
											</template>
											<template #qualityStatus="{ scope }">
												<el-tag
													v-if="scope.row.qualityStatus === 0"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未检测
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 1"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													合格
												</el-tag>
												<el-tag
													v-if="scope.row.qualityStatus === 2"
													type="warning"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													不合格
												</el-tag>
											</template>
											<template #isPurchase="{ scope }">
												{{ scope.row.isPurchase === 1 ? '是' : '否' }}
											</template>
										</scTable>
									</el-main>
<!--									<sy-switch-btn-->
<!--										:text="'产品明细'"-->
<!--										:opened="leftShow"-->
<!--										bottom-->
<!--										@show-left="handleShowLeft"-->
<!--									/>-->
								</el-container>
							</pane>
							<!--已选数据-->
<!--							<pane-->
<!--								v-if="leftShow"-->
<!--								:size="paneSize"-->
<!--								max-size="70"-->
<!--								min-size="20"-->
<!--							>-->
<!--								<el-container-->
<!--									class="sonCont"-->
<!--									:style="multiple && 'padding-right: 10px'"-->
<!--								>-->
<!--									<el-main class="nopadding">-->
										<!--明细-->
<!--										<scTable-->
<!--											ref="tableSon"-->
<!--											:api-obj="tableSonConfig.apiObj"-->
<!--											:column="tableSonConfig.columns"-->
<!--											:params="tableSonConfig.params"-->
<!--											row-key="id"-->
<!--											border-->
<!--											remote-sort-->
<!--										>-->
<!--											<template #fz_goodsName="{ scope }">-->
<!--												<el-link-->
<!--													type="primary"-->
<!--													@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"-->
<!--												>-->
<!--													{{ scope.row.fz_goodsName }}-->
<!--												</el-link>-->
<!--											</template>-->

<!--											<template #fz_stype="{ scope }">-->
<!--												<span v-if="scope.row.fz_stype === '1'">半成品</span>-->
<!--												<span v-if="scope.row.fz_stype === '0'">原材料</span>-->
<!--												<span v-if="scope.row.fz_stype === '2'">成品</span>-->
<!--											</template>-->
<!--										</scTable>-->
<!--									</el-main>-->
<!--								</el-container>-->
<!--							</pane>-->
						</splitpanes>
					</pane>
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumn"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
<!--			<el-button-->
<!--				:size="$store.state.global.uiSize"-->
<!--				@click="visible = false"-->
<!--			>-->
<!--				关闭-->
<!--			</el-button>-->
		</template>
		<Detail ref="refDetail" />
		<GoodsDetail ref="refGoodsDetail" />
		<ProducePlanDetail ref="refProducePlanDetail" />
		<ProduceProcessDetail ref="refProduceProcessDetail" />
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
export default defineComponent ({
	components: {GoodsDetail, Detail ,ProducePlanDetail,ProduceProcessDetail},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择委外加工单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			type:'detail',
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '加工单号',
					prop: 'orderNo',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.produceOutprocess.getList,
				params:{},
				//数据表格列配置
				columns: [
					{
						label: '加工单号',
						prop: 'orderNo',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '物品编号',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '物品单位',
						prop: 'unit',
						width: 110,
						// sortable: 'custom',
					},
					{
						label: '物品数量',
						prop: 'quantity',
						width: 110,
						// sortable: 'custom',
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						width: 200,
						// sortable: 'custom',
					},
					// {
					// 	label: '送检数量',
					// 	prop: 'number',
					// 	width: 130,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '送检id',
					// 	prop: 'submId',
					// 	width: 100,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '送检类型',
					// 	prop: 'submType',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '质检结果',
					// 	prop: 'qualityStatus',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '备注',
					// 	prop: 'remarks',
					// 	width: 180,
					// 	sortable: 'custom',
					// },
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物料名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},

					{
						label: '物料编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},

					{
						label: '物料规格',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '物料型号',
						prop: 'fz_goodsModel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '产品类型',
						prop: 'fz_stype',
						sortable: 'custom',
						width: 110,
					},
					{
						label: '需求数量',
						prop: 'demandQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '耗损率(%)',
						prop: 'wearRate',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '实际数量',
						prop: 'actualQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '来源',
						prop: 'source',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '位置号',
						prop: 'location',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '坏料尺寸',
						prop: 'billetSize',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '坏料数量',
						prop: 'billetNumber',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '提前期',
						prop: 'leadTime',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '摘要',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 100,
				//查询表单项配置
				itemList: [
					{
						label: '加工单号',
						prop: 'orderNo',
						type: 'input',
						default: true,
						span: 8,
					},
					{
						label: '生产计划',
						prop: 'fz_planNo',
						type: 'input',
						default: true,
						span: 8,
					},
					{
						label: '计划开始日期',
						type: 'dateRange',
						default: true,
						span: 8,
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_startDate0',
							},
							{
								prop: 'fz_startDate1',
							},
						],
					},
					{
						label: '计划结束日期',
						type: 'dateRange',
						span: 8,
						//开始结束日期字段
						fields: [
							{
								prop: 'fz_endDate0',
							},
							{
								prop: 'fz_endDate1',
							},
						],
					},
					{
						label: '计划工时',
						type: 'rangeNum',
						span: 8,
						fields: [
							{
								prop: 'fz_workHour0',
								max: 10,
							},
							{
								prop: 'fz_workHour1',
								max: 10,
							},
						],
					},
					{
						label: '负责人',
						span: 8,
						prop: 'fz_managerName',
						type: 'popUser',
						short: 12,
						fields: ['managerId', 'fz_managerName'],
					},
					// {
					// 	label: '共享人',
					// 	span: 8,
					// 	prop: 'fz_shareUserName',
					// 	type: 'popUser',
					// 	short: 12,
					// 	fields: ['shareUserId', 'fz_shareUserName'],
					// },
					{
						label: '产品',
						span: 8,
						prop: 'fz_goodsName',
						type: 'popGoods',
						fields: ['goodsId','fz_goodsName']
					},
					{
						label: '计量单位',
						span: 8,
						prop: 'unit',
						type: 'input',
						short: 12,
						max: 32,
					},
					{
						label: '生产数量',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_quantity0',
								max: 20,
							},
							{
								prop: 'fz_quantity1',
								max: 20,
							},
						],
					},
					{
						label: '辅计单位',
						span: 8,
						short: 12,
						prop: 'assistUnit',
						type: 'input',
						max: 32,
					},
					{
						label: '换算数量',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_assistQuantity0',
								max: 20,
							},
							{
								prop: 'fz_assistQuantity1',
								max: 20,
							},
						],
					},
					{
						label: '设计内容',
						span: 8,
						prop: 'designContent',
						type: 'input',
						max: 255,
					},
					{
						label: '质检要求',
						span: 8,
						prop: 'qualityCheck',
						type: 'input',
						max: 255,
					},
					{
						label: '生产工艺',
						span: 8,
						prop: 'fz_routeName',
						type: 'popRoute',
						fields: ['routeId', 'fz_routeName'],
					},
					{
						label: '拆分方式',
						span: 8,
						prop: 'splitMethod',
						type: 'select',
						short: 12,
						data: [
							{
								label: '拆分至底层',
								value: 1,
							},
							{
								label: '拆分至一级',
								value: 2,
							},
						]
					},
					{
						label: '审核状态',
						span: 8,
						prop: 'auditStatus',
						type: 'select',
						short: 12,
						data: [
							{
								label: '未提交',
								value: 0,
							},
							{
								label: '审核中',
								value: 1,
							},
							{
								label: ' 已驳回',
								value: 2,
							},
							{
								label: ' 审核通过',
								value: 3,
							},
						]
					},
					{
						label: '加工状态',
						span: 8,
						prop: 'processStatus',
						type: 'select',
						short: 12,
						data: [
							{
								label: '未生产',
								value: 0,
							},
							{
								label: '生产中',
								value: 1,
							},
						]
					},
					{
						label: '是否申购',
						span: 8,
						prop: 'isPurchase',
						type: 'select',
						short: 12,
						data: [
							{
								label: '否',
								value: 0,
							},
							{
								label: '是',
								value: 1,
							},
						]
					},
					{
						label: '备注',
						span: 8,
						prop: 'remark',
						type: 'input',
						max: 255,
					},
					{
						label: '操作用户',
						span: 8,
						prop: 'fz_operateName',
						type: 'popUser',
						short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'addTime0',
							},
							{
								prop: 'addTime1',
							},
						],
					},
				],
			},
		})

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			state.selections = []
			state.selData = []
			if(data){
				Object.assign(state.tableConfig.params, data)
			}
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			console.log(data)
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}


		//行单击
		const firClick = (row) => {
			// //添加已选数据
			// setSelData(row)
			// if(row && !props.multiple){
			// 	//传入选中行和赋值的字段
			// 	emit('set-data', row)
			// 	hideDialog()
			// }
			// 多选
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
			// handleRowClick(row)
		}

		//行双击
		const dblClick = (row) => {
			// 单选
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}

		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			// if(state.leftShow){
			// 	state['tableSon'].reload(row.id)
			// }
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.goods.detail
				//传入父级ID//
				// todo params只能传对象，，怎么使用get请求？？？
				state.tableSonConfig.params = res.data.list[0].id
				//根据主数据加载子数据
				handleRowClick(res.data.list[0])
			}else{
			if(proxy.$refs.tableSon){
				proxy.$refs.tableSon.reload(-1)
			}
			}

		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}


		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false)
			if (data && index === -1) {
				state.selData.push(data)


			} else {
				state.selData = state.selData.filter(item => item.id !== data.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false

			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		return{
			...toRefs(state),
			clearSelections,
			delSelData,
			tableSelselectChange,
			addToRight,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			firClick,
			hideDialog,
		}
	},
})
</script>
