import config from "@/config"
import http from "@/utils/request"

export default {
	selectMaterialDetail: {
		url: `${config.API_URL}/mobile/stock/material/detail/selectMaterialDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	warehouseNumDetail: {
		url: `${config.API_URL}/mobile/stock/material/warehouseNumDetail`,
		name: "查询列表数据",
		get: async function (id) {
			return await http.get(this.url + "?id=" + id);
		}
	},

	selectMaterialDetails: {
		url: `${config.API_URL}/mobile/stock/material/detail/selectMaterialDetails`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
