import config from "@/config"
import http from "@/utils/request"

export default {
	detail: {
		url: `${config.API_URL}/erp/produce/procInspRecord/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
}
