<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
		height="600px"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<!--label: 标题 -->
				<el-descriptions-item
					label="订单编号"
					label-align="right"
				>
					{{ data.orderNo }}
				</el-descriptions-item>

				<el-descriptions-item
					label="销售合同"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refContractDetail', 'contractId')"
					>
						{{ data.contractNo }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="客户"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refCustomerDetail', 'customerId')"
					>
						{{ data.fz_customerName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="联系人"
					label-align="right"
				>
					{{ data.contacts }}
				</el-descriptions-item>
				<el-descriptions-item
					label="联系电话"
					label-align="right"
				>
					{{ data.tel }}
				</el-descriptions-item>
				<el-descriptions-item
					label="审批状态"
					label-align="right"
				>
					<el-tag
						v-if="data.auditStatus === 0"
						style="color: #666;"
					>
						未提交
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 1"
						type="warning"
					>
						审核中
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 2"
						type="danger"
					>
						已驳回
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 3"
						type="success"
					>
						审核通过
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 4"
						type="info"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未确认
					</el-tag>
					<el-tag
						v-if="data.auditStatus === 5"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已确认
					</el-tag>
				</el-descriptions-item>
				<el-descriptions-item
					label="发货状态"
					label-align="right"
				>
					<el-tag
						v-if="data.invoiceStatus === 0"
						style="color: #666;"
					>
						未发货
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 1"
						type="warning"
					>
						部分发货
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 2"
						type="success"
					>
						全部发货
					</el-tag>
					<el-tag
						v-if="data.invoiceStatus === 3"
						type="danger"
					>
						已终止
					</el-tag>

				</el-descriptions-item>
				<el-descriptions-item
					label="收款状态"
					label-align="right"
				>
					<el-tag
						v-if="data.receiptStatus === 0"
						style="color: #666;"
					>
						未收款
					</el-tag>
					<el-tag
						v-if="data.receiptStatus === 1"
						type="warning"
					>
						部分收款
					</el-tag>
					<el-tag
						v-if="data.receiptStatus === 2"
						type="success"
					>
						全部收款
					</el-tag>

				</el-descriptions-item>
				<el-descriptions-item
					label="开票状态"
					label-align="right"
				>
					<el-tag
						v-if="data.ticketStatus === 0"
						style="color: #666;"
						:size="$store.state.global.uiSize"
					>
						未开票
					</el-tag>
					<el-tag
						v-if="data.ticketStatus === 1"
						type="warning"
						:size="$store.state.global.uiSize"
					>
						部分开票
					</el-tag>
					<el-tag
						v-if="data.ticketStatus === 2"
						type="success"
						:size="$store.state.global.uiSize"
					>
						全额开票
					</el-tag>
				</el-descriptions-item>
				<el-descriptions-item
					label="合计金额"
					label-align="right"
				>
					{{ data.totalAmount }}
				</el-descriptions-item>

				<el-descriptions-item
					label="优惠金额"
					label-align="right"
				>
					{{ data.discount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="实际金额"
					label-align="right"
				>
					{{ data.actualAmount }}
				</el-descriptions-item>

				<el-descriptions-item
					label="应收金额"
					label-align="right"
				>
					{{ data.receivableAmount }}
				</el-descriptions-item>

				<el-descriptions-item
					label="已收金额"
					label-align="right"
				>
					{{ data.receiptAmount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="未收金额"
					label-align="right"
				>
					{{ data.uncollectAmount }}
				</el-descriptions-item>

				<el-descriptions-item
					label="发票类型"
					label-align="right"
				>
					{{ data.fz_typeName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="开票金额"
					label-align="right"
				>
					{{ data.ticketAmount }}
				</el-descriptions-item>

				<el-descriptions-item
					label="交货日期"
					label-align="right"
				>
					{{ data.deliveryDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="业务员"
					label-align="right"
				>
					{{ data.fz_salesmanName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="下单日期"
					label-align="right"
				>
					{{ data.creationDate }}
				</el-descriptions-item>

				<el-descriptions-item
					label="收款期限"
					label-align="right"
				>
					{{ data.collectDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="退货金额"
					label-align="right"
				>
					{{ data.refundAmount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="关联费用"
					label-align="right"
				>
					{{ data.relationAmount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="合计成本"
					label-align="right"
				>
					{{ data.totalCost }}
				</el-descriptions-item>
				<el-descriptions-item
					label="单位抬头"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refCompanyDetail', 'companyId')"
					>
						{{ data.fz_companyName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>

				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
				<el-descriptions-item
					v-for="item in dataList"
					:key="item.value"
					:label="item.name"
					label-align="right">
					{{ Array.isArray(data[item.value]) ? data[item.value].join(",") : data[item.value] }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="收货信息"
			shadow="never"
			class="mycard mt_10"
		>
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<el-descriptions-item
					label="收货人"
					label-align="right"
				>
					{{ data.receiver }}
				</el-descriptions-item>
				<el-descriptions-item
					label="联系电话"
					label-align="right"
				>
					{{ data.phone }}
				</el-descriptions-item>
				<el-descriptions-item
					label="收货地址"
					label-align="right"
				>
					{{ data.shipAddress }}
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="产品明细"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				:default-summary="tableSonConfig.summary"
				row-key="id"
				border
				remote-sort
				show-summary
				remote-summary
			>
				<!--	生产状态 -->
				<template #processStatus="{ scope }">
					<el-tag
						v-if="scope.row.processStatus === 0"
						style="color: #666;"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未生产
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						生产中
					</el-tag>
					<el-tag
						v-if="scope.row.processStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已完成
					</el-tag>
				</template>

				<template #invoiceStatus="{ scope }">
					<el-tag
						v-if="scope.row.invoiceStatus === 0"
						style="color: #666;"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未发货
					</el-tag>
					<el-tag
						v-if="scope.row.invoiceStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						部分发货
					</el-tag>
					<el-tag
						v-if="scope.row.invoiceStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						全部发货
					</el-tag>
					<el-tag
						v-if="scope.row.invoiceStatus === 3"
						type="danger"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						已终止
					</el-tag>
				</template>

				<template #fz_goodsName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
					>
						{{ scope.row.fz_goodsName }}
					</el-link>
				</template>

				<template #refundStatus="{ scope }">
					<el-tag
						v-if="scope.row.refundStatus === 0"
						style="color: #666;"
						:size="$store.state.global.uiSize"
					>
						未退货
					</el-tag>
					<el-tag
						v-if="scope.row.refundStatus === 1"
						class="warning"
						:size="$store.state.global.uiSize"
					>
						部分退货
					</el-tag>
					<el-tag
						v-if="scope.row.refundStatus === 2"
						class="danger"
						:size="$store.state.global.uiSize"
					>
						全部退货
					</el-tag>
				</template>
				<template #ticketStatus="{ scope }">
					<el-tag
						v-if="scope.row.ticketStatus === 0"
						style="color: #666;"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						未开票
					</el-tag>
					<el-tag
						v-if="scope.row.ticketStatus === 1"
						type="warning"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						部分开票
					</el-tag>
					<el-tag
						v-if="scope.row.ticketStatus === 2"
						type="success"
						class="myTag"
						:size="$store.state.global.uiSize"
					>
						全额开票
					</el-tag>
				</template>
				<!--自定义字段-->
				<template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
					{{ Array.isArray(scope.row[item.value]) ? scope.row[item.value].join(",") : scope.row[item.value] }}
				</template>
			</scTable>
		</el-card>
		<!--    <el-card-->
		<!--      header="收款期限"-->
		<!--      shadow="never"-->
		<!--      class="mycard mt_10"-->
		<!--    >-->
		<!--      <scTable-->
		<!--        ref="tableCollect"-->
		<!--        :data="data.collectList"-->
		<!--        :column="tablecollectConfig.columns"-->
		<!--        :height="'auto'"-->
		<!--        row-key="id"-->
		<!--        border-->
		<!--        remote-sort-->
		<!--        hide-setting-->
		<!--        hide-pagination-->
		<!--      />-->
		<!--    </el-card>-->
		<CustomerDetail ref="refCustomerDetail"/>
		<CompanyDetail ref="refCompanyDetail"/>
		<GoodsDetail ref="refGoodsDetail"/>
		<ContractDetail ref="refContractDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	onMounted
} from "vue";
import CustomerDetail from "@/views/cfg/data/customer/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import ContractDetail from "@/views/erp/sale/contract/detail";

export default defineComponent({
	components: {
		CustomerDetail,
		CompanyDetail,
		GoodsDetail,
		ContractDetail
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			dataList: [],
			sonDataList: [],
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: "60%",
			//高度
			height: "500px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			type: "detail",
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "销售订单详情",
			//详情数据对象
			data: null,
			tablecollectConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				columns: [
					{
						label: "期限名称",
						prop: "name",
						sortable: "custom",
						fixed: "left",
						width: 160,
					},
					{
						label: "期限编码",
						prop: "code",
						sortable: "custom",
						width: 130,
					},
					{
						label: "收款日期",
						prop: "collectDate",
						sortable: "custom",
						width: 130,
					},
					{
						label: "收款金额",
						prop: "collectAmount",
						sortable: "custom",
						width: 110,
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				showSummary: true,
				summary: ["quantity", "taxAmount", "goodsAmount", "subtotal"],
				//数据表格列配置
				columns: [],
			},
			saleColumns: [
				{
					label: "产品名称",
					prop: "fz_goodsName",
					sortable: "custom",
					width: 180,
					fixed: "left"
				},
				{
					label: "产品编码",
					prop: "fz_goodsCode",
					sortable: "custom",
					width: 120,
				},
				{
					label: "规格型号",
					prop: "fz_goodsSpec",
					sortable: "custom",
					width: 120,
				},
				{
					label: "发货仓库",
					prop: "fz_depotName",
					sortable: "custom",
					width: 110,
				},
				{
					label: "发货状态",
					prop: "invoiceStatus",
					width: 110,
					sortable: "custom",
				},

				{
					label: "主单位",
					prop: "unit",
					width: 110,
					sortable: "custom",
				},

				{
					label: "数量",
					prop: "quantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "未税单价",
					prop: "price",
					width: 110,
					sortable: "custom",
				},
				{
					label: "税率(%)",
					prop: "taxRate",
					width: 110,
					sortable: "custom",
				},
				{
					label: "含税单价",
					prop: "taxPrice",
					width: 110,
					sortable: "custom",
				},
				{
					label: "税额(￥)",
					prop: "taxAmount",
					width: 110,
					sortable: "custom",
				},
				{
					label: "货款(￥)",
					prop: "goodsAmount",
					width: 110,
					sortable: "custom",
				},
				{
					label: "小计(￥)",
					prop: "subtotal",
					width: 110,
					sortable: "custom",
				},
				{
					label: "成本(￥)",
					prop: "cost",
					width: 110,
					sortable: "custom",
				},
				{
					label: "辅助单位",
					prop: "assistUnit",
					width: 110,
					sortable: "custom",
				},
				{
					label: "换算数量",
					prop: "assistQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "已发数量",
					prop: "invoiceQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "预发数量",
					prop: "preInvoiceQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "未发数量",
					prop: "notInvoiceQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "开票状态",
					prop: "ticketStatus",
					width: 110,
					sortable: "custom",
				},
				{
					label: "已开票数",
					prop: "ticketQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "预开票数",
					prop: "preTicketQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "未开票数",
					prop: "notTicketQuantity",
					width: 110,
					sortable: "custom",
				},

				{
					label: "备注",
					prop: "remark",
					width: 180,
					sortable: "custom",
				},
			],
			otherColumns: [
				{
					label: "产品名称",
					prop: "fz_goodsName",
					sortable: "custom",
					width: 180,
					fixed: "left"
				},
				{
					label: "产品编码",
					prop: "fz_goodsCode",
					sortable: "custom",
					width: 120,
				},
				{
					label: "规格型号",
					prop: "fz_goodsSpec",
					sortable: "custom",
					width: 120,
				},
				{
					label: "发货仓库",
					prop: "fz_depotName",
					sortable: "custom",
					width: 110,
				},
				{
					label: "发货状态",
					prop: "invoiceStatus",
					width: 110,
					sortable: "custom",
				},

				{
					label: "主单位",
					prop: "unit",
					width: 110,
					sortable: "custom",
				},

				{
					label: "数量",
					prop: "quantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "辅助单位",
					prop: "assistUnit",
					width: 110,
					sortable: "custom",
				},
				{
					label: "换算数量",
					prop: "assistQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "已发数量",
					prop: "invoiceQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "预发数量",
					prop: "preInvoiceQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "未发数量",
					prop: "notInvoiceQuantity",
					width: 110,
					sortable: "custom",
				},
				{
					label: "备注",
					prop: "remark",
					width: 180,
					sortable: "custom",
				},
			],
		});

		//显示弹窗
		const showDialog = async (data, isSale = false) => {
			if (isSale) {
				state.tableSonConfig.columns = state.saleColumns;
			} else {
				state.tableSonConfig.columns = state.otherColumns;
			}
			state.data = data;
			state.visible = true;
			state.fileList = [];
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.saleOrder.detail.get(data.id);
				if (res.code === 200) {
					state.data = res.data;
					//获取明细表数据
					getGoodsList(res.data.id);
					//组装文件列表
					getFileList(res.data.files);
				}
			}
			state.loading = false;
		};

		const getGoodsList = (id) => {
			if (id) {
				state.tableSonConfig.apiObj = proxy.$API.saleOrderGoods.selectOrderGoods;
				state.tableSonConfig.params = {orderId: id};
				proxy.$refs.tableSon.reload({orderId: id});
			}
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};
		onMounted(() => {
			sonListMap();
			dataListMap();
		});

		// 获取产品明细自定义字段
		const sonListMap = async () => {

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 2, isEnabled: 1, menuCode: "erp_sale_order",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {

					if (item.stype == 4) {
						item.value = "customField" + item.fieldLength + "List";
					} else {
						item.value = "customField" + item.fieldLength;
					}
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.sonDataList = res.data.list;
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: "custom",
				},);
			});
		};

		// 获取自定义字段
		const dataListMap = async () => {
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 1, isEnabled: 1, menuCode: "erp_sale_order",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {

					if (item.stype == 4) {
						item.value = "customField" + item.fieldLength + "List";
					} else {
						item.value = "customField" + item.fieldLength;
					}
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.dataList = res.data.list;
			}
		};
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};

		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
		};
		return {
			...toRefs(state),
			previewFile,
			handleDetail,
			showDialog,
			hideDialog,
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
:deep(.el-descriptions.column3 .el-descriptions__cell.el-descriptions__content.is-bordered-content){
  width: 18%;
}
</style>

