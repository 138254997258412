<!--列表滚动组件-->
<template>
  <div ref="refWrapper">
    <div
      v-resizeHeight="resizeHeight"
      class="scrollContent"
    >
      <div
        v-if="pullDown"
        class="pull-wrapper"
      >
        <div class="disflex align-center justify-center">
          <i v-if="loading"
             class="iconfont icon-jiazai loader"
          />
          <div v-html="tipText" />
        </div>
      </div>
      <slot />
      <div v-if="pullUp && isOverHeight">
        <div
          v-if="!noMore"
          class="pullup-tips"
        >
          <div
            v-if="!isPullUpLoad"
            class="before-trigger"
          >
            <span class="pullup-txt">上拉加载更多</span>
          </div>
          <div
            v-else
            class="after-trigger"
          >
            <span class="pullup-txt">加载中...</span>
          </div>
        </div>
        <div
          v-if="noMore"
          class="noMore"
        >
          <i class="iconfont icon-xiaolian" />到底啦!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
	defineComponent,
	getCurrentInstance,
	nextTick,
	onMounted,
	reactive,
	toRefs,
	watch
} from "vue";
import BsScroll from 'better-scroll'
export default defineComponent({
	name: "BetterScroll",
	props: {
		probeType: { type: Number, default: 1 },
		click: { type: Boolean, default: true },
		scrollX: { type:Boolean, default: false },
		listenScroll: { type:Boolean, default: false },
		data: { type:Array, default: null },
		//开启上拉加载
		pullUp: { type:Boolean, default: false },
		//开启下拉刷新
		pullDown: { type:Boolean, default: false },
		beforeScroll: { type:Boolean, default: false },
		refreshDelay: { type:Number, default: 0 },
	},
	emits: ['scroll', 'pullDown', 'scrollEnd', 'beforeScroll', 'pullUp'],
	setup(props,{emit}){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			beforePullDown: true,
			isPullingDown: false,
			scroll: null,
			isPullDown: false,
			tipText: '',
			isLoading: false,
			isPullUpLoad: false,
			noMore: false,
			isOverHeight: false,
			nodata: false,
			loading: false,
		})
		onMounted(()=>{
			nextTick(() => {
				initScroll()
			})
		})
		const initScroll = () => {
			if(!proxy.$refs.refWrapper){
				return false
			}
			//初始化
			state.scroll = new BsScroll(proxy.$refs.refWrapper,{
				probeType: props.probeType,
				scrollX: props.scrollX,
				click: props.click,
				useTransition: false,
				pullDownRefresh: {
					threshold: 50,
					stop: 50,
				},
				disableTouch: false,//启用手指触摸
				pullUpLoad: true
			})
			//是否派发滚动事件
			if(props.listenScroll){
				state.scroll.on('scroll',(pos) => {
					emit('scroll', pos)
				})
			}
			//是否派发滚动到底部事件， 用于上拉加载
			if(props.pullUp){
				//上拉监听
				state.scroll.on('pullingUp', pullingUpHandler)
				/*state.scroll.on('scrollEnd', () => {
					if(state.scroll.y <= (state.scroll.maxScrollY + 50)){
						emit('scrollEnd')
					}
				})*/
			}
			//是否派发顶部事件，用于下拉刷新
			if(props.pullDown){
				//下拉监听
				state.scroll.on('pullingDown', pullingDownHandler)
				//进入下拉监听
				state.scroll.on('enterThreshold', () => {
					state.tipText = '下拉刷新'
				})
				//松手监听
				state.scroll.on('leaveThreshold', () => {
					state.tipText = '松手刷新'
				})
				/*state.scroll.on('touchEnd', (pos) => {
					if(pos.y > 50){
						state.isPullDown = true
						emit('pullDown')
					}
				})*/
			}
			//是否派发滚动开始的事件
			if(props.beforeScroll){
				state.scroll.on('beforeScrollStart', () => {
					emit('beforeScroll')
				})
			}
		}

		watch(
			() => state.isLoading,
			(val) => {
				if(val){
					setTimeout(() =>{
						state.tipText = '数据已更新!'
						finishPullDown()
						refresh()
						state.isLoading = false
						state.loading = false
					},500)
				}
			}
		)

		watch(
			() => state.isPullUpLoad,
			(val) => {
				if(val){
					setTimeout(() =>{
						finishPullUp()
						refresh()
						state.isPullUpLoad = false
					},500)
				}
			}
		)

		//下拉超过设定值执行
		const pullingDownHandler = () => {
			state.tipText = '加载中...'
			state.loading = true
			emit('pullDown')
		}

		//上拉执行
		const pullingUpHandler = () => {
			state.isPullUpLoad = true
			emit('pullUp')
		}

		//代理better-scroll的finishPullDown方法
		const finishPullDown = () => {
			state.scroll && state.scroll.finishPullDown()
		}

		//代理better-scroll的finishPullUp方法
		const finishPullUp = () => {
			state.scroll && state.scroll.finishPullUp()
		}

		//代理better-scroll的disable方法
		const disable = () => {
			state.scroll && state.scroll.disable()
		}
		//代理better-scroll的enable方法
		const enable = () => {
			state.scroll && state.scroll.enable()
		}
		//代理better-scroll的refresh方法
		const refresh = () => {
			state.scroll && state.scroll.refresh()
		}
		//代理better-scroll的scrollTo方法
		const scrollTo = () => {
			state.scroll && state.scroll.scrollTo.apply(state.scroll,arguments)
		}
		//代理better-scroll的scrollToElement方法
		const scrollToElement = () => {
			state.scroll && state.scroll.scrollToElement.apply(state.scroll,arguments)
		}

		watch(
			()=> props.data,
			(value) => {
				setTimeout(() => {
					refresh()
				}, props.refreshDelay)
			}
		)

		//监听内容高度变化
		const resizeHeight = (data) => {
			const { height } = data
			const wh = document.querySelector('.scrollWrapper').offsetHeight
			if(props.pullUp){
				if((height - 0) > wh){
					state.isOverHeight = true
				}else{
					state.isOverHeight = false
				}
			}
		}

		return {
			...toRefs(state),
			resizeHeight,
			initScroll,
			disable,
			enable,
			scrollTo,
			scrollToElement,
			refresh
		}
	}
})
</script>

<style scoped>

</style>
