import config from "@/config"
import http from "@/utils/request"

export default {
    cashierAccount: {
        url: `${config.API_URL}/fms/cashier/account/cashierAccount`,
        name: "查询列表数据",
        get: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

	getAccountNames: {
		url: `${config.API_URL}/fms/cashier/account/getAccountNames`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getJournalDatas: {
		url: `${config.API_URL}/fms/cashier/account/getJournalDatas`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateStatus: {
		url: `${config.API_URL}/fms/cashier/account/updateStatus`,
		name: "修改对账状态",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	uncheck: {
		url: `${config.API_URL}/fms/cashier/account/uncheck`,
		name: "取消勾对",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	handCashierJournal: {
		url: `${config.API_URL}/fms/cashier/account/handCashierJournal`,
		name: "出纳日记选择",
		get: async function (accRecon,id) {
			return await http.get(this.url + '?accRecon=' + accRecon + '&id=' + id);
		}
	},
	handCashierVoucher: {
		url: `${config.API_URL}/fms/cashier/account/handCashierVoucher`,
		name: "会计凭证选择",
		get: async function (accRecon,id) {
			return await http.get(this.url + '?accRecon=' + accRecon + '&id=' + id);
		}
	},
}
