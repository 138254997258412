import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/data/goods/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/data/goods/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/data/goods/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/data/goods/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	batchUpdate: {
		url: `${config.API_URL}/cfg/data/goods/batchUpdate`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/data/goods/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/data/goods/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	checkName: {
		url: `${config.API_URL}/cfg/data/goods/checkName`,
		name: "名称唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/data/goods/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/cfg/data/goods/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	importExcel:{
		url: `${config.API_URL}/cfg/data/goods/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},

	queryReplaceGoods:{
		url: `${config.API_URL}/cfg/data/goods/queryGoodsList`,
		name: "根据ids查询可替代物品",
		get: async function(params){
			return await http.get(this.url+'?ids=' +params);
		}
	},

	queryApprovedGoods:{
		url: `${config.API_URL}/cfg/data/goods/queryApprovedGoods`,
		name: "查询所有审核通过的物品",
		get: async function(data= {}){
			return await http.post(this.url,data);
		}
	},
	updateAudit: {
		url: `${config.API_URL}/cfg/data/goods/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},

	updateFlagSupplier0: {
		url: `${config.API_URL}/cfg/data/goods/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagSupplier1: {
		url: `${config.API_URL}/cfg/data/goods/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	//反审批

	returnProcess: {
		url: `${config.API_URL}/cfg/data/goods/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getQuantity: {
		url: `${config.API_URL}/erp/produce/plan/goods/getQuantity`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?planDetailId=' + params);
		}
	},

	validateAutoGoodsCode: {
		url: `${config.API_URL}/cfg/data/goods/validateAutoGoodsCode`,
		name: "校验自动生成编码",
		get: async function(params){
			return await http.post(this.url, params);
		}
	},
	getMenuData: {
		url: `${config.API_URL}/cfg/data/goods/getMenuData`,
		name: "跟踪",
		get: async function(data){
			return await http.post(this.url,data);
		}
	},


}
