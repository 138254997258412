import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/assets/classify/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/fms/assets/classify/getListByKeyword`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	getAllList: {
		url: `${config.API_URL}/fms/assets/classify/getAllList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/assets/classify/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	del: {
		url: `${config.API_URL}/fms/assets/classify/del`,
		name: "查询",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/assets/classify/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/assets/classify/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/assets/classify/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	updateFlag: {
		url: `${config.API_URL}/fms/assets/classify/updateFlag`,
		name: "修改状态",
		put: async function (data = {}) {
			return await http.put(this.url + "?id=" + data);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/fms/assets/classify/updateFlag1`,
		name: "修改状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/fms/assets/classify/updateFlag0`,
		name: "修改状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getParent: {
		url: `${config.API_URL}/fms/assets/classify/getParent`,
		name: "查询父级类别",
		get: async function (data = {}) {
			return await http.get(this.url + "?parentId=" + data);
		}
	},

	getClassifyByCode: {
		url: `${config.API_URL}/fms/assets/classify/getClassifyByCode`,
		name: "查询",
		get: async function (params) {
			return await http.get(this.url + '?code=' + params);
		}
	},
}
