import config from "@/config"
import http from "@/utils/request"

export default {
	saleOrder: {
		url: `${config.API_URL}/erp/produce/mrp/saleOrder`,
		name: "查询销售订单",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	producePlan: {
		url: `${config.API_URL}/erp/produce/mrp/producePlan`,
		name: "查询生产计划",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	compute: {
		url: `${config.API_URL}/erp/produce/mrp/compute`,
		name: "计算",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/produce/mrp/insert`,
		name: "新增",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/mrp/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	getList: {
		url: `${config.API_URL}/erp/produce/mrp/getList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/mrp/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	occupancy: {
		url: `${config.API_URL}/erp/produce/mrp/occupancy`,
		name: "预计占用库存",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	proInsert: {
		url: `${config.API_URL}/erp/produce/mrp/proInsert`,
		name: "生产建议新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	outInsert: {
		url: `${config.API_URL}/erp/produce/mrp/outInsert`,
		name: "委外建议新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	purOrderInsert: {
		url: `${config.API_URL}/erp/produce/mrp/purOrderInsert`,
		name: "采购建议-采购订单新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	purApplyInsert: {
		url: `${config.API_URL}/erp/produce/mrp/purApplyInsert`,
		name: "采购建议-采购申请新增",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	processCompute: {
		url: `${config.API_URL}/erp/produce/mrp/processCompute`,
		name: "加工单计算",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	queryMrp: {
		url: `${config.API_URL}/erp/produce/mrp/queryMrp`,
		name: "查询MRP",
		get: async function(mrpId){
			return await http.get(this.url+'?mrpId='+mrpId);
		}
	},
	setOccupy:{
		url: `${config.API_URL}/erp/produce/mrp/setOccupy`,
		name: "修改状态",
		get: async function (planId,data) {
			return await http.post(this.url + "?planId=" + planId,data);
		}
	},
	showPlan: {
		url: `${config.API_URL}/erp/produce/plan/showPlan`,
		name: "在采购模块中查询相对应的采购建议",
		get: async function(applyIds,goodsId){
			return await http.get(this.url + '?applyIds=' + applyIds + '&goodsId=' + goodsId);
		}
	},
	showProducePlan: {
		url: `${config.API_URL}/erp/produce/mrp/document/showProducePlan`,
		name: "在采购订单模块中查询相对应的采购建议",
		get: async function(mrpPurDocId){
			return await http.get(this.url + '?mrpPurDocId=' + mrpPurDocId);
		}
	},
	showOccupy: {
		url: `${config.API_URL}/erp/purchase/orderGoods/showOccupy`,
		get: async function(orderSonId){
			return await http.get(this.url + '?orderSonId=' + orderSonId);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/mrp/delete`,
		get: async function(mrpId,planId){
			return await http.delete(this.url + '?mrpId=' + mrpId + '&planId=' + planId);
		}
	},
}
