import config from "@/config"
import http from "@/utils/request"

export default {
	list: {
		url: `${config.API_URL}/menu/pc/getList`,
		name: "获取菜单",
		get: async function(){
			return await http.get(this.url);
		}
	},
	search: {
		url: `${config.API_URL}/menu/pc/queryAllMenuSearchPower`,
		name: "获取所有搜索权限",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	}
}
