import config from "@/config"
import http from "@/utils/request"

export default {
	getFilesList: {
		url: `${config.API_URL}/cfg/data/files/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	delFiles: {
		url: `${config.API_URL}/cfg/data/files/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	doCheckName: {
		url: `${config.API_URL}/cfg/data/depot/checkName`,
		name: "仓库名唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	delFilesByUrl: {
		url: `${config.API_URL}/cfg/data/files/delFilesByUrl`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?url=' + params);
		}
	},

	detailFiles: {
		url: `${config.API_URL}/cfg/data/files/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	insertFiles: {
		url: `${config.API_URL}/cfg/data/files/insert`,
		name: "新增附件",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateFiles: {
		url: `${config.API_URL}/cfg/data/files/update`,
		name: "修改附件",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagFiles0: {
		url: `${config.API_URL}/cfg/data/files/`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagFiles1: {
		url: `${config.API_URL}/cfg/data/files/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getDownloadById: {
		url: `${config.API_URL}/cfg/data/filesDownload/getListByObjectId`,
		name: "根据附件Id查询下载记录",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insertFilesDownload: {
		url: `${config.API_URL}/cfg/data/filesDownload/insert`,
		name: "新增下载记录",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},


}
