<!--选择用户组件-复杂选择-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		type="detail"
		:trigger-dom="triggerDom"
		:placement="placement"
		:bef-close="false"
		:height="'400px'"
		custom-class="popTable"
	>
		<template v-if="choseAll" #header>
			<div class="tabhd">
				<el-button
					:size="$store.state.global.uiSize"
					:class="curTab === 1 ? 'active' : ''"
					@click="tabClick(1)"
				>
					选用户
				</el-button>
				<el-button
					:size="$store.state.global.uiSize"
					:class="curTab === 2 ? 'active' : ''"
					@click="tabClick(2)"
				>
					选部门
				</el-button>
				<el-button
					:size="$store.state.global.uiSize"
					:class="curTab === 3 ? 'active' : ''"
					@click="tabClick(3)"
				>
					选角色
				</el-button>
			</div>
		</template>
		<el-container>
			<el-main class="nopadding">
				<el-container>
					<!--选择用户 multiple是否开启多选-->
					<template v-if="curTab===1">
						<Users
							ref="refUsers"
							:multiple="multiple"
							@sel-change="handleSelChange"
						/>
					</template>
					<!--选择部门-->
					<template v-if="curTab===2 && choseAll">
						<el-main class="nopadding bdr">
							<!--树结构组件-->
							<SyDepartmentDir
								ref="refDepartment"
								:api-obj="$API.department.getList"
								add-top
								hideIcon
								hideTopAddBtn
								placeholder="输入部门名称搜索"
								@fetch-data-node="setNodeData"
							/>
						</el-main>
					</template>
					<template v-if="curTab===3 && choseAll">
						<Roles ref="refRoles" @sel-change="handleSelChange"/>
					</template>
				</el-container>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isDisabled"
				@click="handleConfirm"
			>
				确定
			</el-button>
		</template>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
} from "vue";
import Users from "./users";
import Roles from "./roles";
import SyDepartmentDir from "@/components/syDepartmentDir/index.vue";

export default defineComponent({
	name: "UserList",
	components: {SyDepartmentDir, Users, Roles},
	props: {
		//弹窗显示位置
		placement: {type: String, default: ""},
		//是否开启多选
		multiple: {type: Boolean, default: false},
		//默认开启全部选择
		choseAll: {type: Boolean, default: true},
		//传入标题
		title: {type: String, default: "用户选择"},
	},
	emits: ["set-data"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//当前活动选项卡
			curTab: 1,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 650,
			//确定按钮状态
			isDisabled: true,
			//选中后赋值的字段
			params: [],
			curDepartment: {}
		});

		//显示弹窗
		const showDialog = async () => {
			state.visible = true;
			state.loading = false;
		};

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool;
		};

		//标题栏选择项点击
		const tabClick = (num) => {
			state.curTab = num;
		};

		//确定按钮点击
		const handleConfirm = () => {
			let data = null;
			let type = '';
			if (state.curTab === 1) { //用户
				//多选
				if (props.multiple) {
					data = proxy.$refs.refUsers.selections;
				} else {
					data = proxy.$refs.refUsers.curRow;
				}
				type = 'user'
			} else if (state.curTab === 2) { //部门
				data = state.curDepartment;
				type = 'department'
			} else if (state.curTab === 3) { //角色
				if (props.multiple) {
					data = proxy.$refs.refRoles.selections;
				}else{
					data = proxy.$refs.refRoles.curRow;
				}
				type = 'role'
			}
			emit("set-data", data,type);
			hideDialog();
		};

		//选中数据变化
		const handleSelChange = (bool) => {
			//设置确定按钮可点击状态
			state.isDisabled = bool;
		};

		//部门节点点击
		const setNodeData = (data) => {
			if(data.children && data.children.length > 0){
				state.isDisabled = true
			}else{
				state.curDepartment = data
				state.isDisabled = false
			}
		}

		return {
			...toRefs(state),
			setNodeData,
			handleSelChange,
			handleConfirm,
			tabClick,
			handleShowLeft,
			showDialog,
			hideDialog,
		};
	},
});
</script>
