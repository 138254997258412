<!--选择BOM清单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes
          :horizontal="true"
          @resized="handleResized"
        >
          <pane>
            <el-container class="fatherCont">
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <span class="mr3 font">
                    {{ fieldDepot }}
                    <el-select
                      v-model="depot"
                      class="w150"
                      value-key="id"
                      :size="$store.state.global.uiSize"
                      placeholder="仓库"
                    >
                      <el-option
                        v-for="(item,index) in depotList"
                        :key="index"
                        :value="item"
                        :label="item.name"
                      />
                    </el-select>
                  </span>
                  <el-link
                    v-if="multiple"
                    type="primary"
                    :disabled="selections.length === 0"
                  >
                    添加到已选<el-icon><el-icon-right /></el-icon>
                  </el-link>
                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  :ref-query-form="refFormBar"
                  remote-sort
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
                  @current-change="currentChange"
                  @row-dblclick="dblClick"
                  @row-click="handleRowClick"
                  @data-change="fatherDataChange"
                >
                  <template #fz_goodsName="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                    >
                      {{ scope.row.fz_goodsName }}
                    </el-link>
                  </template>
                </scTable>
              </el-main>
              <sy-switch-btn
                :text="'物料明细'"
                :opened="leftShow"
                bottom
                @show-left="handleShowLeft"
              />
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="leftShow"
            :size="paneSize"
            max-size="70"
            min-size="20"
          >
            <el-container class="sonCont">
              <el-main class="nopadding">
                <!--明细-->
                <scTable
                  ref="tableSon"
                  :api-obj="tableSonConfig.apiObj"
                  :column="tableSonConfig.columns"
                  :params="tableSonConfig.params"
                  row-key="id"
                  border
                  remote-sort
                >
					<template #fz_goodsName="{ scope }">
						<el-link
							type="primary"
							@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
						>
							{{ scope.row.fz_goodsName }}
						</el-link>
					</template>
					<template #fz_goodsStype="{ scope }">
						<span v-if="scope.row['fz_goodsStype'] === '0'">原材料</span>
						<span v-if="scope.row['fz_goodsStype'] === '1'">半成品</span>
						<span v-if="scope.row['fz_goodsStype'] === '2'">成品</span>
					</template>
                  <template #source="{ scope }">
                    <span v-if="scope.row.source == 0">外购</span>
                    <span v-if="scope.row.source == 1">本厂加工</span>
                    <span v-if="scope.row.source == 2">委外加工</span>
                    <span v-if="scope.row.source == 3">其他</span>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <Detail ref="refDetail" />
	  <GoodsDetail ref="refGoodsDetail" />

  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/produce/bom/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
export default defineComponent ({
	name: "SelectProduceBom",
	components: {GoodsDetail, Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		fieldDepot: {type: String, default: '仓库'},
		//传入标题
		title: { type: String, default: '选择BOM清单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			depot: {},
			depotList: [],
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '账户名称',
					prop: 'name',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.produceBom.getList,
				columns: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						width: 180,
						sortable: 'custom',
					},
          {
            label: '产品编码',
            prop: 'fz_goodsCode',
            width: 120,
            sortable: 'custom',
          },
					{
						label: '成品率【%】',
						prop: 'yieldrate',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '版本号',
						prop: 'edition',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物料名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品类型',
						prop: 'fz_goodsStype',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '物料编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '物料规格',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '物料型号',
						prop: 'fz_goodsModel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '需求数量',
						prop: 'demandQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '耗损率(%)',
						prop: 'wearRate',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '实际数量',
						prop: 'actualQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '单价(元)',
						prop: 'price',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '小计金额(元)',
						prop: 'subtotal',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '来源',
						prop: 'source',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '位置号',
						prop: 'location',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '坏料尺寸',
						prop: 'billetSize',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '坏料数量',
						prop: 'billetNumber',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '提前期',
						prop: 'leadTime',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '产品',
						prop: 'fz_goodsName',
						type: 'popGoods',
						default: true,
						span: 8,
					},
					{
						label: '版本号',
						prop: 'edition',
						type: 'input',
						span: 8,
						default: true,
					},
					{
						label: '成品率',
						type: 'rangeNum',
						default: true,
						span: 8,
						fields: [
							{
								prop: 'fz_yieldrate0',
								max: 3,
							},
							{
								prop: 'fz_yieldrate1',
								max: 3,
							}
						]
					},
					{
						label: '备注',
						prop: 'remark',
						type: 'input',
						max: 100,
						span: 8,
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
            max:32,
						span: 8,
						type: 'popUser',
						short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'addTime0',
							},
							{
								prop: 'addTime1',
							},
						],
					},
				],
			},
		})

		//显示弹窗
		const showDialog = (data) => {
			if (data){
				state.tableConfig.params = {
					...state.tableConfig.params,
					...data
				}
			}
			state.visible = true
			state.loading = false
			getDepotList()
		}

		//获取仓库数据
		const getDepotList = async () => {
			const res = await proxy.$API.depot.getAllList.get()
			if(res.data && res.data.length > 0){
				state.depotList = res.data
				state.depot = res.data[0]
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				state.isConfirm = false
			}else{
				state.isDisabled = true
				state.isConfirm = true
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			confirmData()
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				confirmData()
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		const confirmData = () => {
			let data = null
			if(!props.multiple) {
				//单选
				if(state.curRow && Object.keys(state.curRow).length > 0){
					data = state.curRow
					if(JSON.stringify(state.depot) != '{}'){
						data.depotId = state.depot.id
						data.fz_depotName = state.depot.name
					}
				}
			}else{
				//多选
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				state['tableSon'].reload({ bomId: row.id })
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//将接口对象赋给子级表格
				//传入父级ID
				state.tableSonConfig.params = { bomId: res.data.list[0].id }
				state.tableSonConfig.apiObj = proxy.$API.produceBomDetail.selectBomDetail

				//根据主数据加载子数据
				handleRowClick(res.data.list[0])
			} else {
				proxy.$refs.tableSon.reload({ bomId: -1 })
			}

		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}
		/*--------------------已选数据操作---------------------------*/

		return{
			...toRefs(state),
			clearSelections,
			tableSelselectChange,
			delSelData,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
		}
	},
})
</script>
