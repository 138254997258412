<template>
	<div class="syEditTable" :style="{height: noCard?'100%':''}">
		<el-table
			v-infinite-scroll="loadMore"
			ref="table"
			v-loading="listLoading"
			element-loading-text="正在处理中，请稍等..."
			v-bind="$attrs"
			class="edit_table"
			:data="list"
			:height="height === 'auto' ? null : '100%'"
			:max-height="height === 'auto' ? maxHeight : null"
			:size="$store.state.global.uiSize"
			border
			:cell-class-name="cellClassName"
			:row-style="{height: rowHeight}"
			:span-method="spanMethod || arraySpanMethod"
			:show-summary="config.showSummary"
			:summary-method="(val) => getSummaries(val, config.summary)"
			@cell-click="handleCellCLick"
			@current-change="handleCurrentChange"
		>
			<el-table-column
				v-if="checkbox"
				type="selection"
				align="center"
				fixed="left"
				reserve-selection
				:selectable="selectableFn"
			/>
			<el-table-column
				v-else-if="radioCheck && !checkbox"
				width="60"
				align="center"
				fixed="left"
				label="选中"
				:resizable="false"
			>
				<template #default="scope">
					<el-radio
						v-model="curRowKey"
						class="tableRadio"
						:disabled="disRadio(scope.row)"
						name="tableRadio"
						size="small"
						:label="scope.row[radioProp]"
					/>
				</template>
			</el-table-column>

			<el-table-column
				v-if="isIndex"
				align="center"
				label="序号"
				width="55"
				fixed="left"
			>
				<template #default="{ $index }">
					{{ $index + 1 }}
				</template>
			</el-table-column>

			<el-table-column
				v-if="!hideOprate"
				align="center"
				label="操作"
				show-overflow-tooltip
				width="70"
				fixed="left"
			>
				<template #default="{ row }">
					<template v-if="!hideAddBtn">
						<el-button
							circle
							class="add-btn"
							:size="$store.state.global.uiSize"
							type="primary"
							@click="handleAddRow(row)"
						>
							<el-icon>
								<el-icon-plus/>
							</el-icon>
						</el-button>
					</template>
					<template v-else-if="splitBtn">
						<el-button
							v-show="!(disAddProp?((row[disAddProp] && (row[disAddProp]-0) <= 1) || row[disAddProp] === 0):false)"
							circle
							class="add-btn"
							:size="$store.state.global.uiSize"
							type="primary"
							@click="handleAddRow(row)"
						>
							<el-icon>
								<el-icon-plus/>
							</el-icon>
						</el-button>
					</template>

					<template v-if="!hideDelBtn">
						<el-button
							circle
							class="iconBtn"
							plain
							:size="$store.state.global.uiSize"
							type="danger"
							@click="handleDelRow(row)"
						>
							<el-icon>
								<el-icon-minus/>
							</el-icon>
						</el-button>
					</template>
					<template v-else-if="splitBtns">
						<el-button
							v-show="row[disReduceProp] != 2"
							circle
							class="iconBtn"
							plain
							:size="$store.state.global.uiSize"
							type="danger"
							@click="handleDelRow(row)"
						>
							<el-icon>
								<el-icon-minus/>
							</el-icon>
						</el-button>
					</template>
				</template>
			</el-table-column>
			<template v-for="(item, index) in configColumns?config.columns:column" :key="index">
				<el-table-column
					v-if="!item.hide"
					:align="item.align || 'left'"
					:fixed="item.fixed"
					:label="item.label"
					:prop="item.prop"
					:show-overflow-tooltip="item.showOverflowTooltip"
					:width="item.width"
					:min-width="item.minWidth"
					:class-name="item.disable ? 'bgGray' : ''+' '+(item.className || '')"
					:visible="item.visible"
				>
					<template #header="scope">
						<slot
							v-if="item.slotHeader"
							:name="'h_' + item.prop"
							:scope="scope"
							:item="item"
						/>
						<span v-else>
							{{ item.label }}
					  	</span>
					</template>
					<template #default="scope">
						<template v-if="item.disable">
							<span class="cellSp">
							  <slot
								  :name="item.prop"
								  :row="scope.row"
								  :scope="scope"
							  >
								{{ scope.row[item.prop] }}
							  </slot>
							</span>
						</template>
						<template v-else>
							<!--表格选择-->
							<template v-if="item.tableSelect && scope.$index === rowIndex && clickTarget === item.prop">
								<sc-table-select
									v-model="scope.row[item.prop + '_obj']"
									:api-obj="item.config.apiObj"
									:table-width="500"
									:props="item.config.props"
									@change="(val) => handleSelChange(val, item.config.field)"
								>
									<el-table-column
										v-for="(_col, _index) in item.config.columns"
										:key="_index"
										:prop="_col.prop"
										:label="_col.label"
										:width="_col.width"
										show-overflow-tooltip
									/>
								</sc-table-select>
							</template>
							<!--编辑框-->
							<template v-if="item.edit && scope.$index === rowIndex && clickTarget === item.prop">
								<template v-if="item.edit === 'find'">
									<!--物品-->
									<sc-table-select
										v-if="item.prop==='fz_goodsName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.goods.getListByKeyword"
										:params="{ flag: 1 }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectData"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="物品名称"
											width="180"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="code"
											label="物品编码"
											width="120"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="spec"
											label="规格型号"
											width="120"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="mainUnit"
											label="主单位"
											width="80"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="stype"
											label="类型"
											width="80"
											show-overflow-tooltip
										>
											<template #default="{ row }">
												<div v-if="row.stype === 0">
													原材料
												</div>
												<div v-else-if="row.stype === 1">
													半成品
												</div>
												<div v-else-if="row.stype === 2">
													成品
												</div>
											</template>
										</el-table-column>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!-- 供应商-->
									<sc-table-select
										v-else-if="item.prop==='fz_supplierName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.supplier.getListByKeyword"
										:params="{
										flag: 1,
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="selectSupplier"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="供应商名称"
											width="180"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="number"
											label="供应商编码"
											width="120"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--原料送检单-->
									<sc-table-select
										v-else-if="item.prop==='fz_orderNum'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										sel-label="orderNum"
										full-width
										:api-obj="$API.qualitySubmProcure.getListByKeyword"
										:params="{
										flag: 1,qualityStatus:0
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectOrder"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="orderNum"
											label="原料送检单"
											width="250"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_supplierName"
											label="供应商"
											width="250"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--工序名称-->
									<sc-table-select
										v-else-if="item.prop=='fz_procedureName'||item.prop=='procedureName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.produceCraftProcedure.getListByKeyword"
										:params="{
										flag: 1,
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectCraft"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="工序名称"
											width="180"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="code"
											label="工序编号"
											width="120"
											show-overflow-tooltip
										/>
										<el-table-column
											v-if="item.prop === 'fz_procedureName'"
											prop="workTime"
											label="工作时长"
											width="100"
											show-overflow-tooltip
										/>
										<el-table-column
											v-if="item.prop === 'fz_procedureName'"
											prop="fz_managerName"
											label="负责人"
											width="100"
											show-overflow-tooltip
										/>
										<el-table-column
											v-if="item.prop === 'fz_procedureName'"
											prop="workGroupName"
											label="工作组"
											width="100"
											show-overflow-tooltip
										/>
										<el-table-column
											v-if="item.prop === 'fz_workshopName'"
											prop="workTime"
											label="工作车间"
											width="100"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--加工单-->
									<sc-table-select
										v-else-if="item.prop==='fz_processNo'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										selLabel="orderNo"
										:api-obj="$API.produceProcess.getListByKeyword"
										:params="item.params || {}"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectProcessNo"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="orderNo"
											label="加工单号"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_goodsName"
											label="产品名称"
											width="180"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_goodsSpec"
											label="规格型号"
											width="120"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="quantity"
											label="生产数量"
											width="110"
											show-overflow-tooltip
										/>

										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--工作组-->
									<sc-table-select
										v-else-if="item.prop==='workerName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.user.getListByKeyword"
										:params="{
										flag: 1,
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectCraft"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="用户姓名"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="code"
											label="工号"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_departmentName"
											label="部门"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="phone"
											label="联系电话"
											width="150"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--公共-->
									<sc-table-select
										v-else-if="item.prop"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										:multiple="item.multiple"
										customer-value
										:clearable="false"
										full-width
										:classify="item.classify"
										:sonMultiple="item.sonMultiple"
										:isFinance="item.isFinance"
										:hide-pagination="item.hidePagination"
										:selLabel="item.selLabel||'name'"
										:api-obj="item.apiObj"
										:params="item.params"
										:height="item.height||426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="(data)=>selectPurOrderNo(data,item,scope.row)"
										@handle-visible="handleVisibleChange"
										@remove-tag="(tag)=>handleRemove(tag,scope.row,item)"
									>
										<template v-for="_index in item.propName.length" :key="_index">
											<el-table-column
												:prop="item.propName[_index-1]"
												:label="item.labelName[_index-1]"
												:width="item.colWidth?item.colWidth[_index-1]:200"
												show-overflow-tooltip
											>
												<template #default="_scope">
                          <template v-if="item.propName[_index-1] === 'balanceDirection'">
                            <span v-if="_scope.row.balanceDirection===1" style="color: #67c23a">借方</span>
                            <span v-else-if="_scope.row.balanceDirection===2" style="color: #f56c6c">贷方</span>
                          </template>
													<template v-if="item.propName[_index-1] === 'special'">
														<el-tag
															v-if="_scope.row.special === 1"
															type="danger"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															是
														</el-tag>
														<el-tag
															v-else
															type="info"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															否
														</el-tag>
													</template>
													<template v-if="item.propName[_index-1] === 'receiptStatus'">
														<el-tag
															v-if="_scope.row.receiptStatus === 0"
															type="info"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															未收款
														</el-tag>
														<el-tag
															v-if="_scope.row.receiptStatus === 1"
															type="warning"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															部分收款
														</el-tag>
														<el-tag
															v-if="_scope.row.receiptStatus === 2"
															type="success"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															全部收款
														</el-tag>
													</template>
													<template v-if="item.propName[_index-1] === 'paymentStatus'">
														<el-tag
															v-if="_scope.row.paymentStatus === 0"
															type="info"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															未付款
														</el-tag>
														<el-tag
															v-if="_scope.row.paymentStatus === 1"
															type="warning"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															部分付款
														</el-tag>
														<el-tag
															v-if="_scope.row.paymentStatus === 2"
															type="success"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															全部付款
														</el-tag>
													</template>
													<template v-if="item.propName[_index-1] === 'ticketStatus'">
														<el-tag
															v-if="_scope.row.ticketStatus === 0"
															type="info"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															未开票
														</el-tag>
														<el-tag
															v-if="_scope.row.ticketStatus === 1"
															type="warning"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															部分开票
														</el-tag>
														<el-tag
															v-if="_scope.row.ticketStatus === 2"
															type="success"
															class="myTag"
															:size="$store.state.global.uiSize"
														>
															全额开票
														</el-tag>
													</template>
												</template>
											</el-table-column>
										</template>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--收款单销售订单-->
									<sc-table-select
										v-else-if="item.prop==='fz_saleOrderNo'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.saleOrder.getListByKeyword"
										:params="{
										flag: 1,
										auditStatus:-1,
										receiptStatusZeroAndOne:'true'
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectSaleOrder"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="姓名"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_departmentName"
											label="部门"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="code"
											label="工号"
											width="150"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--暂定-->
								</template>
								<template v-else-if="item.edit === 'findedit'">
									<!--供应商-->
									<sc-table-select
										v-if="item.prop=='fz_supplierName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.supplier.getListByKeyword"
										:params="{
										flag: 1,
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="selectSupplier"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="供应商名称"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="number"
											label="供应商编码"
											width="200"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--合同条款-->
									<sc-table-select
										v-else-if="item.prop==='theme'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										customer-value
										automatic-dropdown
										:clearable="false"
										full-width
										sel-label="theme"
										:api-obj="$API.clause.getListByKeyword"
										:params="{
										flag: 1,menuCode:menuCode
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="selectTheme"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="theme"
											label="主题"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="content"
											label="内容"
											width="200"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--采购订单-->
									<sc-table-select
										v-else-if="item.prop==='fz_purOrderNo'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										sel-label="orderNo"
										:api-obj="$API.purchaseOrder.getListByKeyword"
										:params="{
										flag: 1,
										auditStatus:[3,5],paymentStatusZeroAndOne:'true',
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="(data)=>selectPurOrderNo(data,item,scope.row)"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="orderNo"
											label="主题"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_supplierName"
											label="供应商"
											width="200"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--转加工单-->
									<sc-table-select
										v-else-if="item.prop==='fz_managerName'||item.prop==='fz_shareUserName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										:multiple="item.multiple"
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.user.getListByKeyword"
										:params="{
										flag: 1,
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectUser"
										@handle-visible="handleVisibleChange"
										@remove-tag="(tag)=>handleRemove(tag,scope.row,item)"
									>
										<el-table-column
											prop="name"
											label="姓名"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="fz_departmentName"
											label="部门"
											width="150"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="code"
											label="工号"
											width="150"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--工作组-->
									<sc-table-select
										v-else-if="item.prop==='workGroupName'"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:api-obj="$API.produceCraftWorkgroup.getListByKeyword"
										:params="{
										flag: 1,
									  }"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="setSelectWorkgroup"
										:row-height="30"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											prop="name"
											label="工作组名称"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											prop="number"
											label="工作组编码"
											width="200"
											show-overflow-tooltip
										/>
										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
									<!--公共-->
									<sc-table-select
										v-else-if="item.prop"
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										automatic-dropdown
										customer-value
										:clearable="false"
										full-width
										:selLabel="item.selLabel==='orderNo'?'orderNo':'name'"
										:api-obj="item.apiObj"
										:params="item.params"
										:height="426"
										:props="{ label: 'name', value: 'id', keyword: 'name' }"
										@change="selectSubject"
										@handle-visible="handleVisibleChange"
									>
										<el-table-column
											:prop="item.propName[0]"
											:label="item.labelName[0]"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											:prop="item.propName[1]"
											:label="item.labelName[1]"
											width="200"
											show-overflow-tooltip
										/>
										<el-table-column
											v-if="item.propName[2]"
											:prop="item.propName[2]"
											:label="item.labelName[2]"
											width="200"
											show-overflow-tooltip
										/>

										<template #suffix>
											<el-icon
												title="点击选择"
												@click="popSelectDetails(scope.row, item)"
											>
												<el-icon-search/>
											</el-icon>
										</template>
									</sc-table-select>
								</template>
								<template v-else-if="item.edit === 'dict'">
									<dicts-query
										v-model="scope.row[item.prop]"
										:placement="item.placement"
										:type-code="item.typeCode"
									/>
								</template>
								<template v-else-if="item.edit === 'textarea'">
									<el-input
										:id="item.prop + scope.$index + (config.tableKey || '')"
										:ref="'input'+item.prop + scope.$index + (config.tableKey || '')"
										v-model="scope.row[item.prop]"
										type="textarea"
										:maxlength="item.max"
										:rows="item.rows"
										:show-word-limit="item.showLimit"
										@blur="blurEdit(item, scope.row)"
									/>
								</template>
								<!--日期选择-->
								<el-date-picker
									v-else-if="item.edit==='date'"
									:id="item.prop + scope.$index + (config.tableKey || '')"
									v-model="scope.row[item.prop]"
									value-format="YYYY-MM-DD"
									clearable
									@visible-change="visibleChange"
									@blur="blurEdit(item, scope.row)"
								/>
								<!--日期时间选择-->
								<el-date-picker
									v-else-if="item.edit=='datetime'"
									:id="item.prop + scope.$index + (config.tableKey || '')"
									v-model="scope.row[item.prop]"
									type="datetime"
									value-format="YYYY-MM-DD HH:mm:ss"
									clearable
									:default-time="item.defaultTime"
									@visible-change="visibleChange"
									@blur="blurEdit(item, scope.row)"
								/>
								<!--自动补全输入框-->
								<el-autocomplete
									v-else-if="item.edit==='autoInput'"
									:id="item.prop + scope.$index + (config.tableKey || '')"
									ref="autocompleteRef"
									v-model="scope.row[item.prop]"
									popper-class="my-autocomplete"
									:fetch-suggestions="(str,cb)=>querySearch(str,cb,scope.row,item)"
									placeholder="请选择或输入"
									@focus="autocompleteClick(scope.row,item,$event)"
									@select="(val)=>autoInputSelect(val,item,scope.row)"
									@change="(val)=>autoChange(val,item,scope.row)"
								>
									<template #default="autoItem">
										<template v-if="item.showCode">
											<span>{{ autoItem.item.number }}</span>
											<span class="value ml10">{{ autoItem.item.value }}</span>
										</template>
									</template>
								</el-autocomplete>
								<!--输入弹出框-->
								<template v-else-if="item.edit==='popoverInput'">
									<el-popover
										v-if="!!scope.row[item.popProp]"
										:ref="'refPopover'+scope.$index"
										:visible="item.initVisible||true"
										:title="item.title||''"
										:width="item.popWidth||'auto'"
										:trigger="item.trigger||'click'"
									>
										<template #reference>
											<el-input v-model="scope.row[item.prop]" :size="$store.state.global.uiSize" readonly/>
										</template>
										<slot :name="'pop_'+item.prop" :scope="scope" :row="scope.row"/>
									</el-popover>
								</template>
								<!--输入框-->
								<el-input
									v-else
									:id="item.prop + scope.$index + (config.tableKey || '')"
									:ref="'input'+item.prop + scope.$index + (config.tableKey || '')"
									v-model="scope.row[item.prop]"
									clearable
									:disabled="inputDisFunc?inputDisFunc(scope.row,item) : false"
									:maxlength="item.max"
									:size="$store.state.global.uiSize"
									@keydown.enter="(event)=>handleEnterKey(event,scope.$index)"
									@keydown.tab="(event)=>handleTabKey(event,scope.row,scope.$index,item)"
									@input="(val) => handleInput(val, item)"
									@clear="handleClear(item, scope.row)"
									@focus="focusEdit(item, scope.row)"
									@blur="blurEdit(item, scope.row)"
								></el-input>
							</template>
							<!--多级表头-->
							<template v-else-if="item.columns">
								<el-table-column
									v-for="(son,cindex) in item.columns"
									:key="cindex"
									:label="son.label"
									:prop="son.prop"
									align="center"
									:class-name="'th-son'+' '+son.className+' '+(son.disable ? 'bgGray' : '')"
									:fixed="son.fixed"
									:show-overflow-tooltip="son.showOverflowTooltip"
									:width="son.width"
								>
									<template #default="sonScope">
										<template v-if="sonScope.$index === rowIndex && clickTarget === son.prop">
											<!--日期选择-->
											<el-date-picker
												v-if="son.edit=='date'"
												:id="son.prop + scope.$index + (config.tableKey || '')"
												v-model="scope.row[son.prop]"
												value-format="YYYY-MM-DD"
												clearable
												@visible-change="visibleChange"
												@blur="blurEdit(son, scope.row)"
											/>
											<el-input
												v-else
												:id="son.prop + sonScope.$index + (config.tableKey || '')"
												:ref="'input'+item.prop + scope.$index + (config.tableKey || '')"
												v-model="sonScope.row[son.prop]"
												clearable
												:maxlength="son.maxlength || item.max"
												@input="(val) => handleInput(val, son)"
												@clear="handleClear(son, sonScope.row)"
												@blur="blurEdit(son, sonScope.row)"
                        @keydown.tab="(event)=>handleTabKey(event,sonScope.row,sonScope.$index,son)"
											/>
										</template>
										<template v-else>
											<template v-if="son.digit">
												{{ getDigit(sonScope.row[son.prop], cindex) }}
											</template>
											<template v-else>
												{{ sonScope.row[son.prop] }}
											</template>
										</template>
									</template>
								</el-table-column>
							</template>
							<!--地区选择 options: json数据  props: 字段映射-->
							<el-cascader
								v-else-if="item.casCader && scope.$index === rowIndex && clickTarget === item.prop"
								:id="item.prop + scope.$index + (config.tableKey || '')"
								v-model="area[item.prop + scope.$index]"
								clearable
								:options="$API.area"
								separator="-"
								placeholder="选择地区"
								:props="{
								label: 'name',
								value: 'name',
								expandTrigger: 'hover',
							  }"
								style="width: 100%"
								@change="(val) => handleArea(val, item)"
							/>
							<!--下拉选择-->
							<el-select
								v-else-if="item.select && scope.$index === rowIndex && clickTarget === item.prop"
								:id="item.prop + scope.$index + (config.tableKey || '')"
								v-model="scope.row[item.prop]"
								value-key="id"
								filterable
								:size="$store.state.global.uiSize"
								@change="(val) => elselChange(val, scope.row, item)"
								@visible-change="(val) => handleSelectVisible(val,item)"
								:multiple="item.multiple"
							>
								<template v-if="item.options">
									<el-option
										v-for="(_option, _index) in item.options"
										:key="_index"
										:value="_option.value"
										:label="_option.label"
									/>
								</template>
								<slot
									v-else
									:name="'option_'+ item.prop"
									:item="item"
									:row="scope.row"
								/>
							</el-select>
							<!--气泡提示框-->
							<el-tooltip
								v-else-if="item.tooltip && scope.$index === rowIndex && clickTarget === item.prop"
								class="box-item"
								effect="light"
								placement="bottom-start"
							>
								<template #content>
									<div
										v-for="(price, _index) in priceList"
										class="input-tooltip"
										:key="_index"
										@click="setPrice(scope.row,item,price)"
									>
										{{ price.name }}：{{ price.price }}
									</div>
								</template>
								<el-input
									:id="item.prop + scope.$index + (config.tableKey || '')"
									:ref="'input'+item.prop + scope.$index + (config.tableKey || '')"
									v-model="scope.row[item.prop]"
									:size="$store.state.global.uiSize"
									clearable
									:maxlength="item.max"
									@input="(val) => handleInput(val, item, item.prop)"
									@clear="handleClear(item, scope.row)"
									@blur="blurEdit(item, scope.row)"
								/>
							</el-tooltip>
							<!--文字显示-->
							<div
								v-else
								class="cellSp"
							>
								<slot
									:name="item.prop"
									:row="scope.row"
									:scope="scope"
								>
									{{ scope.row[item.prop] }}
								</slot>
							</div>
						</template>
					</template>
				</el-table-column>
			</template>
			<template #append>
				<slot name="append"/>
			</template>
		</el-table>
	</div>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	nextTick,
	getCurrentInstance,
	toRef,
	watch,
	ref,
	computed
} from "vue";
import {resetNum, resetZnum, handleCutZero, resetPositiveNum, resetPnum} from "@/utils/validate";

export default defineComponent({
	name: "SyEditTable",
	props: {
		//配置项
		config: {
			type: Object, default: () => {
			}
		},
		menuCode: {type: String, default: ""},
		depot: {
			type: Object, default: () => {
			}
		},
		focus: {type: Boolean, default: false},
		hideOprate: {type: Boolean, default: false},
		hideAddBtn: {type: Boolean, default: false},
		splitBtn: {type: Boolean, default: false}, //拆分按钮
		splitBtns: {type: Boolean, default: false}, //明细删除按钮禁用
		hideDelBtn: {type: Boolean, default: false},
		isIndex: {type: Boolean, default: true},
		cellClassName: { type: Function, default: () => {}},
		// 行高
		rowHeight: {type: String, default: ""},
		//表格合并方法
		spanMethod: {type: Function, default: null},
		//合计方法
		sumMethod: {type: Function, default: null},
		//是否显示复选框列
		checkbox: {type: Boolean, default: false},
		//是否显示单选框列
		radioCheck: {type: Boolean, default: false},
		//复选框禁用配置
		disCheck: {type: Object, default: null},
		//表格标识符
		tableRef: {type: String, default: ""},
		//复制按钮禁用字段
		disAddProp: {type: String, default: ""},
		disReduceProp: {type: String, default: ""},
		//单选框字段
		radioProp: {type: String, default: "id"},
		//回车键进入下一行
		enterNext: {type: Boolean, default: false},
		//是否初始聚焦
		initFocus: {type: Boolean, default: false},
		//输入框是否禁用
		inputDisFunc: {type: [Function,Boolean], default: false},
		//最大高度
		maxHeight: {type: [String, Number], default: 300},
		//表格高度
		height: {type: [String, Number], default: "100%"},
		//是否未嵌套在卡片中
		noCard: {type: Boolean, default: false},
		//直接使用配置项
		configColumns: {type: Boolean, default: false},

	},
	emits: ["pop-select-detail",'setCellClass','select-purorder', "select-pop", "clear-find", "el-select-change", "blur-edit", "input-edit", "auto-select", "auto-change", "add-row", "remove-row", "get-summary", "select-visiblity", "span-method", "select-find", "set-price", "load-more",'tab-keydown'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			canAdd: true,
			visiblePop: false,
			curRowKey: "",
			//暂存地区选择
			area: {},
			//表格数据
			list: [],
			//行数索引
			rowIndex: 0,
      //表格对象
      table: null,
			//单元格点击对象
			clickTarget: "",
			//表格配置项
			tableOptions: props.config,
			//加载动画
			listLoading: false,
			panelShow: false,
			//
			column: props.config.columns,
			//自动补全输入框列表项
			autoCompleteList: [],
			//点击元素Id
			clickId: "",
			params: {
				goodsId: null,
				menuCode: null,
				flag: null
			},
			toolitipObject: {},
			toolitipTitle: [],
			toolitipContent: [],
			priceList: [],// 价格列表
		});
		//编辑表格
		//初始数据
		const initList = (bool) => {
			state.listLoading = true;
			let list = [];
			if (bool) {
				//修改
				const childLength = state.list.length;
				const difference = state.tableOptions.initRows - childLength;
				state.list.forEach((item) => {
					list.push(item);
				});
				if (difference > 0) {
					// for (let i = 0; i < difference; i++) {
					//   list.push(Object.assign({}, state.tableOptions.blankitem))
					// }
					list = list.concat(Array.from({length: difference}, () => Object.assign({}, state.tableOptions.blankitem)));
				}
			} else {
				//新增
				if (state.tableOptions != null) {
					// for (let i = 0; i < state.tableOptions.initRows; i++) {
					//   list.push(Object.assign({}, state.tableOptions.blankitem))
					// }
					list = Array.from({length: state.tableOptions.initRows}, () => Object.assign({}, state.tableOptions.blankitem));
				}
			}
			state.list = list;
			state.listLoading = false;
		};

		const updateDepot = toRef(props, "depot");

		watch(updateDepot, () => {
			let obj = {
				name: updateDepot.value.name,
				id: updateDepot.value.id - 0
			};
			state.list.forEach(function (item, index) {
				if (JSON.stringify(item) === "{}" || item.depot == undefined) {
					return;
				}
				item.depot = obj;
				item.fz_depotName = obj.name;
				item.depotId = obj.id;
			});
		});

		//单元格点击
		const handleCellCLick = (row, column, cell, event) => {
			if(row.applyStatus){
				emit("apply-status-edit",row,column);
			}
			if (row.goodsId && props.config.parameter) {
				state.params.goodsId = row.goodsId;
				if (column.property === "price") {
					state.params.flag = "price";
				} else {
					state.params.flag = "taxPrice";
				}
				getPriceList();
			}
			if (!cell.innerText.trim()) {
				// 单元格内无文字时，手动设置焦点（假设这里是 el-input 组件）
				const gonext = true;
				const colprop = column.property;
				state.rowIndex = state.list.indexOf(row);
				const target = colprop + state.rowIndex + (props.config.tableKey || "");
				state.clickId = target;
				if (gonext) {
					state.clickTarget = colprop;
					nextTick(() => {
						// console.log(target)
						const iobj = document.getElementById(target);
						if (iobj) {
							const isRead = iobj.hasAttribute("readonly");
							iobj.focus();
							const refEl = proxy.$refs[target];
							if (refEl && refEl[0].focus()) {
								refEl[0].focus();
							}
							if (!isRead) {
								// iobj.select()
							}
							state.tableOptions.isFocus = true;
						}
					});
				}
			}
			if (event.target.tagName === "TD" || event.target.tagName === "DIV" || event.target.tagName === "SPAN") {
				event.stopPropagation();
				console.log(123)
				state.clickTarget = "";
				let gonext = false;
				const colprop = column.property;
				//获取点击的行索引
				state.rowIndex = state.list.indexOf(row);
				//拼接点击的元素id
				const target = colprop + state.rowIndex + (props.config.tableKey || "");
				state.clickId = target;
				//不设置默认列
				if (!state.tableOptions.defaultCol) {
					gonext = true;
				} else if (colprop === state.tableOptions.defaultCol) {
					gonext = true;
				} else if (state.tableOptions.defaultCol === "disabled") {
					gonext = false;
				} else {
					if (row[state.tableOptions.defaultCol]) {
						gonext = true;
					}
				}
				if (state.tableOptions.disRowProps) {
					// 暂MRP运算使用
					if (state.tableOptions.disRowProps.length === 1 && row[state.tableOptions.disRowProps[0]]) {
						// 该禁用字段有数据则禁止编辑
						gonext = false;
					} else if (state.tableOptions.disRowProps.length === 2) {
						console.log(row[state.tableOptions.disRowProps[0]],row[state.tableOptions.disRowProps[1]])
						if (row[state.tableOptions.disRowProps[0]] === row[state.tableOptions.disRowProps[1]]) {
							// 数值1等于数值2则禁止编辑
							gonext = false;
						}
					}
				}
				// // 采购申请编辑时，申请来源为采购建议，则数据不能修改
				// if (state.tableOptions.disRowProp) {
				// 	if(row[state.tableOptions.disRowProp[1]] == 2){
				// 		// 该禁用字段有数据则禁止编辑
				// 		gonext = false;
				// 	}
				// }
				if (gonext) {
					state.clickTarget = colprop;
					nextTick(() => {
						const iobj = document.getElementById(target);
						if (iobj) {
							const isRead = iobj.hasAttribute("readonly");
							iobj.focus();
							const inputTarget = "input" + target;
							//console.log(proxy.$refs[inputTarget]);
							if (inputTarget) {
								if (proxy.$refs[inputTarget] && proxy.$refs[inputTarget][0] && (typeof proxy.$refs[inputTarget][0].select) == "function") {
									proxy.$refs[inputTarget][0].select();
								}
							}
							const refEl = proxy.$refs[target];
							if (refEl && refEl[0].focus()) {
								refEl[0].focus();
							}
							if (!isRead) {
								// iobj.select()
							}
							state.tableOptions.isFocus = true;
						}
					});
				}
			}
			//emit("row-click", row);
		};

		//输入框输入 val:输入的值， item: 传入的行数据
		const handleInput = (val, item, prop) => {
			//数字-负数、小数
			if (item.edit === "number") {
				state.list[state.rowIndex][item.prop] = resetNum(val);
			}
			//数字-正数
			if (item.edit === "pNum") {
				state.list[state.rowIndex][item.prop] = resetPnum(val);
			}
			//工资单中附加项只能输入正数
			if (item.edit === "number" && item.special) {
				state.list[state.rowIndex][item.prop] = resetPositiveNum(val);
			}
			//正整数
			if (item.edit === "num") {
				state.list[state.rowIndex][item.prop] = resetZnum(val);
			}
      //数字-负数、小数
      if (prop && (prop === 'price' || prop === 'taxPrice')) {
        state.list[state.rowIndex][item.prop] = resetNum(val);
      }
			emit("input-edit", {row: state.list[state.rowIndex], item: item});
		};

		//添加空白行
		const handleAddRow = (row) => {
			if (state.canAdd) {
				const index = state.list.indexOf(row);
				state.list.splice(index + 1, 0, Object.assign({}, state.tableOptions.blankitem));
				emit("add-row", index + 1);
			}
		};

		//删除行
		const handleDelRow = (row) => {
			const index = state.list.indexOf(row);
			if (state.list.length > 1) {
				state.list.splice(index, 1);
			}
			emit("remove-row", row);
		};

		//地区选择变化 val:选择后的地区数组对象
		const handleArea = (val, item) => {
			if (val) {
				//将数组对象转为字符串
				state.list[state.rowIndex][item.prop] = val.join("-");
			} else {
				//清空
				state.list[state.rowIndex][item.prop] = "";
			}
		};

		//输入框清除
		const handleClear = (item, row) => {
			//清除的输入框是第一个，则清除整行数据
			if (item.prop === state.tableOptions.defaultCol && row) {
				Object.keys(row).forEach((key) => {
					row[key] = "";
				});
			}
		};
		const tableSelectRef = ref(null);

		onMounted(async () => {
			initList();
			await nextTick(() => {
				document.addEventListener("click", (event) => {
					let stop = false;
					if (event.target.className === "el-input__inner" || event.target.className === "el-popper is-light el-popover") {
						stop = true;
					}
					if (!state.panelShow && !stop && !props.initFocus) {
						state.clickTarget = "";
					}
				});
			}); // 等待组件渲染完成
			const tableSelect = tableSelectRef.value; // 获取组件实例
			if (tableSelect) {
				const tableHeader = tableSelect.$el.querySelector("thead"); // 获取表头元素
				if (tableHeader) {
					tableHeader.addEventListener("click", event => {
						event.stopPropagation(); // 阻止事件冒泡
						console.log("表头被点击了");
					});
				} else {
					console.log("未找到表头元素");
				}
			} else {
				console.log("未找到表格组件实例");
			}
		});

		const handleSelChange = (data) => {

		};

		//弹出选择
		const popSelectDetails = (row, item) => {
			console.log(row, "row");
			console.log(item, "item");
			emit("pop-select-detail", item, props.config.tableKey, row);
			// 延迟清除聚焦
			nextTick(() => {
				console.log(234)
				state.clickTarget = "";
			});
		};
		// 下拉框
		// 	const selectPop=(row,item)=>{
		// 		emit('select-pop',item, props.config.tableKey, row)
		// 	}
		const querySearch = async (queryString, cb, row, item) => {

			console.log(queryString, cb, row, item, "queryString, cb, row, item");
			if (state.autoCompleteList.length < 1) {
				console.log(22222);
				await autocompleteClick(row, item);
			}
			let obj = [];
			setTimeout(() => {
				state.autoCompleteList.forEach((item) => {
					if (item.value.indexOf(queryString) > -1 || item.number.indexOf(queryString) > -1) {
						obj.push(item);
					}
				});
			}, 0);
			setTimeout(() => {
				cb(obj);
			}, 0);
		};

		const autocompleteClick = async (row, item) => {
			state.autoCompleteList = [];
			if (item && item.apiObj) {
				const param = {};
				const res = await item.apiObj.get(param);
				if (res.code === 200) {
					state.autoCompleteList = [];
					res.data.list.forEach((res) => {
						const obj = {};
						obj["number"] = res.number;
						obj["value"] = res.name;
						obj["id"] = res.id;
						state.autoCompleteList.push(obj);
					});
				}
			}
		};

		const handleSelect = () => {
		};

		const showSelGoodSimple = (ref) => {
			proxy.$refs.refSelectGoodsSimple.triggerDom = proxy.$refs[ref];
			proxy.$refs.refSelectGoodsSimple.showDialog();
		};

		//下拉框点击
		const elselChange = (val, row, item) => {
			emit("el-select-change", {row: row, col: item, val: val});
		};

		const blurEdit = (item, row) => {
			emit("blur-edit", {item: item, row: row, key: props.config.tableKey});
		};
		const focusEdit = (item, row) => {
			console.log(props.focus,'focus')
			// if(props.focus){
			// 	emit("focus-edit", {item: item, row: row, key: props.config.tableKey});
			// }
		};

		const autoInputSelect = (val, item, row) => {
			// console.log(val,item,row,'----------------')
			// const inputId = item.prop + state.rowIndex + (props.config.tableKey || '')
			// const inputElement = document.getElementById(inputId)
			// if (inputElement) {
			// 	inputElement.blur()
			// }
			emit("auto-select", {value: val, item: item, row: row});
		};
		const autoChange = (val, item, row) => {
			emit("auto-change", {value: val, item: item, row: row});
		};
		//合计
		const getSummaries = (param, fields) => {
			let sums = [];
			if (props.sumMethod) {
				sums = props.sumMethod(param, fields);
			} else {
				const {columns, data} = param;
				if (columns.length < 1 || fields.length < 1) return;
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = "合计";
						return;
					}
					const values = data.map((item) => Number(item[column.property]));
					if (!values.every((value) => isNaN(value))) {
						fields.forEach((field) => {
							if (column.property === field) {
								sums[index] = `${values.reduce((prev, curr) => {
									const value = Number(curr);
									if (!isNaN(value)) {
										return prev + curr;
									} else {
										return prev;
									}
								}, 0)}`;
								sums[index] = (sums[index] - 0)?handleCutZero((sums[index] - 0).toFixed(4)):0
							}
						});
					} else {
						sums[index] = "";
					}
				});
				emit("get-summary", sums);
			}
			return sums;
		};

		const visibleChange = (bool) => {
			state.panelShow = bool;
		};

		//表格下拉显示回调
		const handleVisibleChange = (bool) => {
			state.panelShow = bool;
		};

		const handleRemove = async (tag, row, item) => {
			if (item.fields && item.fields[0]) {
				row[item.fields[0]] = row[item.prop].map((item) => item.id).join();
				row[item.fields[1]] = row[item.prop].map((item) => item.name).join();
			}
		};
		//下拉框显示隐藏
		const handleSelectVisible = (val, item) => {
			if (val) {
				emit("select-visiblity", item);
			}
		};

		//清除选择
		const handleClearInput = (row, item) => {
			emit("clear-find", row, item);
		};
		//合并列(可改为prop传入)
		const arraySpanMethod = ({row, column, rowIndex, columnIndex}) => {
			if (!props.config.merge) return;
			if (rowIndex === state.rowIndex && column.property === state.clickTarget) {
				let arr = [1, 1];
				props.config.merge.forEach((item) => {
					const colLength = item.end - item.start;
					if (columnIndex === item.start + (props.isIndex ? 1 : 0)) {
						arr = [1, colLength + 1];
					} else if (columnIndex > item.start && columnIndex <= item.end + (props.isIndex ? 1 : 0)) {
						arr = [0, 0];
					}
				});
				return arr;
			}
		};
		const handleClick = (event) => {
			event.stopPropagation();
		};
		//数据拆分
		const getDigit = (number, index) => {
			if (!number) return;
			if(props.config.merge){
				const start = props.config.merge[0].start;
				const end = props.config.merge[0].end;
				const length = end - start;
				//右侧合计
				if (index > length) {
					index = index - (length + 1);
				}
				let numberString = number.toString().replace(".", ""); // 将数字转换为字符串，并去掉小数点
				let digitArray = numberString.split(""); // 将字符串转换为数组

				while (digitArray.length <= length) {
					digitArray.unshift(""); // 在数组左边填充空数据
				}
				return digitArray[index];
			}else return 0
		};
		//复选框是否可勾选
		const selectableFn = (row) => {
			if (props.disCheck) {
				if (props.disCheck.customFunc) {
					return props.disCheck.customFunc(row);
				} else if (props.disCheck.prop) {
					//根据传入prop判断
					return row[props.disCheck.prop] ? true : false;
				}
			} else {
				return true;
			}
		};

		const toggleAllSelection = () => {
			proxy.$refs.table.toggleAllSelection();
		};
		//设置当前行
		const setCurrentRow = (row) => {
			proxy.$refs.table.setCurrentRow(row);
		};
		const clearSelection = () => {
			proxy.$refs.table.clearSelection();
		};
		const toggleRowSelection = (row, bool) => {
			proxy.$refs.table.toggleRowSelection(row, bool);
		};
		//物品
		const setSelectData = (data) => {
			emit("select-pop", data);
		};
		//供应商
		const selectSupplier = (data) => {
			emit("select-supplier", data);
		};
		//合同条款
		const selectTheme = (data) => {
			emit("select-theme", data);
		};
		// 销售订单
		const setSelectOrder = (data) => {
			emit("select-order", data);
		};
		// 	工序
		const setSelectCraft = (data) => {
			emit("select-craft", data);
		};
		// 加工单
		const setSelectProcessNo = (data) => {
			emit("select-process", data);
		};
		// 采购单
		const selectPurOrderNo = (data, item, row) => {
			emit("select-purorder", data);
			emit("select-find", {data: data, item: item, row: row});
		};
		const setSelectSaleOrder = (data) => {
			emit("select-saleorder", data);
		};
		const selectSubject = (data) => {
			emit("select-subject", data);
		};
		const setSelectUser = (data) => {
			emit("select-user", data);
		};
		const setSelectWorkgroup = (data) => {
			emit("select-workgroup", data);
		};
		const getPriceList = async () => {
			state.priceList = [];
			state.params.menuCode = props.config.parameter.menuCode;
			let res = null;
			if (props.config.parameter.type === "sale") {
				res = await proxy.$API.goodsReferencePrice.salePrice.post(state.params);

			} else if (props.config.parameter.type === "purchase") {
				res = await proxy.$API.goodsReferencePrice.purchasePrice.post(state.params);
			}
			if (res && res.code === 200 && res.data) {
				state.priceList = Object.keys(res.data).map(key => {
					return {
						name: key,
						price: res.data[key]
					};
				});
			}
		};
		const handleCurrentChange = (row) => {
			if (row) {
				state.curRowKey = row[props.radioProp];
			}
		};
		const disRadio = (row) => {
			if (props.disCheck) {
				if (props.disCheck.customFunc) {
					return !props.disCheck.customFunc(row);
				} else if (props.disCheck.prop) {
					//根据传入prop判断
					return row[props.disCheck.prop] ? false : true;
				}
			} else {
				return false;
			}
		};
		const setPrice = (row, item, price) => {
			if (row && item && price) {
				row[item.prop] = price.price;
				emit("set-price", {row: row, item: item, price: price});
			}
		};
		// 键盘回车事件--使下一格聚焦
		const handleEnterKey = (event, index) => {
			if (props.enterNext) {
				// 获取当前输入框
				const target = state.clickTarget + index + (props.config.tableKey || "");
				if (target && event.target.id === target) {
					// 使当前表格选中行+1
					state.rowIndex = index + 1;
					// 获取下一输入框
					const nextTarget = state.clickTarget + (index + 1) + (props.config.tableKey || "");
					nextTick(() => {
						const iobj = document.getElementById(nextTarget);
						if (iobj && typeof iobj.focus === "function") {
							iobj.focus(); // 聚焦
							if (typeof iobj.select === "function") {
								iobj.select(); // 自动选中
							}
						}
					});
				}
			}
		};
		// 键盘tab事件
		const handleTabKey = (event, row,index,item) => {
      if(event.key === 'Tab'){
        emit('tab-keydown', {row: row, index: index, item: item})
      }
		};
		/*------------------------------*/
		const debounce = (fn, delay) => {
			let timer = null;
			return function () {
				let context = this;
				let args = arguments;
				clearTimeout(timer);
				timer = setTimeout(function () {
					try {
						fn.apply(context, args);
					} catch (e) {}
				}, delay);
			};
		};

		const _ResizeObserver = window.ResizeObserver;
		window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
			constructor(callback) {
				callback = debounce(callback, 16);
				super(callback);
			}
		};
		/*-------------------------------*/

		// 加载更多
		const loadMore = () => {
			// const list = Array.from({length: 10}, () => Object.assign({}, state.tableOptions.blankitem));
			// state.list.push(...list)
			emit("load-more");
		};

		const focusCell = (prop,index) => {
			if(index){
				state.rowIndex = index
			}
			const rowIndex = state.rowIndex
			setTimeout(()=>{
				state.clickTarget = prop
				nextTick(()=>{
					const element = document.getElementById(prop+rowIndex+(props.config.tableKey||''))

					if(element && typeof element.focus == 'function'){
						element.focus()
					}
				})
			},100)
		}
		return {
			...toRefs(state),
			focusCell,
			loadMore,
			handleEnterKey,
      handleTabKey,
			handleSelectVisible,
			handleRemove,
			handleClearInput,
			visibleChange,
			disRadio,
			getSummaries,
			handleCurrentChange,
			blurEdit,
			focusEdit,
			autoInputSelect,
			autoChange,
			elselChange,
			showSelGoodSimple,
			handleSelect,
			querySearch,
			autocompleteClick,
			popSelectDetails,
			handleSelChange,
			initList,
			handleClear,
			handleArea,
			handleAddRow,
			handleDelRow,
			handleCellCLick,
			handleInput,
			arraySpanMethod,
			getDigit,
			selectableFn,
			toggleAllSelection,
			setCurrentRow,
			clearSelection,
			toggleRowSelection,
			setSelectData,
			selectSupplier,
			selectTheme,
			setSelectOrder,
			setSelectCraft,
			setSelectProcessNo,
			selectPurOrderNo,
			setSelectSaleOrder,
			handleClick,
			selectSubject,
			tableSelectRef,
			setSelectUser,
			setSelectWorkgroup,
			handleVisibleChange,
			getPriceList,
			setPrice
		};
	},
});
</script>
<style scoped lang="scss">
:deep(.el-select__wrapper){
  border-radius: 0;
}
:deep(.el-input__wrapper) {
	//box-shadow: 0 0 0 0 !important;
	//width:150px
  border-radius: 0
}

.input-tooltip {
	width: calc(100% + 22px);
	margin-left: -11px;
	padding: 0 10px;

	&:hover {
		background: #ecf5ff;
		color: #6eb5ff;
		cursor: pointer;
	}
}
.add-btn{
	width: 16px;
	height: 16px;
	vertical-align: -1.8px;
	font-size: 12px;
	margin: 0 3px;
	color: #409eff;
	background: #ffffff;
	&:hover{
		color: #ffffff;
		background: #409eff;
	}
}
.del-btn{
	width: 16px;
	height: 16px;
	vertical-align: -1.8px;
	font-size: 12px;
	margin: 0 3px;
	color: #f56b6b;
	background: #ffffff;
	&:hover{
		color: #ffffff;
		background: #f56b6b;
	}
}
:deep(.el-scrollbar__view){
	height: 100%;
	.el-table__empty-block{
		height: 100%;
	}
}

</style>
