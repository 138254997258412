import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/data/classify/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	queryPageLast: {
		url: `${config.API_URL}/cfg/data/classify/queryPageLast`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	findAll: {
		url: `${config.API_URL}/cfg/data/classify/findAll`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	findAllClassify: {
		url: `${config.API_URL}/cfg/data/classify/findAllClassify`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getContactList: {
		url: `${config.API_URL}/cfg/data/classify/getContactList`,
		name: "获取联系人列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/data/classify/checkCode`,
		name: "编码唯一校验",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getAddressList: {
		url: `${config.API_URL}/cfg/data/classify/getAddressList`,
		name: "获取地址列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getShareUserList: {
		url: `${config.API_URL}/cfg/data/classify/getShareUserList`,
		name: "获取分享用户列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/cfg/data/classify/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/data/classify/del`,
		name: "查询",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/data/classify/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/data/classify/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/data/classify/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	updateFlag: {
		url: `${config.API_URL}/cfg/data/classify/updateFlag`,
		name: "修改状态",
		put: async function (data = {}) {
			return await http.put(this.url + "?id=" + data);
		}
	},

	updateFlag1: {
		url: `${config.API_URL}/cfg/data/classify/updateFlag1`,
		name: "修改状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlag0: {
		url: `${config.API_URL}/cfg/data/classify/updateFlag0`,
		name: "修改状态",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	getParent: {
		url: `${config.API_URL}/cfg/data/classify/getParent`,
		name: "查询父级类别",
		get: async function (data = {}) {
			return await http.get(this.url + "?parentId=" + data);
		}
	},

	getClassifyByCode: {
		url: `${config.API_URL}/cfg/data/classify/getClassifyByCode`,
		name: "查询",
		get: async function (params) {
			return await http.get(this.url + '?code=' + params);
		}
	},

	importExcel:{
		url: `${config.API_URL}/cfg/data/classify/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},
}
