import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	orderQueryBySupplierId: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/orderQueryBySupplierId`,
		name: "根据id查询",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},
	del: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	termination: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/termination`,
		name: "终止",
		get: async function(id,reason){
			return await http.get(this.url + '?id=' + id + '&reason=' + reason);
		}
	},

	updateProcessInfo: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	returnProcess: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?ids=' + id);
		}
	},


	/*-------------------------明细接口-------------------------*/
	getRefundGoodsList: {
		url: `${config.API_URL}/erp/purchase/purchaseRefundGoods/getList`,
		name: "根据采购退货id查询物品明细",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	editStatus: {
		url: `${config.API_URL}/erp/purchase/purchaseRefundGoods/editStatus`,
		name: "查询编辑状态",
		get: async function (refundId) {
			return await http.get(this.url + "?refundId=" + refundId);
		}
	},
	queryBySupplierId: {
		url: `${config.API_URL}/erp/purchase/purchaseRefundGoods/queryBySupplierId`,
		name: "根据id查询",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},
	queryDataBySupplierId: {
		url: `${config.API_URL}/erp/purchase/purchaseRefund/queryBySupplierId`,
		name: "根据id查询",
		get: async function (data = {}) {
			return await http.post(this.url,data);
		}
	},

}
