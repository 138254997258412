import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/hr/wage/payroll/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delete: {
		url: `${config.API_URL}/hr/wage/payroll/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/hr/wage/payroll/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/hr/wage/payroll/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	detail: {
		url: `${config.API_URL}/hr/wage/payroll/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

    submitProcess: {
        url: `${config.API_URL}/hr/wage/payroll/submitProcess`,
        name: "提交审批",
        get: async function(params){
            return await http.get(this.url + '?ids=' + params);
        }
    },
    updateProcessInfo: {
        url: `${config.API_URL}/hr/wage/payroll/updateProcessInfo`,
        name: "修改审批信息(审批状态或审批人)",
        put: async function(data={}){
            return await http.put(this.url,data);
        }
    },
    returnProcess: {
        url: `${config.API_URL}/hr/wage/payroll/returnProcess`,
        name: "反审批",
        get: async function(id){
            return await http.get(this.url + '?id=' + id);
        }
    },

    paySalaries: {
        url: `${config.API_URL}/hr/wage/payroll/paySalaries`,
        name: "发放工资",
        get: async function (params) {
            return await http.get(this.url + '?ids=' + params);
        }
    },
}
