<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :height="'350px'"
    :bef-close="false"
  >
    <el-container>
      <el-main class="nopadding">
        <el-container
          direction="vertical"
          class="mainPanel"
        >
          <el-main class="nopadding bgwhite">
            <el-form
              ref="myForm"
              class="syForm fullWidth"
              :model="form"
			  :label-width="$CONFIG.LABEL_WIDTH"
              label-suffix=":"
              :size="$store.state.global.uiSize"
            >
              <el-row
                class="fullWidth"
				:gutter="$CONFIG.GUTTER"
              >
                <el-col :span="span">
                  <el-form-item
                    prop="orderNo"
                    label="订单编号"
                  >
                    <el-input
                      v-model="form.orderNo"
                      disabled
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="span">
                  <el-form-item
                    prop="fz_supplierName"
                    label="供应商"
                  >
                    <el-input
                      v-model="form.fz_supplierName"
                      disabled
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="span">
                  <el-form-item
                    prop="fz_salesmanName"
                    label="业务员"
                  >
                    <el-input
                      v-model="form.fz_salesmanName"
                      disabled
                    />
                  </el-form-item>
                </el-col>
<!--                <el-col :span="span">-->
<!--                  <el-form-item-->
<!--                    label="订单金额"-->
<!--                    prop="orderAmount"-->
<!--                  >-->
<!--                    <el-input-->
<!--                      v-model="form.orderAmount"-->
<!--                      clearable-->
<!--                      disabled-->
<!--                    />-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
                <el-col :span="span">
                  <el-form-item
                    label="实付金额"
                    prop="paymentAmount"
                  >
                    <el-input
                      v-model="form.paymentAmount"
                      clearable
                      disabled
                      maxlength="26"
                      @input="(val) => handleNumIpt(val, 'paymentAmount')"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="span">
                  <el-form-item
                    prop="paymentMethod"
                    label="付款方式"
                  >
                    <el-select
                      v-model="form.paymentMethod"
                      clearable
					  :disabled="disState"
                    >
                      <el-option
                        label="现金"
                        :value="1"
                      />
                      <el-option
                        label="转账"
                        :value="2"
                      />
                      <el-option
                        label="支票"
                        :value="3"
                      />
                      <el-option
                        label="其他"
                        :value="4"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="span"
                >
                  <el-form-item
                    prop="paymentAccount"
                    label="付款账户"
                  >
                    <Selects
                      v-model="form.paymentAccount"
					  :disabled="disState"
                      :api-obj="$API.account.getAllList"
                      @set-value="(val) => setValue(val, 'paymentAccount')"
                    />
                  </el-form-item>
                </el-col> <el-col :span="span">
				  <el-form-item
					  prop="auditStatus"
					  label="审核状态"
				  >
					  <el-input
						  v-model="form.auditStatus"
						  disabled
						  clearable
						  maxlength="26"
					  />
				  </el-form-item>
			  </el-col>
				  <el-col :span="span">
					  <el-form-item
						  prop="paymentStatus"
						  label="付款状态"
					  >
						  <el-input
							  v-model="form.paymentStatus"
							  disabled
							  clearable
							  maxlength="26"
						  />
					  </el-form-item>
				  </el-col>
              </el-row>
            </el-form>
            <div class="mt_10">
              <SyEditTable
                ref="refSonDetail"
                :config="configSonDetail"
				:hideOprate="hideOprate"
                @pop-select-detail="popSelectDetail"
                @blur-edit="blurEdit"
				@select-purorder="selectDict"
              />
            </div>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
    <template #footer>
      <div class="text-center">
		  <el-popconfirm
			  :visible="visiblePop"
			  :title="$TIPS.SAVE_SUBMIT"
			  confirm-button-text="确定"
			  cancel-button-text="取消"
			  @cancel="visiblePop = false"
			  @confirm="handleConfirm(true)"
		  >
			  <template #reference>
				  <el-button
					  :loading="isLoading"
					  type="primary"
					  :disabled="disState"
					  :size="$store.state.global.uiSize"
					  @click="valiForms"
				  >
					  提交审批
				  </el-button>
			  </template>
		  </el-popconfirm>
		  <el-popconfirm
			  :visible="visibles"
			  :title="$TIPS.SUBMIT"
			  confirm-button-text="确定"
			  cancel-button-text="取消"
			  @cancel="visibles = false"
			  @confirm="handleConfirm(false)"
		  >
			  <template #reference>

				  <el-button
					  :loading="isLoading"
					  :size="$store.state.global.uiSize"
					  type="primary"
					  plain
					  :disabled="disState"
					  @click="valiForm"
				  >
					  保存草稿
				  </el-button>
			  </template>
		  </el-popconfirm>
        <span class="marl100" />
      </div>
    </template>
    <SelectDict
      ref="refSelectDict"
      :title="'选择费用名称'"
      multiple
      @set-data="setSelectGoods"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, nextTick,
} from 'vue'
	import {handleCutZero, resetNum} from "@/utils/validate";
	export default defineComponent({
		name: 'SaleOrderCostRelation',
		setup(props, {emit}) {
			const {proxy} = getCurrentInstance()
			const state = reactive({
				myForm: null,
				isLoading: false,
				form: {},
				span: 12,
				//弹窗显示属性
				visible: false,
				visibles: false,
				visiblePop:false,
				//弹窗标题
				title: '费用关联',
				disState:false,
				hideOprate:false,

				//弹窗宽度
				width: '70%',
				//按钮数据
				buttons: [],
				//是否显示查询表单隐藏项
				showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
				//表格组件
				table: null,
				//已选的数据集合
				selections: [],
        refSonDetail: null,
				//表格列配置
				tableConfig: {
					//数据表格数据源对象
					apiObj: null,
					//数据表格列配置
					columns: [
						{
							label: '类型',
							prop: 'fz_purType',
							width: 120,
							sortable: true,
							fixed: 'left',
						},
						{
							label: '开单日期',
							prop: 'addtime',
							width: 130,
							sortable: true,
						},
						{
							label: '单号',
							prop: 'orderNo',
							width: 160,
							sortable: true,
						},
						{
							label: '供应商名称',
							prop: 'fz_supplierName',
							width: 200,
							sortable: true,
						},
						{
							label: '数量',
							prop: 'quantity',
							width: 110,
							sortable: true,
						},
						{
							label: '计量单位',
							prop: 'unit',
							width: 120,
							sortable: true,
						},
						{
							label: '未税单价',
							prop: 'price',
							width: 110,
							sortable: true,
						},
						{
							label: '含税单价',
							prop: 'taxPrice',
							width: 110,
							sortable: true,
						},
						{
							label: '折扣(%)',
							prop: 'fz_discount',
							width: 120,
							sortable: true,
						},
						{
							label: '货款',
							prop: 'goodsAmount',
							width: 110,
							sortable: true,
						},
						{
							label: '税额',
							prop: 'fz_taxRateAmount',
							width: 110,
							sortable: true,
						},
						{
							label: '价税合计',
							prop: 'fz_subtotal',
							width: 120,
							sortable: true,
						},
					],
				},
				params: {},
				configSonDetail: {
					defaultCol: 'stype',
					initRows: 5,
					showSummary: true,
					//合计行统计的字段
					summary: ['amount'],
					columns: [
						{
							label: '费用项目',
							prop: 'stype',
							width: 120,
							max:20,
							edit: 'text',
						},
						{
							label: '费用金额',
							prop: 'amount',
							width: 120,
							edit: 'number',
						},
						{
							label: '占比(%)',
							prop: 'proportion',
							width: 100,
							disable: true,
						},
						{
							label: '备注',
							prop: 'illustrate',
							edit: 'text',
							width: 180,
							max: 255,
						},
					],
					//空白行数据
					blankItem: {
						stype: '',
						proportion: '',
						amount: '',
						illustrate: '',
					},
				},
			})

			const showDialog = async (row) => {
				state.visible = true
				state.params = {}
				if(row && JSON.stringify(row) != '{}'){
					state.form.orderNo = row.orderNo
					state.form.fz_supplierName = row.fz_supplierName
					state.form.fz_salesmanName = row.fz_salesmanName
					state.form.paymentMethod = row.freightPayment
					state.form.paymentAccountId = row.freightAccountId
					state.form.orderAmount = row.totalAmount
					state.form.paymentAmount = 0
					state.form.objectId = row.id
					state.disState = row.buyRelationAuditStatus == 5 || row.buyRelationAuditStatus == 3

					if(state.disState){
						state.hideOprate = true
						state.configSonDetail.defaultCol = 'disabled'
					}else {
						state.hideOprate = false
						state.configSonDetail.defaultCol = 'stype'
					}
					state.form.paymentStatus = row.fz_paymentStatus
					state.form.auditStatus = row.fz_auditStatus
					state.form.businessType = proxy.$MENU.code.PURCHASE_ORDER
          const res = await proxy.$API.buyRelationDetail.getDetailByPurId.get({
            objectId: state.form.objectId,
            pageNum: 1,
            pageSize: 100
          })
          if(res.code === 200 && res.data.list){
            state.refSonDetail.list = res.data.list
            state.refSonDetail.initList(true)
			  countCostRate()
          }
				}
			}

			const hideDialog = () => {
				state.visible = false
			}

			//只能输入数字（负数、小数）
			const handleNumIpt = (val, field) => {
				if (val) {
					state.form[field] = resetNum(val)
				}
			}

			//查询数据
			const fetchData = (data) => {
				state.excelCondition = data;
				if(!data){
					data = {}
				}
				Object.assign(data, state.params)
				state['table'].reload(data)
			}

			//表格中的选择
			const popSelectDetail = (item) => {
				//选择销售单
				if(item.prop === 'stype'){
					proxy.$refs.refSelectDict.showDialog({
						typeCode: 'fms_buy_relation-stype',
						typeName: '费用名称',
					})
				}
			}

			const setSelectGoods = (data) => {
				proxy.$refs['refSonDetail'].listLoading = true
				//批量设置选择后的数据
				if(data && data.length > 0){
					const sonTable = proxy.$refs.refSonDetail
					let rowIndex = sonTable.rowIndex
					data.forEach((item) => {
						let obj = {}

						//去重
						const hasObj = sonTable.list.find((son) => {
							return son.stype === item.optionValue
						})

						if(hasObj){
							return false
						}

						obj['stype'] = item.optionValue
						obj['amount'] = 1

						setRowData(obj, rowIndex)
						rowIndex ++
					})
					countCostRate()
				}
				proxy.$refs['refSonDetail'].listLoading = false

			}

			const blurEdit = (data) => {
				const item = data.item
				if(item.prop === 'amount'){
					if(!data.row.amount || data.row.amount == 0){
						data.row.amount = 1
						proxy.$baseMessage('费用金额不能为0！','warning')
					}
					countCostRate()
				}
			}

			//设置行数据
			const setRowData = (row, index) => {
				const sonTable = proxy.$refs.refSonDetail
				sonTable.list[index] = Object.assign({}, row)
			}

			//计算费用占比
			const countCostRate = () => {
				const sonTable = proxy.$refs.refSonDetail
				const sonList = sonTable.list
				const costList = sonList.filter((item) => {
					return item.stype && item.stype !== ''
				})
				let total = 0
				if(costList && costList.length > 0){
					//计算总金额
					costList.forEach((item) => {
						const amount = item.amount - 0 || 0
						total += amount
					})
					state.form.paymentAmount = total
					// state.form.paymentAmount = (state.form.orderAmount - 0 || 0) +  total
					//计算占比
					costList.forEach((item) => {
						const amount = item.amount - 0 || 0
						const rate = (amount / total) * 100
						item.proportion = handleCutZero(rate.toFixed(2))
					})
				}
			}

			const setValue = (val, field) => {
				if (val){
					state.form[field + 'Id'] = val.id
				}else {
					state.form[field + 'Id'] = null
				}
			}

			const submitSave = (boolean) => {
				nextTick(() => {//根据ref获取对应子表的有效数据
					const sonDetail = proxy.$refs.refSonDetail
					state['myForm'].validate(async (valid) => {
						if (valid) {
							const sonList =  sonDetail.list.filter((item) => {
								//返回id不为空的数据
								return item.stype && item.stype != ''
							})
							if (sonList && sonList.length > 0) {
								//判断空值
								let checkMassage = ''
								const isChecked = sonList.some((item) => {
									const index = sonDetail.list.indexOf(item)
									if (!item.amount || item.amount == '') {
										checkMassage += '第' + (index + 1) + '行,费用金额不能为空'
										return !item.amount || item.amount == ''
									}
								})
								//如果有空值
								if (isChecked) {
									proxy.$baseMessage(checkMassage, 'error')
									state.visibles = false
									return false
								}
							}else{
								proxy.$baseMessage("请补充费用明细", 'error')
								state.visibles = false
								return false
							}
							//将取得的子表数据赋给form，以便传到后台
							state.form['sonList'] = sonList || []
							let res = ''
							//提示语句
							let tips = "";
							if (boolean) {
								tips = proxy.$TIPS.SAVE_SUBMIT;
							} else {
								tips = proxy.$TIPS.SUBMIT;
							}
							// proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, async () => {
								state.isLoading = true
								if (state.form.id) { //修改
									res = await proxy.$API.buyRelation.update.put(state.form)
								} else { //新增
									res = await proxy.$API.buyRelation.insert.post(state.form)
								}
								if (res.code === 200) {
									if (boolean) {
										let ids = [];
										ids.push(res.data);
										const saveRes = await proxy.$API.buyRelation.submitProcess.get(ids);
										if (saveRes.code === 200 && !state.from) {
											proxy.$baseMessage("保存成功并且已提交审批", "success");
										} else {
											proxy.$baseMessage("保存数据成功但提交审批失败 错误为：" + saveRes.msg, "warning");
										}
									} else if (!state.from) {
										proxy.$baseMessage(res.msg, "success");
									}
									hideDialog()
								} else {
									proxy.$baseMessage(res.msg, 'error')
								}
								state.isLoading = false
							emit("fetch-data");
							// })
						}
					})
				})
			}
			const selectDict=(data)=>{
				setSelectGoods([data])
			}
			const valiForm = () => {
				nextTick(()=>{
					const sonDetail = proxy.$refs.refSonDetail;
					const sonList = sonDetail.list.filter((item) => {
						//返回id不为空的数据
						return item.stype && item.stype != ''
					})
					if (sonList && sonList.length > 0) {
						//判断空值
						let checkMassage = ''
						const isChecked = sonList.some((item) => {
							const index = sonDetail.list.indexOf(item)
							if (!item.amount || item.amount == '') {
								checkMassage += '第' + (index + 1) + '行,费用金额不能为空'
								return !item.amount || item.amount == ''
							}
						})
						//如果有空值
						if (isChecked) {
							proxy.$baseMessage(checkMassage, 'error')
							state.visibles = false
							return false
						}
					} else {
						proxy.$baseMessage("请补充费用明细", 'error')
						state.visibles = false
						return false
					}
					state.myForm.validate((valid)=>{
						if(valid){
							state.visibles = true
						}else {
							state.visibles = false
						}
					})
				})
			}
			const valiForms = () => {
				nextTick(()=>{
					state.myForm.validate((valid)=>{
						if(valid){
							const sonDetail = proxy.$refs.refSonDetail;
							const sonList = sonDetail.list.filter((item) => {
								//返回id不为空的数据
								return item.stype && item.stype != ''
							})
							if (sonList && sonList.length > 0) {
								//判断空值
								let checkMassage = ''
								const isChecked = sonList.some((item) => {
									const index = sonDetail.list.indexOf(item)
									if (!item.amount || item.amount == '') {
										checkMassage += '第' + (index + 1) + '行,费用金额不能为空'
										return !item.amount || item.amount == ''
									}
								})
								//如果有空值
								if (isChecked) {
									proxy.$baseMessage(checkMassage, 'error')
									state.visiblePop = false
									return false
								}
							} else {
								proxy.$baseMessage("请补充费用明细", 'error')
								state.visiblePop = false
								return false
							}
							state.visibles = false
							state.visiblePop = true
						}else {
							state.visiblePop = false
							state.visibles = true
						}
					})
				})
			}
			const handleConfirm = (boolean) => {
				submitSave(boolean)
				state.visiblePop = false
				state.visibles = false
			}
			return {
				...toRefs(state),
				hideDialog,
				submitSave,
				setValue,
				valiForms,
				popSelectDetail,
				blurEdit,
				setSelectGoods,
				handleNumIpt,
				showDialog,
				fetchData,
				selectDict,
				valiForm,
				handleConfirm,
			}
		}
		,
	})
</script>

