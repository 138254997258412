import config from "@/config"
import http from "@/utils/request"

export default {
	getRemindList: {
		url: `${config.API_URL}/cfg/business/remind/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	delRemind: {
		url: `${config.API_URL}/cfg/business/remind/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailRemind: {
		url: `${config.API_URL}/cfg/business/remind/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?menuCode=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/business/remind/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	insertRemind: {
		url: `${config.API_URL}/cfg/business/remind/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateRemind: {
		url: `${config.API_URL}/cfg/business/remind/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagRemind0: {
		url: `${config.API_URL}/cfg/business/remind/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagRemind1: {
		url: `${config.API_URL}/cfg/business/remind/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
}
