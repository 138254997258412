<!--选择商品弹窗组件-->
<template>
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="dftitle"
      :loading="loading"
      :width="width"
      :trigger-dom="triggerDom"
      :bef-close="true"
      :height="'500px'"
      custom-class="popTable"
      @reset="hideDialog"
      type="detail"
  >
    <el-container class="tabMain">
      <el-main class="nopadding">
        <splitpanes @resized="handleResized">
          <pane
              v-show="leftShow"
              min-size="20"
              max-size="30"
              :size="leftShow?paneSize:0"
          >
            <!--树结构组件 :params="{ businessType: 'cfg_OA_ARCHIVE'}" 查询参数 -->
            <SyGoodsDir
                :api-obj="$API.classify.getList"
                hide-pagination
                :is-edit="true"
                add-top
                :params="{flag:1}"
                @fetch-data-node="fetchDataNode"
            />
          </pane>
          <pane :size="multiple==false&&leftShow==false?100:multiple==false||leftShow==false?80:60">
            <!--公用切换按钮，点击切换显示左侧栏
			text:显示文字
			opened:绑定左侧栏显示状态
			show-left:回调函数
			-->
            <sy-switch-btn
                :text="'分类'"
                :opened="leftShow"
                :menu="$MENU.code.DATA_CLASSIFY"
                @show-left="handleShowLeft"
            />
            <el-container
                class="mainPanel sw"
                :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                  v-show="!hideForm"
                  ref="refFormBar"
                  :configs="searchConfig"
                  :params="params"
                  :show-item="showItem"
                  @fetch-data="fetchData"
                  @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-button
                      v-if="showAdd"
                      ref="refEditBtn"
                      type="primary"
                      :size="$store.state.global.uiSize"
                      @click="handleEdit(1, 'refEditBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                      v-if="showEdit"
                      ref="refEditBtn"
                      :type="'primary'"
                      :size="$store.state.global.uiSize"
                      :disabled="isDisabled"
                      @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>

                  <!--<el-link
                    v-if="multiple"
                    type="primary"
                    :disabled="selections.length === 0"
                    @click="addToRight"
                  >
                    添加选择
                    <el-icon>
                      <el-icon-right />
                    </el-icon>
                  </el-link>-->
                </div>
                <div class="right-panel" style="display:flex;align-items:center;">
                  <!-- 物品类型选择 -->
                  <label :style="{ fontSize: getSizeValue($store.state.global.uiSize) }">物品类型：</label>
                  <el-checkbox-group v-model="checkList" :size="$store.state.global.uiSize" @change="checkChange"
                                     style="margin-right:10px">
                    <el-checkbox v-for="(value,key) in checkMap" :key="key" :name="key" :value="value">
                      {{ key }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                      :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                      placement="bottom"
                      :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown/>
                        </template>
                        <template v-else>
                          <el-icon-arrowUp/>
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                    ref="table"
                    :api-obj="tableConfig.apiObj"
                    :column="tableConfig.columns"
                    :table-name="$MENU.code.DATA_GOODS"
                    :params="tableConfig.params"
                    row-key="id"
                    border
                    :checkbox="multiple"
                    radio-check
                    highlight-current-row
                    remote-sort
                    :ref-query-form="refFormBar"
                    remote-filter
                    @data-change="handleDataChange"
                    @current-change="currentChange"
                    @select="handleSelect"
                    @select-all="handleSelectAll"
                    @selection-change="handleSelectChange"
                    @row-dblclick="dblClick"
                    @row-click="firClick"
                >

                  <template #stype="{ scope }">
                    <span v-if="scope.row.stype == 0">原材料</span>
                    <span v-if="scope.row.stype == 1">半成品</span>
                    <span v-if="scope.row.stype == 2">成品</span>
                  </template>
                  <template #batchManage="{ scope }">
                    <el-tag
                        v-if="scope.row.batchManage === 0"
                        type="danger"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      否
                    </el-tag>
                    <el-tag
                        v-if="scope.row.batchManage === 1"
                        type="success"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      是
                    </el-tag>
                  </template>

                  <template #serialNumber="{ scope }">
                    <el-tag
                        v-if="scope.row.serialNumber === 0"
                        type="danger"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      否
                    </el-tag>
                    <el-tag
                        v-if="scope.row.serialNumber === 1"
                        type="success"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      是
                    </el-tag>
                  </template>

                  <template #bastardSize="{ scope }">
                    <el-tag
                        v-if="scope.row.bastardSize === 0"
                        type="danger"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      否
                    </el-tag>
                    <el-tag
                        v-if="scope.row.bastardSize === 1"
                        type="success"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      是
                    </el-tag>
                  </template>

                  <template #goodsGroup="{ scope }">
                    <el-tag
                        v-if="scope.row.goodsGroup === 0"
                        type="danger"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      否
                    </el-tag>
                    <el-tag
                        v-if="scope.row.goodsGroup === 1"
                        type="success"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      是
                    </el-tag>
                  </template>
                  <template #stockIntroduction="{ scope }">
                    <span v-if="scope.row.stockIntroduction == 0">先进先出</span>
                    <span v-if="scope.row.stockIntroduction == 1">先到期先出</span>
                  </template>
                  <template #checkMethod="{ scope }">
                    <span v-if="scope.row.checkMethod == 0">未启用</span>
                    <span v-if="scope.row.checkMethod == 1">全检</span>
                    <span v-if="scope.row.checkMethod == 2">抽检</span>
                    <span v-if="scope.row.checkMethod == 3">免检</span>
                  </template>
                  <template #productType="{ scope }">
                    <span v-if="scope.row.productType === '1'">采购</span>
                    <span v-if="scope.row.productType === '2'">委外</span>
                    <span v-if="scope.row.productType === '3'">自制</span>
                  </template>
                  <!--					&lt;!&ndash;审核状态&ndash;&gt;-->
                  <!--					<template #auditStatus="{ scope }">-->
                  <!--						<el-tag-->
                  <!--							v-if="scope.row.auditStatus === 0"-->
                  <!--							type="info"-->
                  <!--							class="myTag"-->
                  <!--							:size="$store.state.global.uiSize"-->
                  <!--						>-->
                  <!--							未提交-->
                  <!--						</el-tag>-->
                  <!--						<el-tag-->
                  <!--							v-if="scope.row.auditStatus === 1"-->
                  <!--							type="warning"-->
                  <!--							class="myTag"-->
                  <!--							:size="$store.state.global.uiSize"-->
                  <!--						>-->
                  <!--							审核中-->
                  <!--						</el-tag>-->
                  <!--						<el-tag-->
                  <!--							v-if="scope.row.auditStatus === 2"-->
                  <!--							type="danger"-->
                  <!--							class="myTag"-->
                  <!--							:size="$store.state.global.uiSize"-->
                  <!--						>-->
                  <!--							已驳回-->
                  <!--						</el-tag>-->
                  <!--						<el-tag-->
                  <!--							v-if="scope.row.auditStatus === 3"-->
                  <!--							type="success"-->
                  <!--							class="myTag"-->
                  <!--							:size="$store.state.global.uiSize"-->
                  <!--						>-->
                  <!--							审核通过-->
                  <!--						</el-tag>-->
                  <!--					</template>-->

                  <template #name="{ scope }">
                    <el-link
                        type="primary"
                        @click="handleDetail(scope.row)"
                    >
                      {{ scope.row.name }}
                    </el-link>
                  </template>
                  <template #flag="{ scope }">
                    <el-tag
                        v-if="scope.row.flag === 1"
                        type="success"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      启用
                    </el-tag>
                    <el-tag
                        v-if="scope.row.flag === 0"
                        type="danger"
                        class="myTag"
                        :size="$store.state.global.uiSize"
                    >
                      停用
                    </el-tag>
                  </template>

                  <template #assembly="{ scope }">
                    <span>{{ scope.row.assembly == 1 ? "是" : "否" }}</span>
                  </template>

                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane v-if="multiple"
                min-size="20"
                max-size="30"
                :size="paneSize">
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                      type="primary"
                      :disabled="isDelSel"
                      @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                      v-if="selData.length > 0"
                      :title="'确定清空已选择的数据?'"
                      confirm-button-text="确定"
                      cancel-button-text="取消"
                      @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                    ref="tableSel"
                    :data="selData"
                    :column="selColumns"
                    row-key="id"
                    checkbox
                    border
                    hide-pagination
                    hide-do
                    @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
          :size="$store.state.global.uiSize"
          type="primary"
          :disabled="isConfirm"
          @click="handleConfirm"
      >
        确定
      </el-button>
      <!--      <el-button-->
      <!--        :size="$store.state.global.uiSize"-->
      <!--        @click="hideDialog"-->
      <!--      >-->
      <!--        关闭-->
      <!--      </el-button>-->
    </template>
    <!--新增弹窗-->
    <Edit
        ref="refEdit"
        @fetch-data="fetchData"
    />

    <!--	  <Edit22-->
    <!--		  ref="refEdit22"-->
    <!--		  @fetch-data="fetchData"-->
    <!--	  />-->

    <Detail ref="refDetail"/>
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick,
  watch
} from "vue";
import Edit from "./edit";  //新增组件
import Detail from "@/views/cfg/data/goods/detail";

export default defineComponent({
  name: "SelectGoods",
  components: {Edit, Detail},
  props: {
    //是否开启多选
    multiple: {type: Boolean, default: false},
    //默认开启全部选择
    choseAll: {type: Boolean, default: true},
    //传入标题
    title: {type: String, default: "物品选择"},
    //传入复选框状态
    defaultCheck: {type: Array, default: () => ["2"]}
  },
  emits: ["set-data"],
  setup(props, {emit}) {
    const {proxy} = getCurrentInstance();
    const state = reactive({
      refFormBar: null,
      checkList: ["2"],
      checkMap: {
        "原材料": "0",
        "半成品": "1",
        "成品": "2"
      },
      goodsEdition: "",
      goodsEdtitionList: [],
      leftParams: {
        pageNum: 1,
        pageSize: 100,
      },
      isDelSel: true,
      //新增按钮显示与否
      showAdd: false,
      //编辑按钮显示
      showEdit: false,
      hideForm: true,
      //是否显示查询表单隐藏项
      showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
      //控制子表的显隐
      leftShow: true,
      paneSize: 20,
      paneSize1: 25,
      switchText: "分类",
      queryForm: {},
      //弹窗标题
      dftitle: props.title,
      //打开弹窗触发元素
      triggerDom: {},
      //加载动画
      loading: true,
      //弹窗显示属性
      visible: false,
      //弹窗宽度
      width: "90%",
      //编辑按钮状态
      isDisabled: true,
      //确定按钮状态
      isConfirm: true,
      //主表选中数据
      selections: [],
      //已选数据中选中的数据
      selectionsTableSel: [],
      //已选数据记录
      selData: [],
      excelCondition: {},
      selColumns: [
        {
          label: "物品名称",
          prop: "name",
          sortable: "custom",
          width: 180,
        },
        {
          label: "物品编码",
          prop: "code",
          width: 120,
          sortable: "custom",
        },
        {
          label: "规格型号",
          prop: "spec",
          width: 120,
        },
      ],
      //表格
      table: null,
      //传入的赋值字段
      param: {},
      //选中后赋值的字段
      params: [],
      //数据表格配置项
      tableConfig: {
        //数据表格数据源对象
        apiObj: null,
        params: {
          stype: 2
        },
        //数据表格列配置
        columns: [
          {
            label: "物品名称",
            prop: "name",
            width: 180,
            sortable: "custom",
            fixed: "left",
          },
          {
            label: "物品编码",
            prop: "code",
            width: 120,
            sortable: "custom",
          },
          {
            label: "物品类型",
            prop: "stype",
            width: 120,
            sortable: "custom",
            filters: [
              {text: "原材料", value: "0"},
              {text: "半成品", value: "1"},
              {text: "成品", value: "2"},
            ]
          },
          {
            label: "规格型号",
            prop: "spec",
            width: 120,
          },
          // {
          // 	label: '材质',
          // 	prop: 'material',
          // 	width: 170,
          // },
          // {
          // 	label: '牌号',
          // 	prop: 'brandNumber',
          // 	width: 140,
          // },
          {
            label: "生产方式",
            prop: "productType",
            width: 120,
            sortable: "custom",
          },
          {
            label: "所属仓库",
            prop: "fz_depotName",
            width: 110,
          },
          {
            label: "货位",
            prop: "fz_freightName",
            sortable: "custom",
            width: 110,
          },
          {
            label: "主单位",
            prop: "mainUnit",
            width: 110,
          },
          {
            label: "辅助单位",
            prop: "assistUnit1",
            width: 110,
          },
          {
            label: "换算比率",
            prop: "assistRatio1",
            width: 110,
          },
          // {
          // 	label: '数量',
          // 	prop: 'quantity',
          // 	width: 120,
          // },

          // {
          // 	label: '长度/尺寸',
          // 	prop: 'size',
          // 	width: 150,
          // },
          //
          // {
          // 	label: '长度-紧固件',
          // 	prop: 'fasteners',
          // 	width: 150,
          // },
          {
            label: "检验方式",
            prop: "checkMethod",
            // sortable: 'custom',
            width: 120,
          },
          {
            label: "批次管理",
            prop: "batchManage",
            width: 120,
          },

          // {
          // 	label: '非标准尺寸',
          // 	prop: 'bastardSize',
          // 	width: 120,
          // 	disable: true,
          // },
          {
            label: "序列号管理",
            prop: "serialNumber",
            // sortable: 'custom',
            width: 120,
            disable: true,
          },
          {
            label: "出库顺序",
            prop: "stockIntroduction",
            // sortable: 'custom',
            width: 110,
            disable: true,
          },
          {
            label: "组合件",
            prop: "goodsGroup",
            width: 110,
            disable: true,
          },
          {
            label: "参考销售价",
            prop: "referencePrice",
            // sortable: 'custom',
            width: 110,
          },
          {
            label: "参考采购价",
            prop: "referenceCost",
            // sortable: 'custom',
            width: 110,
          },
          // {
          // 	label: '税率【%】',
          // 	prop: 'taxRate',
          // 	width: 120,
          // },
          {
            label: "库存上限",
            prop: "inventoryCap",
            width: 110,
          },
          {
            label: "库存下限",
            prop: "inventoryLower",
            width: 110,
          },
          // {
          // 	label: '组装拆卸',
          // 	prop: 'assembly',
          // 	width: 150,
          // 	sortable: 'custom',
          // 	filters: [
          // 		{text: '是', value: '1'},
          // 		{text: '否', value: '0'},
          // 	]
          // },

          {
            label: "启用状态",
            prop: "flag",
            width: 120,
            sortable: "custom",
            filters: [
              {text: "正常", value: "1"},
              {text: "停用", value: "0"},
            ]
          },

          // {
          // 	label: '自动停用日期',
          // 	prop: 'deactivationDate',
          // 	width: 200,
          // },
          // {
          // 	label: '描述',
          // 	prop: 'represent',
          // 	width: 120,
          // },
          {
            label: "用途",
            prop: "purpose",
            width: 180,
          },
          // {
          // 	label: '装箱规格',
          // 	prop: 'packing',
          // 	width: 120,
          // },
          // {
          // 	label: '存储条件',
          // 	prop: 'storage',
          // 	width: 120,
          // },
          {
            label: "备注",
            prop: "remark",
            width: 180,
          },
          // {
          // 	label: '操作时间',
          // 	prop: 'addTime',
          // 	width: 220,
          // },
        ],
      },
      //搜索表单配置
      searchConfig: {
        params: {},
        //表单标题宽度
        labelWidth: 90,
        //查询表单项配置
        // itemList: [
        // 	{
        // 		label: '物品名称',
        // 		span: 12,
        // 		prop: 'name',
        // 		type: 'input',
        // 		//最大字符数，可不设
        // 		max: 32,
        // 		//是否显示字符数 不添加该属性则不显示
        // 		showWordLimit: true,
        // 		//默认显示
        // 		default: true,
        // 	},
        // 	{
        // 		label: '物品编码',
        // 		span: 12,
        // 		prop: 'code',
        // 		type: 'input',
        // 		//默认显示
        // 		default: true,
        //     max:32
        // 	},
        // 	{
        // 		label: '规格型号',
        // 		span: 12,
        // 		prop: 'spec',
        // 		type: 'input',
        //     max:32
        // 		//默认显示
        // 	},
        // 	{
        // 		label: '物品类型',
        // 		span: 12,
        // 		prop: 'stype',
        // 		type: 'select',
        // 		//下拉选项
        // 		data: [
        // 			{text: '原材料', value: '0'},
        // 			{text: '半成品', value: '1'},
        // 			{text: '成品', value: '2'},
        // 		]
        // 	},
        // 	{
        // 		label: '物品类别',
        // 		span: 12,
        // 		prop: 'classifys',
        // 		type: 'tableSelect',
        // 		//下拉表格选择配置项
        // 		config: {
        // 			//数据接口对象 对象名称：getList
        // 			apiObj: proxy.$API.classify.getList,
        //       hidePagination: true,
        // 			props: {
        // 				//映射label字段，即显示的字段
        // 				label: 'name',
        // 				//映射value字段
        // 				value: 'id',
        // 				//映射搜索字段
        // 				keyword: 'name',
        // 			},
        // 			//表格显示的列配置
        // 			columns: [
        // 				{
        // 					label: '名称',
        // 					prop: 'name',
        // 					width: 200,
        // 				},
        // 				{
        // 					label: 'ID',
        // 					prop: 'id',
        // 				},
        // 			],
        // 			//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
        // 			assignFields: [
        // 				{
        // 					//对应表格列表数据字段
        // 					value: 'id',
        // 					//赋值给到的字段名称
        // 					field: 'classifyId',
        // 				},
        // 			],
        // 		},
        // 	},
        // 	{
        // 		label: '所属仓库',
        // 		span: 12,
        // 		prop: 'depots',
        // 		type: 'tableSelect',
        // 		//下拉表格选择配置项
        // 		config: {
        // 			//数据接口对象 对象名称：getList
        // 			apiObj: proxy.$API.depot.getDepotList,
        // 			props: {
        // 				//映射label字段，即显示的字段
        // 				label: 'name',
        // 				//映射value字段
        // 				value: 'id',
        // 				//映射搜索字段
        // 				keyword: 'name',
        // 			},
        // 			//表格显示的列配置
        // 			columns: [
        // 				{
        // 					label: '名称',
        // 					prop: 'name',
        // 					width: 200,
        // 				},
        // 				{
        // 					label: 'ID',
        // 					prop: 'id',
        // 				},
        // 			],
        // 			//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
        // 			assignFields: [
        // 				{
        // 					//对应表格列表数据字段
        // 					value: 'id',
        // 					//赋值给到的字段名称
        // 					field: 'depotId',
        // 				},
        // 			],
        // 		},
        // 	},
        // 	{
        // 		label: '主计量单位',
        // 		span: 12,
        // 		prop: 'mainUnit',
        // 		type: 'input',
        //     max:32
        // 		//默认显示
        // 	},
        // 	{
        // 		label: '税率',
        // 		span: 12,
        // 		type: 'rangeNum',
        // 		//开始结束字段
        // 		fields: [
        // 			{
        // 				prop: 'fz_taxRate1',
        // 				max: 100,
        // 			},
        // 			{
        // 				prop: 'fz_taxRate2',
        // 				max: 100,
        // 			}
        // 		],
        // 	},
        // 	{
        // 		label: '最小采购量',
        // 		span: 12,
        // 		type: 'rangeNum',
        // 		//开始结束字段
        // 		fields: [
        // 			{
        // 				prop: 'fz_purchaseQuantity1',
        // 				max: 100,
        // 			},
        // 			{
        // 				prop: 'fz_purchaseQuantity2',
        // 				max: 100,
        // 			}
        // 		],
        // 	},
        // 	// {
        // 	// 	label: '参考成本价',
        // 	// 	span: 12,
        // 	// 	type: 'rangeNum',
        // 	// 	//开始结束字段
        // 	// 	fields: [
        // 	// 		{
        // 	// 			prop: 'fz_referenceCost1',
        // 	// 			max: 100,
        // 	// 		},
        // 	// 		{
        // 	// 			prop: 'fz_referenceCost2',
        // 	// 			max: 100,
        // 	// 		}
        // 	// 	],
        // 	// },
        // 	// {
        // 	// 	label: '参考销售价',
        // 	// 	span: 12,
        // 	// 	type: 'rangeNum',
        // 	// 	//开始结束字段
        // 	// 	fields: [
        // 	// 		{
        // 	// 			prop: 'fz_referencePrice1',
        // 	// 			max: 100,
        // 	// 		},
        // 	// 		{
        // 	// 			prop: 'fz_referencePrice2',
        // 	// 			max: 100,
        // 	// 		}
        // 	// 	],
        // 	// },
        // 	{
        // 		label: '库存上限',
        // 		span: 12,
        // 		type: 'rangeNum',
        // 		//开始结束字段
        // 		fields: [
        // 			{
        // 				prop: 'fz_inventoryCap1',
        // 				max: 100,
        // 			},
        // 			{
        // 				prop: 'fz_inventoryCap2',
        // 				max: 100,
        // 			}
        // 		],
        // 	},
        // 	{
        // 		label: '库存下限',
        // 		span: 12,
        // 		type: 'rangeNum',
        // 		//开始结束字段
        // 		fields: [
        // 			{
        // 				prop: 'fz_inventoryLower1',
        // 				max: 100,
        // 			},
        // 			{
        // 				prop: 'fz_inventoryLower2',
        // 				max: 100,
        // 			}
        // 		],
        // 	},
        // 	{
        // 		label: '保质期',
        // 		span: 12,
        // 		type: 'rangeNum',
        // 		//开始结束字段
        // 		fields: [
        // 			{
        // 				prop: 'fz_guarantee1',
        // 				max: 100,
        // 			},
        // 			{
        // 				prop: 'fz_guarantee2',
        // 				max: 100,
        // 			}
        // 		],
        // 	},
        // 	{
        // 		label: '组装拆卸',
        // 		span: 12,
        // 		prop: 'assembly',
        // 		type: 'select',
        // 		//下拉选项
        // 		data: [
        // 			{value: '1', label: '是'},
        // 			{value: '0', label: '否'},
        // 		]
        // 	},
        // 	{
        // 		label: '启用状态',
        // 		span: 12,
        // 		prop: 'flag',
        // 		type: 'select',
        // 		//下拉选项
        // 		data: [
        // 			{value: '1', label: '正常'},
        // 			{value: '0', label: '停用'},
        // 		]
        // 	},
        // 	// {
        // 	// 	label: '自动停止日期',
        // 	// 	span: 12,
        // 	// 	type: 'dateRange',
        // 	// 	//开始结束日期字段
        // 	// 	fields: [
        // 	// 		{
        // 	// 			prop: 'fz_deactivationDate1',
        // 	// 		},
        // 	// 		{
        // 	// 			prop: 'fz_deactivationDate2',
        // 	// 		},
        // 	// 	],
        // 	// },
        // 	{
        // 		label: '描述',
        // 		span: 12,
        // 		prop: 'represent',
        // 		type: 'input',
        //     max:255
        // 	},
        // 	{
        // 		label: '用途',
        // 		span: 12,
        // 		prop: 'purpose',
        // 		type: 'input',
        //     max:255
        // 	},
        // 	// {
        // 	// 	label: '装箱规格',
        // 	// 	span: 12,
        // 	// 	prop: 'packing',
        // 	// 	type: 'input',
        // 	// },
        // 	// {
        // 	// 	label: '装箱条件',
        // 	// 	span: 12,
        // 	// 	prop: 'storage',
        // 	// 	type: 'input',
        // 	// },
        // 	{
        // 		label: '备注',
        // 		span: 12,
        // 		prop: 'remark',
        // 		type: 'input',
        //     max:255
        // 	},
        // 	{
        // 		label: '操作日期',
        // 		span: 12,
        // 		type: 'dateRange',
        // 		//开始结束日期字段
        // 		fields: [
        // 			{
        // 				prop: 'fz_addTimeSmall',
        // 			},
        // 			{
        // 				prop: 'fz_addTimeBig',
        // 			},
        // 		],
        // 	},
        // 	{
        // 		label: '操作用户',
        // 		span: 12,
        // 		prop: 'fz_operateName',
        //     max:32,
        // 		type: 'popUser',
        // 		short: 12,
        // 		fields: ['operateId', 'fz_operateName'],
        // 	},
        // ],

        itemList: [
          {
            label: "物品名称",
            prop: "name",
            type: "input",
            //最大字符数，可不设
            max: 100,
            //是否显示字符数 不添加该属性则不显示
            showWordLimit: true,
            //默认显示
            default: true,
          },
          {
            label: "物品编码",
            prop: "code",
            type: "input",
            //默认显示
            default: true,
          },
          {
            label: "物品类型",
            prop: "fz_stype",
            type: "select",
            default: true,
            //下拉选项
            data: [
              {value: "0", label: "原材料"},
              {value: "1", label: "半成品"},
              {value: "2", label: "成品"},

            ]
          },
          {
            label: "规格型号",
            prop: "spec",
            type: "input",
            //默认显示
            default: true,
          },
          {
            label: "生产方式",
            prop: "productType",
            type: "select",
            data: [
              {label: "采购", value: "1"},
              {label: "委外", value: "2"},
              {label: "自制", value: "3"},
            ]
          },
          // {
          // 	label: '物品类别',
          // 	prop: 'classifys',
          // 	type: 'tableSelect',
          // 	//下拉表格选择配置项
          // 	config: {
          // 		//数据接口对象 对象名称：getList
          // 		apiObj: proxy.$API.classify.getList,
          // 		props: {
          // 			//映射label字段，即显示的字段
          // 			label: 'name',
          // 			//映射value字段
          // 			value: 'id',
          // 			//映射搜索字段
          // 			keyword: 'name',
          // 		},
          // 		//表格显示的列配置
          // 		columns: [
          // 			{
          // 				label: '名称',
          // 				prop: 'name',
          // 				width: 200,
          // 			},
          // 			{
          // 				label: 'ID',
          // 				prop: 'id',
          // 			},
          // 		],
          // 		//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
          // 		assignFields: [
          // 			{
          // 				//对应表格列表数据字段
          // 				value: 'id',
          // 				//赋值给到的字段名称
          // 				field: 'classifyId',
          // 			},
          // 		],
          // 	},
          // },
          {
            label: "所属仓库",
            prop: "fz_depotName",
            type: "tableSelect",
            customValue: true,
            //下拉表格选择配置项
            config: {
              //数据接口对象 对象名称：getList
              apiObj: proxy.$API.depot.getDepotList,
              props: {
                //映射label字段，即显示的字段
                label: "name",
                //映射value字段
                value: "id",
                //映射搜索字段
                keyword: "name",
              },
              //表格显示的列配置
              columns: [
                {
                  label: "名称",
                  prop: "name",
                  width: 200,
                },
                {
                  label: "ID",
                  prop: "id",
                },
              ],
              //赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
              assignFields: [
                {
                  //对应表格列表数据字段
                  value: "id",
                  //赋值给到的字段名称
                  field: "depotId",
                },
              ],
            },
          },
          {
            label: "主单位",
            prop: "mainUnit",
            type: "input",
            //默认显示
          },
          // {
          // 	label: '税率',
          // 	type: 'rangeNum',
          // 	//开始结束字段
          // 	fields: [
          // 		{
          // 			prop: 'fz_taxRate1',
          // 			max: 100,
          // 		},
          // 		{
          // 			prop: 'fz_taxRate2',
          // 			max: 100,
          // 		}
          // 	],
          // },
          // {
          // 	label: '最小采购量',
          // 	type: 'rangeNum',
          // 	//开始结束字段
          // 	fields: [
          // 		{
          // 			prop: 'fz_purchaseQuantity1',
          // 			max: 100,
          // 		},
          // 		{
          // 			prop: 'fz_purchaseQuantity2',
          // 			max: 100,
          // 		}
          // 	],
          // },
          {
            label: "库存上限",
            type: "rangeNum",
            //开始结束字段
            fields: [
              {
                prop: "fz_inventoryCap1",
                max: 100,
              },
              {
                prop: "fz_inventoryCap2",
                max: 100,
              }
            ],
          },
          {
            label: "库存下限",
            type: "rangeNum",
            //开始结束字段
            fields: [
              {
                prop: "fz_inventoryLower1",
                max: 100,
              },
              {
                prop: "fz_inventoryLower2",
                max: 100,
              }
            ],
          },
          // {
          // 	label: '保质期',
          // 	type: 'rangeNum',
          // 	//开始结束字段
          // 	fields: [
          // 		{
          // 			prop: 'fz_guarantee1',
          // 			max: 100,
          // 		},
          // 		{
          // 			prop: 'fz_guarantee2',
          // 			max: 100,
          // 		}
          // 	],
          // },
          // {
          // 	label: '组装拆卸',
          // 	prop: 'assembly',
          // 	type: 'select',
          // 	//下拉选项
          // 	data: [
          // 		{value: '1', label: '是'},
          // 		{value: '0', label: '否'},
          // 	]
          // },

          {
            label: "启用状态",
            prop: "flag",
            type: "select",
            //下拉选项
            data: [
              {value: "1", label: "正常"},
              {value: "0", label: "停用"},
            ]
          },

          // {
          // 	label: '自动停止日期',
          // 	type: 'dateRange',
          // 	//开始结束日期字段
          // 	fields: [
          // 		{
          // 			prop: 'fz_deactivationDate1',
          // 		},
          // 		{
          // 			prop: 'fz_deactivationDate2',
          // 		},
          // 	],
          // },
          // {
          // 	label: '描述',
          // 	prop: 'represent',
          // 	type: 'input',
          // },
          {
            label: "用途",
            prop: "purpose",
            type: "input",
          },
          // {
          // 	label: '验收类别',
          // 	prop: 'examClass',
          // 	type: 'select',
          // 	//下拉选项
          // 	data: [
          // 		{value: '1', label: '原材料验收'},
          // 		{value: '2', label: '焊材验收'},
          // 		{value: '3', label: '外购验收'},
          // 	]
          // },

          {
            label: "检验方式",
            prop: "checkMethod",
            type: "select",
            //下拉选项
            data: [
              {value: "0", label: "未启用"},
              {value: "1", label: "全检"},
              {value: "2", label: "抽检"},
              {value: "3", label: "免检"},
            ]
          },
          {
            label: "批次管理",
            prop: "batchManage",
            type: "select",
            //下拉选项
            data: [
              {value: "0", label: "否"},
              {value: "1", label: "是"},
            ]
          },
          {
            label: "序列号管理",
            prop: "serialNumber",
            type: "select",
            //下拉选项
            data: [
              {value: 0, label: "否"},
              {value: 1, label: "是"},
            ]
          },
          {
            label: "出库顺序",
            prop: "stockIntroduction",
            type: "select",
            //下拉选项
            data: [
              {value: 0, label: "先进先出"},
              {value: 1, label: "先到期先出"},
            ]
          },

          // {
          // 	label: '非标准尺寸',
          // 	prop: 'bastardSize',
          // 	type: 'select',
          // 	//下拉选项
          // 	data: [
          // 		{value: '0', label: '否'},
          // 		{value: '1', label: '是'},
          //
          // 	]
          // },

          {
            label: "组合件",
            prop: "goodsGroup",
            type: "select",
            //下拉选项
            data: [
              {value: "0", label: "否"},
              {value: "1", label: "是"},

            ]
          },

          {
            label: "备注",
            prop: "remark",
            type: "input",
          },
          // {
          // 	label: '操作日期',
          // 	type: 'dateRange',
          // 	//开始结束日期字段
          // 	fields: [
          // 		{
          // 			prop: 'fz_addTimeSmall',
          // 		},
          // 		{
          // 			prop: 'fz_addTimeBig',
          // 		},
          // 	],
          // },
          // {
          // 	label: '操作用户',
          // 	prop: 'fz_operateName',
          // 	type: 'popUser',
          // 	// short: 12,
          // 	fields: ['operateId', 'fz_operateName'],
          //   max:32
          // },
        ],

      },
      data: [],
    });

    //显示弹窗
    const showDialog = async (data) => {
      // 查询启用状态为启用的数据
      state.tableConfig.params = {};
      state.visible = true;
      getShowAdd();
      getShowEdit();
      if (props.defaultCheck.length > 0) {
        state.checkList = props.defaultCheck;
        state.tableConfig.params["stype"] = props.defaultCheck.join(",");
        state.tableConfig.params["flag"] = 1;
      }

      state.tableConfig.apiObj = proxy.$API.goods.getList;
      // await checkChange()
      state.loading = false;
    };

    //初始选择数据
    /*const initChoose = async (data) => {
      if(data){
        for(const item of data){
          const obj = {}
          obj.id = item.goodsId
          obj.name = item.fz_goodsName
          state.selData.push(obj)
        }
      }
    }*/

    //关闭弹窗
    const hideDialog = () => {
      state.visible = false;
      initProps();
    };

    const initProps = () => {
      state.selections = [];
      state.selData = [];
      state.data = [];
    };

    //弹窗关闭回调
    const handleClosed = () => {
      initProps();
    };

    //查询新增权限
    const getShowAdd = async () => {
      const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_GOODS, "add");
      //有结果标识有权限
      if (res.data) {
        state.showAdd = true;
      } else {
        state.showAdd = false;
      }
    };

    //查询修改权限
    const getShowEdit = async () => {
      const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_GOODS, "edit");
      //有结果标识有权限
      if (res.data) {
        state.showEdit = true;
      } else {
        state.showEdit = false;
      }
    };

    //切换显示左侧边栏
    const handleShowLeft = (bool) => {
      state.leftShow = bool;
    };

    /*------------主表数据操作-----------------*/
    //选择项变化
    const handleSelectChange = (val) => {
      state.selections = val;
    };

    //全选
    const handleSelectAll = (selection) => {
      if (selection && selection.length > 0) {
        selection.forEach((item) => {
          setSelData(item);
        });
      } else {
        state.selData = [];
      }
    };

    //复选框选择
    const handleSelect = (selection, row) => {
      if (!row) {
        return;
      }
      const index = selection.indexOf(row);
      const index1 = state.selData.indexOf(row);
      if (index < 0) {
        state.selData.splice(index1, 1);
      } else {
        if (index1 < 0) {
          state.selData.push(row);
        }
        proxy.$refs.tableSel.toggleRowSelection(row, false);
      }
    };

    //添加选中数据到已选
    const addToRight = () => {
      //添加已选数据
      state.selections.forEach((item) => {
        setSelData(item);
      });
    };

    //选中行改变
    const currentChange = (row) => {
      state.curRow = row;
      if (state.curRow && JSON.stringify(state.curRow) != "{}") {
        state.isDisabled = false;
        if (!props.multiple) {
          state.isConfirm = false;
        }
      } else {
        state.isDisabled = true;
        if (!props.multiple) {
          state.isConfirm = true;
        }
      }
    };

    //确定按钮点击
    const handleConfirm = () => {
      let data = {};
      if (!props.multiple) { //多选
        data = state.curRow;
      } else {
        if (state.selData && state.selData.length > 0) {
          state.selections = state.selData;
        }
        data = state.selections;
      }
      //触发父级赋值方法
      emit("set-data", data);
      //隐藏弹窗
      hideDialog();

    };

    // //行双击
    // const dblClick = (row) => {
    // 	//添加已选数据
    // 	setSelData(row)
    // }

    //行双击
    const dblClick = (row) => {
      if (row && !props.multiple) {
        //传入选中行和赋值的字段
        emit("set-data", row);
        hideDialog();
      }
      if (props.multiple) {
        //添加已选数据
        setSelData(row);
      }
    };

    //行单击
    const firClick = (row) => {
      // //添加已选数据
      // setSelData(row)
      // if(row && !props.multiple){
      // 	//传入选中行和赋值的字段
      // 	emit('set-data', row)
      // 	hideDialog()
      // }
      // 多选
      if (props.multiple) {
        //添加已选数据
        setSelData(row);
      }
    };

    const fetchDataLeft = (data) => {
      if (data) {
        state.param = Object.assign({}, data);
      }
      if (state.curNode) {
        state.param["directory"] = state.curNode.coordinate;
        state.param["flag"] = 1;
      }
      state.excelCondition = data;
      state["table"].reload(state.param);
    };

    const fetchDataNode = (node) => {
      if (node) {
        state.curNode = Object.assign({}, node);
        fetchDataLeft();
      }
    };

    //分栏拖动结束
    const handleResized = (res) => {
      if (state.leftShow) {
        state.paneSize = res[0].size;
      }
    };

    //查询数据，刷新表格
    const fetchData = (query) => {
      //查询参数{ 字段名：值, 字段名：值, ... }
      query = {
        ...query,
        ...state.tableConfig.params
      };
      state.excelCondition = query;
      state["table"].reload(query);
    };

    //保存表单显示状态
    const handleFitShow = (bool) => {
      state.showItem = bool;
    };

    //切换显示搜索栏
    const handleShowSearch = () => {
      nextTick(() => {
        state.hideForm = !state.hideForm;
      });
    };

    /*--------------------已选数据操作---------------------------*/
    //已选数据组装
    const setSelData = (data) => {
      // const index = state.selData.indexOf(data)
      const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
      proxy.$refs.tableSel.toggleRowSelection(data, false);
      if (data && index === -1) {
        state.selData.push(data);

      } else {
        state.selData = state.selData.filter(item => item.id !== data.id);
      }
      if (state.selData.length > 0) {
        state.isConfirm = false;

      } else {
        state.selData.splice(index, 1);
      }
      showChooseData();

    };

    //清空已选数据
    const clearSelections = () => {
      state.selData = [];
      proxy.$refs.table.clearSelection();
    };

    //已选数据表选中的数据变化
    const tableSelselectChange = (val) => {
      state.selectionsTableSel = val;
      if (val.length > 0) {
        state.isDelSel = false;
      } else {
        state.isDelSel = true;
      }
    };

    //删除选中的已选数据
    const delSelData = () => {
      if (state.selectionsTableSel.length > 0) {
        state.selectionsTableSel.forEach((item) => {
          const index = state.selData.indexOf(item);
          const index1 = state.selections.indexOf(item);
          if (index > -1) {
            state.selData.splice(index, 1);
          }
          if (index1 > -1) {
            proxy.$refs.table.toggleRowSelection(item, false);
          }
        });
      }
    };

    //新增修改弹窗
    const handleEdit = (type, ref) => {
      let param = {};
      if (type === 2 && state.curRow && JSON.stringify(state.curRow) != "{}") { //编辑
        param = state.curRow;
      }
      proxy.$refs.refEdit.triggerDom = proxy.$refs[ref];
      proxy.$refs.refEdit.showDialog(param);
    };

    watch(
        () => state.selData,
        (val) => {
          if (val.length > 0) {
            state.isConfirm = false;
          } else {
            state.isConfirm = true;
          }
        },
        {deep: true}
    );

    const handleDetail = (row) => {
      proxy.$refs.refDetail.showDialog(row);
    };

    //回显选中状态
    const showChooseData = () => {
      nextTick(() => {
        const dataTable = proxy.$refs.table;
        const tableData = dataTable.tableData;
        tableData.forEach((item) => {
          dataTable.toggleRowSelection(item, false);
        });
        state.selData.forEach((item) => {
          const findItem = tableData.find((son) => {
            return son.id === item.id;
          });
          if (findItem) {
            dataTable.toggleRowSelection(findItem, true);
          }
        });
      });
    };

    //表格数据加载完成
    const handleDataChange = () => {
      showChooseData();
    };

    const checkChange = () => {
      if (state.checkList == "") {
        proxy.$refs.table.tableData = [];
        return;
      }
      let obj = {stype: ""};
      obj.stype = state.checkList.join(",");
      nextTick(() => {
        proxy.$refs.table.upData(obj);
      });
    };

    const getSizeValue = (size) => {
      if (size == "small") {
        return "12px";
      } else if (size == "default") {
        return "14px";
      } else if (size == "large") {
        return "20px";
      }
    };

    return {
      ...toRefs(state),
      getSizeValue,
      checkChange,
      handleDetail,
      tableSelselectChange,
      addToRight,
      delSelData,
      handleEdit,
      clearSelections,
      handleShowSearch,
      handleFitShow,
      fetchData,
      handleResized,
      currentChange,
      handleSelectAll,
      fetchDataNode,
      handleConfirm,
      handleShowLeft,
      showDialog,
      dblClick,
      firClick,
      hideDialog,
      fetchDataLeft,
      handleDataChange,
      handleSelect,
      handleSelectChange,
      handleClosed
    };
  },
});
</script>
