<!--选择客户弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'350px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <el-container
              class="mainPanel"
              :style="multiple && 'padding-right: 10px'"
            >
              <!--              <sy-search-Form-->
              <!--                v-show="!hideForm"-->
              <!--                ref="refFormBar"-->
              <!--                :configs="searchConfig"-->
              <!--                :show-item="showItem"-->
              <!--                @fetch-data="fetchData"-->
              <!--                @set-show="handleFitShow"-->
              <!--              />-->
              <!--              <el-header class="tableTopBar">-->
              <!--                <div class="left-panel">-->
              <!--                  <el-button-->
              <!--                    v-if="showAdd"-->
              <!--                    ref="refPopAddBtn"-->
              <!--                    type="primary"-->
              <!--                    :size="$store.state.global.uiSize"-->
              <!--                    @click="handleEdit(1, 'refPopAddBtn')"-->
              <!--                  >-->
              <!--                    新增-->
              <!--                  </el-button>-->
              <!--                  <el-link-->
              <!--                    v-if="showEdit"-->
              <!--                    ref="refEditBtn"-->
              <!--                    :type="'primary'"-->
              <!--                    :size="$store.state.global.uiSize"-->
              <!--                    :disabled="isDisabled"-->
              <!--                    @click="handleEdit(2, 'refEditBtn')"-->
              <!--                  >-->
              <!--                    编辑-->
              <!--                  </el-link>-->
              <!--                  <el-link-->
              <!--                    v-if="multiple"-->
              <!--                    type="primary"-->
              <!--                    :disabled="selections.length === 0"-->
              <!--                    @click="addToRight"-->
              <!--                  >-->
              <!--                    添加到已选<el-icon><el-icon-right /></el-icon>-->
              <!--                  </el-link>-->
              <!--                </div>-->
              <!--                <div class="right-panel">-->
              <!--                  &lt;!&ndash;切换显示搜索栏hide-after: 延迟隐藏&ndash;&gt;-->
              <!--                  <el-tooltip-->
              <!--                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"-->
              <!--                    placement="bottom"-->
              <!--                    :hide-after="0"-->
              <!--                  >-->
              <!--                    <el-link @click="handleShowSearch">-->
              <!--                      搜索-->
              <!--                      <el-icon>-->
              <!--                        <template v-if="hideForm">-->
              <!--                          <el-icon-arrowDown />-->
              <!--                        </template>-->
              <!--                        <template v-else>-->
              <!--                          <el-icon-arrowUp />-->
              <!--                        </template>-->
              <!--                      </el-icon>-->
              <!--                    </el-link>-->
              <!--                  </el-tooltip>-->
              <!--                </div>-->
              <!--              </el-header>-->
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  border
                  radio-check
                  highlight-current-row
                  :ref-query-form="refFormBar"
                  remote-sort
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
                  @current-change="currentChange"
                  @row-dblclick="dblClick"
                  @dataChange="dataChange"
                />
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
	onBeforeMount,
} from 'vue'
export default defineComponent ({
	name: "SelectWarehouseNum",
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择条形码' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			paneSize1: 25,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//显示编辑那妞
			showEdit: false,
			//隐藏搜索表单
			hideForm: false,
			//编辑按钮状态
			isDisabled: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '45%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '条形码',
					prop: 'warehouseNum',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {},
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.stockRecord.getList,
				params: {
					stypeAndWarehouseNum: 1
				},
				columns: [
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						width: 180,
						fixed: 'left',
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
					},
					{
						label: '仓库名称',
						prop: 'fz_depotName',
						width: 120,
					},
					{
						label: '条形码',
						prop: 'warehouseNum',
						width: 150,
					},
					{
						label: '剩余数量',
						prop: 'surNumber',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '操作时间',
					// 	prop: 'addTime',
					// 	width: 210,
					// 	sortable: 'custom',
					// },
				],
			},
		})

		//显示弹窗
		const showDialog = (param) => {
			state.visible = true
			state.loading = false
			if (param) {
				state.tableConfig.params = {
					...state.tableConfig.params,
					...param
				}
				state.params = Object.assign({}, param)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			//state.excelCondition = data
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		/*------------------------按钮操作start-------------------------------*/
		//弹窗新增编辑
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}
		/*------------------------按钮操作end-------------------------------*/

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		const dataChange = (data) => {
			// if (data.code === 200 && data.data.list.length === 0) {
			// 	proxy.$confirm('当前商品根据【引用标准】、【长度/尺寸】、【长度-紧固件】没有找到对应的库存记录', '提示', {
			// 		type: 'warning',
			// 		confirmButtonText: '确定',
			// 		confirmButtonClass: 'el-button--danger'
			// 	}).then(() => {
      //
			// 	}).catch(() => {
			// 	})
			// }
		}

		return{
			...toRefs(state),
			handleDetail,
			tableSelselectChange,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
			dataChange,
		}
	},
})
</script>
