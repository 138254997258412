
import config from "@/config"
import http from "@/utils/request"

export default {
	selectByUserId: {
		url: `${config.API_URL}/sys/system/format/selectByUserId`,
		name: "查询用户格式信息",
		get: async function() {
			return await http.get(this.url);
		}
	},
	insert: {
		url: `${config.API_URL}/sys/system/format/insert`,
		name: "更新用户格式信息",
		get: async function(data={}) {
			return await http.post(this.url,data);
		}
	},
}
