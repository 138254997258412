<!--
 * @Descripttion: 表格选择器组件
 * @version: 1.2
 * @Author: sakuya
 * @Date: 2021年6月10日10:04:07
 * @LastEditors: sakuya
 * @LastEditTime: 2022年2月28日09:39:03
-->

<template>
	<div class="table-select-main">
		<el-select
			ref="select"
			v-model="defaultValue"
			:clearable="clearable"
			:multiple="multiple"
			:sonMultiple="sonMultiple"
			filterable
			:placeholder="placeholder"
			:disabled="disabled"
			:suffix-icon="$slots.suffix && ''"
			:filter-method="filterMethod"
			:class="{ 'append-active': $slots.append }"
			:size="$store.state.global.uiSize"
			@remove-tag="removeTag"
			@visible-change="visibleChange"
			@clear="clear"
			:automatic-dropdown="automaticDropdown"
		>
			<template #empty>
				<div
					v-loading="loading"
					class="sc-table-select__table"
					:style="{width: fullWidth ? '100%' : tableWidth+'px',height: (selectType === 'tree')?(height+'px'):''}"
				>
					<div class="sc-table-select__header">
						<slot
							name="header"
							:form="formData"
							:submit="formSubmit"
						/>
					</div>
					<template v-if="selectType === 'tree'">
						<el-tree
							ref="refTree"
							node-key="id"
							:data="tableData"
							default-expand-all
							:current-node-key="curNode"
							:props="property"
							highlight-current
							:expand-on-click-node="false"
							style="color: #454545;height: 100%;overflow-y: auto;"
							@node-click="click"
						>
							<template #default="{data}">
								<slot name="treeRow" :row="data">
									{{ (data.number || "") + " " + data.name }}
								</slot>
							</template>
						</el-tree>
					</template>
					<template v-else>
						<el-table
							ref="table"
							:data="tableData"
							:height="height"
							:highlight-current-row="!multiple"
							:tree-props="{ children: 'children' }"
							default-expand-all
							:size="$store.state.global.uiSize"
							stripe
							border
							:row-key="rowKey"
							@row-click="click"
							@select="select"
							@select-all="selectAll"
						>
							<el-table-column
								v-if="multiple"
								type="selection"
								width="45"
							/>
							<el-table-column
								v-if="colIndex"
								type="index" align="center"
								width="60"
								label="序号"
							>
								<template #default="scope">
									<span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
								</template>
							</el-table-column>
							<slot/>
						</el-table>
						<div
							v-if="!hidePagination"
							class="sc-table-select__page"
						>
							<el-pagination
								v-model:currentPage="currentPage"
								ref="refPage"
								:size="$store.state.global.uiSize"
								background
								:teleported="true"
								layout="total, prev, pager, next, jumper"
								:total="total"
								:page-size="pageSize"
								@current-change="reload"
								@update:page-size="pageSizeChange"
							/>
						</div>
					</template>
				</div>
			</template>
		</el-select>
		<div class="append-icon">
			<slot name="append"/>
		</div>
		<div class="suffix-icon">
			<slot name="suffix"/>
		</div>
	</div>
</template>

<script>
import config from "@/config/tableSelect";

export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: null,
		//数据源对象
		apiObj: {
			type: Object, default: () => {
			}
		},
		//是否物品类别选择
		classify: {type: Boolean, default: false},
		//查询参数
		params: {
			type: Object, default: () => {
			}
		},
		//无值显示提示文字
		placeholder: {type: String, default: "请选择"},
		rowKey: {type: String, default: "id"},
		//是否可多选
		multiple: {type: Boolean, default: false},
		// 是否只能最后一层多选
		sonMultiple: {type: Boolean, default: false},
		//是否禁止使用
		disabled: {type: Boolean, default: false},
		//选择会计科目
		isFinance: {type: Boolean, default: false},
		//是否可清除
		clearable: {type: Boolean, default: true},
		//下拉显示的表格宽度
		tableWidth: {type: Number, default: 400},
		//显示方式
		mode: {type: String, default: "popover"},
		filterData: {
			type: Object, default: () => {
			}
		},
		//字段映射
		props: {
			type: Object, default: () => {
			}
		},
		//是否显示序号
		colIndex: {type: Boolean, default: true},
		//自适应宽度
		fullWidth: {type: Boolean, default: false},
		//表格高度
		height: {type: Number, default: 300},
		//隐藏分页
		hidePagination: {type: Boolean, default: false},
		// 自定义默认值
		customerValue: {type: Boolean, default: false},
		// 是否可输入
		inputable: {type: Boolean, default: false},
		//赋值字段
		selLabel: {type: String, default: "name"},
		//赋值字段
		isNull: {type: Boolean, default: false},
		//是否自动弹开下拉
		automaticDropdown: {type: Boolean, default: false},
		//下拉选择组件类型
		selectType: {type: String, default: "table"},
		//树状配置
		property: {
			type: Object, default: () => {
			}
		},
	},
	emits: ["update:modelValue", "change", "clear", "remove-tag", "handle-visible"],
	data() {
		return {
			loading: false,
			keyword: null,
			defaultValue: "",
			tableData: [],
			curNode: null,
			flatTableData: [],  //扁平树形数据
			pageSize: config.pageSize,
			total: 0,
			currentPage: 1,
			defaultProps: {
				label: config.props.label,
				value: config.props.value,
				page: config.request.page,
				pageSize: config.request.pageSize,
				keyword: config.request.keyword
			},
			formData: {},
		};
	},
	computed: {},
	watch: {
		modelValue: {
			handler(val) {
				if(this.customerValue){
					if(this.multiple){
						this.defaultValue = val || []
					}else {
						this.defaultValue = val || ''
					}
				}else if(val){
					this.defaultValue = val[this.selLabel]
				}else if(!val && this.isNull){
					if(this.multiple){
						this.defaultValue = []
					}else {
						this.defaultValue = null
					}
				}
				this.autoCurrentLabel();
			},
			deep: true
		}
	},
	mounted() {
		this.defaultProps = Object.assign(this.defaultProps, this.props);
		if (this.multiple) {
			this.defaultValue = this.modelValue || [];
		} else {
			if (this.customerValue) {
				this.defaultValue = this.modelValue || "";
			} else {
				if (typeof this.modelValue === "object") {
					this.defaultValue = this.modelValue[this.selLabel];
				}
			}
		}
		this.autoCurrentLabel();
	},
	methods: {
		//表格显示隐藏回调
		visibleChange(visible) {
			this.$emit("handle-visible", visible);
			if (visible) {
				this.currentPage = 1;
				this.keyword = "";
				this.formData = {};
				this.getData();
			} else {
				this.autoCurrentLabel();
			}
		},
		//获取表格数据
		async getData() {
			try {
				this.loading = true;
				const reqData = {
					[this.defaultProps.page]: this.currentPage,
					[this.defaultProps.pageSize]: this.pageSize,
					[this.defaultProps.keyword]: this.keyword
				};
				if (this.hidePagination) {
					delete reqData[this.defaultProps.page];
					delete reqData[this.defaultProps.pageSize];
				}
				Object.assign(reqData, this.params, this.formData);
				const res = await this.apiObj.get(reqData);
				if (this.hidePagination) {
					this.tableData = res.data;
				} else {
					if (res.data && res.data.list) {
						const parseData = config.parseData(res);
						this.tableData = parseData.rows;
						this.total = parseData.total;
					} else {
						this.tableData = res.data;
					}
					//过滤传入的字段数据
					if (this.filterData && Object.keys(this.filterData).length > 0) {
						Object.keys(this.filterData).forEach(key => {
							this.tableData.forEach((item, index) => {
								if (this.filterData[key] === item[key]) {
									this.tableData.splice(index, 1);
								}
							});
						});
					}
				}

				this.loading = false;
				//表格默认赋值
				await this.$nextTick(() => {
					if (this.multiple) {
						if (this.defaultValue) {
							this.defaultValue.forEach(row => {
								const setrow = this.findRowByKey(row[this.defaultProps.value]);
								if (setrow) {
									this.$refs.table.toggleRowSelection(setrow, true);
								}
							});
						}
					} else {
						if (this.defaultValue) {
							if (this.tableData && this.tableData.length > 0 && !this.customerValue) {
								const setrow = this.tableData.find((item) => {
									return item[this.defaultProps.value] === this.modelValue[this.defaultProps.value];
								});
								if (setrow) {
									this.$refs.table.setCurrentRow(setrow);
								}
							}
						}
					}
					// this.$refs.table.$el.querySelector('.el-table__body-wrapper').scrollTop = 0
				});
			} catch (e) {
				console.log(e);
			}

		},
		//插糟表单提交
		formSubmit() {
			this.currentPage = 1;
			this.keyword = null;
			this.getData();
		},
		//分页刷新表格
		reload() {
			this.getData();
		},
		//条数变化
		pageSizeChange(size) {
			this.pageSize = size;
			this.getData();
		},
		//自动模拟options赋值
		autoCurrentLabel() {
			this.$nextTick(() => {
				if (this.$refs.select) {
					if (this.multiple && this.$refs.select.selected) {
						this.$refs.select.selected.forEach(item => {
							item.currentLabel = item.value[this.defaultProps.label];
						});
					} else {
						if (this.defaultValue) {
							if (this.customerValue) {
								this.$refs.select.selectedLabel = this.defaultValue;
							} else {
								this.$refs.select.selectedLabel = this.defaultValue[this.defaultProps.label];
							}
						}
					}
				}
			});
		},
		//表格勾选事件
		select(rows, row) {
			const findRow = rows.find(item => item[this.defaultProps.value] === row[this.defaultProps.value]);
			const isSelect = rows.length && findRow;
			if (isSelect) {
				row["label"] = row[this.selLabel];
				this.defaultValue.push(row);
			} else {
				this.defaultValue.splice(this.defaultValue.findIndex(item => item[this.defaultProps.value] === row[this.defaultProps.value]), 1);
			}
			this.autoCurrentLabel();
			this.$emit("update:modelValue", this.defaultValue);
			this.$emit("change", this.defaultValue);
		},
		//表格全选事件
		selectAll(rows) {
			let isAllSelect = rows.length > 0;
			if (isAllSelect) {
				rows.forEach(row => {
					const isHas = this.defaultValue.find(item => item[this.defaultProps.value] === row[this.defaultProps.value]);
					if (!isHas) {
						row["label"] = row[this.selLabel];
						this.defaultValue.push(row);
					}
				});
			} else {
				this.tableData.forEach(row => {
					const isHas = this.defaultValue.find(item => item[this.defaultProps.value] === row[this.defaultProps.value]);
					if (isHas) {
						this.defaultValue.splice(this.defaultValue.findIndex(item => item[this.defaultProps.value] === row[this.defaultProps.value]), 1);
					}
				});
			}
			this.autoCurrentLabel();
			this.$emit("update:modelValue", this.defaultValue);
			this.$emit("change", this.defaultValue);
		},
		click(row) {
			if (this.multiple) {
				//处理多选点击行
				this.$refs.table.toggleRowSelection(row);
				const selections = this.$refs.table.getSelectionRows();
				this.select(selections, row);
			} else {
				// if (row.children !== null && this.classify && this.sonMultiple) {
				if (row.children && this.classify && this.sonMultiple) {
					this.$baseMessage("请选择最后一级", "warning");
					return;
				}
				if (this.customerValue) {
					this.defaultValue = row[this.selLabel];
					this.$refs.select.blur();
					this.autoCurrentLabel();
					this.$emit("update:modelValue", this.defaultValue);
					this.$emit("change", row);
				} else {
					this.defaultValue = row;
					// this.defaultValue['label'] = row[this.defaultProps.label]
					this.$refs.select.blur();
					this.autoCurrentLabel();
					this.$emit("update:modelValue", this.defaultValue);
					this.$emit("change", this.defaultValue);
				}
			}
		},
		//扁平化树形结构
		flatTree() {
			if (this.tableData.length > 0 && this.isFinance) {
				this.eachTableData(this.tableData);
			}
		},
		//递归遍历表格数据
		eachTableData(data) {
			data.forEach(item => {
				this.flatTableData.push(item);
				if (item.children) {
					this.eachTableData(item.children);
				}
			});
		},
		//根据选中的行找到所有父级
		findParent(row, parents) {
			if (this.flatTableData.length > 0) {
				const parent = this.flatTableData.find(item => {
					return item.id === row.parentId;
				});
				if (parent) {
					parents.push(parent);
					if (parent.parentId !== 0) {
						this.findParent(parent, parents);
					}
				}
			}
			return parents;
		},
		//tags删除后回调
		removeTag(tag) {
			const row = this.findRowByKey(tag[this.defaultProps.value]);
			if (row) {
				this.$refs.table.toggleRowSelection(row, false);
			}
			this.$emit("update:modelValue", this.defaultValue);
			this.$emit("remove-tag", tag);
		},
		//清空后的回调
		clear() {
			this.$emit("update:modelValue", this.defaultValue);
			this.$emit("clear");
		},
		// 关键值查询表格数据行
		findRowByKey(value) {
			let row = null;
			let found = false;
			const searchTree = (value, items) => {
				if (found) {
					return;
				}
				for (const item of items) {
					if (item[this.defaultProps.value] === value) {
						row = item;
						found = true;
						return false;
					} else if (item.children && item.children.length > 0) {
						searchTree(value, item.children);
					}
				}
			};
			searchTree(value, this.tableData);
			return row;
		},
		filterMethod(keyword) {
			// if(!keyword){
			// 	this.keyword = null;
			// 	return false;
			// }
			// 开启可输入，直接赋值
			if (this.inputable && keyword) {
				this.defaultValue = keyword;
				this.$emit("update:modelValue", this.defaultValue);
			}
			if (keyword) {
				this.keyword = keyword;
				this.getData();
			}
		},
		// 触发select隐藏
		blur() {
			this.$refs.select.blur();
		},
		// 触发select显示
		focus() {
			this.$refs.select.focus();
		},
		updateDefault(val) {
			this.defaultValue = val;
		}
	}
};
</script>

<style scoped lang="scss">
:deep(.el-select__wrapper) {
	border-radius: 4px 0 0 4px;
}

.sc-table-select__table {
	padding: 0 12px;
	text-align: left;
}

.sc-table-select__page {
	padding-top: 12px;
}

.table-select-main {
	display: flex;
	width: 100%;

	.append-icon:not(:empty) {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 3px;
		border: 1px solid #dcdfe6;
		border-left: none;
		border-radius: 0 4px 4px 0;
		background: #f5f7fa;
		color: #909399;
		cursor: pointer;
	}

	.suffix-icon:not(:empty) {
		position: absolute;
		right: 5%;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		color: #aaadb4;
	}
}

.append-active {
	:deep(.el-input__wrapper) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
</style>
