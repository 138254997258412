
import config from "@/config"
import http from "@/utils/request"

export default {
	sendCode: {
		url: `${config.API_URL}/sys/system/forgot/sendCode`,
		name: "发送手机验证码",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	save: {
		url: `${config.API_URL}/sys/system/forgot/save`,
		name: "提交",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
