import config from "@/config"
import http from "@/utils/request"

export default {
    insert: {
        url: `${config.API_URL}/erp/produce/workOrder/detail/insert`,
        name: "新增",
        post: async function(data={}){
            return await http.post(this.url,data);
        }
    },
    update: {
        url: `${config.API_URL}/erp/produce/workOrder/detail/update`,
        name: "修改",
        put: async function(data){
            return await http.put(this.url,data);
        }
    },
    getOtherProQty: {
        url: `${config.API_URL}/erp/produce/workOrder/detail/getOtherProQty`,
        name: "查询其他在加工数",
        get: async function(workOrderId,workOrderDetailId){
            return await http.get(this.url+'?workOrderId='+workOrderId+'&workOrderDetailId='+workOrderDetailId);
        }
    },
    getListTask: {
        url: `${config.API_URL}/erp/produce/workOrder/detail/getListTask`,
        name: "查询派工单子表",
        get: async function(data={}){
            return await http.post(this.url,data);
        }
    },

}
