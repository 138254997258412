<!--选择加工单弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		custom-class="popTable"
		:type="type"
		@close="hideDialog"
	>
		<el-container>
			<sy-search-Form
				v-show="!hideForm"
				ref="refFormBar"
				:configs="searchConfig"
				:show-item="showItem"
				@fetch-data="fetchSearchData"
				@set-show="handleFitShow"
			/>
			<el-header class="tableTopBar bdb">
				<div class="left-panel"></div>
				<div class="right-panel">
					<!--切换显示搜索栏hide-after: 延迟隐藏-->
					<el-tooltip
						:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
						placement="bottom"
						:hide-after="0"
					>
						<el-link
							v-auth="$MENU.code.ERP_STOCK_SALE_DELIVER+'::search'"
							@click="handleShowSearch"
						>
							搜索
							<el-icon>
								<template v-if="hideForm">
									<el-icon-arrowDown/>
								</template>
								<template v-else>
									<el-icon-arrowUp/>
								</template>
							</el-icon>
						</el-link>
					</el-tooltip>
				</div>
			</el-header>
			<el-header class="tableTopBar no_bdt no_bdb">
				<div class="flexgs1">
					<el-form
						ref="myForm"
						:model="form"
						:rules="rules"
						:size="$store.state.global.uiSize"
						label-suffix=":"
					>
						<div class="disbet">
							<el-form-item label="物品名称">
								<!--								<el-input-->
								<!--									v-model="form.fz_goodsName"-->
								<!--									disabled-->
								<!--								>-->
								<!--								</el-input>-->
								<el-link
									type="primary"
									@click="handleDetail(form,'refGoodsDetail','goodsId')"
								>
									{{ form.fz_goodsName }}
								</el-link>
							</el-form-item>
							<el-form-item label="出库数量">
								<!--								<el-input-->
								<!--									v-model="form.baseNum"-->
								<!--									disabled-->
								<!--								>-->
								<!--								</el-input>-->
								{{ form.baseNum }}
							</el-form-item>
							<el-form-item label="已选数量">
								{{ selections.length }}
							</el-form-item>
							<el-button
								:size="$store.state.global.uiSize"
								type="primary"
								plain
								:disabled="isTurnOut"
								@click="autoSelect"
							>
								自动选择
							</el-button>
						</div>
					</el-form>
				</div>
			</el-header>
			<el-main class="nopadding bgwhite" style="height: 300px;">
				<el-tabs v-model="activeName" type="border-card" @tab-change="handleTabClick">
					<el-tab-pane label="选择序列号" :name="1">
						<!--主数据表格-->
						<scTable
							ref="table"
							:api-obj="tableConfig.apiObj"
							:column="tableConfig.columns"
							:params="tableConfig.params"
							:pageSize="1000"
							row-key="id"
							:checkbox="!isTurnOut"
							border
							height="350px"
							highlight-current-row
							@selection-change="handleSelectionChange"
							@data-load="dataLoad"
							@data-change="dataChange"
						>
						</scTable>
					</el-tab-pane>
					<!--					<el-tab-pane :disabled="isTurnOut || isCheck" label="查询所有" :name="2">-->
					<!--						&lt;!&ndash;主数据表格&ndash;&gt;-->
					<!--						<scTable-->
					<!--							ref="table"-->
					<!--							:api-obj="tableConfig.apiObj"-->
					<!--							:column="tableConfig.columns"-->
					<!--							:params="tableConfig.allParams"-->
					<!--							row-key="id"-->
					<!--							:checkbox="!isTurnOut"-->
					<!--							border-->
					<!--							height="auto"-->
					<!--							highlight-current-row-->
					<!--							@selection-change="handleSelectionChange"-->
					<!--							@data-load="dataLoad"-->
					<!--							@data-change="dataChange"-->
					<!--						>-->
					<!--						</scTable>-->
					<!--					</el-tab-pane>-->
					<el-tab-pane label="录入序列号" :name="3">
						<el-container>
							<el-main class="nopadding bgwhite" style="height: 300px;">
								<SyEditTable
									ref="refSnDetail"
									:config="snConfig"
									highlight-current-row
									hide-oprate
									enter-next
									init-focus
									@load-more="loadMore"
								>
								</SyEditTable>
							</el-main>
						</el-container>
					</el-tab-pane>
				</el-tabs>
			</el-main>
		</el-container>
		<template #footer>
			<el-popconfirm
				title="是否清空序列号？"
				confirm-button-text="确定"
				cancel-button-text="取消"
				@confirm="snClear"
			>
				<template #reference>
					<el-button
						:size="$store.state.global.uiSize"
					>
						清空
					</el-button>
				</template>
			</el-popconfirm>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<!--      <el-button-->
			<!--          :size="$store.state.global.uiSize"-->
			<!--          @click="visible = false"-->
			<!--      >-->
			<!--        关闭-->
			<!--      </el-button>-->
		</template>
		<Detail ref="refDetail"/>
		<GoodsDetail ref="refGoodsDetail"/>
		<ProducePlanDetail ref="refProducePlanDetail"/>
		<ProduceProcessDetail ref="refProduceProcessDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from "vue";
import Detail from "@/views/erp/produce/process/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
import {setColSpan} from "@/utils/window";
import {getNowDateTime} from "@/utils/date";

export default defineComponent({
	name: "SnInput",
	components: {GoodsDetail, Detail, ProducePlanDetail, ProduceProcessDetail},
	props: {
		// //是否开启多选
		// multiple: { type: Boolean, default: false },
		// //默认开启全部选择
		// choseAll: { type: Boolean, default: true },
		//传入标题
		title: {type: String, default: "选择序列号"},
	},
	emits: ["set-data", "set-param"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			activeName: 1,
			query: "已筛选数据", //筛选条件
			//表单项占比
			span: setColSpan(),
			//表单验证
			rules: {
				// name: [{required: true, trigger: 'change', message: '必填'}],
				// number: [{required: true, trigger: 'change', message: '必填'}],
			},
			refSnDetail: null,
			cacheMoreData: [],
			snConfig: {
				defaultCol: "sn",
				initRows: 0,
				columns: [
					{
						label: "序列号",
						prop: "sn",
						width: 400,
						edit: "input",
						fixed: "left",
					},
				],
				//空白行数据
				blankItem: {
					sn: ""
				},
			},
			//批量录入的sn
			snList: [],
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			refFormBar: null,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 70,
				//查询表单项配置
				itemList: [
					{
						label: "序列号",
						prop: "sn",
						type: "input",
						default: true,
						max: 32
					},
					{
						label: "仓库",
						prop: "depot",
						type: "popDepot",
						//默认显示
						default: true,
					},
				],
			},
			//查询表单
			queryForm: {},
			refBatchEntry: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			type: "detail",
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: "60%",
			//确定按钮状态
			isConfirm: false,
			//主表选中数据
			selections: [],
			form: {},
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			isTurnOut: false, //是否转出库单
			isCheck: false, //是否验收单
			tableConfig: {
				apiObj: [],
				params: {},
				allParams: {},
				data: [],
				columns: [
					{
						label: "序列号",
						prop: "sn",
						width: 200,
						fixed: "left",
					},
					{
						label: "仓库名",
						prop: "fz_depotName",
						width: 120,
					},
					{
						label: "货位",
						prop: "fz_positionName",
						width: 120,
					},
					{
						label: "批号",
						prop: "batchNumber",
						width: 120
					},
					{
						label: "到期日期",
						prop: "becomeDueDate",
						width: 130
					}
				],
				//空白行数据
				blankItem: {
					sn: ""
				},
			},
			cacheOutputList: [],
			cacheData: null,
		});

		//显示弹窗
		const showDialog = (data, isTurnOut = false, isCheck = false) => {
			// state.activeName = 1
			state.visible = true;
			state.isTurnOut = isTurnOut;
			state.isCheck = isCheck;
			if (data) {
				data.baseNum = data.baseNum - 0;
				state.form = Object.assign({}, data);
				if (data.fz_depotName) {
					state.query = data.fz_depotName;
				}
				if (isTurnOut) {
					fetchOutputList(data);
				} else if (isCheck && data.fz_outputList) {
					state.selections = data.outputList;
					state.cacheOutputList = data.outputList;
					state.tableConfig.data = data.fz_outputList;
				} else {
					fetchSummarySn(data);
					setInitSn(data);
				}
			}
			autoFocus();
			state.loading = false;
		};

		// 录入栏写入回显数据
		const setInitSn = (data) => {
			state.cacheMoreData = Array(data.baseNum).fill().map(() => ({}));
			if (data.outputList) {
				data.outputList.forEach((item, index) => {
					state.cacheMoreData[index] = Object.assign({}, item);
				});
			}
			let num = data.baseNum < 100 ? data.baseNum : 100;
			state.snConfig.initRows = num;
			nextTick(() => {
				if (data.outputList) {
					state.refSnDetail.list = state.refSnDetail.list.map((item, index) => {
						return {...item, ...(data.outputList[index] || {})};
					});
				}
			});
		};
		const fetchSummarySn = (data) => {
			nextTick(async () => {
				state.table.loading = true;
				let param = Object.assign({}, data);
				state.cacheData = Object.assign({}, data);
				// param['pageSize'] = 100
				// param['pageNum'] = 1
				param["reType"] = 1;
				delete param.baseNum;
				delete param.businessNumber;
				state.tableConfig.params = Object.assign({}, param);
				//查询全部
				let allParams = {goodsId: state.form.goodsId, depotId: state.form.depotId};
				if (state.form.businessId) {
					allParams["businessId"] = state.form.businessId;
					allParams["inStock"] = 2;
				}
				allParams = Object.assign(allParams, state.refFormBar.queryForm);
				state.tableConfig.allParams = Object.assign({}, allParams);

				state.tableConfig.apiObj = proxy.$API.stockSummarySn.querySnList;
				// const res = await proxy.$API.stockSummarySn.querySnList.get(param)
				// if (res.code == 200 && res.data.list && res.data.list.length > 0) {
				// 	if(data.outputList && data.outputList.length > 0){
				// 		state.selections = data.outputList
				// 		// state.cacheOutputList = data.outputList
				// 	}
				// 	state.tableConfig.data = res.data.list
				// }else {
				// 	state.tableConfig.data = []
				// }
				// state.table.loading = false
			});
		};
		const fetchOutputList = (data) => {
			nextTick(async () => {
				state.table.loading = true;
				let param = {};
				if (state.activeName == 2) {
					param = {goodsId: state.form.goodsId};
				} else {
					param = Object.assign({}, data);
				}
				delete param.baseNum;
				param["pageSize"] = 100;
				param["pageNum"] = 1;
				param["reType"] = 1;
				delete param.baseNum;
				state.tableConfig.params = Object.assign({}, param);
				state.tableConfig.apiObj = proxy.$API.snOutput.getList;
				// const res = await proxy.$API.snOutput.getList.get(param)
				// 	if (res.code == 200 && res.data.list && res.data.list.length > 0) {
				// 		state.tableConfig.data = res.data.list
				// 		if(data.outputList && data.outputList.length > 0){
				// 			state.selections = data.outputList
				// 		}
				// 	}else {
				// 		state.tableConfig.data = []
				// 	}
				// 	state.table.loading = false
			});
		};
		const fetchData = (data) => {
			let param = Object.assign({}, state.form);
			if (data) {
				param = Object.assign({}, data);
				param["reType"] = 1;
			}
			// if(state.isTurnOut){
			// 	fetchOutputList(param)
			// }
			if (state.isCheck) {
				// 验收单中序列号查询
			} else {
				// fetchSummarySn(param)
				state.table.reload(param);
			}
		};
		const fetchSearchData = (data) => {
			if (data) {
				let param = {};
				if (state.activeName === 1) {
					param = Object.assign({}, state.form, data);
					delete param.businessNumber;
					//条件查询
					fetchData(param);
				} else if (state.activeName === 2) {
					//查询全部
					param = {goodsId: state.form.goodsId};
					if (state.form.businessId) {
						// param['businessNumber'] = state.form.businessNumber
						param["businessId"] = state.form.businessId;
						param["inStock"] = 2;
					}
					param = Object.assign(param, data);
					fetchData(param);
				}
			}
		};
		const dataChange = (res) => {
			if (res.code == 200 && res.data.list && res.data.list.length > 0) {
				if (state.cacheData.outputList && state.cacheData.outputList.length > 0) {
					state.selections = state.cacheData.outputList;
					// state.cacheOutputList = data.outputList
				}
				state.table.tableData = res.data.list;
			} else {
				state.table.tableData = [];
			}
			let cacheSel = state.selections;
			// state.table.clearSelection()
			if (state.selections.length < 1 && state.form.outputList && state.form.outputList.length > 0) {
				cacheSel = state.form.outputList;
			}
			let isSeled = false;
			cacheSel.forEach((row) => {

				const curRow = state.table.tableData.find(item => item.sn === row.sn);
				if (curRow) {
					state.table.toggleRowSelection(curRow, true);
					isSeled = true;
				}
			});
			state.table.loading = false;
		};
		const dataLoad = (data) => {
			let cacheSel = state.selections;
			state.table.clearSelection();
			if (state.selections.length < 1 && state.form.outputList && state.form.outputList.length > 0) {
				cacheSel = state.form.outputList;
			}
			let isSeled = false;
			cacheSel.forEach((row) => {

				const curRow = state.table.tableData.find(item => item.sn === row.sn);
				if (curRow) {
					state.table.toggleRowSelection(curRow, true);
					isSeled = true;
				}
			});
			// 当存在已选序列号，但当前表没有数据时，重新选择
			if (state.selections.length > 0 && !isSeled) {
				state.selections = [];
			}
		};
		//关闭弹窗
		const hideDialog = () => {
			state.visible = false;
			state.form = {};
			// state.activeName = 1
			state.selections = [];
			state.tableConfig.data = [];
			state.tableConfig.params = {};
			state.tableConfig.apiObj = null;
		};

		/*------------主表数据操作-----------------*/

		const handleTabClick = (tab) => {
			let param = {};
			const queryForm = state.refFormBar.queryForm || {};
			// if(tab === 1){
			// 	//条件查询
			// 	param = Object.assign({},state.form,queryForm)
			// 	delete param.businessNumber
			// 	fetchData(param)
			// }else if(tab === 2){
			// 	//查询全部
			// 	let param = {goodsId: state.form.goodsId,depotId: state.form.depotId}
			// 	if (state.form.businessId){
			// 		// param['businessNumber'] = state.form.businessNumber
			// 		param['businessId'] = state.form.businessId
			// 		param['inStock'] = 2
			// 	}
			// 	param = Object.assign(param,queryForm)
			// 	fetchData(param)
			// }
			state.activeName = tab;
			autoFocus();
		};

		const autoFocus = () => {
			// 自动聚焦第一行
			nextTick(() => {
				const emptyIndex = state.refSnDetail.list.findIndex(item => !item.sn);
				if (emptyIndex !== -1) {
					state.refSnDetail.clickTarget = "sn";
					state.refSnDetail.rowIndex = emptyIndex;
					setTimeout(() => {
						const findRef = state.refSnDetail.$refs["inputsn" + emptyIndex] || [];
						const inputSn = findRef[0];
						if (inputSn && typeof inputSn.focus === "function") {
							inputSn.focus();
						}
					}, 300);
				}
			});
		};
		// 复选框
		const handleSelectionChange = (val) => {
			// state.selections = []
			// val.forEach((sel)=>{
			// 	const findSel = state.selections.find(item=>item.sn == sel.sn)
			// 	if(!findSel){
			// 		state.selections.push(sel)
			// 	}
			// })
			console.log(val);
			state.selections = val;
		};

		//确定按钮点击
		const handleConfirm = async () => {
			console.log(state.selections);
			if (state.isTurnOut) {
				hideDialog();
			} else {
				let result = [];
				if (state.activeName != 3) {
					result = state.selections.reduce((acc, cur) => {
						const {depotId, batchNumber, positionId} = cur;
						const groupIndex = acc.findIndex(group => group[0].depotId === depotId && group[0].batchNumber === batchNumber && group[0].positionId === positionId);
						if (groupIndex === -1) {
							acc.push([cur]);
						} else {
							acc[groupIndex].push(cur);
						}
						return acc;
					}, []);
				} else {
					const sonList = state.refSnDetail.list.filter(item => !!item.sn);
					result = [sonList];
				}
				emit("set-data", result);
				emit("set-param", {data: result, type: state.activeName});
				hideDialog();
			}
		};
		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool;
		};

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm;
			});
		};

		/*--------------------主表事件start-----------------*/
		//主表行点击事件
		const handleRowClick = (row) => {
			if (state.leftShow) {
				// state['tableSon'].reload({submId: row.id})
			}
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};

		const autoSelect = () => {
			state.table.loading = true;
			setTimeout(() => {
				//自动勾选
				const selectedCount = state.selections.length;
				const toAdd = state.form.baseNum - selectedCount;

				if (toAdd > 0) {
					// 获取未勾选的数据
					const unselectedData = state.table.tableData.filter(item => !state.selections.includes(item));

					// 添加未勾选的数据到勾选项中
					const newItems = unselectedData.slice(0, toAdd);
					state.selections = [...state.selections, ...newItems];

					// 更新复选框的勾选状态
					state.selections.forEach((row) => {
						state.table.toggleRowSelection(row, true);
					});
				} else if (toAdd < 0) {
					state.selections.forEach((row, index) => {
						if (index >= state.form.baseNum - 0) {
							state.table.toggleRowSelection(row, false);
						}
					});
				}
				state.table.loading = false;
			}, 100);
		};
		const loadMore = () => {
			console.log("加载更多数据");
			if (state.refSnDetail.list && state.refSnDetail.list.length < state.form.baseNum - 0) {
				const difLength = state.form.baseNum - state.refSnDetail.list.length;
				const newLength = difLength > 100 ? 100 : difLength;
				const newList = state.cacheMoreData.slice(state.refSnDetail.list.length, state.refSnDetail.list.length + newLength);
				state.refSnDetail.list.push(...newList);
			}
		};
		const snClear = () => {
			state.table.clearSelection();
			state.selections = [];
			const length = state.form.baseNum > 100 ? 100 : state.form.baseNum;
			state.refSnDetail.list = Array(length).fill().map(() => ({sn: ""}));
			// state.refSnDetail.initList()
			state.snList = [];
			state.cacheMoreData = [];
			setTimeout(() => {
				autoFocus();
			}, 100);
		};
		return {
			...toRefs(state),
			loadMore,
			snClear,
			showDialog,
			fetchData,
			fetchSearchData,
			hideDialog,
			handleConfirm,
			handleFitShow,
			handleShowSearch,
			handleTabClick,
			handleRowClick,
			handleDetail,
			handleSelectionChange,
			autoSelect,
			dataLoad,
			dataChange
		};
	},
});
</script>
<style scoped lang="scss">
.left-form {
	height: 200px;
	max-width: 250px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
:deep(.el-tabs--border-card){
	.el-tabs__content{
		padding: 10px;
	}
}
.disbet{
	.el-form-item{
		margin-bottom: 0;
	}
}
</style>
