import config from "@/config"
import http from "@/utils/request"

export default {
	insertExam: {
		url: `${config.API_URL}/cfg/business/examineSet/update`,
		name: "审批规则修改",
		get: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	getExamList: {
		url: `${config.API_URL}/cfg/business/examineSet/getList`,
		name: "审批规则查询",
		get: async function(){
			return await http.get(this.url);
		}
	},
	getFlag: {
		url: `${config.API_URL}/cfg/business/examineSet/getFlag`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?menuCode=" + params);
		}
	},

}
