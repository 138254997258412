<template>
	<div ref="scDialog" class="sc-dialog">
		<el-dialog
			ref="dialog"
			v-model="dialogVisible"
			:fullscreen="isFullscreen"
			v-bind="$attrs"
			:show-close="false"
			destroy-on-close
			append-to-body
			draggable
      :close-on-press-escape="false"
			@open="handleOpen"
			:before-close="closeDialog"
		>
			<template #header>
				<slot name="header">
					<span class="el-dialog__title">{{ title }}</span>
				</slot>
				<div class="sc-dialog__headerbtn">
					<button
						v-if="showFullscreen"
						aria-label="fullscreen"
						type="button"
						:title="isFullscreen ? '退出全屏' : '全屏显示'"
						@click="setFullscreen"
					>
						<el-icon
							v-if="isFullscreen"
							class="el-dialog__close"
						>
							<el-icon-bottom-left/>
						</el-icon>
						<el-icon
							v-else
							class="el-dialog__close"
						>
							<el-icon-full-screen/>
						</el-icon>
					</button>
					<button
						v-if="showClose && type === 'detail'"
						aria-label="close"
						type="button"
						@click="closeDialog()"
						title="关闭"
					>
						<el-icon class="el-dialog__close">
							<el-icon-close/>
						</el-icon>
					</button>
					<el-popconfirm
						:title="$TIPS.CONFIRM_CLOSE"
						confirm-button-text="确定"
						cancel-button-text="取消"
						v-if="showClose && type != 'detail'"
						@confirm="handleConfirm()"
					>
						<template #reference>
							<button
								v-if="showClose"
								aria-label="close"
								type="button"
								title="关闭"
							>
								<el-icon class="el-dialog__close">
									<el-icon-close/>
								</el-icon>
							</button>
						</template>
					</el-popconfirm>
				</div>
			</template>
			<div
				ref="popContent"
				v-loading="loading"
				class="popContent"
				:style="{ height: isFullscreen? '100%' : height }"
			>
				<slot/>
			</div>
			<template v-if="footer" #footer>
				<slot name="footer"/>
			</template>
			<!--拖动元素-->
			<div
				ref="refDragSize"
				class="dragSize"
			/>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		//model控制显示隐藏
		modelValue: {type: Boolean, default: false},
		//标题
		title: {type: String, default: ""},
		type: {type: String, default: ""},
		//显示关闭按钮
		showClose: {type: Boolean, default: true},
		//显示底部
		footer: {type: Boolean, default: true},
		//显示全屏按钮
		showFullscreen: {type: Boolean, default: true},
		//加载动画
		loading: {type: Boolean, default: false},
		//是否校验关闭
		befClose: {type: Boolean, default: true},
		//触发元素位置信息
		triggerDom: {
			type: Object, default: () => {
			}
		},
		//触发元素位置信息
		react: {
			type: Object, default: () => {
			}
		},
		//显示位置
		placement: {type: String, default: "right"},
		//内容高度
		height: {type: String, default: ""},
		// 是否初始全屏
		initFull: {type: Boolean, default: false}
	},
	emits: ["reset",'closeDialog'],
	data() {
		return {
			styles: {},
			dialogVisible: false,
			isFullscreen: false,
			contHeight: this.height,
			minWidth: 400,
			minHeight: 100,
			cacheWh: {}
		};
	},
	//监听model值的变化
	watch: {
		modelValue() {
			this.dialogVisible = this.modelValue;
			if (this.dialogVisible) {
				if(this.initFull){
					setTimeout(()=>{
						this.setFullscreen()
					},100)
				}else {
					this.isFullscreen = false;
				}
			}else {
				if(this.initFull && this.isFullscreen){
					this.setFullscreen()
				}
			}
		}
	},
	//初始化完成
	mounted() {
		this.dialogVisible = this.modelValue;
	},
	methods: {
		//拖动弹窗大小
		dragSize() {
			const el = this.$refs.dialog.dialogContentRef.$el;
			if(el){
				this.$nextTick(() => {
					this.$refs.refDragSize.onmousedown = (e) => {
						const _that = this;
						// 记录初始x Y位置
						const clientX = e.clientX;
						const clientY = e.clientY;
						const dreact = el.getBoundingClientRect();
						document.onmousemove = function (e) {
							e.preventDefault(); // 移动时禁用默认事件
							//设置拖动后内容高度自适应
							//_that.$refs.popContent.style.height = "100%";
							// 计算拖动后的宽高
							const x = dreact.width + (e.clientX - clientX);
							const y = dreact.height + (e.clientY - clientY);
							//比较是否小于最小宽高
							el.style.width = x > _that.minWidth ? x + "px" : _that.minWidth + "px";
							el.style.height = y > _that.minHeight ? y + "px" : _that.minHeight + "px";
							//暂存拖动后的宽高
							_that.cacheWh["width"] = el.style.width;
							_that.cacheWh["height"] = el.style.height;
						};
						//拉伸结束
						document.onmouseup = function (e) {
							document.onmousemove = null;
							document.onmouseup = null;
						};
					};
				});
			}
		},
		//关闭
		closeDialog() {
			//如果开启关闭前确认
			// if (this.befClose) {
			// 	this.$nextTick(() => {
			// 		this.$baseConfirm(this.$TIPS.CONFIRM_CLOSE, null, () => {
			this.dialogVisible = false;
			this.$emit("reset", true);
			this.$emit("closeDialog", true);
			// 		})
			// 	})
			// } else {
			// 	this.dialogVisible = false
			// }
		},
		//打开
		showDialog() {
			this.dialogVisible = true;
		},
		//最大化
		setFullscreen() {
			const el = this.$refs.dialog.dialogContentRef.$el;
			this.isFullscreen = !this.isFullscreen;
			if (this.isFullscreen) {
				if (this.triggerDom && Object.keys(this.triggerDom).length > 0) {
					this.resetPosition({
						marginTop: 0,
						marginLeft: 0
					});
				}
				//全屏时内容高度100%
				this.contHeight = "100%";
				this.resetLayout(false);
				//全屏修改弹窗宽高
				el.style.width = "100%";
				el.style.height = "100%";
			} else {
				if (this.triggerDom && Object.keys(this.triggerDom).length > 0) {
					this.resetPosition(this.styles);
				}
				//还原内容高度
				this.contHeight = this.height;
				//退出全屏还原弹窗宽高
				el.style.width = this.cacheWh["width"];
				el.style.height = this.cacheWh["height"];
				this.resetLayout(true);
			}
		},
		//弹窗打开后执行
		handleOpen() {
			this.$nextTick(() => {
				const el = this.$refs.dialog.dialogContentRef.$el;
				this.cacheWh["width"] = el.getBoundingClientRect().width + "px";
				this.cacheWh["height"] = el.getBoundingClientRect().height + "px";
				if (this.triggerDom && Object.keys(this.triggerDom).length > 0) {
					let react = {};
					const dialogWidth = el.getBoundingClientRect().width;
					const winWidth = window.innerWidth;
					const winHeight = window.innerHeight;
					if (this.triggerDom.length) {
						react = this.triggerDom[0].$el.getBoundingClientRect();
					} else {
						react = this.triggerDom.$el.getBoundingClientRect();
					}
					if (this.placement === "bottomEnd") {
						this.styles["marginLeft"] = react.left + react.width - dialogWidth;
						this.styles["marginTop"] = react.top + react.height;
					} else {
						this.styles["marginTop"] = react.top + react.height;
						if ((react.left + dialogWidth) > winWidth) {
							this.styles["marginLeft"] = react.left - (react.left + dialogWidth - winWidth);
						} else {
							this.styles["marginLeft"] = react.left;
						}
					}
					this.resetPosition(this.styles);
				}else if(this.placement === 'center'){
					const el = this.$refs.dialog.dialogContentRef.$el;
					el.style.marginTop = '20%' //上边距待确定
				} else {
					//未传入有效triggerDom，使弹窗上边距为3%
					el.style.marginTop = "3%";
				}

				//弹窗拖动改变大小
				this.dragSize();
				//弹窗最大高度为85%
				el.style.maxHeight = "85%";
			});
		},
		//设置弹窗左、上距离
		resetPosition(style) {
			if (style) {
				const el = this.$refs.dialog.dialogContentRef.$el;
				el.style.marginTop = style.marginTop + "px";
				el.style.marginLeft = style.marginLeft + "px";
			}
		},

		handleConfirm() {
			this.closeDialog();
		},
		//设置弹窗最大高度和上边距
		resetLayout(bool) {
			const el = this.$refs.dialog.dialogContentRef.$el;
			if (bool) {
				el.style.maxHeight = "85%";
				el.style.marginTop = "3%";
			} else {
				el.style.maxHeight = "";
				el.style.marginTop = "0px";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.sc-dialog__headerbtn {
	position: absolute;
	top: var(--el-dialog-padding-primary);
	right: var(--el-dialog-padding-primary);
}

.sc-dialog__headerbtn button {
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: var(--el-message-close-size, 16px);
	margin-left: 15px;
	color: var(--el-color-info);
}

.sc-dialog__headerbtn button:hover .el-dialog__close {
	color: var(--el-color-primary);
}

.sc-dialog:deep(.el-dialog).is-fullscreen {
	display: flex;
	flex-direction: column;
	top: 0px !important;
	left: 0px !important;
}

.sc-dialog:deep(.el-dialog).is-fullscreen .el-dialog__header {
}

.sc-dialog:deep(.el-dialog).is-fullscreen .el-dialog__body {
	flex: 1;
	overflow: auto;
}

.sc-dialog:deep(.el-dialog).is-fullscreen .el-dialog__footer {
	padding-bottom: 10px;
	border-top: 1px solid var(--el-border-color-base);
}
</style>
