import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/accounting/voucher/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByCoordinate: {
		url: `${config.API_URL}/fms/accounting/voucher/getListByCoordinate`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/fms/accounting/voucher/getAllList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/accounting/voucher/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/fms/accounting/voucher/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/fms/accounting/voucher/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/fms/accounting/voucher/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	signature: {
		url: `${config.API_URL}/fms/accounting/voucher/signature`,
		name: "签字",
		get: async function(ids,flag){
			return await http.get(this.url + '?ids=' + ids + '&flag=' + flag);
		}
	},

	examine: {
		url: `${config.API_URL}/fms/accounting/voucher/examine`,
		name: "审核",
		get: async function(ids,flag){
			return await http.get(this.url + '?ids=' + ids + '&flag=' + flag);
		}
	},

	getVoucherNumber: {
		url: `${config.API_URL}/fms/accounting/voucher/getVoucherNumber`,
		name: "获取凭证号",
		get: async function(params){
			return await http.get(this.url + '?month=' + params);
		}
	},

	updateAudit: {
		url: `${config.API_URL}/fms/accounting/voucher/updateAudit`,
		name: "修改审批状态",
		get: async function(id,auditStatus){
			return await http.get(this.url + '?id=' + id + '&auditStatus=' + auditStatus);
		}
	},

	voucherSummary: {
		url: `${config.API_URL}/fms/accounting/voucher/detail/summary`,
		name: "凭证汇总表",
		get: async function(params={}){
			return await http.post(this.url,params);
		}
	},


	voucherClassify: {
		url: `${config.API_URL}/fms/accounting/voucher/detail/classify`,
		name: "总分类账",
		get: async function(params={}){
			return await http.post(this.url,params);
		}
	},

	checkNumberByJournal: {
		url: `${config.API_URL}/fms/accounting/voucher/checkNumberByJournal`,
		name: "出纳日记 - 校验会计凭证是否存在",
		get: async function (voucherNumber, voucherWord, accountingDate) {
			return await http.get(this.url + '?voucherNumber=' + voucherNumber + '&voucherWord=' + voucherWord + '&accountingDate=' + accountingDate);
		}
	},
	importExcel: {
		url: `${config.API_URL}/fms/accounting/voucher/importExcel`,
		name: "导入",
		post: async function (data={},config) {
			return await http.post(this.url, data,config);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/fms/accounting/voucher/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	returnProcess: {
		url: `${config.API_URL}/fms/accounting/voucher/returnProcess`,
		name: "反审批",
		get: async function(id){
			return await http.get(this.url + '?ids=' + id);
		}
	},
}
