<!--选择采购退货单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
                    <div class="left-panel">
                      <el-link
                        v-if="multiple"
                        type="primary"
                        :disabled="selections.length === 0"
                        @click="addToRight"
                      >
                        添加到已选
                        <el-icon><el-icon-right /></el-icon>
                      </el-link>
                    </div>
                    <div class="right-panel">
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @current-change="currentChange"
                      @row-dblclick="dblClick"
                      @row-click="handleRowClick"
                      @data-change="fatherDataChange"
                    >
                      <template #orderNo="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refDetail')"
                        >
                          {{ scope.row.orderNo }}
                        </el-link>
                      </template>
                      <template #fz_saleOrderNo="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refSaleOrderDetail', 'saleOrderId')"
                        >
                          {{ scope.row.fz_saleOrderNo }}
                        </el-link>
                      </template>
                      <template #fz_customerName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refCustomerDetail', 'customerId')"
                        >
                          {{ scope.row.fz_customerName }}
                        </el-link>
                      </template>
                      <template #fz_companyName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refCompanyDetail', 'companyId')"
                        >
                          {{ scope.row.fz_companyName }}
                        </el-link>
                      </template>
                      <template #refundType="{ scope }">
                        <span v-if="scope.row.refundType == 1">换货</span>
                        <span v-if="scope.row.refundType == 2">冲抵往来</span>
                        <span v-if="scope.row.refundType == 3">退还账户</span>
                        <span v-if="scope.row.refundType == 4">退还现金</span>
                      </template>
                      <template #paymentMethod="{ scope }">
                        <span v-if="scope.row.paymentMethod == 1">现金</span>
                        <span v-if="scope.row.paymentMethod == 2">转账</span>
                        <span v-if="scope.row.paymentMethod == 3">支票</span>
                        <span v-if="scope.row.paymentMethod == 4">其他</span>
                      </template>
                      <template #supplement="{ scope }">
                        {{ scope.row.supplement == 1? '是':'否' }}
                      </template>
                      <template #refundStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.refundStatus === 0"
													style="color: #666"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未入库
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分入库
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部入库
                        </el-tag>
                        <el-tag
                          v-if="scope.row.refundStatus === 2"
                          type="info"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          已终止
                        </el-tag>
                      </template>
                      <template #paymentStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.paymentStatus === 0"
                          type="info"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未付款
                        </el-tag>
                        <el-tag
                          v-if="scope.row.paymentStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分付款
                        </el-tag>
                        <el-tag
                          v-if="scope.row.paymentStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部付款
                        </el-tag>
                      </template>
                      <!--质检状态-->
                      <template #qualityStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.qualityStatus === 0"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未检验
                        </el-tag>
                        <el-tag
                          v-if="scope.row.qualityStatus === 1"
                          type="danger"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          检验驳回
                        </el-tag>
                        <el-tag
                          v-if="scope.row.qualityStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          检验通过
                        </el-tag>
                      </template>
                    </scTable>
                  </el-main>
                  <sy-switch-btn
                    :text="'物品明细'"
                    :opened="leftShow"
                    bottom
                    @show-left="handleShowLeft"
                  />
                </el-container>
              </pane>
              <!--已选数据-->
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="50"
                min-size="20"
              >
                <el-container
                  class="sonCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <el-main class="nopadding">
                    <!--明细-->
                    <scTable
                      ref="tableSon"
                      :api-obj="tableSonConfig.apiObj"
                      :column="tableSonConfig.columns"
                      :params="tableSonConfig.params"
                      row-key="id"
                      border
                      remote-sort
                    >
                      <!--自定义单元格显示 #{字段名称} -->
                      <template #fz_goodsName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
                        >
                          {{ scope.row.fz_goodsName }}
                        </el-link>
                      </template>
                      <template #enterStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.enterStatus === 0"
                          class="myTag"
													style="color: #666"
                          :size="$store.state.global.uiSize"
                        >
                          未入库
                        </el-tag>
                        <el-tag
                          v-if="scope.row.enterStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分入库
                        </el-tag>
                        <el-tag
                          v-if="scope.row.enterStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部入库
                        </el-tag>
                      </template>
                    </scTable>
                  </el-main>
                </el-container>
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <CustomerDetail ref="refCustomerDetail" />
    <CompanyDetail ref="refCompanyDetail" />
    <SaleOrderDetail ref="refSaleOrderDetail" />
  </sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick, onMounted,
} from 'vue'
import Detail from "@/views/erp/purchase/order/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import SaleOrderDetail from "@/views/erp/sale/order/detail";
import CustomerDetail from "@/views/cfg/data/customer/detail";
export default defineComponent ({
	name: "SelectSaleRefund",
	components: {
		Detail,
		GoodsDetail,
		CustomerDetail,
		CompanyDetail,
		SaleOrderDetail,
	},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择销售退货单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '订单编号',
					prop: 'orderNo',
					width: 200,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {
				pageNum: 1,
				pageSize: 1000,
			},
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.saleRefund.getList,
				params:{},
				//数据表格列配置
				columns: [
					{
						label: '订单编号',
						prop: 'orderNo',
						width: 200,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '销售订单',
						prop: 'fz_saleOrderNo',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '客户',
						prop: 'fz_customerName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '业务员',
						prop: 'fz_salesmanName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '质检状态',
						prop: 'qualityStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退货日期',
						prop: 'refundDate',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '是否补单',
						prop: 'supplement',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '创建日期',
						prop: 'creationDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '整单折扣[%]',
						prop: 'discount',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '退货类型',
						prop: 'refundType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '付款方式',
						prop: 'paymentMethod',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退款账户',
						prop: 'fz_refundAccountName',
						width: 110,
						sortable: 'custom',
					},
					// {
					// 	label: '共享人',
					// 	prop: 'fz_shareUserName',
					// 	width: 150,
					// 	sortable: 'custom',
					// },
					{
						label: '单位抬头',
						prop: 'fz_companyName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '退货状态',
						prop: 'refundStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '终止原因',
						prop: 'reason',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '付款状态',
						prop: 'paymentStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '合计金额',
						prop: 'totalAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '减免金额',
						prop: 'reductionAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '应付金额',
						prop: 'payableAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已付金额',
						prop: 'paymentAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未付金额',
						prop: 'unpaidAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '物品规格',
						prop: 'fz_goodsSpec',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '物品型号',
						prop: 'fz_goodsModel',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '收货仓库',
						prop: 'fz_depotName',
						sortable: 'custom',
						fixed: 'left',
						width: 110,
					},
					{
						label: '物品批号',
						prop: 'batchNumber',
						width: 130,
						sortable: 'custom',
					},
					{
						label: 'SN序号',
						prop: 'snNumber',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '退货数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未税单价',
						prop: 'price',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '税率(%)',
						prop: 'taxRate',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '含税单价',
						prop: 'taxPrice',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未税成本',
						prop: 'cost',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '含税成本',
						prop: 'taxCost',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '税额(￥)',
						prop: 'taxAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '货款(￥)',
						prop: 'goodsAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '小计(￥)',
						prop: 'subtotal',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '入库状态',
						prop: 'enterStatus',
						width: 110,
						disable: true,
					},
					{
						label: '入库数量',
						prop: 'enterQuantity',
						width: 110,
						disable: true,
					},
					{
						label: '摘要',
						prop: 'abstract',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				defaultLength: 3,
				//查询表单项配置
				itemList: [
					{
						label: '订单编号',
						prop: 'orderNo',
						span: 8,
						type: 'input',
						default: true,
					},
					{
						label: '销售订单',
						span: 8,
						prop: 'fz_saleOrderNo',
						type: 'input',
						default: true,
					},
					{
						label: '客户',
						span: 8,
						prop: 'fz_customerName',
						type: 'popCustomer',
						default: true,
						fields: ['customerId', 'fz_customerName']
					},
					{
						label: '业务员',
						span: 8,
						prop: 'fz_salesmanName',
						type: 'popUser',
            max:32,
						short: 12,
						fields: ['salesmanId', 'fz_salesmanName'],
					},
					{
						label: '质检状态',
						span: 8,
						prop: 'qualityStatus',
						type: 'select',
						short: 12,
						data: [
							{
								label: '未检验',
								value: 0,
							},
							{
								label: '检验驳回',
								value: 1,
							},

							{
								label: '检验通过',
								value: 2,
							},
						],
					},
					{
						label: '退货日期',
						span: 8,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'refundDate0',
							},
							{
								prop: 'refundDate1',
							},
						],
					},
					{
						label: '是否补单',
						span: 8,
						prop: 'supplement',
						type: 'select',
						short: 12,
						data: [
							{
								label: '是',
								value: 1,
							},
							{
								label: '否',
								value: 0,
							},
						],
					},
					{
						label: '创建日期',
						span: 8,
						type: 'dateRange',
						//开始结束日期字段
						fields: [
							{
								prop: 'creationDate0',
							},
							{
								prop: 'creationDate1',
							},
						],
					},
					{
						label: '整单折扣',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'discount0',
								max: 5,
								append: '%'
							},
							{
								prop: 'discount1',
								max: 5,
								append: '%'
							}
						],
					},
					{
						label: '退货类型',
						span: 8,
						prop: 'refundType',
						type: 'select',
						//下拉选项
						data: [
							{value: '1', label: '换货'},
							{value: '2', label: '冲抵往来'},
							{value: '3', label: '退还账户'},
							{value: '4', label: '退还现金'},
						]
					},
					{
						label: '付款方式',
						span: 8,
						prop: 'paymentMethod',
						type: 'select',
						//下拉选项
						data: [
							{value: '1', label: '现金'},
							{value: '2', label: '转账'},
							{value: '3', label: '支票'},
							{value: '4', label: '其他'},
						]
					},
					{
						label: '退款账户',
						span: 8,
						prop: 'fz_refundAccountName',
						type: 'popAccount',
						short: 12,
						fields: ['refundAccountId', 'fz_refundAccountName'],
					},
					{
						label: '备注',
						span: 8,
						prop: 'remark',
						type: 'input',
						max: 100,
					},
					// {
					// 	label: '共享人',
					// 	span: 8,
					// 	prop: 'fz_shareUserName',
					// 	type: 'popUser',
					// 	fields: ['shareUser', 'fz_shareUserName'],
					// },
					{
						label: '单位抬头',
						span: 8,
						prop: 'fz_companyName',
						type: 'popCompany',
						fields: ['companyId', 'fz_companyName']
					},
					{
						label: '退货状态',
						span: 8,
						prop: 'refundStatus',
						type: 'select',
						data: [
							{value: 0, label: '未入库'},
							{value: 1, label: '部分入库'},
							{value: 2, label: '全部入库'},
							{value: 3, label: '已终止'},
						]
					},
					{
						label: '终止原因',
						span: 8,
						prop: 'reason',
						type: 'input',
						max: 100,
					},
					{
						label: '付款状态',
						span: 8,
						prop: 'paymentStatus',
						type: 'select',
						data: [
							{value: 0, label: '未付款'},
							{value: 1, label: '部分付款'},
							{value: 2, label: '全部付款'},
						]
					},
					{
						label: '合计金额',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'totalAmount0',
								max: 10,
							},
							{
								prop: 'totalAmount1',
								max: 10,
							}
						],
					},
					{
						label: '减免金额',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'reductionAmount0',
								max: 10,
							},
							{
								prop: 'reductionAmount1',
								max: 10,
							}
						],
					},
					{
						label: '已付金额',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'payableAmount0',
								max: 10,
							},
							{
								prop: 'payableAmount1',
								max: 10,
							}
						],
					},
					{
						label: '未付金额',
						span: 8,
						type: 'rangeNum',
						//开始结束字段
						fields: [
							{
								prop: 'unpaidAmount0',
								max: 10,
							},
							{
								prop: 'unpaidAmount1',
								max: 10,
							}
						],
					},
					{
						label: '操作用户',
						span: 8,
						prop: 'fz_operateName',
						type: 'popUser',
            max:32,
						short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'addTime0',
							},
							{
								prop: 'addTime1',
							},
						],
					},
				],
			},
		})
    onMounted(() => {
      state.hideForm=true
    })
		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			state.selections = []
			state.selData = []
			state.isConfirm = true
			state.loading = false
			if(data) {
				Object.assign(state.tableConfig.params, data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if(data && state.selData.indexOf(data) === -1){
				state.selData.push(data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				const param = Object.assign({}, state.params)
				param['refundId'] = row.id
				state['tableSon'].reload(param)
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				//设置第一行选中
				state['table'].setCurrentRow(res.data.list[0])
				//传入父级ID
				Object.assign(state.tableSonConfig.params, state.params)
				state.tableSonConfig.params['refundId'] = res.data.list[0].id
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.saleRefundGoods.selectRefundGoods
				//根据主数据加载子数据
				handleRowClick(res.data.list[0])
			}else{
				if (state.leftShow) {
					proxy.$refs.tableSon.reload({refundId: -1})
				}
			}

		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//删除选中的已选数据
		const delSelData = () => {
			if(state.selectionsTableSel.length > 0){
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					if(index != -1){
						state.selData.splice(index,1)
					}
				})
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			clearSelections,
			delSelData,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
		}
	},
})
</script>
