import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/sale/repairInvoice/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/sale/repairInvoice/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/sale/repairInvoice/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/sale/repairInvoice/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/sale/repairInvoice/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	outStock: {
		url: `${config.API_URL}/erp/sale/repairInvoice/outStock`,
		name: "出库",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	sign: {
		url: `${config.API_URL}/erp/sale/repairInvoice/sign`,
		name: "签收",
		get: async function(params){
			return await http.post(this.url + '?ids=' + params);
		}
	},
}
