//获取当前日期
import {ElNotification} from "element-plus";
import modules from "@/api";

export function getNowDate(){
	const date = new Date()
	let dateStr = ''
	dateStr += date.getFullYear() + '-'
	if(date.getMonth() + 1 < 10){
		dateStr += '0' + (date.getMonth() + 1) + '-'
	}else{
		dateStr += date.getMonth() + 1 + '-'
	}
	if(date.getDate() < 10){
		dateStr += '0' + date.getDate()
	}else{
		dateStr += date.getDate()
	}

	return dateStr
}

//获取当前日期时间
export function getNowDateTime(){
	const date = new Date()
	let dateStr = ''
	dateStr += date.getFullYear() + '-'
	if(date.getMonth() + 1 < 10){
		dateStr += '0' + (date.getMonth() + 1) + '-'
	}else{
		dateStr += date.getMonth() + 1 + '-'
	}
	if(date.getDate() < 10){
		dateStr += '0' + date.getDate()
	}else{
		dateStr += date.getDate()
	}

	if(date.getHours() < 10){
		dateStr += ' 0' + date.getHours() + ':'
	}else{
		dateStr += ' ' + date.getHours() + ':'
	}

	if(date.getMinutes() < 10){
		dateStr += '0' + date.getMinutes() + ':'
	}else{
		dateStr += date.getMinutes() + ':'
	}

	if(date.getSeconds() < 10){
		dateStr += '0' + date.getSeconds()
	}else{
		dateStr += date.getSeconds()
	}

	return dateStr
}
//获取当前月
export function getNowMonth(){
	const date = new Date()
	let dateStr = ''
	dateStr += date.getFullYear() + '-'
	if(date.getMonth() + 1 < 10){
		dateStr += '0' + (date.getMonth() + 1)
	}else{
		dateStr += date.getMonth() + 1
	}
	return dateStr
}
//获取上一月
export function getPrevMonth(){
	// 获取当前日期
	const date = new Date();
	// 获取上一个月份，注意要处理边界情况
	const lastMonth = date.getMonth() === 0 ? 11 : date.getMonth() - 1;
	// 获取上一个月的年份
	const lastYear = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
	// 格式化输出
	const formattedOutput = lastYear + '-' + (lastMonth < 9 ? '0' : '') + (lastMonth + 1);

	return formattedOutput
}
// 获取下一个月
export function getNextMonth() {
	// 获取当前日期
	const date = new Date();

	// 获取下一个月份，注意要处理边界情况
	const nextMonth = date.getMonth() === 11 ? 0 : date.getMonth() + 1;

	// 获取下一个月的年份
	const nextYear = date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear();

	// 格式化输出
	const formattedOutput = nextYear + '-' + (nextMonth < 9 ? '0' : '') + (nextMonth + 1);

	return formattedOutput;
}
//获取周一
export function GetMonday() {
	const dd = new Date();
	const week = dd.getDay(); //获取时间的星期数
	const minus = week ? week - 1 : 6;
	dd.setDate(dd.getDate() - minus); //获取周一日期
	const y = dd.getFullYear();
	const m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
	const d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
	return y + "-" + m + "-" + d;
}
//获取周天
export function GetSunday() {
	const dd = new Date();
	const week = dd.getDay(); //获取时间的星期数
	const minus = week ? week - 1 : 6;
	dd.setDate(dd.getDate() - minus);//获取周一日期
	dd.setDate(dd.getDate()+6)//加6得到周日日期
	const y = dd.getFullYear();
	const m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
	const d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
	return y + "-" + m + "-" + d;
}
//当前月最后一天
export function getCurrentMonthLast(){
	const date=new Date();
	let currentMonth=date.getMonth();
	const nextMonth=++currentMonth;
	const nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
	const oneDay=1000*60*60*24;
	const dd = new Date(nextMonthFirstDay-oneDay)
	const y = dd.getFullYear();
	const m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
	const d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
	return y + "-" + m + "-" + d;
}
//当前月第一天
export function getCurrentMonthFirst(){
	const date=new Date();
	date.setDate(1);
	const dd = date
	const y = dd.getFullYear();
	const m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
	const d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
	return y + "-" + m + "-" + d;
}

// 格式化日期，输出 'YYYY-MM-DD' 格式的字符串
function formatDate(date) {
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const day = String(date.getDate()).padStart(2, '0')

	return `${year}-${month}-${day}`
}
//传入月份第一天
export function getMonthFirst(monthStr){
	const [year, month] = monthStr.split('-')
	const firstDay = new Date(year, month - 1, 1)
	return formatDate(firstDay)
}
//传入月份第一天
export function getMonthLast(monthStr){
	const [year, month] = monthStr.split('-')
	const lastDay = new Date(year, month, 0)
	return formatDate(lastDay)
}
//比较日期大小
export function compareDate(start, end){
	if(start && end){
		return Date.parse(start) > Date.parse(end)
	}
}
//获取相差天数
export function getDays(start, end){
	if(start && end){
		const startTime = new Date(start)
		const endTime = new Date(end)
		const days = endTime.getTime() - startTime.getTime()
		return parseInt(days / (1000 * 60 * 60 * 24))
	}
}
