<!--公用属性结构组件-->
<template>
	<el-container class="treeCont">
		<el-header class="treeBar">
			<!--查询输入框-->
			<el-input
				v-model="treeKeyWords"
				:size="$store.state.global.uiSize"
				placeholder="输入名称搜索"
				clearable
				@input="handleTreeInput"
			/>
		</el-header>
		<el-header>
			<div class="left-panel">
				<el-link @click="handleExpandAll">
					{{ expandAll ? "收起" : "展开" }}所有
					<el-icon>
						<el-icon-minus v-if="expandAll"/>
						<el-icon-plus v-else/>
					</el-icon>
				</el-link>
			</div>
			<!--		  <div class="right-panel">-->
			<!--			  <el-icon><el-icon-plus @click="handleAddModle" /></el-icon>-->
			<!--		  </div>-->
		</el-header>
		<el-main
			v-loading="loading"
			class="syTree"
		>
			<!--属性组件
			node-key="id" 数组件唯一key 用于区分节点唯一标识
			:data="groupData" 绑定树的数据
			:default-expand-all="expandAll" 默认是否展开全部
			:expand-on-click-node 点击节点是否展开子节点
			:filter-node-method 筛选方法
			:props="property" 查询参数
			@node-click="groupClick" 节点点击方法
			-->
			<el-tree
				ref="refTree"
				class="menu"
				node-key="id"
				:data="groupData"
				:default-expand-all="expandAll"
				:expand-on-click-node="false"
				:filter-node-method="groupFilterNode"
				:props="property"
				highlight-current
				:current-node-key="currentNodeKey"
				@node-click="groupClick"
			>

				<!--自定义节点内容
				hideIcon是否隐藏图标
				-->
				<template
					v-if="!hideIcon"
					#default="{ node, data }"
				>
				  <span
					  class="custom-tree-node"
					  @mouseover="handleMouseover(node)"
					  @mouseleave="handleMouseLeave(node)"
				  >
					<span>
					  <i
						  v-if="data.children && data.children.length === 0"
						  class="iconfont icon-line-filewendang-03 treeIco"
					  />
					  <i
						  v-else-if="node.expanded"
						  class="iconfont icon-line-folderopenwenjianjiadakai treeIco"
					  />
					  <i
						  v-else
						  class="iconfont icon-line-folderwenjianjia-04 treeIco"
					  />
					  {{ node.label }}
					</span>
				  </span>
				</template>
			</el-tree>
		</el-main>
		<!--    <el-footer class="treeFooter">-->
		<!--      <el-link @click="handleExpandAll">-->
		<!--        {{ expandAll ? '收起' : '展开' }}所有-->
		<!--        <el-icon>-->
		<!--          <el-icon-minus v-if="expandAll" />-->
		<!--          <el-icon-plus v-else />-->
		<!--        </el-icon>-->
		<!--      </el-link>-->
		<!--    </el-footer>-->
		<edit
			ref="refEdit"
			@fetch-data="fetchData"
		/>
	</el-container>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	getCurrentInstance,
	nextTick,
} from "vue";
import Edit from "./edit";

export default defineComponent({
	name: "SyTree",
	components: {Edit},
	props: {
		//默认查询参数
		params: {
			type: Object, default: () => {
			}
		},
		//数据接口对象
		apiObj: {
			type: Object, default: () => {
			}
		},
		//是否显示图标
		hideIcon: {type: Boolean, default: false},
		//树结构映射字段
		properties: {
			type: Object, default: () => {
			}
		},
		//是否开启编辑
		isEdit: {type: Boolean, default: true},
		//treeList数据列表
		treeList: {type: Array, default: () => []},
		//是否添加顶级项
		addTop: {type: Boolean, default: false},
		//顶级项名字
		topName: {type: String, default: '全部类别'},
		//不带分页参数
		hidePagination: {type: Boolean, default: false},
	},
	emits: ["fetch-data-node", "data-change"],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//编辑组件
			refEdit: null,
			currentNodeKey: null,
			//暂存节点名称
			cacheNodeName: "",
			//是否显示选择框
			showCheck: false,
			//暂存节点
			oldCurNode: {},
			//最大限制层级
			nodeLevel: 4,
			//加载动画
			loading: false,
			//搜索Model
			treeKeyWords: "",
			//是否展开全部
			expandAll: true,
			//树组件
			refTree: null,
			//节点数据
			groupData: [],
			//暂存选中节点
			curNode: {},
			property: props.properties || {label: "name", children: "children"}
		});

		//筛选返回节点
		const groupFilterNode = (value, data) => {
			if (!value) return true;
			return data.name.indexOf(value) !== -1;
		};

		//节点点击
		const groupClick = (data, node) => {
			if (state.oldCurNode) {
				state.oldCurNode.optable = false;
			}
			state.oldCurNode = node;
			//传递点击节点给父组件
			emit("fetch-data-node", data);
			if (props.isEdit) {
				node.optable = true;
			}
		};

		//筛选节点
		const filterNodes = (val) => {
			state["refTree"].filter(val);
		};

		//展开收起全部
		const handleExpandAll = () => {
			state.expandAll = !state.expandAll;
			const allNodes = state["refTree"].store.nodesMap;
			Object.keys(allNodes).forEach((item) => {
				allNodes[item].expanded = state.expandAll;
			});
		};

		//筛选
		const handleTreeInput = (val) => {
			filterNodes(val);
		};

		const handleAddModle = () => {
			//业务编码存在则新增
			if (props.params) {
				let node = null;
				if (props.params.businessType) {
					node = {
						businessType: props.params.businessType,
					};
				}
				state["refEdit"].showDialog(node);
			}
		};
		//添加节点
		const append = async (node, data) => {
			if (node.level > state.nodeLevel) {
				proxy.$baseMessage(
					"当前分类最多只能添加到5级",
					"error",
					"vab-hey-message-error"
				);
				return;
			}
			//业务编码存在则新增
			if (props.params && props.params.businessType) {
				const blankNode = {
					parentId: data.id,
					parentName: data.name,
					businessType: props.params.businessType,
				};
				state["refEdit"].showDialog(blankNode);
			}
		};

		//添加顶级节点
		const appendTopNode = async () => {
			if (props.params && props.params.businessType) {
				const blankNode = {
					id: "",
					name: "顶级分类",
					parentId: 0,
					businessType: props.params.businessType,
				};
				state["refTree"].append(blankNode);
				const msg = await proxy.$API.classify.insert.post(blankNode);
				if (msg.code === 200) {
					const newNode = state["refTree"].getNode(blankNode);
					const newData = newNode.data;
					Object.assign(newData, msg.data);
				}
			}
		};

		//打开编辑
		const edit = (node, data) => {
			state["refEdit"].showDialog(data);
		};

		//编辑后保存节点
		const handleBlur = async (node, data) => {
			if (!data.name) {
				data.name = state.cacheNodeName;
			} else {
				const res = proxy.$API.classify.update.put(data);
				if (res.code === 200) {
					proxy.$baseMessage("保存成功", "success");
				} else {
					proxy.$baseMessage(res.msg, "error");
				}
			}
			node.editable = false;
		};

		//删除节点
		const handleDelete = (node, data) => {
			if (data) {
				if (node.childNodes.length > 0) {
					proxy.$baseMessage(
						"当前节点存在子级，请先删除子级!",
						"error",
						"vab-hey-message-error"
					);
					return;
				}
				proxy.$baseConfirm("你确定要删除当前项吗", null, async () => {
					const msg = await doDelete({ids: data.id});
					if (msg.code == 200) {
						proxy.$baseMessage(msg.msg, "success", "vab-hey-message-success");
					} else {
						proxy.$baseMessage(msg.msg, "error", "vab-hey-message-error");
					}
					await fetchData();
				});
			} else {
				/* if (state.selectRows.length > 0) {
				  const ids = state.selectRows.map((item) => item.id).join()
				  proxy.$baseConfirm('你确定要删除选中项吗', null, async () => {
					const msg = await doDelete({ ids })
					proxy.$baseMessage(msg.msg, 'success', 'vab-hey-message-success')
					await fetchData()
				  })
				} else {
				  proxy.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
				} */
			}
		};

		//编辑框点击
		const handleInputClick = (e) => {
			e.stopPropagation();
		};

		//开启选择框
		const handleShowCheck = () => {
			state.showCheck = !state.showCheck;
		};

		//拉取数据
		const fetchData = async () => {
			state.loading = true;
			if (props.apiObj && JSON.stringify(props.apiObj) !== "{}") {
				const res = await props.apiObj.get(props.params);
				if (res.code === 200) {
					if (props.params) {
						if (props.params.freightType === 1) {
							if (props.addTop) {
								const childList = res.data;
								state.groupData = [{name: "全部仓库", children: childList}];
							} else {
								state.groupData = res.data;
							}
						} else {
							if (props.params.businessType) {
								state.groupData = res.data;
							} else {
								if (props.addTop) {
									let childList = [];
									if (props.hidePagination) {
										childList = res.data;
									} else {
										childList = res.data.list;
									}
									state.groupData = [{
										name: "全部类别",
										id: -1,
										fz_name: "全部类别",
										children: childList
									}];
									console.log(state.groupData);
								} else {
									state.groupData = res.data.list;
								}
							}
						}
					} else {
						if(props.addTop){
							state.groupData = [{name: props.topName,id: -1, fz_name: props.topName, children: res.data}]
						}else {
							state.groupData = res.data
						}
					}
				}
			} else if (props.treeList) {
				state.groupData = props.treeList;
			}
			emit("data-change", state.groupData);
			state.loading = false;
		};

		onMounted(() => {
			fetchData();
		});

		//鼠标移动到节点名称上
		const handleMouseover = (node) => {
			if (props.isEdit) {
				node.optable = true;
			}
		};

		//鼠标移出节点名称
		const handleMouseLeave = (node) => {
			if (!node.isCurrent) {
				node.optable = false;
			}
		};
		const setCurrentKey = (key, bool = true) => {
			nextTick(() => {
				state.refTree.setCurrentKey(key, bool);
			});
		};

		return {
			...toRefs(state),
			setCurrentKey,
			fetchData,
			appendTopNode,
			edit,
			handleBlur,
			handleDelete,
			handleInputClick,
			handleShowCheck,
			append,
			handleMouseover,
			handleMouseLeave,
			handleTreeInput,
			groupFilterNode,
			groupClick,
			filterNodes,
			handleExpandAll,
			handleAddModle
		};
	},
});
</script>
<style scoped>
.item {
	cursor: pointer;
}

.syTree:deep(.el-tree.menu) .el-tree-node__content {
	height: 30px;
	line-height: 30px;
}

.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 8px;
}

.clsBtn {
	margin: 0 2px;
}
</style>

