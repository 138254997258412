import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import '@/assets/iconfont/iconfont.css'
//import 'vant/lib/index.css' // 引入 Vant 的样式文件
//import Vant from 'vant' // 引入 Vant
//import '@vant/touch-emulator'
import scui from './scui'
import i18n from './locales'
import router from './router'
import store from './store'
import { createApp } from 'vue'
import App from './App.vue'
import VueSign from 'vue-esign'
import focusMixin from '@/utils/focusMixin'

const app = createApp(App);
//app.use(Vant);
app.use(store);
app.use(router);
app.use(ElementPlus,);
app.use(i18n);
app.use(scui);
app.use(VueSign);

app.mixin(focusMixin)

//挂载app
app.mount('#app');
