import config from "@/config"
import http from "@/utils/request"

export default {
	purSupplier: {
		url: `${config.API_URL}/erp/purchase/company/purProduct`,
		name: "采购统计 - 按产品",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	purOrderGlance: {
		url: `${config.API_URL}/erp/purchase/company/purOrderGlance`,
		name: "采购订单 - 览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	receiveGlance: {
		url: `${config.API_URL}/erp/purchase/company/receiveGlance`,
		name: "采购收货 - 览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	purRefundGlance: {
		url: `${config.API_URL}/erp/purchase/company/purRefundGlance`,
		name: "采购退货 - 览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	purApplyGlance: {
		url: `${config.API_URL}/erp/purchase/company/purApplyGlance`,
		name: "采购申请 - 览表",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
