import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/data/currency/getList`,
		name: "列表(分页)",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	getAllList: {
		url: `${config.API_URL}/cfg/data/currency/getAllList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	checkCode: {
		url: `${config.API_URL}/cfg/data/currency/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/data/currency/delete`,
		name: "删除",
		post: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/cfg/data/currency/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/data/currency/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/data/currency/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	updateDefault: {
		url: `${config.API_URL}/cfg/data/currency/setDefault`,
		name: "设置默认",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
}
