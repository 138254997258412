<!--选择物流联系人弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'500px'"
		custom-class="popTable"
		type="detail"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<el-container :style="multiple && 'padding-right: 10px'">
							<sy-search-Form
								v-show="!hideForm"
								ref="refFormBar"
								:configs="searchConfig"
								:show-item="showItem"
								@fetch-data="fetchData"
								@set-show="handleFitShow"
							/>
							<el-header class="tableTopBar">
								<div class="left-panel">
									<!--									<el-link-->
									<!--										v-if="multiple"-->
									<!--										type="primary"-->
									<!--										:disabled="selections.length === 0"-->
									<!--										@click="addToRight"-->
									<!--									>-->
									<!--										添加到已选<el-icon><el-icon-right /></el-icon>-->
									<!--									</el-link>-->
								</div>
								<div class="right-panel">
									<!--切换显示搜索栏hide-after: 延迟隐藏-->
									<el-tooltip
										:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
										placement="bottom"
										:hide-after="0"
									>
										<el-link @click="handleShowSearch">
											搜索
											<el-icon>
												<template v-if="hideForm">
													<el-icon-arrowDown />
												</template>
												<template v-else>
													<el-icon-arrowUp />
												</template>
											</el-icon>
										</el-link>
									</el-tooltip>
								</div>
							</el-header>
							<el-main class="nopadding bgwhite">
								<!--主数据表格-->
								<scTable
									ref="table"
									:api-obj="tableConfig.apiObj"
									:column="tableConfig.columns"
									:params="tableConfig.params"
									row-key="id"
									:checkbox="multiple"
									radio-check
									border
									highlight-current-row
									:ref-query-form="refFormBar"
									remote-sort
									remote-filter
									hide-column-setting
									hide-setting
									:pagination-layout="'total, prev, pager, next, jumper'"
									@selection-change="selectChange"
									@select="handleSelect"
									@data-change="handleDataChange"
									@current-change="currentChange"
									@select-all="handleSelectAll"
									@row-click="firClick"
									@row-dblclick="dblClick"
								>
									<template #gender="{ scope }">
										<span v-if="scope.row['gender'] === 1">男</span>
										<span v-if="scope.row['gender'] === 0">女</span>
									</template>
									<template #importance="{ scope }">
										<span v-if="scope.row['importance'] === 1">关键联系人</span>
										<span v-if="scope.row['importance'] === 2">重要联系人</span>
										<span v-if="scope.row['importance'] === 3">普通联系人</span>
										<span v-if="scope.row['importance'] === 4">普通联系人</span>
										<span v-if="scope.row['importance'] === 5">特别联系人</span>
									</template>
									<template #defaul="{ scope }">
										<span v-if="scope.row['defaul'] === 1">是</span>
										<span v-if="scope.row['defaul'] === 0">否</span>
									</template>
								</scTable>
							</el-main>
						</el-container>
					</pane>
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumn"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
			<!--			<el-button-->
			<!--				:size="$store.state.global.uiSize"-->
			<!--				@click="visible = false"-->
			<!--			>-->
			<!--				关闭-->
			<!--			</el-button>-->
		</template>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick, watch, onMounted,
} from 'vue'
export default defineComponent ({
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择物流联系人' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			depot: {},
			depotList: [],
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '姓名',
					prop: 'name',
					width: 120,
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.logisticsContacts.getList,
				params: {},
				columns: [
					{
						label: '姓名',
						prop: 'name',
						width: 120,
						sortable: 'custom',
						fixed: 'left'
					},
					{
						label: '性别',
						prop: 'gender',
						width: 90,
						sortable: 'custom',
					},
					// {
					// 	label: '重要程度',
					// 	prop: 'importance',
					// 	sortable: 'custom',
					// 	width: 120,
					// },
					{
						label: '职位',
						prop: 'position',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '联系手机',
					// 	prop: 'phone',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '联系电话',
						prop: 'tel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '电子邮箱',
						prop: 'email',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: 'QQ',
					// 	prop: 'qq',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					{
						label: '微信',
						prop: 'weChat',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '传真',
					// 	prop: 'fax',
					// 	width: 120,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '生日',
					// 	prop: 'birthday',
					// 	width: 160,
					// 	sortable: 'custom',
					// },
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '默认',
						prop: 'defaul',
						sortable: 'custom',
						width: 90,
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				queryForm:{},
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '姓名',
						prop: 'name',
						type: 'input',
						max: 100,
					},
					{
						label: '性别',
						prop: 'gender',
						type: 'select',
						// short: 12,
						data: [
							{
								label: '女',
								value: 0,
							},
							{
								label: '男',
								value: 1,
							},
						],
					},
				],
			},
		})
		onMounted(() => {
			//消息监听
			state.hideForm=!state.hideForm
		})
		//显示弹窗
		const showDialog = (param) => {

			if (param != null){
				state.tableConfig.params = Object.assign({}, param)
			}

			state.visible = true
			state.loading = false
			state.selData = []
			getDepotList()
		}

		//获取仓库数据
		const getDepotList = async () => {
			const res = await proxy.$API.depot.getAllList.get()
			if(res.data && res.data.length > 0){
				state.depotList = res.data
				state.depot = res.data[0]
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val


			// if (state.selections && state.selections.length >= 1){
			// 	for (const item of state.selections) {
			// 		setSelData(item)
			// 	}
			// }
		}


		//全选
		const handleSelectAll = (selection) => {
			if(selection && selection.length > 0){
				selection.forEach((item) => {
					setSelData(item)
				})
			}else{
				state.selData = []
			}
		}

		//复选框选择
		const handleSelect = (selection, row) => {

			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				// 右边没有给他推进去
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		watch(
			() => state.selData,
			(val) => {
				if (val.length > 0) {
					state.isConfirm = false
				} else {
					state.isConfirm = true
				}
			},
			{deep: true}
		)

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		// //行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}

		}

		//行单击
		const firClick = (row) => {

			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			data = {
				...state.searchConfig.queryForm,
				...data
			}
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {

		}

		// const handleDetail = (row) => {
		// 	proxy.$refs.refDetail.showDialog(row)
		// }


		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				// if (field == 'saleOrderId'){
				// 	params.id = row['saleOrderId']
				// }else {
				//
				// }
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		/*--------------------已选数据操作---------------------------*/


		const setSelData = (data) => {
			const index = state.selData.indexOf(data)
			if (data && index === -1) {
				state.selData.push(data)
			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()
		}




		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据

		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if(findItem){
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//表格数据加载完成
		const handleDataChange = () => {
			showChooseData()
		}

		/*--------------------已选数据操作---------------------------*/

		return{
			...toRefs(state),
			addToRight,
			handleDataChange,
			clearSelections,
			tableSelselectChange,
			delSelData,
			handleDetail,
			handleRowClick,
			handleShowSearch,
			handleFitShow,
			handleSelectAll,
			handleSelect,
			fetchData,
			selectChange,
			handleConfirm,
			handleShowLeft,
			currentChange,
			showDialog,
			dblClick,
			firClick,
			hideDialog,
		}
	},
})
</script>
