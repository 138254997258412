<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="姓名"
        >
          <el-input
            v-model="form.name"
            maxlength="10"
            clearable
          />
        </el-form-item>
<!--        <el-form-item-->
<!--          prop="phone"-->
<!--          label="联系手机"-->
<!--        >-->
<!--          <el-input-->
<!--            v-model="form.phone"-->
<!--            maxlength="20"-->
<!--            clearable-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item
          prop="tel"
          label="联系电话"
        >
          <el-input
            v-model="form.tel"
            maxlength="255"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="gender"
          label="性别"
        >
          <el-radio-group
            v-model="form.gender"
            size="small"
          >
            <el-radio :label="1">
              男
            </el-radio>
            <el-radio :label="0">
              女
            </el-radio>
          </el-radio-group>
        </el-form-item>
<!--        <el-form-item-->
<!--          prop="importance"-->
<!--          label="重要程度"-->
<!--        >-->
<!--          <el-select-->
<!--            v-model="form.importance"-->
<!--            clearable-->
<!--          >-->
<!--            <el-option-->
<!--              label="一般"-->
<!--              :value="1"-->
<!--            />-->
<!--            <el-option-->
<!--              label="重要"-->
<!--              :value="2"-->
<!--            />-->
<!--            <el-option-->
<!--              label="非常重要"-->
<!--              :value="3"-->
<!--            />-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <!--文本框-->
        <el-form-item
          prop="remark"
          label="备注"
        >
          <!--
          type="textarea"表示文本输入框
          rows="5"默认行数，越大文本框越高
          -->
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="255"
            rows="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      remote-sort
      :ref-query-form="refFormBar"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetZnum } from "@/utils/validate"; //resetZnum返回正整数的方法
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      refFormBar:null,
      visibles: false,
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '用户新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				name: [
					{required: true, trigger: 'blur', message: '必填'},
				],
				tel: [
					{required: true, trigger: 'blur', message: '必填'},
				],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.customerContacts.getList,
				params: {},
				//弹窗标题
				title:'复制联系人信息',
				//查询匹配的字段
				prop: 'name',
				//表格显示列
				columns: [
					{
						label: '姓名',
						prop: 'name',
					},
					{
						label: '联系电话',
						prop: 'tel',
					}
				]
			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row, type) => {
			state.visible = true
			if (type === 1) { //新增
				state.title="新增联系人"
				state.form.customerId = row.customerId
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑联系人[" + row.name + ']'
				await getDetailData(row)
			}
			state.copyConfig.params = { customerId: state.form.customerId }
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.customerContacts.detail.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
      save()
			state.visibles = false
		}
    const valiForm = () => {
      nextTick(()=>{
        state.refForm.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }

		//提交方法
		const save = () => {
			state['refForm'].validate(async(valid) => {
				if(valid) {
					// proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res = null
						if(state.form.id){ //修改
							res = await proxy.$API.customerContacts.update.put(state.form)
						}else{
							res = await proxy.$API.customerContacts.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data', { customerId: state.form.customerId })
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					// })
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				if(state.form.customerId){
					//把触发元素传给弹窗
					proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
					//copyDialog弹窗的ref属性
					proxy.$refs.copyDialog.showDialog()
				}
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}

		return {
			...toRefs(state),
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
      valiForm
		}
	},
})
</script>
