<!--选择司机弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'450px'"
    custom-class="popTable"
    :type="type"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <el-container
              class="mainPanel"
              :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-button
                    v-if="showAdd"
                    ref="refPopAddBtn"
                    type="primary"
                    :size="$store.state.global.uiSize"
                    @click="handleEdit(1, 'refPopAddBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                    v-if="showEdit"
                    ref="refEditBtn"
                    :type="'primary'"
                    :size="$store.state.global.uiSize"
                    :disabled="isDisabled"
                    @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>

                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  :ref-query-form="refFormBar"
                  remote-sort
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
                  @current-change="currentChange"
                  @row-click="firClick"
                  @row-dblclick="dblClick"
									@select="handleSelect"
                >
                  <template #name="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refDetail')"
                    >
                      {{ scope.row.name }}
                    </el-link>
                  </template>

					<template #gender="{ scope }">
						<span>{{ scope.row.gender == 1 ? '男' : '女' }}</span>
					</template>

                  <!--物流公司-->
                  <template #fz_logisticsName="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row, 'refLogisticsDetail')"
                    >
                      {{ scope.row.fz_logisticsName }}
                    </el-link>
                  </template>
                  <template #flag="{ scope }">
                    <el-tag
                      v-if="scope.row.flag === 0"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      停用
                    </el-tag>
                    <el-tag
                      v-if="scope.row.flag === 1"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      启用
                    </el-tag>
                    <el-tag
                      v-if="scope.row.flag === 2"
                      type="info"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      过期
                    </el-tag>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <span class="mr_5">
        <el-checkbox
          v-if="!hideAsyncUpdate"
          v-model="asyncAll"
          :true-label="1"
          :false-label="0"
          :size="$store.state.global.uiSize"
        >
          同步修改所有数据
        </el-checkbox>
      </span>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <!--新增弹窗-->
    <Edit
      ref="refEdit"
      @fetch-data="fetchData"
    />
    <detail ref="refDetail" />
    <LogisticsDetail ref="refLogisticsDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
	onBeforeMount,
} from 'vue'
import Edit from './edit'  //新增组件
import Detail from '@/views/erp/logistics/vehicle/detail'
import LogisticsDetail from '@/views/cfg/data/logistics/detail'
export default defineComponent ({
	name: "SelectLogisticsDriver",
	components: { Edit, Detail, LogisticsDetail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择司机' },
		//隐藏设置更新复选
		hideAsyncUpdate: { type: Boolean, default: true },
		//选中后更新所有数据
		asyncall: { type: Number, default: 0 },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
      type:'detail',
			asyncAll: props.asyncall,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//显示编辑那妞
			showEdit: false,
			paneSize1: 30,
			//隐藏搜索表单
			hideForm: true,
			//编辑按钮状态
			isDisabled: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '55%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '司机姓名',
					prop: 'name',
					width: 200,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.logisticsDriver.getList,
				params: {},
				columns: [
					{
						label: '司机姓名',
						prop: 'name',
						width: 120,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '物流公司',
						prop: 'fz_logisticsName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '性别',
						prop: 'gender',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '年龄',
						prop: 'age',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '身份证号',
						prop: 'code',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '联系电话',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '驾照类型',
						prop: 'driveType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '准驾车型',
						prop: 'vehicleType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '领证日期',
						prop: 'issueDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '驾驶证号',
						prop: 'drilicense',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '年检日期',
						prop: 'verificationDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '发证机构',
						prop: 'licenseAgency',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '发证日期',
						prop: 'licenseDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '过期日期',
						prop: 'invalidDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					//文本框
					{
						label: '物流公司',
						span: 8,
						prop: 'fz_logisticsName',
						type: 'popLogistics',
						fields: ['logisticsId', 'fz_logisticsName'],
						default: true,
					},
					{
						label: '司机姓名',
						span: 8,
						prop: 'name',
						type: 'input',
						max: 255,
						default: true,
					},
					{
						label: '性别',
						span: 8,
						prop: 'gender',
						type: 'select',
						default: true,
						short: 12,
						data: [
							{
								label: '男',
								value: 1,
							},
							{
								label: '女',
								value: 0,
							},
						]
					},
					{
						label: '年龄',
						type: 'rangeNum',
						span: 8,
						fields: [
							{
								prop: 'fz_age0',
								max: 3,
								append: '岁',
							},
							{
								prop: 'fz_age1',
								max: 3,
								append: '岁',
							},
						]
					},
					{
						label: '身份证号',
						span: 8,
						prop: 'code',
						max: 255,
						type: 'input',
					},
					{
						label: '联系电话',
						span: 8,
						prop: 'phone',
						max: 32,
						type: 'number',
					},
					{
						label: '驾照类型',
						span: 8,
						prop: 'driveType',
						type: 'select',
						data: [
							{
								label: 'A1',
								value: 'A1',
							},
							{
								label: 'A2',
								value: 'A2',
							},
							{
								label: 'A3',
								value: 'A3',
							},
							{
								label: 'B1',
								value: 'B1',
							},
							{
								label: 'B2',
								value: 'B2',
							},
							{
								label: 'B3',
								value: 'B3',
							},
							{
								label: 'C1',
								value: 'C1',
							},
							{
								label: 'C2',
								value: 'C2',
							},
							{
								label: 'C3',
								value: 'C3',
							},
							{
								label: 'C4',
								value: 'C4',
							},
							{
								label: '其他',
								value: '其他',
							},
						]
					},
					{
						label: '准驾车型',
						span: 8,
						prop: 'vehicleType',
						max: 255,
						type: 'input',
					},
					{
						label: '领证日期',
						span: 8,
						type: 'dateRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_issueDate0',
							},
							{
								prop: 'fz_issueDate1',
							},
						],
					},
					{
						label: '驾驶证号',
						span: 8,
						prop: 'drilicense',
						max: 32,
						type: 'input',
					},
					{
						label: '年检日期',
						type: 'dateRange',
						span: 8,
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_verificationDate0',
							},
							{
								prop: 'fz_verificationDate1',
							},
						],
					},
					{
						label: '发证机构',
						span: 8,
						prop: 'licenseAgency',
						max: 255,
						type: 'input',
					},
					{
						label: '发证日期',
						span: 8,
						type: 'dateRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_licenseDate0',
							},
							{
								prop: 'fz_licenseDate1',
							},
						],
					},
					{
						label: '过期日期',
						span: 8,
						type: 'dateRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_invalidDate0',
							},
							{
								prop: 'fz_invalidDate1',
							},
						],
					},
					{
						label: '状态',
						prop: 'flag',
						span: 8,
						type: 'select',
						short: 12,
						data: [
							{
								label: '停用',
								value: 0,
							},
							{
								label: '启用',
								value: 1,
							},
							{
								label: '过期',
								value: 2,
							},
						]
					},
					{
						label: '备注',
						span: 8,
						prop: 'remark',
						type: 'input',
						max: 255,
					},
					{
						label: '操作用户',
						span: 8,
						prop: 'fz_operateName',
            max:32,
						type: 'popUser',
						short: 12,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_addTime0',
							},
							{
								prop: 'fz_addTime1',
							},
						],
					},
				]
			},
		})

		//显示弹窗
		const showDialog = (param) => {
      if(param){
        state.tableConfig.params = Object.assign({},param)
      }
			state.visible = true
			state.loading = false
			getShowAdd()
			getShowEdit()
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selData = []
			state.selections = []
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ERP_LOGISTICS_DRIVER,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ERP_LOGISTICS_DRIVER,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行单击
		const firClick = (row) => {
			// if(row && !props.multiple){
			// 	//传入选中行和赋值的字段
			// 	emit('set-data', row)
			// 	hideDialog()
			// }
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}


    //行双击
    const dblClick = (row) => {
      if(row && !props.multiple){
      	//传入选中行和赋值的字段
      	emit('set-data', row)
      	hideDialog()
      }
      // if(props.multiple){
      //   //添加已选数据
      //   setSelData(row)
      // }
    }


		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/



    //回显选中状态
    const showChooseData = () => {
      nextTick(() => {
        const dataTable = proxy.$refs.table
        const tableData = dataTable.tableData
        tableData.forEach((item) => {
          dataTable.toggleRowSelection(item, false)
        })
        state.selData.forEach((item) => {
          const findItem = tableData.find((son) => {
            return son.id === item.id
          })
          if (findItem) {
            dataTable.toggleRowSelection(findItem, true)
          }
        })
      })
    }


		//已选数据组装
		const setSelData = (data) => {
      const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
      proxy.$refs.tableSel.toggleRowSelection(data, false)
      if (data && index === -1) {
        state.selData.push(data)


      } else {
        state.selData = state.selData.filter(item => item.id !== data.id)
      }
      if(state.selData.length > 0){
        state.isConfirm = false

      }else{
        state.selData.splice(index, 1)
      }
      showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
      proxy.$refs.table.clearSelection()
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
      if (state.selectionsTableSel.length > 0) {
        state.selectionsTableSel.forEach((item) => {
          const index = state.selData.indexOf(item)
          const index1 = state.selections.indexOf(item)
          if (index > -1) {
            state.selData.splice(index, 1)
          }
          if(index1 > -1){
            proxy.$refs.table.toggleRowSelection(item, false)
          }
        })
      }
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		//详情
		const handleDetail = (row, ref, field) => {
			if(field){
				row.id = row[field]
			}else{
				row.id = row.id
			}
			proxy.$refs[ref].showDialog(row)
		}

		/*------------------------按钮操作start-------------------------------*/
		//弹窗新增编辑
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}
		/*------------------------按钮操作end-------------------------------*/

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}
		return{
			...toRefs(state),
			handleSelect,
			handleDetail,
			tableSelselectChange,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
      firClick,
      dblClick,
			hideDialog,
		}
	},
})
</script>
