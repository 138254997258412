import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/fms/cashier/balanceAdjustment/getList`,
		name: "查询",
		get: async function (data) {
			return await http.post(this.url, data);
		}
	},
	insert: {
		url: `${config.API_URL}/fms/cashier/balanceAdjustment/insert`,
		name: "新增",
		get: async function (data) {
			return await http.post(this.url, data);
		}
	},
}
