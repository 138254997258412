<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type = "type"
    :bef-close="false"
    :width="width"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="姓名"
          :label-align="labelAlign"
        >
          {{ data.name }}
        </el-descriptions-item>
        <el-descriptions-item
            label="所属部门"
            :label-align="labelAlign"
        >
          {{ data.fz_departmentName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工号"
          :label-align="labelAlign"
        >
          {{ data.code }}
        </el-descriptions-item>
        <el-descriptions-item
            label="手机号"
            :label-align="labelAlign"
        >
          {{ data.phone }}
        </el-descriptions-item>

        <el-descriptions-item
            label="微信号"
            :label-align="labelAlign"
        >
          {{ data.wechat }}
        </el-descriptions-item>
        <el-descriptions-item
            label="电子邮箱"
            :label-align="labelAlign"
        >
          {{ data.email }}
        </el-descriptions-item>

        <el-descriptions-item
          label="职位"
          :label-align="labelAlign"
        >
          {{ data.position }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="职务"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.duties }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="职级"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.rank }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="合同类型"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.contractType }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="用工形式"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.recruit }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="入职日期"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.inductionDate }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="合同公司"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.contractCompany }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="历史工龄"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.workYear }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="工作地点"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.workPiston }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="在职状态"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.jobStatus === 1 ? '转正' : '试用' }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
          label="直属上级"
          :label-align="labelAlign"
        >
          {{ data.fz_leaderName }}
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="证件正面照"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          <el-image-->
<!--            class="imgViewItem"-->
<!--            fit="cover"-->
<!--			v-if="data.certFront"-->
<!--            hide-on-click-modal-->
<!--            :z-index="9999"-->
<!--            :src="$CONFIG.API_URL + '/' + 'api/' + data.certFront"-->
<!--            :preview-src-list="[$CONFIG.API_URL + '/' + 'api/' + data.certFront]"-->
<!--          />-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="证件反面照"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          <el-image-->
<!--            class="imgViewItem"-->
<!--            fit="cover"-->
<!--			v-if="data.certReverse"-->
<!--            hide-on-click-modal-->
<!--            :z-index="9999"-->
<!--            :src="$CONFIG.API_URL + '/' + 'api/' + data.certReverse"-->
<!--            :preview-src-list="[$CONFIG.API_URL + '/' + 'api/' + data.certReverse]"-->
<!--          />-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
    </el-card>

    <el-card
      header="个人信息"
      shadow="never"
      class="mycard mt_10"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
            label="证件类型"
            :label-align="labelAlign"
        >
          {{ data.certType }}
        </el-descriptions-item>
        <el-descriptions-item
            label="证件号"
            :label-align="labelAlign"
        >
          {{ data.certNumber }}
        </el-descriptions-item>
        <el-descriptions-item
            label="性别"
            :label-align="labelAlign"
        >
          {{ data.gender === 1 ? '男' : '女' }}
        </el-descriptions-item>
                <el-descriptions-item
                  label="出生日期"
                  :label-align="labelAlign"
                >
                  {{ data.birthday }}
                </el-descriptions-item>
        <el-descriptions-item
          label="年龄"
          :label-align="labelAlign"
        >
          {{ data.age }}
        </el-descriptions-item>
                <el-descriptions-item
                  label="联系地址"
                  :label-align="labelAlign"
                >
                  {{ data.address }}
                </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="籍贯"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.nativePlace }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="政治面貌"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.political }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
            label="最高学历"
            :label-align="labelAlign"
        >
          {{ data.education }}
        </el-descriptions-item>
        <el-descriptions-item
          label="民族"
          :label-align="labelAlign"
        >
          {{ data.nation }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="家庭住址"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.homeAddress }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="婚姻状况"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.marital }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="血型"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.bloodType }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="户口类型"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.accountType }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="户口所在地"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.accountAddress }}-->
<!--        </el-descriptions-item>-->
<!--        -->
<!--        <el-descriptions-item-->
<!--          label="开户银行"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.bank }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="开户账号"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.bankAccount }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="银行行号"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.bankCode }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="紧急联系人"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.emergencyUser }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="紧急联系电话"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.emergencyPhone }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="备注"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          {{ data.remark }}-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
<!--        <el-descriptions-item-->
<!--          label="1签名"-->
<!--          label-class-name="noTopBorder"-->
<!--          class-name="noTopBorder"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          <div class="mtf_5">-->
<!--            <el-image-->
<!--              v-for="(file, index) in imgsList"-->
<!--              :key="index"-->
<!--              fit="cover"-->
<!--              class="imgViewItem"-->
<!--              :preview-src-list="imgsPreviewList"-->
<!--              hide-on-click-modal-->
<!--              :z-index="9999"-->
<!--              :src="$CONFIG.API_URL + '/'+'api/' +file.url"-->
<!--            />-->
<!--          </div>-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
<!--        <el-descriptions-item-->
<!--          label="附件文件"-->
<!--          label-class-name="noTopBorder"-->
<!--          class-name="noTopBorder"-->
<!--          :label-align="labelAlign"-->
<!--        >-->
<!--          <div class="mtf_5">-->
<!--            <el-tag-->
<!--              v-for="(file, index) in fileList"-->
<!--              :key="index"-->
<!--              class="dtFile"-->
<!--              @click="previewFile(file)"-->
<!--            >-->
<!--              <a href="javascript:">{{ file.name }}</a>-->
<!--            </el-tag>-->
<!--          </div>-->
<!--        </el-descriptions-item>-->
      </el-descriptions>
    </el-card>
    <el-card
        header="登录信息"
        shadow="never"
        class="mycard mt_10"
    >
      <el-descriptions
          v-if="data.login === 0"
          :title="''"
          :column="1"
          :size="$store.state.global.uiSize"
          border
          class="column1"
      >
        <el-descriptions-item
            label="登录权限"
            :label-align="labelAlign"
        >
          {{ data.login === 1 ? '有' : '无' }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
          v-else
          :title="''"
          :column="3"
          :size="$store.state.global.uiSize"
          border
          class="column3"
      >
        <el-descriptions-item
            label="登录权限"
            :label-align="labelAlign"
        >
          {{ data.login === 1 ? '有' : '无' }}
        </el-descriptions-item>
        <el-descriptions-item
            label="用户角色"
            :label-align="labelAlign"
        >
          {{ data.fz_roleName }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({
	props: {
		title: { type: String, default: '用户详情'}
	},
	setup(props) {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//弹窗宽度
			width: '60%',
      type:'detail',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: props.title,
			//详情数据对象
			data: {},
			fileList: [],
			imgsList: [],
			imgsPreviewList: [],
		})
		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true
			state.fileList = []
			state.imgsList = []
			state.imgsPreviewList = []
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.user.userDetail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					getFileList(res.data.imgs, 'imgsList')
					getFileList(res.data.files, 'fileList')
				}
			}
			state.loading = false
		}
		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//组装文件列表
		const getFileList = (data, list) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state[list].push(obj)
						if (list === 'imgsList') {
							state.imgsPreviewList.push(proxy.$CONFIG.API_URL + '/' + 'api/' + item)
						}
					})
				}
			}
		}
		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
		}
		return {
			...toRefs(state),
			previewFile,
			showDialog,
			hideDialog,
		}
	},
})
</script>
