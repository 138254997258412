const DEFAULT_CONFIG = {
	//标题
	APP_NAME: process.env.VUE_APP_TITLE,

	//首页地址
	DASHBOARD_URL: "/dashboard",

	//版本号
	APP_VER: "1.0.0",

	//内核版本号
	CORE_VER: "1.0.0",

	//接口地址
	API_URL: process.env.VUE_APP_API_BASEURL,

	//websocket地址
	// WEBSOCKET_URL: 'ws://127.0.0.1:8080/dms/webSocket/',
	WEBSOCKET_URL: 'wss://www.sanyiy.com:8080/dms/webSocket/',
	// 请求超时
	TIMEOUT: 60000,

	//TokenName
	TOKEN_NAME: "Authorization",

	//Token前缀，注意最后有个空格，如不需要需设置空字符串
	TOKEN_PREFIX: "",

	//追加其他头
	HEADERS: {},

	//请求是否开启缓存
	REQUEST_CACHE: false,

	//布局 默认：default | 通栏：header | 经典：menu | 功能坞：dock
	//dock将关闭标签和面包屑栏
	LAYOUT: 'header',

	//token是否失效
	TOKEN_INVALID: false,

	//cookie存放时间
	COOKIE_TIME: 24 * 60 * 60 ,

	//菜单是否折叠
	MENU_IS_COLLAPSE: false,

	//菜单是否启用手风琴效果
	MENU_UNIQUE_OPENED: false,

	//是否开启多标签
	LAYOUT_TAGS: true,

	//语言
	LANG: 'zh-cn',

	//主题颜色
	COLOR: '',

	//组件大小 可选值 'small' 'default' 'large'
	UI_SIZE: 'small',

	//加载字样
	loadingText: '正在处理中，请稍等...',

	//消息框消失时间
	messageDuration: 3000,

	//是否默认显示搜索表单隐藏项
	SHOW_FORM_ITEMS: false,

	//是否加密localStorage, 为空不加密，可填写AES(模式ECB,移位Pkcs7)加密
	LS_ENCRYPTION: '',

	//localStorageAES加密秘钥，位数建议填写8的倍数
	LS_ENCRYPTION_key: '2XNN4K8LC0ELVWN4',

	//控制台首页默认布局
	DEFAULT_GRID: {
		//默认分栏数量和宽度 例如 [24] [18,6] [8,8,8] [6,12,6]
		layout: [18, 6],
		//小组件分布，com取值:views/home/components 文件名
		copmsList: [
			['commonUse','toDoList'],
			['time']
		]
	},
	//跳转表单标题宽度
	LABEL_WIDTH: 110,
	//表单项间距
	GUTTER: 50,
	//字体大小
	fontSize: 12, //12 14 20
}

// 如果生产模式，就合并动态的APP_CONFIG
// public/config.js
if(process.env.NODE_ENV === 'production'){
	// eslint-disable-next-line no-undef
	const APP_CONFIG = {
		//标题
		// APP_NAME: "三易科技",
		APP_NAME: process.env.VUE_APP_TITLE,
		API_URL: "/dms"
		//接口地址，如遇跨域需使用nginx代理
		//API_URL: "http://127.0.0.1:8080/dms"
	}
	Object.assign(DEFAULT_CONFIG, APP_CONFIG)
}

module.exports = DEFAULT_CONFIG
