<!--选择加工单弹窗组件-->
<template>
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="dftitle"
      :loading="loading"
      :width="width"
      :trigger-dom="triggerDom"
      :bef-close="false"
      custom-class="popTable"
			@close="hideDialog"
  >
    <el-container>
			<el-aside width="45%" class="nopadding bgwhite">
        <el-form
          ref="myForm"
          :model="form"
          :rules="rules"
          :size="$store.state.global.uiSize"
          class="left-form"
          label-suffix=":"
        >
          <div>
            <el-button
                :size="$store.state.global.uiSize"
                :disabled="disBtn"
                @click="batchEntry"
            >
              批量生成
            </el-button>
          </div>
          <div>
            <el-button
                :size="$store.state.global.uiSize"
                :disabled="disBtn"
                @click="handleImport"
            >
              批量导入
            </el-button>
          </div>
          <el-form-item label="物品名称">
            <!--						<el-input-->
            <!--							v-model="form.fz_goodsName"-->
            <!--							disabled-->
            <!--						>-->
            <!--						</el-input>-->
            <el-link
                type="primary"
                @click="handleDetail(form,'refGoodsDetail','goodsId')"
            >
              {{form.fz_goodsName}}
            </el-link>
          </el-form-item>
          <el-form-item label="入库数量">
            <!--						<el-input-->
            <!--							v-model="form.baseNum"-->
            <!--							disabled-->
            <!--						>-->
            <!--						</el-input>-->
            {{form.baseNum}}
          </el-form-item>
				</el-form>
			</el-aside>
			<el-main class="nopadding bgwhite" style="height: 300px;">
				<!--主数据表格-->
				<SyEditTable
						ref="refSnDetail"
						:config="snConfig"
						highlight-current-row
						hide-oprate
						@pop-select-detail="popSelectDetail"
						@el-select-change="selChange"
						@blur-edit="blurEdit"
						@row-click="handleRowClick"
            enter-next
						init-focus
						@load-more="loadMore"
				>
<!--					<template #append>-->
<!--						<div v-if="isLoad" style="width: 100%;text-align: center">-->
<!--							<el-link type="primary" @click="loadMore">{{ loadMsg }}</el-link>-->
<!--						</div>-->
<!--					</template>-->
				</SyEditTable>
			</el-main>
    </el-container>
    <template #footer>
      <el-popconfirm
          title="是否清空序列号？"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @confirm="snClear"
      >
        <template #reference>
          <el-button
              :size="$store.state.global.uiSize"
              :disabled="disBtn"
          >
            清空
          </el-button>
        </template>

      </el-popconfirm>

      <el-button
          :size="$store.state.global.uiSize"
          type="primary"
          :disabled="isConfirm"
          @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <ProducePlanDetail ref="refProducePlanDetail" />
    <ProduceProcessDetail ref="refProduceProcessDetail" />
    <BatchEntry :seledSn="seledSn" @set-data="setSnList" ref="refBatchEntry" />
      <import ref="refImport" @fetch-data="fetchData"/>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick, watch,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import ProducePlanDetail from "@/views/erp/produce/plan/detail.vue";
import ProduceProcessDetail from "@/views/erp/produce/process/detail.vue";
import BatchEntry from "./batchEntry.vue"
import {setColSpan} from "@/utils/window";
import {getNowDateTime} from "@/utils/date";
import Import from "./import.vue";
export default defineComponent ({
  name: "SnInput",
  components: {Import, GoodsDetail, Detail ,ProducePlanDetail,ProduceProcessDetail,BatchEntry},
  props: {
    // //是否开启多选
    // multiple: { type: Boolean, default: false },
    // //默认开启全部选择
    // choseAll: { type: Boolean, default: true },
    //传入标题
    title: { type: String, default: '序列号录入' },
  },
  emits: [ 'set-data' ],
  setup(props, { emit }){
    const { proxy } = getCurrentInstance()
    const state = reactive({
			//加载信息
			loadMsg: '加载更多...',
			isLoad: false,
      //表单项占比
      span: setColSpan(),
      //表单验证
      rules: {
        // name: [{required: true, trigger: 'change', message: '必填'}],
        // number: [{required: true, trigger: 'change', message: '必填'}],
      },
      //批量生成的sn
      snList: [],
			disInput: false, // 是否禁用录入
			disBtn: false, // 是否禁用按钮
      isDelSel: true,
      paneSize1: 25,
      paneSize: 30,
      leftShow: true,
      //隐藏搜索表单
      hideForm: true,
      //是否显示查询表单隐藏项
      showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
      //查询表单
      queryForm: {},
      refSnDetail: null,
			cacheData: [],
      refBatchEntry: null,
      //弹窗标题
      dftitle: props.title,
      //打开弹窗触发元素
      triggerDom: {},
      //切换部门角色
      curCls: 1,
      //加载动画
      loading: true,
      //弹窗显示属性
      visible: false,
      //弹窗宽度
      width: '60%',
      //确定按钮状态
      isConfirm: false,
      //主表选中数据
      selections: [],
      form: {},
      //清空按钮状态
      clearAble: true,
      //表格
      table: null,
      //传入的赋值字段
      param: null,
      //选中后赋值的字段
      params: [],
      //暂存选中行
      curRow: {},
      snConfig: {
        defaultCol: 'sn',
        initRows: 0,
        columns: [
          {
            label: '序列号',
            prop: 'sn',
            width: 260,
            edit: 'input',
            fixed: 'left',
          },
        ],
        //空白行数据
        blankItem: {
          sn: ''
        },
      },
			//暂已录入序列号
			seledSn: [],
    })

    //显示弹窗
    const showDialog = async (data,seledSn) => {
			// 打开弹窗需要用物品id把已有序列号查出来回显
			// 并判断是编辑还是单纯地查看序列号详情
			if (!data.baseNum || data.baseNum == 0) {
				proxy.$baseMessage('数量不能为空！', 'error')
				return
			}
			state.visible = true
			state.seledSn = seledSn || []
			if (data) {
				data.baseNum = data.baseNum - 0
				state.form = Object.assign({}, data)
				if (data.businessId) {//编辑--查已有序列号
					state.disInput = true //编辑不允许录入
					await fetchSnList(data)
				// }else if (data.baseNum - 0 > 1000) {
				// 	nextTick(() => {
				// 		state.refSnDetail.listLoading = true
				// 		setTimeout(() => {
				// 			state.refSnDetail.list = Array.from({length: data.baseNum}, () => Object.assign({}, state.snConfig.blankItem))
				// 			if(data.inputList){
				// 				state.refSnDetail.list = state.refSnDetail.list.map((item, index) => {
				// 					return {...item, ...(data.inputList[index] || {})}
				// 				})
				// 				disEdit(data.inputList)
				// 			}
				// 			state.refSnDetail.listLoading = false
				// 		}, 100)
				// 	})
				} else {
					state.cacheData = Array(data.baseNum).fill().map(() => ({ }))
					if(data.inputList){
						data.inputList.forEach((item,index)=>{
							state.cacheData[index] = Object.assign({},item)
						})
						disEdit(data.inputList)
					}
					let num = data.baseNum < 100 ? data.baseNum : 100
					state.snConfig.initRows = num
					nextTick(() => {
						if(data.inputList){
							state.refSnDetail.list = state.refSnDetail.list.map((item, index) => {
								return {...item, ...(data.inputList[index] || {})}
							})
						}
					})
					if(data.baseNum > 100){
						state.isLoad = true
					}
				}
			}
			state.loading = false
			autoFocus()
		}
		const autoFocus = () => {
			// 自动聚焦第一行
			nextTick(()=> {
				const sonList = state.refSnDetail.list || []
				if(sonList.length < 1 || (sonList.length > 0 && (!!sonList[0].snRulesId || sonList[0].snRulesId === 0))){
					console.log('进')
					return
				}
				const emptyIndex = state.refSnDetail.list.findIndex(item => !item.sn);
				if(emptyIndex !== -1){
					state.refSnDetail.clickTarget = 'sn'
					state.refSnDetail.rowIndex = emptyIndex
					setTimeout(() => {
						console.log(state.refSnDetail)
						const findRef = state.refSnDetail.$refs['inputsn'+emptyIndex] || []
						const inputSn = state.refSnDetail.$refs['inputsn'+emptyIndex][0]
						if (inputSn && typeof inputSn.focus === 'function') {
							console.log(inputSn)
							inputSn.focus()
						}
					}, 300)
				}
			})
		}
		const fetchSnList = async (data) => {
			if(data){
				// state.refSnDetail.initList()
				let param = Object.assign({}, data)
				console.log(param)
				const res = await proxy.$API.snInput.getList.get(param)
				if (res.code === 200 && res.data.list && res.data.list.length > 0) {
					state.refSnDetail.list = res.data.list
					disEdit(res.data.list)
				}
			}
    }
    const fetchData = (data) => {
			if(data){
				state.cacheData = data
				state.snList = data
				if(data.length > 100){
					state.refSnDetail.list = data.slice(0,100)
				}else {
					state.refSnDetail.list = data
				}
			}
    }
		const disEdit = (list) => {
			if(list.length > 0 && (!!list[0].snRulesId || list[0].snRulesId === 0)){
				state.snConfig.defaultCol = 'disabled' // 如序列号有规则id，无法手动输入
			}
		}
    //关闭弹窗
    const hideDialog = () => {
      state.visible = false
				state.form = {}
				state.selections = []
				state.snConfig.defaultCol = 'sn'
				state.refSnDetail.list = []
    }

    /*------------主表数据操作-----------------*/

		// 复选框
		const handleSelectionChange = (val) => {
			state.selections = val
		}

    // 批量生成
    const batchEntry = ()=>{
      const list = state.refSnDetail.list
			if(list.length > 0 && (list[0].snRulesId === undefined || list[0].snRulesId === null) && !!list[0].sn){
				proxy.$baseMessage('已存在手动输入数据','warning')
				return
			}
			state.refSnDetail.clickTarget = ''
      state.refBatchEntry.showDialog({
        fz_goodsName: state.form.fz_goodsName,
        baseNum: state.form.baseNum,
        goodsId: state.form.goodsId
      })
    }
    const setSnList = (data)=>{
      // 如果有进行批量生成把数据存入state.snList
      state.snList = data
			state.cacheData = data
			disEdit(data)
			if(state.snList.length > 100){
				state.refSnDetail.list = state.snList.slice(0,100)
			}else {
				state.refSnDetail.list = state.snList
			}
    }
    //确定按钮点击
    const handleConfirm = async () => {
			if(state.disBtn){
				hideDialog()
			}else {
				let data = []
				// 确定是请求保存序列号，下次点开弹窗第一步要查询序列号（物品id）回显
				if(state.snList && state.snList.length > 0){
					data = state.snList
				} else{
					data = state.refSnDetail.list
				}
				//找到编辑表格里有序列号的行，序列号列表
				let sns = data.filter((item)=>{
					return item.sn && item.sn != ''
				})
				if(sns && sns.length > 0) {
					sns.forEach((it)=>{
						it['businessNumber'] = state.form.businessNumber
						it['goodsId'] = state.form.goodsId
					})
					emit('set-data',sns)
					hideDialog()
				}else {
					proxy.$baseMessage('请录入序列号！','error')
				}
			}
    }
    //保存表单显示状态
    const handleFitShow = (bool) => {
      state.showItem = bool
    }

    //切换显示搜索栏
    const handleShowSearch = () => {
      nextTick(() => {
        state.hideForm = !state.hideForm
      })
    }

    /*--------------------主表事件start-----------------*/
    const blurEdit = (data)=>{
			if(data && data.item && data.row){
				if(data.item.prop === 'sn'){
					if(data.row.sn && data.row.sn.trim() === ''){
						data.row.sn = ''
					}
				}
			}
      const snTable = state.refSnDetail
        let sns = snTable.list.filter(sn=>sn.sn)
        if(sns){
          state.isConfirm = false
        }
    }
    const selChange = (val)=>{}
    const popSelectDetail = (val)=>{}
    //主表行点击事件
    const handleRowClick = (row) => {
      if(state.leftShow){
        // state['tableSon'].reload({submId: row.id})
      }
    }

    const handleDetail = (row, ref, field) => {
      const params = Object.assign({}, row)
      if (field) {
        params.id = row[field]
      }
      proxy.$refs[ref].showDialog(params)
    }
		//导入
		const handleImport = (ref) => {
				proxy.$refs.refImport.showDialog()
				proxy.$refs.refImport.triggerDom = proxy.$refs[ref]
		}
		const snClear = () => {
			// proxy.$baseConfirm('是否清空？',null,()=>{
			// const list = state.refSnDetail.list
			// for (let i = 0; i < list.length; i++) {
			// 	list[i] = {}
			// }
			const length = state.form.baseNum > 100 ? 100 : state.form.baseNum
			state.refSnDetail.list = Array(length).fill().map(() => ({sn: ''}))
			// state.refSnDetail.initList()
			state.snList = []
			state.cacheData = []
			state.snConfig.defaultCol = 'sn'
			setTimeout(()=>{
				autoFocus()
			},100)
			// })
		}
		const loadMore = () => {
			console.log('加载更多数据')
			if(state.refSnDetail.list && state.refSnDetail.list.length < state.form.baseNum - 0){
				const difLength = state.form.baseNum - state.refSnDetail.list.length
				const newLength = difLength > 100 ? 100 : difLength
				const newList = state.cacheData.slice(state.refSnDetail.list.length,state.refSnDetail.list.length + newLength )
				state.refSnDetail.list.push(...newList)
			}
			state.isLoad = state.form.baseNum != state.refSnDetail.list.length
		}
    return{
      ...toRefs(state),
      showDialog,
      fetchData,
      hideDialog,
      handleConfirm,
      handleFitShow,
      handleShowSearch,
      batchEntry,
      setSnList,
      blurEdit,
      selChange,
      popSelectDetail,
      handleRowClick,
      handleDetail,
			handleSelectionChange,
			handleImport,
			snClear,
			loadMore
    }
  },
})
</script>
<style scoped lang="scss">
.left-form{
	//height: 200px;
	//max-width: 250px;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 20px;
	display: flex;
  flex-direction: column;
	justify-content: space-around;
}
</style>
