import config from "@/config"
import http from "@/utils/request"

export default {
	getUserInfo: {
		url: `${config.API_URL}/wechat/login/token`,
		name: "企业微信登录获取TOKEN",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
