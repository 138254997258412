
import config from "@/config"
import http from "@/utils/request"

export default {
	getAccountList: {
		url: `${config.API_URL}/cfg/data/account/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/data/account/getAllList`,
		name: "所有数据",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/data/account/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	delAccount: {
		url: `${config.API_URL}/cfg/data/account/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insertAccount: {
		url: `${config.API_URL}/cfg/data/account/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateAccount: {
		url: `${config.API_URL}/cfg/data/account/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	detailAccount: {
		url: `${config.API_URL}/cfg/data/account/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	updateFlagAccount0: {
		url: `${config.API_URL}/cfg/data/account/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagAccount1: {
		url: `${config.API_URL}/cfg/data/account/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateDefault: {
		url: `${config.API_URL}/cfg/data/account/setDefault`,
		name: "设置默认",
		get: async function(id){
			return await http.get(this.url + '?id=' + id);
		}
	},
}
