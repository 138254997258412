//根据窗口宽度设置表单列占比
export function setColSpan(num){
	const winw = window.innerWidth
	let span = num || 6
	if(winw < 1600){
		span = 8
	}
	if(winw < 1200){
		span = 12
	}
	if(winw < 768){
		span = 24
	}
	return span
}
