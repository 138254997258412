<!--快速编辑组件-->
<template>
  <el-popover
    ref="popRef1"
    v-model:visible="popVisible"
    :width="500"
    :title="config.title || '快速编辑'"
    :placement="config.placement || 'bottom-start'"
    @show="popShow"
  >
    <el-button
      class="closePopoverBtn"
      title="关闭"
      @click="hidePop"
    >
      <el-icon><el-icon-close /></el-icon>
    </el-button>
    <template #reference>
      <el-button
        class="schbtn"
        title="打开快速编辑"
        @click.stop="showPop"
      >
        <el-icon><el-icon-edit /></el-icon>
      </el-button>
    </template>
    <div class="dropEditPanel">
      <el-container>
        <el-header class="fastHeader">
          <div class="fullWidth">
            <el-form
              ref="formRef"
              :model="form"
              class="syForm"
              :label-width="config.formConfig.labelWidth || 60"
              :rules="config.formConfig.rules || {}"
            >
              <slot
                name="formItems"
                :form-data="form"
              />
            </el-form>
            <div class="text-center mt_5">
              <el-button
                type="primary"
                :size="$store.state.global.uiSize"
                :loading="isLoading"
                @click="handleSave"
              >
                保存
              </el-button>
              <el-button
                :size="$store.state.global.uiSize"
                @click="handleReset"
              >
                重置
              </el-button>
<!--              <el-button-->
<!--                :size="$store.state.global.uiSize"-->
<!--                @click="cancel"-->
<!--              >-->
<!--                关闭-->
<!--              </el-button>-->
            </div>
          </div>
        </el-header>
        <el-main class="pd0">
          <scTable
            ref="table"
            :api-obj="config.apiObj.getList"
            :column="config.columns"
            :params="config.params || {}"
            row-key="id"
            border
            highlight-current-row
            remote-sort
            remote-filter
            hide-column-setting
            hide-setting
            :height="300"
          >
            <!--自定义列 操作列-->
            <template #operation="{ scope }">
              <el-link @click="handleEdit(scope.row)">
                编辑
              </el-link>
              <el-popconfirm
                :title="$TIPS.DELETE"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="handleDelete(scope.row)"
              >
                <template #reference>
                  <el-link>删除</el-link>
                </template>
              </el-popconfirm>
            </template>
          </scTable>
        </el-main>
      </el-container>
    </div>
  </el-popover>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	onMounted,
	getCurrentInstance,
	nextTick,
} from 'vue'
export default defineComponent ({
	name: "FastEdit",
	props: {
		//配置项
		config: { type: Object, default: () => {} },
	},
	setup(props){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			//弹出对象
			popRef: null,
			//控制弹窗的显示
			popVisible: false,
			//form表单
			form: {},
			//form组件对象
			formRef: null,
			table: null,
			rules: props.config.rules,
			isLoading: false,
		})
		const showPop = () => {
			state.popVisible = true
		}
		const hidePop = () => {
			state.popVisible = false
			state.apiObj = {}
			handleReset()
		}
		const cancel = () => {
			hidePop()
		}
		const handleSave = () => {
			state['formRef'].validate((valid) => {
				if(valid){
					proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null,async () => {
						if(props.config.apiObj){
							state.isLoading = true
							const apiObj = props.config.apiObj
							Object.assign(state.form, props.config.params)
							let res;
							if(props.config.isDict){
								if(state.form.id){
									res = await apiObj.update.post(state.form)
								}else{
									res = await apiObj.insertObj.post(state.form)
								}
							}else{
								res = await apiObj.insert.post(state.form)
							}
							state['table'].reload(props.config.params)
							state.isLoading = false
							if(res.code === 200){
								proxy.$baseMessage(res.msg,'success')
								handleReset()
							}else{
								proxy.$baseMessage(res.msg,'error')
							}
						}
					})
				}
			})
		}
		const handleReset = () => {
			state.form = {}
			state['formRef'].resetFields()
		}
		const popShow = () => {
			//显示后赋值数据接口
			state.apiObj = props.config.apiObj
		}

		const handleEdit = (row) => {
			state.form = Object.assign({},row)
		}

		const handleDelete = async (row) => {
			if(props.config.apiObj){
				const res = await props.config.apiObj.del.get(row.id)
				if(res.code === 200){
					proxy.$baseMessage(res.msg,'success')
				}else{
					proxy.$baseMessage(res.msg,'error')
				}
				state['table'].reload(props.config.params)
			}
		}

		return{
			...toRefs(state),
			handleDelete,
			handleEdit,
			popShow,
			showPop,
			hidePop,
			cancel,
			handleSave,
			handleReset,
		}
	},
})
</script>
