<!--选择售后维修单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
		type="detail"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
                    <div class="left-panel"/>
                    <div class="right-panel">
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @current-change="currentChange"
                      @row-click="firClick"
                      @data-change="fatherDataChange"
                    >
                      <template #orderNo="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refDetail')"
                        >
                          {{ scope.row.orderNo }}
                        </el-link>
                      </template>

                      <template #fz_registerNo="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refRegisterDetail', 'registerId')"
                        >
                          {{ scope.row.fz_registerNo }}
                        </el-link>
                      </template>
                      <template #fz_customerName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refCustomerDetail', 'customerId')"
                        >
                          {{ scope.row.fz_customerName }}
                        </el-link>
                      </template>
                      <template #auditStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.auditStatus === 0"
                          type="info"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未提交
                        </el-tag>
                        <el-tag
                          v-if="scope.row.auditStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
													审核中
                        </el-tag>
                        <el-tag
                          v-if="scope.row.auditStatus === 2"
                          type="danger"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          已驳回
                        </el-tag>
                        <el-tag
                          v-if="scope.row.auditStatus === 3"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          审核通过
                        </el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 4"
													type="info"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													未确认
												</el-tag>
												<el-tag
													v-if="scope.row.auditStatus === 5"
													type="success"
													class="myTag"
													:size="$store.state.global.uiSize"
												>
													已确认
												</el-tag>
                      </template>
                      <template #collectStatus="{ scope }">
                        <el-tag
                          v-if="scope.row.collectStatus === 0"
                          type="info"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          未收款
                        </el-tag>
                        <el-tag
                          v-if="scope.row.collectStatus === 1"
                          type="warning"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          部分收款
                        </el-tag>
                        <el-tag
                          v-if="scope.row.collectStatus === 2"
                          type="success"
                          class="myTag"
                          :size="$store.state.global.uiSize"
                        >
                          全部收款
                        </el-tag>
                      </template>
                      <template #isPurchase="{ scope }">
                        {{ scope.row.isPurchase === 1 ? '是' : '否' }}
                      </template>
                      <template #isMaterial="{ scope }">
                        {{ scope.row.isMaterial === 1 ? '是' : '否' }}
                      </template>
                    </scTable>
                  </el-main>
                  <sy-switch-btn
                    :text="'维修产品'"
                    :opened="leftShow"
                    bottom
                    @show-left="handleShowLeft"
                  />
                </el-container>
              </pane>
              <!--已选数据-->
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="70"
                min-size="20"
              >
                <el-container
                  class="sonCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <el-main class="nopadding">
                    <!--明细-->
                    <scTable
                      ref="tableSon"
                      :api-obj="tableSonConfig.apiObj"
                      :column="tableSonConfig.columns"
                      :params="tableSonConfig.params"
                      row-key="id"
                      border
                      remote-sort
                    >
                      <template #fz_goodsName="{ scope }">
                        <el-link
                          type="primary"
                          @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
                        >
                          {{ scope.row.fz_goodsName }}
                        </el-link>
                      </template>
                    </sctable>
                  </el-main>
                </el-container>
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
    <Detail ref="refDetail" />
    <GoodsDetail ref="refGoodsDetail" />
    <CustomerDetail ref="refCustomerDetail" />
    <RegisterDetail ref="refRegisterDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/produce/process/detail'
import GoodsDetail from "@/views/cfg/data/goods/detail";
import CustomerDetail from "@/views/cfg/data/customer/detail";
import RegisterDetail from "@/views/erp/uphold/register/detail";
export default defineComponent ({
	name: "SelectUpholdRepair",
	components: { Detail, GoodsDetail, CustomerDetail, RegisterDetail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择售后维修单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '维修单号',
					prop: 'orderNo',
					width: 200,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				//数据表格数据源对象
				apiObj: proxy.$API.upholdRepair.getList,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '服务单号',
						prop: 'orderNo',
						width: 200,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '报修登记号',
						prop: 'fz_registerNo',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '客户',
						prop: 'fz_customerName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '联系人',
						prop: 'contacts',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '联系电话',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '联系地址',
						prop: 'address',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '服务主题',
						prop: 'theme',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '服务类型',
						prop: 'stype',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '服务日期',
						prop: 'repairDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '负责人',
						prop: 'fz_managerName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '提醒时间',
						prop: 'remindTime',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '审核状态',
						prop: 'auditStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '是否申购',
						prop: 'isPurchase',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '是否领料',
						prop: 'isMaterial',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '合计费用',
						prop: 'totalAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '收款状态',
						prop: 'collectStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已收费用',
						prop: 'collectAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未收费用',
						prop: 'uncollectAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '服务内容',
						prop: 'content',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '产品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '产品规格',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '产品型号',
						prop: 'fz_goodsModel',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '维修数量',
						prop: 'quantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '故障说明',
						prop: 'fault',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '服务单号',
						span: 8,
						prop: 'orderNo',
						type: 'input',
						max: 32,
						default: true,
					},
					{
						label: '报修登记单号',
						span: 8,
						prop: 'fz_registerNo',
						type: 'input',
						max: 32,
						default: true,
					},
					{
						label: '客户',
						span: 8,
						prop: 'fz_customerName',
						type: 'popCustomer',
						default: true,
						fields: ['customerId', 'fz_customerName'],
					},
					{
						label: '联系人',
						span: 8,
						prop: 'contacts',
						type: 'input',
						max: 32,
					},
					{
						label: '联系电话',
						span: 8,
						prop: 'phone',
						type: 'number',
						max: 32,
					},
					{
						label: '联系地址',
						span: 8,
						prop: 'address',
						type: 'input',
						max: 255,
					},
					{
						label: '服务主题',
						span: 8,
						prop: 'theme',
						type: 'input',
						max: 255,
					},
					{
						label: '服务类型',
						span: 8,
						prop: 'stype',
						typeCode: 'erp_uphold-stype',
						type: 'selectDicts',
					},
					{
						label: '服务日期',
						span: 8,
						type: 'dateRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_repairDate0',
							},
							{
								prop: 'fz_repairDate1',
							},
						],
					},
					{
						label: '负责人',
						span: 8,
						prop: 'fz_managerName',
            type: 'popUser',
						short: 12,
            max:32,
						fields: ['managerId', 'fz_managerName'],
					},
					{
						label: '提醒时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_remindTime0',
							},
							{
								prop: 'fz_remindTime1',
							},
						],
					},
					{
						label: '服务内容',
						span: 8,
						prop: 'content',
						type: 'input',
						max: 255,
					},
					{
						label: '备注',
						span: 8,
						prop: 'remark',
						type: 'input',
						max: 255,
					},
					{
						label: '审核状态',
						span: 8,
						prop: 'auditStatus',
						type: 'select',
						short: 12,
						data: [
							{
								label: '未提交',
								value: 0,
							},
							{
								label: '审核中',
								value: 1,
							},
							{
								label: ' 已驳回',
								value: 2,
							},
							{
								label: ' 审核通过',
								value: 3,
							},
						]
					},
					{
						label: '是否申购',
						span: 8,
						prop: 'isPurchase',
						type: 'select',
						short: 12,
						data: [
							{
								label: '否',
								value: 0,
							},
							{
								label: '是',
								value: 1,
							},
						]
					},
					{
						label: '是否领料',
						span: 8,
						prop: 'isMaterial',
						type: 'select',
						short: 12,
						data: [
							{
								label: '否',
								value: 0,
							},
							{
								label: '是',
								value: 1,
							},
						]
					},
					{
						label: '合计费用',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_totalAmount0',
								max: 20,
								append: '元',
							},
							{
								prop: 'fz_totalAmount1',
								max: 20,
								append: '元',
							}
						],
					},
					{
						label: '已收费用',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_collectAmount0',
								max: 20,
								append: '元',
							},
							{
								prop: 'fz_collectAmount1',
								max: 20,
								append: '元',
							}
						],
					},
					{
						label: '未收费用',
						span: 8,
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_uncollectAmount0',
								max: 20,
								append: '元',
							},
							{
								prop: 'fz_uncollectAmount1',
								max: 20,
								append: '元',
							}
						],
					},
					{
						label: '收款状态',
						span: 8,
						prop: 'collectStatus',
						type: 'select',
						short: 12,
						data: [
							{
								label: '未收款',
								value: 0,
							},
							{
								label: '部分收款',
								value: 1,
							},
							{
								label: '全部收款',
								value: 2,
							},
						]
					},
					{
						label: '操作员',
						span: 8,
						prop: 'fz_operateName',
						type: 'popUser',
            max:32,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'fz_addTime0',
							},
							{
								prop: 'fz_addTime1',
							},
						],
					},
				],
			},
		})

		//显示弹窗
		const showDialog = (param) => {
			state.visible = true
			state.tableConfig.params = Object.assign({}, param)
			state.selections = []
			state.selData = []
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				state.isConfirm = false
			}else{
				state.isDisabled = true
				state.isConfirm = true
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行单击
		const firClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				state['tableSon'].reload({ orderId: row.id })
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				//设置第一行选中
				// state['table'].setCurrentRow(res.data.list[0])
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.upholdRepair.getRepairProductList
				//传入父级ID
				state.tableSonConfig.params = { orderId: res.data.list[0].id }
				//根据主数据加载子数据
				handleRowClick(res.data.list[0])
			}else{
				proxy.$refs.tableSon.reload({ orderId: -1 })
			}

		}

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
      if (state.selectionsTableSel.length > 0) {
        state.selectionsTableSel.forEach((item) => {
          const index = state.selData.indexOf(item)
          const index1 = state.selections.indexOf(item)
          if (index > -1) {
            state.selData.splice(index, 1)
          }
          if(index1 > -1){
            proxy.$refs.table.toggleRowSelection(item, false)
          }
        })
      }
		}



    //回显选中状态
    const showChooseData = () => {
      nextTick(() => {
        const dataTable = proxy.$refs.table
        const tableData = dataTable.tableData
        tableData.forEach((item) => {
          dataTable.toggleRowSelection(item, false)
        })
        state.selData.forEach((item) => {
          const findItem = tableData.find((son) => {
            return son.id === item.id
          })
          if (findItem) {
            dataTable.toggleRowSelection(findItem, true)
          }
        })
      })
    }


    //已选数据组装
		const setSelData = (data) => {
      const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
      proxy.$refs.tableSel.toggleRowSelection(data, false)
      if (data && index === -1) {
        state.selData.push(data)


      } else {
        state.selData = state.selData.filter(item => item.id !== data.id)
      }
      if(state.selData.length > 0){
        state.isConfirm = false

      }else{
        state.selData.splice(index, 1)
      }
      showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
      proxy.$refs.table.clearSelection()
		}

		return{
			...toRefs(state),
			clearSelections,
			delSelData,
			tableSelselectChange,
			addToRight,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
      firClick,
			hideDialog,
		}
	},
})
</script>
