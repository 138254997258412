import config from "@/config";
import http from "@/utils/request";

export default {
    //现金流量初始化 根据操作者的shopId获取对应的现金流对象的信息
    getListByShopId: {
        url: `${config.API_URL}/cfg/finance/CashFlow/getListByShopId`,
        name: "列表",
        get: async function (data = {}) {
            return await http.get(this.url, data);
        }
    },
    //现金流量初始化，根据id和shopId修改对应的现金流的信息
    beginUpdate: {
        url: `${config.API_URL}/cfg/finance/CashFlow/beginUpdate`,
        name: "修改",
        get: async function (data = {}) {
            return await http.put(this.url, data);
        }
    }
}
