<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
      ref="refScDialog"
      v-model="visible"
      :title="title"
      :loading="loading"
      :width="width"
      :height="'450px'"
      :before-close="beforeClose"
      :trigger-dom="triggerDom"
      :close-on-click-modal="false"
      @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
          v-if="!form.id"
          ref="copyBtn"
          class="ml10"
          :size="$store.state.global.uiSize"
          @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
          ref="refForm"
          :model="form"
          :label-width="labelWidth"
          size="small"
          :rules="rules"
      >
        <!--基本信息-->
        <el-card
            shadow="never"
            class="mycard form"
        >
          <!--自定义car头部-->
          <template #header>
            <span>基本信息</span>
            <!--复制数据按钮-->
            <el-button
                v-if="!form.id"
                ref="copyBtn"
                class="ml10"
                :size="$store.state.global.uiSize"
                @click="copyData"
            >
              复制信息
            </el-button>
          </template>
          <el-row :gutter="$CONFIG.GUTTER">
            <el-col :span="span">
              <el-form-item
                  prop="name"
                  label="物品名称"
              >
                <el-input
                    v-model.trim="form.name"
                    maxlength="255"
                    clearable
                    @input="(val) => handleInput(val, 'name')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                  label="物品类别"
                  prop="fz_classifyName"
                  :rules="requireClassfiy ? [{ required: true, message: '必填', trigger: 'blur' }] : []"
              >
                <sc-table-select
                    v-model="form.fz_classifyName"
                    :api-obj="$API.classify.getList"
                    :classify="true"
                    :params="{flag: 1,}"
                    hide-pagination
                    :props="{ label: 'name', value: 'id', keyword: 'name' }"
                    :table-width="390"
                    @change="(val) => handleSelChange(val, 'classifyId')"
                    @clear="(val) => handleCancel()"
                >
                  <el-table-column
                      prop="name"
                      label="名称"
                      width="150"
                  />
                  <el-table-column
                      prop="code"
                      label="编码"
                      width="150"
                  />
                </sc-table-select>
              </el-form-item>
            </el-col>
            <el-col :span="span" v-if="requireCode">
              <el-form-item
                  prop="codeed"
                  label="物品编码"
              >
                <el-input
                    v-model="form.codes"
                    v-if="goodsRelClassify"
                    maxlength="32"
                    style="width: 30%"
                    clearable
                    disabled
                />
                <el-input
                    v-model="form.codeed"
                    maxlength="32"
                    :style="{width:goodsRelClassify?'69%':'100%'}"
                    clearable
                    @blur="handleCodeBlur"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span" v-else>
              <el-form-item
                  label="物品编码"
              >
                <el-input
                    v-model="form.codes"
                    v-if="goodsRelClassify"
                    maxlength="32"
                    style="width: 30%"
                    clearable
                    disabled
                />
                <el-input
                    v-model="form.codeed"
                    maxlength="32"
                    :style="{width:goodsRelClassify?'69%':'100%'}"
                    clearable
                    @blur="handleCodeBlur"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                  prop="stype"
                  label="物品类型"
              >
                <el-select
                    v-model="form.stype"
                >
                  <el-option
                      label="原材料"
                      :value="0"
                  />
                  <el-option
                      label="半成品"
                      :value="1"
                  />
                  <el-option
                      label="成品"
                      :value="2"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="span">
              <el-form-item
                  label="所属仓库"
                  prop="depot"
              >
                <SelectDepot
                    v-model="form.depot"
                    @set-value="(val)=>setValue(val,'depot')"
                    @clear="selClear('depot')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                  label="货位"
                  prop="fz_freightName"
              >
                <el-select
                    v-model="form.fz_freightName"
                    value-key="id"
                    :disabled="!form.fz_depotName"
                    @change="(val)=>setValue(val,'freight')"
                    @clear="selClear('freight')"
                >
                  <el-option
                      v-for="position in positionList"
                      :key="position.id"
                      :value="position"
                      :label="position.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                  prop="spec"
                  label="规格型号"
              >
                <el-input
                    v-model="form.spec"
                    maxlength="250"
                    clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="span">
              <el-form-item
                  prop="productType"
                  label="生产方式"
              >
                <el-select
                    v-model="form.productType"
                    clearable
                >
                  <el-option
                      label="采购"
                      :value="'1'"
                  />
                  <el-option
                      label="委外"
                      :value="'2'"
                  />
                  <el-option
                      label="自制"
                      :value="'3'"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="span">
              <el-form-item
                  prop="purpose"
                  label="用途"
              >
                <el-input
                    v-model="form.purpose"
                    maxlength="255"
                    clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="span">
              <el-form-item
                  prop="remark"
                  label="备注"
              >
                <el-input
                    v-model="form.remark"
                    type="textarea"
                    maxlength="255"
                    rows="1"
                />
              </el-form-item>
            </el-col>

            <el-col :span="span">
              <el-form-item
                  prop="inventoryCap"
                  label="库存上限"
              >
                <el-input
                    v-model="form.inventoryCap"
                    maxlength="15"
                    clearable
                    @input="(val) => handleNumIpt(val, 'inventoryCap')"
                    @blur="inventoryBlur('inventoryCap')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                  prop="inventoryLower"
                  label="库存下限"
              >
                <el-input
                    v-model="form.inventoryLower"
                    maxlength="15"
                    clearable
                    @input="(val) => handleNumIpt(val, 'inventoryLower')"
                    @blur="inventoryBlur('inventoryLower')"
                />
              </el-form-item>
            </el-col>
            <!--            <el-col :span="span">-->
            <!--              &lt;!&ndash;供应商&ndash;&gt;-->
            <!--              <el-form-item-->
            <!--                prop="fz_supplierName"-->
            <!--                label="供应商"-->
            <!--              >-->
            <!--                <el-input-->
            <!--                  ref="refSupplierInput"-->
            <!--                  v-model="form.fz_supplierName"-->
            <!--                  clearable-->
            <!--                  placeholder="请选择"-->
            <!--                  @blur="inputBlur('supplier')"-->
            <!--                  @clear="inputClear('supplier')"-->
            <!--                >-->
            <!--                  <template #append>-->
            <!--                    <el-icon-->
            <!--                      @click="popSelect('refSupplierInput',['supplierIds','fz_supplierName'], 2, 'refSelectSupplier')"-->
            <!--                    >-->
            <!--                      <el-icon-search />-->
            <!--                    </el-icon>-->
            <!--                  </template>-->
            <!--                </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="span">-->
            <!--              &lt;!&ndash;客户&ndash;&gt;-->
            <!--              <el-form-item-->
            <!--                prop="fz_customerName"-->
            <!--                label="客户"-->
            <!--              >-->
            <!--                <el-input-->
            <!--                  ref="refCustomerInput"-->
            <!--                  v-model="form.fz_customerName"-->
            <!--                  clearable-->
            <!--                  placeholder="请选择"-->
            <!--                  @blur="inputBlur('customer')"-->
            <!--                  @clear="inputClear('customer')"-->
            <!--                >-->
            <!--                  <template #append>-->
            <!--                    <el-icon-->
            <!--                      @click="popSelect('refCustomerInput',['customerIds','fz_customerName'], 1, 'refSelectCustomer')"-->
            <!--                    >-->
            <!--                      <el-icon-search />-->
            <!--                    </el-icon>-->
            <!--                  </template>-->
            <!--                </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="span">-->
            <!--              &lt;!&ndash;客户&ndash;&gt;-->
            <!--              <el-form-item-->
            <!--                prop="fz_replaceGoodsName"-->
            <!--                label="可代替物品"-->
            <!--              >-->
            <!--                <el-input-->
            <!--                  ref="refReplaceGoodsInput"-->
            <!--                  v-model="form.fz_replaceGoodsName"-->
            <!--                  clearable-->
            <!--                  placeholder="请选择"-->
            <!--                  @blur="inputBlur('replaceGoods')"-->
            <!--                  @clear="inputClear('replaceGoods')"-->
            <!--                >-->
            <!--                  <template #append>-->
            <!--                    <el-icon-->
            <!--                      @click="popSelect('refReplaceGoodsInput',['replaceGoodsIds','fz_replaceGoodsName'], 2, 'refSelectGoods')"-->
            <!--                    >-->
            <!--                      <el-icon-search />-->
            <!--                    </el-icon>-->
            <!--                  </template>-->
            <!--                </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->

            <!--						<el-col :span="span">-->
            <!--							<el-form-item-->
            <!--								prop="useByDate"-->
            <!--								label="保质期"-->
            <!--							>-->
            <!--								<el-input-->
            <!--									v-model="form.useByDate"-->
            <!--									maxlength="32"-->
            <!--									clearable-->
            <!--									@input="(val) => handleInput(val, 'useByDate')"-->
            <!--								/>-->
            <!--							</el-form-item>-->
            <!--						</el-col>-->
            <el-col :span="span">
              <el-form-item
                  prop="checkMethod"
                  label="检验方式"
              >
                <el-select
                    v-model="form.checkMethod"
                >
                  <el-option
                      label="未启用"
                      :value="0"
                  />
                  <el-option
                      label="全检"
                      :value="1"
                  />
                  <el-option
                      label="抽检"
                      :value="2"
                  />
                  <el-option
                      label="免检"
                      :value="3"
                  />
                </el-select>
              </el-form-item>
            </el-col>

<!--            <el-col :span="span">-->
<!--              <el-form-item-->
<!--                  prop="goodsGroup"-->
<!--                  label="组合件"-->
<!--              >-->
<!--                <el-radio-group v-model="form.goodsGroup" @change="goodsGroupChange">-->
<!--                  <el-radio :label="1">-->
<!--                    是-->
<!--                  </el-radio>-->
<!--                  <el-radio :label="0">-->
<!--                    否-->
<!--                  </el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <!--			  <el-col :span="span">-->
            <!--				  <el-form-item-->
            <!--					  prop="serialNumber"-->
            <!--					  label="启用序列号管理"-->
            <!--				  >-->
            <!--					  <el-select-->
            <!--						  v-model="form.serialNumber"-->
            <!--						  clearable-->
            <!--					  >-->
            <!--						  <el-option-->
            <!--							  label="否"-->
            <!--							  :value="'0'"-->
            <!--						  />-->
            <!--						  <el-option-->
            <!--							  label="是"-->
            <!--							  :value="'1'"-->
            <!--						  />-->
            <!--					  </el-select>-->
            <!--				  </el-form-item>-->
            <!--			  </el-col>-->

            <!--            <el-col v-if="form.size"-->
            <!--                    :span="span"-->
            <!--            >-->
            <!--              <el-form-item-->
            <!--                prop="bastardSize"-->
            <!--                label="非标准尺寸"-->
            <!--              >-->
            <!--                <el-radio-group v-model="form.bastardSize">-->
            <!--					<el-radio :label="1">-->
            <!--						是-->
            <!--					</el-radio>-->
            <!--                  <el-radio :label="0">-->
            <!--                    否-->
            <!--                  </el-radio>-->
            <!--                </el-radio-group>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--						<el-col :span="span">-->
            <!--							<el-form-item-->
            <!--								prop="batchManage"-->
            <!--								label="批次管理"-->
            <!--							>-->
            <!--								<el-checkbox-->
            <!--									v-model="form.batchManage"-->
            <!--									:true-label="1"-->
            <!--									:false-label="0"-->
            <!--								/>-->
            <!--							</el-form-item>-->
            <!--						</el-col>-->
            <el-col :span="span">
              <el-form-item
                  prop="batchManage"
                  label="批次管理"
              >
                <el-select
                    v-model="form.batchManage"
                >
                  <el-option
                      label="是"
                      :value="1"
                  />
                  <el-option
                      label="否"
                      :value="0"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                  prop="serialNumber"
                  label="序列号管理"
              >
                <el-select
                    v-model="form.serialNumber"
                >
                  <el-option
                      label="是"
                      :value="1"
                  />
                  <el-option
                      label="否"
                      :value="0"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!--						<el-col :span="span">-->
            <!--							<el-form-item-->
            <!--								prop="serialNumber"-->
            <!--								label="序列号管理"-->
            <!--							>-->
            <!--								<el-checkbox-->
            <!--									v-model="form.serialNumber"-->
            <!--									:true-label="1"-->
            <!--									:false-label="0"-->
            <!--								/>-->
            <!--							</el-form-item>-->
            <!--						</el-col>-->

            <el-col :span="span">
              <el-form-item
                  prop="stockIntroduction"
                  label="出库顺序"
              >
                <el-select
                    v-model="form.stockIntroduction"
                    clearable
                >
                  <el-option
                      label="先进先出"
                      :value="0"
                  />
                  <el-option
                      label="先到期先出"
                      :value="1"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item label="物品舍入策略">
                <el-radio-group
                    v-model="form.materialRoundingPolicy"
                    size="small"
                >
                  <el-radio
                      :label="1"
                  >
                    精确舍入策略
                  </el-radio>
                  <el-radio
                      :label="2"
                  >
                    整数舍入策略
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="span" v-for="item in dataList" :key="item.id">
              <el-form-item :prop="item.value" :label="item.name" :rules="item.rules">
                <el-input v-model="form[item.value]" maxlength="100" clearable rows="1" v-if="item.stype == 0"/>
                <el-input v-model="form[item.value]" type="number" maxlength="100" clearable rows="1"  v-if="item.stype == 1"/>
                <el-date-picker
                    v-if="item.stype == 2"
                    v-model="form[item.value]"
                    type="date"
                    value-format="YYYY-MM-DD"
                    clearable
                />
                <el-select
                    v-if="item.stype == 3"
                    v-model="form[item.value]"
                    clearable
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(option, index) in item.optionValueList"
                      :key="index"
                      :label="option"
                      :value="option"
                  />
                </el-select>
                <el-select
                    v-if="item.stype == 4"
                    v-model="form[item.value]"
                    clearable
                    multiple
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(option, index) in item.optionValueList"
                      :key="index"
                      :label="option"
                      :value="option"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>


        <el-card
            shadow="never"
            class="mycard form mt_2"
            header="计量单位"
        >
          <el-row :gutter="$CONFIG.GUTTER">
            <el-col :span="span">
              <el-form-item
                  prop="mainUnit"
                  label="主单位"
              >
                <el-input
                    v-model.trim="form.mainUnit"
                    maxlength="32"
                    clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="$CONFIG.GUTTER">
            <el-col :span="span">
              <el-form-item
                  prop="assistUnit1"
                  label="辅助单位1"
              >
                <el-input
                    v-model="form.assistUnit1"
                    maxlength="32"
                    clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span" v-if="!form.bastardSize">
              <el-form-item
                  prop="assistRatio1"
                  label="换算比率1"
              >
                <el-input
                    v-model="form.assistRatio1"
                    maxlength="32"
                    clearable
                    @input="(val) => handleNumIpt(val, 'assistRatio1')"
                >
                  <template
                      v-if="form.assistUnit1"
                      #prepend
                  >
                    1{{ form.assistUnit1 }}=
                  </template>
                  <template
                      v-if="form.mainUnit"
                      #append
                  >
                    {{ form.mainUnit }}
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="$CONFIG.GUTTER">
            <el-col :span="span">
              <el-form-item
                  prop="assistUnit2"
                  label="辅助单位2"
              >
                <el-input
                    v-model="form.assistUnit2"
                    maxlength="32"
                    clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span" v-if="!form.bastardSize">
              <el-form-item
                  prop="assistRatio2"
                  label="换算比率2"
              >
                <el-input
                    v-model="form.assistRatio2"
                    maxlength="32"
                    clearable
                    @input="(val) => handleNumIpt(val, 'assistRatio2')"
                >
                  <template
                      v-if="form.assistUnit2"
                      #prepend
                  >
                    1{{ form.assistUnit2 }}=
                  </template>
                  <template
                      v-if="form.mainUnit"
                      #append
                  >
                    {{ form.mainUnit }}
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="$CONFIG.GUTTER">
            <el-col :span="span">
              <el-form-item
                  prop="assistUnit3"
                  label="辅助单位3"
              >
                <el-input
                    v-model="form.assistUnit3"
                    maxlength="32"
                    clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span" v-if="!form.bastardSize">
              <el-form-item
                  prop="assistRatio3"
                  label="换算比率3"
              >
                <el-input
                    v-model="form.assistRatio3"
                    maxlength="32"
                    clearable
                    @input="(val) => handleNumIpt(val, 'assistRatio3')"
                >
                  <template
                      v-if="form.assistUnit3"
                      #prepend
                  >
                    1{{ form.assistUnit3 }}=
                  </template>
                  <template
                      v-if="form.mainUnit"
                      #append
                  >
                    {{ form.mainUnit }}
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.BOM_SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm(true)"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
          :title="$TIPS.CONFIRM_RESET"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
      <el-popconfirm
          :title="$TIPS.CONFIRM_CLOSE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @confirm="handleConfirm"
      >
        <template #reference>
          <!--          <el-button-->
          <!--            :size="$store.state.global.uiSize"-->
          <!--          >-->
          <!--            关闭-->
          <!--          </el-button>-->
        </template>
      </el-popconfirm>
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
        ref="copyDialog"
        :dataGoods="true"
        :config="copyConfig"
        @set-form="setFormData"
        remote-sort
        :ref-query-form="refFormBar"
    >
      <!--自定义单元格显示 #{字段名称} -->
      <template #name="{ scope }">
        <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refGoodsDetail', 'id')"
        >
          {{ scope.row.name }}
        </el-link>
      </template>

      <template #size="{ scope }">
        <el-tag
            v-if="scope.row.size === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          否
        </el-tag>
        <el-tag
            v-if="scope.row.size === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          是
        </el-tag>
      </template>
      <template #fasteners="{ scope }">
        <el-tag
            v-if="scope.row.fasteners === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          否
        </el-tag>
        <el-tag
            v-if="scope.row.fasteners === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          是
        </el-tag>
      </template>

      <template #batchManage="{ scope }">
        <el-tag
            v-if="scope.row.batchManage === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          否
        </el-tag>
        <el-tag
            v-if="scope.row.batchManage === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          是
        </el-tag>
      </template>
      <template #bastardSize="{ scope }">
        <el-tag
            v-if="scope.row.bastardSize === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          否
        </el-tag>
        <el-tag
            v-if="scope.row.bastardSize === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          是
        </el-tag>
      </template>


      <template #goodsGroup="{ scope }">
        <el-tag
            v-if="scope.row.goodsGroup === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          否
        </el-tag>
        <el-tag
            v-if="scope.row.goodsGroup === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          是
        </el-tag>
      </template>

      <template #flag="{ scope }">
        <el-tag
            v-if="scope.row.flag === 1"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          启用
        </el-tag>
        <el-tag
            v-if="scope.row.flag === 0"
            type="danger"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          停用
        </el-tag>
      </template>


      <template #stype="{ scope }">
        <span v-if="scope.row.stype == 0">原材料</span>
        <span v-if="scope.row.stype == 1">半成品</span>
        <span v-if="scope.row.stype == 2">成品</span>
      </template>

      <template #examClass="{ scope }">
        <span v-if="scope.row.examClass === '1'">原材料验收</span>
        <span v-if="scope.row.examClass === '2'">焊材验收</span>
        <span v-if="scope.row.examClass === '3'">外购验收</span>
      </template>

    </SyCopyDialog>
    <GoodsDetail ref="refGoodsDetail" />
    <GoodsPrice
        ref="refGoodsPrice"
        @set-price="setPriceData"
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs,onMounted} from 'vue'
import Pinyin from "@/utils/ChinesePY";
import {setColSpan} from "@/utils/window";
import GoodsDetail from "@/views/cfg/data/goods/detail.vue";
import {resetNum} from "@/utils/validate";
import GoodsPrice from "@/views/cfg/data/goods/goodsPrice.vue"; //resetZnum返回正整数的方法
export default defineComponent({
  components: {GoodsPrice, GoodsDetail},
  emits: ['fetch-data'],
  setup(props, {emit}) {
    const {proxy} = getCurrentInstance()
    const validateCode = (rule, value, callback) => {
      if (state.codeMsg) {
        callback(new Error(state.codeMsg))
      } else {
        callback()
      }
    }
    const state = reactive({
      refFormBar:null,
      span: setColSpan(),
      multiples: false,
      //触发元素
      triggerDom: {},
      //弹窗对象
      refScDialog: null,
      //加载动画
      loading: true,
      //表单组件 dom
      refForm: null,
      //当前弹窗组件 dom
      refDialog: null,
      //提交加载动画
      subLoading: false,
      //全屏显示
      fullScreen: false,
      //表单label宽度
      labelWidth: 90,
      visibles: false,
      positionList:[],
      //弹窗显示属性
      visible: false,
      requireCode: false, // 物品编码是否必填
      goodsRelClassify: false,
      requireClassfiy: false,
			isAutoGenerateCode: false, // 是否自动生成编码
			cacheAutoCode: '', // 缓存自动生成编码
      //弹窗宽度
      width: 1250,
      //弹窗标题
      title: '物品新增',
      //表单当前数据
      form: {
        fasteners: 0,
        size: 0,
        batchManage: 0,
        serialNumber: 0,
        bastardSize: 0,
        goodsGroup: 0,
        checkMethod: 0,
        stype:0,
        materialRoundingPolicy: 1,
      },
      //暂存原始数据
      orgForm: {},
      //表单验证规则 trigger: 可选'blur' 'change' 'focus'
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '必填'},
        ],
        stype:[
          {required: true, trigger: 'blur', message: '必填'},
        ],
        parent:[
          {required: true, trigger: 'blur', message: '必填'},
        ],
        mainUnit:[
          {required: true, trigger: 'blur', message: '必填'},
        ],
        codeed: [
          {required: true, trigger: 'blue', message: '必填'},
          {validator: validateCode, trigger: 'change'},
        ],
        productType: [{required: true, message: '必填'}],
      },
      //复制信息配置
      copyConfig: {
        //数据接口对象
        apiObj: proxy.$API.goods.getList,
        //弹窗标题
        title:'复制物品信息',
        //查询匹配的字段
        prop: 'name',
        //表格显示列
        columns: [
          {
            label: '物品名称',
            prop: 'name',
            width: 180,
            sortable: 'custom',
            fixed: 'left',
          },
          {
            label: '物品编码',
            prop: 'code',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '物品类型',
            prop: 'stype',
            width: 120,
            sortable: 'custom',
            filters: [
              {text: '原材料', value: '0'},
              {text: '半成品', value: '1'},
              {text: '成品', value: '2'},

            ]
          },
          {
            label: '规格型号',
            prop: 'spec',
            width: 120,
          },
          //
          // {
          // 	label: '材质',
          // 	prop: 'material',
          // 	width: 170,
          // },

          {
            label: '所属仓库',
            prop: 'fz_depotName',
            width: 110,
          },

          {
            label: '主单位',
            prop: 'mainUnit',
            width: 110,
          },
          {
            label: '辅助单位',
            prop: 'assistUnit1',
            width: 110,
          },
          {
            label: '换算比率',
            prop: 'assistRatio1',
            width: 110,
          },

          // {
          // 	label: '审核状态',
          // 	prop: 'auditStatus',
          // 	width: 120,
          // 	sortable: 'custom',
          // },

          {
            label: '库存上限',
            prop: 'inventoryCap',
            width: 110,
          },
          {
            label: '库存下限',
            prop: 'inventoryLower',
            width: 110,
          },


          // {
          // 	label: '启用状态',
          // 	prop: 'flag',
          // 	width: 120,
          // 	sortable: 'custom',
          // 	filters: [
          // 		{text: '正常', value: '1'},
          // 		{text: '停用', value: '0'},
          // 	]
          // },

          // {
          // 	label: '自动停用日期',
          // 	prop: 'deactivationDate',
          // 	width: 200,
          // },

          {
            label: '用途',
            prop: 'purpose',
            width: 180,
          },
          {
            label: '验收类别',
            prop: 'examClass',
            sortable: 'custom',
            width: 110,
          },
          {
            label: '批次管理',
            prop: 'batchManage',
            width: 110,
            disable: true,
          },

          // {
          // 	label: '非标准尺寸',
          // 	prop: 'bastardSize',
          // 	width: 120,
          // 	disable: true,
          // },
          {
            label: '组合件',
            prop: 'goodsGroup',
            width: 110,
            disable: true,
          },

          {
            label: '备注',
            prop: 'remark',
            width: 180,
          },
        ]
      },
      //赋值字段
      fields: [],
    })


    onMounted(async () => {
      await getSetupData()
    })

    const getSetupData = async () => {
      const res = await proxy.$API.setup.getList.get()
      if (res.code === 200 && res.data) {
        state.goodsRelClassify = res.data.goodsAndClassifyRelevancy === 0?true:false
        state.requireClassfiy = res.data.goodsAndClassifyRelevancy === 0?true:false
        state.requireCode = res.data.goodsCode === 0?true:false
				state.isAutoGenerateCode = res.data.isAutoGenerateCode == 0 ? false : true;
				// 关联:选择类别再回填编码;不关联:进入页面就回填编码,选择类别不改变
				if(!state.goodsRelClassify){
						await getAutoCode()
				}
      }
    }

		const getAutoCode = async (classifyId=-1) => {
				if(state.isAutoGenerateCode){
						const res = await proxy.$API.setup.generateGoodsCode.get(classifyId)
						if(res.code === 200){
								state.form['codeed'] = res.data
								if(state.goodsRelClassify){
										state.cacheAutoCode = state.form.codes + ',' + res.data
								}else {
										state.cacheAutoCode = res.data
								}
						}
				}
		}

    //显示弹窗
    const showDialog = async (row) => {
      state.visible = true
      if (JSON.stringify(row) == '{}') { //新增
        state.title="新增物品"
        state.orgForm = Object.assign({}, state.form)
      } else { //编辑
        state.title="编辑物品[" + row.name + ']'
        await getDetailData(row)
      }
      state.loading = false
    }

    //根据id查询数据
    const getDetailData = async (row) => {
      //根据id查询
      const res = await proxy.$API.goods.detail.get(row.id)
      if (res.code === 200) {
        if(state.goodsRelClassify){
          res.data.codes = res.data.fz_classifyCode
          if(res.data.fz_classifyCode){
            let num = res.data.fz_classifyCode.length
            let data = res.data.code.substring(num)
            res.data.codeed = data
          }
        }else if(!state.goodsRelClassify){
          res.data.codeed = res.data.code
        }
        if (res.data.assistRatio1 == 0 || !res.data.assistUnit1){
          state.form.assistRatio1 = ''
        }
        if (res.data.assistRatio2 == 0 || !res.data.assistUnit2) {
          state.form.assistRatio3 = ''
        }
        if (res.data.assistRatio3 == 0 || !res.data.assistUnit3){
          state.form.assistRatio2 = ''
        }
        state.orgForm = Object.assign({}, res.data)

        const classifyId = res.data.classifyId
        let classifyContent = null
        if (classifyId){
          const classify = await proxy.$API.classify.detail.get(classifyId)
          if (classify.code === 200){
            classifyContent = {
              name: classify.data.name,
              id: classify.data.id
            }
          }
        }

        state.form = Object.assign({}, res.data)

        state.form.depot = {
          id: res.data.depotId,
          name: res.data.fz_depotName
        }
        if(state.form.depotId){
          getPositionList(1)
        }
        if (classifyContent !== null) {
          state.form.parent = classifyContent
        }
        setSonlist(res.data)
      }
    }

    const selClear = (field) => {
      state.form[field + 'Id'] = null
      state.form['fz_' + field + 'Name'] = ''
    }
    const getDepotList = async () => {
      const res = await proxy.$API.depot.getAllList.get()
      if (res.data) {
        state.depotList = res.data
      }
    }



    const getPositionList = async (type) => {
      if(type !== 1){
        state.form['freightId'] = null
        state.form['fz_freightName'] = ''
      }
      const res = await proxy.$API.freight.getDepotLocation.get(state.form.depotId,'')
      if (res.code === 200 && res.data && res.data.length > 0) {
        if(res.data && res.data.length > 0){
          state.positionList = res.data.map((item)=>{
            return {
              name: item.name,
              id: item.id,
              depotQuantity: item.depotQuantity || 0
            }
          })
        }
      }else {
        state.positionList = []
      }
    }

    const setSonlist = (data) => {
      data.productLimitList.forEach((item)=>{
        item['depot'] = {
          id: item.depotId,
          name: item.fz_depotName
        }
      })
    }

    //关闭弹窗
    const hideDialog = () => {
      state.visible = false
      reset(true)
    }

    //弹窗关闭前调用方法
    const beforeClose = (done) => {
      nextTick(() => {
        proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
          done()
          reset(true)
        })
      })
    }

    //关闭确认
    const handleConfirm = () => {
      save()
      state.visibles = false
      // hideDialog()
    }


    const valiForm = async () => {
      state.visibles = false
      nextTick(()=>{
        state.refForm.validate(async (valid) => {
          if (valid) {
            state.isLoading = true
            const _res = await proxy.$API.setup.getList.get()
            state.isLoading = false
            if (_res.code === 200) {
              //是否允许重复
              if (_res.data.allowDuplicateProductEntry !== 0) {
                let param = {}
                let errMsg = ''
                if (_res.data.productNameUniquenessType === 1) {
                  param = {
                    name: state.form.name
                  }
                  errMsg = '物品名称重复'
                } else if (_res.data.productNameUniquenessType === 2) {
                  param = {
                    name: state.form.name,
                    spec: state.form.spec,
                  }
                  errMsg = '物品名称和规格型号重复'
                }
                param['id'] = state.form.id
                const checkRes = await proxy.$API.goods.checkName.post(param)
                console.log(123)
                if (checkRes.code === 600) {
                  if (_res.data.allowDuplicateProductEntry === 1) {
                    proxy.$baseConfirm(errMsg + '，是否继续？', null, () => {
                      save()
                    })
                  } else {
                    proxy.$baseAlert(errMsg)
                    // proxy.$baseMessage(errMsg,'error')
                  }
                } else if (checkRes.code === 500) {
                  console.log(444)
                  proxy.$baseMessage(checkRes.msg, 'error')
                } else {
                  console.log(555)
                  state.visibles = true
                }
              } else {
                state.visibles = true
              }
            } else {
              proxy.$baseMessage(_res.msg, 'error')
            }
            state.isLoading = false
          }else {
            state.visibles = false
          }
        })
      })
    }


    //提交方法
    const save = () => {
      state['refForm'].validate(async (valid) => {
        if (valid) {
          if (state.form.batchManage == 0) {
            state.form.examClass = null
          }
          //根据ref获取对应子表的有效数据
          const priceList = proxy.$refs.refGoodsPrice.data.filter((item) => {
            //返回name不为空的数据
            return item.name && item.name != ''
          })
          // const limitList = state.refLimitDetail.list.filter((item) => {
          // 	//返回contry不为空的数据
          // 	return item.depotId && item.depotId != ''
          // })
          //将取得的子表数据赋给form，以便传到后台
          // proxy.$LOADING.loading();
          state.form['goodsPriceList'] = priceList || []
          // state.form['productLimitList'] = limitList || []
          let res = ''
          let tips = proxy.$TIPS.SUBMIT;
          if (state.form.batchManage == 0) {
            state.form.examClass = null
          }
          if (state.form.size == 0) {
            state.form.bastardSize = 0
          }
          if (state.form.goodsGroup == 0) {
            state.form['goodsLinks'] = []
            delete state.form['goodsLinks']
          }
          state.subLoading = true
          state.form.classify = null;
          if (state.goodsRelClassify && state.form.codes) {
            state.form.code = state.form.codes + (state.form.codeed || '')
          } else {
            state.form.code = state.form.codeed
          }
					if(!!state.form.code){
							const isCacheAutoCodeValid = !!state.cacheAutoCode
							const isCodeChanged = state.cacheAutoCode !== state.form.code

							if (state.goodsRelClassify) {
									const codes = state.cacheAutoCode.split(',')[0]
									state.form['isChangedAutoCode'] = (codes === state.form.codes) ? isCodeChanged : false
							} else if (isCacheAutoCodeValid) {
									state.form['isChangedAutoCode'] = isCodeChanged
							}
					}
          // proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
          state.subLoading = true
          state.form.classify = null;
          // let res = null
          if (state.form.id) { //修改，id不为空
            res = await proxy.$API.goods.update.put(state.form)
          } else { //新增
            res = await proxy.$API.goods.insert.post(state.form)
          }
          if (res.code === 200) {
            proxy.$baseMessage(res.msg, 'success')
            emit('fetch-data')
            hideDialog()
          } else {
            proxy.$baseMessage(res.msg, 'error')
          }
          state.subLoading = false
          // })
        }
      })
    }



    // const save = async () => {
    //   if (!state.form.classifyId) {
    //     state.form.parent = null
    //   }
    //   nextTick(async () => {
    //     state['refForm'].validate(async (valid) => {
    //       if (valid) {
    //         if (state.form.goodsGroup) {
    //           let sonDetail = proxy.$refs.refSonDetail
    //           const goodsLinks = sonDetail.list.filter((item) => {
    //             //返回id不为空的数据
    //             return item.goodsId && item.goodsId != ''
    //           })
    //           if (goodsLinks && goodsLinks.length < 1) {
    //             proxy.$baseMessage("请补充物品明细或关闭组合件", 'error')
    //             return
    //           }
    //           if (goodsLinks && goodsLinks.length > 0) {
    //             let checkMassage = ''
    //             const isChecked = goodsLinks.some((item) => {
    //               const index = sonDetail.list.indexOf(item)
    //               if (!item.quantity || item.quantity == '') {
    //                 checkMassage += '第' + (index + 1) + '行,数量不能为空'
    //                 return !item.quantity || item.quantity == ''
    //               }
    //             })
    //             //如果有空值
    //             if (isChecked) {
    //               proxy.$baseMessage(checkMassage, 'error')
    //               return
    //             }
    //           }
    //           state.form['goodsLinks'] = goodsLinks || []
    //         }
    //         //根据ref获取对应子表的有效数据
    //         const priceList = proxy.$refs.refGoodsPrice.data.filter((item) => {
    //           //返回name不为空的数据
    //           return item.name && item.name != ''
    //         })
    //         // const limitList = state.refLimitDetail.list.filter((item) => {
    //         // 	//返回contry不为空的数据
    //         // 	return item.depotId && item.depotId != ''
    //         // })
    //         //将取得的子表数据赋给form，以便传到后台
    //         proxy.$LOADING.loading();
    //         state.form['goodsPriceList'] = priceList || []
    //         // state.form['productLimitList'] = limitList || []
    //         let res = ''
    //         let tips =  proxy.$TIPS.SUBMIT;
    //         if (state.form.batchManage == 0) {
    //           state.form.examClass = null
    //         }
    //         if (state.form.size == 0) {
    //           state.form.bastardSize = 0
    //         }
    //         if (state.form.goodsGroup == 0) {
    //           state.form['goodsLinks'] = []
    //           delete state.form['goodsLinks']
    //         }
    //         state.subLoading = true
    //         state.form.classify = null;
    //         if (state.goodsRelClassify && state.form.codes) {
    //           state.form.code = state.form.codes + (state.form.codeed || '')
    //         }else{
    //           state.form.code = state.form.codeed
    //         }
    //         if (state.form.id) { //修改，id不为空
    //           res = await proxy.$API.goods.update.put(state.form)
    //         } else { //新增
    //           res = await proxy.$API.goods.insert.post(state.form)
    //         }
    //         if(res.code === 200) {
    //           proxy.$baseMessage(res.msg, 'success')
    //           emit('fetch-data')
    //           hideDialog()
    //         } else {
    //           proxy.$baseMessage(res.msg, 'error')
    //         }
    //         state.subLoading = false
    //       } else {
    //         proxy.$baseMessage('请完善必填项！', 'error')
    //       }
    //     })
    //   })
    // }


    //重置表单
    const reset = () => {
      state['refForm'].resetFields()
      Object.keys(state.form).forEach((item) => {
        delete state.form[item]
      })
      if (state.form.id) {
        fetchData(state.form)
      }

    }


    //下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
    const handleSelChange = (data,field) => {
      // if(data.length > -1){ //多选
      //   let ids = ''
      //   if(data.length > 0){
      //     //取得选中的id串
      //     ids = data.map((item) => item.id).join()
      //   }else{
      //     ids = ''
      //   }
      //   state.form[field] = ids
      // }else{ //单选
      //   if(data && data.id){
      //     //将选择的数据id赋值给指定的form中的属性 例：groupId
      //     state.form[field[0]] = data.id
      //   }
      // }

      if (field === "assistUnit1Id") {
        state.form.assistRatio1 = data.assistRatio1
        // state.form.mainUnit1 = data.assistUnit1
      } else if (field === "assistUnit2Id") {
        state.form.assistRatio2 = data.assistRatio1
        // state.form.mainUnit2 = data.assistUnit1
      } else if (field === "assistUnit3Id") {
        state.form.assistRatio3 = data.assistRatio1
        // state.form.mainUnit3 = data.assistUnit1
      }
      if (field === 'classifyId') {
        state.code = data.code
        if (state.goodsRelClassify) {
          state.form.codes = data.code
        }
        state.form.classifyId = data.id
        state.form.fz_classifyName = data.name
        state.classifyId = data.id
        state.form.parent = {
          name: data.name,
          id: data.id,
          coordinate: data.coordinate
        }
				getAutoCode(state.form.classifyId)
      }
    }

    const handleCancel = (data) =>{
      state.form.codes = ""
    }


    //只能输入数字（负数、小数）
    const handleNumIpt = (val, field) => {
      if (val) {
        state.form[field] = resetNum(val)
      }
    }

    //复制弹窗
    const copyData = () => {
      nextTick(() => {
        //把触发元素传给弹窗
        proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
        //copyDialog弹窗的ref属性
        proxy.$refs.copyDialog.showDialog()
      })
    }

    //价格回调
    const setPriceData = (data) => {
      if (data && data.length > 0) {
        state.form.goodsPriceList = JSON.parse(JSON.stringify(data))
      }
    }

    //打开详情
    const handleDetail = (row, ref, field) => {
      const params = Object.assign({}, row)
      if (field) {
        params.id = row[field]
      }
      proxy.$refs[ref].showDialog(params)
    }


    //根据id获取数据赋值给表单
    const setData = async (id) => {
      const res = await proxy.$API.goods.detail.get(id)
      if (res.code === 200) {
        if (res.data.assistRatio1 == 0 || !res.data.assistUnit1){
          state.form.assistRatio1 = ''
        }
        if (res.data.assistRatio2 == 0 || !res.data.assistUnit2) {
          state.form.assistRatio3 = ''
        }
        if (res.data.assistRatio3 == 0 || !res.data.assistUnit3){
          state.form.assistRatio2 = ''
        }
        state.orgForm = Object.assign({}, res.data)

        const classifyId = res.data.classifyId
        let classifyContent = null
        if (classifyId){
          const classify = await proxy.$API.classify.detail.get(classifyId)
          if (classify.code === 200){
            classifyContent = {
              name: classify.data.name,
              id: classify.data.id
            }
          }
        }
        state.form = Object.assign({}, res.data)
        state.form.depot = {
          id: res.data.depotId,
          name: res.data.fz_depotName
        }
        if (classifyContent !== null) {
          state.form.parent = classifyContent
        }
      }
    }

    //根据主表id获取回显对象
    const echoObject = async () => {
      //查询物品分类
      if (state.form.classifyId) {
        const resClassify = await proxy.$API.classify.detail.get(state.form.classifyId);
        state.form['classify'] = resClassify.data
      }
    }

    const fetchData = async (data, type) => {
      console.log("666")
      if (data.id) { //修改
        //获取主信息 与 子级信息
        await setData(data.id)
        //获取回显对象
        await echoObject()
        //如果是复制信息则删掉id
        if (type === 1) {
          delete state.form.id
          delete state.form.taskId
          delete state.form.instanceId
          delete state.form.auditStatus
        }
      } else { //新增
        //把form的默认值赋给orgForm，以便重置表单恢复默认值
        Object.assign(state.orgForm, state.form)
      }
    }

    //复制信息点击行后返回
    const setFormData = (data) => {
      // state.form = Object.assign({},data)
      // //删除id 选择复制的信息后需要删除带过来的id
      // delete state.form.id
      fetchData(data , 1)
    }

    const handleInput = (val, field) => {
      if (field && field == 'name') {
        //获取名称首字母赋值给编码
        state.form['number'] = Pinyin.getWordsCode(val)
      }
    }

    //编码重复校验
    const handleCodeBlur = async (e) => {
      if(state.goodsRelClassify && state.form.codes && state.form.codeed){
        state.form.code = state.form.codes + state.form.codeed
      }else if(!state.goodsRelClassify && state.form.codeed){
        state.form.code = state.form.codeed
      }else return
      const val = e.target.value
      if (val !== '') {
				const isCacheAutoCodeValid = !!state.cacheAutoCode
				const isCodeChanged = state.cacheAutoCode !== state.form.code
				let isChangedAutoCode = false

				if (state.goodsRelClassify) {
						const codes = state.cacheAutoCode.split(',')[0]
						isChangedAutoCode = (codes === state.form.codes) ? isCodeChanged : false
				} else if (isCacheAutoCodeValid) {
						isChangedAutoCode = isCodeChanged
				}
				// let object = {
				//   id: state.form.id,
				//   code: state.form.code,
				//   isChangedAutoCode: isChangedAutoCode,
				// };
				let param = Object.assign({},state.form)
				param['isChangedAutoCode'] = isChangedAutoCode
				const res = await proxy.$API.goods.validateAutoGoodsCode.get(param)
				if(res.code === 200 && res.data){
						proxy.$baseMessage('编码重复,已自动生成新编码!','warning')
						state.codeed = res.data
				}
      }
    }

    //仓库选择回调
    const setValue = (data) => {
      if(data){
        state.form.depotId = data.id
      }else {
        state.form.depotId = null
      }
    }

    const inventoryBlur = (field) => {
      if(state.form.inventoryCap && state.form.inventoryLower){
        const inventoryCap = state.form.inventoryCap - 0 || 0
        const inventoryLower = state.form.inventoryLower - 0 || 0
        if(inventoryLower > inventoryCap){
          state.form['inventoryLower'] = 0
        }
      }
    }


    return {
      ...toRefs(state),
      setFormData,
      copyData,
      handleSelChange,
      save,
      reset,
      beforeClose,
      setValue,
      showDialog,
      handleCodeBlur,
      hideDialog,
      handleConfirm,
      handleInput,
      handleDetail,
      handleNumIpt,
      inventoryBlur,
      selClear,
      setPriceData,
      handleCancel,
      valiForm
    }
  },
})
</script>
