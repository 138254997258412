<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="width"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="父级指标"
          prop="fz_parentName"
        >
            <div class="disflex fullWidth sc-table-select--append">
              <div class="flexgs1">
                <sc-table-select
                    v-model="form.fz_parentName"
                    customerValue
                    fullWidth
                    :api-obj="$API.performanceTarget.getListByKeyword"
                    :params="{
                        flag: 1,
                      }"
                    :height="426"
                    :props="{ label: 'name', value: 'id', keyword: 'name' }"
                    @change="(val) => handleSelChange(val, ['parentId','fz_parentName'])"
                    @clear="inputClear('parent')"
                >
                  <el-table-column
                      prop="name"
                      label="指标名称"
                      width="200"/>
                  <el-table-column
                      prop="code"
                      label="指标编码"
                      width="200"/>

            <template #append>
              <el-icon @click="popSelect('refParentIput',['parentId','fz_parentName'], 1, 'refSelectPerformanceTarget')">
                <el-icon-search />
              </el-icon>
            </template>
                </sc-table-select>
              </div>
            </div>
        </el-form-item>
        <el-form-item
          prop="classify"
          label="指标分类"
        >
          <sc-table-select
            v-model="form.classify"
            :api-obj="$API.classify.getList"
            :table-width="500"
            hide-pagination
            :params="{ businessType: $MENU.code.HR_PERFORMANCE_TARGET }"
            :props="{ label: 'name', value: 'id', keyword: 'name' }"
            style="width: 100%;"
            @change="(val) => handleSelChange(val, 'classifyId')"
          >
            <el-table-column
              prop="name"
              label="分类名称"
            />
          </sc-table-select>
        </el-form-item>
        <el-form-item
          prop="name"
          label="指标名称"
        >
          <el-input
            v-model="form.name"
            clearable
            maxlength="255"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="指标编码"
        >
          <el-input
            v-model="form.code"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="definition"
          label="指标定义"
        >
          <el-input
            v-model="form.definition"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="source"
          label="信息来源"
        >
          <el-input
            v-model="form.source"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="scope"
          label="适用范围"
        >
          <el-input
            v-model="form.scope"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="frequency"
          label="考核频率"
        >
          <el-input
            v-model="form.frequency"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="access"
          label="获取方式"
        >
          <el-input
            v-model="form.access"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="scoringRule"
          label="评分规则"
        >
          <el-input
            v-model="form.scoringRule"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="nature"
          label="指标性质"
        >
          <el-input
            v-model="form.nature"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="standard"
          label="评分标准"
        >
          <el-input
            v-model="form.standard"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="unit"
          label="计量单位"
        >
          <el-input
            v-model="form.unit"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="basicScore"
          label="基本分值"
        >
          <el-input
            v-model="form.basicScore"
            clearable
            maxlength="24"
            placeholder="请输入"
            @input="(val) => handleNumInput(val, 'basicScore')"
          />
        </el-form-item>
        <el-form-item
          prop="weight"
          label="权重比例"
        >
          <el-input
            v-model="form.weight"
            clearable
            maxlength="24"
            placeholder="请输入"
            @input="(val) => handleNumInput(val, 'weight')"
          >
            <template #suffix>
              %
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="effective"
          label="是否有效"
        >
          <el-radio-group v-model="form.effective">
            <el-radio :label="1">
              是
            </el-radio>
            <el-radio :label="0">
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="255"
            rows="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-popconfirm
          :visible="visibles"
          :title="$TIPS.SAVE"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @cancel="visibles = false"
          @confirm="handleConfirm"
      >
        <template #reference>
          <el-button
              type="primary"
              :size="$store.state.global.uiSize"
              :loading="subLoading"
              @click = "valiForm"
          >
            提交
          </el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button :size="$store.state.global.uiSize">
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #effective="{ scope }">
        <el-tag
          v-if="scope.row.effective === 1"
          type="success"
          class="myTag"
        >
          是
        </el-tag>
        <el-tag
          v-if="scope.row.effective === 0"
          type="warning"
          class="myTag"
        >
          否
        </el-tag>
      </template>
    </SyCopyDialog>
    <!--选择考核指标-->
    <SelectPerformanceTarget
      ref="refSelectPerformanceTarget"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetNum } from "@/utils/validate";
export default defineComponent({
	name: "PerformanceTargetEdit",
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      refFormBar:null,
			codeMsg: '',
			loading: false,
			triggerDom: {},
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			cacheFields: {},
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				// classifyObj: [{required: true, message: '必填'}],
				code: [
					{required: true, trigger: 'blur', message: '必填'},
					{validator: validateCode, trigger: 'blur'},
				],
				name: [{required: true, message: '必填'}],
				definition: [{required: true, message: '必填'}],
			},
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.performanceTarget.getList,
				//弹窗标题
				title:'复制考核指标信息',
				//表格显示列
				columns: [
					{
						label: '指标名称',
						prop: 'name',
						width: 180,
						sortable: 'custom',
						fixed: 'left'
					},
					{
						label: '指标编码',
						prop: 'code',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '分类',
						prop: 'fz_classifyName',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '指标定义',
						prop: 'definition',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '信息来源',
						prop: 'source',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '适用范围',
						prop: 'scope',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '考核频率',
						prop: 'frequency',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '获取方式',
						prop: 'access',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '评分规则',
						prop: 'scoringRule',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '指标性质',
						prop: 'nature',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '评分标准',
						prop: 'standard',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '基本分值',
						prop: 'basicScore',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '权重比例(%)',
						prop: 'weight',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '是否有效',
						prop: 'effective',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: 'custom',
					},
				],
				searchConfig: {
					//表单项配置
					itemList: [
						{
							label: '指标名称',
							span: 8,
							prop: 'name',
							type: 'input',
							max: 255,
							default: true,
						},
						{
							label: '指标编码',
							span: 8,
							prop: 'code',
							type: 'input',
							max: 32,
							default: true,
						},
						{
							label: '指标定义',
							span: 8,
							prop: 'definition',
							type: 'input',
							max: 255,
							default: true,
						},
						{
							label: '信息来源',
							span: 8,
							prop: 'source',
							type: 'input',
							max: 255,
						},
						{
							label: '适用范围',
							span: 8,
							prop: 'scope',
							type: 'input',
							max: 255,
						},
						{
							label: '考核频率',
							span: 8,
							prop: 'frequency',
							type: 'input',
							max: 255,
						},
						{
							label: '获取方式',
							span: 8,
							prop: 'access',
							type: 'input',
							max: 255,
						},
						{
							label: '评分规则',
							span: 8,
							prop: 'scoringRule',
							type: 'input',
							max: 255,
						},
						{
							label: '指标性质',
							span: 8,
							prop: 'nature',
							type: 'input',
							max: 255,
						},
						{
							label: '评分标准',
							span: 8,
							prop: 'standard',
							type: 'input',
							max: 255,
						},
						{
							label: '计量单位',
							span: 8,
							prop: 'unit',
							type: 'input',
							max: 32,
						},
						{
							label: '基本分值',
							span: 8,
							type: 'rangeNum',
							//开始结束字段
							fields: [
								{
									prop: 'fz_basicScore0',
									max: 24,
								},
								{
									prop: 'fz_basicScore1',
									max: 24,
								}
							],
						},
						{
							label: '权重比例',
							span: 8,
							type: 'rangeNum',
							//开始结束字段
							fields: [
								{
									prop: 'fz_weight0',
									max: 24,
									append: '%'
								},
								{
									prop: 'fz_weight1',
									max: 24,
									append: '%'
								}
							],
						},
						{
							label: '是否有效',
							span: 8,
							prop: 'effective',
							type: 'select',
							data: [
								{label: '是', value: 1},
								{label: '否', value: 0},
							]
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			}
		})

		const showDialog = (row, type) => {
			state.visible = true
			nextTick(async () => {
				if (type === 1) { //新增
					state.title="新增"
					state.form = Object.assign({}, row)
					state.form.effective = 1
					if(!row.classifyId){
						const res = await proxy.$API.classify.findAll.get({ businessType: proxy.$MENU.code.HR_PERFORMANCE_TARGET })
						if(res.code === 200 && res.data != null){
							state.form.classify = res.data[0]
							//state.form.classifyId = res.data[0].id
							//state.form.classifyPath = res.data[0].coordinate
						}
					}else{
						state.form.classify = {
							id: row.classifyId,
							name: row.fz_classifyName
						}
					}
					state.orgForm = Object.assign({}, state.form)
				} else {
					state.form = Object.assign({}, row)
					state.orgForm = Object.assign({}, row)
					await getClass(row)
				}
			})
		}

		const getClass = async (data) => {
			if (data.classifyId){
				const res = await proxy.$API.classify.detail.get(data.classifyId)
				if(res.code === 200&&res.data){
					state.form.classify = res.data
					state.form.classifyId = res.data.id
					state.form.classifyPath = res.data.coordinate
					state.orgForm.classify = res.data
					state.orgForm.classifyId = res.data.id
					state.orgForm.classifyPath = res.data.coordinate
				}
			}
		}

		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
    //关闭确认
    const handleConfirm = () => {
      save()
      state.visibles = false
    }
    const valiForm = () => {
      nextTick(()=>{
        state.formRef.validate((valid)=>{
          if(valid){
            state.visibles = true
          }else {
            state.visibles = false
          }
        })
      })
    }
		//确定按钮
		const save = () => {
			state['formRef'].validate(async (valid) => {
				if(valid) {
						state.subLoading = true
						let res
						if(state.form.id){
							res = await proxy.$API.performanceTarget.update.put(state.form)
						}else{
							res = await proxy.$API.performanceTarget.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.performanceTarget.checkCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['formRef'].validateField('code')
				}
			}
		}

		//只能输入数字小数符数
		const handleNumInput = (val, field) => {
			if(val){
				state.form[field] = resetNum(val)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
        state.form[field[0]] = data.id
					state.form['classifyPath'] = data.coordinate
				}
			}
		}

		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id
			delete state.form.id
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = state.form[fields[0]]
				state.fields = fields
				state.multiples = type !== 1;
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}
		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			if(field !== 'shareUser'){
				state.form[field + 'Id'] = ''
			}else{
				state.form[field] = ''
			}
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}
		return {
			...toRefs(state),
			popSelect,
			setSelectData,
			inputBlur,
			inputClear,
			setFormData,
			copyData,
			handleSelChange,
			handleNumInput,
			handleCodeBlur,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
      valiForm
		}
	},
})
</script>
