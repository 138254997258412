<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <div
    v-loading="loading"
    class="dropBox"
    :class="visible ? 'show' : ''"
  >
    <el-container
      class="nopadding"
      direction="vertical"
    >
      <el-scrollbar>
        <Classify
          v-if="slot == 'refClassify'"
          ref="refClassify"
          @loaded="loaded"
          @node-click="(data) => nodeClick(data,'refClassify')"
        />
        <DropDepot
          v-if="slot == 'refDropDepot'"
          ref="refDropDepot"
          @loaded="loaded"
          @node-click="(data) => nodeClick(data,'refDropDepot')"
        />
      </el-scrollbar>
    </el-container>
  </div>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, nextTick
} from 'vue'
import Classify from './classify'
import DropDepot from './depotDrop'
export default defineComponent({
	name: 'DropSel',
	components: { Classify, DropDepot },
	emits: ['node-click'],
	setup(props,{emit}) {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			top: '',
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//节点数据
			data: [],
			slot: '',
			loading: true,
		})

		//显示弹窗
		const showDialog = (slot) => {
			state.loading = true
			state.visible = true
			tabSlot(slot)
		}

		//切换显示选择内容
		const tabSlot = (slot) => {
			state.slot = slot
			nextTick(() => {
				proxy.$refs[slot].fetchData()
			})
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//节点点击
		const nodeClick = (data, type) => {
			emit('node-click',data, type)
		}

		//数据加载完成执行
		const loaded = () => {
			state.loading = false
		}

		//设置下拉框style
		const setStyle = (top) => {
			const dropBox = document.querySelector('.dropBox')
			dropBox.style.top = top
		}

		return {
			...toRefs(state),
			loaded,
			nodeClick,
			showDialog,
			hideDialog,
			setStyle,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

