import config from "@/config"

//系统路由
const routes = [
	{
		name: "Index",
		path: "/",
		component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
		//redirect: config.DASHBOARD_URL || '/dashboard',
		meta: {
			title: process.env.VUE_APP_TITLE,
			//title: "四川电信",
		}
	},
	{
		path: "/login",
		component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
		meta: {
			title: "登录"
		}
	},
	{
		path: "/user_register",
		component: () => import(/* webpackChunkName: "userRegister" */ '@/views/login/userRegister'),
		meta: {
			title: "注册"
		}
	},
	{
		path: "/reset_password",
		component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/login/resetPassword'),
		meta: {
			title: "忘记密码"
		}
	},
	{
		path: "/userCenter",
		component: () => import(/* webpackChunkName: "userCenter" */ '@/views/userCenter/index.vue'),
		meta: {
			title: "个人中心"
		}
	},
	{
		path: "/syResult",
		name: 'SyResult',
		component: () => import(/* webpackChunkName: "syResult" */ '@/components/syResult/index.vue'),
		meta: {
			title: "结果页"
		}
	},
	{
		path: "/weChatLogin",
		component: () => import(/* webpackChunkName: "dingding" */ '@/views/weChat/weChatLogin.vue'),
		meta: {
			title: "企业微信登陆跳转"
		}
	},
	{
		path: "/redirect",
		component: () => import(/* webpackChunkName: "dingding" */ '@/views/weChat/redirect.vue'),
		meta: {
			title: "企业微信登陆跳转"
		}
	},
]

export default routes;
