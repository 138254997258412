import config from "@/config";

export default {
	state: {
		//移动端布局
		ismobile: false,
		//布局
		layout: config.LAYOUT,
		//菜单是否折叠 toggle
		menuIsCollapse: config.MENU_IS_COLLAPSE,
		//多标签栏
		layoutTags: config.LAYOUT_TAGS,
		//主题
		theme: config.THEME,
		//显示面包写
		showTopbar: false,
		//字体大小
		fontSize: config.fontSize,
		//websocket连接状态
		socketConnect: false,
		//ui尺寸大小
		uiSize: config.UI_SIZE,
		//新消息
		msgList: null
	},
	mutations: {
		SET_socketConnect(state){
			state.socketConnect = !state.socketConnect
		},
		SET_fontSize(state, key){
			state.fontSize = key
		},
		SET_uiSize(state, key){
			state.uiSize = key
		},
		SET_ismobile(state, key){
			state.ismobile = key
		},
		SET_layout(state, key){
			state.layout = key
		},
		SET_theme(state, key){
			state.theme = key
		},
		SET_msgList(state, key){
			state.msgList = key
		},
		TOGGLE_menuIsCollapse(state){
			state.menuIsCollapse = !state.menuIsCollapse
		},
		TOGGLE_layoutTags(state){
			state.layoutTags = !state.layoutTags
		},
		TOGGLE_layoutBread(state){
			state.showTopbar = !state.showTopbar
		}
	}
}
