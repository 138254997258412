<template>
  <el-popover
    ref="popRef1"
    v-model:visible="popvisible"
    :width="500"
    :title="config.title || '编辑'"
    placement="bottom-start"
  >
    <el-button
      class="closePopoverBtn"
      @click="hidePop"
    >
      <el-icon><el-icon-close /></el-icon>
    </el-button>
    <template #reference>
      <el-button
        class="schbtn"
        @click="showPop"
      >
        <el-icon><el-icon-edit /></el-icon>
      </el-button>
    </template>
    <div class="dropEditPanel">
      <el-container>
        <el-header class="tableTopBar drop">
          <div class="fullWidth">
            <sy-form
              ref="formRef"
              :config="config.formConfig"
            />
            <div class="text-center mt_5">
              <el-button
                type="primary"
                :size="$store.state.global.uiSize"
                @click="handleSave"
              >
                保存
              </el-button>
              <el-button
                :size="$store.state.global.uiSize"
                @click="handleReset"
              >
                重置
              </el-button>
              <el-button
                :size="$store.state.global.uiSize"
                @click="cancel"
              >
                取消
              </el-button>
            </div>
          </div>
        </el-header>
        <el-main class="pd0 mt_15">
          <scTable
            ref="table"
            :api-obj="config.apiObj"
            :column="config.columns"
            row-key="id"
            border
            highlight-current-row
            remote-sort
            remote-filter
            :colindex="false"
            :hide-do="true"
            :hide-refresh="true"
            :hide-setting="true"
            :padding="0"
            :height="300"
          >
            <!--自定义列 操作列-->
            <template #operation="{ scope }">
              <el-link @click="handleEdit(scope.row)">
                编辑
              </el-link>
              <el-popconfirm
                :title="$TIPS.DELETE"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="handleDelete(scope.row)"
              >
                <template #reference>
                  <el-link>删除</el-link>
                </template>
              </el-popconfirm>
            </template>
          </scTable>
        </el-main>
      </el-container>
    </div>
  </el-popover>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
} from 'vue'
export default defineComponent({
	name: "FastEdit",
	props: {
		config: { type: Object, default: () => {} }
	},
	setup(){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			popRef: null,
			popvisible: false,
			form: {},
			formRef: null,
		})
		const showPop = () => {
			state.popvisible = true
		}
		const hidePop = () => {
			state.popvisible = false
		}
		const cancel = () => {
			hidePop()
		}
		const handleSave = () => {
			const res = state['formRef'].submit()
		}
		const handleReset = () => {
			state['formRef'].resetForm()
		}
		return{
			...toRefs(state),
			showPop,
			hidePop,
			cancel,
			handleSave,
			handleReset,
		}
	}
})
</script>

