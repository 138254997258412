import config from "@/config"
import http from "@/utils/request"
export default {
	getCustomerSourceData: {
		url: `${config.API_URL}/cfg/data/customer/customerSource`,
		name: "销售排行榜",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},
}
