import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/produce/outprocess/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/produce/outprocess/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/produce/outprocess/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/produce/outprocess/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/produce/outprocess/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	turnOutMaterialData: {
		url: `${config.API_URL}/erp/produce/outprocess/turnOutMaterialData`,
		name: "查询详情",
		get: async function(id,keyWord){
			return await http.get(this.url + "?id=" + id+ '&keyWord=' + keyWord);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/produce/outprocess/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	submitProcess: {
		url: `${config.API_URL}/erp/produce/outprocess/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/erp/produce/outprocess/updateProcessInfo`,
		name: "修改审批信息",
		put: async function(data ={}){
			return await http.put(this.url,data);
		}
	},
	updateProcessing: {
		url: `${config.API_URL}/erp/produce/outprocess/updateProcessing`,
		name: "修改加工状态",
		get: async function(ids,state){
			return await http.get(this.url + '?ids=' + ids + '&state=' + state);
		}
	},
	//反审批
	returnProcess: {
		url: `${config.API_URL}/erp/produce/outprocess/returnProcess`,
		name: "反审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	selectDetail: {
		url: `${config.API_URL}/erp/produce/outprocess/detail/selectDetail`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	selectDetailNotPage: {
		url: `${config.API_URL}/erp/produce/outprocess/detail/selectDetailNotPage`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	updateOutQty: {
		url: `${config.API_URL}/erp/produce/outprocess/detail/updateOutQty`,
		name: "更新出料数量",
		get: async function(id,shopId,number){
			return await http.get(this.url + '?id=' + id + '&shopId=' + shopId + '&number=' + number);
		}
	},
	updateQuality: {
		url: `${config.API_URL}/erp/produce/outprocess/updateQuality`,
		name: "修改进度",
		put: async function(data){
			return await http.put(this.url,data);
		}
	},
	outProcessClose: {
		url: `${config.API_URL}/erp/produce/outprocess/outProcessClose`,
		name: "关闭订单",
		get: async function(ids){
			return await http.get(this.url+'?ids='+ids);
		}
	},
	outProcessOpen: {
		url: `${config.API_URL}/erp/produce/outprocess/outProcessOpen`,
		name: "开启订单",
		get: async function(ids){
			return await http.get(this.url+'?ids='+ids);
		}
	},
}
