import config from "@/config"
import http from "@/utils/request"

export default {
	getSupplierList: {
		url: `${config.API_URL}/cfg/data/supplier/getList`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/data/supplier/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	delSupplier: {
		url: `${config.API_URL}/cfg/data/supplier/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	detailSupplier: {
		url: `${config.API_URL}/cfg/data/supplier/detail`,
		name: "查询单条数据",
		get: async function(params){
			return await http.get(this.url + '?id=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/cfg/data/supplier/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	doCheckName: {
		url: `${config.API_URL}/cfg/data/supplier/checkName`,
		name: "名称唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	insertSupplier: {
		url: `${config.API_URL}/cfg/data/supplier/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},

	updateSupplier: {
		url: `${config.API_URL}/cfg/data/supplier/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

	updateFlagSupplier: {
		url: `${config.API_URL}/cfg/data/supplier/updateFlag`,
		name: "修改状态",
		put: async function(params){
			return await http.put(this.url + '?id=' + params);
		}
	},

	updateFlagSupplier0: {
		url: `${config.API_URL}/cfg/data/supplier/updateFlag0`,
		name: "状态禁用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	updateFlagSupplier1: {
		url: `${config.API_URL}/cfg/data/supplier/updateFlag1`,
		name: "状态启用",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

	getContactList: {
		url: `${config.API_URL}/cfg/data/supplier/getContactList`,
		name: "获取联系人列表",
		get: async function(data={}){
			return await http.get(this.url, data);
		}
	},

	querySuppliers: {
		url: `${config.API_URL}/cfg/data/supplier/querySuppliers`,
		name: "根据ids查询联系人集合",
		get: async function (params) {
			return await http.get(this.url + "?ids=" + params);
		}
	},

	getContactsBirthdayList: {
		url: `${config.API_URL}/cfg/data/supplier/queryContactsBirthday`,
		name: "列表",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},


	importExcel:{
		url: `${config.API_URL}/cfg/data/supplier/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},

	getSupplierNameByIds: {
		url: `${config.API_URL}/cfg/data/supplier/getSupplierNameByIds`,
		name: "根据 ids 去查询供应商",
		get: async function(data){
			return await http.post(this.url,data);
		}
	},


}
