import config from "@/config"
import http from "@/utils/request"

export default {
    getDefaultPrice: {
        url: `${config.API_URL}/erp/sharing/price/getDefaultPrice`,
        name: "物品默认价格",
        get: async function (goodsId,flag,status) {
            return await http.get(this.url+'?goodsId='+goodsId+'&flag='+flag+'&status='+status);
        }
    },
}
