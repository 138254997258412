import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/purchase/out/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/mobile/purchase/out/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	outStock: {
		url: `${config.API_URL}/mobile/purchase/out/outStock`,
		name: "确认出库",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/purchase/out/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/purchase/out/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/purchase/out/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},
	selectDetail: {
		url: `${config.API_URL}/mobile/purchase/out/goods/selectDetail`,
		name: "根据采购退货id查询物品明细",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

}
