<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="classifyObj"
          label="资产分类"
        >
          <sc-table-select
            v-model="form.classifyObj"
            :api-obj="$API.classify.getList"
            :table-width="500"
            hide-pagination
            :params="{ businessType: $MENU.code.FMS_ASSETS }"
            :props="{ label: 'name', value: 'id', keyword: 'name' }"
            style="width: 100%;"
            @change="(val) => handleSelChange(val, 'classifyId')"
          >
            <el-table-column
              prop="name"
              label="分类名称"
            />
          </sc-table-select>
        </el-form-item>
        <el-form-item
          prop="name"
          label="资产名称"
        >
          <el-input
            v-model="form.name"
            clearable
            maxlength="255"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="number"
          label="资产编号"
        >
          <el-input
            v-model="form.number"
            clearable
            maxlength="32"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          prop="balance"
          label="资产价值"
        >
          <el-input
            v-model="form.balance"
            clearable
            maxlength="24"
            @input="(val) => handleNumInput(val, 'balance')"
          />
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="255"
            rows="10"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
      <el-button
        type="primary"
        :size="$store.state.global.uiSize"
        :loading="subLoading"
        @click="save"
      >
        提交
      </el-button>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--&lt;!&ndash;          <el-button&ndash;&gt;-->
<!--&lt;!&ndash;            :size="$store.state.global.uiSize"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            关闭&ndash;&gt;-->
<!--&lt;!&ndash;          </el-button>&ndash;&gt;-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import { resetZnum } from "@/utils/validate"; //resetZnum返回正整数的方法
export default defineComponent({
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const state = reactive({
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '用户新增',
			//表单当前数据
			form: {},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				name: [{required: true, message: '必填' }],
				number: [
					{required: true, message: '必填' },
					{validator: validateCode, trigger: 'blur'},
				],
				balance: [{required: true, message: '必填' }],
			},
			//赋值字段
			fields: [],
		})
		const showDialog = async (row, node, type) => {
			state.visible = true
			if(type === 1){ //新增
				state.title = '新增资产'
				if(node){
					state.form.classifyObj = Object.assign({}, node)
				}else{
					const res = await proxy.$API.classify.findAll.get({ businessType: proxy.$MENU.code.FMS_ASSETS })
					if(res.code === 200){
						state.form.classifyObj = res.data[0]
						if(res.data[0].id) {
							state.form.classifyId = res.data[0].id
						}
						state.form.classify = res.data[0].coordinate
					}
				}
				state.orgForm = Object.assign({}, state.form)
			}else{ //编辑
				state.title = '编辑资产'
				//根据id查询
				//todo 详情接口
				//todo 资产接口
				const res = await proxy.$API.assetsManage.detail.get(row.id)
				if(res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
					await getClass(res.data)
				}
			}
			state.loading = false
		}

		const getClass = async (data) => {
			const res = await proxy.$API.classify.detail.get(data.classifyId)
			if(res.code === 200 && res.data){
				state.form.classifyObj = res.data
				state.form.classifyId = res.data.id
				state.form.classify = res.data.coordinate
				state.orgForm.classifyObj = res.data
				state.orgForm.classifyId = res.data.id
				state.orgForm.classify = res.data.coordinate
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
			hideDialog()
		}

		//提交方法
		const save = () => {
			state['formRef'].validate((valid) => {
				if(valid) {
					proxy.$baseConfirm(proxy.$TIPS.SUBMIT,null, async ()=>{
						state.subLoading = true
						let res
						if(state.form.id){
							//todo 修改接口
							res = await proxy.$API.assetsManage.update.put(state.form)
						}else{
							//todo 新增接口
							res = await proxy.$API.assetsManage.insert.post(state.form)
						}
						if(res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
					})
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				//todo 校验接口
				const res = await proxy.$API.assetsManage.checkCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['formRef'].validateField('number')
				}
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		return {
			...toRefs(state),
			handleCodeBlur,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm
		}
	},
})
</script>
