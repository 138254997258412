import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/cfg/examine/record/getList`,
		name: "查询所有流程历史记录",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
