import config from "@/config"
import http from "@/utils/request"

export default {
	detailPool: {
		url: `${config.API_URL}/erp/stock/report/detailPool`,
		name: "库存明细汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	numberPool: {
		url: `${config.API_URL}/erp/stock/report/numberPool`,
		name: "库存数量汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	availableStock: {
		url: `${config.API_URL}/erp/stock/report/availableStock`,
		name: "可用库存数量汇总表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	dullAnalysis: {
		url: `${config.API_URL}/erp/stock/report/dullAnalysis`,
		name: "呆滞品分析",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	materialGlance: {
		url: `${config.API_URL}/erp/stock/report/materialGlance`,
		name: "领料单 - 览表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	outEnterPool: {
		url: `${config.API_URL}/erp/stock/report/outEnterPool`,
		name: "出入库明细",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
