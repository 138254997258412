<template>
  <el-container class="pageContainer">
    <el-main
      id="main"
      class="pd10"
    >
      <!--label-width: 标题宽度, label-suffix:标题后缀 size: 表单尺寸大小 rules:验证规则-->
      <el-form
        ref="myForm"
        class="syForm"
        :model="form"
        :label-width="110"
        label-suffix=":"
        :size="$store.state.global.uiSize"
        :rules="rules"
      >
        <!--基本信息-->
        <el-card
          shadow="never"
          class="mycard form"
        >
          <!--自定义car头部-->
          <template #header>
            <span>基本信息</span>
            <!--复制数据按钮-->
            <el-button
              v-if="!form.id"
              ref="copyBtn"
              class="ml10"
              :size="$store.state.global.uiSize"
              @click="copyData"
            >
              复制信息
            </el-button>
          </template>
          <el-row :gutter="50">
            <el-col :span="span">
              <el-form-item
                label="职员"
                prop="workerName"
              >
                <el-input
                  ref="refWorkerIput"
                  v-model="form.workerName"
                  clearable
                  placeholder="请选择"
                  @blur="inputBlur('worker')"
                  @clear="inputClear('worker')"
                  maxlength="32"
                >
                  <template #append>
                    <el-icon
                      @click="popSelect('refWorkerIput',['workerId','workerName'], 1, 'refSelectUser')"
                    >
                      <el-icon-search />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="workerCode"
                label="工号"
              >
                <el-input
                  v-model="form.workerCode"
                  clearable
                  placeholder="请选择"
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                label="部门"
                prop="departmentName"
              >
                <el-input
                  ref="refDepartmentIput"
                  v-model="form.departmentName"
                  clearable
                  placeholder="请选择"
                  @blur="inputBlur('department')"
                  @clear="inputClear('department')"
                  maxlength="32"
                >
                  <template #append>
                    <el-icon
                      @click="popSelect('refDepartmentIput',['departmentId','departmentName'], 1, 'refSelectDepartment')"
                    >
                      <el-icon-search />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="gender"
                label="性别"
              >
                <el-radio-group v-model="form.gender">
                  <el-radio :label="1">
                    男
                  </el-radio>
                  <el-radio :label="0">
                    女
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="birthday"
                label="出生日期"
              >
                <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  value-format="YYYY-MM-DD"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="certType"
                label="证件类型"
              >
                <dicts-query
                  v-model="form.certType"
                  :type-code="'certType'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="certNumber"
                label="证件号"
              >
                <el-input
                  v-model="form.certNumber"
                  clearable
                  placeholder="请选择"
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="homeAddress"
                label="家庭住址"
              >
                <el-input
                  v-model="form.homeAddress"
                  maxlength="255"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="nativePlace"
                label="籍贯"
              >
                <el-input
                  v-model="form.nativePlace"
                  maxlength="255"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="employment"
                label="用工形式"
              >
                <dicts-query
                  v-model="form.employment"
                  :type-code="'employment'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="reportDate"
                label="到岗日期"
              >
                <el-date-picker
                  v-model="form.reportDate"
                  type="date"
                  value-format="YYYY-MM-DD"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="contractType"
                label="劳动合同类型"
              >
                <dicts-query
                  v-model="form.contractType"
                  :type-code="'contractType'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="termType"
                label="合同期限类型"
              >
                <dicts-query
                  v-model="form.termType"
                  :type-code="'termType'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="signDate"
                label="签约日期"
              >
                <el-date-picker
                  v-model="form.signDate"
                  type="date"
                  value-format="YYYY-MM-DD"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="contractTerm"
                label="合同期限"
              >
                <el-input
                  v-model="form.contractTerm"
                  clearable
                  placeholder="请选择"
                  maxlength="3"
                  @input="(val) => handleNumIpt(val, 'contractTerm')"
                >
                  <template #suffix>
                    月
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="effectDate"
                label="生效日期"
              >
                <el-date-picker
                  v-model="form.effectDate"
                  type="date"
                  value-format="YYYY-MM-DD"
                  clearable
                  @change="(val) => dateRangeChange(val, ['effectDate', 'expireDate'])"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="expireDate"
                label="到期日期"
              >
                <el-date-picker
                  v-model="form.expireDate"
                  type="date"
                  value-format="YYYY-MM-DD"
                  clearable
                  @change="(val) => dateRangeChange(val, ['effectDate', 'expireDate'])"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="trialTerm"
                label="试用期限"
              >
                <el-input
                  v-model="form.trialTerm"
                  clearable
                  placeholder="请选择"
                  maxlength="3"
                  @input="(val) => handleNumIpt(val, 'trialTerm')"
                >
                  <template #suffix>
                    月
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="trialDate"
                label="试用到期日期"
              >
                <el-date-picker
                  v-model="form.trialDate"
                  type="date"
                  value-format="YYYY-MM-DD"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                label="岗位"
                prop="postName"
              >
                <el-input
                  ref="refPostIput"
                  v-model="form.postName"
                  clearable
                  placeholder="请选择"
                  @blur="inputBlur('post')"
                  @clear="inputClear('post')"
                >
                  <template #append>
                    <el-icon
                      @click="popSelect('refPostIput',['postId','postName'], 1, 'refSelectPosition')"
                    >
                      <el-icon-search />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="workPiston"
                label="工作地点"
              >
                <el-input
                  v-model="form.workPiston"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="standard"
                label="应达工作标准"
              >
                <el-input
                  v-model="form.standard"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="workHour"
                label="工时制度"
              >
                <el-input
                  v-model="form.workHour"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="vacation"
                label="休假制度"
              >
                <el-input
                  v-model="form.vacation"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="wageSystem"
                label="工资制度"
              >
                <el-input
                  v-model="form.wageSystem"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="wage"
                label="月工资"
              >
                <el-input
                  v-model="form.wage"
                  clearable
                  placeholder="请选择"
                  maxlength="24"
                  @input="(val) => handleNumIpt(val, 'wage')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="wageExplain"
                label="工资说明"
              >
                <el-input
                  v-model="form.wageExplain"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="trialWage"
                label="试用工资"
              >
                <el-input
                  v-model="form.trialWage"
                  clearable
                  placeholder="请选择"
                  maxlength="24"
                  @input="(val) => handleNumIpt(val, 'trialWage')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="payDate"
                label="发薪日期"
              >
                <el-input
                  v-model="form.payDate"
                  clearable
                  placeholder="请选择"
                  maxlength="24"
                  @input="(val) => handleNumIpt(val, 'payDate')"
                >
                  <template #suffix>
                    日
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="alimony"
                label="生活费标准"
              >
                <el-input
                  v-model="form.alimony"
                  clearable
                  placeholder="请选择"
                  maxlength="24"
                  @input="(val) => handleNumIpt(val, 'alimony')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="waitWage"
                label="待工工资"
              >
                <el-input
                  v-model="form.waitWage"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="otherWage"
                label="其他工资"
              >
                <el-input
                  v-model="form.otherWage"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="leaveWage"
                label="病假工资"
              >
                <el-input
                  v-model="form.leaveWage"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="welfare"
                label="福利待遇"
              >
                <el-input
                  v-model="form.welfare"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="otherMatters"
                label="其他事项"
              >
                <el-input
                  v-model="form.otherMatters"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="protectionClause"
                label="劳动保护条款"
              >
                <el-input
                  v-model="form.protectionClause"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="remark"
                label="备注"
              >
                <el-input
                  v-model="form.remark"
                  clearable
                  placeholder="请选择"
                  maxlength="255"
                />
              </el-form-item>
            </el-col>

            <el-col :span="span">
              <el-form-item
                prop="company"
                label="公司名称"
              >
                <el-input
                  v-model="form.company"
                  maxlength="255"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="legalPerson"
                label="法人代表"
              >
                <el-input
                  v-model="form.legalPerson"
                  maxlength="255"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="span">
              <el-form-item
                prop="companyAddress"
                label="公司地址"
              >
                <el-input
                  v-model="form.companyAddress"
                  maxlength="255"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                prop="files"
                label="附件"
              >
                <sc-upload-multiple
                  v-model="form.files"
                  :title="'附件'"
                  :accept="'*'"
                  :is-file="true"
                  :list-type="'text'"
                  :limit="10"
                >
                   <el-button :size="$store.state.global.uiSize">
                    选择文件
                  </el-button>
                </sc-upload-multiple>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </el-main>
    <el-header class="bottom">
      <div class="text-center fullWidth">
        <el-button
          :loading="isLoading"
          type="primary"
          :size="$store.state.global.uiSize"
          @click="submitSave"
        >
          保存数据
        </el-button>
        <el-popconfirm
          :title="$TIPS.CONFIRM_RESET"
          confirm-button-text="确定"
          cancel-button-text="取消"
          @confirm="resetForm"
        >
          <template #reference>
            <el-button
              :size="$store.state.global.uiSize"
            >
              重置
            </el-button>
          </template>
        </el-popconfirm>
      </div>
    </el-header>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #number="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row)"
        >
          {{ scope.row.number }}
        </el-link>
      </template>

      <template #gender="{ scope }">
        <span v-if="scope.row.gender === 1">男</span>
        <span v-if="scope.row.gender === 0">女</span>
      </template>
      <template #contractStatus="{ scope }">
        <el-tag
          v-if="scope.row.contractStatus === 0"
          type="info"
          class="myTag"
        >
          待执行
        </el-tag>
        <el-tag
          v-if="scope.row.contractStatus === 1"
          type="success"
          class="myTag"
        >
          执行中
        </el-tag>
        <el-tag
          v-if="scope.row.contractStatus === 2"
          type="danger"
          class="myTag"
        >
          已终止
        </el-tag>
      </template>
    </SyCopyDialog>
    <!--选用户-->
    <SelectUser
      ref="refSelectUser"
      @set-data="setSelectData"
    />
    <!--选择部门-->
    <SelectDepartment
      ref="refSelectDepartment"
      @set-data="setSelectData"
    />
    <!--选择岗位-->
    <SelectPosition
      ref="refSelectPosition"
      @set-data="setSelectData"
    />
    <!--详情页弹出-->
    <detail ref="refDetail" />
  </el-container>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, onMounted, onUnmounted, reactive, toRefs,} from 'vue'
import {resetNum, resetZnum} from "@/utils/validate"
import {useRouter} from 'vue-router'
import {setColSpan} from "@/utils/window";

export default defineComponent({
	setup() {
		//获取当前实例对象，proxy包含了全局定义的参数方法及当前实例的属性方法
		const {proxy} = getCurrentInstance()
		//初始化router路由对象
		const router = useRouter()
		//定义响应式对象
		const state = reactive({
      refFormBar:null,
			myForm: null,
			//提交按钮加载
			isLoading: false,
			//表单数据对象
			form: {
				gender: 1,
			},
			//暂存表单数据
			orgForm: {},
			cacheFields: {},
			//表单验证规则
			rules: {
				workerName: [{required: true, trigger: 'change', message: '必填'}],
				protectionClause: [{required: true, trigger: 'change', message: '必填'}],
				company: [{required: true, trigger: 'change', message: '必填'}],
			},
			span: setColSpan(),
			//复制信息选择配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.laborContract.getList,
				//弹窗标题
				title: '复制合同信息',
				width: '70%',
				height: '600px',
				//表格显示列
				columns: [
					{
						label: '合同编号',
						prop: 'number',
						width: 180,
						sortable: "custom",
						fixed: 'left',
					},
					{
						label: '合同状态',
						prop: 'contractStatus',
						width: 110,
						sortable: "custom",
					},
					{
						label: '职员姓名',
						prop: 'workerName',
						width: 110,
						sortable: "custom",
					},
					{
						label: '工号',
						prop: 'workerCode',
						width: 110,
						sortable: "custom",
					},

					{
						label: '部门',
						prop: 'fz_departmentName',
						width: 110,
						sortable: "custom",
					},
					{
						label: '部门路径',
						prop: 'departmentName',
						width: 180,
						sortable: "custom",
					},
					{
						label: '性别',
						prop: 'gender',
						width: 110,
						sortable: "custom",
					},
					{
						label: '出生日期',
						prop: 'birthday',
						width: 130,
						sortable: "custom",
					},
					{
						label: '证件类型',
						prop: 'certType',
						width: 110,
						sortable: "custom",
					},
					{
						label: '证件号',
						prop: 'certNumber',
						width: 150,
						sortable: "custom",
					},
					{
						label: '家庭住址',
						prop: 'homeAddress',
						width: 180,
						sortable: "custom",
					},
					{
						label: '籍贯',
						prop: 'nativePlace',
						width: 120,
						sortable: "custom",
					},
					{
						label: '用工形式',
						prop: 'employment',
						width: 110,
						sortable: "custom",
					},
					{
						label: '到岗日期',
						prop: 'reportDate',
						width: 130,
						sortable: "custom",
					},
					{
						label: '劳动合同类型',
						prop: 'contractType ',
						width: 150,
						sortable: "custom",
					},
					{
						label: '合同期限类型',
						prop: 'termType',
						width: 150,
						sortable: "custom",
					},
					{
						label: '签约日期',
						prop: 'signDate',
						width: 130,
						sortable: "custom",
					},
					{
						label: '合同期限(月)',
						prop: 'contractTerm',
						width: 140,
						sortable: "custom",
					},
					{
						label: '生效日期',
						prop: 'effectDate',
						width: 130,
						sortable: "custom",
					},
					{
						label: '到期日期',
						prop: 'expireDate',
						width: 130,
						sortable: "custom",
					},
					{
						label: '试用期限(月)',
						prop: 'trialTerm',
						width: 140,
						sortable: "custom",
					},
					{
						label: '试用到期日期',
						prop: 'trialDate',
						width: 160,
						sortable: "custom",
					},
					{
						label: '岗位名称',
						prop: 'postName',
						width: 120,
						sortable: "custom",
					},
					{
						label: '工作地点',
						prop: 'workPiston',
						width: 180,
						sortable: "custom",
					},
					{
						label: '应达工作标准',
						prop: 'standard',
						width: 130,
						sortable: "custom",
					},
					{
						label: '工时制度',
						prop: 'workHour',
						width: 120,
						sortable: "custom",
					},
					{
						label: '休假制度',
						prop: 'vacation',
						width: 120,
						sortable: "custom",
					},
					{
						label: '工资制度',
						prop: 'wageSystem',
						width: 120,
						sortable: "custom",
					},
					{
						label: '月工资',
						prop: 'wage',
						width: 120,
						sortable: "custom",
					},
					{
						label: '发薪日期',
						prop: 'payDate',
						width: 110,
						sortable: "custom",
					},
					{
						label: '生活费标准',
						prop: 'alimony',
						width: 120,
						sortable: "custom",
					},
					{
						label: '待工工资',
						prop: 'waitWage',
						width: 120,
						sortable: "custom",
					},
					{
						label: '其他工资',
						prop: 'otherWage',
						width: 120,
						sortable: "custom",
					},
					{
						label: '病假工资',
						prop: 'leaveWage',
						width: 120,
						sortable: "custom",
					},
					{
						label: '福利待遇',
						prop: 'welfare',
						width: 120,
						sortable: "custom",
					},
					{
						label: '其他事项',
						prop: 'otherMatters',
						width: 120,
						sortable: "custom",
					},
					{
						label: '劳动保护条款',
						prop: 'protectionClause',
						width: 180,
						sortable: "custom",
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: "custom",
					},
					{
						label: '公司名称',
						prop: 'company',
						width: 150,
						sortable: "custom",
					},
					{
						label: '法人代表',
						prop: 'legalPerson',
						width: 120,
						sortable: "custom",
					},
					{
						label: '公司地址',
						prop: 'companyAddress',
						width: 180,
						sortable: "custom",
					},
					{
						label: '签订操作人',
						prop: 'fz_signWorkerName',
						width: 130,
						sortable: "custom",
					},
					{
						label: '终止操作人',
						prop: 'fz_stopWorkerName',
						width: 130,
						sortable: "custom",
					},
					{
						label: '终止操作日期',
						prop: 'stopDate',
						width: 200,
						sortable: "custom",
					},

					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						sortable: "custom",
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						sortable: "custom",
					},
				],
				searchConfig: {
					//表单标题宽度
					labelWidth: 120,
					//查询表单项配置
					itemList: [
						{
							label: '职员姓名',
							prop: 'workerName',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '工号',
							prop: 'workerCode',
							type: 'input',
							default: true,
							max: 255,
						},
						{
							label: '部门',
							default: true,
							prop: 'fz_departmentName',
							type: 'popDepartment',
							fields: ['departmentId', 'fz_departmentName']
						},
						{
							label: '性别',
							default: true,
							prop: 'gender',
							type: 'select',
							data: [
								{
									label: '男',
									value: 1,
								},
								{
									label: '女',
									value: 0,
								},
							]
						},
						{
							label: '部门名称',
							prop: 'departmentName',
							type: 'input',
							max: 32,
						},
						{
							label: '出生日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_birthday0',
								},
								{
									prop: 'fz_birthday1',
								},
							],
						},
						{
							label: '证件类型',
							prop: 'certType',
							type: 'selectDicts',
							typeCode: 'certType'
						},
						{
							label: '证件号',
							prop: 'certNumber',
							type: 'input',
							max: 32,
						},
						{
							label: '家庭住址',
							prop: 'homeAddress',
							type: 'input',
							max: 255,
						},
						{
							label: '籍贯',
							prop: 'nativePlace',
							type: 'input',
							max: 255,
						},
						{
							label: '用工形式',
							prop: 'employment',
							type: 'selectDicts',
							typeCode: 'employment'
						},
						{
							label: '到岗日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{prop: 'fz_reportDate0'},
								{prop: 'fz_reportDate1'},
							],
						},
						{
							label: '劳动合同类型',
							prop: 'contractType',
							type: 'selectDicts',
							typeCode: 'contractType'
						},
						{
							label: '合同期限类型',
							prop: 'termType',
							type: 'selectDicts',
							typeCode: 'termType'
						},
						{
							label: '签约日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{prop: 'fz_signDate0'},
								{prop: 'fz_signDate1'},
							],
						},
						{
							label: '合同期限',
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_contractTerm0',
									append: '月',
									max: 3,
								},
								{
									prop: 'fz_contractTerm1',
									append: '月',
									max: 3,
								},
							]
						},
						{
							label: '生效日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_effectDate0',
								},
								{
									prop: 'fz_effectDate1',
								},
							],
						},
						{
							label: '到期日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_expireDate0',
								},
								{
									prop: 'fz_expireDate1',
								},
							],
						},
						{
							label: '试用期限',
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_trialTerm0',
									append: '月',
									max: 3,
								},
								{
									prop: 'fz_trialTerm1',
									append: '月',
									max: 3,
								},
							]
						},
						{
							label: '试用到期日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_trialDate0',
								},
								{
									prop: 'fz_trialDate1',
								},
							],
						},
						{
							label: '岗位名称',
							prop: 'postName',
							type: 'input',
							max: 255,
						},
						{
							label: '工作地点',
							prop: 'workPiston',
							type: 'input',
							max: 255,
						},
						{
							label: '工时制度',
							prop: 'workHour',
							type: 'input',
							max: 255,
						},
						{
							label: '休假制度',
							prop: 'vacation',
							type: 'input',
							max: 255,
						},
						{
							label: '工资制度',
							prop: 'wageSystem',
							type: 'input',
							max: 255,
						},
						{
							label: '月工资',
							type: 'rangeNum',
							fields: [
								{prop: 'fz_wage0', max: 24},
								{prop: 'fz_wage1', max: 24},
							]
						},
						{
							label: '工资说明',
							prop: 'wageExplain',
							type: 'input',
							max: 255,
						},
						{
							label: '试用工资',
							type: 'rangeNum',
							fields: [
								{prop: 'fz_trialWage0', max: 24},
								{prop: 'fz_trialWage1', max: 24},
							]
						},
						{
							label: '发薪日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_payDate0',
								},
								{
									prop: 'fz_payDate1',
								},
							],
							append: '日'
						},
						{
							label: '生活费标准',
							type: 'rangeNum',
							fields: [
								{prop: 'fz_alimony0', max: 24},
								{prop: 'fz_alimony1', max: 24},
							]
						},
						{
							label: '待工工资',
							type: 'rangeNum',
							fields: [
								{prop: 'fz_waitWage0', max: 24},
								{prop: 'fz_waitWage1', max: 24},
							]
						},
						{
							label: '其他工资',
							type: 'rangeNum',
							fields: [
								{prop: 'fz_otherWage0', max: 24},
								{prop: 'fz_otherWage1', max: 24},
							]
						},
						{
							label: '病假工资',
							type: 'rangeNum',
							fields: [
								{prop: 'fz_leaveWage0', max: 24},
								{prop: 'fz_leaveWage2', max: 24},
							]
						},
						{
							label: '福利待遇',
							prop: 'welfare',
							type: 'input',
							max: 255,
						},
						{
							label: '其他事项',
							prop: 'otherMatters',
							type: 'input',
							max: 255,
						},
						{
							label: '劳动保护条款',
							prop: 'protectionClause',
							type: 'input',
							max: 255,
						},
						{
							label: '备注',
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '公司名称',
							prop: 'company',
							type: 'input',
							max: 255,
						},
						{
							label: '法人代表',
							prop: 'legalPerson',
							type: 'input',
							max: 255,
						},
						{
							label: '公司地址',
							prop: 'companyAddress',
							type: 'input',
							max: 255,
						},
						{
							label: '签订操作人',
							prop: 'fz_signWorkerName',
							type: 'popUser',
              max:32,
							fields: ['signWorkerId', 'fz_signWorkerName']
						},
						{
							label: '终止操作人',
							prop: 'fz_stopWorkerName',
							type: 'popUser',
							fields: ['stopWorkerId', 'fz_stopWorkerName'],
              max:32,
						},
						{
							label: '终止操作日期',
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{prop: 'fz_stopDate0'},
								{prop: 'fz_stopDate1'},
							],
						},
						{
							label: '合同状态',
							prop: 'contractStatus',
							type: 'select',
							data: [
								{label: '待执行', value: 0},
								{label: '执行中', value: 1},
								{label: '已终止', value: 2},
							]
						},
						{
							label: '操作用户',
							prop: 'fz_operateName',
							type: 'popUser',
              max:32,
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			},
		})

		//只能输入数字（负数、小数）
		const handleNumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetNum(val)
			}
		}

		//只能输入正整数
		const handleZnumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetZnum(val)
			}
		}

		//提交方法
		const submitSave = () => {
			nextTick(() => {
				state['myForm'].validate((valid) => {
					if (valid) {
						if (!state.form.role) {
							state.form.roleId = null;
						}
						let res = ''
						proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, async () => {
							state.isLoading = true
							if (state.form.id) { //修改，id不为空
								res = await proxy.$API.laborContract.update.put(state.form)
							} else { //新增
								res = await proxy.$API.laborContract.insert.post(state.form)
							}
							if (res.code === 200) {
								goBack(res.msg)
							} else {
								window.parent.postMessage({
									cmd: 'tips_error',
									msg: res.msg,
								}, '*');
							}
							state.isLoading = false
						})
					} else {
						//滚动到顶部
						document.getElementById('main').scrollTop = 0
					}
				})
			})
		}
		//返回列表页
		const goBack = (msg) => {
			window.parent.postMessage({
				jump: true,
				cmd: 'goBack',
				msg: msg,
				path: '/hr/labor/contract/stop'
			}, '*');
		}

		//重置表单
		const resetForm = () => {
			state['myForm'].resetFields()
			if (state.form.id) {
				fetchData(state.form)
			}
		}

		//监听文本框输入 field：字段名
		const handleInput = (val, field) => {
			if (field && field === 'name') {
				//获取名称首字母赋值给编码
				state.form['number'] = Pinyin.getWordsCode(val)
			}
		}

		//点击复制信息按钮
		const copyData = () => {
			//dom元素加载完后执行
			nextTick(() => {
				//将点击按钮传给弹窗对象
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//显示弹窗
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息选择后返回
		const setFormData = async (data) => {
			//获取联系人列表
			await fetchData(data, 1);
		}

		const setPage = () => {
			const param = proxy.$TOOL.data.get('param_labor_contract')
			if (JSON.stringify(param) !== '{}') {
				//根据id查询
				fetchData(param)
			} else {
				state['myForm'].resetFields()
			}
		}

		onMounted(() => {
			window.onresize = () => {
				state.span = setColSpan()
			}
			setPage()
		})

		onMounted(() => {
			//消息监听
			window.addEventListener("message", handleMessage)
		})

		onUnmounted(() => {
			//移除监听
			window.removeEventListener('message', handleMessage)
		})

		//处理接收的消息
		const handleMessage = (event) => {
			const cmd = event.data.cmd
			if(cmd === 'edit'){
				setPage()
			}
		}

		//拉取数据
		const fetchData = async (data, type) => {
			if (data.id) { //修改
				//获取主信息
				await setData(data.id)
				//如果是复制信息则删掉id
				if (type === 1) {
					delete state.form.id
					delete state.form.contractStatus
				}
			}
		}

		//根据id获取数据赋值给表单
		const setData = async (id) => {
			//详情接口
			const res = await proxy.$API.laborContract.detail.get(id)
			if (res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//详情
		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if (type === 1) { //单选
					state.multiples = false
				} else { //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if (state.fields.length > 0) {
				if (data.length) { //多选
					const ids = data.map((item) => {
						return item.id
					}).join()
					const names = data.map((item) => {
						return item.name
					}).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				} else { //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
					if (state.fields[1] === 'workerName') {
						state.form.workerCode = data.code
					}
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}
		const inputBlur = (field) => {
			state.form['fz_' + field + 'Name'] = state.cacheFields['fz_' + field + 'Name']
			state.form['fz_' + field + 'Code'] = state.cacheFields['fz_' + field + 'Code']
		}

		//输入框清除
		const inputClear = (field) => {
			state.form[field] = ''
			state.form['fz_' + field + 'Name'] = ''
			state.form['fz_' + field + 'Code'] = ''
			state.cacheFields['fz_' + field + 'Name'] = ''
			state.cacheFields['fz_' + field + 'Code'] = ''
		}

		const dateRangeChange = (val, fields) => {
			if (state.form[fields[0]] && state.form[fields[1]]) {
				const start = state.form[fields[0]]
				const end = state.form[fields[1]]
				if (Date.parse(start) > Date.parse(end)) {
					proxy.$baseMessage('到期日期不能小于生效日期', 'error')
					state.form[fields[1]] = ''
				}
			}
		}

		return {
			...toRefs(state),
			setSelectData,
			inputClear,
			inputBlur,
			popSelect,
			handleDetail,
			copyData,
			setFormData,
			handleInput,
			submitSave,
			resetForm,
			handleZnumIpt,
			handleNumIpt,
			dateRangeChange,
		}
	},
})
</script>
