import config from "@/config"
import http from "@/utils/request"

export default {
	selectDetail: {
		url: `${config.API_URL}/hr/wage/package/detail/getPackageDetailList`,
		name: "查询列表数据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getPackageDetails: {
		url: `${config.API_URL}/hr/wage/package/detail/getPackageDetails`,
		name: "根据主表id查询列表数据",
		get: async function (packAgeId) {
			return await http.get(this.url + "?packAgeId=" + packAgeId);
		}
	},
}
