import config from "@/config"
import http from "@/utils/request"
/*列管理*/
export default {
	getList: {
		url: `${config.API_URL}/cfg/system/column/getList`,
		name: "分页查询",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getAllList: {
		url: `${config.API_URL}/cfg/system/column/getAllList`,
		name: "查询所有列",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	del: {
		url: `${config.API_URL}/cfg/system/column/delete`,
		name: "删除",
		get: async function(data={}){
			return await http.get(this.url + '?ids=' + data);
		}
	},
	deleteByTableName: {
		url: `${config.API_URL}/cfg/system/column/deleteByTableName`,
		name: "根据表名删除所有",
		get: async function(data={}){
			return await http.get(this.url + '?tableName=' + data);
		}
	},
	insert: {
		url: `${config.API_URL}/cfg/system/column/insert`,
		name: "批量新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/cfg/system/column/update`,
		name: "修改",
		post: async function(data={}){
			return await http.put(this.url,data);
		}
	},
}
