<template>
  <sc-table-select
    v-model="value"
    v-bind="$attrs"
    :api-obj="apiObj"
    :table-width="config.tableWidth || 500"
    :multiple="config.multiple"
    :params="config.params"
    :props="config.props"
    style="width: 100%;"
  >
    <el-table-column
      v-for="(_item, _index) in config.columns"
      :key="_index"
      :prop="_item.prop"
      :label="_item.label"
      :width="_item.width"
    />
  </sc-table-select>
</template>

<script>
export default {
	name: 'CustomerSel',
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: [String, Number, Boolean, Date, Object, Array],
		config: { type: Object, default: () => {} }
	},
	emits: ['update:modelValue', 'changes'],
	data() {
		return {
			value: this.modelValue,
			apiObj: this.getApiObj()
		}
	},
	watch:{
		value:{
			handler(val){
				this.$emit("update:modelValue", val)
			},
			deep: true,
		},
    modelValue: {
      handler(val){
        this.value = val
      },
      deep: true,
    }
	},
	mounted() {

	},
	methods: {
		getApiObj(){
			return this.config.apiObj
		}
	}
}
</script>

