<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :bef-close="false"
    :trigger-dom="triggerDom"
  >
    <el-table
      :data="data"
      class="edit_table"
      border
      size="small"
    >
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="70"
        fixed="left"
      >
        <template #default="{ row }">
          <el-button
            circle
            title="增加行"
            class="iconBtn"
            plain
            size="small"
            type="primary"
            @click="handleAddRow(row)"
          >
            <el-icon><el-icon-plus /></el-icon>
          </el-button>
          <el-button
            circle
            title="删除行"
            class="iconBtn"
            plain
            size="small"
            type="danger"
            @click="handleDelRow(row)"
          >
            <el-icon><el-icon-minus /></el-icon>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :fixed="item.fixed"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.showOverflowTooltip || true"
        :sortable="item.sortable"
        :width="item.width"
        :class-name="item.disable ? 'bgGray' : ''"
      >
        <template #default="scope">
          <template v-if="item.edit === 'date'">
            <el-date-picker
              v-model="scope.row[item.prop]"
              value-format="YYYY-MM-DD"
              size="small"
              clearable
              style="width: 100%;"
            />
          </template>
          <template v-else-if="item.edit === 'number'">
            <el-input
              v-model="scope.row[item.prop]"
              size="small"
              clearable
              :maxlength="item.maxlength"
              @input="(val) => handleInput(val, scope.row, item)"
            />
          </template>
          <template v-else-if="item.edit === 'input'">
            <el-input
              v-model="scope.row[item.prop]"
              size="small"
              clearable
              :maxlength="item.maxlength"
              @input="(val) => handleInput(val, scope.row, item)"
              @blur="(val) =>inputBlur(val, scope.row, item)"
            />
          </template>
          <template v-else>
            <span class="cellSp">
              <template v-if="item.prop === 'stype'">
                <span v-if="scope.row[item.prop] === 0">成本价</span>
                <span v-if="scope.row[item.prop] === 1">销售价</span>
              </template>
              <template v-else>
                {{ scope.row[item.prop ] }}
              </template>
            </span>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	watch,
} from 'vue'
import {resetNum, resetZnum} from "@/utils/validate";
export default defineComponent({
	name: "GoodsPricePop",
	emits: ['set-price'],
	setup(props, { emit }) {
		const {proxy} = getCurrentInstance()
		const state = reactive({
			columns: [
				{
					label: '价格名称',
					prop: 'name',
					width: 150,
					edit: 'input',
				},
				{
					label: '价格',
					prop: 'price',
					edit: 'number',
          max: 20,
					width: 110,
				},
			],
			blankitem: {
				name: '',
				price: '',
			},
			data: [],
			cacheData: [],
			loading: false,
			visible: false,
			title: '预设价格',
			triggerDom: {},
		})
		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			if(data && data.length > 0){
				data.forEach((item) => {
					item['name'] = item.fz_goodsPriceName
				})
				state.data = data
				state.cacheData = JSON.parse(JSON.stringify(data))
			}else{
				fetchData()
			}
		}
		const fetchData = async () => {
			const res = await proxy.$API.goodsPrice.getList.get({
				pageSize: 1000,
				pageNum: 1,
			})
			if(res.code === 200){
				if(res.data.list && res.data.list.length > 0){
					res.data.list.forEach((item) => {
						item['goodsPriceId'] = item.id
						item['fz_goodsPriceName'] = item.name
					})
					state.data = res.data.list
					state.cacheData = JSON.parse(JSON.stringify(res.data.list))
				}
			}
		}
		watch(
			() => state.data,
			(data) => {
				data.forEach((item) => {
					item['fz_goodsPriceName'] = item.name
				})
				emit('set-price', data)
			},
			{ deep: true }
		)
		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
		}
		//输入框输入 val:输入的值， item: 传入的行数据
		const handleInput = (val, row, item) => {
			//数字-负数、小数
			if (item.edit === 'number') {
				row[item.prop] = resetNum(val)
			}
			//正整数
			if (item.edit === 'num') {
				row[item.prop] = resetZnum(val)
			}
		}
		//输入框失去焦点
		const inputBlur = (val, row, item) => {
			const value = val.target.value
			const rowIndex = state.data.indexOf(row)
			if(item.prop === 'name'){
				//如果name为空还原为原来的值
				if(value === ''){
					if(state.cacheData[rowIndex][item.prop]){
						row[item.prop] = state.cacheData[rowIndex][item.prop]
					}
				}
			}
		}

		//添加空白行
		const handleAddRow = (row) => {
			const index = state.data.indexOf(row)
			state.data.splice(index + 1, 0, Object.assign({}, state.blankitem))
		}

		//删除行
		const handleDelRow = (row) => {
			const index = state.data.indexOf(row)
			if (state.data.length > 1) {
				state.data.splice(index, 1)
			} else {
				proxy.$baseMessage('必须保留一条', 'error')
			}
		}
		return {
			...toRefs(state),
			handleDelRow,
			handleAddRow,
			inputBlur,
			fetchData,
			showDialog,
			handleInput,
			hideDialog,
		}
	}
})
</script>

<style scoped>

</style>
