import config from "@/config"
import http from "@/utils/request"
export default {
	getPrintLayout: {
		url: `${config.API_URL}/cfg/system/print/printLayout`,
		name: "获取打印模板",
		get: async function(menuCode){
			return await http.get(this.url + '?menuCode=' + menuCode);
		}
	},
	setPrintLayout: {
		url: `${config.API_URL}/cfg/system/print/setPrintLayout`,
		name: "保存打印模板",
		post: async function(data = {}){
			return await http.post(this.url, data);
		}
	},
}
