import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/stock/material/getList`,
		name: "获取列表",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	delete: {
		url: `${config.API_URL}/mobile/stock/material/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	outDetail: {
		url: `${config.API_URL}/mobile/out/detail`,
		name: "详情",
		get: async function (id, warehouseNum) {
			return await http.get(this.url + '?id=' + id + "&warehouseNum=" + warehouseNum);
		}
	},
	outStock: {
		url: `${config.API_URL}/mobile/out/outStock`,
		name: "出库",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
