<template>
  <el-container>
    <splitpanes>
      <pane>
        <el-container>
          <el-main
              ref="lfContainer"
              id="wfMain"
              class="nopadding"
          >
          <!--<div class="wfOperate">
              <el-button>保存xml</el-button>
              <el-button>保存图片</el-button>
            </div>-->
          </el-main>
        </el-container>
      </pane>
      <pane
          v-if="editAble"
          size="30"
          max-size="50"
          min-size="10"
      >
        <PropertyUserTask
            v-show="Object.keys(curNode).length > 0 && curNode.type === 'bpmn:userTask'"
            ref="refPropertyUserTask"
            @set-data="setData"
        />
        <PropertySequenceFlow
            v-show="Object.keys(curNode).length > 0 && curNode.type === 'bpmn:sequenceFlow'"
            ref="refPropertySequenceFlow"
            @set-sequence="setSequence"
        />
        <GraphProperty
            v-show="Object.keys(curNode).length === 0"
            ref="refGraphProperty"
            @set-graph-data="setGraphData"
        />
      </pane>
    </splitpanes>
  </el-container>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
  nextTick,
} from "vue";
//logic核心
import LogicFlow from "@logicflow/core";
//logic扩展插件
import {
  BpmnElement,
  MiniMap,
  FlowPath,
  AutoLayout,
  DndPanel,
  Menu,
  ContextMenu,
  Group,
  Control,
  BpmnXmlAdapter,
  BpmnAdapter,
  Snapshot,
  SelectionSelect,
} from "@logicflow/extension";
//流程扩展样式
import "@logicflow/extension/lib/style/index.css";
//流程核心样式
import "@logicflow/core/dist/style/index.css";
//自定义元素-并行网关
import ParallelGateway from "./parallelGateway";
//自定义元素-包含网关组件
import InclusiveGateway from "./inclusiveGateway";
//用户任务属性设置vue组件
import PropertyUserTask from "./property_usertask";
//箭头线条属性设置vue组件
import PropertySequenceFlow from "./property_sequenceFlow";
//流程属性设置
import GraphProperty from "./graphProperty";

export default defineComponent({
  components: {
    GraphProperty,
    PropertyUserTask,
    PropertySequenceFlow,
  },
  props: {
    editAble: {type: Boolean, default: true},
  },
  setup(props) {
    const {proxy} = getCurrentInstance();

    const state = reactive({
      //流程对象
      lf: {},
      //当前选中节点
      curNode: {},
      //流程属性表单
      graphForm: {},
    });

    const startConfig = {
      type: "bpmn:startEvent",
      text: "开始",
      label: "开始",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAABGdBTUEAALGPC/xhBQAAAnBJREFUOBGdVL1rU1EcPfdGBddmaZLiEhdx1MHZQXApraCzQ7GKLgoRBxMfcRELuihWKcXFRcEWF8HBf0DdDCKYRZpnl7p0svLe9Zzbd29eQhTbC8nv+9zf130AT63jvooOGS8Vf9Nt5zxba7sXQwODfkWpkbjTQfCGUd9gIp3uuPP8bZ946g56dYQvnBg+b1HB8VIQmMFrazKcKSvFW2dQTxJnJdQ77urmXWOMBCmXM2Rke4S7UAW+/8ywwFoewmBps2tu7mbTdp8VMOkIRAkKfrVawalJTtIliclFbaOBqa0M2xImHeVIfd/nKAfVq/LGnPss5Kh00VEdSzfwnBXPUpmykNss4lUI9C1ga+8PNrBD5YeqRY2Zz8PhjooIbfJXjowvQJBqkmEkVnktWhwu2SM7SMx7Cj0N9IC0oQXRo8xwAGzQms+xrB/nNSUWVveI48ayrFGyC2+E2C+aWrZHXvOuz+CiV6iycWe1Rd1Q6+QUG07nb5SbPrL4426d+9E1axKjY3AoRrlEeSQo2Eu0T6BWAAr6COhTcWjRaYfKG5csnvytvUr/WY4rrPMB53Uo7jZRjXaG6/CFfNMaXEu75nG47X+oepU7PKJvvzGDY1YLSKHJrK7vFUwXKkaxwhCW3u+sDFMVrIju54RYYbFKpALZAo7sB6wcKyyrd+aBMryMT2gPyD6GsQoRFkGHr14TthZni9ck0z+Pnmee460mHXbRAypKNy3nuMdrWgVKj8YVV8E7PSzp1BZ9SJnJAsXdryw/h5ctboUVi4AFiCd+lQaYMw5z3LGTBKjLQOeUF35k89f58Vv/tGh+l+PE/wG0rgfIUbZK5AAAAABJRU5ErkJggg==",
    };

    const userConfig = {
      type: "bpmn:userTask",
      label: "用户任务",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAEFVwZaAAAABGdBTUEAALGPC/xhBQAAAqlJREFUOBF9VM9rE0EUfrMJNUKLihGbpLGtaCOIR8VjQMGDePCgCCIiCNqzCAp2MyYUCXhUtF5E0D+g1t48qAd7CCLqQUQKEWkStcEfVGlLdp/fm3aW2QQdyLzf33zz5m2IsAZ9XhDpyaaIZkTS4ASzK41TFao88GuJ3hsr2pAbipHxuSYyKRugagICGANkfFnNh3HeE2N0b3nN2cgnpcictw5veJIzxmDamSlxxQZicq/mflxhbaH8BLRbuRwNtZp0JAhoplVRUdzmCe/vO27wFuuA3S5qXruGdboy5/PRGFsbFGKo/haRtQHIrM83bVeTrOgNhZReWaYGnE4aUQgTJNvijJFF4jQ8BxJE5xfKatZWmZcTQ+BVgh7s8SgPlCkcec4mGTmieTP4xd7PcpIEg1TX6gdeLW8rTVMVLVvb7ctXoH0Cydl2QOPJBG21STE5OsnbweVYzAnD3A7PVILuY0yiiyDwSm2g441r6rMSgp6iK42yqroI2QoXeJVeA+YeZSa47gZdXaZWQKTrG93rukk/l2Al6Kzh5AZEl7dDQy+JjgFahQjRopSxPbrbvK7GRe9ePWBo1wcU7sYrFZtavXALwGw/7Dnc50urrHJuTPSoO2IMV3gUQGNg87IbSOIY9BpiT9HV7FCZ94nPXb3MSnwHn/FFFE1vG6DTby+r31KAkUktB3Qf6ikUPWxW1BkXSPQeMHHiW0+HAd2GelJsZz1OJegCxqzl+CLVHa/IibuHeJ1HAKzhuDR+ymNaRFM+4jU6UWKXorRmbyqkq/D76FffevwdCp+jN3UAN/C9JRVTDuOxC/oh+EdMnqIOrlYteKSfadVRGLJFJPSB/ti/6K8f0CNymg/iH2gO/f0DwE0yjAFO6l8JaR5j0VPwPwfaYHqOqrCI319WzwhwzNW/aQAAAABJRU5ErkJggg==",
    };
    const exclusiveGatewayConfig = {
      type: "bpmn:exclusiveGateway",
      label: "排它网关",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAAHeEJUAAAAABGdBTUEAALGPC/xhBQAAAvVJREFUOBGNVEFrE0EU/mY3bQoiFlOkaUJrQUQoWMGePLX24EH0IIoHKQiCV0G8iE1covgLiqA/QTzVm1JPogc9tIJYFaQtlhQxqYjSpunu+L7JvmUTU3AgmTfvffPNN++9WSA1DO182f6xwILzD5btfAoQmwL5KJEwiQyVbSVZ0IgRyV6PTpIJ81E5ZvqfHQR0HUOBHW4L5Et2kQ6Zf7iAOhTFAA8s0pEP7AXO1uAA52SbqGk6h/6J45LaLhO64ByfcUzM39V7ZiAdS2yCePPEIQYvTUHqM/n7dgQNfBKWPjpF4ISk8q3J4nB11qw6X8l+FsF3EhlkEMfrjIer3wJTLwS2aCNcj4DbGxXTw00JmAuO+Ni6bBxVUCvS5d9aa04+so4pHW5jLTywuXAL7jJ+D06sl82Sgl2JuVBQn498zkc2bGKxULHjCnSMadBKYDYYHAtsby1EQ5lNGrQd4Y3v4Zo0XdGEmDno46yCM9Tk+RiJmUYHS/aXHPNTcjxcbTFna000PFJHIVZ5lFRqRpJWk9/+QtlOUYJj9HG5pVFEU7zqIYDVsw2s+AJaD8wTd2umgSCCyUxgGsS1Y6TBwXQQTFuZaHcd8gAGioE90hlsY+wMcs30RduYtxanjMGal8H5dMW67dmT1JFtYUEe8LiQLRsPZ6IIc7A4J5tqco3T0pnv/4u0kyzrYUq7gASuEyI8VXKvB9Odytv6jS/PNaZBln0nioJG/AVQRZvApOdhjj3Jt8QC8Im09SafwdBdvIpztpxWxpeKCC+EsFdS8DCyuCn2munFpL7ctHKp+Xc5cMybeIyMAN33SPL3ZR9QV1XVwLyzHm6Iv0/yeUuUb7PPlZC4D4HZkeu6dpF4v9j9MreGtMbxMMRLIcjJic9yHi7WQ3yVKzZVWUr5UrViJvn1FfUlwe/KYVfYyWRLSGNu16hR01U9IacajXPei0wx/5BqgInvJN+MMNtNme7ReU9SBbgntovn0kKHpFg7UogZvaZiOue/q1SBo9ktHzQAAAAASUVORK5CYII=",
    };
    const parallelGatewayConfig = {
      type: "bpmn:parallelGateway",
      label: "并行网关",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LjljY2M0ZGU5MywgMjAyMi8wMy8xNC0xNDowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjlFMjQxOTRGMDgwRjExRURCMjZDQzk1RDIyQzk3NEExIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjlFMjQxOTUwMDgwRjExRURCMjZDQzk1RDIyQzk3NEExIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OUUyNDE5NEQwODBGMTFFREIyNkNDOTVEMjJDOTc0QTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OUUyNDE5NEUwODBGMTFFREIyNkNDOTVEMjJDOTc0QTEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4iTqq8AAABE0lEQVR42qSV0QnCMBCGm9AHcQIfnaETOIOuoIIiqAM4goq16ptT6CT6Jg5SC9YL3MERmuRSD35I2vDlz3HJqd6mToSxA71AF8niVAjdg1YgchGE6whohfMTaPYvmKAf0BC0lMJTIXQEurF/B4Q706JbQI8S5zoSKobrCGjNqiII11adhpyGnM9tsIGuI6FN8ILgpiq2CK0cx7eDf1MMTtVSkOM3Ljab9JP28QCVuFlmYGd2jJyNuSPuVDVAB6A7qAO6gqaUYwNf4DhnY0kQtIvQCejLb57PuRQ6ppPZV1qaFi/UdfMkafFCfW+FDx6Ehl63prQ8JVBJB7HhJSspJ1TaQXhaRNCYnkfOM6zTYAf+CTAAKlhlwKTu3oUAAAAASUVORK5CYII=",
    };
    const inclusiveGatewayConfig = {
      type: "bpmn:inclusiveGateway",
      label: "包含网关",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LjljY2M0ZGU5MywgMjAyMi8wMy8xNC0xNDowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU4NTAwMjUzMDcxMTExRURBRDRFRTNGNzBEQjY2RUFFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjU4NTAwMjU0MDcxMTExRURBRDRFRTNGNzBEQjY2RUFFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTg1MDAyNTEwNzExMTFFREFENEVFM0Y3MERCNjZFQUUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTg1MDAyNTIwNzExMTFFREFENEVFM0Y3MERCNjZFQUUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz44nFTyAAABjElEQVR42qSVu0oEMRSGZ4KCutgJWrlrZ+EreO1crQQFtRDttbQQFgRB8CmsRFCx8VaJ6/gICgoWO2O9pa6ggv5n+SOHOJeAgY8zk02+JGeTTDhQ+w48yhDYBysgKWpsPIQVcAPGGcv/lYqwTlGLMy4UG0+hjZGP2HgKZ0ATVH3EJieH0uGWwhZ/e0sRDxZJrbBCYVUJA0d8R3HdFWtpOUM4D+7BB3gESxRPZ4mNEtZThHPgCIyATjAMDsBintg4wshZ8jbjFiiBTb7XMlLRznGIEyUPE+CdIzVVSqSuC/SCVz5L3Rdnbks/aIBucC0zXQUxK05Aj2r8zLjO+g2nPuAKjtlfxGuGwklGOYoXSmyXv8ul7vF9RwkvwSiF4nmxf5QVJ0yFFZ+CBfAAPsETWAaHSjimhO3LJnRuKX2SJNezKfs04IBXacK0zR9zpgkbnjs5LhRmHdM8sRbGacK8C0V3kHgG+lQO9cB/SkfO1WeXJrmdoqhUJPS5pH+3ia/Q93PSoCjKyqFbfgQYAM2AgT3nCg3SAAAAAElFTkSuQmCC",
    };
    const endConfig = {
      type: "bpmn:endEvent",
      label: "结束",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAABGdBTUEAALGPC/xhBQAAA1BJREFUOBFtVE1IVUEYPXOf+tq40Y3vPcmFIdSjIorWoRG0ERWUgnb5FwVhYQSl72oUoZAboxKNFtWiwKRN0M+jpfSzqJAQclHo001tKkjl3emc8V69igP3znzfnO/M9zcDcKT67azmjYWTwl9Vn7Vumeqzj1DVb6cleQY4oAVnIOPb+mKAGxQmKI5CWNJ2aLPatxWa3aB9K7/fB+/Z0jUF6TmMlFLQqrkECWQzOZxYGjTlOl8eeKaIY5yHnFn486xBustDjWT6dG7pmjHOJd+33t0iitTPkK6tEvjxq4h2MozQ6WFSX/LkDUGfFwfhEZj1Auz/U4pyAi5Sznd7uKzznXeVHlI/Aywmk6j7fsUsEuCGADrWARXXwjxWQsUbIupDHJI7kF5dRktg0eN81IbiZXiTESic50iwS+t1oJgL83jAiBupLDCQqwziaWSoAFSeIR3P5Xv5az00wyIn35QRYTwdSYbz8pH8fxUUAtxnFvYmEmgI0wYXUXcCCSpeEVpXlsRhBnCEATxWylL9+EKCAYhe1NGstUa6356kS9NVvt3DU2fd+Wtbm/+lSbylJqsqkSm9CRhvoJVlvKPvF1RKY/FcPn5j4UfIMLn8D4UYb54BNsilTDXKnF4CfTobA0FpoW/LSp306wkXM+XaOJhZaFkcNM82ASNAWMrhrUbRfmyeI1FvRBTpN06WKxa9BK0o2E4Pd3zfBBEwPsv9sQBnmLVbLEIZ/Xe9LYwJu/Er17W6HYVBc7vmuk0xUQ+pqxdom5Fnp55SiytXLPYoMXNM4u4SNSCFWnrVIzKG3EGyMXo6n/BQOe+bX3FClY4PwydVhthOZ9NnS+ntiLh0fxtlUJHAuGaFoVmttpVMeum0p3WEXbcll94l1wM/gZ0Ccczop77VvN2I7TlsZCsuXf1WHvWEhjO8DPtyOVg2/mvK9QqboEth+7pD6NUQC1HN/TwvydGBARi9MZSzLE4b8Ru3XhX2PBxf8E1er2A6516o0w4sIA+lwURhAON82Kwe2iDAC1Watq4XHaGQ7skLcFOtI5lDxuM2gZe6WFIotPAhbaeYlU4to5cuarF1QrcZ/lwrLaCJl66JBocYZnrNlvm2+MBCTmUymPrYZVbjdlr/BxlMjmNmNI3SAAAAAElFTkSuQmCC",
    };
    const groupConfig = {
      type: "group",
      label: "分组",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LjljY2M0ZGU5MywgMjAyMi8wMy8xNC0xNDowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkM4NTdGQkM5MDY5NTExRURBMkU3QTM2RTY2OTExQ0RFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkM4NTdGQkNBMDY5NTExRURBMkU3QTM2RTY2OTExQ0RFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qzg1N0ZCQzcwNjk1MTFFREEyRTdBMzZFNjY5MTFDREUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qzg1N0ZCQzgwNjk1MTFFREEyRTdBMzZFNjY5MTFDREUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4dR5flAAABB0lEQVR42mKUqP3PAAURQBwHxNxA/JcBN2AB4qdA3APEZ9ElQKAEiLsZSAMgy02B+AxMgAnqGpBByUDMSALeAcSnkU0HGZYHZc8j0WVRUFoS2bCvDOQBbjQaHGZ/yTSMFUq/AOJUIP7DwkA5+ALEU4D4HTUMA4E7QPyRiYGKYAQZxkmmXuT0+R9m2CcyDRNE9yEoaVQCsRsQbwTiMiD+Cc17uMB/aBbaB8Q3oGI8IEeBDDsHxFZQST8SXHYWqg8EuEAGwhLtcWjYSUKzySsg/kGkocZALAzE09FzwHMoHQ/EFUD8D4c3maFeYwdiUWiJU8uCJ5Y/4jAMBkDyf4B4MhA3gwQAAgwAc3kvdwcsj64AAAAASUVORK5CYII="
    };
    const selectionConfig = {
      label: "选区",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAABGdBTUEAALGPC/xhBQAAAOVJREFUOBGtVMENwzAIjKP++2026ETdpv10iy7WFbqFyyW6GBywLCv5gI+Dw2Bluj1znuSjhb99Gkn6QILDY2imo60p8nsnc9bEo3+QJ+AKHfMdZHnl78wyTnyHZD53Zzx73MRSgYvnqgCUHj6gwdck7Zsp1VOrz0Uz8NbKunzAW+Gu4fYW28bUYutYlzSa7B84Fh7d1kjLwhcSdYAYrdkMQVpsBr5XgDGuXwQfQr0y9zwLda+DUYXLaGKdd2ZTtvbolaO87pdo24hP7ov16N0zArH1ur3iwJpXxm+v7oAJNR4JEP8DoAuSFEkYH7cAAAAASUVORK5CYII=",
      callback: () => {
        state.lf.extension.selectionSelect.openSelectionSelect();
        state.lf.once("selection:selected", () => {
          state.lf.extension.selectionSelect.closeSelectionSelect();
        });
      }
    };

    const serviceConfig = {
      type: "bpmn:serviceTask",
      label: "系统任务",
      className: "import_icon",
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LjljY2M0ZGU5MywgMjAyMi8wMy8xNC0xNDowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQyMEUwOTA4MDY4MDExRUQ5QUEzQTdBNDY2MDVGQUMxIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQyMEUwOTA5MDY4MDExRUQ5QUEzQTdBNDY2MDVGQUMxIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDIwRTA5MDYwNjgwMTFFRDlBQTNBN0E0NjYwNUZBQzEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDIwRTA5MDcwNjgwMTFFRDlBQTNBN0E0NjYwNUZBQzEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz60IrDNAAABsklEQVR42oTUzSuEQRwH8PUkKZIDkpBkDyi7ypKXSPJyEVIOEg4OHLhw9UcoN0kuolUcyFsrF3tQRCktIuV1a0tZoZb1nfpuzf6a3Wfq0zMzz+xvZp75zabkz0YdNmUP3PAITfCVaGCqTaBc6GA9D0rgKtFgS7R3YB0y2HaL93VafRkOIc20sn1oZ90D29Angs0xYD242HcOVRDRg/1p9WKYMOwkC8YN/VG5sh64hDIxwRo8Qw70Q6b2/g1a4Vd+sx/wau0AlMMgzMAoOOFYG3PEgHEHoE6pC4bYVsffAtdiO6/sD7LdBr2x3VjMo3ueZBEHefUZRVFbWmJdbX0DbtSKLS2P9HJrk393hr4GFezM8KLQJliBoS+ggjVCDYxBiC8GID1JsBE+P2GSuedRwb7hFBZhgYOywcenvH6bPDAHE3seTuBD3s1O/RswPVbhiXdT3YhSbUwt8y4sk3YFqkVwFWAqyXbVCnehWSW4nrROra6+xVZsRq1E2P8ifmfJG6CuxQX4oRK6YVgEm2Z/BRzwM7g4Sdw2w4a/HL9o+/h8N+WnZZNPQQYMcRUPyQb/CzAAvTVYbv4lS40AAAAASUVORK5CYII=",
    };

    //删除按钮
    const commonMenuConfig = {
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAVhJREFUOE/VlLFKA0EQhmewEazshVjGJlgKWkSLNAGdveMqEayt4hMY9AXyCIKVhuy/TSwCFnZ2Jp1vIRaWZmTCBc6LF86ooNvcz+38383tzw7TnCUi68xcyZao6ksIYVhk46KNFPZIRKNcjX2k473vfOadB6wzcxtAPWsUEWHmVv79tIadc4GIDub9etk9Zj4t7LAsJF/3ARhFUcsKis4nbxaRTWY+BjDx2coD2ylw8ozjuNrr9Z5Mm3mabhRFW977BxGZOedCoHMuIaITALvOuT0i6gCoxXFcU9Vb7/3aIsAbABacAe9MG3A8Ho9MLwI8BCC/BTwHsJN2aJ1Xv9vh/wL+nVCuACz/WMqqehRC2Degql6EELbTlK8BbHwpZbtuSZIsdbvdN9ONRmNlMBi8mm42m6v9fv+5LLCiqpdlpo2q2nBw2dk4c5dV9SyF3ZeBEtEwO23eAUh+SIoWcATkAAAAAElFTkSuQmCC",
      callback: (data) => {
        state.lf.deleteElement(data.id);
        state.lf.hideContextMenu();
      }
    };

    const contextMenuSet = [
      userConfig,
      //serviceConfig,
      exclusiveGatewayConfig,
      parallelGatewayConfig,
      inclusiveGatewayConfig,
      endConfig,
      //groupConfig,
    ];

    let lfConfig = {
      //线条可拖动
      edgeTextDraggable: true,
      //节点可拖动
      nodeTextDraggable: true,
      //节点文本可编辑
      nodeTextEdit: false,
      //边线文本可编辑
      edgeTextEdit: false,
      //是否隐藏节点元素锚点
      hideAnchors: false,
      //引用插件
      plugins: [
        //导航图
        MiniMap,
        //
        FlowPath,
        //自动排版
        AutoLayout,
        //拖动面板
        DndPanel,
        //菜单
        Menu,
        //右键菜单
        ContextMenu,
        //分组
        Group,
        //控制面板
        Control,
        //快照插件
        Snapshot,
        //选取
        SelectionSelect,
        //适配BPMN元素
        BpmnElement,
        //转换数据适配BPMN
        BpmnAdapter,
        //BpmnXmlAdapter,
      ],
      //网格背景
      grid: {
        type: "dot",
        size: 20,
      },
      //键盘事件 Ctrl+c Ctrl+v  删除元素返回键←
      keyboard: {
        enabled: true,
      },
      //对齐线
      snapline: true,
      //静默模式，只读
      isSilentMode: false,
    };

    if (!props.editAble) {
      lfConfig.isSilentMode = true;
      lfConfig.hoverOutline = false;
      lfConfig.hideAnchors = true;
      lfConfig.plugins = [
        //导航图
        MiniMap,
        //
        FlowPath,
        //自动排版
        AutoLayout,
        //适配BPMN元素
        BpmnElement,
        //转换数据适配BPMN
        BpmnAdapter,
      ];
    }

    //组件挂载完成
    onMounted(() => {
      //元素加载完成
      nextTick(() => {
        //初始化流程
        state.lf = new LogicFlow({
          //指定加载容器
          container: proxy.$refs.lfContainer.$el,
          ...lfConfig
        });

        //批量注册自定义节点
        state.lf.batchRegister([ParallelGateway, InclusiveGateway]);
        if (!props.editAble) {
          return false;
        }
        //添加设置拖动面板元素
        state.lf.extension.dndPanel.setPatternItems([
          selectionConfig,
          startConfig,
          userConfig,
          //serviceConfig,
          exclusiveGatewayConfig,
          parallelGatewayConfig,
          inclusiveGatewayConfig,
          endConfig,
          //groupConfig,
        ]);

        //节点绑定快捷菜单
        state.lf.setContextMenuByType("bpmn:userTask", contextMenuSet);
        state.lf.setContextMenuByType("bpmn:startEvent", contextMenuSet);
        state.lf.setContextMenuByType("bpmn:exclusiveGateway", contextMenuSet);
        state.lf.setContextMenuByType("bpmn:parallelGateway", contextMenuSet);
        state.lf.setContextMenuByType("bpmn:inclusiveGateway", contextMenuSet);

        //控制面板添加图片保存按钮
        state.lf.extension.control.addItem({
          iconClass: "lf-control-saveImg",
          title: "另存为图片",
          text: "保存图片",
          onClick: (lf, ev) => {
            if (lf.getGraphRawData()["nodes"].length === 0 || lf.getGraphRawData()["edges"].length === 0) {
              proxy.$baseMessage("请画出您的杰作!", "error");
            } else {
              lf.getSnapshot();
            }
          }
        });

        //控制面板添加图片画布清空按钮
        state.lf.extension.control.addItem({
          iconClass: "lf-control-clear",
          title: "清空画布",
          text: "清空",
          onClick: (lf, ev) => {
            lf.clearData();
          }
        });

        //配置删除按钮
        state.lf.setContextMenuItems(commonMenuConfig);

        //构建流程面板
        state.lf.render();

        state.lf.on("node:dnd-add", (data) => {
          const graphData = state.lf.getGraphRawData();
          if (data.data.type === "bpmn:startEvent") {
            //判断只能有一个开始节点
            const nodes = graphData["nodes"].filter((item) => {
              return item.type === "bpmn:startEvent";
            });
            if (nodes.length > 1) {
              state.lf.deleteNode(data.data.id);
              proxy.$baseMessage("只能有一个开始节点", "error");
              return false;
            }
          } else if (data.data.type === "bpmn:endEvent") {
            //判断只能有一个结束节点
            const nodes = graphData["nodes"].filter((item) => {
              return item.type === "bpmn:endEvent";
            });
            if (nodes.length > 1) {
              state.lf.deleteNode(data.data.id);
              proxy.$baseMessage("只能有一个结束节点", "error");
              return false;
            }
          }
        });

        //节点点击 node:click-节点点击 node:add-节点新增 edge:click-边线点击 node:dnd-add节点拖动新增 edge:add-边线新增
        state.lf.on("node:click,edge:click,node:add,edge:add,node:dnd-add", (data) => {
          //根据ID选中节点或元素
          state.lf.selectElementById(data.data.id);

          //重置当前选中节点
          state.curNode = {};

          //用户任务
          if (data.data.type === "bpmn:userTask") {
            state.curNode = Object.assign({}, state.lf.getNodeDataById(data.data.id));
            //清空表单属性
            proxy.$refs.refPropertyUserTask.form = {};

            if (!state.curNode["properties"]["exclusive"]) {
              state.curNode["properties"]["exclusive"] = "true";
            }
            if (!state.curNode["properties"]["name"]) {
              state.curNode["properties"]["name"] = data.data.id;
            }
            if (!state.curNode["properties"]["nodeType"]) {
              state.curNode["properties"]["nodeType"] = 1;
            }
            //更新显示文本
            state.lf.updateText(data.data.id, state.curNode["properties"]["name"]);
            //赋值属性表单
            Object.assign(proxy.$refs.refPropertyUserTask.form, state.curNode["properties"]);
          }

          //箭头线条
          if (data.data.type === "bpmn:sequenceFlow") {
            state.curNode = Object.assign({}, state.lf.getEdgeDataById(data.data.id));
            proxy.$refs.refPropertySequenceFlow.form = {};
            proxy.$refs.refPropertySequenceFlow.list = [];
            if (state.curNode["properties"]["conditionArr"] && state.curNode["properties"]["conditionArr"].length > 0) {
              proxy.$refs.refPropertySequenceFlow.list = state.curNode["properties"]["conditionArr"];
            } else {
              proxy.$refs.refPropertySequenceFlow.initList();
            }
            //更新显示文本
            state.lf.updateText(data.data.id, state.curNode["properties"]["name"]);
            Object.assign(proxy.$refs.refPropertySequenceFlow.form, state.curNode["properties"]);
          }
        });

        //blank:click-画布空白点击事件 node:delete-节点删除 edge:delete-边线删除
        state.lf.on("blank:click,node:delete,edge:delete", () => {
          state.curNode = {};
        });

        //流程初始值
        if (proxy.$refs.refGraphProperty) {
          state.graphForm = Object.assign({}, proxy.$refs.refGraphProperty.form);
        }
      });
    });

    //设置流程属性
    const setGraphData = (data) => {
      state.graphForm = Object.assign({}, data);
    };

    //设置用户任务节点属性
    const setData = (data) => {
      if (data && JSON.stringify(state.curNode) !== "{}" && state.curNode.type === "bpmn:userTask") {
        const newData = Object.assign({}, data);
        state.curNode["properties"] = newData;
        let text = newData["name"];
        /*if(newData['assign']){
          text = text +'\n审核人:'+ newData['assign']
        }
        if(newData['candidateUserNames']){
          text = text +'\n审核人:'+ newData['candidateUserNames']
        }*/
        state.lf.updateText(state.curNode.id, text);
        state.lf.setProperties(state.curNode.id, newData);
      }
    };

    //设置线条属性
    const setSequence = (data) => {
      if (data && JSON.stringify(state.curNode) !== "{}" && state.curNode.type === "bpmn:sequenceFlow") {
        //更新显示文本
        state.lf.updateText(state.curNode.id, data[1]["name"]);
        let str = "";
        data[0].forEach((item) => {
          if (item["field"] && item["judge"] && item["value"]) {
            str += item["field"] + " " + item["judge"] + " " + item["value"] + " " + item["linkCode"];
          }
        });
        if (str !== "") {
          str = "${ " + str + " }";
        }
        //找出所有不为空的json
        const arr = data[0].filter((item) => {
          return item["field"] !== "" && item["judge"] !== "" && item["value"] !== "";
        });
        const properties = {condition: str, name: data[1]["name"] || "", conditionArr: arr};
        state.curNode["properties"] = properties;
        state.lf.setProperties(state.curNode.id, properties);
      }
    };

    const renderData = (data) => {
      const arr = {};
      nextTick(() => {
        if (data && state.lf) {
          Object.keys(data).forEach((item) => {
            if (item !== "process") {
              arr[item] = data[item];
            } else {
              if (props.editAble) {
                proxy.$refs.refGraphProperty.form = Object.assign({}, data[item]);
              }
            }
          });
          state.lf.renderRawData(arr);
        }
      });
    };

    // const debounce = (fn, delay) => {
    //   let timer = null;
    //   return function () {
    //     let context = this;
    //     let args = arguments;
    //     clearTimeout(timer);
    //     timer = setTimeout(function () {
    //       fn.apply(context, args);
    //     }, delay);
    //   };
    // };
	//
    // const _ResizeObserver = window.ResizeObserver;
    // window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    //   constructor(callback) {
    //     callback = debounce(callback, 16);
    //     super(callback);
    //   }
    // };
    return {
      ...toRefs(state),
      renderData,
      setSequence,
      setData,
      setGraphData
    };
  }
});
</script>
<style scoped>
#wfMain {
  overflow: hidden;
}
</style>
