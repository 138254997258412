import config from "@/config"
import http from "@/utils/request"

export default {
	selectRecord: {
		url: `${config.API_URL}/erp/stock/record/selectRecord`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	getList: {
		url: `${config.API_URL}/erp/stock/record/getList`,
		name: "分页菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/erp/stock/barcode/getListByKeyword`,
		name: "分页菜单",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getBatchNum: {
		url: `${config.API_URL}/erp/stock/record/getBatchNum`,
		name: "查询批号",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
}
