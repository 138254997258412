import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/getList`,
		name: "查询列表数据",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	insert: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/insert`,
		name: "新增",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	update: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detail: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/detail`,
		name: "查询详情",
		get: async function(params){
			return await http.get(this.url + "?id=" + params);
		}
	},
	delete: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/delete`,
		name: "删除",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	enterStock: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/enterStock`,
		name:"入库",
		get: async function (ids) {
			return await http.get(this.url + "?ids=" + ids);
		}
	},
	getOtherEnterDetailList: {
		url: `${config.API_URL}/erp/stock/stockOtherEnterDetail/getList`,
		name: "根据其他入库单查询物品明细 +（分页）",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	otherEnterDetail:{
		url: `${config.API_URL}/erp/stock/stockOtherEnterDetail/detail`,
		name:"根据其他入库单查询物品明细",
		get: async function (params) {
			return await http.get(this.url + "?id=" + params);
		}
	},

	submitProcess: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/submitProcess`,
		name: "提交审批",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	updateProcessInfo: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/updateProcessInfo`,
		name: "修改审批信息(审批状态或审批人)",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},

    returnProcess: {
        url: `${config.API_URL}/erp/stock/stockOtherEnter/returnProcess`,
        name: "反审批",
        get: async function(id){
            return await http.get(this.url + '?id=' + id);
        }
    },
	handleCancel: {
		url: `${config.API_URL}/erp/stock/stockOtherEnter/isEnd`,
		name: "作废",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},

}
