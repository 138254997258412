<!--选择采购单弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'650px'"
    custom-class="popTable"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <splitpanes
              :horizontal="true"
              @resized="handleResized"
            >
              <pane>
                <el-container
                  class="fatherCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <sy-search-Form
                    v-show="!hideForm"
                    ref="refFormBar"
                    :configs="searchConfig"
                    :show-item="showItem"
                    @fetch-data="fetchData"
                    @set-show="handleFitShow"
                  />
                  <el-header class="tableTopBar">
                    <div class="left-panel" />
                    <div class="right-panel">
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <!--切换显示搜索栏hide-after: 延迟隐藏-->
                      <el-tooltip
                        :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                        placement="bottom"
                        :hide-after="0"
                      >
                        <el-link @click="handleShowSearch">
                          搜索
                          <el-icon>
                            <template v-if="hideForm">
                              <el-icon-arrowDown />
                            </template>
                            <template v-else>
                              <el-icon-arrowUp />
                            </template>
                          </el-icon>
                        </el-link>
                      </el-tooltip>
                    </div>
                  </el-header>
                  <el-main class="nopadding bgwhite">
                    <!--主数据表格-->
                    <scTable
                      ref="table"
                      :api-obj="tableConfig.apiObj"
                      :column="tableConfig.columns"
                      :params="tableConfig.params"
                      row-key="id"
                      :checkbox="multiple"
                      radio-check
                      border
                      highlight-current-row
                      :ref-query-form="refFormBar"
                      remote-sort
                      remote-filter
                      hide-column-setting
                      hide-setting
                      :pagination-layout="'total, prev, pager, next, jumper'"
                      @selection-change="selectChange"
                      @select="handleSelect"
                      @select-all="handleSelectAll"
                      @current-change="currentChange"
                      @row-dblclick="dblClick"
                      @row-click="handleRowClick"
                      @data-change="fatherDataChange"
                    >
                      <template #submType="{scope}">
												<span v-if="scope.row.submType == 1">自行质检</span>
												<span v-if="scope.row.submType == 2">第三方机构</span>
												<span v-if="scope.row.submType == 3">混合质检</span>
											</template>
											<template #qualityStatus="{scope}">
												<span v-if="scope.row.qualityStatus == 0">未检测</span>
												<span v-if="scope.row.qualityStatus == 1">检测中</span>
												<span v-if="scope.row.qualityStatus == 2">已检测</span>
											</template>
                    </scTable>
                  </el-main>
                  <sy-switch-btn
                    :text="'物品明细'"
                    :opened="leftShow"
                    bottom
                    @show-left="handleShowLeft"
                  />
                </el-container>
              </pane>
              <pane
                v-if="leftShow"
                :size="paneSize"
                max-size="50"
                min-size="20"
              >
                <el-container
                  class="sonCont"
                  :style="multiple && 'padding-right: 10px'"
                >
                  <el-main class="nopadding">
                    <!--明细-->
                    <scTable
                      ref="tableSon"
                      :api-obj="tableSonConfig.apiObj"
                      :column="tableSonConfig.columns"
                      :params="tableSonConfig.params"
                      row-key="id"
                      border
                      remote-sort
                    >
                      <template #submType="{scope}">
												<span v-if="scope.row.submType == 1">无损检测</span>
												<span v-if="scope.row.submType == 2">有损检测</span>
											</template>
											<template #qualityStatus="{scope}">
												<span v-if="scope.row.qualityStatus == 0">未检测</span>
												<span v-if="scope.row.qualityStatus == 1">合格</span>
												<span v-if="scope.row.qualityStatus == 2">不合格</span>
											</template>
                    </scTable>
                  </el-main>
                </el-container>
              </pane>
            </splitpanes>
          </pane>
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumn"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
      <el-button
        :size="$store.state.global.uiSize"
        @click="visible = false"
      >
        关闭
      </el-button>
    </template>
    <GoodsDetail ref="refGoodsDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import GoodsDetail from "@/views/cfg/data/goods/detail";
export default defineComponent ({
	components: {
		GoodsDetail,
	},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择原料送检单' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '送检单号',
					prop: 'orderNum',
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: {
				pageNum: 1,
				pageSize: 1000,
			},
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.qualitySubmProcure.getList,
				params: {},
				columns: [
					{
						label: '送检单号',
						prop: 'orderNum',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						width: 200,
						sortable: 'custom',
					},
					{
						label: '经办人',
						prop: 'fz_handledName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '部门',
						prop: 'fz_deparmentName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '送检类型',
						prop: 'submType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '送检机构名称',
						prop: 'institution',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '送检金额',
						prop: 'submAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '质检状态',
						prop: 'qualityStatus',
						width: 110,
						sortable: 'custom',
					},

					{
						label: '备注',
						prop: 'remarks',
						width: 180,
						sortable: 'custom',
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: '采购订单',
						prop: 'fz_orderNo',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '检测类型',
						prop: 'submType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '送检数量',
						prop: 'number',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '质检状态',
						prop: "qualityStatus",
						width: 110,
					},
					{
						label: '备注',
						prop: "remarks",
						width: 180,
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '送检单号',
						prop: 'orderNum',
						type: 'input',
						default: true,
						max: 30,
					},
					{
						label: '供应商',
						prop: 'fz_supplierName',
						type: 'popSupplier',
						default: true,
						fields: ['supplierId','fz_supplierName']
					},
				]
			},
			isSameSup : false,//是否只能选择相同供应商的订单
		})

		//显示弹窗
		const showDialog = (data,isSameSup=false) => {
			state.visible = true
			state.isSameSup = isSameSup
			state.selections = []
			if (state.selData && state.selData.length > 0){
				state.selData = []
			}
			state.isConfirm = true
			state.loading = false
			state.tableConfig.params = {}
			if(data) {
				Object.assign(state.tableConfig.params, data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//已选数据组装
		const setSelData = (data) => {
			if(!props.multiple) return false
			if (data && state.selData.indexOf(data) === -1) {
				state.selData.push(data)
			} else {
				state.selData = state.selData.filter(item => item !== data)
			}
			if(state.selData.length > 0){
				state.isConfirm = false
			}else{
				state.isConfirm = true
			}
			showChooseData()
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) !== '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		//确定按钮点击
		const handleConfirm = () => {
			if (state.selections.length < 1) {
				proxy.$baseMessage('请选择订单！', 'warning')
				return
			}
			if (state.isSameSup) {
				let isDiff = false
				state.selections.forEach((item)=>{
					if(item.fz_supplierName != state.selections[0].fz_supplierName){
						isDiff = true
					}
				})
				if (isDiff) {
					proxy.$baseMessage('请选择供应商相同的订单！', 'warning')
					return
				}
			}
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[1].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			data = {
				...data,
				...state.tableConfig.params
			}
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				const param = Object.assign({}, state.params)
				param['submId'] = row.id
				state['tableSon'].reload(param)
			}
			if (props.multiple) {
				//添加已选数据
				setSelData(row)
			}
		}

		//父级表格数据加载完成后执行
		const fatherDataChange = (res) => {
			if(res.data.list && res.data.list[0]){
				if(!props.multiple) {   // 多选
					//设置第一行选中
					state['table'].setCurrentRow(res.data.list[0])
				}
				//传入父级ID
				Object.assign(state.tableSonConfig.params, state.params)
				state.tableSonConfig.params['submId'] = res.data.list[0].id
				//将接口对象赋给子级表格
				state.tableSonConfig.apiObj = proxy.$API.qualitySubmProcure.submProcureDetailGetList
				//根据主数据加载子数据
				// handleRowClick(res.data.list[0])
			}else{
				if(state.leftShow){
					proxy.$refs.tableSon.reload({ submId: -1 })
				}
			}
			showChooseData()
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if(field){
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//复选框选择
		const handleSelect = (selection, row) => {
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				if(index1 < 0){
					state.isConfirm = false
					state.selData.push(row)
				}
			}
		}

		//全选
		const handleSelectAll = (selection) => {
			if(selection && selection.length > 0){
				selection.forEach((item) => {
					setSelData(item)
				})
			}else{
				state.selData = []
			}
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			clearSelections,
			delSelData,
			handleDetail,
			handleRowClick,
			fatherDataChange,
			addToRight,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			currentChange,
			selectChange,
			handleConfirm,
			handleShowLeft,
			showDialog,
			dblClick,
			hideDialog,
			handleSelectAll,
			showChooseData,
			handleSelect
		}
	},
})
</script>
