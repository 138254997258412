export default {
	data () {
		return {
			inputElements: document.querySelectorAll('.el-input__inner,.el-textarea__inner'),
			notEmojiData:"",
		}
	},
	mounted() {
		this.inputElements.forEach(input=>{
			input.addEventListener('focus', this.mixinFocus);
			input.addEventListener('input', this.mixinInput);
		})
	},
	beforeUnmount() {
		this.inputElements.forEach(input=>{
			input.removeEventListener('focus', this.mixinFocus);
			input.removeEventListener('input', this.mixinInput);
		})
	},
	methods: {
		mixinFocus(event) {
			// console.log(event)
			// console.log(event.target.baseURI) // 如需要，用作页面区分
			if ((event.target.className === 'el-input__inner' || event.target.className =='el-textarea__inner') && typeof event.target.select == 'function' && !event.target.readOnly) {
				event.target.select();
			}
		},
		// 控制input框不能输入表情包
		mixinInput(event) {
			// if(event.target.value && event.target.value.trim() === ''){
			// 	// 如果全为空格则赋值为空字符串
			// 	event.target.value = ''
			// }
			if(this.containsEmoji(event.target.value)){
				event.target.value = this.notEmojiData
			}else {
				this.notEmojiData = event.target.value
			}
		},
		containsEmoji(text) {
			const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
			return emojiRegex.test(text);
		}
	}
};
