import config from "@/config"
import http from "@/utils/request"

export default {
	getPositionList: {
		url: `${config.API_URL}/cfg/organize/position/getList`,
		name: "分页查询岗位管理",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/cfg/organize/position/getListByKeyword`,
		name: "关键字搜索",
		get: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	doCheckCode: {
		url: `${config.API_URL}/cfg/organize/position/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	delPosition: {
		url: `${config.API_URL}/cfg/organize/position/delete`,
		name: "删除岗位管理",
		get: async function(params){
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insertPosition: {
		url: `${config.API_URL}/cfg/organize/position/insert`,
		name: "添加",
		post: async function(data={}){
			return await http.post(this.url,data);
		}
	},
	updatePosition: {
		url: `${config.API_URL}/cfg/organize/position/update`,
		name: "修改",
		put: async function(data={}){
			return await http.put(this.url,data);
		}
	},
	detailPosition: {
		url: `${config.API_URL}/cfg/organize/position/detail`,
				name: "查询单条数据",
				get: async function(params){
				return await http.get(this.url + '?id=' + params);
			}
	},
}
