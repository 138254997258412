<!--选择供应商弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'450px'"
    custom-class="popTable"
    :type="type"

  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes>
          <pane>
            <el-container
              class="mainPanel pdr10"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-button
                    v-if="showAdd"
                    ref="refPopAddBtn"
                    type="primary"
                    :size="$store.state.global.uiSize"
                    @click="handleEdit(1, 'refPopAddBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                    v-if="showEdit"
                    ref="refEditBtn"
                    :type="'primary'"
                    :size="$store.state.global.uiSize"
                    :disabled="isDisabled"
                    @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>
<!--                  <el-link-->
<!--                    v-if="multiple"-->
<!--                    type="primary"-->
<!--                    :disabled="selections.length === 0"-->
<!--                    @click="addToRight"-->
<!--                  >-->
<!--                    添加到已选<el-icon><el-icon-right /></el-icon>-->
<!--                  </el-link>-->
                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                    ref="table"
                    :api-obj="tableConfig.apiObj"
                    :column="tableConfig.columns"
                    :params="tableConfig.params"
                    row-key="id"
                    :checkbox="multiple"
                    radio-check
                    border
                    highlight-current-row
                    remote-sort
										:ref-query-form="refFormBar"
                    remote-filter
                    hide-column-setting
                    hide-setting
                    :pagination-layout="'total, prev, pager, next, jumper'"
                    @data-change="handleDataChange"
                    @selection-change="selectChange"
                    @current-change="currentChange"
                    @row-click="firClick"
                    @row-dblclick="dblClick"
                    @select="handleSelect"
                    @select-all="handleSelectAll"
                >
                  <template #name="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                    >
                      {{ scope.row.name }}
                    </el-link>
                  </template>

                  <template #supplieType="{ scope }">
                    <span>{{ scope.row.supplieType === 1?'设备供应商':'原材料供应商' }}</span>
                  </template>

                  <template #paymentMethod="{ scope }">
                    <span v-if="scope.row.paymentMethod === 1">款到发货</span>
                    <span v-if="scope.row.paymentMethod === 2">发货后X天为收款期限</span>
                    <span v-if="scope.row.paymentMethod === 3">开票后X天为收款期限</span>
                    <span v-if="scope.row.paymentMethod === 4">每月固定日期收款</span>
                  </template>

                  <template #presetPrice="{ scope }">
                    <span v-if="scope.row.presetPrice === 0">参考销售价</span>
                    <span v-if="scope.row.presetPrice === 1">一级销售价</span>
                    <span v-if="scope.row.presetPrice === 2">二级销售价</span>
                    <span v-if="scope.row.presetPrice === 3">三级销售价</span>
                    <span v-if="scope.row.presetPrice === 4">四级销售价</span>
                    <span v-if="scope.row.presetPrice === 5">五级销售价</span>
                    <span v-if="scope.row.presetPrice === 6">六级销售价</span>
                    <span v-if="scope.row.presetPrice === 7">七级销售价</span>
                    <span v-if="scope.row.presetPrice === 8">八级销售价</span>
                    <span v-if="scope.row.presetPrice === 9">九级销售价</span>
                  </template>


                  <template #taxRate="{ scope }">
                    <el-tag
                        v-if="scope.row.taxRate == '-1'"
                        class="myTag"
                        type="success"
                        :size="$store.state.global.uiSize"
                    >
                    </el-tag>
                    <el-tag
                        v-else
                        class="myTag"
                        type="danger"
                        :size="$store.state.global.uiSize"
                    >
                      {{ scope.row.taxRate }}
                    </el-tag>
                  </template>

                  <template #flag="{ scope }">
                    <el-tag
                      v-if="scope.row.flag === 1"
                      class="myTag"
                      type="success"
                      :size="$store.state.global.uiSize"
                    >
                      启用
                    </el-tag>
                    <el-tag
                      v-if="scope.row.flag === 0"
                      class="myTag"
                      type="danger"
                      :size="$store.state.global.uiSize"
                    >
                      停用
                    </el-tag>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <!--新增弹窗-->
    <Edit
      ref="refEdit"
      @fetch-data="fetchData"
    />
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
	onBeforeMount,
} from 'vue'
import Edit from './edit'  //新增组件
import Detail from '@/views/cfg/data/supplier/detail'
export default defineComponent ({
	name: "SelectSupplier",
	components: { Edit, Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择供应商' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar: null,
      type:"detail",
			paneSize1: 25,
			isDelSel: true,
			//新增按钮显示与否
			showAdd: false,
			//显示编辑那妞
			showEdit: false,
			//隐藏搜索表单
			hideForm: true,
			//编辑按钮状态
			isDisabled: true,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '55%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '供应商名称',
					prop: 'name',
					width: 200,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.supplier.getSupplierList,
				params: {},
				columns: [
          {
            label: '供应商名称',
            prop: 'name',
            width: 200,
            fixed: 'left',
            sortable: 'custom',
          },
          {
            label: '供应商编码',
            prop: 'number',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '社会信用代码',
            prop: 'code',
            width: 180,
            sortable: 'custom',
          },
          {
            label: '状态',
            prop: 'flag',
            width : 110,
            sortable: 'custom',
          },
          // {
          // 	label: '供应商类型',
          // 	prop: 'supplieType',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          // {
          // 	label: '付款方式设置',
          // 	prop: 'paymentMethod',
          // 	width: 150,
          // 	sortable: 'custom',
          // },
          {
            label: '采购员',
            prop: 'fz_buyerName',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '公司规模',
            prop: 'scale',
            width: 110,
            sortable: 'custom',
          },
          // {
          // 	label: '所在国家',
          // 	prop: 'country',
          // 	width: 140,
          // 	sortable: 'custom',
          // },
          {
            label: '所在地区',
            prop: 'area',
            width: 180,
            sortable: 'custom',
          },
          {
            label: '详细地址',
            prop: 'address',
            width: 180,
            sortable: 'custom',
          },
          /*{
            label: '联系手机',
            prop: 'phone',
            width: 120,
            sortable: 'custom',
          },*/
          {
            label: '联系电话',
            prop: 'tel',
            width: 120,
            sortable: 'custom',
          },
          // {
          // 	label: '传真',
          // 	prop: 'fax',
          // 	width: 130,
          // 	sortable: 'custom',
          // },
          {
            label: '邮政编码',
            prop: 'zipCode',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '电子邮箱',
            prop: 'email',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '网址',
            prop: 'website',
            width: 120,
            sortable: 'custom',
          },
          // {
          // 	label: 'QQ',
          // 	prop: 'qq',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          {
            label: '微信',
            prop: 'weChat',
            width: 120,
            sortable: 'custom',
          },
          // {
          // 	label: '旺旺',
          // 	prop: 'wangwang',
          // 	width: 120,
          // 	sortable: 'custom',
          // },
          {
            label: '法定代表人',
            prop: 'corporation',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '委托代理人',
            prop: 'entrust',
            width: 120,
            sortable: 'custom',
          },
          // {
          // 	label: '共享人',
          // 	prop: 'fz_shareUserName',
          // 	width: 160,
          // 	sortable: 'custom',
          // },
          {
            label: '经营范围',
            prop: 'businessScope',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '企业简介',
            prop: 'profile',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '备注',
            prop: 'remark',
            width: 180,
            sortable: 'custom',
          },
          // {
          // 	label: '预设价格',
          // 	prop: 'presetPrice',
          // 	width: 150,
          // 	sortable: 'custom',
          // },
          {
            label: '关联客户',
            prop: 'fz_customerName',
            width: 120,
            sortable: 'custom',
            max:32
          },
          {
            label: '开户行',
            prop: 'bank',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '账户名称',
            prop: 'accountName',
            width: 120,
          },
          {
            label: '银行账号',
            prop: 'accountNumber',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '联行号',
            prop: 'bankNo',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '许可证号',
            prop: 'licenseKey',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '税号',
            prop: 'taxCode',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '发票类型',
            prop: 'fz_typeName',
            width: 130,
            sortable: 'custom',
          },
          {
            label: '发票税率(%)',
            prop: 'taxRate',
            width: 140,
            sortable: 'custom',
          },
          {
            label: '预付金额',
            prop: 'advancePayment',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '欠供应商款',
            prop: 'oweAmount',
            width: 120,
            sortable: 'custom',
          },
          {
            label: '操作用户',
            prop: 'fz_operateName',
            width: 110,
            sortable: 'custom',
          },
          {
            label: '操作时间',
            prop: 'addTime',
            width: 200,
            sortable: 'custom',
          },
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '供应商名称',
						prop: 'name',
						type: 'input',
						max: 100,
						default: true,
					},
					{
						label: '供应商编码',
						prop: 'number',
						type: 'input',
						max: 32,
						default: true,
					},
					{
						label: '社会信用代码',
						prop: 'code',
						type: 'input',
						max: 32,
					},
					// {
					// 	label: '供应商类型',
					// 	prop: 'supplieType',
					// 	type: 'select',
					// 	default: true,
					// 	data: [
					// 		{value: '1', label: '设备供应商'},
					// 		{value: '2', label: '原材料供应商'},
					// 	]
					// },
					// {
					// 	label: '付款方式设置',
					// 	prop: 'paymentMethod',
					// 	type: 'select',
					// 	data: [
					// 		{value: '1', label: '款到发货'},
					// 		{value: '2', label: '发货后X天为收款期限'},
					// 		{value: '3', label: '开票后X天为收款期限'},
					// 		{value: '4', label: '每月固定日期收款'},
					// 	]
					// },
					{
						label: '采购员',
						prop: 'buyer',
						type: 'popUser',
						fields: ['buyerId', 'fz_buyerName'],
						max:32
					},
					{
						label: '公司规模',
						prop: 'scale',
						type: 'select',
						data: [
							{value: '微型（10人以下）', label: '微型（10人以下）'},
							{value: '小型（10-100人）/经营', label: '决策/小型（10-100人）'},
							{value: '中小型（100-500人)', label: '中型（500-1000人)'},
							{value: '大型（1000人以上）', label: '大型（1000人以上）'},
						]
					},
					// {
					// 	label: '所在国家',
					// 	prop: 'country',
					// 	type: 'input',
					// 	max: 100,
					// },
					{
						label: '所在地区',
						prop: 'area',
						type: 'area',
						default: true,
					},
					{
						label: '详细地址',
						prop: 'address',
						type: 'input',
						max: 100,
					},
					/*{
						label: '联系手机',
						prop: 'phone',
						type: 'number',
						max: 32,

					},*/
					{
						label: '联系电话',
						prop: 'tel',
						type: 'input',
						max: 32,

					},
					// {
					// 	label: '传真',
					// 	prop: 'fax',
					// 	type: 'input',
					// 	max: 32,
					// },
					{
						label: '邮政编码',
						prop: 'zipCode',
						type: 'number',
						max: 32,
					},
					{
						label: '电子邮箱',
						prop: 'email',
						type: 'input',
						max: 32,
					},
					{
						label: '网址',
						prop: 'website',
						type: 'input',
						max: 255,
					},
					// {
					// 	label: 'QQ',
					// 	prop: 'qq',
					// 	type: 'input',
					// 	max: 32,
					// },
					{
						label: '微信',
						prop: 'weChat',
						type: 'input',
						max: 32,
					},
					// {
					// 	label: '旺旺',
					// 	prop: 'wangwang',
					// 	type: 'input',
					// 	max: 32,
					// },
					{
						label: '法定代表人',
						prop: 'corporation',
						type: 'input',
						max: 255,
					},
					{
						label: '委托代理人',
						prop: 'entrust',
						type: 'input',
						max: 255,
					},
					// {
					// 	label: '共享人',
					// 	prop: 'shareUser',
					// 	type: 'tableSelect',
					// 	config: {
					// 		apiObj: proxy.$API.user.getUserList,
					// 		props: {
					// 			label: 'name',
					// 			value: 'id',
					// 			keyword: "keyword"
					// 		},
					// 		columns: [
					// 			{
					// 				label: '姓名',
					// 				prop: 'name',
					// 			},
					// 			{
					// 				label: '工号',
					// 				prop: 'code',
					// 			},
					// 			{
					// 				label: '上级所属部门',
					// 				prop: 'fz_departmentName',
					// 			},
					// 		],
					// 		//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
					// 		assignFields: [
					// 			{
					// 				//对应表格列表数据字段
					// 				value: 'id',
					// 				//赋值给到的字段名称
					// 				field: 'managerId',
					// 			},
					// 		],
					// 	}
					// },
					/*{
						label: '经营范围',
						prop: 'businessScope',
						type: 'input',
						max: 255,
					},
					{
						label: '企业简介',
						prop: 'profile',
						type: 'input',
						max: 255,
					},*/
					{
						label: '备注',
						prop: 'remark',
						type: 'input',
						max: 255,
					},
					// {
					// 	label: '预设价格',
					// 	prop: 'presetPrice',
					// 	type: 'select',
					// 	data: [
					// 		{value: '0', label: '参考销售价'},
					// 		{value: '1', label: '一级销售价'},
					// 		{value: '2', label: '二级销售价'},
					// 		{value: '3', label: '三级销售价'},
					// 		{value: '4', label: '四级销售价'},
					// 		{value: '5', label: '五级销售价'},
					// 		{value: '6', label: '六级销售价'},
					// 		{value: '7', label: '七级销售价'},
					// 		{value: '8', label: '八级销售价'},
					// 		{value: '9', label: '九级销售价'},
					// 	]
					// },
					{
						label: '关联客户',
						prop: 'customer',
						type: 'tableSelect',
						config: {
							apiObj: proxy.$API.customer.getCustomerList,
							props: {
								label: 'name',
								value: 'id',
								keyword: "keyword"
							},
							columns: [
								{
									label: '客户名称',
									prop: 'name',
								},
								{
									label: '客户编码',
									prop: 'number',
								},
								{
									label: '销售负责人',
									prop: 'fz_saleDirectorName',
								},
							],
							//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
							assignFields: [
								{
									//对应表格列表数据字段
									value: 'id',
									//赋值给到的字段名称
									field: 'managerId',
								},
							],
						}
					},
					{
						label: '开户行',
						prop: 'bank',
						type: 'input',
						max: 255,
					},
					{
						label: '账户名称',
						prop: 'accountName',
						type: 'input',
						max: 255,
					},
					{
						label: '银行账号',
						prop: 'accountNumber',
						type: 'input',
						max: 255,
					},
					{
						label: '联行号',
						prop: 'bankNo',
						type: 'input',
						max: 32,
					},
					{
						label: '许可证号',
						prop: 'licenseKey',
						type: 'input',
						max: 32,
					},
					{
						label: '税号',
						prop: 'taxCode',
						type: 'input',
						max: 32,
					},

					{
						label: '发票类型',
						prop: 'invoiceType',
						type: 'selectDicts',
						typeCode: 'public_attribute-invoiceType',
					},
					/*{
						label: '税率',
						prop: 'taxRate',
						type: 'number',
						max: 3,
						append: '%',
					},
					{
						label: '预付金额',
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_advancePayment0',
								max: 22,
								append: '元',
							},
							{
								prop: 'fz_advancePayment1',
								max: 22,
								append: '元',
							},
						]
					},
					{
						label: '欠供应商款',
						type: 'rangeNum',
						fields: [
							{
								prop: 'fz_oweAmount0',
								max: 22,
								append: '元',
							},
							{
								prop: 'fz_oweAmount1',
								max: 22,
								append: '元',
							},
						]
					},*/
					// {
					// 	label: '操作用户',
					// 	prop: 'fz_operateName',
					// 	type: 'popUser',
					// 	short: 12,
					// 	fields: ['operateId', 'fz_operateName'],
					// },
					// {
					// 	label: '操作时间',
					// 	type: 'dateTimeRange',
					// 	//开始结束时间字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_addTimeSmall',
					// 		},
					// 		{
					// 			prop: 'fz_addTimeBig',
					// 		},
					// 	],
					// },
				],
			},
		})

		//显示弹窗
		const showDialog = (data) => {
			state.visible = true
			if (data) {
				Object.assign(state.tableConfig.params, data)
			}
			Object.assign(state.tableConfig.params, {flag: 1})
			getShowAdd()
			getShowEdit()
			state.loading = false
		}

			//关闭弹窗
			const hideDialog = () => {
				state.visible = false
				state.selections = []
				state.selData = []
			}

			//查询新增权限
			const getShowAdd = async () => {
				const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_SUPPLIER, 'add')
				//有结果标识有权限
				if (res.data) {
					state.showAdd = true
				} else {
					state.showAdd = false
				}
			}

			//查询修改权限
			const getShowEdit = async () => {
				const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.DATA_SUPPLIER, 'edit')
				//有结果标识有权限
				if (res.data) {
					state.showEdit = true
				} else {
					state.showEdit = false
				}
			}

			//切换显示左侧边栏
			const handleShowLeft = (bool) => {
				state.leftShow = bool
			}

			/*------------主表数据操作-----------------*/

			//复选框选择回调函数 val：选中的数据
			const selectChange = (val) => {
				state.selections = val
			}

			//添加选中数据到已选
			const addToRight = () => {
				//添加已选数据
				state.selections.forEach((item) => {
					setSelData(item)
				})
			}

			//选中行改变
			const currentChange = (row) => {
				state.curRow = row
				if (state.curRow && JSON.stringify(state.curRow) != '{}') {
					state.isDisabled = false
					if (!props.multiple) {
						state.isConfirm = false
					}
				} else {
					state.isDisabled = true
					if (!props.multiple) {
						state.isConfirm = true
					}
				}
			}

			//确定按钮点击
			const handleConfirm = () => {
				let data
				if (!props.multiple) { //多选
					data = state.curRow
				} else {
					data = state.selData
				}
				//触发父级赋值方法
				emit('set-data', data)
				//隐藏弹窗
				hideDialog()
			}

			//行双击
			const dblClick = (row) => {
				if(row && !props.multiple){
					//传入选中行和赋值的字段
					emit('set-data', row)
					hideDialog()
				}
				if(props.multiple){
					//添加已选数据
					setSelData(row)
				}
			}

			//分栏拖动结束
			const handleResized = (res) => {
				if (state.leftShow) {
					state.paneSize = res[0].size
				}
			}

			//查询数据，刷新表格
			const fetchData = (data) => {
				//查询参数{ 字段名：值, 字段名：值, ... }
				state['table'].reload(data)
			}

			//保存表单显示状态
			const handleFitShow = (bool) => {
				state.showItem = bool
			}

			//切换显示搜索栏
			const handleShowSearch = () => {
				nextTick(() => {
					state.hideForm = !state.hideForm
				})
			}

			/*--------------------已选数据操作---------------------------*/
			//已选数据组装
			// const setSelData = (data) => {
			// 	if(!props.multiple) return false
			// 	if(data && state.selData.indexOf(data) === -1){
			// 		state.selData.push(data)
			// 	}
			// 	if(state.selData.length > 0){
			// 		state.isConfirm = false
			// 	}else{
			// 		state.isConfirm = true
			// 	}
			// }

			//回显选中状态
			const showChooseData = () => {
				nextTick(() => {
					const dataTable = proxy.$refs.table
					const tableData = dataTable.tableData
					tableData.forEach((item) => {
						dataTable.toggleRowSelection(item, false)
					})
					state.selData.forEach((item) => {
						const findItem = tableData.find((son) => {
							return son.id === item.id
						})
						if (findItem) {
							dataTable.toggleRowSelection(findItem, true)
						}
					})
				})
			}

			const handleDataChange = () => {
				showChooseData()
			}


			const setSelData = (data) => {
				const index = state.selData.indexOf(data)
				if (data && index === -1) {
					state.selData.push(data)
				} else {
					state.selData.splice(index, 1)
				}
				showChooseData()
			}

			//清空已选数据
			const clearSelections = () => {
				state.selData = []
				proxy.$refs.table.clearSelection()
				// emit('set-data', state.selData)
			}

			//已选数据表选中的数据变化
			const tableSelselectChange = (val) => {
				state.selectionsTableSel = val
				if (val.length > 0) {
					state.isDelSel = false
				} else {
					state.isDelSel = true
				}
			}

			//删除选中的已选数据
			// const delSelData = () => {
			// 	if(state.selectionsTableSel.length > 0){
			// 		state.selectionsTableSel.forEach((item) => {
			// 			const index = state.selData.indexOf(item)
			// 			if(index != -1){
			// 				state.selData.splice(index,1)
			// 			}
			// 		})
			// 	}
			// }


			//删除选中的已选数据
			const delSelData = () => {
				if (state.selectionsTableSel.length > 0) {
					state.selectionsTableSel.forEach((item) => {
						const index = state.selData.indexOf(item)
						const index1 = state.selections.indexOf(item)
						if (index > -1) {
							state.selData.splice(index, 1)
						}
						if (index1 > -1) {
							proxy.$refs.table.toggleRowSelection(item, false)
						}
					})
				}
			}


			//复选框选择
			const handleSelect = (selection, row) => {
				if (!row) {
					return
				}
				const index = selection.indexOf(row)
				const index1 = state.selData.indexOf(row)
				if (index < 0) {
					state.selData.splice(index1, 1)
				} else {
					if (index1 < 0) {
						state.selData.push(row)
					}
					proxy.$refs.tableSel.toggleRowSelection(row, false)
				}
			}

			//行单击
			const firClick = (row) => {
				//添加已选数据
				setSelData(row)
			}

			//全选
			const handleSelectAll = (selection) => {
				if (selection && selection.length > 0) {
					selection.forEach((item) => {
						setSelData(item)
					})
				} else {
					state.selData = []
				}
			}

			watch(
				() => state.selData,
				(val) => {
					if (val.length > 0) {
						state.isConfirm = false
					} else {
						state.isConfirm = true
					}
				},
				{deep: true}
			)

			/*------------------------按钮操作start-------------------------------*/
			//弹窗新增编辑
			const handleEdit = (type, ref) => {
				let param = {}
				if (type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}') { //编辑
					param = state.curRow
				}
				proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
				proxy.$refs.refEdit.showDialog(param)
			}
			/*------------------------按钮操作end-------------------------------*/

			//详情
			const handleDetail = (row) => {
				proxy.$refs.refDetail.showDialog(row)
			}



			return {
				...toRefs(state),
				handleDetail,
				tableSelselectChange,
				addToRight,
				delSelData,
				handleEdit,
				clearSelections,
				handleShowSearch,
				handleFitShow,
				fetchData,
				handleResized,
				currentChange,
				selectChange,
				handleConfirm,
				handleShowLeft,
				showDialog,
				hideDialog,
				handleSelect,
				handleSelectAll,
				firClick,
				dblClick,
				handleDataChange,
			}

	},
})
</script>
