<!--选择BOM清单弹窗组件-->
<template>
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="dftitle"
		:loading="loading"
		:width="width"
		:trigger-dom="triggerDom"
		:bef-close="false"
		:height="'500px'"
		custom-class="popTable"
		type="detail"
	>
		<el-container>
			<el-main class="nopadding">
				<splitpanes>
					<pane>
						<el-container :style="multiple && 'padding-right: 10px'">
							<sy-search-Form
								v-show="!hideForm"
								ref="refFormBar"
								:configs="searchConfig"
								:show-item="showItem"
								@fetch-data="fetchData"
								@set-show="handleFitShow"
							/>
							<el-header class="tableTopBar">
								<div class="left-panel">
<!--									<el-link-->
<!--										v-if="multiple"-->
<!--										type="primary"-->
<!--										:disabled="selections.length === 0"-->
<!--										@click="addToRight"-->
<!--									>-->
<!--										添加到已选<el-icon><el-icon-right /></el-icon>-->
<!--									</el-link>-->
								</div>
								<div class="right-panel">
									<!--切换显示搜索栏hide-after: 延迟隐藏-->
									<el-tooltip
										:content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
										placement="bottom"
										:hide-after="0"
									>
										<el-link @click="handleShowSearch">
											搜索
											<el-icon>
												<template v-if="hideForm">
													<el-icon-arrowDown />
												</template>
												<template v-else>
													<el-icon-arrowUp />
												</template>
											</el-icon>
										</el-link>
									</el-tooltip>
								</div>
							</el-header>
							<el-main class="nopadding bgwhite">
								<!--主数据表格-->
								<scTable
									ref="table"
									:api-obj="tableConfig.apiObj"
									:column="tableConfig.columns"
									:params="searchConfig.params"
									row-key="id"
									:checkbox="multiple"
									radio-check
									border
									highlight-current-row
                  :ref-query-form="refFormBar"
									remote-sort
									remote-filter
									hide-column-setting
									hide-setting
									:pagination-layout="'total, prev, pager, next, jumper'"
									@selection-change="selectChange"
									@select="handleSelect"
									@data-change="handleDataChange"
									@current-change="currentChange"
									@select-all="handleSelectAll"
									@row-click="firClick"
									@row-dblclick="dblClick"
								>

									<template #bomNum="{ scope }">
										<el-link
											type="primary"
											@click="handleDetail(scope.row, 'refBomDetail', 'id')"
										>
											{{ scope.row.bomNum }}
										</el-link>
									</template>

									<template #fz_goodsName="{ scope }">
										<el-link
											type="primary"
											@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
											>
											{{ scope.row.fz_goodsName }}
										</el-link>
									</template>


								</scTable>
							</el-main>
						</el-container>
					</pane>
					<pane
						v-if="multiple"
						:size="paneSize1"
					>
						<el-container :style="multiple && 'padding-left: 10px'">
							<el-header class="tableTopBar">
								<div class="left-panel">
									<el-link
										type="primary"
										:disabled="isDelSel"
										@click="delSelData"
									>
										删除
									</el-link>
									<el-popconfirm
										v-if="selData.length > 0"
										:title="'确定清空已选择的数据?'"
										confirm-button-text="确定"
										cancel-button-text="取消"
										@confirm="clearSelections"
									>
										<template #reference>
											<el-link type="primary">
												清空
											</el-link>
										</template>
									</el-popconfirm>
								</div>
								<div class="right-panel">
									<h5>已选 <span>{{ selData.length }}</span> 条</h5>
								</div>
							</el-header>
							<el-main class="nopadding">
								<scTable
									ref="tableSel"
									:data="selData"
									:column="selColumn"
									row-key="id"
									checkbox
									border
									hide-pagination
									hide-do
									@selection-change="tableSelselectChange"
								/>
							</el-main>
						</el-container>
					</pane>
				</splitpanes>
			</el-main>
		</el-container>
		<template #footer>
			<el-button
				:size="$store.state.global.uiSize"
				type="primary"
				:disabled="isConfirm"
				@click="handleConfirm"
			>
				确定
			</el-button>
<!--			<el-button-->
<!--				:size="$store.state.global.uiSize"-->
<!--				@click="visible = false"-->
<!--			>-->
<!--				关闭-->
<!--			</el-button>-->
		</template>
		<Detail ref="refDetail" />
		<GoodsDetail ref="refGoodsDetail" />
		<BomDetail ref="refBomDetail" />
	</sc-dialog>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  nextTick, watch, onMounted,
} from 'vue'
import Detail from '@/views/erp/produce/bom/detail'
import GoodsDetail from '@/views/cfg/data/goods/detail'
import BomDetail from "@/views/erp/produce/bom/detail.vue";
export default defineComponent ({
	name: "SelectBom",
	components: {BomDetail, Detail , GoodsDetail},
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '选择BOM' },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
      refFormBar:null,
			isDelSel: true,
			paneSize1: 25,
			depot: {},
			depotList: [],
			paneSize: 30,
			leftShow: true,
			//隐藏搜索表单
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			//查询表单
			queryForm: {},
			tableSon: null,
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumn: [
				{
					label: '产品名称',
					prop: 'fz_goodsName',
					width: 180,
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			//暂存选中行
			curRow: {},
			tableConfig: {
				apiObj: proxy.$API.produceBom.getList,
				columns: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						width: 180,
            fixed: "left",
						sortable: "custom",
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: "custom",
					},
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: "custom",
					},
          {
            label: 'BOM编号',
            prop: 'bomNum',
            width: 180,
            sortable: "custom",
            fixed: 'left',
          },
					{
						label: '版本号',
						prop: 'edition',
						width: 110,
						sortable: "custom",
					},

					{
						label: '主单位',
						prop: 'fz_goodsUnit',
						width: 110,
						sortable: "custom",
					},
					{
						label: '辅助单位',
						prop: 'assistUnit',
						width: 110,
						sortable: "custom",
					},
					// {
					// 	label: '版本号',
					// 	prop: 'edition',
					// 	width: 150,
					// 	sortable: "custom",
					// },
					// {
					// 	label: '成品率【%】',
					// 	prop: 'yieldrate',
					// 	width: 120,
					// 	sortable: "custom",
					// },
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: "custom",
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,

				//数据表格列配置
				columns: [
					{
						label: '物料名称',
						prop: 'fz_goodsName',
						sortable: "custom",
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品类型',
						prop: 'fz_goodsStype',
						sortable: "custom",
						width: 120,
					},
					{
						label: '物料编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: "custom",
					},
					{
						label: '物料规格',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: "custom",
					},
					{
						label: '物料型号',
						prop: 'fz_goodsModel',
						width: 120,
						sortable: "custom",
					},
					{
						label: '需求数量',
						prop: 'demandQuantity',
						width: 120,
						sortable: "custom",
					},
					{
						label: '耗损率(%)',
						prop: 'wearRate',
						width: 120,
						sortable: "custom",
					},
					{
						label: '实际数量',
						prop: 'actualQuantity',
						width: 110,
						sortable: "custom",
					},
					{
						label: '计量单位',
						prop: 'unit',
						width: 110,
						sortable: "custom",
					},
					{
						label: '辅计单位',
						prop: 'assistUnit',
						width: 110,
						sortable: "custom",
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: "custom",
					},
					{
						label: '单价(元)',
						prop: 'price',
						width: 130,
						sortable: "custom",
					},
					{
						label: '小计金额(元)',
						prop: 'subtotal',
						width: 140,
						sortable: "custom",
					},
					{
						label: '来源',
						prop: 'source',
						width: 110,
						sortable: "custom",
					},
					{
						label: '位置号',
						prop: 'location',
						width: 110,
						sortable: "custom",
					},
					{
						label: '坏料尺寸',
						prop: 'billetSize',
						width: 110,
						sortable: "custom",
					},
					{
						label: '坏料数量',
						prop: 'billetNumber',
						width: 110,
						sortable: "custom",
					},
					{
						label: '提前期',
						prop: 'leadTime',
						width: 130,
						sortable: "custom",
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: "custom",
					},
				],
			},
			//搜索表单配置
			searchConfig: {
				param:{},
				//表单标题宽度
				labelWidth: 90,
				//查询表单项配置
				itemList: [
					{
						label: '产品名称',
						prop: 'fz_goodsName',
						type: 'popGoods',
						default: true,
						span: 8,
						fields: ['goodsId', 'fz_goodsName']
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						type: 'input',
						default: true,
						span: 8,
            max:32
					},
					// {
					// 	label: '版本号',
					// 	prop: 'edition',
					// 	type: 'input',
					// 	span: 8,
					// 	default: true,
					// },
					// {
					// 	label: '成品率',
					// 	type: 'rangeNum',
					// 	default: true,
					// 	span: 8,
					// 	fields: [
					// 		{
					// 			prop: 'fz_yieldrate0',
					// 			max: 3,
					// 		},
					// 		{
					// 			prop: 'fz_yieldrate1',
					// 			max: 3,
					// 		}
					// 	]
					// },
					{
						label: '备注',
						prop: 'remark',
						type: 'input',
						max: 100,
						span: 8,

					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						span: 8,
						type: 'popUser',
						short: 12,
            max:32,
						fields: ['operateId', 'fz_operateName'],
					},
					{
						label: '操作时间',
						span: 8,
						type: 'dateTimeRange',
						//开始结束时间字段
						fields: [
							{
								prop: 'addTime0',
							},
							{
								prop: 'addTime1',
							},
						],
					},
				],
			},
		})
    onMounted(() => {
      //消息监听
      state.hideForm=!state.hideForm
    })
		//显示弹窗
		const showDialog = (param) => {

			if (param != null){
				state.searchConfig.params = Object.assign({}, param)
			}

			state.visible = true
			state.loading = false
			state.selData = []
			getDepotList()
		}

		//获取仓库数据
		const getDepotList = async () => {
			const res = await proxy.$API.depot.getAllList.get()
			if(res.data && res.data.length > 0){
				state.depotList = res.data
				state.depot = res.data[0]
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		//复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val


			// if (state.selections && state.selections.length >= 1){
			// 	for (const item of state.selections) {
			// 		setSelData(item)
			// 	}
			// }
		}


		//全选
		const handleSelectAll = (selection) => {
			if(selection && selection.length > 0){
				selection.forEach((item) => {
					setSelData(item)
				})
			}else{
				state.selData = []
			}
		}

		//复选框选择
		const handleSelect = (selection, row) => {

			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if(index < 0){
				state.selData.splice(index1, 1)
			}else{
				// 右边没有给他推进去
				if(index1 < 0){
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}
		}

		//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		watch(
			() => state.selData,
			(val) => {
				if (val.length > 0) {
					state.isConfirm = false
				} else {
					state.isConfirm = true
				}
			},
			{deep: true}
		)

		//确定按钮点击
		const handleConfirm = () => {
			let data
			if(!props.multiple){ //多选
				data = state.curRow
			}else{
				data = state.selData
			}
			//触发父级赋值方法
			emit('set-data',data)
			//隐藏弹窗
			hideDialog()
		}

		// //行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}

		}

		//行单击
		const firClick = (row) => {

			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			data = {
				...state.searchConfig.params,
				...data
			}
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		//主表行点击事件
		const handleRowClick = (row) => {
			if(state.leftShow){
				state['tableSon'].reload({ bomId: row.id })
			}
		}

		// const handleDetail = (row) => {
		// 	proxy.$refs.refDetail.showDialog(row)
		// }


		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				// if (field == 'saleOrderId'){
				// 	params.id = row['saleOrderId']
				// }else {
				//
				// }
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}

		/*--------------------已选数据操作---------------------------*/


		const setSelData = (data) => {
			const index = state.selData.indexOf(data)
			if (data && index === -1) {
				state.selData.push(data)
			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()
		}




		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据

		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if(findItem){
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//表格数据加载完成
		const handleDataChange = () => {
			showChooseData()
		}

		/*--------------------已选数据操作---------------------------*/

		return{
			...toRefs(state),
			addToRight,
			handleDataChange,
			clearSelections,
			tableSelselectChange,
			delSelData,
			handleDetail,
			handleRowClick,
			handleShowSearch,
			handleFitShow,
			handleSelectAll,
			handleSelect,
			fetchData,
			selectChange,
			handleConfirm,
			handleShowLeft,
			currentChange,
			showDialog,
			dblClick,
			firClick,
			hideDialog,
		}
	},
})
</script>
