import config from "@/config"
import http from "@/utils/request"

export default {
	getList:{
		url:`${config.API_URL}/cfg/data/freight/getList`,
		name:'获取货位列表',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	getFreightList:{
		url:`${config.API_URL}/cfg/data/freight/getFreightList`,
		name:'获取货位列表，不分页',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	insertFreight:{
		url:`${config.API_URL}/cfg/data/freight/insert`,
		name:'新增货位',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	getDepotLocation:{
		url:`${config.API_URL}/cfg/data/freight/getDepotLocation`,
		name:'根据仓库查询货位',
		get: async function(depotId,goodsId){
			return await http.get(this.url + '?depotId=' + depotId + '&goodsId=' + goodsId)
		}
	},
	updateFreight:{
		url:`${config.API_URL}/cfg/data/freight/update`,
		name:'修改货位',
		get: async function(data={}){
			return await http.put(this.url,data)
		}
	},
	deleteFreight:{
		url:`${config.API_URL}/cfg/data/freight/delete`,
		name:'删除货位',
		get: async function(ids){
			return await http.get(this.url + '?ids=' + ids)
		}
	},
	getDetail:{
		url:`${config.API_URL}/cfg/data/freight/detail`,
		name:'获取货位详情',
		get: async function(id){
			return await http.get(this.url + '?id=' + id)
		}
	},
	updateFlag1:{
		url:`${config.API_URL}/cfg/data/freight/updateFlag1`,
		name:'更新启用状态',
		get: async function(ids){
			return await http.get(this.url + '?ids=' + ids)
		}
	},
	updateFlag0:{
		url:`${config.API_URL}/cfg/data/freight/updateFlag0`,
		name:'更新停用状态',
		get: async function(ids){
			return await http.get(this.url + '?ids=' + ids)
		}
	},
	checkName:{
		url:`${config.API_URL}/cfg/data/freight/checkName`,
		name:'校验名字',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},
	checkNumber:{
		url:`${config.API_URL}/cfg/data/freight/checkNumber`,
		name:'校验编码',
		get: async function(data={}){
			return await http.post(this.url,data)
		}
	},

	importExcel:{
		url: `${config.API_URL}/cfg/data/freight/importExcel`,
		name: "导入Excel",
		post: async function(data = {},config){
			return await http.post(this.url,data,config);
		}
	},
}
