<!--空数据提示组件-->
<template>
	<el-empty
		:image="NullImage"
		:description="description"
		:image-size="imgSize"
		:class="noImage?'no-image':''"
	>
		<slot name="description" />
	</el-empty>
</template>
<script>
import {defineComponent, reactive, toRefs} from "vue";
import NullImage from '/public/img/null.png'
export default defineComponent({
	name: "SyEmpty",
	props: {
		description: { type: String, default: "暂无数据" }, //提示文字
		imgSize: { type: Number, default: 60 },  //图片大小
		noImage: { type: Boolean, default: false },  //是否显示图片
	},
	setup(){
		const state = reactive({
		})
		return {
			...toRefs(state),
			NullImage
		}
	}
})
</script>
<style scoped lang="scss">
.no-image{
	:deep(.el-empty__image){
		display: none;
	}
}
</style>
