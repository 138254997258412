<!--选择用户弹窗组件-->
<template>
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="dftitle"
    :loading="loading"
    :width="width"
    :trigger-dom="triggerDom"
    :bef-close="false"
    :height="'550px'"
    :type="type"
    custom-class="popTable"
  >
    <el-container>
      <el-main class="nopadding">
        <splitpanes @resized="handleResized">
          <pane
            v-if="leftShow"
            min-size="10"
            max-size="60"
            :size="paneSize"
          >
            <el-container>
              <el-header class="clstop">
                <el-button
                  :size="$store.state.global.uiSize"
                  :class="curCls === 1 ? 'active' : ''"
                  @click="handleClsClick(1)"
                >
                  部门
                </el-button>
                <el-button
                  :size="$store.state.global.uiSize"
                  :class="curCls === 2 ? 'active' : ''"
                  @click="handleClsClick(2)"
                >
                  角色
                </el-button>
              </el-header>
              <el-main class="nopadding">
                <template v-if="curCls === 1">
                  <!--树结构组件-->
                  <sy-tree
                    :api-obj="$API.department.getList"
                    add-top
					hide-pagination
                    :is-edit="false"
					:params="{}"
                    @fetch-data-node="(val) => fetchDataNode(val, 1)"
                  />
                </template>
                <template v-if="curCls === 2">
                  <!--树结构组件-->
                  <sy-tree
                    add-top
                    :api-obj="$API.role.getList"
                    :params="{ pageNum: 1, pageSize: 1000 }"
                    hide-icon
                    :is-edit="false"
                    @fetch-data-node="(val) => fetchDataNode(val, 2)"
                  />
                </template>
              </el-main>
            </el-container>
          </pane>
          <pane>
            <!--公用切换按钮，点击切换显示左侧栏
			text:显示文字
			opened:绑定左侧栏显示状态
			show-left:回调函数
			-->
            <sy-switch-btn
              :text="switchText"
              :opened="leftShow"
              @show-left="handleShowLeft"
            />
            <el-container
              class="mainPanel sw"
              :style="multiple && 'padding-right: 10px'"
            >
              <sy-search-Form
                v-show="!hideForm"
                ref="refFormBar"
                :configs="searchConfig"
                :show-item="showItem"
                @fetch-data="fetchData"
                @set-show="handleFitShow"
              />
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-button
                    v-if="showAdd"
                    ref="refPopAddBtn"
                    type="primary"
                    :size="$store.state.global.uiSize"
                    @click="handleEdit(1, 'refPopAddBtn')"
                  >
                    新增
                  </el-button>
                  <el-link
                    v-if="showEdit"
                    ref="refEditBtn"
                    :type="'primary'"
                    :size="$store.state.global.uiSize"
                    :disabled="isDisabled"
                    @click="handleEdit(2, 'refEditBtn')"
                  >
                    编辑
                  </el-link>
<!--                  <el-link-->
<!--                    v-if="multiple"-->
<!--                    type="primary"-->
<!--                    :disabled="selections.length === 0"-->
<!--                    @click="addToRight"-->
<!--                  >-->
<!--                    添加到已选<el-icon><el-icon-right /></el-icon>-->
<!--                  </el-link>-->
                </div>
                <div class="right-panel">
                  <!--切换显示搜索栏hide-after: 延迟隐藏-->
                  <el-tooltip
                    :content="hideForm ? '点击显示搜索' : '点击隐藏搜索'"
                    placement="bottom"
                    :hide-after="0"
                  >
                    <el-link @click="handleShowSearch">
                      搜索
                      <el-icon>
                        <template v-if="hideForm">
                          <el-icon-arrowDown />
                        </template>
                        <template v-else>
                          <el-icon-arrowUp />
                        </template>
                      </el-icon>
                    </el-link>
                  </el-tooltip>
                </div>
              </el-header>
              <el-main class="nopadding bgwhite">
                <!--主数据表格-->
                <scTable
                  ref="table"
                  :api-obj="tableConfig.apiObj"
                  :column="tableConfig.columns"
                  :params="tableConfig.params"
                  row-key="id"
                  :checkbox="multiple"
                  radio-check
                  border
                  highlight-current-row
                  remote-sort
									:ref-query-form="refFormBar"
                  remote-filter
                  hide-column-setting
                  hide-setting
                  :pagination-layout="'total, prev, pager, next, jumper'"
                  @selection-change="selectChange"
                  @current-change="currentChange"
				  @select-all="handleSelectAll"
				  @select="handleSelect"
				  @row-click="firClick"
				  @row-dblclick="dblClick"
				  @add-row="handleAddRow"
                >
                  <template #gender="{ scope }">
                    <span>{{ scope.row.gender === 1 ? '男' : '女' }}</span>
                  </template>
                  <template #jobStatus="{ scope }">
                    <span>{{ scope.row.jobStatus === 1 ? '转正' : '试用' }}</span>
                  </template>
                  <template #manager="{ scope }">
                    <span>{{ scope.row.manager === 1 ? '是' : '否' }}</span>
                  </template>
                  <template #login="{ scope }">
                    <span>{{ scope.row.login === 1 ? '是' : '否' }}</span>
                  </template>
                  <!--自定义单元格显示 #{字段名称} -->
                  <template #name="{ scope }">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                    >
                      {{ scope.row.name }}
                    </el-link>
                  </template>
                  <template #flag="{ scope }">
                    <el-tag
                      v-if="scope.row.flag === 1"
                      type="success"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      启用
                    </el-tag>
                    <el-tag
                      v-if="scope.row.flag === 0"
                      type="danger"
                      class="myTag"
                      :size="$store.state.global.uiSize"
                    >
                      停用
                    </el-tag>
                  </template>
                </scTable>
              </el-main>
            </el-container>
          </pane>
          <!--已选数据-->
          <pane
            v-if="multiple"
            :size="paneSize1"
          >
            <el-container :style="multiple && 'padding-left: 10px'">
              <el-header class="tableTopBar">
                <div class="left-panel">
                  <el-link
                    type="primary"
                    :disabled="isDelSel"
                    @click="delSelData"
                  >
                    删除
                  </el-link>
                  <el-popconfirm
                    v-if="selData.length > 0"
                    :title="'确定清空已选择的数据?'"
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="clearSelections"
                  >
                    <template #reference>
                      <el-link type="primary">
                        清空
                      </el-link>
                    </template>
                  </el-popconfirm>
                </div>
                <div class="right-panel">
                  <h5>已选 <span>{{ selData.length }}</span> 条</h5>
                </div>
              </el-header>
              <el-main class="nopadding">
                <scTable
                  ref="tableSel"
                  :data="selData"
                  :column="selColumns"
                  row-key="id"
                  checkbox
                  border
                  hide-pagination
                  hide-do
                  @selection-change="tableSelselectChange"
                />
              </el-main>
            </el-container>
          </pane>
        </splitpanes>
      </el-main>
    </el-container>
    <template #footer>
      <span class="mr_5">
        <el-checkbox
          v-if="!hideAsyncUpdate"
          v-model="asyncAll"
          :true-label="1"
          :false-label="0"
          :size="$store.state.global.uiSize"
        >
          同步修改所有数据
        </el-checkbox>
      </span>
      <el-button
        :size="$store.state.global.uiSize"
        type="primary"
        :disabled="isConfirm"
        @click="handleConfirm"
      >
        确定
      </el-button>
<!--      <el-button-->
<!--        :size="$store.state.global.uiSize"-->
<!--        @click="visible = false"-->
<!--      >-->
<!--        关闭-->
<!--      </el-button>-->
    </template>
    <!--新增弹窗-->
    <Edit
      ref="refEdit"
      @fetch-data="fetchData"
    />
    <Detail ref="refDetail" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
	watch,
	onMounted
} from 'vue'
import Edit from './edit'  //新增组件
import Detail from '@/views/publicQuery/selectUser/detail.vue'
export default defineComponent ({
	name: "SelectUser",
	components: { Edit, Detail },
	props: {
		//是否开启多选
		multiple: { type: Boolean, default: false },
		//默认开启全部选择
		choseAll: { type: Boolean, default: true },
		//传入标题
		title: { type: String, default: '用户选择' },
		//隐藏设置更新复选
		hideAsyncUpdate: { type: Boolean, default: true },
		//
		asyncAllData: { type: Number, default: 0 },
	},
	emits: [ 'set-data' ],
	setup(props, { emit }){
		const { proxy } = getCurrentInstance()
		const state = reactive({
			refFormBar: null,
      type:"detail",
			asyncAll: props.asyncAllData,
			isDelSel: true,
			isSubmit: true,
			//新增按钮显示与否
			showAdd: false,
			//编辑按钮显示
			showEdit: false,
			hideForm: false,
			//是否显示查询表单隐藏项
			showItem: proxy.$CONFIG.SHOW_FORM_ITEMS,
			leftShow: true,
			paneSize: 20,
			paneSize1: 20,
			switchText: '部门',
			queryForm: {},
			//弹窗标题
			dftitle: props.title,
			//打开弹窗触发元素
			triggerDom: {},
			//切换部门角色
			curCls: 1,
			//加载动画
			loading: true,
			//弹窗显示属性
			visible: false,
			//弹窗宽度
			width: '75%',
			//编辑按钮状态
			isDisabled: true,
			//确定按钮状态
			isConfirm: true,
			//主表选中数据
			selections: [],
			//已选数据中选中的数据
			selectionsTableSel: [],
			//已选数据记录
			selData: [],
			selColumns: [
				{
					label: '姓名',
					prop: 'name',
					width: 150,
					sortable: 'custom',
				},
			],
			//清空按钮状态
			clearAble: true,
			//表格
			table: null,
			//传入的赋值字段
			param: null,
			//选中后赋值的字段
			params: [],
			tableConfig: {
				apiObj: proxy.$API.user.getUsers,
				params: {
					flag: 1
				},
				columns: [
					{
						label: '姓名',
						prop: 'name',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '所属部门',
						prop: 'fz_departmentName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '工号',
						prop: 'code',
						width: 110,
						sortable: 'custom',
					},
					// {
					// 	label: '状态',
					// 	prop: 'flag',
					// 	width: 110,
					// 	sortable: 'custom',
					// },
					{
						label: '手机号',
						prop: 'phone',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '微信号',
						prop: 'wechat',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '电子邮箱',
						prop: 'email',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '职位',
						prop: 'position',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '直属上级',
						prop: 'fz_leaderName',
						width: 110,
						sortable: 'custom',
					},

					{
						label: '证件类型',
						prop: 'certType',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '证件号',
						prop: 'certNumber',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '性别',
						prop: 'gender',
						width: 110,
						sortable: 'custom',
					},

					{
						label: '出生日期',
						prop: 'birthday',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '年龄',
						prop: 'age',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '联系地址',
						prop: 'address',
						sortable: 'custom',
						width: 180,
					},
					{
						label: '最高学历',
						prop: 'education',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '民族',
						prop: 'nation',
						width: 110,
						sortable: 'custom',

					},
					// {
					// 	label: '开户账号',
					// 	prop: 'bankAccount',
					// 	width: 110,
					// 	sortable: 'custom',
					// },
          //
          {
            label: '登录权限',
            prop: 'login',
            width: 110,
            sortable: 'custom',
          },
					{
						label: '角色',
						prop: 'fz_roleName',
						width: 110,
						sortable: 'custom',
					},
					// {
					// 	label: '超级管理员',
					// 	prop: 'manager',
					// 	width: 120,
					// 	sortable: 'custom',
					// },

					// {
					// 	label: '登录账号',
					// 	prop: 'loginName',
					// 	width: 110,
					// 	sortable: 'custom',
					// },
					// {
					// 	label: '登录次数',
					// 	prop: 'loginTimes',
					// 	width: 110,
					// 	sortable: 'custom',
					// },
				],
			},
			//搜索表单配置
			searchConfig: {
				//表单标题宽度
				labelWidth: 70,
				queryForm: {
					flag: 1
				},
				//查询表单项配置
				itemList: [
					{
						label: '姓名',
						prop: 'name',
						type: 'input',
						max: 30,
						//默认显示
						default: true,
						span: 8,
					},
					{
						label: '所属部门',
						prop: 'department',
						type: 'tableSelect',
						span: 8,
						default: true,
						config: {
							apiObj: proxy.$API.department.getList,
							tableWidth: 500,
							height: 400,
							hidePagination: true,
							params: {
								parentId:0,
							},
							props: {
								label: 'name',
								value: 'id',
								keyword: "keyword"
							},
							columns: [
								{
									label: '部门名称',
									prop: 'name',
								},
								{
									label: '部门代码',
									prop: 'code',
								},
								{
									label: '部门经理',
									prop: 'fz_managerName',
								},
							],
							//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
							assignFields: [
								{
									//对应表格列表数据字段
									value: 'id',
									//赋值给到的字段名称
									field: 'departmentId',
								},
							],
						}
					},
					{
						label: '工号',
						prop: 'code',
						type: 'input',
						span: 8,
						max: 20,
						default: true,
					},
          {
            label: '手机号',
            prop: 'phone',
            type: 'number',
            max: 32,
            span: 8,
          },

          {
            label: '微信号',
            prop: 'wechat',
            type: 'input',
            max: 32,
            span: 8,
          },
          {
            label: '电子邮箱',
            prop: 'email',
            type: 'input',
            max: 32,
            span: 8,
          },
					{
						label: '职位',
						prop: 'position',
						span: 8,
						type: 'select',
						//下拉选项
						data: [
							{value: '总监', label: '总监'},
							{value: '经理', label: '经理'},
							{value: '职员', label: '职员'},
						]
					},
          {
            label: '直属上级',
            prop: 'user',
            span: 8,
            type: 'tableSelect',
            config: {
              apiObj: proxy.$API.user.getUserList,
              props: {
                label: 'name',
                value: 'id',
                keyword: "keyword"
              },
              columns: [
                {
                  label: '姓名',
                  prop: 'name',
                },
                {
                  label: '年龄',
                  prop: 'age',
                },
                {
                  label: '部门',
                  prop: 'fz_departmentName',
                },
              ],
              //赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
              assignFields: [
                {
                  //对应表格列表数据字段
                  value: 'id',
                  //赋值给到的字段名称
                  field: 'leaderId',
                },
              ],
            }
          },
					// {
					// 	label: '职务',
					// 	prop: 'duties',
					// 	span: 8,
					// 	type: 'select',
					// 	//下拉选项
					// 	data: [
					// 		{value: '总监', label: '总监'},
					// 		{value: '经理', label: '经理'},
					// 		{value: '职员', label: '职员'},
					// 	]
					// },
					// {
					// 	label: '职级',
					// 	prop: 'rank',
					// 	type: 'select',
					// 	span: 8,
					// 	//下拉选项
					// 	data: [
					// 		{value: '总监', label: '总监'},
					// 		{value: '经理', label: '经理'},
					// 		{value: '职员', label: '职员'},
					// 	]
					// },

					// {
					// 	label: '合同类型',
					// 	prop: 'contractType',
					// 	span: 8,
					// 	type: 'select',
					// 	//下拉选项
					// 	data: [
					// 		{value: '劳动合同', label: '劳动合同'},
					// 		{value: '劳务合同', label: '劳务合同'},
					// 		{value: '实习合同', label: '实习合同'},
					// 	]
					// },
					// {
					// 	label: '用工形式',
					// 	span: 8,
					// 	prop: 'recruit',
					// 	type: 'select',
					// 	//下拉选项
					// 	data: [
					// 		{value: '全职', label: '全职'},
					// 		{value: '兼职', label: '兼职'},
					// 		{value: '实习', label: '实习'},
					// 		{value: '外派', label: '外派'},
					// 		{value: '临时工', label: '临时工'},
					// 		{value: '退休返聘', label: '退休返聘'},
					// 	]
					// },
					// {
					// 	label: '入职日期',
					// 	span: 8,
					// 	type: 'dateRange',
					// 	//开始结束日期字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_inductionDateSmall',
					// 		},
					// 		{
					// 			prop: 'fz_inductionDateBig',
					// 		},
					// 	],
					// },
					// {
					// 	label: '合同公司',
					// 	span: 8,
					// 	prop: 'contractCompany',
					// 	type: 'input',
					// 	max: 20,
					// },

					// {
					// 	label: '工作地点',
					// 	prop: 'workPiston',
					// 	type: 'input',
					// 	span: 8,
					// 	max: 30,
					// },
					// {
					// 	label: '在职状态',
					// 	prop: 'jobStatus',
					// 	type: 'select',
					// 	span: 8,
					// 	data: [
					// 		{value: '1', label: '转正'},
					// 		{value: '0', label: '试用'},
					// 	]
					// },
          {
          	label: '证件类型',
          	prop: 'certType',
          	type: 'select',
          	span: 8,
          	data: [
          		{value: '0', label: '身份证'},
          	]
          },
          {
            label: '证件号',
            prop: 'certNumber',
            span: 8,
            type: 'input',
            max: 20,
          },
          {
            label: '性别',
            prop: 'gender',
            type: 'select',
            span: 8,
            //下拉选项
            data: [
              {value: '1', label: '男'},
              {value: '0', label: '女'},
            ]
          },
          {
            label: '出生日期',
            type: 'dateRange',
            span: 8,
            //开始结束日期字段
            fields: [
              {
                prop: 'fz_addTime0',
              },
              {
                prop: 'fz_addTime1',
              },
            ],
          },
					{
						label: '年龄',
						type: 'rangeNum',
						span: 8,
						//开始结束字段
						fields: [
							{
								prop: 'fz_ageSmall',
								max: 100,
							},
							{
								prop: 'fz_ageBig',
								max: 100,
							}
						],
					},
          {
            label: '联系地址',
            prop: 'address',
            span: 8,
            type: 'input',
            max: 36,
          },
          {
            label: '最高学历',
            prop: 'education',
            type: 'select',
            span: 8,
            data: [
              {value: '小学及以下', label: '小学及以下'},
              {value: '初中', label: '初中'},
              {value: '高中/职高', label: '高中/职高'},
              {value: '大专', label: '大专'},
              {value: '本科', label: '本科'},
              {value: '硕士', label: '硕士'},
              {value: '博士', label: '博士'},
            ]
          },
					// {
					// 	label: '籍贯',
					// 	prop: 'nativePlace',
					// 	type: 'input',
					// 	max: 20,
					// 	span: 8,
					// },
					{
						label: '民族',
						prop: 'nation',
						type: 'select',
						span: 8,
						data: [
							{value: '汉族', label: '汉族'},
							{value: '白族', label: '白族'},
							{value: '土家族', label: '土家族'},
							{value: '苗族', label: '苗族'},
							{value: '回族', label: '回族'},
							{value: '维吾尔族', label: '维吾尔族'},
						]
					},
					// {
					// 	label: '婚姻状况',
					// 	prop: 'marital',
					// 	type: 'select',
					// 	span: 8,
					// 	data: [
					// 		{value: '未婚', label: '未婚'},
					// 		{value: '已婚未育', label: '已婚未育'},
					// 		{value: '已婚已育', label: '已婚已育'},
					// 		{value: '离异', label: '离异'},
					// 	]
					// },
					// {
					// 	label: '血型',
					// 	prop: 'bloodType',
					// 	type: 'select',
					// 	span: 8,
					// 	data: [
					// 		{value: 'A', label: 'A'},
					// 		{value: 'B', label: 'B'},
					// 		{value: 'O', label: 'O'},
					// 		{value: 'AB', label: 'AB'},
					// 	]
					// },

					// {
					// 	label: '户口类型',
					// 	prop: 'accountType',
					// 	span: 8,
					// 	type: 'select',
					// 	data: [
					// 		{value: '城镇', label: '城镇'},
					// 		{value: '非城镇', label: '非城镇'},
					// 	]
					// },
          //
					// {
					// 	label: '备注',
					// 	prop: 'remark',
					// 	type: 'input',
					// 	max: 255,
					// 	span: 8,
					// },
          {
            label: '登陆权限',
            prop: 'login',
            type: 'select',
            span: 8,
            //下拉选项
            data: [
              {value: '1', label: '有'},
              {value: '0', label: '无'},
            ]
          },
					{
						label: '用户角色',
						prop: 'role',
						type: 'tableSelect',
						span: 8,
						config: {
							apiObj: proxy.$API.role.getList,
							props: {
								label: 'name',
								value: 'id',
								keyword: "keyword"
							},
							columns: [
								{
									label: '角色名称',
									prop: 'name',
								},
								{
									label: '备注',
									prop: 'remark',
								},
							],
							//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
							assignFields: [
								{
									//对应表格列表数据字段
									value: 'id',
									//赋值给到的字段名称
									field: 'roleId',
								},
							],
						}
					},
					{
						label: '状态',
						prop: 'flag',
						type: 'select',
						short: 12,
						span: 8,
						data: [
							{
								label: '正常',
								value: 1,
							},
							{
								label: '锁定',
								value: 0,
							},
						]
					},
					// {
					// 	label: '操作用户',
					// 	prop: 'fz_operateName',
					// 	type: 'popUser',
					// 	span: 8,
					// 	short: 12,
					// 	fields: ['operateId', 'fz_operateName'],
					// },
					// {
					// 	label: '操作时间',
					// 	type: 'dateTimeRange',
					// 	span: 8,
					// 	//开始结束时间字段
					// 	fields: [
					// 		{
					// 			prop: 'fz_addTime0',
					// 		},
					// 		{
					// 			prop: 'fz_addTime1',
					// 		},
					// 	],
					// },
				],
			},
		})

		onMounted(() => {
			//消息监听
			state.hideForm=!state.hideForm
		})
		//显示弹窗
		const showDialog = (param) => {
			state.visible = true
			if(param){
				state.tableConfig.params = param
				state.params = Object.assign({}, param)
			}
			getShowAdd()
			getShowEdit()
			state.loading = false
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			state.selections = []
			state.selData = []
		}

		//查询新增权限
		const getShowAdd = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ORGANIZE_USER,'add')
			//有结果标识有权限
			if(res.data){
				state.showAdd = true
			}else{
				state.showAdd = false
			}
		}

		//查询修改权限
		const getShowEdit = async () => {
			const res = await proxy.$API.menuapi.getSearch.get(proxy.$MENU.code.ORGANIZE_USER,'edit')
			//有结果标识有权限
			if(res.data) {
				state.showEdit = true
			}else{
				state.showEdit = false
			}
		}

		//切换显示左侧边栏
		const handleShowLeft = (bool) => {
			state.leftShow = bool
		}

		/*------------主表数据操作-----------------*/

		// 复选框选择回调函数 val：选中的数据
		const selectChange = (val) => {
			state.selections = val
		}

		//全选
		const handleSelectAll = (selection) => {
			if(selection && selection.length > 0){
				selection.forEach((item) => {
					setSelData(item)
				})
			}else{
				state.selData = []
			}
		}

		//复选框选择
		const handleSelect = (selection, row) => {
			if (!row) {
				return
			}
			const index = selection.indexOf(row)
			const index1 = state.selData.indexOf(row)
			if (index < 0) {
				state.selData.splice(index1, 1)
			} else {
				if (index1 < 0) {
					state.selData.push(row)
				}
				proxy.$refs.tableSel.toggleRowSelection(row, false)
			}

		}

				//添加选中数据到已选
		const addToRight = () => {
			//添加已选数据
			state.selections.forEach((item) => {
				setSelData(item)
			})
		}

		//选中行改变
		const currentChange =(row)=>{
			state.curRow = row
			if(state.curRow && JSON.stringify(state.curRow) != '{}'){
				state.isDisabled = false
				if(!props.multiple){
					state.isConfirm = false
				}
			}else{
				state.isDisabled = true
				if(!props.multiple){
					state.isConfirm = true
				}
			}
		}

		const handleConfirm = () => {
			let data
			if (!props.multiple) { //多选
				data = state.curRow
			} else {

				if (state.selData && state.selData.length > 0) {
					state.selections = state.selData
				}
				data = state.selections

			}
			//触发父级赋值方法
			emit('set-data', data)
			//隐藏弹窗
			hideDialog()

		}

		//行双击
		const dblClick = (row) => {
			if(row && !props.multiple){
				//传入选中行和赋值的字段
				emit('set-data', row)
				hideDialog()
			}
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		// 行单击
		const firClick = (row) => {
			// // 这是单选
			// if(row && !props.multiple){
			// 	// //添加已选数据
			// 	// setSelData(row)
			// 	// 传入选中行和赋值的字段
			// 	emit('set-data', row)
			// 	hideDialog()
			// }

			//多选
			if(props.multiple){
				//添加已选数据
				setSelData(row)
			}
		}

		//切换显示左侧栏内容
		const handleClsClick = (num) => {
			state.curCls = num
			//刷新表格
			proxy.$refs.table.reload()
			//清除查询条件
			state.queryForm = {}
			if(num === 1){
				state.switchText = '部门'
			}else{
				state.switchText = '角色'
			}
		}

		//左侧树节点点击
		const fetchDataNode = (data, type) => {
			let params = {}
			if(type === 1){ //部门
				params = { departmentId: data.id }
			}else if(type === 2){ //角色
				params = { roleId: data.id }
			}
			proxy.$refs.table.reload(params)
		}

		//用户搜索
		const handleSearch = () => {
			proxy.$refs.table.reload(state.queryForm)
		}

		//分栏拖动结束
		const handleResized = (res) => {
			if(state.leftShow){
				state.paneSize = res[0].size
			}
		}

		//查询数据，刷新表格
		const fetchData = (data) => {
			//查询参数{ 字段名：值, 字段名：值, ... }
			state['table'].reload(data)
		}

		//保存表单显示状态
		const handleFitShow = (bool) => {
			state.showItem = bool
		}

		//切换显示搜索栏
		const handleShowSearch = () => {
			nextTick(() => {
				state.hideForm = !state.hideForm
			})
		}

		/*--------------------已选数据操作---------------------------*/
		//已选数据组装
		const setSelData = (data) => {
			const index = state.selData.findIndex(item => JSON.stringify(item) === JSON.stringify(data));
			proxy.$refs.tableSel.toggleRowSelection(data, false)
			if (data && index === -1) {
				state.selData.push(data)
			} else {
				state.selData = state.selData.filter(item => item.id !== data.id)
			}
			if(state.selData.length > 0){
				state.isConfirm = false

			}else{
				state.selData.splice(index, 1)
			}
			showChooseData()
		}

		//清空已选数据
		const clearSelections = () => {
			state.selData = []
			proxy.$refs.table.clearSelection()
			// emit('set-data', state.selData)
		}

		//已选数据表选中的数据变化
		const tableSelselectChange = (val) => {
			state.selectionsTableSel = val
			if(val.length > 0){
				state.isDelSel = false
			}else{
				state.isDelSel = true
			}
		}

		//删除选中的已选数据
		const delSelData = () => {
			if (state.selectionsTableSel.length > 0) {
				state.selectionsTableSel.forEach((item) => {
					const index = state.selData.indexOf(item)
					const index1 = state.selections.indexOf(item)
					if (index > -1) {
						state.selData.splice(index, 1)
					}
					if(index1 > -1){
						proxy.$refs.table.toggleRowSelection(item, false)
					}
				})
			}
		}

		//回显选中状态
		const showChooseData = () => {
			nextTick(() => {
				const dataTable = proxy.$refs.table
				const tableData = dataTable.tableData
				tableData.forEach((item) => {
					dataTable.toggleRowSelection(item, false)
				})
				state.selData.forEach((item) => {
					const findItem = tableData.find((son) => {
						return son.id === item.id
					})
					if (findItem) {
						dataTable.toggleRowSelection(findItem, true)
					}
				})
			})
		}

		//新增修改弹窗
		const handleEdit = (type, ref) => {
			let param = {}
			if(type === 2 && state.curRow && JSON.stringify(state.curRow) != '{}'){ //编辑
				param = state.curRow
			}
			proxy.$refs.refEdit.triggerDom = proxy.$refs[ref]
			proxy.$refs.refEdit.showDialog(param)
		}

		watch(
			() => state.selData,
			(val) => {
				if(val.length > 0){
					state.isConfirm = false
				}else{
					state.isConfirm = true
				}
			},
			{ deep: true }
		)

		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		//添加行回调
		const handleAddRow = (index) => {
			const sonTable = proxy.$refs.refSonDetail
			const tableData = sonTable.list
			//点击加号复制上一行数据
			Object.assign(tableData[index], tableData[index-1])
		}

		return{
			...toRefs(state),
			tableSelselectChange,
			handleDetail,
			addToRight,
			delSelData,
			handleEdit,
			clearSelections,
			handleShowSearch,
			handleFitShow,
			fetchData,
			handleResized,
			handleClsClick,
			handleSearch,
			currentChange,
			selectChange,
			fetchDataNode,
			handleConfirm,
			handleShowLeft,
			showDialog,
			hideDialog,
			handleSelect,
			handleSelectAll,
			firClick,
			dblClick,
			handleAddRow
		}
	},
})
</script>
