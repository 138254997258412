<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:type="type"
		:loading="loading"
		:width="width"
		:bef-close="false"
	>
		<div class="popPane">
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="2"
				:size="$store.state.global.uiSize"
				border
				class="column2"
			>
				<el-descriptions-item
					label="工序名称"
					:label-align="labelAlign"
				>
					{{ data.name }}
				</el-descriptions-item>

				<el-descriptions-item
					label="工序编号"
					:label-align="labelAlign"
				>
					{{ data.code }}
				</el-descriptions-item>
				<el-descriptions-item
					label="工作组"
					:label-align="labelAlign"
				>
					<el-link
						type="primary"
						@click="handleDetail(data,'refWorkgroupDetail', 'workgroupId')"
					>
						{{ data.workGroupName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="生产车间"
					:label-align="labelAlign"
				>
					{{ data.fz_workshopName }}
				</el-descriptions-item>

				<el-descriptions-item
					label="生产人员"
					:label-align="labelAlign"
				>
					{{ data.fz_managerName }}
				</el-descriptions-item>

				<el-descriptions-item
					label="设备名称"
					:label-align="labelAlign"
				>
					{{ data.fz_deviceName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="标准效率"
					label-align="right"
				>
					{{ data.workTime }}
				</el-descriptions-item>
				<el-descriptions-item
					label="报工数配比"
					label-align="right"
				>
					{{ data.workReportRatio }}
				</el-descriptions-item>
				<el-descriptions-item
					label="计价方式"
					label-align="right"
				>
					{{ data.priceMethod === 0 ? "计件" : "计时" }}
				</el-descriptions-item>
				<el-descriptions-item
					label="计件工资"
					label-align="right"
				>
					{{ data.wageSalary }}
				</el-descriptions-item>
				<el-descriptions-item
					label="计时工资"
					label-align="right"
				>
					{{ data.hourSalary }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作用户"
					:label-align="labelAlign"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					:label-align="labelAlign"
				>
					{{ data.addTime }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="不良品项"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<el-tag type="info" v-for="item in data.wasteList" :key="item.id">{{ item.name }}</el-tag>
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="工序要求"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.requirement }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="作业指导书"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<el-image
						v-for="(file, index) in imgsList"
						:key="index"
						fit="cover"
						class="imgViewItem"
						:preview-src-list="imgsPreviewList"
						hide-on-click-modal
						:z-index="9999"
						:src="$CONFIG.API_URL + '/'+'api/' +file.url"
					/>
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="检验指导书"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<el-image
						v-for="(file, index) in examImgsList"
						:key="index"
						fit="cover"
						class="imgViewItem"
						:preview-src-list="examImgsPreviewList"
						hide-on-click-modal
						:z-index="9999"
						:src="$CONFIG.API_URL + '/'+'api/' +file.url"
					/>
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</div>
		<!--    <template #footer>-->
		<!--      <div class="text-center">-->
		<!--        <el-button-->
		<!--          :size="$store.state.global.uiSize"-->
		<!--          @click="visible = false"-->
		<!--        >-->
		<!--          关 闭-->
		<!--        </el-button>-->
		<!--      </div>-->
		<!--    </template>-->
		<WorkgroupDetail ref="refWorkgroupDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from "vue";
import WorkgroupDetail from "@/views/erp/produce/craft/workgroup/detail.vue";

export default defineComponent({
	components: {WorkgroupDetail},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			//弹窗宽度
			width: "60%",
			//加载动画
			loading: true,
			type: "detail",
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "工序详情",
			data: {},
			//详情数据对象
			deswidth: "25%",
			fileList: [],
			imgsList: [],
			imgsPreviewList: [],
			examImgsList: [],
			examImgsPreviewList: [],
		});

		//显示弹窗
		const showDialog = async (data) => {
			state.fileList = [];
			state.imgsList = [];
			state.examImgsList = [];
			state.imgsPreviewList = [];
			state.examImgsPreviewList = [];
			state.data = {};
			state.visible = true;
			state.loading = true;
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.produceCraftProcedure.detail.get(data.id);
				console.log(res);
				if (res.code === 200) {
					state.data = res.data;
					getFileList(res.data.imgs, "imgsList");
					getFileList(res.data.examImgs, "examImgsList");
					getFileList(res.data.files, "fileList");
				}
			}
			state.loading = false;
		};

		const getFileList = (data, list) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state[list].push(obj);
						if (list === "imgsList") {
							state.imgsPreviewList.push(proxy.$CONFIG.API_URL + "/" + "api/" + item);
						}
						if (list === "examImgsList") {
							state.examImgsPreviewList.push(proxy.$CONFIG.API_URL + "/" + "api/" + item);
						}
					});
				}
			}
		};

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
			await proxy.$API.files.insertFilesDownload.post(file);
		};

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};
		return {
			...toRefs(state),
			showDialog,
			previewFile,
			hideDialog,
			handleDetail,
		};
	},
});
</script>
