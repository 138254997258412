<template>
  <!--sc-dialog 弹窗组件
  :title="title" 弹窗标题
  :loading="loading" 弹窗加载动画
  :width="500" 弹窗宽度
  :before-close="beforeClose" 弹窗关闭前回调方法
  :trigger-dom="triggerDom" 触发打开弹窗的元素ref
  :close-on-click-modal="false" 点击遮罩层是否可关闭弹窗
  @reset="reset" 关闭弹窗回调函数,这里执行重置表单
  -->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <!--表单-->
    <div class="syForm formset">
      <el-form
        ref="refForm"
        :model="form"
        :label-width="labelWidth"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="设备名称"
        >
          <el-input
            v-model="form.name"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="设备编号"
        >
          <el-input
            v-model="form.code"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="brand"
          label="品牌"
        >
          <el-input
            v-model="form.brand"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
<!--        <el-form-item-->
<!--          prop="stype"-->
<!--          label="类型"-->
<!--        >-->
<!--          <el-input-->
<!--            v-model="form.stype"-->
<!--            clearable-->
<!--            placeholder="请输入"-->
<!--            maxlength="32"-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item
          prop="spec"
          label="规格"
        >
          <el-input
            v-model="form.spec"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="supplier"
          label="生产厂家"
        >
          <el-input
            v-model="form.supplier"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="factoryDate"
          label="出厂日期"
        >
          <el-date-picker
            v-model="form.factoryDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="purchaseDate"
          label="采购日期"
        >
          <el-date-picker
            v-model="form.purchaseDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
        <el-form-item
          prop="produceDate"
          label="投产日期"
        >
          <el-date-picker
            v-model="form.produceDate"
            type="date"
            value-format="YYYY-MM-DD"
            clearable
          />
        </el-form-item>
        <!--文本框-->
        <el-form-item
          prop="remark"
          label="备注"
        >
          <!--
          type="textarea"表示文本输入框
          rows="5"默认行数，越大文本框越高
          -->
          <el-input
            v-model="form.remark"
            type="textarea"
            maxlength="255"
            rows="5"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--弹窗底部自定义-->
    <template #footer>
		<el-popconfirm
			:visible="visibles"
			:title="$TIPS.SAVE"
			confirm-button-text="确定"
			cancel-button-text="取消"
			@cancel="visibles = false"
			@confirm="handleConfirm"
		>
			<template #reference>
				<el-button
					type="primary"
					:size="$store.state.global.uiSize"
					:loading="subLoading"
					@click = "valiForm"
				>
					提交
				</el-button>
			</template>
		</el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
      <!--el-popconfirm 确认组件
       title:提示文字
       confirm-button-text:确认按钮文本
       cancel-button-text:取消按钮文本-->
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <!--复制信息弹窗 copyConfig:弹出配置项 set-form:点击回调函数-->
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #name="{ scope, item }">
        <el-link
          :size="$store.state.global.uiSize"
          type="primary"
          @click="handleDetail(scope.row,'detailDialog')"
        >
          {{ scope.row[item.prop] }}
        </el-link>
      </template>
      <template #flag="{ scope }">
        <el-tag
          v-if="scope.row.flag === 0"
          type="danger"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          停用
        </el-tag>
        <el-tag
          v-if="scope.row.flag === 1"
          type="success"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          启用
        </el-tag>
        <el-tag
          v-if="scope.row.flag === 2"
          type="info"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          报废
        </el-tag>
        <el-tag
          v-if="scope.row.flag === 3"
          type="info"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          封存
        </el-tag>
        <el-tag
          v-if="scope.row.flag === 4"
          type="info"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          借出
        </el-tag>
        <el-tag
          v-if="scope.row.flag === 5"
          type="info"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          转让
        </el-tag>
        <el-tag
          v-if="scope.row.flag === 6"
          type="info"
          class="myTag"
          :size="$store.state.global.uiSize"
        >
          维修
        </el-tag>
      </template>
    </SyCopyDialog>
    <!--详情页弹出-->
    <detail ref="detailDialog" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance,
	nextTick,
} from 'vue'
import Detail from '@/views/erp/device/devices/detail'
export default defineComponent({
	components: { Detail },
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()

		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}

		const state = reactive({
      refFormBar:null,
			codeMsg: '',
			multiples: false,
			//触发元素
			triggerDom: {},
			//弹窗对象
			refScDialog: null,
			//加载动画
			loading: true,
			//表单组件 dom
			refForm: null,
			//当前弹窗组件 dom
			refDialog: null,
			//提交加载动画
			subLoading: false,
			//全屏显示
			fullScreen: false,
			//表单label宽度
			labelWidth: 90,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '资金账户新增',
			//表单当前数据
			form: {
				manager: 0,
			},
			//暂存原始数据
			orgForm: {},
			//表单验证规则 trigger: 可选'blur' 'change' 'focus'
			rules: {
				name: [{required: true, message: '必填'}],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.device.getList,
				//弹窗标题
				title:'复制设备信息',
				height: '500px',
				//表格显示列
				columns: [
					{
						label: '设备名称',
						prop: 'name',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '设备编号',
						prop: 'code',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '品牌',
						prop: 'brand',
						width: 120,
						sortable: 'custom',
					},
					// {
					// 	label: '类型',
					// 	prop: 'stype',
					// 	width: 110,
					// 	sortable: 'custom',
					// },
					{
						label: '型号',
						prop: 'model',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '规格',
						prop: 'spec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '外形尺寸',
						prop: 'outline',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '负责人',
						prop: 'fz_managerName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '重量',
						prop: 'weight',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '生产厂家',
						prop: 'supplier',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '出厂日期',
						prop: 'factoryDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '采购日期',
						prop: 'purchaseDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '投产日期',
						prop: 'produceDate',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '采购成本[元]',
						prop: 'cost',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '存放地点',
						prop: 'position',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '保养周期',
						prop: 'maintain',
						width: 130,
						sortable: 'custom',
					},
					{
						label: '功率[W]',
						prop: 'power',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '电费[元]',
						prop: 'powerRate',
						width: 150,
						sortable: 'custom',
					},
					{
						label: '负责人',
						prop: 'fz_managerName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '用途',
						prop: 'purpose',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '状态',
						prop: 'flag',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
					},
				],
				//查询表单配置
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//查询表单项配置
					itemList: [
						//文本框
						{
							label: '设备名称',
							prop: 'name',
							type: 'input',
							max: 32,
							span: 8,
							default: true,
						},
						{
							label: '设备编号',
							prop: 'code',
							type: 'input',
							max: 255,
							span: 8,
							default: true,
						},
						{
							label: '品牌',
							prop: 'brand',
							type: 'input',
							max: 255,
							span: 8,
							default: true,
						},
						// {
						// 	label: '类型',
						// 	prop: 'stype',
						// 	type: 'input',
						// 	max: 32,
						// 	span: 8,
						// },
						{
							label: '型号',
							prop: 'model',
							max: 255,
							type: 'input',
							span: 8,
						},
						{
							label: '规格',
							prop: 'spec',
							max: 255,
							type: 'input',
							span: 8,
						},
						{
							label: '外形尺寸',
							prop: 'outline',
							max: 255,
							type: 'input',
							span: 8,
						},
						{
							label: '重量',
							prop: 'weight',
							max: 32,
							type: 'input',
							short: 12,
							span: 8,
						},
						{
							label: '生产厂家',
							prop: 'supplier',
							max: 255,
							span: 8,
							type: 'input',
						},
						{
							label: '出厂日期',
							type: 'dateRange',
							span: 8,
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_factoryDate0',
								},
								{
									prop: 'fz_factoryDate1',
								},
							],
						},
						{
							label: '采购日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_purchaseDate0',
								},
								{
									prop: 'fz_purchaseDate1',
								},
							],
						},
						{
							label: '投产日期',
							span: 8,
							type: 'dateRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_produceDate0',
								},
								{
									prop: 'fz_produceDate1',
								},
							],
						},
						{
							label: '采购成本',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_cost0',
								},
								{
									prop: 'fz_cost1',
								}
							]
						},
						{
							label: '存放地点',
							span: 8,
							prop: 'position',
							type: 'input',
							max: 255,
						},
						{
							label: '保养周期',
							span: 8,
							prop: 'maintain',
							type: 'input',
							max: 255,
						},
						{
							label: '功率',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_power0',
								},
								{
									prop: 'fz_power1',
								}
							]
						},
						{
							label: '电费',
							span: 8,
							type: 'rangeNum',
							fields: [
								{
									prop: 'fz_powerRate0',
								},
								{
									prop: 'fz_powerRate1',
								}
							]
						},
						{
							label: '负责人',
							span: 8,
							prop: 'fz_managerName',
              max:32,
							type: 'popUser',
							short: 12,
							fields: ['managerId', 'fz_managerName'],
						},
						{
							label: '状态',
							span: 8,
							prop: 'flag',
							type: 'select',
							short: 12,
							data: [
								{
									label: '停用',
									value: 0,
								},
								{
									label: '启用',
									value: 1,
								},
								{
									label: '报废',
									value: 2,
								},
								{
									label: '封存',
									value: 3,
								},
								{
									label: '借出',
									value: 4,
								},
								{
									label: '转让',
									value: 5,
								},
								{
									label: '维修',
									value: 6,
								},
							]
						},
						{
							label: '用途',
							span: 8,
							prop: 'purpose',
							type: 'input',
							max: 255,
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 255,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
							type: 'popUser',
              max:32,
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					]
				},
			},
			//赋值字段
			fields: [],
		})
		//显示弹窗
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) == '{}') { //新增
				state.title="新增设备"
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title="编辑设备[" + row.name + ']'
				await getDetailData(row)
			}
			state.loading = false
		}

		//根据id查询数据
		const getDetailData = async (row) => {
			//根据id查询
			const res = await proxy.$API.device.detail.get(row.id)
			if(res.code === 200) {
				state.form = Object.assign({}, res.data)
				state.orgForm = Object.assign({}, res.data)
			}
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}

		//弹窗关闭前调用方法
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.CONFIRM_CLOSE, null, () => {
					done()
					reset(true)
				})
			})
		}

		//关闭确认
		const handleConfirm = () => {
			save()
			state.visibles = false
		}

		const valiForm = () => {
			nextTick(()=>{
				state.refForm.validate((valid)=>{
					if(valid){
						state.visibles = true
					}else {
						state.visibles = false
					}
				})
			})
		}

		//提交方法
		const save = () => {
			state['refForm'].validate(async (valid) => {
				if (valid) {
					state.subLoading = true
					let res = null
					if (state.form.id) { //修改
						res = await proxy.$API.device.update.put(state.form)
					} else {
						res = await proxy.$API.device.insert.post(state.form)
					}
					if (res.code === 200) {
						proxy.$baseMessage(res.msg, 'success')
						emit('fetch-data')
						hideDialog()
					} else {
						proxy.$baseMessage(res.msg, 'error')
					}
					state.subLoading = false
				}
			})
		}

		//重置表单
		const reset = (isClose) => {
			state['refForm'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if(!isClose){
				state.form = Object.assign({}, orgForm)
			}
		}

		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data,field) => {
			if(data.length > -1){ //多选
				let ids = ''
				if(data.length > 0){
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				}else{
					ids = ''
				}
				state.form[field] = ids
			}else{ //单选
				if(data && data.id){
					//将选择的数据id赋值给指定的form中的属性 例：groupId
state.form[field[0]] = data.id
				}
			}
		}

		//复制弹窗
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				//copyDialog弹窗的ref属性
				proxy.$refs.copyDialog.showDialog()
			})
		}

		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id 选择复制的信息后需要删除带过来的id
			delete state.form.id
		}

		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.company.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					await state['myForm'].validateField('number')
				}
			}
		}

		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}
		return {
			...toRefs(state),
			handleCodeBlur,
			setFormData,
			copyData,
			handleSelChange,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			valiForm,
			handleDetail
		}
	},
})
</script>
