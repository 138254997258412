import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/hr/wage/wageTax/getList`,
		name: "列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	delete: {
		url: `${config.API_URL}/hr/wage/wageTax/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},

	insert: {
		url: `${config.API_URL}/hr/wage/wageTax/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	update: {
		url: `${config.API_URL}/hr/wage/wageTax/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	},

	detail: {
		url: `${config.API_URL}/hr/wage/wageTax/detail`,
		name: "查询单条数据",
		get: async function (params) {
			return await http.get(this.url + '?id=' + params);
		}
	},

	doCheckCode: {
		url: `${config.API_URL}/hr/wage/wageTax/checkCode`,
		name: "编码唯一校验",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	getDetailList: {
		url: `${config.API_URL}/hr/wage/wageTaxDetail/getList`,
		name: "明细列表",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},

	updateDefaultSet: {
		url: `${config.API_URL}/hr/wage/wageTax/updateDefaultSet`,
		name: "设为默认",
		get: async function(id,defaultSet){
			return await http.get(this.url + '?id=' + id + '&defaultSet=' + defaultSet);
		}
	},
	getListByKeyword: {
		url: `${config.API_URL}/hr/wage/wageTax/getListByKeyword`,
		name: "关键字筛选",
		get: async function(data={}){
			return await http.post(this.url, data);
		}
	},
}
