import config from "@/config"
import http from "@/utils/request"

export default {
	getList: {
		url: `${config.API_URL}/mobile/purchase/purchaseRefund/getList`,
		name: "获取所有采购退货单",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	goodsGetList: {
		url: `${config.API_URL}/mobile/purchase/refundGoods/getList`,
		name: "获取采购退货单物品详情",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	detail: {
		url: `${config.API_URL}/mobile/purchase/purchaseRefund/detail`,
		name: "获取采购退货单详情",
		get: async function (id) {
			return await http.get(this.url + '?id='+ id);
		}
	},
	delete: {
		url: `${config.API_URL}/mobile/purchase/purchaseRefund/delete`,
		name: "删除",
		get: async function (params) {
			return await http.get(this.url + '?ids=' + params);
		}
	},
	insert: {
		url: `${config.API_URL}/mobile/purchase/purchaseRefund/insert`,
		name: "新增",
		post: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	update: {
		url: `${config.API_URL}/mobile/purchase/purchaseRefund/update`,
		name: "修改",
		put: async function (data = {}) {
			return await http.put(this.url, data);
		}
	}
}
