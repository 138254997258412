<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
  <sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :type="type"
    :loading="loading"
    :width="width"
  >
    <el-card
      header="基本信息"
      shadow="never"
      class="mycard"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="合同编号"
          :label-align="labelAlign"
        >
          {{ data.number }}
        </el-descriptions-item>
        <el-descriptions-item
          label="用工形式"
          :label-align="labelAlign"
        >
          {{ data.employment }}
        </el-descriptions-item>
        <el-descriptions-item
          label="劳动合同类型"
          :label-align="labelAlign"
        >
          {{ data.contractType }}
        </el-descriptions-item>
        <el-descriptions-item
          label="合同期限类型"
          :label-align="labelAlign"
        >
          {{ data.termType }}
        </el-descriptions-item>
        <el-descriptions-item
          label="签约日期"
          :label-align="labelAlign"
        >
          {{ data.signDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="合同期限(月)"
          :label-align="labelAlign"
        >
          {{ data.contractTerm }}
        </el-descriptions-item>
        <el-descriptions-item
          label="生效日期"
          :label-align="labelAlign"
        >
          {{ data.effectDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="到期日期"
          :label-align="labelAlign"
        >
          {{ data.expireDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="试用期限(月)"
          :label-align="labelAlign"
        >
          {{ data.trialTerm }}
        </el-descriptions-item>
        <el-descriptions-item
          label="试用到期日期"
          :label-align="labelAlign"
        >
          {{ data.trialDate }}
        </el-descriptions-item>

        <el-descriptions-item
          label="备注"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
        <el-descriptions-item
          label="签订操作人"
          :label-align="labelAlign"
        >
          {{ data.fz_signWorkerName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="终止操作人"
          :label-align="labelAlign"
        >
          {{ data.fz_stopWorkerName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="终止操作日期"
          :label-align="labelAlign"
        >
          {{ data.stopDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="合同状态"
          :label-align="labelAlign"
        >
          <el-tag
            v-if="data.contractStatus === 0"
            type="info"
            class="myTag"
          >
            待执行
          </el-tag>
          <el-tag
            v-if="data.contractStatus === 1"
            type="success"
            class="myTag"
          >
            执行中
          </el-tag>
          <el-tag
            v-if="data.contractStatus === 2"
            type="danger"
            class="myTag"
          >
            已终止
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item
          label="操作用户"
          :label-align="labelAlign"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="操作时间"
          :label-align="labelAlign"
        >
          {{ data.addTime }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="员工信息"
      shadow="never"
      class="mycard mt_10"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="职员姓名"
          :label-align="labelAlign"
        >
          {{ data.workerName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工号"
          :label-align="labelAlign"
        >
          {{ data.workerCode }}
        </el-descriptions-item>
        <el-descriptions-item
          label="部门路径"
          :label-align="labelAlign"
        >
          {{ data.departmentName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="性别"
          :label-align="labelAlign"
        >
          <span v-if="data.gender === 1">男</span>
          <span v-if="data.gender === 0">女</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="出生日期"
          :label-align="labelAlign"
        >
          {{ data.birthday }}
        </el-descriptions-item>
        <el-descriptions-item
          label="证件类型"
          :label-align="labelAlign"
        >
          {{ data.certType }}
        </el-descriptions-item>
        <el-descriptions-item
          label="证件号"
          :label-align="labelAlign"
        >
          {{ data.certNumber }}
        </el-descriptions-item>
        <el-descriptions-item
          label="籍贯"
          :label-align="labelAlign"
        >
          {{ data.nativePlace }}
        </el-descriptions-item>
        <el-descriptions-item
          label="家庭住址"
          :label-align="labelAlign"
        >
          {{ data.homeAddress }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="工作信息"
      shadow="never"
      class="mycard mt_10"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="到岗日期"
          :label-align="labelAlign"
        >
          {{ data.reportDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="岗位名称"
          :label-align="labelAlign"
        >
          {{ data.postName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工作地点"
          :label-align="labelAlign"
        >
          {{ data.workPiston }}
        </el-descriptions-item>
        <el-descriptions-item
          label="应达工作标准"
          :label-align="labelAlign"
        >
          {{ data.standard }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工时制度"
          :label-align="labelAlign"
        >
          {{ data.workHour }}
        </el-descriptions-item>
        <el-descriptions-item
          label="休假制度"
          :label-align="labelAlign"
        >
          {{ data.vacation }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工资制度"
          :label-align="labelAlign"
        >
          {{ data.wageSystem }}
        </el-descriptions-item>
        <el-descriptions-item
          label="月工资"
          :label-align="labelAlign"
        >
          {{ data.wage }}
        </el-descriptions-item>
        <el-descriptions-item
          label="工资说明"
          :label-align="labelAlign"
        >
          {{ data.wageExplain }}
        </el-descriptions-item>
        <el-descriptions-item
          label="试用工资"
          :label-align="labelAlign"
        >
          {{ data.trialWage }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发薪日期"
          :label-align="labelAlign"
        >
          {{ data.payDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="生活费标准"
          :label-align="labelAlign"
        >
          {{ data.alimony }}
        </el-descriptions-item>
        <el-descriptions-item
          label="待工工资"
          :label-align="labelAlign"
        >
          {{ data.waitWage }}
        </el-descriptions-item>
        <el-descriptions-item
          label="其他工资"
          :label-align="labelAlign"
        >
          {{ data.otherWage }}
        </el-descriptions-item>
        <el-descriptions-item
          label="病假工资"
          :label-align="labelAlign"
        >
          {{ data.leaveWage }}
        </el-descriptions-item>
        <el-descriptions-item
          label="福利待遇"
          :label-align="labelAlign"
        >
          {{ data.welfare }}
        </el-descriptions-item>
        <el-descriptions-item
          label="其他事项"
          :label-align="labelAlign"
        >
          {{ data.otherMatters }}
        </el-descriptions-item>
        <el-descriptions-item
          label="劳动保护条款"
          :label-align="labelAlign"
        >
          {{ data.protectionClause }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      header="公司信息"
      shadow="never"
      class="mycard mt_10"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="公司名称"
          :label-align="labelAlign"
        >
          {{ data.company }}
        </el-descriptions-item>
        <el-descriptions-item
          label="法人代表"
          :label-align="labelAlign"
        >
          {{ data.legalPerson }}
        </el-descriptions-item>
        <el-descriptions-item
          label="公司地址"
          :label-align="labelAlign"
        >
          {{ data.companyAddress }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
<!--    <template #footer>-->
<!--      <div class="text-center">-->
<!--        <el-button-->
<!--          :size="$store.state.global.uiSize"-->
<!--          @click="visible = false"-->
<!--        >-->
<!--          关 闭-->
<!--        </el-button>-->
<!--      </div>-->
<!--    </template>-->
  </sc-dialog>
</template>

<script>
	import {
		defineComponent,
		toRefs,
		reactive,
		getCurrentInstance
	} from 'vue'
	export default defineComponent({
		setup(){
			//获取当前实例对象
			const { proxy } = getCurrentInstance()
			const state = reactive({
				//弹窗宽度
				width: '60%',
				//加载动画
				loading: true,
				//标题对齐方式 'left' 'right' 'center'
				labelAlign: 'right',
        type:"detail",
				//尺寸大小
				size: 'small',
				//控制弹窗显示隐藏
				visible: false,
				//弹窗标题
				title: '合同详情',
				//详情数据对象
				data: null,
				fileList: [],
			})
			//显示弹窗
			const showDialog = async (data) => {
				state.data = data
				state.visible = true
				if(data.id){
					//根据id查询
					const res = await proxy.$API.laborContract.detail.get(data.id)
					if(res.code === 200){
						state.data = res.data
						getFileList(res.data.files)
					}
				}
				state.loading = false
			}

			//组装文件列表
			const getFileList = (data) => {
				let arr = []
				if(data){
					arr = data.split(',')
					if(arr.length > 0) {
						arr.forEach((item) => {
							let obj = {}
							const iarr = item.split('/')
							obj['url'] = item
							obj['name'] = iarr[iarr.length - 1]
							state.fileList.push(obj)
						})
					}
				}
			}
			const previewFile = (file) => {
				window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			}

			//隐藏弹窗
			const hideDialog = () => {
				state.visible = false
			}
			return{
				...toRefs(state),
				previewFile,
				showDialog,
				hideDialog,
			}
		},
	})
</script>
