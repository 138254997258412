<template>
  <sc-dialog
    ref="dialogRef"
    v-model="visible"
    :title="title"
    :loading="loading"
    :width="500"
    :before-close="beforeClose"
    :trigger-dom="triggerDom"
    :close-on-click-modal="false"
    @reset="reset"
  >
    <!--弹窗头部自定义-->
    <template #header>
      <span class="el-dialog__title">{{ title }}</span>
      <!--复制信息按钮 新增时显示：form.id-->
      <el-button
        v-if="!form.id"
        ref="copyBtn"
        class="ml10"
        :size="$store.state.global.uiSize"
        @click="copyData"
      >
        复制信息
      </el-button>
    </template>
    <div class="syForm formset">
      <el-form
        ref="formRef"
        :model="form"
        :label-width="90"
        size="small"
        :rules="rules"
      >
        <el-form-item
          prop="name"
          label="车间名称"
        >
          <el-input
            v-model="form.name"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          prop="code"
          label="车间编号"
        >
          <el-input
            v-model="form.code"
            clearable
            placeholder="请输入"
            maxlength="32"
            @blur="handleCodeBlur"
          />
        </el-form-item>
        <el-form-item
          prop="position"
          label="车间位置"
        >
          <el-input
            v-model="form.position"
            clearable
            placeholder="请输入"
            maxlength="32"
          />
        </el-form-item>
        <div class="fullWidth">
          <el-row
            :gutter="20"
          >
            <el-col :span="12">
              <el-form-item
                prop="fz_departmentName"
                label="所属部门"
              >
					<div class="disflex fullWidth sc-table-select--append">
						<div class="flexgs1">
							<sc-table-select
								v-model="form.fz_departmentName"
								customerValue
								fullWidth
                hide-pagination
								:api-obj="$API.department.getListByKeyword"
								:params="{
                        flag: 1,
                      }"
								:height="426"
								:props="{ label: 'name', value: 'id', keyword: 'name' }"
								@change="(val) => handleSelChange(val, ['departmentId','fz_departmentName'])"
								@clear="inputClear('department')"
							>
								<el-table-column
									prop="name"
									label="名称"
									width="150"
								/>
								<el-table-column
									prop="fz_departmentName"
									label="部门"
									width="150"
								/>
								<el-table-column
									prop="code"
									label="工号"
									width="150"
								/>
                  <template #append>
                    <el-icon @click="popSelect('refDepartmentIput',['departmentId','fz_departmentName'], 1, 'refSelectDepartment')">
                      <el-icon-search />
                    </el-icon>
                  </template>
							</sc-table-select>
						</div>
					</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="fz_managerName"
                label="负责人"
              >

					<div class="disflex fullWidth sc-table-select--append">
						<div class="flexgs1">
							<sc-table-select
								v-model="form.fz_managerName"
								customerValue
								fullWidth
								:api-obj="$API.user.getListByKeyword"
								:params="{
                        flag: 1,
                      }"
								:height="426"
								:props="{ label: 'name', value: 'id', keyword: 'name' }"
								@change="(val) => handleSelChange(val, ['managerId','fz_managerName'])"
								@clear="inputClear('manager')"
							>
								<el-table-column
									prop="name"
									label="名称"
									width="150"
								/>
								<el-table-column
									prop="fz_departmentName"
									label="部门"
									width="150"
								/>
								<el-table-column
									prop="code"
									label="工号"
									width="150"
								/>
                  <template #append>
                    <el-icon @click="popSelect('refManagerIput',['managerId','fz_managerName'], 1, 'refSelectUser')">
                      <el-icon-search />
                    </el-icon>
                  </template>
							</sc-table-select>
						</div>
					</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="personnel"
                label="车间人数"
              >
                <el-input
                  v-model="form.personnel"
                  clearable
                  placeholder="请输入"
                  maxlength="11"
                  @input="(val) => handleNumIpt(val, 'personnel')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="workTime"
                label="工作时长"
              >
                <el-input
                  v-model="form.workTime"
                  clearable
                  placeholder="请输入"
                  maxlength="11"
                  @input="(val) => handleNumIpt(val, 'workTime')"
                >
                  <template #suffix>
                    小时
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="efficiency"
                label="工作效率"
              >
                <el-input
                  v-model="form.efficiency"
                  clearable
                  placeholder="请输入"
                  maxlength="11"
                  @input="(val) => handleNumIpt(val, 'efficiency')"
                >
                  <template #suffix>
                    %
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="capacity"
                label="总工作能力"
              >
                <el-input
                  v-model="form.capacity"
                  clearable
                  placeholder="请输入"
                  maxlength="20"
                  @input="(val) => handleNumIpt(val, 'capacity')"
                >
                  <template #suffix>
                    小时
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="unitCost"
                label="单位成本"
              >
                <el-input
                  v-model="form.unitCost"
                  clearable
                  placeholder="请输入"
                  maxlength="20"
                  @input="(val) => handleNumIpt(val, 'unitCost')"
                >
                  <template #suffix>
                    ￥
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="isKey"
                label="是否关键车间"
              >
                <el-radio-group v-model="form.isKey">
                  <el-radio :label="1">
                    是
                  </el-radio>
                  <el-radio :label="0">
                    否
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-form-item
          prop="remark"
          label="备注"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
            rows="1"
            clearable
            show-word-limit
            placeholder="请输入"
            maxlength="255"
          />
        </el-form-item>
        <el-form-item
          prop="files"
          label="附件"
        >
          <sc-upload-multiple
            v-model="form.files"
            :title="'附件'"
            :accept="'*'"
            :is-file="true"
            :list-type="'text'"
            :limit="10"
          >
			  <el-button :size="$store.state.global.uiSize">
				  选择文件
			  </el-button>
          </sc-upload-multiple>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
		<el-popconfirm
			:visible="visibles"
			:title="$TIPS.SAVE"
			confirm-button-text="确定"
			cancel-button-text="取消"
			@cancel="visibles = false"
			@confirm="handleConfirm"
		>
			<template #reference>
				<el-button
					type="primary"
					:size="$store.state.global.uiSize"
					:loading="subLoading"
					@click = "valiForm"
				>
					提交
				</el-button>
			</template>
		</el-popconfirm>
      <el-popconfirm
        :title="$TIPS.CONFIRM_RESET"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="reset(false)"
      >
        <template #reference>
          <el-button
            :size="$store.state.global.uiSize"
          >
            重置
          </el-button>
        </template>
      </el-popconfirm>
<!--      <el-popconfirm-->
<!--        :title="$TIPS.CONFIRM_CLOSE"-->
<!--        confirm-button-text="确定"-->
<!--        cancel-button-text="取消"-->
<!--        @confirm="handleConfirm"-->
<!--      >-->
<!--        <template #reference>-->
<!--          <el-button-->
<!--            :size="$store.state.global.uiSize"-->
<!--          >-->
<!--            关闭-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-popconfirm>-->
    </template>
    <SyCopyDialog
      ref="copyDialog"
      :config="copyConfig"
      @set-form="setFormData"
      :ref-query-form="refFormBar"
      remote-sort
    >
      <template #name="{ scope }">
        <el-link
          type="primary"
          @click="handleDetail(scope.row)"
        >
          {{ scope.row.name }}
        </el-link>
      </template>
      <template #isKey="{ scope }">
        {{ scope.row.isKey === 1 ? '是' : '否' }}
      </template>
    </SyCopyDialog>
    <Detail ref="refDetail" />
    <!--选择用户-->
    <SelectUser
      ref="refSelectUser"
      @set-data="setSelectData"
    />
    <!--选择部门-->
    <SelectDepartment
      ref="refSelectDepartment"
      @set-data="setSelectData"
    />
  </sc-dialog>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs,} from 'vue'
import {resetNum, resetZnum} from "@/utils/validate";
import Detail from '@/views/erp/produce/craft/workshop/detail'
export default defineComponent({
	name: "SelectWorkShopEdit",
	components: { Detail },
	emits: ['fetch-data'],
	setup(props, {emit}) {
		const {proxy} = getCurrentInstance()
		//验证code重复
		const validateCode = (rule, value, callback) => {
			if (state.codeMsg) {
				callback(new Error(state.codeMsg))
			} else {
				callback()
			}
		}
		const state = reactive({
      refFormBar:null,
			triggerDom: {},
			//加载动画
			loading: true,
			//提交加载动画
			subLoading: false,
			dialogRef: null,
			//表单label宽度
			labelWidth: 70,
			formRef: null,
			//弹窗显示属性
			visible: false,
			visibles: false,
			//弹窗宽度
			width: 500,
			//弹窗标题
			title: '新增',
			//表单当前数据
			form: {
				isKey: 0
			},
			cacheFields: {},
			//暂存原始数据
			orgForm: {},
			rules: {
				name: [
					{required: true, trigger: 'blur', message: '必填'},
				],
				// code: [
				// 	{required: true, message: '必填'},
				// 	{validator: validateCode},
				// ],
			},
			//复制信息配置
			copyConfig: {
				//数据接口对象
				apiObj: proxy.$API.produceCraftWorkshop.getList,
				//弹窗标题
				title:'复制工作车间信息',
				//表格显示列
				columns: [
					{
						label: '车间名称',
						prop: 'name',
						width: 180,
						sortable: 'custom',
						fixed: 'left',
					},
					{
						label: '车间编号',
						prop: 'code',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '车间位置',
						prop: 'position',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '所属部门',
						prop: 'fz_departmentName',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '负责人',
						prop: 'fz_managerName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '车间人数',
						prop: 'personnel',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '工作时长[小时]',
						prop: 'workTime',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '工作效率(%)',
						prop: 'efficiency',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '总工作能力[小时]',
						prop: 'capacity',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '单位成本[元]',
						prop: 'unitCost',
						width: 140,
						sortable: 'custom',
					},
					{
						label: '是否关键车间',
						prop: 'isKey',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '备注',
						prop: 'remark',
						width: 180,
						sortable: 'custom',
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						width: 110,
						showOverflowTooltip: true,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						width: 200,
						showOverflowTooltip: true,
					},
				],
				searchConfig: {
					//表单标题宽度
					labelWidth: 90,
					//表单项配置
					itemList: [
						//文本框
						{
							label: '车间名称',
							prop: 'name',
							type: 'input',
							max: 255,
							default: true,
							span: 8,
						},
						{
							label: '车间编号',
							prop: 'code',
							type: 'input',
							max: 32,
							default: true,
							span: 8,
						},
						{
							label: '车间位置',
							prop: 'position',
							type: 'input',
							max: 255,
							default: true,
							span: 8,
						},
						{
							label: '所属部门',
							prop: 'department',
							type: 'tableSelect',
							span: 8,
							config: {
								apiObj: proxy.$API.department.getList,
								tableWidth: 500,
								height: 400,
                hidePagination: true,

								params: {
									parentId:0,
								},
								props: {
									label: 'name',
									value: 'id',
									keyword: "keyword"
								},
								columns: [
									{
										label: '部门名称',
										prop: 'name',
									},
									{
										label: '部门代码',
										prop: 'code',
									},
									{
										label: '部门经理',
										prop: 'fz_managerName',
									},
								],
								//赋值字段,选择完毕后把对应的值赋给搜索表单对象中的某几个属性
								assignFields: [
									{
										//对应表格列表数据字段
										value: 'id',
										//赋值给到的字段名称
										field: 'departmentId',
									},
								],
							}
						},
						{
							label: '负责人',
							prop: 'fz_managerName',
              max:32,
							type: 'popUser',
							span: 8,
							short: 12,
							fields: ['managerId', 'fz_managerName'],
						},
						{
							label: '车间人数',
							prop: 'personnel',
							type: 'number',
							span: 8,
							max: 11,
						},
						{
							label: '工作时长',
							type: 'rangeNum',
							span: 8,
							fields: [
								{
									prop: 'fz_workTime0'
								},
								{
									prop: 'fz_workTime1'
								},
							]
						},
						{
							label: '工作效率',
							type: 'rangeNum',
							span: 8,
							fields: [
								{
									prop: 'fz_efficiency0'
								},
								{
									prop: 'fz_efficiency1'
								},
							]
						},
						{
							label: '总工作能力',
							type: 'rangeNum',
							span: 8,
							fields: [
								{
									prop: 'fz_capacity0'
								},
								{
									prop: 'fz_capacity1'
								},
							]
						},
						{
							label: '单位成本',
							type: 'rangeNum',
							span: 8,
							fields: [
								{
									prop: 'fz_unitCost0'
								},
								{
									prop: 'fz_unitCost1'
								},
							]
						},
						{
							label: '是否关键车间',
							type: 'select',
							span: 8,
							short: 12,
							data: [
								{
									label: '是',
									value: 1,
								},
								{
									label: '否',
									value: 0,
								},
							],
						},
						{
							label: '备注',
							span: 8,
							prop: 'remark',
							type: 'input',
							max: 50,
						},
						{
							label: '操作用户',
							span: 8,
							prop: 'fz_operateName',
              max:32,
							type: 'popUser',
							short: 12,
							fields: ['operateId', 'fz_operateName'],
						},
						{
							label: '操作时间',
							span: 8,
							type: 'dateTimeRange',
							//开始结束时间字段
							fields: [
								{
									prop: 'fz_addTime0',
								},
								{
									prop: 'fz_addTime1',
								},
							],
						},
					],
				},
			}
		})
		const showDialog = async (row) => {
			state.visible = true
			if (JSON.stringify(row) === '{}') {
				state.title = '新增'
				state.orgForm = Object.assign({}, state.form)
			} else { //编辑
				state.title = '编辑'
				//根据id查询
				const res = await proxy.$API.produceCraftWorkshop.detail.get(row.id)
				if(res.code === 200) {
					state.form = Object.assign({}, res.data)
					state.orgForm = Object.assign({}, res.data)
				}
			}
			state.loading = false
		}
		//只能输入正整数
		const handleNumIpt = (val, field) => {
			if (val) {
				state.form[field] = resetZnum(val)
			}
		}
		//编码重复校验
		const handleCodeBlur = async (e) => {
			state.codeMsg = ''
			const val = e.target.value
			if (val !== '') {
				const res = await proxy.$API.produceCraftWorkshop.doCheckCode.post(state.form)
				if (res.code === 500) {
					state.codeMsg = res.msg
					state['formRef'].validateField('number')
				}
			}
		}
		const copyData = () => {
			nextTick(() => {
				//把触发元素传给弹窗
				proxy.$refs.copyDialog.triggerDom = proxy.$refs.copyBtn
				proxy.$refs.copyDialog.showDialog()
			})
		}
		//复制信息点击行后返回
		const setFormData = (data) => {
			state.form = Object.assign({},data)
			//删除id
			delete state.form.id
		}

		//关闭弹窗
		const hideDialog = () => {
			state.visible = false
			reset(true)
		}
		const beforeClose = (done) => {
			nextTick(() => {
				proxy.$baseConfirm(proxy.$TIPS.SUBMIT, null, () => {
					done()
					reset(true)
				})
			})
		}
		const handleConfirm = () => {
			save()
			state.visibles = false
		}

		const valiForm = () => {
			nextTick(()=>{
				state.formRef.validate((valid)=>{
					if(valid){
						state.visibles = true
					}else {
						state.visibles = false
					}
				})
			})
		}
		//确定按钮
		const save = () => {
			state['formRef'].validate(async (valid) => {
				if (valid) {
						state.subLoading = true
						let res
						if (state.form.id) { //修改
							res = await proxy.$API.produceCraftWorkshop.update.put(state.form)
						} else { //新增
							res = await proxy.$API.produceCraftWorkshop.insert.post(state.form)
						}
						if (res.code === 200) {
							proxy.$baseMessage(res.msg, 'success')
							emit('fetch-data')
							hideDialog()
						} else {
							proxy.$baseMessage(res.msg, 'error')
						}
						state.subLoading = false
				}
			})
		}
		//重置表单
		const reset = (isClose) => {
			state['formRef'].resetFields()
			const orgForm = state.orgForm
			Object.keys(state.form).forEach((item) => {
				delete state.form[item]
			})
			if (!isClose) {
				state.form = Object.assign({}, orgForm)
			}
		}
		const handleNumInput = (val, prop) => {
			if (val) {
				state.form[prop] = resetNum(val)
			}
		}

		//详情
		const handleDetail = (row) => {
			proxy.$refs.refDetail.showDialog(row)
		}

		const popSelect = (ref, fields, type, pop) => {
			nextTick(() => {
				let param = {}
				state.fields = fields
				if(type === 1){ //单选
					state.multiples = false
				}else{ //多选
					state.multiples = true
				}
				if(pop === 'refSelectUser') {
					param['flag'] = 1
				}
				//赋值弹窗的触发元素
				proxy.$refs[pop].triggerDom = proxy.$refs[ref]
				//打开弹窗
				proxy.$refs[pop].showDialog(param)
			})
		}

		const setSelectData = async (data) => {
			if(state.fields.length > 0){
				if(data.length){ //多选
					const ids = data.map((item) => { return item.id }).join()
					const names = data.map((item) => { return item.name }).join()
					state.form[state.fields[0]] = ids
					state.form[state.fields[1]] = names
				}else{ //单选
					state.form[state.fields[0]] = data.id
					state.form[state.fields[1]] = data.name
				}
				state.cacheFields = Object.assign({}, state.form)
			}
		}
		const inputBlur = (field) => {
			state.form['fz_' + field+ 'Name'] = state.cacheFields['fz_' + field+ 'Name']
		}

		//输入框清除
		const inputClear = (field) => {
			if(field != 'shareUser'){
				state.form[field + 'Id'] = ''
			}else{
				state.form[field] = ''
			}
			state.form['fz_' + field+ 'Name'] = ''
			state.cacheFields['fz_' + field+ 'Name'] = ''
		}
		//下拉表格选择完毕后回调 data：返回的数据, field需要赋值的字段名
		const handleSelChange = (data, fields) => {
			if (data.length > -1) { //多选
				let ids = ''
				if (data.length > 0) {
					//取得选中的id串
					ids = data.map((item) => item.id).join()
				} else {
					ids = ''
				}
				state.form[fields[0]] = ids
			} else { //单选
				if (data && data.id) {
					//将选择的数据id赋值给指定的form中的属性 例：groupId
					state.form[fields[0]] = data.id
					// if(data.contractNo){
					// 	state.form[fields[1]] = data.contractNo
					// }
				}
			}
			state.fields = fields
			setSelectData(data)
		}
		return {
			...toRefs(state),
			inputClear,
			inputBlur,
			setSelectData,
			popSelect,
			handleDetail,
			handleNumInput,
			copyData,
			setFormData,
			save,
			reset,
			beforeClose,
			showDialog,
			hideDialog,
			handleConfirm,
			handleNumIpt,
			handleCodeBlur,
			handleSelChange,
		valiForm
		};
	},
})
</script>

<style scoped>

</style>
