<template>
  <!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
<sc-dialog
    ref="refScDialog"
    v-model="visible"
    :title="title"
    :loading="loading"
    :type="type"
    :bef-close="false"
    :width="width"
  >
    <el-card
      shadow="never"
      class="mycard"
      header="基本信息"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <!--label: 标题 -->
        <el-descriptions-item
          label="订单编号"
          label-align="right"
        >
          {{ data.orderNo }}
        </el-descriptions-item>
        <el-descriptions-item
          label="供应商"
          label-align="right"
        >
          <el-link
            type="primary"
            @click="handleDetail(data, 'refSupplierDetail', 'supplierId')"
          >
            {{ data.fz_supplierName }}
          </el-link>
        </el-descriptions-item>

<!--        <el-descriptions-item-->
<!--          label="联系人"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.contacts }}-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item-->
<!--          label="联系手机"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.phone }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="联系电话"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.tel }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
          label="交货地址"
          label-align="right"
        >
          {{ data.deliveryAddress }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发票类型"
          label-align="right"
        >
					{{ data.fz_typeName }}
        </el-descriptions-item>


		  <el-descriptions-item
			  label="审核状态"
			  label-align="right"
		  >
			  <el-tag
				  v-if="data.auditStatus === -1"
				  style="color: #666;"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  待编辑
			  </el-tag>
			  <el-tag
				  v-if="data.auditStatus === 0"
				  tstyle="color: #666;"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  未提交
			  </el-tag>
			  <el-tag
				  v-if="data.auditStatus === 1"
				  type="warning"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  审核中
			  </el-tag>
			  <el-tag
				  v-if="data.auditStatus === 2"
				  type="danger"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  已驳回
			  </el-tag>
			  <el-tag
				  v-if="data.auditStatus === 3"
				  type="success"
				  class="myTag"
				  :size="$store.state.global.uiSize"
			  >
				  审核通过
			  </el-tag>
        <el-tag
            v-if="data.auditStatus === 4"
            type="info"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          未确认
        </el-tag>
        <el-tag
            v-if="data.auditStatus === 5"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
        >
          已确认
        </el-tag>

		  </el-descriptions-item>

		  <!--收货状态-->
		  <el-descriptions-item
			  label="收货状态"
			  label-align="right"
		  >
			  <el-tag
				  v-if="data.receiptStatus === 0"
				  style="color: #666;"
				  :size="$store.state.global.uiSize"
			  >
				  未收货
			  </el-tag>
			  <el-tag
				  v-if="data.receiptStatus === 1"
				  type="warning"
				  :size="$store.state.global.uiSize"
			  >
				  部分收货
			  </el-tag>
			  <el-tag
				  v-if="data.receiptStatus === 2"
				  type="success"
				  :size="$store.state.global.uiSize"
			  >
				  全部收货
			  </el-tag>
		  </el-descriptions-item>



        <el-descriptions-item
          label="业务员"
          label-align="right"
        >
          {{ data.fz_salesmanName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="业务部门"
          label-align="right"
        >
          {{ data.fz_departmentName }}
        </el-descriptions-item>


        <el-descriptions-item
          label="下单日期"
          label-align="right"
        >
          {{ data.creationDate }}
        </el-descriptions-item>

        <el-descriptions-item
          label="交货日期"
          label-align="right"
        >
          {{ data.deliveryDate }}
        </el-descriptions-item>
        <el-descriptions-item
          label="付款期限"
          label-align="right"
        >
          {{ data.paymentDate }}
        </el-descriptions-item>
        <!--开票状态-->
        <el-descriptions-item
          label="开票状态"
          label-align="right"
        >
          <el-tag
            v-if="data.ticketStatus === 0"
            style="color: #666;"
            :size="$store.state.global.uiSize"
          >
            未开票
          </el-tag>
          <el-tag
            v-if="data.ticketStatus === 1"
            type="warning"
            :size="$store.state.global.uiSize"
          >
            部分开票
          </el-tag>
          <el-tag
            v-if="data.ticketStatus === 2"
            type="success"
            :size="$store.state.global.uiSize"
          >
            全额开票
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item
          label="开票金额"
          label-align="right"
        >
          {{ data.ticketAmount }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="预付款"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.advanceAmount }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--          label="保证金"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.bondAmount }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
          label="单位抬头"
          label-align="right"
        >
          <el-link
            type="primary"
            @click="handleDetail(data, 'refCompanyDetail', 'companyId')"
          >
            {{ data.fz_companyName }}
          </el-link>
        </el-descriptions-item>
        <el-descriptions-item
            label="采购合同"
            label-align="right"
        >
          {{ data.contractNo }}
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="共享人"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.fz_shareUserName }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
          label="操作用户"
          label-align="right"
        >
          {{ data.fz_operateName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="操作时间"
          label-align="right"
        >
          {{ data.addTime }}
        </el-descriptions-item>
		  <el-descriptions-item
		  v-for="item in dataList"
		  :key="item.value"
		  :label="item.name"
		  label-align="right">
			  {{Array.isArray(data[item.value])?data[item.value].join(','):data[item.value]}}
	  </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="备注"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          {{ data.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :title="''"
        :size="$store.state.global.uiSize"
        border
        :column="1"
        class="column1"
      >
        <el-descriptions-item
          label="附件"
          label-class-name="noTopBorder"
          class-name="noTopBorder"
          :label-align="labelAlign"
        >
          <div class="mtf_5">
            <el-tag
              v-for="(file, index) in fileList"
              :key="index"
              class="dtFile"
              @click="previewFile(file)"
            >
              <a href="javascript:">{{ file.name }}</a>
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card
      shadow="never"
      class="mycard mt_10"
      header="业务信息"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >


<!--        <el-descriptions-item-->
<!--          label="终止原因"-->
<!--          label-align="right"-->
<!--        >-->
<!--          {{ data.reason }}-->
<!--        </el-descriptions-item>-->
        <!--退货状态-->
        <el-descriptions-item
          label="退货状态"
          label-align="right"
        >
          <el-tag
            v-if="data.refundStatus === 0"
            style="color: #666;"
            :size="$store.state.global.uiSize"
          >
            未退货
          </el-tag>
          <el-tag
            v-if="data.refundStatus === 1"
            type="warning"
            :size="$store.state.global.uiSize"
          >
            部分退货
          </el-tag>
          <el-tag
            v-if="data.refundStatus === 2"
            type="danger"
            :size="$store.state.global.uiSize"
          >
            全部退货
          </el-tag>
        </el-descriptions-item>


        <!--付款状态-->
        <el-descriptions-item
          label="付款状态"
          label-align="right"
        >
          <el-tag
            v-if="data.paymentStatus === 0"
            style="color: #666;"
            :size="$store.state.global.uiSize"
          >
            未付款
          </el-tag>
          <el-tag
            v-if="data.paymentStatus === 1"
            type="warning"
            :size="$store.state.global.uiSize"
          >
            部分付款
          </el-tag>
          <el-tag
            v-if="data.paymentStatus === 2"
            type="success"
            :size="$store.state.global.uiSize"
          >
            全部付款
          </el-tag>
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--          label="支付方式"-->
<!--          label-align="right"-->
<!--        >-->
<!--          <span v-if="data.freightPayment === 1">现金</span>-->
<!--          <span v-if="data.freightPayment === 2">转账</span>-->
<!--          <span v-if="data.freightPayment === 3">支票</span>-->
<!--          <span v-if="data.freightPayment === 4">其他</span>-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
          label="资金账户"
          label-align="right"
        >
          {{ data.fz_freightAccountName }}
        </el-descriptions-item>

        <el-descriptions-item
          label="合计金额"
          label-align="right"
        >
          {{ data.totalAmount }}
        </el-descriptions-item>

        <el-descriptions-item
          label="优惠金额"
          label-align="right"
        >
          {{ data.reductionAmount }}
        </el-descriptions-item>

        <el-descriptions-item
          label="实际金额"
          label-align="right"
        >
          {{ data.actualAmount }}
        </el-descriptions-item>
        <el-descriptions-item
            label="应付金额"
            label-align="right"
        >
          {{ data.copewithAmount }}
        </el-descriptions-item>
        <el-descriptions-item
          label="已付金额"
          label-align="right"
        >
          {{ data.amountPaid }}
        </el-descriptions-item>

        <el-descriptions-item
          label="未付金额"
          label-align="right"
        >
		</el-descriptions-item>
		<el-descriptions-item
          label="关联费用"
          label-align="right"
        >
          {{ data.relationAmount }}
        </el-descriptions-item>
        <el-descriptions-item
            label="退货金额"
            label-align="right"
        >
          {{ data.refundAmount }}
        </el-descriptions-item>

      </el-descriptions>
    </el-card>
    <!--    <el-card
      shadow="never"
      class="mycard mt_10"
      header="收货信息"
    >
      <el-descriptions
        :title="''"
        :column="3"
        :size="$store.state.global.uiSize"
        border
        class="column3"
      >
        <el-descriptions-item
          label="收货方式"
          label-align="right"
        >
          {{ data.collectMethod }}
        </el-descriptions-item>
        <el-descriptions-item
          label="合计运费"
          label-align="right"
        >
          {{ data.freightAmount }}
        </el-descriptions-item>
        <el-descriptions-item
          label="支付方式"
          label-align="right"
        >
          <span v-if="data.freightPayment === 1">无运费</span>
          <span v-if="data.freightPayment === 2">现金</span>
          <span v-if="data.freightPayment === 3">转账</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="资金账户"
          label-align="right"
        >
          {{ data.fz_freightAccountName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货方名称"
          label-align="right"
        >
          {{ data.shipperName }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货方电话"
          label-align="right"
        >
          {{ data.shipperTel }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发货单号"
          label-align="right"
        >
          {{ data.shipmentNo }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>-->
    <el-card
      shadow="never"
      class="mycard mt_10"
      header="物品明细"
    >
      <!--明细-->
      <scTable
        ref="tableSon"
        :api-obj="tableSonConfig.apiObj"
        :column="tableSonConfig.columns"
        :params="tableSonConfig.params"
        :height="'auto'"
        row-key="id"
        border
        remote-sort
      >
        <template #fz_goodsName="{ scope }">
          <el-link
            type="primary"
            @click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
          >
            {{ scope.row.fz_goodsName }}
          </el-link>
        </template>
		  <template #applyStatus="{ scope }">
			  <span v-if="scope.row.applyStatus == 1">单据新增</span>
			  <el-link
				  v-if="scope.row.applyStatus ==  2"
				  type="primary"
				  @click="applyList(scope.row)"
			  >
				  采购申请
			  </el-link>
			  <el-link
				  v-if="scope.row.applyStatus ==  3"
				  type="primary"
				  @click="handleList(scope.row)"
			  >
				  生产计划
			  </el-link>
			  <el-link
				  v-if="scope.row.applyStatus ==  4"
				  type="primary"
				  @click="contractList(scope.row)"
			  >
				  采购合同
			  </el-link>
		  </template>
        <template #receiptStatus="{ scope }">
          <el-tag
            v-if="scope.row.receiptStatus === 0"
            style="color: #666;"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            未收货
          </el-tag>
          <el-tag
            v-if="scope.row.receiptStatus === 1"
            type="warning"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            部分收货
          </el-tag>
          <el-tag
            v-if="scope.row.receiptStatus === 2"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            全部收货
          </el-tag>
        </template>
        <template #refundStatus="{ scope }">
          <el-tag
            v-if="scope.row.refundStatus === 0"
            class="myTag"
            style="color: #666;"
            :size="$store.state.global.uiSize"
          >
            未退货
          </el-tag>
          <el-tag
            v-if="scope.row.refundStatus === 1"
            type="warning"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            部分退货
          </el-tag>
          <el-tag
            v-if="scope.row.refundStatus === 2"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            全部退货
          </el-tag>
        </template>
        <template #ticketStatus="{ scope }">
          <el-tag
            v-if="scope.row.ticketStatus === 0"
            style="color: #666;"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            未开票
          </el-tag>
          <el-tag
            v-if="scope.row.ticketStatus === 1"
            type="warning"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            部分开票
          </el-tag>
          <el-tag
            v-if="scope.row.ticketStatus === 2"
            type="success"
            class="myTag"
            :size="$store.state.global.uiSize"
          >
            全额开票
          </el-tag>
        </template>

		  <!--自定义字段-->
		  <template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
			  {{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}
		  </template>
      </scTable>
    </el-card>
    <el-card
      shadow="never"
      class="mycard mt_10"
      header="付款期限"
    >
      <scTable
        ref="tablePayment"
        :data="data.paymentList"
        :column="tablePaymentConfig.columns"
        :height="'auto'"
        row-key="id"
        border
        remote-sort
      />
    </el-card>
    <el-card
      shadow="never"
      class="mycard mt_10"
      header="物流跟踪"
    />
    <GoodsDetail ref="refGoodsDetail" />
    <SupplierDetail ref="refSupplierDetail" />
    <CompanyDetail ref="refCompanyDetail" />
	<contractNumPop ref="refContractNumPop" />
	<applyNumPop ref="refApplyNumPop" />
	<suggestionPop ref="refSuggestionPop" />
  </sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from 'vue'
import GoodsDetail from "@/views/cfg/data/goods/detail";
import SupplierDetail from "@/views/cfg/data/supplier/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import applyNumPop from "@/views/erp/purchase/order/applyNumPop.vue";
import Export from "@/views/publicQuery/export/index.vue";
import suggestionPop from "@/views/erp/purchase/apply/suggestionPop.vue";
import CostRelation from "@/views/erp/purchase/order/costRelation.vue";
import contractNumPop from "@/views/erp/purchase/order/contractNumPop.vue";

export default defineComponent({
	components: {
		contractNumPop,
		CostRelation, suggestionPop,
		Export, applyNumPop,
		GoodsDetail,
		SupplierDetail,
		CompanyDetail,
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			dataList:[],
			sonDataList:[],
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: '60%',
      		type:"detail",
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '采购订单详情',
			//详情数据对象
			data: null,
			tablePaymentConfig: {
				apiObj: null,
				params: {},
				columns: [
					{
						label: '期限名称',
						prop: 'name',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '期限编码',
						prop: 'code',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '付款日期',
						prop: 'paymentDate',
						sortable: 'custom',
						width: 130,
					},
					{
						label: '付款金额[元]',
						prop: 'paymentAmount',
						sortable: 'custom',
						width: 140,
					},
					{
						label: '操作用户',
						prop: 'fz_operateName',
						sortable: 'custom',
						width: 110,
					},
					{
						label: '操作时间',
						prop: 'addTime',
						sortable: 'custom',
						width: 200,
					},
				],
			},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					// {
					// 	label: '材料名称',
					// 	prop: 'fz_goodsName',
					// 	sortable: 'custom',
					// 	fixed: 'left',
					// 	width: 160,
					// },
          {
						label: '物品名称',
						prop: 'fz_goodsName',
						sortable: 'custom',
						fixed: 'left',
						width: 180,
					},
					{
						label: '物品编码',
						prop: 'fz_goodsCode',
						width: 120,
						sortable: 'custom',
          },
					{
						label: '规格型号',
						prop: 'fz_goodsSpec',
						width: 120,
						sortable: 'custom',
					},
					{
						label: '收货仓库',
						prop: 'fz_depotName',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '数量',
						prop: "quantity",
						width: 110,
					},
					{
						label: '申请来源',
						prop: "applyStatus",
						width: 110,
					},
          {
						label: '主单位',
						prop: 'unit',
			      width: 110,
						sortable: 'custom',
					},
					{
						label: '未税单价',
						prop: 'price',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '税率(%)',
						prop: 'taxRate',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '含税单价',
						prop: 'taxPrice',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '税额(￥)',
						prop: 'taxAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '货款(￥)',
						prop: 'goodsAmount',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '小计(￥)',
						prop: 'subtotal',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '辅助单位',
						prop: 'assistUnit',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '换算数量',
						prop: 'assistQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '收货状态',
						prop: 'receiptStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已收数量',
						prop: 'receiptQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '预收数量',
						prop: 'preReceiptQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未收数量',
						prop: 'notReceiptQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '退货状态',
						prop: 'refundStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已退数量',
						prop: 'refundQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '预退数量',
						prop: 'preReturnQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未退数量',
						prop: 'notRefundQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '开票状态',
						prop: 'ticketStatus',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '已开票数',
						prop: 'ticketQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '预开票数',
						prop: 'preTicketQuantity',
						width: 110,
						sortable: 'custom',
					},
					{
						label: '未开票数',
						prop: 'notTicketQuantity',
						width: 110,
						sortable: 'custom',
         			 },
					{
						label: '备注',
						prop: 'abstracts',
						width: 180,
						sortable: 'custom',
					},
				],
			},
		})

		onMounted(() => {
			sonListMap()
			dataListMap()
		})

		// 获取产品明细自定义字段
		const sonListMap =async () =>{

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:2,isEnabled:1,menuCode:"erp_purchase_order",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.sonDataList = res.data.list
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: "custom",
				},)
			})
		}

		// 获取自定义字段
		const dataListMap =async () =>{
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode:1,isEnabled:1,menuCode:"erp_purchase_order",
			}
			let res = await proxy.$API.custom.getList.get(params)
			if (res.code === 200) {
				res.data.list.forEach(item => {
					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if(item.isRequired === 1){
						item.rules = {required: true, message: '必填'}
					}
				})
				state.dataList = res.data.list
			}
		}
		//显示弹窗
		const showDialog = async (data) => {
      console.log(data.id)
			state.data = data
			state.visible = true
			state.fileList = []
			if (data.id) {
				const res = await proxy.$API.purchaseOrder.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					//合同条款
					state.clauseList = res.data.clauseList
					//获取明细表数据
					getGoodsList(res.data.id)
					//组装文件列表
					getFileList(res.data.files)
				}
			}
			state.loading = false
		}

		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.params = {orderId: id}
				state.tableSonConfig.apiObj = proxy.$API.purchaseOrder.getOrderGoodsList
				//刷新表格
				proxy.$refs.tableSon.reload({orderId: id})
			}
		}

		//组装文件列表
		const getFileList = (data) => {
			let arr = []
			if (data) {
				arr = data.split(',')
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {}
						const iarr = item.split('/')
						obj['url'] = item
						obj['name'] = iarr[iarr.length - 1]
						state.fileList.push(obj)
					})
				}
			}
		}

		const previewFile = async (file) => {
			window.open(proxy.$CONFIG.API_URL + '/api/' + file.url)
			await proxy.$API.files.insertFilesDownload.post(file)
		}

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		//打开详情
		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}
		const handleList = async (row) => {
			console.log(row)
			proxy.$refs.refSuggestionPop.showDialog(row,'order')

		}
		// 查看采购申请来源List
		const applyList = async (row) => {
			// applyList
			console.log(row)
			if (row && row.id) {
				const res = await proxy.$API.purchaseOrder.showApply.get(row.id,row.goodsId)
				if(res.code === 200 && res.data){
					state.applyList = res.data
				}else if(res.code === 500){
					proxy.$baseMessage(res.msg,'error')
				}
			}
			proxy.$refs.refApplyNumPop.showDialog(state.applyList,row)
		}
		const contractList = async (row) => {
			if (row && row.id) {
				const res = await proxy.$API.purchaseOrder.contractDetail.get(row.contractGoodsId)
				if(res.code === 200 && res.data){
					state.applyList = res.data
				}else if(res.code === 500){
					proxy.$baseMessage(res.msg,'error')
				}
			}
			proxy.$refs.refContractNumPop.showDialog(state.applyList,row)
		}
		return {
			...toRefs(state),
			handleList,
			contractList,
			applyList,
			handleDetail,
			showDialog,
			previewFile,
			hideDialog,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

