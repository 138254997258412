<template>
	<div
		ref="scTableMain"
		v-loading="loading"
		element-loading-text="正在处理中，请稍等..."
		class="scTable"
		:style="{'height':_height, 'padding': _padding }"
	>
		<div class="scTable-table" :style="{height: _table_height}">
			<el-table
				v-bind="$attrs"
				:key="toggleIndex"
				ref="scTable"
				:data="tableData"
				:row-key="rowKey"
				:class="tableData.length === 0 ? 'nodataTable' : ''"
				:height="height==='auto'?null:'100%'"
				:max-height="height==='auto' ? maxHeight : null"
				:size="$store.state.global.uiSize"
				:border="config.border === 1"
				:stripe="config.stripe === 1"
				:summary-method="remoteSummary?remoteSummaryMethod:summaryMethod"
				:row-class-name="rowClassName"
				:row-style="rowStyle"
				:span-method="spanMethod"
				@current-change="currentChange"
				@sort-change="sortChange"
				@filter-change="filterChange"
				@select-all="selectAll"
			>
				<el-table-column
					v-if="radioCheck && !checkbox"
					width="60"
					align="center"
					fixed="left"
					label="选中"
					type=""
					:resizable="false"
				>
					<template #default="scope">
						<el-radio
							v-model="curRowKey"
							class="tableRadio"
							name="tableRadio"
							:size="$store.state.global.uiSize"
							:value="scope.row.id"
						/>
					</template>
				</el-table-column>
				<el-table-column
					v-if="checkbox"
					type="selection"
					align="center"
					fixed="left"
					reserve-selection
					:selectable="selectableFn"
				/>
				<el-table-column
					v-if="colindex"
					type="index"
					label="序号"
					:width="60"
					:fixed="'left'"
					align="center"
					:index="indexMethod || calcIndex"
				/>
				<!--<el-table-column
				  v-if="colindex"
				  label="序号"
				  width="60"
				  fixed="left"
			  >
				  <template #default="{ $index }">
					  {{ $index + 1 }}
				  </template>
			  </el-table-column>-->
				<template v-for="(item, index) in userColumn" :key="index">
					<template v-if="item.columns">
						<el-table-column
							v-if="!item.hide"
							:label="item.label"
							:align="item.align"
						>
							<template
								v-for="(col, _index) in item.columns"
								:key="_index"
							>
								<el-table-column
									v-if="!col.hide"
									:align="col.align"
									:column-key="col.prop"
									:label="col.label"
									:prop="col.prop"
									:width="col.width"
									:min-width="col.minWidth"
									:sortable="col.sortable"
									:fixed="col.fixed"
									:filters="col.filters"
									:filter-method="remoteFilter||!col.filters?null:filterHandler"
									:show-overflow-tooltip="col.showOverflowTooltip || true"
								>
									<template #default="scope">
										<slot
											v-if="!hideSlot"
											:name="col.prop"
											:scope="scope"
										>
											<span>{{ scope.row[col.prop] }}</span>
										</slot>
										<slot
											name="column"
											:scope="scope"
											:item="col"
										/>
									</template>
								</el-table-column>
							</template>
						</el-table-column>
					</template>
					<template v-else>
						<el-table-column
							v-if="!item.hide"
							:column-key="item.prop"
							:label="item.label"
							:prop="item.prop"
							:width="item.width"
							:min-width="item.minWidth"
							:sortable="item.sortable"
							:align="item.align"
							:fixed="tableData.length > 0?item.fixed:true"
							:filters="item.filters"
							:filter-method="remoteFilter||!item.filters?null:filterHandler"
							:show-overflow-tooltip="item.showOverflowTooltip !== false"
						>
							<template #default="scope">
								<slot
									v-if="!hideSlot"
									:name="item.prop"
									:scope="scope"
								>
									<span>{{ scope.row[item.prop] }}</span>
								</slot>
								<slot
									name="column"
									:scope="scope"
									:item="item"
								/>
							</template>
						</el-table-column>
					</template>
				</template>
				<slot/>
				<!--<el-table-column min-width="1" />-->
				<template #empty>
					<sy-empty/>
				</template>
			</el-table>
		</div>
		<div
			v-if="!hidePagination || !hideDo"
			class="scTable-page"
		>
			<div class="scTable-pagination">
				<el-pagination
					v-if="!hidePagination"
					v-model:currentPage="currentPage"
					background
					:size="$store.state.global.uiSize"
					:layout="paginationLayout"
					:total="total"
					:page-size="scPageSize"
					:page-sizes="pageSizes"
					@current-change="paginationChange"
					@update:page-size="pageSizeChange"
				/>
			</div>
			<div
				v-if="!hideDo"
				class="scTable-do"
			>
				<!-- 刷新按钮-->
				<el-button
					v-if="!hideRefresh"
					class="tbsetbtn"
					icon="el-icon-refresh"
					circle
					:size="$store.state.global.uiSize"
					@click="refresh"
				/>
				<!-- 列设置-->
				<el-popover
					v-if="!hideColumnSetting"
					:visible="columnVisible"
					title="列设置"
					:width="650"
					trigger="click"
					:hide-after="0"
					@show="customColumnShow=true"
					@after-leave="customColumnShow=false"
				>
					<el-button
						class="closePopoverBtn"
						:size="$store.state.global.uiSize"
						@click="columnVisible = false"
					>
						<el-icon>
							<el-icon-close/>
						</el-icon>
					</el-button>
					<template #reference>
						<el-button
							class="tbsetbtn"
							icon="el-icon-set-up"
							circle
							:size="$store.state.global.uiSize"
							@click="columnVisible = !columnVisible"
						/>
					</template>
					<columnSetting
						v-if="customColumnShow"
						:ref="'columnSetting'+tableName"
						:column="userColumn"
						@user-change="columnSettingChange"
						@save="columnSettingSave"
						@back="columnSettingBack"
					/>
				</el-popover>
				<!-- 表格设置-->
				<!--<el-popover
						  v-if="!hideSetting"
						  placement="top"
						  title="表格设置"
						  :width="400"
						  trigger="click"
						  :hide-after="0"
						>
						  <template #reference>
							<el-button
							  class="tbsetbtn"
							  icon="el-icon-setting"
							  circle
							/>
						  </template>
						  <el-form
							label-width="80px"
							label-position="left"
						  >
							<el-form-item label="表格尺寸">
							  <el-radio-group
								v-model="config.size"
								size="small"
								@change="configSizeChange"
							  >
								<el-radio-button label="large">
								  大
								</el-radio-button>
								<el-radio-button label="default">
								  中
								</el-radio-button>
								<el-radio-button label="small">
								  小
								</el-radio-button>
							  </el-radio-group>
							</el-form-item>
							<el-form-item label="样式">
							  <el-checkbox
								v-model="config.border"
								label="纵向边框"
								:true-label="1"
								:false-label="0"
								@change="setTableConfig"
							  />
							  <el-checkbox
								v-model="config.stripe"
								label="斑马纹"
								:true-label="1"
								:false-label="0"
								@change="setTableConfig"
							  />
							</el-form-item>
						  </el-form>
						</el-popover>-->
			</div>
		</div>
	</div>
</template>

<script>
//表格配置参数
import config from "@/config/table";
//列设置组件
import columnSetting from "./columnSetting";
import {handleCutZero} from "@/utils/validate";
import NullImage from "/public/img/null.png";

export default {
	name: "ScTable",
	components: {
		columnSetting,
	},
	props: {
		tableConfig: {
			type: Object, default: () => {
			}
		},
		//表格名称，可不关注
		tableName: {type: String, default: ""},
		//数据源对象，即获取数据的接口对象
		apiObj: {
			type: Object, default: () => {
			}
		},
		//查询的默认参数
		params: {type: Object, default: () => ({})},
		//静态数据
		data: {type: Array, default: () => []},
		//表格高度
		height: {type: [String, Number], default: "100%"},
		//最大高度
		maxHeight: {type: [String, Number], default: 300},
		//表格组件大小
		size: {type: String, default: "small"},
		//是否显示边框
		border: {type: Boolean, default: false},
		indexNofixed: {type: Boolean, default: false},
		indexWidthAuto: {type: Boolean, default: false},
		//表格行是否以斑马线显示
		stripe: {type: Boolean, default: true},
		//每页显示条数
		pageSize: {type: Number, default: config.pageSize},
		//分页条数切换选择
		pageSizes: {type: Array, default: config.pageSizes},
		//表格行的唯一标识
		rowKey: {type: String, default: ""},
		//合计行方法
		summaryMethod: {type: Function, default: null},
		//合并行或列的计算方法
		spanMethod: {type: Function, default: null},

		// tableRowClassNameMethod: { type: Function, default: null },
		rowClassName: {type: Function, default: null},
		//表格行样式
		rowStyle: {type: [Object, Function], default: null},
		//表格列数组
		column: {
			type: Object, default: () => {
			}
		},
		//是否开启远程排序
		remoteSort: {type: Boolean, default: false},
		//是否开启远程筛选
		remoteFilter: {type: Boolean, default: false},
		//是否开启远程合计
		remoteSummary: {type: Boolean, default: false},
		//合计字段
		defaultSummary: {type: Array, default: null},
		//隐藏分页
		hidePagination: {type: Boolean, default: false},
		//表格底部操作栏是否显示
		hideDo: {type: Boolean, default: false},
		//是否隐藏刷新按钮
		hideRefresh: {type: Boolean, default: false},
		//是否隐藏设置按钮
		hideSetting: {type: Boolean, default: false},
		//隐藏列设置
		hideColumnSetting: {type: Boolean, default: false},
		//分页样式
		paginationLayout: {type: String, default: config.paginationLayout},
		//是否显示复选框列
		checkbox: {type: Boolean, default: false},
		//复选框禁用配置
		disCheck: {type: Object, default: null},
		//是否显示序号列
		colindex: {type: Boolean, default: true},
		//序号列计算方法
		indexMethod: {type: Function, default: null},
		//是否显示单选
		radioCheck: {type: Boolean, default: false},
		//表格边距
		padding: {type: Number, default: 0},
		hideSlot: {type: Boolean, default: false},
		originSelAll: {type: Boolean, default: false},
		customEmpty: {type: String, default: ""},
		overAllHeight: {type: String, default: ""},
		refQueryForm: {type: Object, default: null},
	},
	emits: ["dataChange", "sortChange", "selectAll", "dataLoad", "res-error"],
	data() {
		return {
			NullImage,
			//每页显示条数
			scPageSize: this.pageSize,
			isActivat: true,
			emptyText: "暂无数据",
			toggleIndex: 0,
			curRowKey: "",
			tableData: [],
			total: 0,
			currentPage: 1,
			prop: null,
			order: null,
			loading: false,
			tableHeight: "100%",
			tableParams: this.params,
			userColumn: [],
			customColumnShow: false,
			columnVisible: false,
			summary: {},
			config: {
				size: this.size,
				border: this.border === true ? 1 : 0,
				stripe: this.stripe === true ? 1 : 0
			},
			//排序参数
			index: 0
		};
	},
	computed: {
		_height() {
			return Number(this.height) ? Number(this.height) + "px" : this.height;
		},
		_table_height() {
      const uiSize = this.$store.state.global.uiSize
      if(uiSize === 'small'){
        return this.hidePagination && this.hideDo ? "100%" : "calc(100% - 28px)";
      }else if(uiSize === 'default'){
        return this.hidePagination && this.hideDo ? "100%" : "calc(100% - 42px)";
      }else if(uiSize === 'large'){
        return this.hidePagination && this.hideDo ? "100%" : "calc(100% - 42px)";
      }

		},
		_padding() {
			return Number(this.padding) ? Number(this.padding) + "px" : this.padding;
		}
	},
	watch: {
		//监听从props里拿到值了
		data() {
			this.tableData = this.data || [];
			this.total = this.tableData.length;
			this.$emit("dataLoad", this.tableData);
		},
		apiObj() {
			this.tableParams = this.params;
			this.refresh();
		}
	},
	mounted() {
		//判断是否开启自定义列
		if (this.column) {
			this.getCustomColumn();
		} else {
			this.userColumn = this.column;
		}
		//判断是否静态数据
		if (this.apiObj) {
			this.getData();
		} else if (this.data) {
			this.tableData = this.data;
			this.total = this.tableData.length;
		}
		//读取表格配置
		this.getTableConfig();
	},
	//组件激活
	activated() {
		if (!this.isActivat) {
			this.$refs.scTable.doLayout();
		}
	},
	//组件失活
	deactivated() {
		this.isActivat = false;
	},
	methods: {
		//获取列
		async getCustomColumn() {
			const userColumn = await config.columnSettingGet(this.tableName, this.column);
			this.userColumn = userColumn;
		},
		//获取数据
		async getData() {
			this.loading = true;
			//请求参数
			let reqData = {
				[config.request.page]: this.currentPage,
				[config.request.pageSize]: this.scPageSize,
				[config.request.prop]: this.prop,
				[config.request.order]: this.order
			};
			if (this.hidePagination) {
				delete reqData[config.request.page];
				delete reqData[config.request.pageSize];
			}
			Object.assign(reqData, this.tableParams);
			try {
				//调用数据接口
				const res = await this.apiObj.get(reqData);
				try {
					const response = config.parseData(res);
					if (response.code !== config.successCode) {
						this.loading = false;
						this.emptyText = response.msg;
					} else {
						this.emptyText = "暂无数据";
						if (this.hidePagination) {
							if (response.rows) {
								this.tableData = response.rows || [];
							} else {
								this.tableData = response.data || [];
							}
						} else {
							this.tableData = response.rows || [];
						}
						this.total = response.total || 0;
						/*this.summary = { freightAmount: 123 };*/
						this.summary = response.summary || {};
						this.loading = false;
					}
					this.$nextTick(() => {
						if (this.$refs.scTable) {
							this.$refs.scTable.setScrollTop(0);
						}
						if (this.tableData.length === 0) {
							// document.querySelector('.el-table__body').style.height = '1px'
						}
					});
					this.$emit("dataChange", res, this.tableData);
				} catch (error) {
					this.loading = false;
					if (this.customEmpty) {
						this.emptyText = this.customEmpty;
					} else {
						this.emptyText = "数据格式错误";
					}
					return false;
				}
			} catch (error) {
				this.loading = false;
				this.emptyText = error.statusText;
				return false;
			}
		},
		//序号
		calcIndex(index) {
			// 当前页数 - 1 * 每页数据条数 + 1
			const page = this.currentPage; // 当前页码
			const pageSize = this.scPageSize; // 每页条数
			return index + 1 + (page - 1) * pageSize;
		},
		//分页点击
		paginationChange() {
			this.getData();
		},
		//条数变化
		pageSizeChange(size) {
			this.scPageSize = size;
			this.getData();
		},
		//刷新数据
		refresh() {
			this.$refs.scTable.clearSelection();
			this.getData();
		},
		//更新数据 合并上一次params
		upData(params, page = 1) {
			this.currentPage = page;
			this.$refs.scTable.clearSelection();
			Object.assign(this.tableParams, params || {});
			this.getData();
		},
		//重载数据 替换params
		reload(params, pageNum = 1) {
			this.currentPage = pageNum;
			this.tableParams = params || this.params;
			this.$refs.scTable.clearSelection();
			this.$refs.scTable.clearSort();
			this.$refs.scTable.clearFilter();
			this.getData();
		},
		//自定义变化事件
		columnSettingChange(userColumn) {
			this.userColumn = userColumn;
			this.toggleIndex += 1;
			this.$nextTick(() => {
				if (this.tableData.length === 0) {
					document.querySelector(".el-table__body").style.height = "1px";
				}
			});
		},
		//自定义列保存
		async columnSettingSave(userColumn) {
			if (this.tableName) {
				this.$refs["columnSetting" + this.tableName].isSave = true;
				try {
					await config.columnSettingSave(this.tableName, userColumn);
				} catch (error) {
					this.$message.error("保存失败");
					this.$refs["columnSetting" + this.tableName].isSave = false;
				}
				this.$message.success("保存成功");
				this.$refs["columnSetting" + this.tableName].isSave = false;
			}
		},
		//自定义列重置
		async columnSettingBack() {
			this.$refs["columnSetting" + this.tableName].isSave = true;
			try {
				const column = await config.columnSettingReset(this.tableName, this.column);
				this.userColumn = column;
				this.$refs["columnSetting" + this.tableName].list = JSON.parse(JSON.stringify(this.userColumn || []));
			} catch (error) {
				this.$message.error("重置失败");
				this.$refs["columnSetting" + this.tableName].isSave = false;
			}
			this.$refs["columnSetting" + this.tableName].isSave = false;
		},
		//排序事件
		sortChange(obj) {
			if (!this.remoteSort) {
				return false;
			}
			if (obj.column && obj.prop && obj.order) {
				this.prop = obj.prop;
				if (obj.order == "ascending") {
					this.order = "asc";
				} else if (obj.order == "descending") {
					this.order = "desc";
				} else {
					this.order = null;
				}
			} else {
				this.prop = null;
				this.order = null;
			}
			if (this.refQueryForm && this.refQueryForm.queryForm) {
				this.tableParams = Object.assign(this.tableParams, this.refQueryForm.queryForm);
			}
			// //库存汇总排序
			// let res = {
			// 	order: null,
			// 	prop: obj.prop
			// }
			// if(this.index === 0){
			// 	res.order = 'ascending'
			// 	this.index = this.index + 1
			// }else if(this.index === 1){
			// 	res.order = 'descending'
			// 	this.index = this.index + 1
			// }else if(this.index === 2) {
			// 	res.prop = null
			// 	this.index = this.index - 2
			// }
			// this.$emit('sortChange', res,obj)
			this.$emit("sortChange", obj);
			if (this.apiObj) {
				this.getData();
			}
		},
		selectAll(selections) {
			if (this.originSelAll) {
				this.$emit("selectAll", selections);
			} else {
				this.$emit("selectAll", this.tableData);
			}
		},
		//本地过滤
		filterHandler(value, row, column) {
			console.log(value);
			const property = column.property;
			return row[property] === value;
		},
		//过滤事件
		filterChange(filters) {
			if (!this.remoteFilter) {
				return false;
			}
			Object.keys(filters).forEach(key => {
				filters[key] = filters[key].join(",");
			});
			this.upData(filters);
		},
		//远程合计行处理
		remoteSummaryMethod(param) {
			if (!param) {
				return;
			}
			let sums = [];
			if (this.summaryMethod && this.defaultSummary !== null) {
				const fields = this.defaultSummary;
				sums = this.summaryMethod(param, fields);
				return sums;
			}
			if (this.defaultSummary !== null) {
				const {columns, data} = param;
				const fields = this.defaultSummary;
				if (columns.length < 1 || fields.length < 1) return;
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = "合计";
						return;
					}
					const values = data.map((item) => Number(item[column.property]));
					if (!values.every((value) => isNaN(value))) {
						fields.forEach((field) => {
							if (column.property === field) {
								sums[index] = `${values.reduce((prev, curr) => {
									const value = Number(curr);
									if (!isNaN(value)) {
										return prev + curr;
									} else {
										return prev;
									}
								}, 0)}`;
								sums[index] = handleCutZero((sums[index] - 0).toFixed(4));
							}
						});
					} else {
						sums[index] = "";
					}
				});
			} else {
				const {columns, data} = param;
				if (columns.length < 1) return;
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = "合计";
						return;
					}
					const values = data.map((item) => Number(item[column.property]));
					if (!values.every((value) => isNaN(value))) {
						sums[index] = `${values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0)}`;
						sums[index] = handleCutZero((sums[index] - 0).toFixed(4));
					} else {
						sums[index] = "";
					}
				});
			}

			return sums;
		},
		//数据拆分
		getDigit(number, index) {
			if (!number) return;
			console.log(this.tableConfig);
			const start = this.tableConfig.merge[0].start;
			const end = this.tableConfig.merge[0].end;
			const length = end - start;
			//右侧合计
			if (index > length) {
				index = index - (length + 1);
			}
			let numberString = number.toString().replace(".", ""); // 将数字转换为字符串，并去掉小数点
			let digitArray = numberString.split(""); // 将字符串转换为数组

			while (digitArray.length <= length) {
				digitArray.unshift(""); // 在数组左边填充空数据
			}
			return digitArray[index];
		},
		//表格尺寸大小切换
		configSizeChange(val) {
			this.$refs.scTable.doLayout();
			this.setTableConfig();
		},
		//读取表格配置
		async getTableConfig() {
			if (this.tableName) {
				const res = await this.$API.systemTable.detail.get(this.tableName);
				if (res.code === 200) {
					this.config.size = res.data.size;
					this.config.stripe = res.data.stripe;
					this.config.border = res.data.border;
				}
			}
		},
		//保存表格设置
		async setTableConfig() {
			if (this.tableName) {
				const res = await this.$API.systemTable.insert.post({
					typeCode: this.tableName,
					size: this.config.size,
					border: this.config.border,
					stripe: this.config.stripe,
				});
				/*if(res.code === 200){
					this.$baseMessage(res.msg,'success')
				}else{
					this.$baseMessage(res.msg,'error')
				}*/
			}
		},
		//插入行 unshiftRow
		unshiftRow(row) {
			this.tableData.unshift(row);
		},
		//插入行 pushRow
		pushRow(row) {
			this.tableData.push(row);
		},
		//根据key覆盖数据
		updateKey(row, rowKey = this.rowKey) {
			this.tableData.filter(item => item[rowKey] === row[rowKey]).forEach(item => {
				Object.assign(item, row);
			});
		},
		//根据index覆盖数据
		updateIndex(row, index) {
			Object.assign(this.tableData[index], row);
		},
		//根据index删除
		removeIndex(index) {
			this.tableData.splice(index, 1);
		},
		//根据index批量删除
		removeIndexes(indexes = []) {
			indexes.forEach(index => {
				this.tableData.splice(index, 1);
			});
		},
		//根据key删除
		removeKey(key, rowKey = this.rowKey) {
			this.tableData.splice(this.tableData.findIndex(item => item[rowKey] === key), 1);
		},
		//根据keys批量删除
		removeKeys(keys = [], rowKey = this.rowKey) {
			keys.forEach(key => {
				this.tableData.splice(this.tableData.findIndex(item => item[rowKey] === key), 1);
			});
		},
		//原生方法转发，清楚已选数据
		clearSelection() {
			this.$refs.scTable.clearSelection();
		},
		//设置行选中
		toggleRowSelection(row, selected) {
			this.$refs.scTable.toggleRowSelection(row, selected);
		},
		getSelectionRows() {
			return this.$refs.scTable.getSelectionRows();
		},
		//设置所有行选中
		toggleAllSelection() {
			this.$refs.scTable.toggleAllSelection();
		},
		//设置所有行展开
		toggleRowExpansion(row, expanded) {
			this.$refs.scTable.toggleRowExpansion(row, expanded);
		},
		//设置当前行
		setCurrentRow(row) {
			this.$refs.scTable.setCurrentRow(row);
		},
		currentChange(row) {
			if (row) {
				this.curRowKey = row.id;
			} else {
				this.curRowKey = "";
			}
		},
		//清除排序
		clearSort() {
			this.$refs.scTable.clearSort();
		},
		//清楚所有筛选条件
		clearFilter(columnKey) {
			this.$refs.scTable.clearFilter(columnKey);
		},
		showColumnSetting() {
			this.columnVisible = !this.columnVisible;
		},
		//重置表格
		doLayout() {
			this.$refs.scTable.doLayout();
		},
		//排序方法
		sort(prop, order) {
			this.$refs.scTable.sort(prop, order);
		},
		//复选框是否可勾选
		selectableFn(row) {
			if (this.disCheck) {
				//根据传入prop判断
				return row[this.disCheck.prop] ? true : false;
			} else {
				return true;
			}
		}
	}
};
</script>
<style scoped lang="scss">
.table-container {
	overflow-x: scroll;
	max-width: 100%;
}

:deep(.el-table__empty-block) {
	position: absolute;
}

.nodataTable:deep(.el-table__header-wrapper tr th.el-table-fixed-column--left) {
	position: relative !important;
	left: auto !important;
}

.nodataTable:deep(.el-table__body) {
	height: 1px !important;
}

:deep(.el-table .el-scrollbar__view) {
	padding-bottom: 0 !important;
}
:deep(.el-table.has-footer.el-table--fluid-height tr:last-child td.el-table__cell, .el-table.has-footer.el-table--scrollable-y tr:last-child td.el-table__cell){
	border-bottom: 1px solid #e7e7f1;
}
</style>
