import sysConfig from '@/config'
import globalSocket from "@/utils/globalSocket"

let socket = null

// 全局变量，用于保存所有 WebSocket 实例
const webSockets = {}

// 创建 WebSocket 连接并存储在全局变量中
export function connectSocket(uid) {
	if (!uid) {
		return false
	}
	if ('WebSocket' in window) {
		// 如果已经存在对应用户的 WebSocket 实例，则不再创建新实例，直接返回
		if (webSockets[uid]) {
			return webSockets[uid]
		}
		socket = new WebSocket(sysConfig.WEBSOCKET_URL + uid)
		/*建立连接*/
		socket.onopen = evt => {
			console.log("webSocket连接成功")
		};
		/*连接关闭*/
		socket.onclose = evt => {
			console.log("webSocket连接关闭")
			// 连接关闭时从全局变量中删除该 WebSocket 实例
			delete webSockets[uid]
		};
		/*接收服务器推送消息*/
		// socket.onmessage = (evt) => {
		// 	console.log(evt.data)
		// };
		/*连接发生错误时*/
		socket.onerror = (evt, e) => {
		}
		// 将 WebSocket 实例存储在全局变量中
		webSockets[uid] = socket
		// 返回 WebSocket 实例
		return socket
	} else {
		return false
	}
}

// 获取指定用户的 WebSocket 实例
export function getWebSocket(uid) {
	return webSockets[uid]
}

// 设置全局 WebSocket 实例
globalSocket.setWs(webSockets)
