<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
    :type="type"
		:loading="loading"
		:bef-close="false"
		:width="width"
		height="350px"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<!--title:标题  column:列数 size: 尺寸大小 border:是否显示边框-->
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<el-descriptions-item
					label="工作组名称"
					label-align="right"
				>
					{{ data.name }}
				</el-descriptions-item>
				<!--label: 标题 -->
				<el-descriptions-item
					label="工作组编号"
					label-align="right"
				>
					{{ data.number }}
				</el-descriptions-item>


			</el-descriptions>
		</el-card>
		<el-card
			header="详细信息"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:data="data.sonList"
				:column="tableSonConfig.columns"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
				hide-setting
				hide-pagination
			/>
		</el-card>
<!--		<template #footer>-->
<!--			<div class="text-center">-->
<!--				<el-button-->
<!--					:size="$store.state.global.uiSize"-->
<!--					@click="visible = false"-->
<!--				>-->
<!--					关 闭-->
<!--				</el-button>-->
<!--			</div>-->
<!--		</template>-->
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance
} from 'vue'

export default defineComponent({

	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance()
		const state = reactive({
			//条款数据
			clauseList: [],
      type:"detail",
			//弹窗宽度
			width: '60%',
			//高度
			height: '500px',
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: 'right',
			fileList: [],
			//尺寸大小
			size: 'small',
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: '工作组详情',
			//详情数据对象
			data: null,
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				columns: [
					{
						label: '用户姓名',
						prop: 'workerName',
						sortable: 'custom',
						fixed: 'left',
						width: 120,

					},
					{
						label: '工号',
						prop: 'code',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '部门',
						prop: 'departmentName',
						sortable: 'custom',
						width: 120,
					},
					{
						label: '联系电话',
						prop: 'phone',
						sortable: 'custom',
						width: 120,
					},

				],
			},
		})

		//显示弹窗
		const showDialog = async (data) => {
			state.data = data
			state.visible = true
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.produceCraftWorkgroup.detail.get(data.id)
				if (res.code === 200) {
					state.data = res.data
					//获取明细表数据
					// getUserList(res.data.id)
				}
			}
			state.loading = false
		}

		// const getUserList = (id) => {
		// 	if (id) {
		// 		state.tableSonConfig.apiObj = proxy.$API.produceCraftWorkgroup.selectWorkGroupDetail
		// 		state.tableSonConfig.params = {workGroupId: id}
		// 		proxy.$refs.tableSon.reload({workGroupId: id})
		// 	}
		// }

		//组装文件列表

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false
		}

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row)
			if (field) {
				params.id = row[field]
			}
			proxy.$refs[ref].showDialog(params)
		}


		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
		}
	},
})
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
</style>

