import config from "@/config"
import http from "@/utils/request"

export default {
	priceTotal: {
		url: `${config.API_URL}/fms/report/statistics/priceTotal`,
		name: "汇总",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
	getDataByMenuCode: {
		url: `${config.API_URL}/fms/report/statistics/getDataByMenuCode`,
		name: "根据菜单编码查询单据",
		get: async function (data = {}) {
			return await http.post(this.url, data);
		}
	},
}
