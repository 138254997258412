<template>
	<!--v-model：控制弹窗显示隐藏  title:标题 loading:加载动画 width:弹窗宽度，数字或百分比，默认'50%'	-->
	<sc-dialog
		ref="refScDialog"
		v-model="visible"
		:title="title"
		:loading="loading"
		:type="type"
		:bef-close="false"
		:width="width"
		:footer="false"
	>
		<el-card
			header="基本信息"
			shadow="never"
			class="mycard"
		>
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<el-descriptions-item
					label="合同单号"
					label-align="right"
				>
					{{ data.contractNo }}
				</el-descriptions-item>
				<el-descriptions-item
					label="客户"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refCustomerDetail', 'customerId')"
					>
						{{ data.fz_customerName }}
					</el-link>
				</el-descriptions-item>

				<el-descriptions-item
					label="合同类型"
					label-align="right"
				>
					<span v-if="data.type === 1">年度框架</span>
					<span v-if="data.type === 0">单次销售</span>
				</el-descriptions-item>

				<el-descriptions-item
					label="合同状态"
					label-align="right"
				>
					<el-tag
						v-if="data.contractStatus === 0"
						type="info"
						:size="$store.state.global.uiSize"
					>
						未签约
					</el-tag>
					<el-tag
						v-if="data.contractStatus === 1"
						type="warning"
						:size="$store.state.global.uiSize"
					>
						执行中
					</el-tag>
					<el-tag
						v-if="data.contractStatus === 2"
						type="success"
						:size="$store.state.global.uiSize"
					>
						已结束
					</el-tag>
					<el-tag
						v-if="data.contractStatus === 3"
						type="danger"
						:size="$store.state.global.uiSize"
					>
						已作废
					</el-tag>
				</el-descriptions-item>

				<el-descriptions-item
					label="开始日期"
					label-align="right"
				>
					{{ data.startDate }}
				</el-descriptions-item>

				<el-descriptions-item
					label="结束日期"
					label-align="right"
				>
					{{ data.endDate }}
				</el-descriptions-item>

				<el-descriptions-item
					label="联系人"
					label-align="right"
				>
					{{ data.contacts }}
				</el-descriptions-item>
<!--				<el-descriptions-item
					label="联系手机"
					label-align="right"
				>
					{{ data.phone }}
				</el-descriptions-item>-->
				<el-descriptions-item
					label="联系电话"
					label-align="right"
				>
					{{ data.tel }}
				</el-descriptions-item>

				<el-descriptions-item
					label="发票类型"
					label-align="right"
				>
					{{ data.fz_typeName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="交货日期"
					label-align="right"
				>
					{{ data.deliveryDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="业务员"
					label-align="right"
				>
					{{ data.fz_salesmanName }}
				</el-descriptions-item>

				<el-descriptions-item
					label="业务部门"
					label-align="right"
				>
					{{ data.fz_departmentName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="签约日期"
					label-align="right"
				>
					{{ data.creationDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="收款期限"
					label-align="right"
				>
					{{ data.collectDate }}
				</el-descriptions-item>
				<el-descriptions-item
					label="合计金额"
					label-align="right"
				>
					{{ data.collectAmount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="优惠金额"
					label-align="right"
				>
					{{ data.discount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="实际金额"
					label-align="right"
				>
					{{ data.actualAmount }}
				</el-descriptions-item>
				<el-descriptions-item
					label="单位抬头"
					label-align="right"
				>
					<el-link
						type="primary"
						@click="handleDetail(data, 'refCompanyDetail', 'companyId')"
					>
						{{ data.fz_companyName }}
					</el-link>
				</el-descriptions-item>
				<el-descriptions-item
					label="操作用户"
					label-align="right"
				>
					{{ data.fz_operateName }}
				</el-descriptions-item>
				<el-descriptions-item
					label="操作时间"
					label-align="right"
				>
					{{ data.addTime }}
				</el-descriptions-item>
				<el-descriptions-item
					v-for="item in dataList"
					:key="item.value"
					:label="item.name"
					label-align="right">
					{{Array.isArray(data[item.value])?data[item.value].join(','):data[item.value]}}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="备注"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					{{ data.remark }}
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions
				:title="''"
				:size="$store.state.global.uiSize"
				border
				:column="1"
				class="column1"
			>
				<el-descriptions-item
					label="附件"
					label-class-name="noTopBorder"
					class-name="noTopBorder"
					:label-align="labelAlign"
				>
					<div class="mtf_5">
						<el-tag
							v-for="(file, index) in fileList"
							:key="index"
							class="dtFile"
							@click="previewFile(file)"
						>
							<a href="javascript:">{{ file.name }}</a>
						</el-tag>
					</div>
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="收货信息"
			shadow="never"
			class="mycard"
		>
			<el-descriptions
				:title="''"
				:column="3"
				:size="$store.state.global.uiSize"
				border
				class="column3"
			>
				<el-descriptions-item
					label="收货人"
					label-align="right"
				>
					{{ data.receiver }}
				</el-descriptions-item>
				<el-descriptions-item
					label="联系电话"
					label-align="right"
				>
					{{ data.phone }}
				</el-descriptions-item>
				<el-descriptions-item
					label="收货地址"
					label-align="right"
				>
					{{ data.shipAddress }}
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card
			header="产品明细"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableSon"
				:api-obj="tableSonConfig.apiObj"
				:column="tableSonConfig.columns"
				:params="tableSonConfig.params"
				:height="'auto'"
				row-key="id"
				border
				remote-sort
			>
				<template #fz_goodsName="{ scope }">
					<el-link
						type="primary"
						@click="handleDetail(scope.row, 'refGoodsDetail', 'goodsId')"
					>
						{{ scope.row.fz_goodsName }}
					</el-link>
				</template>
				<!--自定义字段-->
				<template v-for="item in sonDataList" :key="item.value" #[item.value]="{ scope }">
					{{Array.isArray(scope.row[item.value])?scope.row[item.value].join(','):scope.row[item.value]}}
				</template>
			</scTable>
		</el-card>
		<el-card
			header="收款期限"
			shadow="never"
			class="mycard mt_10"
		>
			<scTable
				ref="tableCollect"
				:data="data.collectList"
				:column="tableCollectConfig.columns"
				:height="'auto'"
				row-key="id"
				border
				hide-pagination
			/>
		</el-card>
		<el-card
			header="合同条款"
			shadow="never"
			class="mycard mt_10"
		>
			<!--空数据-->
			<sy-empty v-if="clauseList.length === 0"/>
			<el-card
				v-for="(item, index) in clauseList"
				:key="index"
				shadow="never"
				:header="(index+1) + '.' + item.theme"
				class="mycard mb_10"
			>
				<div
					class="htmlCont"
					v-html="item.content"
				/>
			</el-card>
		</el-card>
		<CustomerDetail ref="refCustomerDetail"/>
		<CompanyDetail ref="refCompanyDetail"/>
		<GoodsDetail ref="refGoodsDetail"/>
	</sc-dialog>
</template>

<script>
import {
	defineComponent,
	toRefs,
	reactive,
	getCurrentInstance, onMounted
} from "vue";
import CustomerDetail from "@/views/cfg/data/customer/detail";
import CompanyDetail from "@/views/cfg/organize/company/detail";
import GoodsDetail from "@/views/cfg/data/goods/detail";

export default defineComponent({
	components: {
		CustomerDetail,
		CompanyDetail,
		GoodsDetail
	},
	setup() {
		//获取当前实例对象
		const {proxy} = getCurrentInstance();
		const state = reactive({
			dataList: [],
			sonDataList: [],
			//条款数据
			clauseList: [],
			//弹窗宽度
			width: "60%",
			type: "detail",
			//高度
			height: "500px",
			//加载动画
			loading: true,
			//标题对齐方式 'left' 'right' 'center'
			labelAlign: "right",
			fileList: [],
			//尺寸大小
			size: "small",
			//控制弹窗显示隐藏
			visible: false,
			//弹窗标题
			title: "销售合同详情",
			//详情数据对象
			data: {},
			tableSonConfig: {
				//数据表格数据源对象,子表先不配置接口，等主表加载完后设置
				apiObj: null,
				params: {},
				//数据表格列配置
				columns: [
					{
						label: "产品名称",
						prop: "fz_goodsName",
						sortable: "custom",
						fixed: "left",
						width: 180,
					},
					{
						label: "产品编码",
						prop: "fz_goodsCode",
						sortable: "custom",
						width: 120,
					},
					{
						label: "规格型号",
						prop: "fz_goodsSpec",
						sortable: "custom",
						width: 120,
					},
					{
						label: "数量",
						prop: "quantity",
						width: 110,
						sortable: "custom",
					},
					{
						label: "计量单位",
						prop: "unit",
						width: 110,
						sortable: "custom",
					},
					{
						label: "未税单价",
						prop: "price",
						width: 110,
						sortable: "custom",
					},
					{
						label: "税率(%)",
						prop: "taxRate",
						width: 110,
						sortable: "custom",
					},
					{
						label: "含税单价",
						prop: "taxPrice",
						width: 110,
						sortable: "custom",
					},
					{
						label: "税额(￥)",
						prop: "taxAmount",
						width: 110,
						sortable: "custom",
					},
					{
						label: "货款(￥)",
						prop: "goodsAmount",
						width: 110,
						sortable: "custom",
					},
					{
						label: "小计(￥)",
						prop: "subtotal",
						width: 110,
						sortable: "custom",
					},
					{
						label: "辅计单位",
						prop: "assistUnit",
						width: 110,
						sortable: "custom",
					},
					{
						label: "换算数量",
						prop: "assistQuantity",
						width: 110,
						sortable: "custom",
					},
					{
						label: "摘要",
						prop: "summary",
						width: 180,
						sortable: "custom",
					},
				],
			},
			tableCollectConfig: {
				//数据表格列配置
				columns: [
					{
						label: "期限名称",
						prop: "name",
						sortable: 'custom',
						fixed: "left",
						width: 160,
					},
					{
						label: "收款日期",
						prop: "collectDate",
						width: 130,
						sortable: 'custom',
					},
					{
						label: "收款金额",
						prop: "collectAmount",
						width: 120,
						sortable: 'custom',
					},
					{
						label: "操作用户",
						prop: "fz_operateName",
						width: 110,
						sortable: 'custom',
					},
					{
						label: "操作时间",
						prop: "addTime",
						width: 200,
						sortable: 'custom',
					},
				],
			}
		});

		onMounted(() => {
			sonListMap();
			dataListMap();
		});

		// 获取产品明细自定义字段
		const sonListMap = async () => {

			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 2, isEnabled: 1, menuCode: "erp_sale_contract",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {

					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.sonDataList = res.data.list;
			}
			state.sonDataList.forEach(item => {
				state.tableSonConfig.columns.push({
					label: item.name,
					prop: item.value,
					width: 120,
					sortable: 'custom',
				},);
			});
		};

		// 获取自定义字段
		const dataListMap = async () => {
			const params = {
				pageNum: 1, pageSize: 1000, tableHeaderCode: 1, isEnabled: 1, menuCode: "erp_sale_contract",
			};
			let res = await proxy.$API.custom.getList.get(params);
			if (res.code === 200) {
				res.data.list.forEach(item => {

					if(item.stype == 4){
						item.value = 'customField' + item.fieldLength + 'List'
					}else {
						item.value = 'customField' + item.fieldLength
					}
					// 是否必填
					if (item.isRequired === 1) {
						item.rules = {required: true, message: "必填"};
					}
				});
				state.dataList = res.data.list;
			}
		};
		//显示弹窗
		const showDialog = async (data) => {
			state.visible = true;
			state.fileList = [];
			if (data.id) {
				//根据id查询
				const res = await proxy.$API.saleContract.detail.get(data.id);
				if (res.code === 200) {
					state.data = res.data;
					//合同条款
					state.clauseList = res.data.clauseList;
					//获取明细表数据
					getGoodsList(res.data.id);
					//组装文件列表
					getFileList(res.data.files);
				}
			}
			state.loading = false;
		};

		//获取产品明细
		const getGoodsList = (id) => {
			if (id) {
				//将接口对象赋给表格
				state.tableSonConfig.params = {contractId: id};
				state.tableSonConfig.apiObj = proxy.$API.saleContractGoods.selectContractGoods;
				//刷新表格
				proxy.$refs.tableSon.reload({contractId: id});
			}
		};

		//组装文件列表
		const getFileList = (data) => {
			let arr = [];
			if (data) {
				arr = data.split(",");
				if (arr.length > 0) {
					arr.forEach((item) => {
						let obj = {};
						const iarr = item.split("/");
						obj["url"] = item;
						obj["name"] = iarr[iarr.length - 1];
						state.fileList.push(obj);
					});
				}
			}
		};

		//隐藏弹窗
		const hideDialog = () => {
			state.visible = false;
		};

		const previewFile = (file) => {
			window.open(proxy.$CONFIG.API_URL + "/api/" + file.url);
		};

		const handleDetail = (row, ref, field) => {
			const params = Object.assign({}, row);
			if (field) {
				params.id = row[field];
			}
			proxy.$refs[ref].showDialog(params);
		};
		return {
			...toRefs(state),
			handleDetail,
			showDialog,
			hideDialog,
			previewFile,
		};
	},
});
</script>

<style scoped>
.my-label {
	background: var(--el-color-success-light-9);
}

.my-content {
	background: var(--el-color-danger-light-9);
}
:deep(.el-descriptions__label.el-descriptions__cell.is-bordered-label){
  width: 13%;
}
</style>

